import { PixelRatio } from 'react-native';

const fontScale = PixelRatio.getFontScale();

const type = {
    base: 'System',
    black: 'NunitoSans-Black',
    bold: 'NunitoSans-ExtraBold',
    regular: 'NunitoSans-Bold',
    light: 'NunitoSans-Regular',
};

const size = {
    font8: Math.floor(8 / fontScale),
    font9: Math.floor(9 / fontScale),
    font10: Math.floor(10 / fontScale),
    font11: Math.floor(11 / fontScale),
    font12: Math.floor(12 / fontScale),
    font13: Math.floor(13 / fontScale),
    font14: Math.floor(14 / fontScale),
    font15: Math.floor(15 / fontScale),
    font16: Math.floor(16 / fontScale),
    font17: Math.floor(17 / fontScale),
    font18: Math.floor(18 / fontScale),
    font20: Math.floor(20 / fontScale),
    font21: Math.floor(21 / fontScale),
    font22: Math.floor(22 / fontScale),
    font23: Math.floor(23 / fontScale),
    font24: Math.floor(24 / fontScale),
    font25: Math.floor(25 / fontScale),
    font26: Math.floor(26 / fontScale),
    font30: Math.floor(30 / fontScale),
    font32: Math.floor(32 / fontScale),
    font34: Math.floor(34 / fontScale),
    font40: Math.floor(40 / fontScale),
    font46: Math.floor(46 / fontScale),
};

export default { type, size };
