import moment from 'moment';
import React, { PureComponent } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { any } from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import s from './styles';

export default class ListItem extends PureComponent {
    static propTypes = {
        item: any,
    };

    render() {
        const { item } = this.props;
        return (
            <View style={[s.listItemWrapper]}>
                <View style={s.listItemBody}>
                    <Text style={[s.successText, item.isProceed ? s.successText_ready : s.successText_error]}>
                        {item.isApproved ? 'NOTICE TO PROCEED' : 'DO NOT PROCEED'}
                    </Text>
                    <Text numberOfLines={1} style={s.listItemTitle}>
                        {item.title}
                    </Text>
                    <View style={s.listItemInfo}>
                        <View style={s.listItemInfoItem}>
                            <Text style={s.listItemInfoData}>{moment(item.date).format('MM/DD/YY')}</Text>
                        </View>
                        <View style={s.listItemInfoItem}>
                            <Text style={s.listItemInfoData}>{item.userName}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={[s.successText, item.isApproved && s.successText_ready]}>
                            {!item.isApproved ? 'NOT APPROVED' : 'APPROVED'}
                        </Text>
                    </View>
                </View>
                <TouchableOpacity onPress={item.onPress}>
                    <Icon name="chevron-right" size={28} style={s.chevronRight} />
                </TouchableOpacity>
            </View>
        );
    }
}
