import { StyleSheet } from 'react-native';
import { Font, Color } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        paddingTop: 16,
        alignItems: 'center',
    },
    form: {
        height: '70%',
    },
    menuTopContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    topMenu_title: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
    },
    itemTitle: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font16,
        fontFamily: Font.type.regular,
    },
    itemRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 12,
    },
    iconWrapper: {
        width: 25,
        height: 25,
        borderRadius: 13,
        backgroundColor: Color.light_navy_10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    surveyItem: {
        width: '90%',
        left: '5%',
        backgroundColor: Color.white,
        paddingHorizontal: 21,
        paddingVertical: 19,
        marginTop: 15,
        elevation: 15,
        borderRadius: 6,
    },
    modalWrapper: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.dark_navy_blue_50,
    },
    attachmentsWrapper: {
        width: '100%',
        flexDirection: 'row',
        marginTop: 10,
        flexWrap: 'wrap',
    },
    attachmentItem: {
        height: 150,
        width: 150,
        marginRight: 10,
        marginBottom: 10,
    },
    attachmentImage: {
        height: '100%',
        width: '100%',
    },
    attachmentButton: {
        width: 30,
        height: 30,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        right: 5,
        top: 5,
        backgroundColor: Color.pale_grey,
    },
    uploadingWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.white_50,
    },
    uploadingLabel: {
        backgroundColor: Color.white_50,
        padding: 5,
        borderRadius: 5,
    },
    offline: {
        flex: 0.33,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderRadius: 3,
        borderColor: Color.cloudy_blue,
        marginRight: 5,
        padding: 5,
    },
    offlineText: {
        textAlign: 'center',
        color: Color.cloudy_blue,
        fontSize: Font.size.font10,
    },
    buttonWrapper: {
        height: 250,
        width: 180,
        marginVertical: 40,
        marginHorizontal: 'auto',
    },
});
