import { cloneDeep } from 'lodash';
import produce from 'immer';
import NetInfo from '@react-native-community/netinfo';
import { showMessage } from 'react-native-flash-message';
import CNST from '../constants';
import Api from '../../utils/apiMiddleware';
import { Config } from '../../config';
import { generateId } from '../../utils/helpers';

import { Color } from '../../theme';

function saveToolBoxOffline(ticket, dispatch) {
    ticket.unsaved = true;
    if (!ticket.unsavedId) {
        ticket.unsavedId = generateId();
    }
    let message = null;
    if (ticket.CreatorStatus !== 'C') {
        message =
            // eslint-disable-next-line max-len
            'You do not currently have a data connection. This Tool Box Talk will be saved on your phone and uploaded as soon as you have a connection. Users will not be able to access this Tool Box Talk until it has been uploaded';
    } else {
        message =
            // eslint-disable-next-line max-len
            'You do not currently have a data connection. This Tool Box Talk will be saved on your phone and updated as soon as you have a connection.';
    }
    showMessage({
        message: 'Error',
        description: message,
        type: 'danger',
        icon: 'danger',
        position: 'right',
        hideStatusBar: true,
        duration: 1500,
        backgroundColor: Color.red,
    });
    dispatch({
        type: CNST.TOOLBOX_TALKS.SUBMIT_TICKET.ERROR,
        toolboxTalk: ticket,
    });
}

export function getToolboxTicketTypes() {
    return (dispatch) => {
        dispatch({
            type: CNST.TOOLBOX_TALKS.GET_TYPES.LOADING,
        });
        return Api()
            .get(`/sources/${Config.TOOLBOX_TYPES}/data`, { limit: 500 })
            .then((response) => {
                return dispatch({
                    type: CNST.TOOLBOX_TALKS.GET_TYPES.SUCCESS,
                    data: response.data,
                });
            })
            .catch((error) => {
                return dispatch({
                    type: CNST.TOOLBOX_TALKS.GET_TYPES.ERROR,
                    error,
                });
            });
    };
}

export function getSignedTickets() {
    return (dispatch) => {
        return Api()
            .get(`/sources/${Config.TOOLBOX_USER_SIGN}/data`, { limit: 500 })
            .then((response) => {
                const { data } = response;
                return dispatch({
                    type: CNST.TOOLBOX_TALKS.GET_SIGNED,
                    signedTickets: data,
                });
            });
    };
}

export function getToolboxTickets(userId) {
    return (dispatch) => {
        dispatch({
            type: CNST.TOOLBOX_TALKS.GET_TICKETS.LOADING,
        });
        return Api()
            .post(`/sources/${Config.TOOLBOX_TALKS}/data`, {
                relatedSearch: false,
                limit: 500,
                searchCriteria: {
                    FieldForeman: userId,
                },
            })
            .then((response) => {
                return dispatch({
                    type: CNST.TOOLBOX_TALKS.GET_TICKETS.SUCCESS,
                    data: response.data,
                });
            })
            .catch((error) => {
                return dispatch({
                    type: CNST.TOOLBOX_TALKS.GET_TICKETS.ERROR,
                    error,
                });
            });
    };
}

export function setToolboxTicket(item) {
    return (dispatch) => {
        dispatch({
            type: CNST.TOOLBOX_TALKS.SET_TICKET,
            item,
        });
    };
}

export function createToolboxTicket() {
    return (dispatch) => {
        return dispatch({
            type: CNST.TOOLBOX_TALKS.SET_TICKET,
            item: {
                id: null,
                IsActive: true,
                ToolBoxTalkName: '',
                ToolBoxTalk: '',
                FieldForeman: '',
                CreatorStatus: 'O',
                TicketStatus: 'I',
                Project: '',
                files: {
                    pdf: '',
                },
            },
        });
    };
}

export function saveToolboxTalks(data = {}) {
    const { user, ticket } = data;
    const toolboxTalk = {
        CreatorStatus: ticket.CreatorStatus,
        FieldForeman: ticket.FieldForeman,
        IsActive: ticket.IsActive,
        Project: ticket.Project,
        TicketStatus: ticket.TicketStatus,
        ToolBoxTalk: ticket.ToolBoxTalk,
        ToolBoxTalkName: ticket.ToolBoxTalkName,
        files: ticket.files,
    };
    return (dispatch) => {
        dispatch({ type: CNST.TOOLBOX_TALKS.SUBMIT_TICKET.LOADING });
        const isNew = ticket.id === null;
        return NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                return Api()
                    .put(`/sources/${Config.TOOLBOX_TALKS}/data/${isNew ? 'new' : ticket.id}`, {
                        data: toolboxTalk,
                        modifiedBy: user.username,
                    })
                    .then((response) => {
                        if (toolboxTalk.CreatorStatus !== 'C') {
                            showMessage({
                                message: 'Success',
                                description: 'Your Tool Box Talk is now available for your employees',
                                type: 'success',
                                position: 'right',
                                hideStatusBar: true,
                                duration: 1500,
                                backgroundColor: Color.turquoise,
                            });
                        }
                        dispatch({
                            type: CNST.TOOLBOX_TALKS.SUBMIT_TICKET.SUCCESS,
                            item: response,
                            unsavedTicketId: ticket.unsavedId,
                        });
                        return true;
                    })
                    .catch(() => {
                        saveToolBoxOffline(ticket, dispatch);
                        return false;
                    });
            }
            saveToolBoxOffline(ticket, dispatch);
            return false;
        });
    };
}

export function saveToolboxTalksUserSign(data = {}) {
    const { ticket, user } = data;
    return (dispatch) => {
        dispatch({ type: CNST.TOOLBOX_TALKS.SUBMIT_SIGNED_TICKET.LOADING });

        return Api()
            .put(`/sources/${Config.TOOLBOX_USER_SIGN}/data/new`, {
                data: ticket,
                modifiedBy: user.username,
            })
            .then((response) => {
                dispatch({
                    type: CNST.TOOLBOX_TALKS.SUBMIT_SIGNED_TICKET.SUCCESS,
                    response,
                });
                return true;
            })
            .catch(() => {
                dispatch({
                    type: CNST.TOOLBOX_TALKS.SUBMIT_SIGNED_TICKET.ERROR,
                });
                return false;
            });
    };
}

export function finishEditing() {
    return (dispatch) => {
        dispatch({
            type: CNST.TOOLBOX_TALKS.FINISH_EDITING,
        });
    };
}

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = {
    types: [],
    toolboxTalksTickets: [],
    currentTicket: null,
    isLoading: false,
    signedTickets: [],
};

export default produce((state, action) => {
    switch (action.type) {
        case CNST.TOOLBOX_TALKS.GET_SIGNED:
            state.signedTickets = action.signedTickets;
            break;
        case CNST.TOOLBOX_TALKS.GET_TYPES.SUCCESS:
            state.types = action.data;
            break;
        case CNST.TOOLBOX_TALKS.GET_TYPES.LOADING:
        case CNST.TOOLBOX_TALKS.GET_TICKETS.LOADING:
            state.isLoading = true;
            break;
        case CNST.TOOLBOX_TALKS.GET_TICKETS.SUCCESS:
            state.toolboxTalksTickets = action.data;
            state.isLoading = false;
            break;
        case CNST.TOOLBOX_TALKS.GET_TYPES.ERROR:
        case CNST.TOOLBOX_TALKS.GET_TICKETS.ERROR:
            state.isLoading = false;
            break;
        case CNST.TOOLBOX_TALKS.SET_TICKET:
            state.currentTicket = action.item;
            state.isInjuryReportEdit = true;
            break;
        case CNST.TOOLBOX_TALKS.SUBMIT_TICKET.LOADING:
            state.isLoading = true;
            break;
        case CNST.TOOLBOX_TALKS.SUBMIT_TICKET.ERROR: {
            const { toolboxTalk } = action;
            const { unsavedId } = toolboxTalk;
            let existsIndex = state.toolboxTalksTickets.findIndex((r) => r.unsavedId === unsavedId);

            if (existsIndex === -1 && toolboxTalk.id) {
                existsIndex = state.toolboxTalksTickets.findIndex((r) => r.id === toolboxTalk.id);
            }
            if (existsIndex >= 0) {
                state.toolboxTalksTickets[existsIndex] = toolboxTalk;
            } else {
                state.toolboxTalksTickets.push(toolboxTalk);
            }
            state.isLoading = false;
            break;
        }
        case CNST.TOOLBOX_TALKS.FINISH_EDITING:
            state.currentTicket = {};
            state.isInjuryReportEdit = false;
            break;
        case CNST.TOOLBOX_TALKS.SUBMIT_TICKET.SUCCESS: {
            const { item, unsavedTicketId } = action;
            const currentTicketIndex = state.toolboxTalksTickets.findIndex((ticket) => {
                return (item.id && ticket.id === item.id) || (unsavedTicketId && ticket.unsavedId === unsavedTicketId);
            });

            if (currentTicketIndex >= 0) {
                state.toolboxTalksTickets[currentTicketIndex] = item;
            } else {
                state.toolboxTalksTickets.push(item);
            }

            state.isLoading = false;
            break;
        }
        case CNST.TOOLBOX_TALKS.REMOVE_ATTACHMENT:
        case CNST.TOOLBOX_TALKS.SAVE_ATTACHMENT:
            state.currentTicket = {
                ...state.currentTicket,
                Attachment: action.attachments,
            };
            state.isChanged = true;
            break;
        case CNST.TOOLBOX_TALKS.SUBMIT_SIGNED_TICKET.SUCCESS:
            state.signedTickets = [...state.signedTickets, action.response];
            break;
        case CNST.ACCOUNT.LOGOUT.SUCCESS: {
            const newState = cloneDeep(initialState);
            newState.toolboxTalksTickets = state.toolboxTalksTickets.filter((t) => t.unsaved);
            return newState;
        }
        default:
            break;
    }
}, initialState);
