import { StyleSheet } from 'react-native';
import { Font, Color } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        paddingTop: 30,
        height: '100%',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    listTitle: {
        color: Color.blue,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font12,
        marginBottom: 12.5,
        marginTop: 4,
    },
    listWrapper: {
        width: '100%',
    },
    listContentContainer: {
        paddingBottom: 80,
        paddingHorizontal: '5%',
    },
});
