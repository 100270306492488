import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ScrollView, View, Text } from 'react-native';
import { cloneDeep } from 'lodash';
import s from './styles';
import { PrimaryButton, YesNoAnswer } from '../../../../components';

export default class DailyQuestionDialog extends Component {
    static propTypes = {
        data: PropTypes.array,
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        onSubmit: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.reloadItems();
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        if (prevProps.data !== data) {
            this.reloadItems();
        }
    }

    reloadItems() {
        const { data } = this.props;
        const newItems = [];
        if (data) {
            data.forEach((item, i) => {
                newItems.push({
                    id: i,
                    Question: item.Question,
                    Response: 'No',
                    FlaggedQuestion: item.FlaggedQuestion,
                });
            });
            this.setState({ items: newItems });
        }
    }

    submit = () => {
        const { onSubmit } = this.props;
        const { items } = this.state;
        onSubmit(items);
        this.reloadItems();
    };

    updateItem(data = {}) {
        const { item, value } = data;
        const { items } = this.state;
        if (items) {
            const newItems = cloneDeep(items);
            newItems.forEach((itm, i) => {
                if (itm.Question === item.Question) {
                    newItems[i].Response = value;
                }
            });
            this.setState({ items: newItems });
        }
    }

    render() {
        const { isOpen, title } = this.props;
        const { items } = this.state;

        return (
            <Modal visible={isOpen} animationType="fade" presentationStyle="overFullScreen" transparent>
                <View style={s.modalBackground} />
                <View style={s.modalContainer}>
                    <Text style={s.modalHeader}>DAILY QUESTIONS</Text>
                    <Text style={s.dailyTitle}>{title}</Text>
                    <ScrollView style={{ width: '100%' }} containerStyle={{ alignItems: 'center', paddingBottom: 30 }}>
                        {items.map((item) => {
                            return (
                                <View style={s.surveyItem} key={item.id}>
                                    <Text style={s.itemTitle}>{item.Question}</Text>
                                    <YesNoAnswer
                                        answer={item.Response}
                                        onChange={(value) => this.updateItem({ item, value })}
                                        isTwo
                                        big
                                    />
                                </View>
                            );
                        })}
                    </ScrollView>
                    <View style={s.modalButtonContainer}>
                        <PrimaryButton title="SUBMIT" md onPress={this.submit} disabled={false} />
                    </View>
                </View>
            </Modal>
        );
    }
}
