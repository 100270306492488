import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text, TouchableOpacity, View, ActivityIndicator } from 'react-native';
import { NavigationActions } from 'react-navigation';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import NetInfo from '@react-native-community/netinfo';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { NavigationBar } from '../../../components';
import { getTimeCards, setRefreshed, adminSetCards } from '../../../store/modules/timeCards';

import s from './styles';
import { Color } from '../../../theme'

const insertCard = (data) => {
    const { cards, date, totalTime, currentCard } = data;
    const existingCard = cards.find((c) => c.date === date);

    if (isEmpty(existingCard)) {
        cards.push({
            date,
            totalTime,
            data: [currentCard],
        });
    } else {
        existingCard.data.push(currentCard);
        existingCard.totalTime += totalTime;
    }
};

const _returnCurrentWeekPayPeriod = (week) => {
    let startDate = moment('12-23-2019', 'MM-DD-YYYY');
    let endDate = moment('1-5-2020', 'MM-DD-YYYY');
    const currentDate = moment().day('Tuesday').week(week);
    while (!currentDate.isBetween(startDate, endDate)) {
        startDate = moment(endDate, 'MM-DD-YYYY').add(1, 'day');
        endDate = moment(endDate).add(14, 'day');
    }
    return { startDate, endDate };
};

class AdminViewerTimeCardsScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentProject: PropTypes.object,
        user: PropTypes.object,
        groupedCards: PropTypes.array,
        timeCards: PropTypes.array,
        timeCardModifications: PropTypes.array,
        isLoading: PropTypes.bool,
        isRefresh: PropTypes.bool,
        currentClockInType: PropTypes.string,
        currentClockInTime: PropTypes.string,
        getTimeCards: PropTypes.func,
        setRefreshed: PropTypes.func,
        selectTimeCardEdit: PropTypes.func,
        adminSetCards: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { groupedCards, user } = props;
        const lastItem = !isEmpty(groupedCards) ? groupedCards.length - 1 : null;
        this.state = {
            week: !isEmpty(groupedCards) ? moment(groupedCards[lastItem].StartTime).week() : moment().week(),
            user,
            cards: [],
        };
    }

    componentDidMount() {
        const { user } = this.state;
        const { getTimeCards } = this.props;
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                getTimeCards({ user });
                this._returnTimeCards();
            }
        });
    }

    componentDidUpdate(prevProps) {
        const { isRefresh, timeCards, setRefreshed } = this.props;
        if (timeCards.length !== prevProps.timeCards.length) {
            this._returnTimeCards();
        }
        if (isRefresh) {
            this._returnTimeCards();
            setRefreshed();
        }
    }

    _returnTimeCards() {
        const {
            navigation,
            timeCards,
            currentProject,
            currentClockInType,
            currentClockInTime,
            selectTimeCardEdit,
            timeCardModifications,
            groupedCards,
        } = this.props;

        const cards = [];
        if (!isEmpty(timeCards)) {
            timeCards.forEach((timeCard) => {
                const date = moment(timeCard.StartTime).format('YYYY-MM-DD');
                let cardModification = false;
                timeCardModifications.forEach((m) => {
                    if (m.TimeCardDay === timeCard.WorkTimeId && !m.Change) {
                        cardModification = m;
                    }
                });
                const currentCard = {
                    id: timeCard.Project + 1,
                    title: timeCard.ProjectName ? timeCard.ProjectName : '',
                    type: timeCard.ClockInType,
                    timeStart: timeCard.StartTime,
                    timeEnd: timeCard.EndTime,
                    totalTime: timeCard.Duration.WorkTime,
                    totalBreakTime: timeCard.Duration.BreakTime ? timeCard.Duration.BreakTime : 0,
                    onPress: () => {
                        if (cardModification) {
                            selectTimeCardEdit(cardModification, 2);
                        } else {
                            selectTimeCardEdit(timeCard, 1);
                        }
                        navigation.navigate('TimeCardEdit');
                    },
                };
                if (cardModification) {
                    currentCard.timeStart = cardModification.StartTime;
                    currentCard.timeEnd = cardModification.EndTime;
                    currentCard.totalTime = cardModification.TotalTime;
                    switch (cardModification.Status) {
                        case 'U':
                            currentCard.status = 'EDIT IS PENDING APPROVAL';
                            break;
                        case 'D':
                            currentCard.status = 'EDIT IS DENIED';
                            break;
                        case 'N':
                            currentCard.status = 'ADMIN NEEDS MORE INFORMATION';
                            break;
                        default:
                            break;
                    }
                }
                insertCard({
                    cards,
                    date,
                    totalTime: timeCard.Duration.WorkTime,
                    currentCard,
                });
            });
        }

        if (groupedCards[groupedCards.length - 1].StartTime && !groupedCards[groupedCards.length - 1].EndTime) {
            const totalTime = moment().diff(groupedCards[groupedCards.length - 1].StartTime, 'second');
            const date = moment(groupedCards[groupedCards.length - 1].StartTime).format('YYYY-MM-DD');
            insertCard({
                cards,
                date,
                totalTime,
                currentCard: groupedCards[groupedCards.length - 1],
            });
        }
        if (currentProject) {
            const date = moment(currentClockInTime).format('YYYY-MM-DD');
            const totalTime = moment().diff(currentClockInTime, 'second');
            insertCard({
                cards,
                date,
                totalTime,
                currentCard: {
                    id: currentProject.id,
                    title: `Currently Clocked into: ${currentProject.ProjectName}`,
                    type: currentClockInType,
                    timeStart: currentClockInTime,
                    totalTime,
                    totalBreakTime: 0,
                },
            });
        }
        cards.reverse();
        this.setState({ cards });
    }

    _returnCurrentWeek() {
        const { week } = this.state;
        const weekStart = moment().week(week).startOf('week').add(1, 'day')
            .format('MMM DD');
        const weekEnd = moment().week(week).endOf('week').add(1, 'day')
            .format('MMM DD');
        return `${weekStart} - ${weekEnd}`;
    }

    _returnWeekDays() {
        const { week, cards } = this.state;
        const weekDays = [];
        for (let i = 1; i <= 7; i++) {
            const weekDay = moment().week(week).weekday(i);
            let dayTotalTime = null;
            cards.forEach((card) => {
                if (moment(weekDay).format('YYYY-MM-DD') === card.date && card.totalTime) {
                    dayTotalTime += card.totalTime;
                }
            });
            if (dayTotalTime) {
                weekDays.push(
                    <TouchableOpacity
                        style={s.weekDay}
                        key={moment(weekDay).format('YYYY-MM-DD') + i}
                        onPress={() => this.dayOnPress(weekDay)}
                    >
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={s.weekDayDate}>{moment(weekDay).format('MM/DD')}</Text>
                            <Text style={s.weekDayName}>{moment(weekDay).format('dddd')}</Text>
                        </View>
                        <Text style={[s.weekDayTime, s.weekDayTime_inactive]}>
                            {dayTotalTime ? moment().startOf('day').seconds(dayTotalTime).format('HH:mm') : '-'}
                        </Text>
                    </TouchableOpacity>,
                );
            } else {
                weekDays.push(
                    <View style={s.weekDay} key={moment(weekDay).format('YYYY-MM-DD') + i}>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={s.weekDayDate}>{moment(weekDay).format('MM/DD')}</Text>
                            <Text style={s.weekDayName}>{moment(weekDay).format('dddd')}</Text>
                        </View>
                        <Text style={[s.weekDayTime, s.weekDayTime_inactive]}>
                            {dayTotalTime ? moment().startOf('day').seconds(dayTotalTime).format('HH:mm') : '-'}
                        </Text>
                    </View>,
                );
            }
        }
        return weekDays;
    }

    dayOnPress(date) {
        const { navigation, adminSetCards } = this.props;
        const { cards } = this.state;
        let adminCards = [];
        for (let i = 0; i < cards.length; i++) {
            if (cards[i].date === moment(date).format('YYYY-MM-DD')) {
                adminCards = cards[i];
            }
        }
        adminSetCards(adminCards);
        navigation.navigate(
            'AdminViewer',
            {},
            NavigationActions.navigate({
                routeName: 'AdminViewerTimeCardDetails',
            }),
        );
    }

    _returnWorkedTimes(startDate, endDate, weekTotalTime, weekOverTime) {
        const { groupedCards } = this.props;
        const companyDayOverTime = 43200;
        const companyWeekOverTime = 144000;
        for (let i = 0; i < groupedCards.length; i++) {
            let currentDay = null;
            let dayTotal = 0;
            let weekTotal = 0;
            let overTime = 0;
            if (
                moment(groupedCards[i].StartTime).week() === startDate.week() ||
                moment(groupedCards[i].StartTime).week() === endDate.week() - 1
            ) {
                for (let j = 0; j < groupedCards[i].WorkTimes.length; j++) {
                    if (groupedCards[i].WorkTimes[j].Duration.WorkTimeHours) {
                        const currentTimeCardDay = new Date(groupedCards[i].WorkTimes[j].StartTime).toDateString();
                        if (!currentDay || currentDay !== currentTimeCardDay) {
                            currentDay = currentTimeCardDay;
                            dayTotal = 0;
                        }
                        dayTotal = groupedCards[i].WorkTimes[j].Duration.WorkTime;
                        if (dayTotal > companyDayOverTime) {
                            const diff = dayTotal - companyDayOverTime;
                            dayTotal = companyDayOverTime;
                            overTime += diff;
                        }
                        weekTotal += dayTotal;
                    }
                }
                if (weekTotal > companyWeekOverTime) {
                    const diff = weekTotal - companyWeekOverTime;
                    weekTotal = companyWeekOverTime;
                    overTime += diff;
                }
                weekTotalTime += weekTotal;
                weekOverTime += overTime;
            }
        }
        return { weekTotalTime, weekOverTime };
    }

    _returnTime(type) {
        const { week } = this.state;
        const { startDate, endDate } = _returnCurrentWeekPayPeriod(week);
        const { weekTotalTime, weekOverTime } = this._returnWorkedTimes(startDate, endDate, 0, 0);
        if (type === 'regular') {
            return weekTotalTime;
        }
        return weekOverTime;
    }

    _returnWeekTotal() {
        const weekTotalTime = this._returnTime('regular');
        const wholeMinutes = (Math.floor(weekTotalTime / 60) % 60).toString();
        const totalMinutes = wholeMinutes.length === 1 ? `0${wholeMinutes}` : wholeMinutes;
        return !weekTotalTime ? '00:00' : `${Math.floor(weekTotalTime / 60 / 60)}:${totalMinutes}`;
    }

    _returnWeekOverTime() {
        const weekTotalTime = this._returnTime('overtime');
        const wholeMinutes = (Math.floor(weekTotalTime / 60) % 60).toString();
        const totalMinutes = wholeMinutes.length === 1 ? `0${wholeMinutes}` : wholeMinutes;
        return !weekTotalTime ? '00:00' : `${Math.floor(weekTotalTime / 60 / 60)}:${totalMinutes}`;
    }

    nextWeek = () => {
        const { week } = this.state;
        this.setState({ week: week + 1 });
    };

    previousWeek = () => {
        const { week } = this.state;
        this.setState({ week: week - 1 });
    };

    _returnCurrentPeriod(week) {
        const { groupedCards } = this.props;
        let currentPeriod = null;
        if (!isEmpty(groupedCards)) {
            groupedCards.forEach((period) => {
                const { StartTime } = period;
                if (moment(StartTime).week() === week) {
                    currentPeriod = period;
                }
            });
        }
        return currentPeriod;
    }

    render() {
        const { user, isLoading } = this.props;

        return (
            <View style={s.wrapper}>
                <NavigationBar title="ADMIN VIEWER TIMECARDS" backIcon {...this.props} />
                <View contentContainerStyle={s.mainContainer}>
                    <View style={s.weekMenu}>
                        <TouchableOpacity onPress={this.previousWeek}>
                            <Icon name="chevron-left" size={28} style={s.weekMenuIcon} />
                        </TouchableOpacity>
                        <Text style={s.currentWeek}>{this._returnCurrentWeek()}</Text>
                        <TouchableOpacity onPress={this.nextWeek}>
                            <Icon name="chevron-right" size={28} style={s.weekMenuIcon} />
                        </TouchableOpacity>
                    </View>

                    {isLoading ? (
                        <ActivityIndicator size={40} color={Color.blue} />
                    ) : (
                        <View style={[s.formBlock, s.payPeriodBlock]}>
                            <View style={[s.formHeaderBlock, s.formHeaderBlock_paymentPeriod]}>
                                <View style={s.formHeaderBlockCol}>
                                    <Text style={s.title}>{`${user.name.first} ${user.name.last}`}</Text>
                                </View>
                            </View>
                            <View style={s.formBlockDates}>{this._returnWeekDays()}</View>
                            <View style={[s.formBlockTotal, s.formBlockTotal_border]}>
                                <Text style={[s.weekTotal, s.weekTotal_border]}>REGULAR</Text>
                                <Text style={[s.weekTotal]}>{this._returnWeekTotal()}</Text>
                            </View>
                            <View style={s.formBlockTotal}>
                                <Text style={s.weekTotal}>OVERTIME</Text>
                                <Text style={s.weekTotal}>{this._returnWeekOverTime()}</Text>
                            </View>
                        </View>
                    )}
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ timeCards, account, currentTimeCard }) => ({
    user: timeCards.user,
    groupedCards: timeCards.groupedCards,
    timeCards: timeCards.timeCards,
    isRefresh: timeCards.isRefresh,
    isLoading: timeCards.isLoading,
    projects: account.projects,
    currentProject: currentTimeCard.currentProject,
    currentCostCode: currentTimeCard.currentCostCode,
    currentClockInType: currentTimeCard.currentClockInType,
    currentClockInTime: currentTimeCard.clockIn[0],
    timeCardModifications: timeCards.timeCardModifications,
});

const mapDispatchToProps = (dispatch) => {
    const bindActions = bindActionCreators(
        {
            getTimeCards,
            setRefreshed,
            adminSetCards,
        },
        dispatch,
    );
    bindActions.dispatch = dispatch;
    return bindActions;
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminViewerTimeCardsScreen);
