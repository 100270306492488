import { StyleSheet } from 'react-native';
import { Font, Color } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        alignItems: 'center',
        paddingHorizontal: '5%',
    },
    listWrapper: {
        paddingTop: 20,
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    menuTopContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    topMenu_title: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
    },
});
