import { StyleSheet } from 'react-native';
import { Font, Color } from '../../theme';
import Common from '../../styles';

export default StyleSheet.create({
    wrapper: {
        ...Common.wrapperForm,
    },
    accText: {
        ...Common.accTextForm,
        color: Color.darkOrange,
    },
    title: {
        fontSize: Font.size.font21,
        alignSelf: 'flex-start',
        fontWeight: '300',
    },
    passwordRow: {
        width: '100%',
        flexDirection: 'row',
    },
    showPasswordIcon: {},
});
