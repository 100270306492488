import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, TouchableOpacity, View, Modal, TouchableWithoutFeedback, ActivityIndicator } from 'react-native';
import RNPrint from 'react-native-print';
import NetInfo from '@react-native-community/netinfo';
import { showMessage } from 'react-native-flash-message';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { SwipeListView } from 'react-native-swipe-list-view';
// import Pdf from 'react-native-pdf';

import moment from 'moment';
import { isEmpty, cloneDeep } from 'lodash';
import { DropdownList, NavigationBar, PrimaryButton, LinkButton } from '../../../components/index';
import { Color } from '../../../theme';

import {
    createToolboxTicket,
    getToolboxTickets,
    getToolboxTicketTypes,
    setToolboxTicket,
    getSignedTickets,
    saveToolboxTalks,
    finishEditing,
} from '../../../store/modules/toolboxTalks';

import s from './styles';

class ToolboxTalkDashboard extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        isLoading: PropTypes.bool,
        user: PropTypes.object,
        currentTicket: PropTypes.object,
        currentProject: PropTypes.object,
        signedTickets: PropTypes.array,
        tickets: PropTypes.array,
        toolboxTypes: PropTypes.array,
        getToolboxTicketTypes: PropTypes.func,
        getToolboxTickets: PropTypes.func,
        getSignedTickets: PropTypes.func,
        createToolboxTicket: PropTypes.func,
        setToolboxTicket: PropTypes.func,
        saveToolboxTalks: PropTypes.func,
        finishEditing: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            isCreateModal: false,
            isPdfOpen: false,
            pdf: null,
            toolBoxTalk: null,
            loadingPdfDownload: false,
        };
    }

    componentDidMount() {
        const { getToolboxTicketTypes } = this.props;
        getToolboxTicketTypes();
        this.onRefresh();
    }

    onRefresh = () => {
        const { getToolboxTickets, getSignedTickets } = this.props;
        getToolboxTickets();
        getSignedTickets();
    };

    goToItem = (item) => {
        const { navigation, setToolboxTicket } = this.props;
        setToolboxTicket(item);
        navigation.navigate('ToolboxTalkTicketDetails');
    };

    createTicket = () => {
        const { createToolboxTicket, user } = this.props;
        createToolboxTicket(user._id);
        this.setState({ isCreateModal: true });
    };

    countParticipants(toolBoxTalkName, project) {
        const { signedTickets } = this.props;
        let numberOfUsersSigned = 0;
        signedTickets.forEach(
            (t) => t.ToolBoxTalkName === toolBoxTalkName && t.Project === project && numberOfUsersSigned++,
        );
        return numberOfUsersSigned;
    }

    handleDownloadPress = (pdfUrl) => {
        // const fileName = pdfUrl.split('/').pop();

        this.setState({
            loadingPdfDownload: true,
        });

        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                /*
                RNFetchBlob.config({
                    timeout: 15000,
                    path: `${dirs.DownloadDir}/Trades2Work/${fileName}`,
                })
                    .fetch('GET', pdfUrl)
                    .then(() => {
                        showMessage({
                            message: 'Success',
                            description: `The PDF ${fileName} downloaded to Download/Trades2Work folder`,
                            duration: 5000,
                            backgroundColor: Color.turquoise,
                            animationDuration: 0,
                            hideOnPress: true,
                            hideStatusBar: true,
                            style: { zIndex: 3000 },
                        });
                        this.setState({
                            loadingPdfDownload: false,
                        });
                    })
                    .catch(() => {
                        showMessage({
                            message: 'PDF download failed',
                            type: 'danger',
                            hideStatusBar: true,
                            duration: 2000,
                            backgroundColor: Color.red,
                        });
                        this.setState({
                            loadingPdfDownload: false,
                        });
                    });
                    */
            } else {
                showMessage({
                    message: 'No data connection. PDF download will be available when you have a data connection.',
                    backgroundColor: Color.faded_orange,
                    color: Color.dark_navy_blue,
                    duration: 5000,
                    animationDuration: 0,
                    hideOnPress: true,
                    hideStatusBar: true,
                });
                this.setState({
                    loadingPdfDownload: false,
                });
            }
        });
    };

    handlePrintPress = (pdfUrl) => {
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                RNPrint.print({ filePath: pdfUrl });
            } else {
                showMessage({
                    message: 'No data connection. PDF print will be available when you have a data connection.',
                    backgroundColor: Color.faded_orange,
                    color: Color.dark_navy_blue,
                    duration: 5000,
                    animationDuration: 0,
                    hideOnPress: true,
                    hideStatusBar: true,
                });
            }
        });
    };

    renderItem(ticket) {
        const status = ticket.CreatorStatus === 'O' ? 'Opened' : 'Closed';

        let pdfActionButtons = null;
        if (!isEmpty(ticket.files.pdf)) {
            pdfActionButtons = (
                <View style={{ flexDirection: 'row', }}>
                    <TouchableOpacity
                        onPress={() => this.setState({ pdf: ticket.files.pdf, isPdfOpen: true })}
                        style={{ flexDirection: 'row' }}
                    >
                        <Icon
                            name="file-pdf-box"
                            size={20}
                            color={Color.turquoise}
                            style={{ marginRight: 5, marginTop: -4 }}
                        />
                        <Text style={s.listItemViewPDF}>PDF</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => this.handleDownloadPress(ticket.files.pdf)}
                        style={{ flexDirection: 'row' }}
                    >
                        <Icon
                            name="download"
                            size={20}
                            color={Color.turquoise}
                            style={{ marginLeft: 15, marginRight: 5, marginTop: -3 }}
                        />
                        <Text style={s.listItemViewPDF}>Download</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => this.handlePrintPress(ticket.files.pdf)}
                        style={{ flexDirection: 'row' }}
                    >
                        <Icon
                            name="printer"
                            size={20}
                            color={Color.turquoise}
                            style={{ marginLeft: 15, marginRight: 5, marginTop: -3 }}
                        />
                        <Text style={s.listItemViewPDF}>Print</Text>
                    </TouchableOpacity>
                </View>
            );
        }
        return (
            <TouchableWithoutFeedback onPress={() => this.goToItem(ticket)}>
                <View style={s.listItemWrapper}>
                    <View
                        style={{
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <Text style={s.listItemTitle}>{ticket.ToolBoxTalkName}</Text>
                        <Text style={ticket.CreatorStatus === 'C' ? s.listItemStatusClosed : s.listItemStatusOpen}>
                            {status}
                        </Text>
                    </View>
                    <View
                        style={{
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <Text style={s.listItemDetails}>
                            {`${this.countParticipants(ticket.ToolBoxTalkName, ticket.Project)} participants`}
                        </Text>
                        {pdfActionButtons}
                    </View>
                    {ticket.unsavedId ? <Text style={s.listItemStatusUnsaved}>Saved On Device</Text> : null}
                </View>
            </TouchableWithoutFeedback>
        );
    }

    onCreate = () => {
        const { saveToolboxTalks, user, currentTicket, currentProject } = this.props;
        const { toolBoxTalk } = this.state;
        if (toolBoxTalk) {
            const ticket = cloneDeep(currentTicket);
            const currentDate = moment().format('MM-DD-YYYY hh:mm:ss A');
            ticket.ToolBoxTalk = toolBoxTalk.id;
            // eslint-disable-next-line max-len
            ticket.ToolBoxTalkName = `${toolBoxTalk.title} ${currentProject.ProjectNumber} ${user.fullName} ${currentDate}`;
            ticket.FieldForeman = user._id;
            ticket.Project = currentProject.id;
            ticket.CreatorStatus = 'O';

            saveToolboxTalks({ user, ticket });
            this.setState({ isCreateModal: false });
        }
    };

    onChangeTicketStatus = (ticket, status) => {
        const { user, saveToolboxTalks } = this.props;
        const t = cloneDeep(ticket.item);
        t.CreatorStatus = status;
        saveToolboxTalks({ user, ticket: t });
    };

    onCancel = () => {
        const { finishEditing } = this.props;
        finishEditing();
        this.setState({ isCreateModal: false });
    };

    render() {
        const { isCreateModal, toolBoxTalk, isPdfOpen, pdf, loadingPdfDownload } = this.state;
        const { tickets, isLoading, currentProject, toolboxTypes } = this.props;

        const plusButton = (
            <TouchableOpacity style={s.addButton} onPress={this.createTicket}>
                <Icon name="plus" color={Color.turquoise} size={30} />
            </TouchableOpacity>
        );

        const types = toolboxTypes.map((t) => {
            return {
                id: t.id,
                title: t.ToolBoxTalkName,
            };
        });

        return (
            <View style={s.wrapper}>
                <NavigationBar
                    {...this.props}
                    title="TOOL BOX TALKS DASHBOARD"
                    menuIcon
                    rightComponent={
                        !isEmpty(currentProject) && !isLoading && !isEmpty(toolboxTypes) ? plusButton : null
                    }
                />
                {loadingPdfDownload && (
                    <View style={s.loadingIndicator} pointerEvents="none">
                        <ActivityIndicator color={Color.blue} size={40} />
                    </View>
                )}
                <View style={s.mainContainer}>
                    <SwipeListView
                        data={tickets}
                        keyExtractor={(item, i) => (item ? item.id : i)}
                        refreshing={isLoading}
                        onRefresh={this.onRefresh}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        contentContainerStyle={{
                            paddingTop: 20,
                            paddingHorizontal: 20,
                            paddingBottom: 100,
                        }}
                        renderItem={({ item }) => this.renderItem(item)}
                        // renderHiddenItem={(data) => (
                        //     <View style={s.swipeButtonsContainer}>
                        //         <TouchableOpacity
                        //             style={[s.swipeButton, s.swipeButton_open]}
                        //             onPress={() => this.onChangeTicketStatus(data, 'O')}
                        //         >
                        //             <Text style={s.swipeButtonText}>Open</Text>
                        //         </TouchableOpacity>
                        //         <TouchableOpacity
                        //             style={[s.swipeButton, s.swipeButton_close]}
                        //             onPress={() => this.onChangeTicketStatus(data, 'C')}
                        //         >
                        //             <Text style={s.swipeButtonText}>Close</Text>
                        //         </TouchableOpacity>
                        //     </View>
                        // )}
                        leftOpenValue={100}
                        rightOpenValue={-100}
                    />
                </View>

                <Modal visible={isCreateModal} transparent>
                    <View style={s.createModal}>
                        <View style={s.createModalContainer}>
                            <DropdownList
                                items={types}
                                containerStyle={s.dropdownContainer}
                                selectedItem={toolBoxTalk}
                                onItemSelect={(item) => this.setState({ toolBoxTalk: item })}
                                title="TOOLBOX TALK"
                                withIds
                            />
                            <View style={s.buttonsRow}>
                                <View style={{ width: '30%' }}>
                                    <LinkButton title="CANCEL" lg onPress={this.onCancel} />
                                </View>
                                <View style={{ width: '50%' }}>
                                    <PrimaryButton title="CREATE" onPress={this.onCreate} />
                                </View>
                            </View>
                        </View>
                    </View>
                </Modal>
                {isPdfOpen && (
                    <View style={s.modal}>
                        <View style={s.pdfContainer}>
                            <TouchableOpacity
                                style={[s.closeButton, s.downloadButton]}
                                onPress={() => this.handleDownloadPress(pdf)}
                            >
                                <Icon name="download" color={Color.white} size={30} />
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={[s.closeButton, s.printButton]}
                                onPress={() => this.handlePrintPress(pdf)}
                            >
                                <Icon name="printer" color={Color.white} size={30} />
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={s.closeButton}
                                onPress={() => this.setState({ pdf: null, isPdfOpen: false })}
                            >
                                <Icon name="close" color={Color.white} size={30} />
                            </TouchableOpacity>

                        </View>
                    </View>
                )}
            </View>
        );
    }
}

const mapStateToProps = ({ toolboxTalks, account, currentTimeCard }) => ({
    user: account.user,
    tickets: toolboxTalks.toolboxTalksTickets,
    isLoading: toolboxTalks.isLoading,
    currentProject: currentTimeCard.currentProject,
    toolboxTypes: toolboxTalks.types,
    currentTicket: toolboxTalks.currentTicket,
    signedTickets: toolboxTalks.signedTickets,
});

export default connect(mapStateToProps, {
    getToolboxTicketTypes,
    getToolboxTickets,
    setToolboxTicket,
    createToolboxTicket,
    getSignedTickets,
    saveToolboxTalks,
    finishEditing,
})(ToolboxTalkDashboard);
