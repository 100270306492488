import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FlatList, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {
    getInjuryTickets,
    getInjuryTicketTypes,
    setInjuryTicket,
    createInjuryTicket,
} from '../../../store/modules/injuryReporting';

import { NavigationBar } from '../../../components/index';
import ListItem from './ListItem';

import s from './styles';
import { Color } from '../../../theme/index';

class InjuryReportingListScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        user: PropTypes.object,
        currentProject: PropTypes.object,
        isLoading: PropTypes.bool,
        injuryTicketTypes: PropTypes.array,
        injuryTickets: PropTypes.array,
        clockIn: PropTypes.array,
        getInjuryTicketTypes: PropTypes.func,
        getInjuryTickets: PropTypes.func,
        setInjuryTicket: PropTypes.func,
        createInjuryTicket: PropTypes.func,
    };

    componentDidMount() {
        const { getInjuryTicketTypes } = this.props;
        getInjuryTicketTypes();
        this.onRefresh();
    }

    returnItemType(id) {
        const { injuryTicketTypes } = this.props;
        let injuryTypeName = '';
        injuryTicketTypes.forEach((ticketType) => {
            if (ticketType.id === id) {
                injuryTypeName = ticketType.Type;
            }
        });
        return injuryTypeName;
    }

    _returnItems() {
        const { injuryTickets } = this.props;
        const items = [];
        injuryTickets.forEach((t) => {
            if (t.InjuryType) {
                const item = {
                    id: t.id,
                    type: this.returnItemType(t.InjuryType),
                    isApproved: t.TicketStatus,
                    date: t.Date,
                    adminFeedback: t.AdminFeedback,
                    unsavedId: t.unsavedId ? t.unsavedId : null,
                    onPress: () => this.setTicket(t),
                };
                items.push(item);
            }
        });
        return items;
    }

    setTicket = (item) => {
        const { setInjuryTicket, navigation } = this.props;
        setInjuryTicket(item);
        navigation.navigate('InjuryReportingItem');
    };

    createTicket = () => {
        const { createInjuryTicket, navigation, user } = this.props;
        createInjuryTicket({ user });
        navigation.navigate('InjuryReportingItem');
    };

    onRefresh = () => {
        const { user, getInjuryTickets } = this.props;
        getInjuryTickets(user._id);
    };

    render() {
        const { isLoading, currentProject, clockIn, injuryTicketTypes } = this.props;
        const items = this._returnItems();
        const isClockedIn = !!currentProject && clockIn.length > 0;
        return (
            <View style={[s.wrapper]}>
                <NavigationBar {...this.props} title="DASHBOARD" menuIcon />
                <View style={[s.mainContainer]}>
                    <FlatList
                        data={items}
                        keyExtractor={(item, i) => (item ? item.id : i)}
                        refreshing={isLoading}
                        onRefresh={this.onRefresh}
                        style={{ width: '100%', height: '100%', }}
                        contentContainerStyle={{ paddingTop: 20, }}
                        renderItem={({ item, i }) => {
                            return <ListItem item={item} i={i} itemFabrication isLast={item.id === items.length - 1} />;
                        }}
                    />
                    {injuryTicketTypes.length > 0 ? (
                        <View style={s.bottomButtonsBlock}>
                            <TouchableOpacity
                                style={[s.addButton, !isClockedIn && s.addButton_inactive]}
                                disabled={!isClockedIn}
                                onPress={this.createTicket}
                            >
                                <Icon name="plus" color={Color.white} size={30} />
                            </TouchableOpacity>
                        </View>
                    ) : null}
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ injuryReporting, account, currentTimeCard }) => ({
    injuryTickets: injuryReporting.injuryTickets,
    injuryTicketTypes: injuryReporting.injuryTicketTypes,
    isLoading: injuryReporting.isLoading,
    user: account.user,
    currentProject: currentTimeCard.currentProject,
    clockIn: currentTimeCard.clockIn,
});

export default connect(mapStateToProps, {
    getInjuryTickets,
    getInjuryTicketTypes,
    setInjuryTicket,
    createInjuryTicket,
})(InjuryReportingListScreen);
