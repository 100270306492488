import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../../theme';

export default StyleSheet.create({
    listItemWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        marginBottom: 30,
        backgroundColor: Color.white,
        borderRadius: 6,
        width: '100%',
        elevation: 5,
        alignSelf: 'center',
    },
    flexRow: {
        flex: 1,
        flexDirection: 'row',
    },
    availableBox: {
        backgroundColor: Color.light_blue_3,
        borderRadius: 6,
        alignItems: 'center',
        alignSelf: 'flex-start',
        marginRight: 10,
        minHeight: 80,
        paddingHorizontal: 15,
        paddingVertical: 10,
    },
    upcomingBox: {
        backgroundColor: '#fce4d4',
        borderRadius: 6,
        alignItems: 'center',
        alignSelf: 'flex-start',
        marginRight: 10,
        minHeight: 80,
        paddingHorizontal: 15,
        paddingVertical: 10,
    },
    listItemRow: {
        flexDirection: 'row',
        width: '100%',
    },
    listItemColumn: {
        flexDirection: 'column',
        flexShrink: 1,
        paddingVertical: 5,
    },
    listItemHours: {
        color: Color.dark_navy_blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        textAlign: 'right',
    },
    listItemTitle: {
        color: '#8b9198',
        fontSize: Font.size.font14,
    },
    listItemButtonShowMore: {
        color: Color.dark_navy_blue,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font12,
        letterSpacing: 0.5,
    },
    listItemButtonViewAll: {
        color: Color.turquoise,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font11,
        textTransform: 'uppercase',
        letterSpacing: 0.5,
    },
    listItemProject: {
        color: Color.dark_navy_blue,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font16,
    },
    listItemDayAvailable: {
        color: Color.turquoise,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font10,
        textTransform: 'uppercase',
    },
    listItemDayUpcoming: {
        color: Color.orange,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font10,
        textTransform: 'uppercase',
    },
    listItemDayNum: {
        color: Color.dark_navy_blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font22,
        textTransform: 'uppercase',
        marginTop: -3,
    },
    listItemTimeBlock: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10,
    },
    listItemTime: {
        marginLeft: 5,
    },
    buttonRow: {
        borderTopColor: Color.pale_lilac,
        borderTopWidth: 1,
        borderStyle: 'dashed',
        marginTop: 20,
        paddingTop: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    listItemRowShowAll: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
    },
    listItemRowViewAll: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-end',
    },
});
