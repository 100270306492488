import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, TouchableOpacity, View } from 'react-native';
import { NavigationActions } from 'react-navigation';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import { NavigationBar } from '../../../components';

import s from './styles';

import { Config } from '../../../config';

class OutstandingDailyReportsDashboard extends Component {
    static propTypes = {
        navigation: PropTypes.object,
    };

    render() {
        const { navigation } = this.props;
        const projectTitle = Config.USE_DEPARTMENTS_JOBS ? 'Department' : 'Project';
        return (
            <View style={s.mainContainer}>
                <NavigationBar title="DASHBOARD" menuIcon {...this.props} />
                <View style={s.wrapper}>
                    <View style={s.menuContainer}>
                        <Text style={s.activeTab}>OUTSTANDING DAILY REPORTS</Text>
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate(
                                    'OutstandingReports',
                                    {},
                                    NavigationActions.navigate({
                                        routeName: 'OutstandingTimeCards',
                                    }),
                                );
                            }}
                        >
                            <Text style={s.inactiveTab}>OUTSTANDING TIME CARDS</Text>
                        </TouchableOpacity>
                    </View>
                    {!this.getListItems() ? (
                        <TouchableOpacity
                            style={s.noOutstanding}
                            onPress={() => {
                                navigation.navigate(
                                    'Clock',
                                    {},
                                    NavigationActions.navigate({
                                        routeName: 'Clock',
                                    }),
                                );
                            }}
                        >
                            <Icon name="clipboard-check-outline" size={150} color="#7f98b4" />
                            <Text style={s.noOutstandingText}>You have submitted all of your daily reports</Text>
                        </TouchableOpacity>
                    ) : (
                        <View>
                            <Text style={s.dateText}>Today</Text>
                            <TouchableOpacity style={s.listItemWrapper}>
                                <View style={s.listItemTimeCardHeader}>
                                    <Text style={s.cardTimes}>08:00 AM - 12:00 PM</Text>
                                    <Text style={s.cardTotalTime}>04:00</Text>
                                </View>
                                <Text style={s.listItemTitle}> Unassigned {projectTitle} Name</Text>
                                <Icon
                                    name="chevron-right"
                                    size={25}
                                    style={[s.chevronRight, s.chevronRight_timeCard]}
                                />
                            </TouchableOpacity>
                            <TouchableOpacity style={s.listItemWrapper}>
                                <View style={s.listItemTimeCardHeader}>
                                    <Text style={s.cardTimes}>01:00 PM - 04:30 PM</Text>
                                    <Text style={s.cardTotalTime}>03:30</Text>
                                </View>
                                <Text style={s.listItemTitle}> Unassigned {projectTitle} Name</Text>
                                <Icon
                                    name="chevron-right"
                                    size={25}
                                    style={[s.chevronRight, s.chevronRight_timeCard]}
                                />
                            </TouchableOpacity>
                        </View>
                    )}
                </View>
            </View>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OutstandingDailyReportsDashboard);
