import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { NavigationActions } from 'react-navigation';

import { NavigationBar } from '../../../components';
import ListItem from '../ListItem';

import { Color } from '../../../theme';
import s from './styles';

class ItemRequestItemsRequested extends Component {
    static propTypes = {
        navigation: PropTypes.object,
    };

    state = {
        items: [
            {
                id: '1',
                status: 'I',
                title: 'Item Name',
                quantity: '1',
            },
            {
                id: '2',
                status: 'I',
                title: 'Item Name',
                quantity: '1',
            },
        ],
    };

    render() {
        const { navigation } = this.props;
        const { items } = this.state;
        return (
            <View style={s.wrapper}>
                <NavigationBar title="ITEMS REQUESTED" backIcon {...this.props} />
                <View style={s.menuContainer}>
                    <Text style={s.menuTitle}>Unassigned Ticket Name</Text>
                </View>
                <View style={s.pencilIcon}>
                    <TouchableOpacity
                        onPress={() => {
                            navigation.navigate(
                                'ItemRequest',
                                {},
                                NavigationActions.navigate({
                                    routeName: 'ItemRequestAddNew',
                                }),
                            );
                        }}
                    >
                        <Icon name="pencil" color={Color.light_navy} size={15} />
                    </TouchableOpacity>
                </View>
                <View style={s.mainContainer}>
                    {items.map((f) => {
                        return <ListItem item={f} navigation={navigation} />;
                    })}
                </View>
            </View>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ItemRequestItemsRequested);
