export default {
    LOGIN: {
        LOADING: 'LOGIN',
        SUCCESS: 'LOGIN_SUCCESS',
        FAILED: 'LOGIN_FAILED',
    },
    LOGOUT: {
        LOADING: 'LOGOUT',
        SUCCESS: 'LOGOUT_SUCCESS',
        FAILED: 'LOGOUT_FAILED',
    },
    FORGOT_PASSWORD: {
        LOADING: 'FORGOT_PASSWORD',
        SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
        FAILED: 'FORGOT_PASSWORD_FAILED',
    },
    RESET_PASSWORD: {
        LOADING: 'RESET_PASSWORD',
        SUCCESS: 'RESET_PASSWORD_SUCCESS',
        FAILED: 'RESET_PASSWORD_FAILED',
    },
    TOGGLE_MENU: 'TOGGLE_MENU',
    GET_COST_CODES: 'GET_COST_CODES',
    GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
    GET_DEPARTMENT: 'GET_DEPARTMENT',
    GET_PROJECTS: 'GET_PROJECTS',
    USER_PERMISSIONS: 'USER_PERMISSIONS',
    USER_UPDATE: 'USER_UPDATE',
    USER_UPDATE_PROFILE_PHOTO: 'USER_UPDATE_PROFILE_PHOTO',
    DAILY_QUESTIONS_FETCH: {
        LOADING: 'DAILY_QUESTIONS_FETCH',
        SUCCESS: 'DAILY_QUESTIONS_FETCH_SUCCESS',
        ERROR: 'DAILY_QUESTIONS_FETCH_ERROR',
    },
    GET_USERS: {
        LOADING: 'GET_USERS_LOADING',
        SUCCESS: 'GET_USERS_SUCCESS',
    },
    USER_PHOTO_UPLOAD: {
        LOADING: 'USER_PHOTO_UPLOAD_START',
        SUCCESS: 'USER_PHOTO_UPLOAD_SUCCESS',
        ERROR: 'USER_PHOTO_UPLOAD_ERROR',
        CACHE: 'USER_PHOTO_CACHE',
    },
    CACHE_RECENT_PROJECTS: 'CACHE_RECENT_PROJECTS',
    GET_EMPLOYEE_HANDBOOKS: 'GET_EMPLOYEE_HANDBOOKS',
    GET_PAYROLL_CHECK_DATES: 'GET_PAYROLL_CHECK_DATES',
    GET_PTO_SETTINGS: {
        SUCCESS: 'GET_PTO_SETTINGS_SUCCESS',
    },
    GET_GENERAL_RULES_SETTINGS: {
        SUCCESS: 'GET_GENERAL_RULES_SETTINGS_SUCCESS',
    },
    GET_TIME_CARD_RULES_SETTINGS: {
        SUCCESS: 'GET_TIME_CARD_RULES_SETTINGS_SUCCESS',
    },
};
