import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SectionList, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {
    createChangeOrderItem,
    getChangeOrders,
    saveChangeOrderItem,
    setChangeOrderItem,
} from '../../../store/modules/changeOrder';

import { NavigationBar } from '../../../components/index';
import ListItem from './ListItem';
import { convertTimeStringToSeconds } from '../../../utils/helpers';

import s from './styles';
import { Color } from '../../../theme';

import { uploadImage, resizeImage } from '../../../store/helpers/common';
import { uploadStart } from '../../../store/modules/dailyReports';
import { Config } from '../../../config';

class ItemFabricationListScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentProject: PropTypes.object,
        changeOrderItems: PropTypes.array,
        user: PropTypes.object,
        isLoading: PropTypes.bool,
        isFocused: PropTypes.bool,
        userCanCreateChangeOrders: PropTypes.bool,
        getChangeOrders: PropTypes.func,
        setChangeOrderItem: PropTypes.func,
        selectCurrentChangeOrder: PropTypes.func,
        createChangeOrderItem: PropTypes.func,
        saveChangeOrderItem: PropTypes.func,
        uploadImage: PropTypes.func,
    };

    state = {
        search: '',
        currentOrder: null,
    };

    componentDidMount() {
        const { currentProject, getChangeOrders } = this.props;
        if (!isEmpty(currentProject)) {
            getChangeOrders(currentProject);
        }
    }

    componentDidUpdate(prevProps) {
        const { isFocused, currentProject, getChangeOrders } = this.props;

        if (
            (!isEmpty(currentProject) &&
                !isEmpty(prevProps.currentProject) &&
                currentProject.id !== prevProps.currentProject.id) ||
            (!isEmpty(currentProject) && isEmpty(prevProps.currentProject))
        ) {
            getChangeOrders(currentProject);
        }
        if (!prevProps.isFocused && isFocused && !isEmpty(currentProject)) {
            getChangeOrders(currentProject);
        }
    }

    _onSaveEvent = async (image) => {
        const { changeOrderItems, user, saveChangeOrderItem, uploadImage } = this.props;
        const { currentOrder } = this.state;
        const changeOrderItem = currentOrder ? changeOrderItems.find((e) => e.id === currentOrder) : null;
        if (!isEmpty(changeOrderItem)) {
            const data = {
                name: `${changeOrderItem.id}_change_order_complete_sign`,
                type: 'image',
                fileName: `${changeOrderItem.id}_change_order_complete_sign.jpg`,
                tags: [
                    {
                        value: 'image',
                        protected: true,
                    },
                ],
            };
            const file = await resizeImage(image.encoded);
            const filetype = 'change_order_complete';
            uploadImage(data, file, filetype, changeOrderItem.id).then(({ asset, error }) => {
                if (error) {
                    return;
                }

                changeOrderItem.Complete.Complete = true;
                changeOrderItem.Complete.UserSignature = asset;
                saveChangeOrderItem({ changeOrderItem, user });
                this.setState({ currentOrder: null });
            });
        }
    };

    returnTotalTime(estimates) {
        let time = 0;
        estimates.forEach((estimate) => {
            time += convertTimeStringToSeconds(estimate.UsedTime);
        });
        return time;
    }

    _returnItems() {
        const { changeOrderItems } = this.props;
        const { search } = this.state;
        const inProgressItems = [];
        const items = [];
        changeOrderItems.sort((CO1, CO2) => {
            if (CO1.timeCreated < CO2.timeCreated) {
                return 1;
            }
            if (CO1.timeCreated > CO2.timeCreated) {
                return -1;
            }
            return 0;
        });
        changeOrderItems.forEach((c) => {
            if (isEmpty(search) || c.TicketName.toLowerCase().includes(search.toLowerCase())) {
                const item = {
                    id: c.id,
                    isProceed: false,
                    isApproved: c.GMApproval === 'A',
                    date: moment(c.timeCreated),
                    title: c.TicketName,
                    userName: c.UserName,
                    onCheck: () => c.GMApproval && c.GMApproval === 'A' && this.setState({ currentOrder: c.id }),
                    onPress: () => this.setItem(c),
                };
                inProgressItems.push(item);
            }
        });
        if (inProgressItems.length > 0) {
            items.push({
                title: 'In progress',
                data: inProgressItems,
            });
        }
        return items;
    }

    setItem = (item) => {
        const { setChangeOrderItem, navigation } = this.props;
        setChangeOrderItem(item);
        navigation.navigate('ChangeOrderItemProperties');
    };

    setChangeOrder = (item) => {
        const { setChangeOrderItem, navigation } = this.props;
        setChangeOrderItem(item);
        navigation.navigate('ChangeOrderItem');
    };

    selectCurrentChangeOrder = (item) => {
        const { selectCurrentChangeOrder, navigation } = this.props;
        selectCurrentChangeOrder(item);
        navigation.goBack();
    };

    createChangeOrder = () => {
        const { createChangeOrderItem, navigation, currentProject, user } = this.props;
        createChangeOrderItem({ user, currentProject });
        navigation.navigate('ChangeOrderItem');
    };

    render() {
        const { currentProject, isLoading, getChangeOrders, userCanCreateChangeOrders } = this.props;
        const items = this._returnItems();
        const projectTitle = Config.USE_DEPARTMENTS_JOBS ? 'department' : 'project'

        return (
            <View style={[s.wrapper]}>
                <NavigationBar
                    {...this.props}
                    title="DASHBOARD"
                    menuIcon
                    searchIcon
                    onSearch={(val) => this.setState({ search: val })}
                />
                <View style={s.menuTopContainer}>
                    <Text style={s.topMenu_title}>
                        {isEmpty(currentProject) ? `You didn't clock in in to any ${projectTitle}` : currentProject.ProjectName}
                    </Text>
                </View>
                <View style={[s.mainContainer]}>
                    <SectionList
                        sections={items}
                        keyExtractor={(item, i) => (item ? item.id : i)}
                        refreshing={isLoading}
                        onRefresh={() => getChangeOrders(currentProject)}
                        style={{ width: '100%', height: '100%' }}
                        contentContainerStyle={{
                            paddingTop: 20,
                            paddingHorizontal: 20,
                            paddingBottom: 250,
                        }}
                        renderSectionHeader={({ section: { title } }) => <Text style={s.listTitle}>{title}</Text>}
                        renderItem={({ item, i }) => {
                            return <ListItem item={item} i={i} itemFabrication isLast={item.id === items.length - 1} />;
                        }}
                    />
                    {!isEmpty(currentProject) && userCanCreateChangeOrders && (
                        <View style={s.bottomButtonsBlock}>
                            <TouchableOpacity style={s.addButton} onPress={this.createChangeOrder}>
                                <Icon name="plus" color={Color.white} size={30} />
                            </TouchableOpacity>
                        </View>
                    )}
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ dailyReports, changeOrder, account, currentTimeCard }) => ({
    currentProject: dailyReports.currentProject,
    changeOrderItems: changeOrder.changeOrderItems,
    isLoading: changeOrder.isLoading,
    user: account.user,
    isSelecting: currentTimeCard.isSelectingClockInType,
    currentClockInType: currentTimeCard.currentClockInType,
    userCanCreateChangeOrders: account.user.meta.AllowedToCreatedCPA,
});

export default connect(mapStateToProps, {
    getChangeOrders,
    createChangeOrderItem,
    setChangeOrderItem,
    uploadStart,
    uploadImage,
    saveChangeOrderItem,
})(ItemFabricationListScreen);
