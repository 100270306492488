import { StyleSheet, Platform } from 'react-native';
import { Font, Color } from '../../theme';

const isIos = Platform.OS === 'ios';
export default StyleSheet.create({
    label: {
        marginBottom: isIos ? -45 : -5,
        fontSize: Font.size.font12,
        alignItems: 'center',
        textAlign: 'center',
        paddingBottom: isIos ? 5 : 5,
        color: Color.grey,
        fontFamily: Font.type.regular,
    },
    labelFocused: {
        color: Color.blue,
    },
});
