// import RNFetchBlob from 'rn-fetch-blob';
import { showMessage } from 'react-native-flash-message';
import mime from 'mime-types';

import CNST from '../constants';
import Api from '../../utils/apiMiddleware';

import { Color } from '../../theme';

// TODO: re-implement or remove functionality
export async function resizeImage(file, width, height) {
    const options = {
        output: 'jpg',
    };
    if (width || height) {
        if (width) {
            options.maxWidth = width;
        } else {
            options.maxHeight = height;
        }
    } else {
        options.maxHeight = 900;
        options.maxWidth = 900;
    }
    const result = ''; // await ImageCompressor.compress(file, options);
    return result;
}

export function uploadImage(data, file, type, id) {
    return (dispatch) => {
        if (dispatch) {
            dispatch({
                type: CNST.DAILY_REPORTS.FILE_UPLOAD.START,
                item: {
                    id: id || type,
                    type,
                    name: data.name,
                },
            });
        }

        let mimeType = mime.lookup(data.fileName);
        if (!mimeType) {
            mimeType = 'image/jpeg';
        }
        const body = {
            ...data,
            type: mimeType,
            checkFileNameDuplicates: false,
        };
        delete body.file;
        delete body.id;
        delete body.uploaded;
        delete body.path;
        delete body.uploading;
        delete body.uploadingStartTime;

        return Api()
            .put('/assets', body)
            .then((response) => {
                const formData = new FormData();
                const headers = {};

                Object.keys(response.presignedUrl.fields).forEach((key) => {
                    formData.append(key, response.presignedUrl.fields[key]);
                });
                formData.append('acl', 'public-read');
                formData.append('Content-Disposition', 'attachment');
                formData.append('Content-Type', mimeType);
                formData.append('file', file);
                headers['Content-Type'] = 'multipart/form-data';

                return Api()
                    .post(response.presignedUrl.url, formData, headers, { skipAuth: true })
                    .then(() => {
                        if (dispatch) {
                            dispatch({
                                type: CNST.DAILY_REPORTS.FILE_UPLOAD.SUCCESS,
                                name: data.name,
                            });
                        }
                        return { asset: response.asset, error: null };
                    })
                    .catch((error) => {
                        console.log(error);
                        return { asset: null, error };
                    });
            })
            .catch((error) => {
                if (dispatch) {
                    dispatch({
                        type: CNST.DAILY_REPORTS.FILE_UPLOAD.ERROR,
                        id: id || type,
                    });
                }

                if (type === 'avatar') {
                    // save photo in cache
                    if (dispatch) {
                        dispatch({
                            type: CNST.ACCOUNT.USER_PHOTO_UPLOAD.CACHE,
                            photo: { data, img: file },
                        });
                    }

                    showMessage({
                        message: 'Save Photo Error',
                        description:
                            // eslint-disable-next-line max-len
                            'An error occurred while saving photo. Your photo has been saved on your device and will be sent next time.',
                        type: 'danger',
                        duration: 5000,
                        animationDuration: 700,
                        hideOnPress: true,
                        hideStatusBar: true,
                        backgroundColor: Color.red,
                    });
                }

                return { asset: null, error };
            });
    };
}

export function isUploadingOverdue(uploadingStartTime) {
    if (uploadingStartTime) {
        const uploadingStartTimeDiff = Math.abs(new Date() - uploadingStartTime) / 36e5;
        return uploadingStartTimeDiff > 2;
    }

    return true;
}
