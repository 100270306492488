import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    listItemWrapper: {
        width: '90%',
        padding: 0,
        marginBottom: 10,
        backgroundColor: Color.white,
        borderRadius: 6,
        height: 80,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        elevation: 5,
        alignSelf: 'center',
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    listItemRow: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 17,
        height: 40,
    },
    listItemRowUnsaved: {
        height: 30,
    },
    listItemRow_border: {
        borderBottomColor: Color.pale_lilac,
        borderBottomWidth: 2,
        borderStyle: 'dotted',
        borderRadius: 3,
    },
    listItemBottomText: {
        color: Color.light_navy,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font11,
        marginTop: 3,
    },
    listGreyItem: {
        fontSize: Font.size.font12,
        fontFamily: Font.type.regular,
        color: Color.grey,
    },
    listItemDate: {
        fontSize: Font.size.font13,
        fontFamily: Font.type.regular,
        color: Color.dark_navy_blue,
    },
    listItemWorkOrderNumber: {
        fontSize: Font.size.font13,
        fontFamily: Font.type.regular,
        color: Color.dark_navy_blue,
    },
    listItemUnsaved: {
        fontSize: Font.size.font13,
        fontFamily: Font.type.regular,
        color: Color.red,
    },
    iconWrapper: {
        width: 30,
        height: 30,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.light_navy_05,
        marginRight: 20,
    },
    listItemIcon: {
        color: Color.light_navy,
    },
    listItemTitle: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font15,
        color: Color.dark_navy_blue,
        marginTop: 5,
        alignSelf: 'flex-start',
    },
    listItemTitle_timeCard: {
        marginTop: 6,
    },
    listItemColumn: {
        flexDirection: 'row',
    },
    listItemColumn_timeCard: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    labelRequired: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font13,
        fontFamily: Font.type.regular,
        marginTop: 1,
        marginRight: 15,
    },
    chevronRight: {
        color: Color.turquoise,
        fontSize: Font.size.font24,
    },
    listItemTimeCardHeader: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5,
    },
    cardTimes: {
        fontFamily: Font.type.regular,
        color: Color.light_grey_blue,
        fontSize: Font.size.font11,
    },
    cardTotalTime: {
        fontFamily: Font.type.regular,
        color: Color.light_navy,
        fontSize: Font.size.font12,
    },
});
