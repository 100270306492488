import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        overflow: 'scroll',
        alignItems: 'center',
        paddingHorizontal: '5%',
        paddingTop: 20,
        paddingBottom: 100,
    },
    buttonWrapper: {
        marginTop: 20,
        width: 180,
    },
    listWrapper: {
        paddingTop: 20,
        height: 200,
        width: '90%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    listTitle: {
        fontSize: Font.size.font12,
        fontFamily: Font.type.bold,
        color: Color.light_navy,
        marginBottom: 10,
        marginLeft: '5%',
    },
    workOrder: {
        flex: 1,
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        marginBottom: 90,
    },
    workOrderNumber: {
        minHeight: 100,
        width: '100%',
    },
    workOrderText: {
        textAlign: 'center',
        alignItems: 'center',
    },
    workOrderTitle: {
        fontSize: Font.size.font12,
        fontFamily: Font.type.bold,
        color: Color.dark_navy_blue_50,
    },
    label: {
        alignSelf: 'flex-start',
        fontSize: Font.size.font11,
        color: Color.dark_navy_blue_30,
        marginBottom: 20,
    },
});
