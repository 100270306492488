import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme';

export default StyleSheet.create({
    listItemWrapper: {
        width: '90%',
        height: 50,
        marginBottom: 10,
        marginHorizontal: 'auto',
        backgroundColor: Color.white,
        borderRadius: 6,
        elevation: 5,
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        paddingTop: 20,
        paddingBottom: 20,
        height: '100%',
        width: '100%',
        paddingHorizontal: '5%',
    },
    title: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue,
        textAlign: 'left',
        paddingLeft: 18.5,
        marginTop: 14,
    },
    listText: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue,
        textAlign: 'left',
        marginBottom: 9.75,
    },
    menuContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        paddingHorizontal: 15,
    },
    menuDotOne: {
        backgroundColor: Color.light_navy,
        marginLeft: '42.5%',
        height: 9.5,
        width: 9.5,
        borderRadius: 5,
        marginTop: 22.5,
    },
    menuDotTwo: {
        backgroundColor: Color.pale_grey_three,
        marginLeft: 13.5,
        height: 9.5,
        width: 9.5,
        borderRadius: 5,
        marginTop: 22.5,
    },
    menuDotThree: {
        backgroundColor: Color.pale_grey_three,
        borderRadius: 5,
        marginLeft: 12.5,
        height: 9.5,
        width: 9.5,
        marginTop: 22.5,
    },
    button: {
        height: 50,
        width: '50%',
        backgroundColor: Color.light_navy,
        marginLeft: '25%',
        borderRadius: 7.5,
        alignItems: 'center',
        paddingTop: 17,
        marginBottom: 30,
        marginTop: 50,
    },
    buttonText: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.white,
    },
    underLine: {
        height: 1,
        backgroundColor: Color.turquoise,
        marginVertical: 15,
    },
    videoPlaceHolder: {
        width: '105%',
        backgroundColor: Color.light_navy,
        height: 179,
    },
});
