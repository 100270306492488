import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../../theme';

export default StyleSheet.create({
    modalBackground: {
        flex: 1,
        backgroundColor: Color.light_grey_blue_05,
    },
    modalContainer: {
        position: 'absolute',
        width: '90%',
        height: '80%',
        left: '5%',
        top: '10%',
        backgroundColor: Color.pale_grey_two,
        borderRadius: 7.5,
        alignItems: 'center',
        paddingVertical: 10,
    },
    modalButtonContainer: {
        width: '50%',
        alignSelf: 'center',
        marginTop: 40,
    },
    modalHeader: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font13,
        fontFamily: Font.type.bold,
        textAlign: 'center',
        marginTop: 20,
    },
    dailyTitle: {
        color: Color.light_navy,
        fontSize: Font.size.font15,
        fontFamily: Font.type.light,
        marginTop: 15,
        marginBottom: 10,
        textAlign: 'center',
    },
    surveyItem: {
        width: '90%',
        left: '5%',
        backgroundColor: Color.white,
        paddingHorizontal: 21,
        paddingVertical: 19,
        marginTop: 15,
        elevation: 5,
        borderRadius: 6,
        alignItems: 'center',
    },
    itemTitle: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font16,
        fontFamily: Font.type.light,
        marginBottom: 15,
    },
});
