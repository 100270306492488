import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text, TouchableOpacity, View, ScrollView } from 'react-native';
import { NavigationActions } from 'react-navigation';
import { NavigationBar } from '../../../components';

import s from './styles';

class AccidentReportingAddNewStepOneScreen extends PureComponent {
    static propTypes = {
        navigation: PropTypes.object,
    };

    render() {
        const { navigation } = this.props;
        return (
            <View style={s.wrapper}>
                <NavigationBar title="AUTO ACCIDENT REPORT" backIcon {...this.props} />
                <View style={s.menuContainer}>
                    <View style={s.menuDotOne} />
                    <View style={s.menuDotTwo} />
                    <View style={s.menuDotThree} />
                </View>
                <ScrollView>
                    <View style={s.mainContainer}>
                        <Text style={s.title}>First Steps</Text>
                        <Text style={s.listText}>Remain calm</Text>
                        <Text style={s.listText}>Get to a safe place</Text>
                        <Text style={s.listText}>Check for injuries</Text>
                        <Text style={s.listText}>Administer First Aid</Text>
                        <Text style={s.listText}>Call police/EMT</Text>
                        <View style={s.underLine} />
                        <Text style={s.title}>Do NOT Say</Text>
                        <Text style={s.listText}>{"\u2022   It's all my fault, (even if it is)."}</Text>
                        <Text style={s.listText}>{'\u2022   My insurance will pay for everything.'}</Text>
                        <Text style={s.listText}>{"\u2022   It's OK, I have full coverage."}</Text>
                        <View style={s.underLine} />
                        <Text style={s.title}>While Still At the Scene</Text>
                        <Text style={s.listText}>
                            {'\u2022   Get as much information as possible\n     on this report'}
                        </Text>
                        <Text style={s.listText}>{'\u2022   Take Pictures'}</Text>
                        <Text style={s.listText}>
                            {'\u2022   When the police come, cooperate and\n     tell what you know'}
                        </Text>
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate(
                                    'AccidentReporting',
                                    {},
                                    NavigationActions.navigate({
                                        routeName: 'AccidentReportingAddNewStepTwo',
                                    }),
                                );
                            }}
                        >
                            <View style={s.button}>
                                <Text style={s.buttonText}>CONTINUE</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </ScrollView>
            </View>
        );
    }
}

export default AccidentReportingAddNewStepOneScreen;
