const minLength = min => value => (value && value.length < min ? `Must be at least ${min} symbols` : undefined);

export const required = (value) => {
    return value ? undefined : 'required';
};

export const minLength6 = minLength(6);

export const email = (value) => {
    return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? 'Invalid email address' : undefined;
};

export const confirmPassword = (confirmPass, values) => {
    return values.password !== confirmPass ? 'Password mismatched' : undefined;
};
