import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, FlatList } from 'react-native';
import { connect } from 'react-redux';
import NetInfo from '@react-native-community/netinfo';
import { isEmpty } from 'lodash';
import { setCurrentCostCode } from '../../../store/modules/currentTimeCard';
import { setTimeCardEditCostCode, setBulkTimeCardEditCostCode } from '../../../store/modules/timeCardEdit';
import { getCostCodes } from '../../../store/modules/account';
import { ListItem, NavigationBar, BottomGradient } from '../../../components/index';
import { Config } from '../../../config';

import s from './styles';

class CostCodeScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        costCodes: PropTypes.array,
        project: PropTypes.object,
        editTimeCardProject: PropTypes.object,
        getCostCodes: PropTypes.func,
        setCurrentCostCode: PropTypes.func,
        setTimeCardEditCostCode: PropTypes.func,
        setBulkTimeCardEditCostCode: PropTypes.func,
        bulkTimeCardEdits: PropTypes.array,
    };

    state = {};

    constructor(props) {
        super(props);
        const { navigation } = this.props;
        this.isTimeCardEdit = navigation.getParam('isTimeCardEdit', false);
        this.isTimeCardBulkEdit = navigation.getParam('isTimeCardBulkEdit', false);
        this.index = navigation.getParam('index', false);
    }

    componentDidMount() {
        const { getCostCodes } = this.props;
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                getCostCodes();
            }
        });
    }

    componentDidUpdate() {
        const { navigation } = this.props;
        this.isTimeCardEdit = navigation.getParam('isTimeCardEdit', false);
        this.isTimeCardBulkEdit = navigation.getParam('isTimeCardBulkEdit', false);
        this.index = navigation.getParam('index', false);
    }

    onBack() {
        const { navigation } = this.props;
        if (this.isTimeCardBulkEdit) {
            navigation.pop();
            navigation.navigate('BulkTimeCardEdit');
        } else if (this.isTimeCardEdit) {
            navigation.pop();
            navigation.navigate('TimeCardEdit');
        } else {
            navigation.navigate('ClockIn');
        }
    }

    _returnCostCodes() {
        const {
            setCurrentCostCode,
            setTimeCardEditCostCode,
            navigation,
            costCodes,
            project,
            editTimeCardProject,
            setBulkTimeCardEditCostCode,
            bulkTimeCardEdits,
        } = this.props;
        const codes = [];
        let projectToSearch = this.isTimeCardEdit ? editTimeCardProject : project || null;
        projectToSearch = this.isTimeCardBulkEdit ? bulkTimeCardEdits[this.index].projectDetails : projectToSearch;
        costCodes.forEach((code) => {
            let isPersist = false;
            if (projectToSearch && !isEmpty(projectToSearch.ProjectEstimates)) {
                projectToSearch.ProjectEstimates.forEach((estimate) => {
                    if (estimate.CostCode === code.id) {
                        isPersist = true;
                    }
                });
            }
            if (isPersist) {
                codes.push(code);
            }
        });

        return codes.map((code) => {
            return {
                id: code.id,
                title: `${code.Code} ${code.Title}`,
                onPress: () => {
                    if (this.isTimeCardBulkEdit) {
                        setBulkTimeCardEditCostCode(code.id, this.index);
                        navigation.navigate('BulkTimeCardEdit');
                    } else if (this.isTimeCardEdit) {
                        setTimeCardEditCostCode(code.id);
                        navigation.navigate('TimeCardEdit');
                    } else {
                        setCurrentCostCode({ code });
                        navigation.navigate('ClockIn');
                    }
                },
            };
        });
    }

    render() {
        let title = 'COST CODE';
        if (Config.USE_DEPARTMENTS_JOBS) {
            title = 'JOB';
        }
        return (
            <View style={s.wrapper}>
                <NavigationBar {...this.props} title={title} backIcon backIconFunction={() => this.onBack()} />
                <View style={[s.mainContainer]}>
                    <BottomGradient />
                    <FlatList
                        ListHeaderComponent={<Text style={s.listTitle}>{title}</Text>}
                        data={this._returnCostCodes()}
                        keyExtractor={(item) => item.id}
                        style={{ width: '100%', paddingHorizontal: '5%' }}
                        renderItem={({ item }) => {
                            return <ListItem item={item} />;
                        }}
                        ListFooterComponent={<View style={s.bottomPadding} />}
                    />
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ account, currentTimeCard, timeCardEdit }) => ({
    costCodes: account.costCodes,
    project: currentTimeCard.currentProject,
    isSwitch: currentTimeCard.isSwitch,
    editTimeCardProject: timeCardEdit.projectDetails,
    bulkTimeCardEdits: timeCardEdit.bulkTimeCardEdits,
});

export default connect(mapStateToProps, {
    setCurrentCostCode,
    setTimeCardEditCostCode,
    getCostCodes,
    setBulkTimeCardEditCostCode,
})(CostCodeScreen);
