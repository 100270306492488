import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Text, TouchableOpacity, View } from 'react-native';

import LinkButton from '../LinkButton/LinkButton';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

import s from './styles';

const ConfirmationDialog = ({ isOpen, confirmLabel, onCancel, onPress, title, text, onOk }) => {
    return (
        <Modal visible={isOpen} transparent>
            <View style={s.modalWrapper}>
                {onCancel ? (
                    <TouchableOpacity
                        onPress={() => onCancel()}
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                ) : null}
                <View style={s.attachmentModal}>
                    <Text style={s.attachmentModalTitle}>{title}</Text>
                    <Text style={s.attachmentModalText}>{text}</Text>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginTop: 20,
                            marginBottom: 20,
                            paddingHorizontal: 20,
                            alignItems: 'center',
                        }}
                    >
                        {onCancel && (
                            <View style={{ width: '30%' }}>
                                <LinkButton title="Cancel" lg onPress={() => onCancel()} />
                            </View>
                        )}
                        <View style={{ width: '50%' }}>
                            <PrimaryButton title={confirmLabel || 'Confirm'} onPress={() => onPress()} />
                        </View>
                    </View>
                    {onOk ? (
                        <View
                            style={{
                                alignContent: 'center',
                                paddingLeft: '20%',
                                paddingRight: '20%',
                                paddingBottom: '5%',
                            }}
                        >
                            <PrimaryButton title="Ok" lg onPress={() => onOk()} />
                        </View>
                    ) : null}
                </View>
            </View>
        </Modal>
    );
};

ConfirmationDialog.propTypes = {
    isOpen: PropTypes.bool,
    confirmLabel: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    onCancel: PropTypes.func,
    onPress: PropTypes.func,
    onOk: PropTypes.func,
};

export default ConfirmationDialog;
