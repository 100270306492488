import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { NavigationActions } from 'react-navigation';

import { NavigationBar } from '../../../components';
import ListItem from '../ListItem';

import { Color } from '../../../theme';
import s from './styles';
import { Config } from '../../../config'

class ItemRequestDashboard extends Component {
    static propTypes = {
        navigation: PropTypes.object,
    };

    state = {
        items: [
            {
                id: '1',
                title: 'Unassigned Ticket Name',
                date: '04/04/2020',
                quantity: '2',
                status: 'P',
            },
            {
                id: '2',
                title: 'Unassigned Ticket Name',
                date: '05/01/2020',
                quantity: '1',
                status: 'A',
            },
            {
                id: '3',
                title: 'Unassigned Ticket Name',
                date: '04/04/2020',
                quantity: '2',
                status: 'D',
            },
        ],
    };

    render() {
        const { navigation } = this.props;
        const { items } = this.state;
        const projectTitle = Config.USE_DEPARTMENTS_JOBS ? 'Department' : 'Project';

        return (
            <View style={s.wrapper}>
                <NavigationBar title="DASHBOARD" menuIcon {...this.props} />
                <View style={s.menuContainer}>
                    <Text style={s.menuTitle}>Unassigned {projectTitle} Name</Text>
                </View>
                <View style={s.mainContainer}>
                    {items.map((f, index) => {
                        return <ListItem key={index} item={f} navigation={navigation} />;
                    })}
                </View>
                <View style={s.bottomButtonsBlock}>
                    <TouchableOpacity
                        style={s.addButton}
                        onPress={() => {
                            navigation.navigate(
                                'ItemRequest',
                                {},
                                NavigationActions.navigate({
                                    routeName: 'ItemRequestAddNew',
                                }),
                            );
                        }}
                    >
                        <Icon name="plus" color={Color.white} size={30} />
                    </TouchableOpacity>
                </View>
            </View>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ItemRequestDashboard);
