import produce from 'immer';
import { cloneDeep, isNil } from 'lodash';
import NetInfo from '@react-native-community/netinfo';
import { showMessage } from 'react-native-flash-message';
import CNST from '../constants';
import Api from '../../utils/apiMiddleware';
import { Config } from '../../config';
import { uploadImage, isUploadingOverdue } from '../helpers/common';
import { generateId } from '../../utils/helpers';
import Queue from '../helpers/queue';

import { Color } from '../../theme';

const uploadingQueue = new Queue();

function uploadCheck(currentReport) {
    const results = {
        isNeedUpload: false,
        uploading: false,
    };

    const checkAttachment = (attachment) => {
        const { id, uploaded, uploading, uploadingStartTime } = attachment;
        const uploadingOverdue = isUploadingOverdue(uploadingStartTime);

        if (id && !isNil(uploaded) && !uploaded && (!uploading || uploadingOverdue)) {
            results.isNeedUpload = true;
        }

        if (id && !isNil(uploaded) && !uploaded && uploading && !uploadingOverdue) {
            results.uploading = true;
        }

        return results.isNeedUpload && results.uploading;
    };

    for (const section of currentReport.Sections) {
        for (const attachment of section.Attachments) {
            if (checkAttachment(attachment)) {
                return results;
            }
        }
        for (const question of section.Questions) {
            for (const attachment of question.Attachments) {
                if (checkAttachment(attachment)) {
                    return results;
                }
            }
        }
    }

    return results;
}

function saveReportImages(currentReport, onImageStartUpload, onImageUploaded, onImageUploadError, onComplete) {
    let isNeedUpload = false;

    uploadingQueue.setOnComplete = (success) => {
        onComplete(success);
    };

    const uploadHandler = (asset, attachment, item) => {
        if (asset && asset.file) {
            onImageUploaded(asset, attachment.id, currentReport);
            const existsIndex = item.Attachments.findIndex((a) => a.id === attachment.id);
            if (existsIndex >= 0) {
                item.Attachments.splice(existsIndex, 1, {
                    Attachment: asset.file.url,
                    AttachmentAssetId: asset._id,
                });
            } else {
                item.Attachments.push({
                    Attachment: asset.file.url,
                    AttachmentAssetId: asset._id,
                });
            }
            return true;
        }
        onImageUploadError(attachment.id, currentReport);
        return false;
    };

    const addToUploadingQueue = (attachment, item) => {
        isNeedUpload = true;
        onImageStartUpload(attachment.id, currentReport);
        const uploadAttachment = async () => {
            const response = await uploadImage(attachment, attachment.file)();
            return uploadHandler(response.asset, attachment, item);
        };

        uploadingQueue.push({
            func: uploadAttachment,
            args: [],
        });
    };

    // upload questions attachments
    for (const section of currentReport.Sections) {
        for (const attachment of section.Attachments) {
            const { id, uploaded, uploading, uploadingStartTime } = attachment;
            const uploadingOverdue = isUploadingOverdue(uploadingStartTime);
            if (id && !isNil(uploaded) && !uploaded && (!uploading || uploadingOverdue)) {
                addToUploadingQueue(attachment, section);
            }
        }

        for (const question of section.Questions) {
            for (const attachment of question.Attachments) {
                const { id, uploaded, uploading, uploadingStartTime } = attachment;
                const uploadingOverdue = isUploadingOverdue(uploadingStartTime);
                if (id && !isNil(uploaded) && !uploaded && (!uploading || uploadingOverdue)) {
                    addToUploadingQueue(attachment, question);
                }
            }
        }
    }

    return isNeedUpload;
}

function saveCustomReportOffline(report, status, dispatch, isShowMessage = true) {
    report.unsaved = true;
    if (!report.unsavedId) {
        report.unsavedId = generateId();
    }

    if (status) {
        report.status = status;
    }

    if (isShowMessage) {
        showMessage({
            message: 'Error',
            description:
                // eslint-disable-next-line max-len
                'You do not currently have a data connection. This site report will save as soon has you have connection',
            type: 'danger',
            icon: 'danger',
            position: 'right',
            hideStatusBar: true,
            duration: 1500,
            backgroundColor: Color.red,
        });
    }

    dispatch({
        type: CNST.CUSTOM_REPORT.SUBMIT_CUSTOM_REPORT.ERROR,
        report,
    });
}

export function getCustomers() {
    return (dispatch) => {
        return Api()
            .get(`/sources/${Config.CUSTOMER}/data`, { limit: 500 })
            .then((response) => {
                dispatch({
                    type: CNST.CUSTOM_REPORT.GET_CUSTOMERS,
                    data: response.data,
                });
                return response;
            })
            .catch((error) => {
                return error;
            });
    };
}

export function getCustomReports() {
    return (dispatch) => {
        return Api()
            .get(`/sources/${Config.CUSTOM_REPORTS}/data`, { limit: 500 })
            .then((response) => {
                dispatch({
                    type: CNST.CUSTOM_REPORT.GET_CUSTOM_REPORTS,
                    data: response.data,
                });
                return response;
            })
            .catch((error) => {
                return error;
            });
    };
}

export function getCompletedCustomReports() {
    return (dispatch) => {
        return Api()
            .get(`/sources/${Config.CUSTOM_REPORT_ANSWERS}/data`, { limit: 500 })
            .then((response) => {
                dispatch({
                    type: CNST.CUSTOM_REPORT.GET_COMPLETED_CUSTOM_REPORTS,
                    data: response.data,
                });
                return response;
            })
            .catch((error) => {
                return error;
            });
    };
}

export function createCustomReportItem(data = {}) {
    return (dispatch) => {
        return dispatch({
            type: CNST.CUSTOM_REPORT.CREATE_CUSTOM_REPORT,
            data: {
                User: data.user,
                Survey: data.report || [],
                Id: data.id || null,
                QuestionCategory: data.QuestionCategory || null,
            },
        });
    };
}

export function setReport(report) {
    return (dispatch) => {
        return dispatch({
            type: CNST.CUSTOM_REPORT.SET_CUSTOM_REPORT,
            data: report,
        });
    };
}

export function saveCustomReportItem(report, user) {
    let reportToSave = cloneDeep(report);

    return (dispatch, getState) => {
        dispatch({ type: CNST.CUSTOM_REPORT.SUBMIT_CUSTOM_REPORT.LOADING });

        const save = () => {
            const customReportAnswers = {
                CustomReportName: reportToSave.CustomReportName,
                Sections: reportToSave.Sections,
                User: user._id,
                QuestionCategory: reportToSave.QuestionCategory,
            };

            const isNew = isNil(reportToSave.id);

            return NetInfo.fetch().then((state) => {
                const { isConnected } = state;
                if (isConnected) {
                    return Api()
                        .put(`/sources/${Config.CUSTOM_REPORT_ANSWERS}/data/${isNew ? 'new' : report.id}`, {
                            data: customReportAnswers,
                            modifiedBy: user.username,
                        })
                        .then((response) => {
                            showMessage({
                                message: 'Success',
                                description: 'Your Site Survey has been uploaded',
                                type: 'success',
                                position: 'right',
                                hideStatusBar: true,
                                duration: 1500,
                                backgroundColor: Color.turquoise,
                            });

                            dispatch({
                                type: CNST.CUSTOM_REPORT.SUBMIT_CUSTOM_REPORT.SUCCESS,
                                item: response,
                                unsavedReportId: reportToSave.unsavedId,
                            });
                            return true;
                        })
                        .catch(() => {
                            saveCustomReportOffline(reportToSave, null, dispatch);
                            return true;
                        });
                }
                saveCustomReportOffline(reportToSave, null, dispatch);
                return true;
            });
        };

        const uploadStatus = uploadCheck(reportToSave);
        if (uploadStatus.isNeedUpload) {
            // save report in storage while images uploading
            saveCustomReportOffline(reportToSave, 'Attachments uploading', dispatch, false);
            reportToSave = cloneDeep(reportToSave);
            return saveReportImages(
                reportToSave,
                (attachmentId, report) => {
                    dispatch({
                        type: CNST.CUSTOM_REPORT.ATTACHMENT_UPLOAD.START,
                        attachmentId,
                        report,
                    });
                },
                (asset, attachmentId, report) => {
                    dispatch({
                        type: CNST.CUSTOM_REPORT.ATTACHMENT_UPLOAD.SUCCESS,
                        asset,
                        attachmentId,
                        report,
                    });
                },
                (attachmentId, report) => {
                    dispatch({
                        type: CNST.CUSTOM_REPORT.ATTACHMENT_UPLOAD.ERROR,
                        attachmentId,
                        report,
                    });
                },
                (success) => {
                    if (success) {
                        // getting the latest report info to save
                        const { completedCustomReportItems } = getState().customReports;
                        if (reportToSave.id) {
                            reportToSave = completedCustomReportItems.find((r) => r.id === reportToSave.id);
                        } else {
                            reportToSave = completedCustomReportItems.find(
                                (r) => r.unsavedId === reportToSave.unsavedId,
                            );
                        }
                        save();
                    }
                },
            );
        }

        if (uploadStatus.uploading) {
            saveCustomReportOffline(reportToSave, 'Attachments uploading', dispatch, false);
            return true;
        }

        return save();
    };
}

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = {
    customers: [],
    currentCustomReportItem: null,
    customReports: [],
    unsavedReports: [],
    loading: false,
    completedCustomReportItems: [],
};

export default produce((state, action) => {
    switch (action.type) {
        case CNST.CUSTOM_REPORT.GET_CUSTOMERS:
            state.customers = action.data;
            break;
        case CNST.CUSTOM_REPORT.CREATE_CUSTOM_REPORT:
            state.currentCustomReportItem = action.data;
            break;
        case CNST.CUSTOM_REPORT.GET_CUSTOM_REPORTS:
            state.customReports = action.data;
            break;
        case CNST.CUSTOM_REPORT.GET_COMPLETED_CUSTOM_REPORTS:
            state.completedCustomReportItems = action.data;
            break;
        case CNST.CUSTOM_REPORT.SET_CUSTOM_REPORT:
            state.currentCustomReportItem.Survey = action.data;
            break;
        case CNST.CUSTOM_REPORT.SUBMIT_CUSTOM_REPORT.LOADING:
            state.loading = true;
            break;

        case CNST.CUSTOM_REPORT.SUBMIT_CUSTOM_REPORT.SUCCESS: {
            const { item, unsavedReportId } = action;
            let found = false;
            state.completedCustomReportItems.forEach((report, i) => {
                if ((item.id && report.id === item.id) || (unsavedReportId && report.unsavedId === unsavedReportId)) {
                    state.completedCustomReportItems[i] = item;
                    found = true;
                }
            });
            if (!found) {
                state.completedCustomReportItems.push(item);
            }
            state.isLoading = false;
            break;
        }

        case CNST.CUSTOM_REPORT.SUBMIT_CUSTOM_REPORT.ERROR: {
            const { report } = action;
            let found = false;
            state.completedCustomReportItems.forEach((r, i) => {
                if ((report.id && report.id === r.id) || (report.unsavedId && report.unsavedId === r.unsavedId)) {
                    state.completedCustomReportItems[i] = report;
                    found = true;
                }
            });
            if (!found) {
                state.completedCustomReportItems.push(report);
            }
            state.isLoading = false;
            break;
        }

        case CNST.CUSTOM_REPORT.ATTACHMENT_UPLOAD.START: {
            const { report, attachmentId } = action;
            const { id, unsavedId } = report;

            const reportIndex = state.completedCustomReportItems.findIndex((r) => {
                return (unsavedId && r.unsavedId === unsavedId) || (id && r.id === id);
            });

            if (reportIndex >= 0) {
                const reportToUpdate = state.completedCustomReportItems[reportIndex];
                for (const section of reportToUpdate.Sections) {
                    let attachment = section.Attachments.find((a) => a.id === attachmentId);
                    if (attachment) {
                        attachment.uploading = true;
                        attachment.uploadingStartTime = new Date();
                        break;
                    }

                    for (const question of section.Questions) {
                        attachment = question.Attachments.find((a) => a.id === attachmentId);
                        if (attachment) {
                            attachment.uploading = true;
                            attachment.uploadingStartTime = new Date();
                            break;
                        }
                    }

                    if (attachment) {
                        break;
                    }
                }
            }
            break;
        }

        case CNST.CUSTOM_REPORT.ATTACHMENT_UPLOAD.ERROR: {
            const { report, attachmentId } = action;
            const { id, unsavedId } = report;

            const reportIndex = state.completedCustomReportItems.findIndex((r) => {
                return (unsavedId && r.unsavedId === unsavedId) || (id && r.id === id);
            });

            if (reportIndex >= 0) {
                const reportToUpdate = state.completedCustomReportItems[reportIndex];
                for (const section of reportToUpdate.Sections) {
                    let attachment = section.Attachments.find((a) => a.id === attachmentId);
                    if (attachment) {
                        attachment.uploading = false;
                        attachment.uploadingStartTime = null;
                        break;
                    }
                    for (const question of section.Questions) {
                        attachment = question.Attachments.find((a) => a.id === attachmentId);
                        if (attachment) {
                            attachment.uploading = false;
                            attachment.uploadingStartTime = null;
                            break;
                        }
                    }
                    if (attachment) {
                        break;
                    }
                }
            }
            break;
        }

        case CNST.CUSTOM_REPORT.ATTACHMENT_UPLOAD.SUCCESS: {
            const { asset, report, attachmentId } = action;
            const { id, unsavedId } = report;

            const reportIndex = state.completedCustomReportItems.findIndex((r) => {
                return (unsavedId && r.unsavedId === unsavedId) || (id && r.id === id);
            });

            if (reportIndex >= 0) {
                const reportToUpdate = state.completedCustomReportItems[reportIndex];
                for (const section of reportToUpdate.Sections) {
                    let attachmentIndex = section.Attachments.findIndex((a) => a.id === attachmentId);
                    if (attachmentIndex >= 0) {
                        section.Attachments[attachmentIndex] = {
                            Attachment: asset.file.url,
                            AttachmentAssetId: asset._id,
                        };
                        break;
                    }
                    for (const question of section.Questions) {
                        attachmentIndex = question.Attachments.findIndex((a) => a.id === attachmentId);
                        if (attachmentIndex >= 0) {
                            question.Attachments[attachmentIndex] = {
                                Attachment: asset.file.url,
                                AttachmentAssetId: asset._id,
                            };
                            break;
                        }
                    }
                    if (attachmentIndex >= 0) {
                        break;
                    }
                }
            }
            break;
        }

        default:
            break;
    }
}, initialState);
