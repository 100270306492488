export default {
    SELECT_TIMECARD: 'SELECT_TIMECARD_EDIT',
    SAVE_TIMECARD: {
        LOADING: 'SAVE_TIMECARD_LOADING',
        SUCCESS: 'SAVE_TIMECARD_SUCCESS',
        ERROR: 'SAVE_TIMECARD_ERROR',
    },
    SET_PROJECT: 'SET_TIMECARD_EDIT_PROJECT',
    SET_COSTCODE: 'SET_TIMECARD_EDIT_COSTCODE',
    CLEAN_UP: 'TIMECARD_EDIT_CLEAN_UP',
    CHANGE: 'TIMECARD_EDIT_CHANGE',
    CHANGE_BREAKS: 'TIMECARD_EDIT_CHANGE_BREAKS',
    GET_ITEMS: {
        LOADING: 'GET_ITEMS_LOADING',
        SUCCESS: 'GET_ITEMS_SUCCESS',
        ERROR: 'GET_ITEMS_ERROR',
    },
    SELECT_START_TIME: 'TIMECARD_EDIT_CHANGE_BULK_TIME_CARD_START_TIME',
    SELECT_END_TIME: 'TIMECARD_EDIT_CHANGE_BULK_TIME_CARD_END_TIME',
    ADD_DATES: 'TIMECARD_EDIT_ADD_DATES',
    BULK_EDIT_CHANGE: 'TIMECARD_BULK_EDIT_CHANGE',
    CHANGE_BULK_BREAKS: 'TIMECARD_EDIT_CHANGE_BULK_BREAKS',
    CLEAR_BULK_TIME_ENTIRES: 'TIMECARD_EDIT_CLEAR_BULK_TIME_ENTIRES',
    ADD_EXTRA_DAY: 'TIMECARD_EDIT_ADD_EXTRA_DAY',
    REMOVE_EXTRA_DAY: 'TIMECARD_EDIT_REMOVE_EXTRA_DAY',
};
