import { StyleSheet, Platform } from 'react-native';
import { Color, Font } from '../../../../theme';

const isIos = Platform.OS === 'ios';

export default StyleSheet.create({
    modalWrapper: {
        width: '100%',
        height: '100%',
        backgroundColor: Color.dark_navy_blue_50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalBlock: {
        backgroundColor: Color.white,
        borderRadius: 10,
        width: '90%',
        padding: 15,
        paddingTop: 25,
        minHeight: isIos ? 250 : 222,
    },
    modalTitle: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font12,
        color: Color.purple_brown,
        marginLeft: isIos ? 0 : 5,
        marginBottom: 5,
    },
    modalContent: {
        width: '100%',
        height: 110,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    },
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 20,
    },
    submitModalButton: {
        width: 180,
    },
    cancelModalButton: {
        width: 60,
        marginRight: 25,
        marginTop: 18,
    },
    timesRow: {
        paddingTop: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        zIndex: 2,
    },
    materialDropdown: {
        backgroundColor: Color.white,
        borderRadius: 6,
        minHeight: 0,
        marginBottom: 10,
        elevation: 5,
        paddingHorizontal: 10,
        flexDirection: 'column',
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    materialDropdown_half: {
        marginBottom: 10,
        width: '40%',
    },
});
