import React, { PureComponent } from 'react';
import { string, bool } from 'prop-types';
import { View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

// import icoMoonConfig from '../../fonts/selection.json';
import s from './styles';

// const Icon = createIconSetFromIcoMoon(icoMoonConfig);

export default class TabBarIcon extends PureComponent {
    static propTypes = {
        tintColor: string,
        iconName: string,
        focused: bool,
    };

    render() {
        const { tintColor, iconName, focused } = this.props;
        return (
            <View style={[s.tab, focused && s.tabActive]}>
                <View style={[s.tabIconWrapper, focused && s.tabIconWrapperActive]}>
                    <Icon name={iconName} style={!focused ? s.tabIcon : s.tabIconActive} color={tintColor} />
                </View>
            </View>
        );
    }
}
