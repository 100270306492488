import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Text, TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Color } from '../../theme';

import s from './styles';

const OutstandingItemDialog = ({ isOpen, onCancel, onPress, text, goButtonText, cancelButtonText }) => {
    return (
        <Modal visible={isOpen} transparent>
            <View style={s.modalWrapper}>
                <TouchableOpacity
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                    }}
                />
                <View style={s.attachmentModal}>
                    <Text style={s.attachmentModalTitle}>{text}</Text>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 20,
                            paddingHorizontal: 20,
                            alignItems: 'center',
                        }}
                    >
                        <View style={{ width: '45%' }}>
                            <TouchableOpacity style={s.modalButton} onPress={() => onPress()}>
                                <Icon
                                    name="clipboard-check-outline"
                                    size={110}
                                    color={Color.light_navy}
                                    style={s.modalButtonIcon}
                                />
                                <Text style={s.modalButtonText}>{goButtonText}</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{ width: '45%' }}>
                            <TouchableOpacity style={s.modalButton} onPress={() => onCancel()}>
                                <Icon
                                    name="folder-clock-outline"
                                    size={110}
                                    color={Color.light_navy}
                                    style={s.modalButtonIcon}
                                />
                                <Text style={s.modalButtonText}>{cancelButtonText}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View>
        </Modal>
    );
};

OutstandingItemDialog.propTypes = {
    isOpen: PropTypes.bool,
    text: PropTypes.string,
    goButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,

    onCancel: PropTypes.func,
    onPress: PropTypes.func,
};

export default OutstandingItemDialog;
