import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    buttonWrapper: {
        alignItems: 'center',
        textTransform: 'uppercase',
    },
    buttonText: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.turquoise,
        textAlign: 'center',
    },
});
