import { StyleSheet, Platform } from 'react-native';
import { Color, Font } from '../../../theme';

export default StyleSheet.create({
    mainContainer: {
        height: '100%',
        width: '100%',
    },
    menuContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        paddingHorizontal: 15,
    },
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_three,
        overflow: 'auto',
    },
    listItemWrapper: {
        width: '80%',
        paddingVertical: 20,
        marginVertical: 20,
        marginHorizontal: 'auto',
        backgroundColor: Color.white,
        borderRadius: 6,
        elevation: 5,
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    headerDate: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.blue,
        marginLeft: 17,
    },
    headerTotalTime: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font11,
        color: Color.light_navy,
        paddingLeft: '55%',
    },
    notSigned: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font9,
        color: Color.light_blue_grey,
        marginTop: 11.5,
        marginLeft: 17,
    },
    projectName: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue,
        marginTop: 12,
        marginLeft: 17,
    },
    activeTab: {
        width: '40%',
        textAlign: 'center',
        marginLeft: '-10%',
        fontFamily: Font.type.bold,
        fontSize: Font.size.font11,
        color: Color.dark_navy_blue,
    },
    inactiveTab: {
        width: '40%',
        textAlign: 'center',
        marginLeft: '20%',
        fontFamily: Font.type.bold,
        fontSize: Font.size.font11,
        color: Color.light_grey_blue,
    },
    noOutstanding: {
        width: '100%',
        paddingLeft: '25%',
        paddingTop: '20%',
        paddingRight: '25%',
        alignItems: 'center',
    },
    noOutstandingText: {
        textAlign: 'center',
        fontFamily: Font.type.bold,
        fontSize: Font.size.font18,
        color: '#7f98b4',
    },
    formBlockTotal_border: {
        borderBottomWidth: 2,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderRadius: 3,
        borderStyle: 'dotted',
        borderColor: Color.pale_grey_two,
        width: '100%',
        marginTop: 12,
        marginBottom: 14.5,
    },
    weekDay: {
        flexDirection: 'row',
        width: '90%',
        marginLeft: '5%',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 19.5,
    },
    weekDayDate: {
        fontFamily: Font.type.regular,
        color: Color.light_grey_blue,
        fontSize: Font.size.font12,
        marginRight: 10,
    },
    weekDayName: {
        fontFamily: Font.type.regular,
        color: Color.light_navy,
        fontSize: Font.size.font12,
    },
    weekDayTime: {
        fontFamily: Font.type.regular,
        color: Color.light_navy,
        fontSize: Font.size.font11,
    },
    weekDayTime_inactive: {
        color: Color.light_grey_blue,
    },
    buttonWrapper: {
        width: '70%',
        marginLeft: '15%',
        paddingBottom: '2%',
    },
    heading: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 18,
    },
    reg: {
        fontSize: Font.size.font10,
        color: '#b2becc',
        marginRight: '-10%',
        alignItems: 'flex-end',
    },
    pto: {
        paddingRight: '8.5%',
        fontSize: Font.size.font10,
        color: '#b2becc',
        alignItems: 'flex-end',
    },
    weekDayTimePto: {
        fontFamily: Font.type.regular,
        color: Color.light_navy,
        fontSize: Font.size.font11,
        alignItems: 'flex-end',
        paddingLeft: '-20%',
    },
    weekDayTime_inactivePto: {
        color: Color.light_grey_blue,
        alignItems: 'flex-end',
        paddingRight: '5%',
    },
    weekDayTimeReg: {
        fontFamily: Font.type.regular,
        color: Color.light_navy,
        fontSize: Font.size.font11,
        alignItems: 'flex-end',
        marginRight: '-5%',
    },
    weekDayTime_inactiveReg: {
        color: Color.light_grey_blue,
        alignItems: 'flex-end',
        marginRight: '-5%',
    },
    weekDayTimeRegPadding: {
        fontFamily: Font.type.regular,
        color: Color.light_navy,
        fontSize: Font.size.font11,
        alignItems: 'flex-end',
        marginRight: '-7%',
    },
    weekDayTime_inactiveRegPadding: {
        color: Color.light_grey_blue,
        alignItems: 'flex-end',
        marginRight: '-7%',
    },
    totalTimes: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: '3%',
        borderBottomWidth: 2,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderRadius: 3,
        borderStyle: 'dotted',
        borderColor: Color.pale_grey_two,
    },
    totalTimeReg: {
        color: Color.light_navy,
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
        marginRight: '-11%',
        alignItems: 'flex-end',
    },
    totalTimePto: {
        color: Color.light_navy,
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
        paddingRight: '8.5%',
        alignItems: 'flex-end',
    },
});
