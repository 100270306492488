import React, { Component } from 'react';
import { View } from 'react-native';

import { Field, reduxForm } from 'redux-form';
import { func, object, bool } from 'prop-types';

import { FieldInput, PrimaryButton } from '../../components';
import { required, email } from '../../utils/fieldValidation';

import s from './styles';

class LoginForm extends Component {
    static propTypes = {
        handleSubmit: func,
        handleLogin: func,
        user: object,
        isLoading: bool,
        containerStyle: object,
    };

    buttonDisable() {
        const { isLoading, handleSubmit, handleLogin, user } = this.props;
        return (
            <PrimaryButton
                title="SIGN IN"
                onPress={handleSubmit(handleLogin)}
                isLoading={isLoading}
                disabled={isLoading || !!user}
                icon="arrow-right"
            />
        );
    }

    render() {
        const { containerStyle } = this.props;

        return (
            <View style={[s.wrapper, { ...containerStyle }]}>
                <Field
                    name="email"
                    title="EMAIL"
                    autoFocus
                    type="email-address"
                    keyboardType="email-address"
                    component={FieldInput}
                    autoCorrect={false}
                    autoCapitalize="none"
                    validate={[required, email]}
                />
                <Field
                    name="password"
                    title="PASSWORD"
                    type="password"
                    component={FieldInput}
                    autoCapitalize="none"
                    validate={required}
                />
                {this.buttonDisable()}
            </View>
        );
    }
}

export default reduxForm({
    form: 'login',
})(LoginForm);
