import { Platform, StyleSheet } from 'react-native';
import { Font, Color } from '../../theme';

const isIos = Platform.OS === 'ios';
export default StyleSheet.create({
    tabIcon: {
        fontSize: isIos ? Font.size.font21 : Font.size.font17,
        paddingTop: 5,
    },
    tabIconActive: {
        fontSize: isIos ? Font.size.font26 : Font.size.font22,
    },
    tab: {
        backgroundColor: Color.white,
        width: 30,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    tabActive: {
        borderRadius: isIos ? 50 : 25,
        width: isIos ? 55 : 50,
        height: isIos ? 55 : 50,
        marginTop: isIos ? -20 : -10,
    },
    tabIconWrapper: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    tabIconWrapperActive: {
        backgroundColor: Color.blue,
        borderRadius: 25,
        width: isIos ? 45 : 35,
        height: isIos ? 45 : 35,
        shadowColor: Color.light_grey_blue,
        shadowOffset: { width: 0, height: 4 },
        shadowRadius: 5,
        elevation: 8,
    },
});
