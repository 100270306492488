import moment from 'moment';
import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import s from './styles';
import { Color } from '../../../../theme';

const ListItem = (props) => {
    const { item } = props;
    
    return (
        <TouchableOpacity onPress={item.onPress}>
            <View style={[s.listItemWrapper, item.note && s.listItemWrapper_comment]}>
                <View style={s.listItemBody}>
                    <Text numberOfLines={1} style={s.listItemTitle}>
                        {item.CustomReportName}
                    </Text>
                    {item.status ? (
                        <View>
                            <Text style={s.cardTypeStatusText}>{item.status.toUpperCase()}</Text>
                        </View>
                    ) : null}
                    {item.unsaved ? (
                        <View>
                            <Text style={[s.cardTypeOfflineText]}>SAVED OFFLINE</Text>
                        </View>
                    ) : null}
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={s.listItemDate}>{moment(item.timeCreated).format('MM/DD/YYYY')}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <Icon name="pencil" size={28} color={Color.turquoise} />
                </View>
            </View>
        </TouchableOpacity>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
};

export default ListItem;
