import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text, TouchableOpacity, View, BackHandler } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { isEmpty } from 'lodash';
import {
    addBulkTimeCardBreak,
    changeBulkTimeCardBreak,
    changeBulkTimeCardEdit,
    cleanTimeCardEdit,
    removeBulkTimeCardBreak,
    saveTimeCardEdit,
    changeBulkTimeCardStartDate,
    changeBulkTimeCardEndDate,
    addBulkTimeCardEdits,
    clearBulkTimeCardEdits,
    addBulkTimeCardEditsAddExtraDay,
    removeBulkTimeCardEditsRemoveExtraDay,
} from '../../store/modules/timeCardEdit';
import { userLogin } from '../../store/modules/account';
import {
    FieldInput,
    ListItem,
    NavigationBar,
    PrimaryButton,
    ConfirmationDialog,
    DropdownCalendar,
} from '../../components';
import { Config } from '../../config';
import { Color } from '../../theme/index';
import s from './styles';

const timeArray = [];
for (let i = 0; i < 24; i++) {
    const item = moment()
        .utcOffset(0)
        .set({
            hour: i,
            minute: 0,
            second: 0,
            millisecond: 0,
        })
        .format('HH:MM a');
    timeArray.push(item);
}
class BulkTimeCardEditScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        timeCardEdit: PropTypes.object,
        user: PropTypes.object,
        projects: PropTypes.array,
        costCodes: PropTypes.array,
        timeCards: PropTypes.array,
        cleanTimeCardEdit: PropTypes.func,
        saveTimeCardEdit: PropTypes.func,
        changeBulkTimeCardEdit: PropTypes.func,
        addBulkTimeCardBreak: PropTypes.func,
        removeBulkTimeCardBreak: PropTypes.func,
        changeBulkTimeCardBreak: PropTypes.func,
        generalRules: PropTypes.object,
        addBulkTimeCardEdits: PropTypes.func,
        changeBulkTimeCardStartDate: PropTypes.func,
        changeBulkTimeCardEndDate: PropTypes.func,
        payPeriods: PropTypes.array,
        clearBulkTimeCardEdits: PropTypes.func,
        addBulkTimeCardEditsAddExtraDay: PropTypes.func,
        removeBulkTimeCardEditsRemoveExtraDay: PropTypes.func,
        userLogin: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            confirmationDialog: false,
            confirmationText: '',
        };
    }

    componentDidMount() {
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
            this.navigationBar.goBack();
            return true;
        });
    }

    componentWillUnmount() {
        this.backHandler.remove();
    }

    componentDidUpdate(prevProps) {
        const { timeCardEdit, addBulkTimeCardEdits } = this.props;
        const { bulkTimeCardEditsStartDate, bulkTimeCardEditsEndDate } = timeCardEdit;

        const prevBulkTimeCardEditsStartDate = prevProps.timeCardEdit.bulkTimeCardEditsStartDate;
        const prevBulkTimeCardEditsEndDate = prevProps.timeCardEdit.bulkTimeCardEditsEndDate;

        if (
            (bulkTimeCardEditsEndDate !== null && prevBulkTimeCardEditsEndDate === null) ||
            bulkTimeCardEditsStartDate !== prevBulkTimeCardEditsStartDate ||
            bulkTimeCardEditsEndDate !== prevBulkTimeCardEditsEndDate
        ) {
            addBulkTimeCardEdits();
        }
    }

    returnCostCodeTitle(code) {
        const { costCodes } = this.props;
        const costCode = costCodes.find((c) => c.id === code);
        if (costCode) {
            return `${costCode.Code} ${costCode.Title}`;
        }
        return '';
    }

    returnProjectTitle(projectId, projectDetails) {
        const { projects } = this.props;
        let project = null;
        let projectNameString = null;
        if (projectDetails) {
            project = projectDetails;
        } else {
            project = projects.find((c) => c.id === projectId);
        }

        if (project) {
            const { ProjectNumber, ProjectName } = project;

            if (ProjectNumber && ProjectName) {
                projectNameString = `${ProjectNumber}-${ProjectName}`;
            }
            if (ProjectNumber) {
                projectNameString = ProjectNumber;
            }
            if (ProjectName) {
                projectNameString = ProjectName;
            }
        }

        return projectNameString;
    }

    returnClockInItemsArray(index) {
        const { navigation, timeCardEdit } = this.props;
        const { bulkTimeCardEdits } = timeCardEdit;
        const list = {
            project: {
                title:
                    typeof index !== 'undefined' &&
                    typeof bulkTimeCardEdits !== 'undefined' &&
                    !isEmpty(bulkTimeCardEdits[index].project)
                        ? !isEmpty(bulkTimeCardEdits[index].projectName)
                            ? bulkTimeCardEdits[index].projectName
                            : this.returnProjectTitle(
                                  bulkTimeCardEdits[index].project,
                                  bulkTimeCardEdits[index].projectDetails,
                              )
                        : '',
                required: !bulkTimeCardEdits[index].project,
                inactiveText: isEmpty(bulkTimeCardEdits[index].project),
                editScreen: true,
                onPress: (i) => {
                    navigation.navigate('BulkTimeCardEditProjects', {
                        standalone: false,
                        isTimeCardBulkEdit: true,
                        index: i,
                    });
                },
            },
            costCode: {
                title:
                    typeof index !== 'undefined' &&
                    typeof bulkTimeCardEdits !== 'undefined' &&
                    !isEmpty(bulkTimeCardEdits[index].costCode)
                        ? this.returnCostCodeTitle(bulkTimeCardEdits[index].costCode)
                        : '',
                inactiveText: isEmpty(bulkTimeCardEdits[index].costCode),
                editScreen: true,
                isDisabled: isEmpty(bulkTimeCardEdits[index].project),
                onPress: (i) => {
                    navigation.navigate('BulkTimeCardEditCostCodes', { isTimeCardBulkEdit: true, index: i });
                },
            },
        };

        return list;
    }

    // return seconds
    returnTotalBreak(index) {
        const { timeCardEdit } = this.props;
        const { bulkTimeCardEdits } = timeCardEdit;

        let time = 0;

        if (typeof bulkTimeCardEdits !== 'undefined') {
            const { breaks } = bulkTimeCardEdits[index];

            if (!isEmpty(breaks)) {
                breaks.forEach((b) => {
                    time += moment(b.EndTime).diff(moment(b.StartTime), 'seconds');
                });
            }
        }
        return time;
    }

    returnTotalClockInTime(index) {
        const { timeCardEdit } = this.props;
        const { bulkTimeCardEdits } = timeCardEdit;
        let time = '';
        const totalBreak = this.returnTotalBreak(index);
        let totalTime = null;
        if (
            typeof bulkTimeCardEdits !== 'undefined' &&
            !!bulkTimeCardEdits[index].clockIn &&
            !!bulkTimeCardEdits[index].clockOut
        ) {
            totalTime =
                moment(bulkTimeCardEdits[index].clockOut).diff(moment(bulkTimeCardEdits[index].clockIn), 'seconds') -
                totalBreak;
            totalTime = moment().startOf('day').add(totalTime, 'seconds');

            let totalHours = totalTime.hours();
            let totalMinutes = totalTime.minutes();

            if (totalHours < 10) {
                totalHours = `0${totalHours}`;
            }

            if (totalMinutes < 10) {
                totalMinutes = `0${totalMinutes}`;
            }

            time = `${totalHours}:${totalMinutes}`;
        }

        if (totalTime) {
            return time;
        }

        return '00:00';
    }

    returnTotalBreakTime(index) {
        let time = '';
        const totalBreak = this.returnTotalBreak(index);
        if (totalBreak === 0) {
            time = '00:00';
        } else if (totalBreak < 60) {
            time = `00:${totalBreak > 10 ? totalBreak : `0${totalBreak}`}`;
        } else {
            let hours = Math.trunc(totalBreak / 60);
            let minutes = totalBreak % 60;

            if (hours < 10) {
                hours = `0${hours}`;
            }

            if (minutes < 10) {
                minutes = `0${minutes}`;
            }

            time = `${hours}:${minutes}`;
        }
        return time;
    }

    // -- GENERAL --
    close() {
        this.setState({ confirmationDialog: false, confirmationText: '' });
    }

    addExtraDay = (day, index) => {
        const { addBulkTimeCardEditsAddExtraDay } = this.props;

        addBulkTimeCardEditsAddExtraDay(day, index);
    };

    removeExtraDay = (index) => {
        const { removeBulkTimeCardEditsRemoveExtraDay } = this.props;

        removeBulkTimeCardEditsRemoveExtraDay(index);
    };

    onSave = () => {
        const { user, timeCardEdit, saveTimeCardEdit, navigation, timeCards, clearBulkTimeCardEdits } = this.props;
        const { bulkTimeCardEdits } = timeCardEdit;

        let userError = false;

        const projectLabel = Config.USE_DEPARTMENTS_JOBS ? 'department' : 'project';

        for (const bulkTimeCardEdit of bulkTimeCardEdits) {
            // validate date
            if (!bulkTimeCardEdit.date) {
                this.setState({
                    confirmationDialog: true,
                    confirmationText: 'Please enter time card date',
                });
                userError = true;
            }

            // validate project
            if (!userError && !bulkTimeCardEdit.project) {
                this.setState({
                    confirmationDialog: true,
                    confirmationText: `Please enter time card ${projectLabel}`,
                });
                userError = true;
            }

            // validate clock in time
            if (!userError && !bulkTimeCardEdit.clockIn) {
                this.setState({
                    confirmationDialog: true,
                    confirmationText: 'Please enter time card clock in time',
                });
                userError = true;
            }

            // validate clock out time
            if (!userError && !bulkTimeCardEdit.clockOut) {
                this.setState({
                    confirmationDialog: true,
                    confirmationText: 'Please enter time card clock out time',
                });
                userError = true;
            }

            if (!userError && (bulkTimeCardEdit.note.length <= 4 || bulkTimeCardEdit.note.trim().length === 0)) {
                this.setState({
                    confirmationDialog: true,
                    confirmationText:
                        "Please enter a reason why in the comment box. It must be greater than 5 characters and can't be spaces",
                });
                userError = true;
            }

            const editTimeCardStartTime = moment(bulkTimeCardEdit.clockIn).toISOString();
            const editTimeCardEndTime = moment(bulkTimeCardEdit.clockOut).toISOString();
            if (!userError) {
                // validate break times
                for (let i = 0; i < bulkTimeCardEdit.breaks.length; i++) {
                    const editTimeCardStartBreakTime = moment(bulkTimeCardEdit.breaks[i].StartTime).toISOString();
                    const editTimeCardEndBreakTime = moment(bulkTimeCardEdit.breaks[i].EndTime).toISOString();
                    // break time is within start and end time
                    if (
                        !moment(editTimeCardStartBreakTime).isBetween(editTimeCardStartTime, editTimeCardEndTime) ||
                        !moment(editTimeCardEndBreakTime).isBetween(editTimeCardStartTime, editTimeCardEndTime)
                    ) {
                        this.setState({
                            confirmationDialog: true,
                            confirmationText:
                                'One of your break time(s) is not within your clock in and clock out time',
                        });
                        userError = true;
                        break;
                    }
                    if (editTimeCardStartBreakTime > editTimeCardEndBreakTime) {
                        this.setState({
                            confirmationDialog: true,
                            confirmationText: 'Please enter an end break time that is after the start break time.',
                        });
                        userError = true;
                    }
                    if (editTimeCardStartBreakTime === editTimeCardEndBreakTime) {
                        this.setState({
                            confirmationDialog: true,
                            confirmationText:
                                'Please make sure your break time(s) are more than at least a minute apart.',
                        });
                        userError = true;
                    }
                }
            }

            if (!userError) {
                for (const timeCard of timeCards) {
                    const currentTimeCardStartTime = moment(timeCard.StartTime).toISOString();
                    const currentTimeCardEndTime = moment(timeCard.EndTime).toISOString();
                    // validate that we do not have a overlap in the time cards.
                    if (
                        timeCardEdit.timeCardDayId !== timeCard.WorkTimeId &&
                        (editTimeCardStartTime === currentTimeCardStartTime ||
                            editTimeCardEndTime === currentTimeCardEndTime ||
                            moment(editTimeCardStartTime).isBetween(currentTimeCardStartTime, currentTimeCardEndTime) ||
                            moment(editTimeCardEndTime).isBetween(currentTimeCardStartTime, currentTimeCardEndTime))
                    ) {
                        this.setState({
                            confirmationDialog: true,
                            confirmationText:
                                'You have selected a time range that conflicts with another time card. Please try again.',
                        });
                        userError = true;
                        break;
                    }
                    if (editTimeCardStartTime > editTimeCardEndTime) {
                        this.setState({
                            confirmationDialog: true,
                            confirmationText: 'Please enter an end time that is after the start time.',
                        });
                        userError = true;
                        break;
                    }
                }
            }

            if (userError) {
                break;
            }
        }

        for (let i = 0; i < bulkTimeCardEdits.length; i++) {
            for (let j = 0; j < bulkTimeCardEdits.length; j++) {
                if (i !== j) {
                    let entry1 = bulkTimeCardEdits[i];
                    let entry2 = bulkTimeCardEdits[j];
                    let clockIn1 = new Date(entry1.clockIn);
                    let clockOut1 = new Date(entry1.clockOut);
                    let clockIn2 = new Date(entry2.clockIn);
                    let clockOut2 = new Date(entry2.clockOut);
                    if (clockIn1 < clockOut2 && clockOut1 > clockIn2) {
                        this.setState({
                            confirmationDialog: true,
                            confirmationText: 'One of the date and time has a overlap ',
                        });
                        userError = true;

                        break;
                    }
                }
            }
        }

        if (!userError) {
            let index = 0;
            for (const bulkTimeCardEdit of bulkTimeCardEdits) {
                const totalTime = this.returnTotalClockInTime(index);
                const projectNameString = this.returnProjectTitle(
                    bulkTimeCardEdit.project,
                    bulkTimeCardEdit.projectDetails,
                );
                index = index + 1;
                saveTimeCardEdit(user, bulkTimeCardEdit, totalTime, projectNameString).then((resp) => {
                    if (resp && bulkTimeCardEdits.length === index) {
                        clearBulkTimeCardEdits();
                        navigation.navigate('TimeCards');
                    }
                });
            }
        }
    };

    validateStartAndEndDateRange = (date, isStartDate) => {
        const { changeBulkTimeCardStartDate, changeBulkTimeCardEndDate } = this.props;

        if (isStartDate) {
            changeBulkTimeCardStartDate(date);
        } else {
            changeBulkTimeCardEndDate(date);
        }
    };

    onBack = () => {
        const { navigation, cleanTimeCardEdit } = this.props;
        cleanTimeCardEdit();
        navigation.navigate('TimeCards');
    };

    render() {
        const {
            timeCardEdit,
            changeBulkTimeCardEdit,
            addBulkTimeCardBreak,
            removeBulkTimeCardBreak,
            changeBulkTimeCardBreak,
            generalRules,
            changeBulkTimeCardStartDate,
            changeBulkTimeCardEndDate,
            payPeriods,
        } = this.props;
        const { isLoading, bulkTimeCardEditsStartDate, bulkTimeCardEditsEndDate, bulkTimeCardEdits } = timeCardEdit;

        const { confirmationDialog, confirmationText } = this.state;

        const backConfirmText =
            // eslint-disable-next-line max-len
            'You are about to back out of your time card editing. All not saved data will be lost. Are you sure you want to do this?';
        const backTitle = 'Warning';
        const projectLabel = Config.USE_DEPARTMENTS_JOBS ? 'DEPARTMENT' : 'PROJECT';
        const costCodeLabel = Config.USE_DEPARTMENTS_JOBS ? 'JOB' : 'COST CODE';

        let lastBeforeClosed = null;

        for (let i = payPeriods.length - 1; i >= 0; i--) {
            if (payPeriods[i].IsClosed) {
                break;
            } else {
                lastBeforeClosed = payPeriods[i];
            }
        }
        if (lastBeforeClosed === null && payPeriods.length > 0) {
            lastBeforeClosed = payPeriods[payPeriods.length - 1];
        }

        return (
            <View style={[s.wrapper]}>
                <NavigationBar
                    ref={(c) => {
                        this.navigationBar = c;
                    }}
                    {...this.props}
                    title={'Bulk Time Card Entire'}
                    backIcon
                    backConfirm={{
                        title: backTitle,
                        text: backConfirmText,
                    }}
                    backIconFunction={this.onBack}
                />
                <View style={s.mainContainer}>
                    <View style={s.dropdownCalendarWrapper}>
                        <DropdownCalendar
                            containerStyle={[s.materialDropdown]}
                            date={bulkTimeCardEditsStartDate}
                            onSelect={(date) => changeBulkTimeCardStartDate({ date })}
                            placeholder="Start Date Range"
                            height={89}
                            topLabel
                            mode="date"
                            range={{
                                to: new Date(),
                                from: new Date(lastBeforeClosed.StartDate),
                            }}
                            type="1"
                            startTime={new Date(lastBeforeClosed.StartDate)}
                            EndTime={new Date()}
                        />
                    </View>
                    {bulkTimeCardEditsStartDate ? (
                        <View style={s.dropdownCalendarWrapper}>
                            <DropdownCalendar
                                containerStyle={[s.materialDropdown]}
                                date={bulkTimeCardEditsEndDate}
                                onSelect={(date) => changeBulkTimeCardEndDate({ date })}
                                placeholder="End Date Range"
                                height={89}
                                topLabel
                                mode="date"
                                range={{
                                    to: new Date(),
                                    from: bulkTimeCardEditsStartDate,
                                }}
                                type="1"
                                startTime={bulkTimeCardEditsStartDate}
                                EndTime={new Date()}
                            />
                        </View>
                    ) : null}

                    {bulkTimeCardEditsStartDate && bulkTimeCardEditsEndDate
                        ? bulkTimeCardEdits.map((edit, index) => (
                              <View style={s.block} key={index}>
                                  <Text style={s.date}>{moment(edit.date).format('MM/DD/YY')}</Text>
                                  <ListItem
                                      item={this.returnClockInItemsArray(index).project}
                                      label={projectLabel}
                                      index={index}
                                  />
                                  <ListItem
                                      item={this.returnClockInItemsArray(index).costCode}
                                      label={costCodeLabel}
                                      index={index}
                                  />

                                  <View style={s.timesRow}>
                                      <View style={s.materialDropdown_half}>
                                          <DropdownCalendar
                                              containerStyle={[s.materialDropdown]}
                                              date={
                                                  !!bulkTimeCardEdits[index].date &&
                                                  moment(bulkTimeCardEdits[index].clockIn).isValid()
                                                      ? moment(bulkTimeCardEdits[index].clockIn).toDate()
                                                      : null
                                              }
                                              onSelect={(d) => {
                                                  let clockIn = moment(d);
                                                  clockIn = moment(bulkTimeCardEdits[index].date).set({
                                                      hour: clockIn.hours(),
                                                      minute: clockIn.minutes(),
                                                  });
                                                  clockIn = clockIn.second(0).toISOString();
                                                  changeBulkTimeCardEdit({ clockIn, index });
                                              }}
                                              placeholder={
                                                  bulkTimeCardEdits[index].clockIn
                                                      ? `CLOCK IN TIME ${moment(
                                                            bulkTimeCardEdits[index].clockIn,
                                                        ).format('MM/DD/YY')}`
                                                      : 'CLOCK IN TIME'
                                              }
                                              height={89}
                                              mode="time"
                                              type="3"
                                              range={{
                                                  from: bulkTimeCardEdits[index].clockIn,
                                                  to: bulkTimeCardEdits[index].clockOut,
                                              }}
                                              topLabel
                                          />
                                      </View>

                                      <View style={s.materialDropdown_half}>
                                          <DropdownCalendar
                                              containerStyle={[s.materialDropdown]}
                                              date={
                                                  !!bulkTimeCardEdits[index].clockOut &&
                                                  moment(bulkTimeCardEdits[index].clockOut).isValid()
                                                      ? moment(bulkTimeCardEdits[index].clockOut).toDate()
                                                      : null
                                              }
                                              onSelect={(d) => {
                                                  let clockOut = moment(d);
                                                  clockOut = moment(bulkTimeCardEdits[index].date).set({
                                                      hour: clockOut.hours(),
                                                      minute: clockOut.minutes(),
                                                  });
                                                  clockOut = clockOut.second(0).toISOString();
                                                  changeBulkTimeCardEdit({ clockOut, index });
                                              }}
                                              placeholder={
                                                  bulkTimeCardEdits[index].clockOut
                                                      ? `CLOCK OUT TIME ${moment(
                                                            bulkTimeCardEdits[index].clockOut,
                                                        ).format('MM/DD/YY')}`
                                                      : 'CLOCK OUT TIME'
                                              }
                                              height={89}
                                              mode="time"
                                              type="3"
                                              range={{
                                                  from: bulkTimeCardEdits[index].clockIn,
                                                  to: bulkTimeCardEdits[index].clockOut,
                                              }}
                                              topLabel
                                          />
                                      </View>
                                  </View>
                                  <View style={s.rangeDaysContainer}>
                                      <View style={s.dateRow}>
                                          <Text style={s.dateRowTitle}>BREAKS</Text>
                                          <Text style={s.dateRowHours}>{this.returnTotalBreakTime(index)}</Text>
                                      </View>
                                      {!isEmpty(bulkTimeCardEdits[index].breaks) &&
                                          bulkTimeCardEdits[index].breaks.map((b, i) => {
                                              return (
                                                  <View
                                                      style={[
                                                          s.timesRow,
                                                          { zIndex: bulkTimeCardEdits[index].breaks.length + 1 - i },
                                                      ]}
                                                      key={i}
                                                  >
                                                      <View style={s.breaksRow}>
                                                          <View style={s.materialDropdown_half}>
                                                              <DropdownCalendar
                                                                  containerStyle={[s.fieldInternalStyle]}
                                                                  date={moment(b.StartTime).toDate()}
                                                                  onSelect={(d) => {
                                                                      let time = moment(d);
                                                                      time = moment(bulkTimeCardEdits[index].date).set({
                                                                          hour: time.hours(),
                                                                          minute: time.minutes(),
                                                                      });
                                                                      changeBulkTimeCardBreak({
                                                                          time,
                                                                          breaks: bulkTimeCardEdits[index].breaks,
                                                                          index: i,
                                                                          type: 1,
                                                                          timeCardLocation: index,
                                                                      });
                                                                  }}
                                                                  placeholder={
                                                                      b.StartTime
                                                                          ? `START TIME ${moment(b.StartTime).format(
                                                                                'MM/DD/YY',
                                                                            )}`
                                                                          : 'START TIME'
                                                                  }
                                                                  type="3"
                                                                  height={89}
                                                                  mode="time"
                                                                  topLabel
                                                              />
                                                          </View>
                                                          <View style={s.materialDropdown_half}>
                                                              <DropdownCalendar
                                                                  containerStyle={[s.fieldInternalStyle]}
                                                                  date={moment(b.EndTime).toDate()}
                                                                  onSelect={(d) => {
                                                                      let time = moment(d);
                                                                      time = moment(bulkTimeCardEdits[index].date).set({
                                                                          hour: time.hours(),
                                                                          minute: time.minutes(),
                                                                      });
                                                                      changeBulkTimeCardBreak({
                                                                          time,
                                                                          breaks: bulkTimeCardEdits[index].breaks,
                                                                          index: i,
                                                                          type: 2,
                                                                          timeCardLocation: index,
                                                                      });
                                                                  }}
                                                                  placeholder={
                                                                      b.EndTime
                                                                          ? `END TIME ${moment(b.EndTime).format(
                                                                                'MM/DD/YY',
                                                                            )}`
                                                                          : 'END TIME'
                                                                  }
                                                                  height={89}
                                                                  type="3"
                                                                  mode="time"
                                                                  topLabel
                                                              />
                                                          </View>
                                                      </View>
                                                      <TouchableOpacity
                                                          onPress={() =>
                                                              removeBulkTimeCardBreak({
                                                                  index: i,
                                                                  breaks: bulkTimeCardEdits[index].breaks,
                                                                  timeCardLocation: index,
                                                              })
                                                          }
                                                          style={s.breakDelete}
                                                      >
                                                          <Icon
                                                              name="delete-circle"
                                                              size={30}
                                                              color={Color.pinkish_orange}
                                                          />
                                                      </TouchableOpacity>
                                                  </View>
                                              );
                                          })}

                                      <View style={s.timeRow}>
                                          <TouchableOpacity
                                              onPress={() =>
                                                  addBulkTimeCardBreak({
                                                      breaks: bulkTimeCardEdits[index].breaks,
                                                      date: bulkTimeCardEdits[index].date,
                                                      index,
                                                  })
                                              }
                                              style={{ alignSelf: 'center' }}
                                          >
                                              <Icon name="plus-circle" color={Color.turquoise} size={40} />
                                          </TouchableOpacity>
                                      </View>
                                  </View>
                                  <View style={s.dateRow}>
                                      <Text style={s.dateRowHours}>{`TOTAL CLOCK IN TIME: ${this.returnTotalClockInTime(
                                          index,
                                      )}`}</Text>
                                  </View>

                                  <FieldInput
                                      title="COMMENT"
                                      multiline
                                      input={{
                                          onChange: (text) => {
                                              changeBulkTimeCardEdit({ note: text, index });
                                          },
                                      }}
                                      meta={{
                                          error: null,
                                          touched: false,
                                      }}
                                      initialValues={bulkTimeCardEdits[index].note}
                                  />

                                  {edit.showRemoveButton ? (
                                      <View style={s.buttonWrapperRemove}>
                                          <PrimaryButton
                                              title={`Remove entry for day ${moment(edit.date).format('MM/DD/YY')}`}
                                              isLoading={isLoading}
                                              onPress={() => this.removeExtraDay(index)}
                                              orange={true}
                                          />
                                      </View>
                                  ) : null}

                                  {edit.showAddButton ? (
                                      <View style={s.buttonWrapperSave}>
                                          <PrimaryButton
                                              title={`Add new entry for day ${moment(edit.date).format('MM/DD/YY')}`}
                                              isLoading={isLoading}
                                              onPress={() => this.addExtraDay(edit.date, index)}
                                          />
                                      </View>
                                  ) : null}
                              </View>
                          ))
                        : null}

                    <Text style={s.textAttestation}>
                        I hereby attest that the time card submitted accurately reflects the hours worked during the
                        specified period. I have reviewed the records and confirm that they align with the work
                        performed during that time frame.
                    </Text>
                    {generalRules && generalRules.showAttestation && generalRules.attestationText ? (
                        <Text style={s.textAttestation}>{generalRules.attestationText}</Text>
                    ) : null}
                    <View style={s.buttonWrapper}>
                        <PrimaryButton title="SAVE" isLoading={isLoading} onPress={this.onSave} />
                    </View>
                </View>
                <ConfirmationDialog
                    isOpen={confirmationDialog}
                    onPress={() => this.close()}
                    confirmLabel="OK"
                    text={confirmationText}
                />
            </View>
        );
    }
}

const mapStateToProps = ({ timeCards, currentTimeCard, account, timeCardEdit }) => ({
    currentTimeCard,
    timeCards: timeCards.timeCards,
    user: account.user,
    projects: account.projects,
    costCodes: account.costCodes,
    timeCardEdit,
    generalRules: account.settings.generalRules,
    payPeriods: timeCards.payPeriods,
});

const mapDispatchToProps = (dispatch) => {
    const bindActions = bindActionCreators(
        {
            saveTimeCardEdit,
            cleanTimeCardEdit,
            changeBulkTimeCardEdit,
            addBulkTimeCardBreak,
            removeBulkTimeCardBreak,
            changeBulkTimeCardBreak,
            changeBulkTimeCardStartDate,
            changeBulkTimeCardEndDate,
            addBulkTimeCardEdits,
            clearBulkTimeCardEdits,
            addBulkTimeCardEditsAddExtraDay,
            removeBulkTimeCardEditsRemoveExtraDay,
            userLogin,
        },
        dispatch,
    );
    bindActions.dispatch = dispatch;
    return bindActions;
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkTimeCardEditScreen);
