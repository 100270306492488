import moment from 'moment';
import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import s from './styles';

const ListItem = (props) => {
    const { item } = props;

    return (
        <View style={[s.listItemWrapper]}>
            <TouchableOpacity style={[s.checkBox, item.isChecked && s.checkBox_checked]} onPress={item.onCheck}>
                {item.isChecked && <Icon name="check-bold" style={s.iconChecked} />}
            </TouchableOpacity>
            <View style={s.listItemBody}>
                <Text numberOfLines={1} style={s.listItemTitle}>
                    {item.title}
                </Text>
                <View style={s.listItemInfo}>
                    <View style={s.listItemInfoItem}>
                        <Text style={s.listItemInfoData}>{moment(item.date).format('MM/DD/YY')}</Text>
                    </View>
                    <View style={s.listItemInfoItem}>
                        <Text style={s.listItemInfoData}>{item.userName}</Text>
                    </View>
                    <View style={s.listItemInfoItem}>
                        <Icon name="layers" style={s.listItemInfoIcon} />
                        <Text style={s.listItemInfoData}>{`${item.quantity} items`}</Text>
                    </View>
                </View>
                <Text style={[s.successText, item.isApproved && s.successText_ready]}>
                    {item.isApproved ? 'APPROVED' : 'NOT APPROVED'}
                </Text>
            </View>
            <TouchableOpacity onPress={item.onPress}>
                <Icon name="chevron-right" size={28} style={s.chevronRight} />
            </TouchableOpacity>
        </View>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
};

export default ListItem;
