import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, TouchableOpacity, View, ScrollView } from 'react-native';

import HowToDetailsScreen from '../HowToDetailsScreen/HowToDetailsScreen';
import { NavigationBar } from '../../../components';
import { changeScreen } from '../../../store/modules/howTo';
import { Config } from '../../../config'

import s from './styles';

class HowToListScreen extends Component {
    static propTypes = {
        screen: PropTypes.string,
        changeScreen: PropTypes.func,
    };

    state = {
        item: [],
        title: 'DASHBOARD',
    };

    componentDidMount() {
        const { changeScreen } = this.props;
        changeScreen('dashboard');
    }

    setScreen(screen) {
        const { changeScreen } = this.props;
        const projectLabel = Config.USE_DEPARTMENTS_JOBS ? 'Department' : 'Project'
        const costCodeLabel = Config.USE_DEPARTMENTS_JOBS ? 'Job' : 'Cost code'
        switch (screen) {
            case 'timeTracking': {
                const item = [
                    {
                        title: 'Clock In',
                        steps: [
                            'Click on the CLOCK IN button (This will take you to a new screen)',
                            `Click on the "${projectLabel}" box and select the appropriate ${projectLabel.toLowerCase()}`,
                            // eslint-disable-next-line max-len
                            `Click either “${costCodeLabel}” and then appropriate ”Labor code” or “Time and material” and then appropriate “Time and Material Ticket”`,
                            // eslint-disable-next-line max-len
                            'Click on the “Description” box, enter a description of the work to be done, work order year and work order number',
                            'Click the “SAVE” button',
                            'Click the “DONE” button',
                            'Answer any required “Daily Questions” and hit “Submit”',
                        ],
                        type: 'steps',
                    },
                    {
                        title: 'Daily Questions',
                        steps: [
                            'The first time you Clock in each day you will have a pop up',
                            'For each question click on the appropriate answer',
                            'When you have answered all the questions click the SUBMIT button',
                        ],
                        type: 'steps',
                    },
                    {
                        title: 'Clock Out',
                        steps: [
                            'While clocked in',
                            'Click on the CLOCK OUT button',
                            'Click on the CONFIRM button',
                            'You are now clocked out',
                        ],
                        type: 'steps',
                    },
                    {
                        title: 'Break',
                        steps: [
                            'While clocked in',
                            'Click on the BREAK button (You are now on break)',
                            'Click the END BREAK button to end the break',
                        ],
                        type: 'steps',
                    },
                    {
                        title: `Change ${projectLabel}s`,
                        steps: [
                            'While clocked in',
                            `Click on the "SWITCH ${projectLabel.toUpperCase()}S" button`,
                            `Click on the "${projectLabel}" box and select the appropriate ${projectLabel.toLowerCase()}`,
                            // eslint-disable-next-line max-len
                            `Click either “${costCodeLabel}” and then appropriate ”Labor code” or “Time and material” and then appropriate “Time and Material Ticket”`,
                            // eslint-disable-next-line max-len
                            'Click on the “Description” box, enter a description of the work to be done, work order year and work order number',
                            'Click the “SAVE” button',
                            'Click the “SWITCH” button',
                        ],
                        type: 'steps',
                    },
                    {
                        title: 'Time card modifications',
                        steps: [
                            'Starting at the CLOCK screen',
                            'Click the button on the bottom bar that says "Time Cards"',
                            'This should have the entries tab at the top selected',
                            'Click the time card you would like to modify',
                            'Select the field you would like to change and chose the correct value',
                            'When you are finished making changes click the SAVE button',
                        ],
                        type: 'steps',
                    },
                    {
                        title: 'Signing off on time cards',
                        steps: [
                            'Starting at the CLOCK screen',
                            'Click the button on the bottom bar that says "Time Cards"',
                            // eslint-disable-next-line max-len
                            'This should have the entries tab at the top selected, select the pay period tab at the top',
                            // eslint-disable-next-line max-len
                            'Use the arrow buttons next to the date to go forward or back to the time card you want to sign off on',
                            // eslint-disable-next-line max-len
                            'Click the Arrow button next to the NOT SUBMITTED TEXT (if a time card has been signed off it will say waiting for approval or approved)',
                            'Sign your name',
                            'Click the SUBMIT FOR APPROVAL button',
                        ],
                        type: 'steps',
                    },
                ];
                this.setState({ item, title: 'TIME TRACKING' });
                changeScreen('timeTracking');
                break;
            }
            case 'paidTimeOff': {
                const paidTimeOff = [
                    {
                        title: 'Putting in a time off request',
                        steps: [
                            'Starting from the paid time off screen',
                            'Click the blue circle with a white plus sign button',
                            'Select the range of days that you want to take off',
                            'The days that you want to take off will show up on the screen',
                            'For each day select the leave type "paid", "unpaid", or a combination of the 2',
                            'Click the time section for each day',
                            'Chose the amount of time you would like to take off',
                            'Enter a comment to go with the request',
                            'Chose who to assign the request to',
                            'Click the SAVE BUTTON',
                        ],
                        type: 'steps',
                    },
                ];
                this.setState({ item: paidTimeOff, title: 'PAID TIME OFF' });
                changeScreen('paidTimeOff');
                break;
            }
            case 'dailyReports': {
                const dailyReports = [
                    {
                        title: 'Creating a daily report',
                        steps: [
                            'To use this screen you must be clocked in',
                            // eslint-disable-next-line max-len
                            'Starting from the clock screen, you can access the daily reports screen from one of two places, the first one is the icon at the bottom right of the corner that says reports, the second is by clicking the three lines in the upper left hand corner to bring up the menu and then select daily reports',
                            `This screen will show reports from the current ${projectLabel.toLowerCase()} that you are clocked into`,
                            'To start creating a report click on the blue circle with the + sign in it',
                            `Here you can review the work logs for this ${projectLabel.toLowerCase()} by clicking on the work logs section`,
                            // eslint-disable-next-line max-len
                            'To add a note to the report click on the notes section, then click on the blue circle with the + sign in it to add a note, this screen will also show you all notes on this current report',
                            // eslint-disable-next-line max-len
                            'After clicking the button you will have 3 sections, the first is the category of the note, chose the appropriate category',
                            'Click on the note section to type out a note',
                            'You can add photos to the note by clicking on the attachments section',
                            'Click the SAVE button when you are done with your note',
                            // eslint-disable-next-line max-len
                            'Last is the survey section, here you will have a group of questions to answer, for each question pick one of the three buttons, N/A, YES, or NO',
                            // eslint-disable-next-line max-len
                            'Click the paperclip button next to the answer options if you have a photo to attach to the survey',
                            'Click on the description section to add additional comments to the survey question',
                            'After answering all the questions click the SAVE button',
                            'Click on the SAVE REPORT button',
                        ],
                        type: 'steps',
                    },
                ];
                this.setState({ item: dailyReports, title: 'DAILY REPORTS' });
                changeScreen('dailyReports');
                break;
            }
            case 'location': {
                const changeOrder = [
                    {
                        title: 'Location',
                        steps: [
                            // eslint-disable-next-line max-len
                            'This app will use the location for geofencing projects specific to the company, and locating the user to ensure they are where they are suppose to when clocked in.  It will also use the the location to get the weather conditions for fills out a daily report. In order to clock in, you must have the location setting on.',
                        ],
                        type: 'steps',
                    },
                ];
                this.setState({ item: changeOrder, title: 'LOCATION' });
                changeScreen('location');
                break;
            }
            case 'changeOrder': {
                const changeOrder = [
                    {
                        title: 'Creating a ticket',
                        steps: [
                            `You must be clocked into a ${projectLabel.toLowerCase()} to use this screen`,
                            // eslint-disable-next-line max-len
                            'Once clocked in and on the Change order screen, click the circle button with the white + in it',
                            'Click the Ticket name section to add an appropriate name to the ticket',
                            'Do this for each field',
                            // eslint-disable-next-line max-len
                            'If you have photos to add there are sections to add photos, click the paper clip to attach a photo from you library or the camera to take a new picture to add',
                            'When all sections are filled in, sign the bottom and click the save button',
                        ],
                        type: 'steps',
                    },
                ];
                this.setState({ item: changeOrder, title: 'CHANGE ORDER' });
                changeScreen('changeOrder');
                break;
            }
            case 'costPerMaterial': {
                const costPerMaterial = [
                    {
                        title: 'Creating a ticket',
                        steps: [
                            `You must be clocked into a ${projectLabel.toLowerCase()} to use this screen`,
                            // eslint-disable-next-line max-len
                            'Once clocked in and on the Change order screen, click the circle button with the white + in it',
                            'Click the Ticket name section to add an appropriate name to the ticket',
                            'Do this for each field',
                            // eslint-disable-next-line max-len
                            'If you have photos to add there are sections to add photos, click the paper clip to attach a photo from you library or the camera to take a new picture to add',
                            'When all sections are filled in, sign the bottom and click the save button',
                        ],
                        type: 'steps',
                    },
                    {
                        title: 'Adding to existing ticket',
                        steps: [
                            `You must be clocked into a ${projectLabel.toLowerCase()} to use this screen`,
                            'Once clocked in and on the Change order screen, click the ticket you want to add more to',
                            'If there are no tickets then one will need to be created',
                            'Click the blue circle with the white + in it under the category you want to add',
                            'Add the information to the pop up and click the SAVE button',
                        ],
                        type: 'steps',
                    },
                ];
                this.setState({ item: costPerMaterial, title: 'COST PER MATERIAL' });
                changeScreen('costPerMaterial');
                break;
            }
            default:
                break;
        }
    }

    render() {
        const { screen } = this.props;
        const { item, title } = this.state;
        if (screen === 'dashboard' || !screen) {
            return (
                <View style={s.wrapper}>
                    <NavigationBar title="HOW TO" menuIcon {...this.props} />
                    <ScrollView style={s.mainContainer}>
                        <TouchableOpacity style={s.listItemWrapper} onPress={() => this.setScreen('timeTracking')}>
                            <Text style={s.title}>Time Tracking</Text>
                        </TouchableOpacity>
                        {/* <TouchableOpacity style={s.listItemWrapper} onPress={() => this.setScreen('paidTimeOff')}>
              <Text style={s.title}>Paid Time Off</Text>
            </TouchableOpacity> */}
                        <TouchableOpacity style={s.listItemWrapper} onPress={() => this.setScreen('dailyReports')}>
                            <Text style={s.title}>Daily Reports</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={s.listItemWrapper} onPress={() => this.setScreen('location')}>
                            <Text style={s.title}>Location</Text>
                        </TouchableOpacity>
                        {/* <TouchableOpacity style={s.listItemWrapper} onPress={() => this.setScreen('changeOrder')}>
              <Text style={s.title}>Change Order</Text>
            </TouchableOpacity>
            <TouchableOpacity style={s.listItemWrapper} onPress={() => this.setScreen('costPerMaterial')}>
              <Text style={s.title}>Cost per material</Text>
            </TouchableOpacity> */}
                    </ScrollView>
                </View>
            );
        }
        return <HowToDetailsScreen item={item} title={title} />;
    }
}

const mapStateToProps = ({ howTo }) => ({
    screen: howTo.screen,
});

export default connect(mapStateToProps, {
    changeScreen,
})(HowToListScreen);
