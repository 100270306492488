import { StyleSheet, Dimensions } from 'react-native';
import { Font, Color } from '../../../theme';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        paddingBottom: 140,
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logo: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingTop: '10%',
        paddingBottom: '10%',
    },
    logoImg: {
        width: 150,
        height: 50,
    },
    form: {
        height: '70%',
    },
    bottomButtonsBlock: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        position: 'absolute',
        bottom: -55,
        elevation: 12,
    },
    addButton: {
        width: 43,
        height: 43,
        borderRadius: 22,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.turquoise,
        elevation: 12,
        shadowColor: Color.light_grey_blue,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.5,
        shadowRadius: 5,
    },
    addButton_inactive: {
        backgroundColor: Color.light_grey_blue_05,
    },
    weatherButton: {
        width: 120,
        height: 18,
        opacity: 0.25,
        marginTop: 10,
    },
    weatherButtonImage: {
        width: 120,
        height: 18,
    },
    closeButton: {
        width: 43,
        height: 43,
        borderRadius: 22,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        backgroundColor: Color.light_navy,
        top: 0,
        right: 20,
        elevation: 12,
        zIndex: 999,
    },
    pdfContainer: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 25,
    },
    pdfViewer: {
        flex: 1,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    },
    listTitle: {
        color: Color.light_navy,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font12,
        marginBottom: 12.5,
        marginTop: 4,
    },
    totalRow: {
        width: '100%',
        flexDirection: 'row',
        marginBottom: 20,
        marginTop: 30,
        paddingHorizontal: 25,
    },
    totalCol: {
        flex: 0.5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    totalTitle: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font24,
        fontFamily: Font.type.light,
    },
    totalSubtitle: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font10,
        fontFamily: Font.type.light,
    },
    totalText_blue: {
        color: Color.light_navy,
    },
});
