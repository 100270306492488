import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    menuContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        paddingHorizontal: 15,
    },
    menuItem: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    menuItem_1: {
        width: '100%',
    },
    menuItem_2: {
        width: '50%',
    },
    menuItem_3: {
        width: '33.3%',
    },
    menuItem_4: {
        width: '25%',
    },
    menuitemText: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font11,
        color: Color.grey,
    },
    menuitemTextActive: {
        color: Color.blue,
        fontFamily: Font.type.bold,
    },
    menuActiveItemSlash: {
        width: 20,
        height: 2,
        backgroundColor: Color.blue,
        position: 'absolute',
        bottom: 0,
        left: '50%',
        marginLeft: -10,
    },
});
