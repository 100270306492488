import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, ImageBackground, Image, KeyboardAvoidingView, Platform, ActivityIndicator } from 'react-native';
import { connect } from 'react-redux';
import { showMessage } from 'react-native-flash-message';
import { isEmpty } from 'lodash';
import { userLogin } from '../../../store/modules/account';
import { LinkButton, PrimaryButton } from '../../../components/index';
import { LoginForm, SignInCodeLoginForm } from '../../../forms/index';
import s from './styles';
import { Img, Color } from '../../../theme/index';

class LoginScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        form: PropTypes.object,
        user: PropTypes.object,
        isLoading: PropTypes.bool,
        isAutoLoginLoading: PropTypes.bool,
        error: PropTypes.string,
        userLogin: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            showCodeLogin: false,
        };
    }

    onLogin = () => {
        const { userLogin, form } = this.props;
        const { email, password, signInCode } = form.values;
        userLogin({ email, password, signInCode });
    };

    onShowMessage = (code, message) => {
        showMessage({
            message: `${code}`,
            description: message,
            type: 'danger',
            icon: 'danger',
            position: 'right',
            hideStatusBar: true,
            backgroundColor: Color.red,
        });
    };

    onLoginTypeSwitch = () => {
        this.setState({ showCodeLogin: !this.state.showCodeLogin });
    };

    render() {
        const { navigation, isLoading, isAutoLoginLoading, error, user } = this.props;
        return (
            <ImageBackground source={Img.bg} style={[s.wrapper]}>
                <KeyboardAvoidingView style={[s.mainContainer]} behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
                    {isAutoLoginLoading ? (
                        <ActivityIndicator color={Color.light_navy} size={40} style={{ marginTop: 150 }} />
                    ) : (
                        <>
                            <View style={s.logo}>
                                <Image source={Img.logo} style={s.logoImg} />
                            </View>
                            <View style={s.form}>
                                {!isEmpty(error) && <Text style={s.errorText}>{error}</Text>}
                                {this.state.showCodeLogin ? (
                                    <SignInCodeLoginForm
                                        isLoading={isLoading}
                                        handleLogin={this.onLogin}
                                        navigation={navigation}
                                        containerStyle={{ width: '100%' }}
                                        user={user}
                                    />
                                ) : (
                                    <>
                                        <LoginForm
                                            isLoading={isLoading}
                                            handleLogin={this.onLogin}
                                            navigation={navigation}
                                            containerStyle={{ width: '100%' }}
                                            user={user}
                                        />
                                        <LinkButton
                                            title="Forgot password?"
                                            onPress={() => navigation.navigate('ForgotPassword')}
                                            style={{ marginTop: 20, textTransform: 'inherit' }}
                                        />
                                    </>
                                )}
                                <Text style={s.orText}> OR </Text>
                                <PrimaryButton
                                    title={
                                        this.state.showCodeLogin ? 'Sign In with Email' : 'Sign In with Company Code'
                                    }
                                    onPress={this.onLoginTypeSwitch}
                                    isLoading={isLoading}
                                    disabled={isLoading || !!user}
                                />
                            </View>
                        </>
                    )}
                </KeyboardAvoidingView>
            </ImageBackground>
        );
    }
}

const mapStateToProps = ({ account, form }) => ({
    isLoading: account.isLoading,
    error: account.error,
    form: form.login,
    user: account.user,
    isAutoLoginLoading: account.isAutoLoginLoading,
});

export default connect(mapStateToProps, {
    userLogin,
})(LoginScreen);
