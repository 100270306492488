import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    modalWrapper: {
        width: '100%',
        height: '100%',
        backgroundColor: Color.dark_navy_blue_50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalBlock: {
        backgroundColor: Color.white,
        borderRadius: 10,
        width: '70%',
        padding: 15,
        paddingTop: 25,
        minHeight: 222,
    },
    modalBlock_attachments: {
        height: 'auto',
    },
    modalTitle: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font20,
        color: Color.blue,
        marginBottom: 20,
        textAlign: 'center',
    },
    textAttestation: {
        color: Color.dark_navy_blue_30,
        fontSize: Font.size.font12,
        fontFamily: Font.type.regular,
        textAlign: 'center',
        marginTop: 15,
    },
    modalSignature: {
        width: '100%',
        height: 110,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 25,
        marginBottom: 50,
    },
    signature: {
        height: '100%',
    },
    signatureLine: {
        backgroundColor: Color.brown_grey,
        height: 1,
        position: 'absolute',
        top: 80,
        left: '10%',
        width: '50%',
    },
    signaturePlaceholder: {
        paddingTop: 7,
        color: Color.brown_grey,
        fontFamily: Font.type.light,
        textAlign: 'center',
        userSelect: 'none',
    },
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginTop: 40,
        width: '100%',
    },
    cancelModalButton: {
        width: 60,
        marginRight: 25,
        marginTop: 18,
    },
    uploadBlock: {
        backgroundColor: Color.white,
        borderRadius: 6,
        padding: 10,
        width: '100%',
        elevation: 5,
        marginBottom: 20,
        marginTop: 10,
    },
    uploadBlockHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    uploadBlockTitle: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
        alignSelf: 'flex-start',
        marginTop: 6,
    },
    uploadIcon: {
        backgroundColor: Color.light_navy_10,
        width: 25,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12.5,
        marginLeft: 10,
    },
    attachmentsWrapper: {
        width: '100%',
        flexDirection: 'row',
        marginTop: 10,
        flexWrap: 'wrap',
    },
    attachmentItem: {
        height: 150,
        width: 150,
        marginRight: 10,
        marginBottom: 10,
    },
    attachmentImage: {
        height: '100%',
        width: '100%',
    },
    attachmentButton: {
        width: 30,
        height: 30,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        right: 5,
        top: 5,
        backgroundColor: Color.pale_grey,
    },
    buttonsWrapper: {
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
});
