import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Text, View } from 'react-native';
import FieldInput from '../../../../components/FieldInput/FieldInput';
import LinkButton from '../../../../components/LinkButton/LinkButton';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';

import { DropdownCalendar } from '../../../../components';

import s from './styles';

export default class TimeOffRequestModal extends Component {
    static propTypes = {
        isOpen: PropTypes.bool,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        shift: PropTypes.object,
        selectedDay: PropTypes.instanceOf(Date),
    };

    state = {
        notes: '',
        startTime: null,
        endTime: null,
    };

    handleSubmitPress = () => {
        const { onSubmit } = this.props;
        const { notes, startTime, endTime } = this.state;
        onSubmit(notes, startTime, endTime);
    };

    handleCancelPress = () => {
        const { onCancel } = this.props;
        onCancel();

        this.setState({
            notes: '',
        });
    };

    render() {
        const { notes, startTime, endTime } = this.state;
        const { isOpen, selectedDay, shift } = this.props;
        return (
            <Modal visible={isOpen} animationType="fade" transparent presentationStyle="overFullScreen">
                <View style={s.modalWrapper}>
                    <View style={s.modalBlock}>
                        <Text style={s.modalTitle}>Please add some notes if needed</Text>

                        <View style={s.modalContent}>
                            <FieldInput
                                multiline
                                initialValues={notes}
                                input={{
                                    onChange: (notes) => {
                                        this.setState({ notes });
                                    },
                                }}
                                meta={{
                                    error: null,
                                    touched: false,
                                }}
                            />
                        </View>
                        {!shift ? (
                            <View style={s.timesRow}>
                                <View style={s.materialDropdown_half}>
                                    <DropdownCalendar
                                        containerStyle={[s.materialDropdown]}
                                        date={moment(startTime).isValid() ? moment(startTime).toDate() : null}
                                        onSelect={(d) => {
                                            let startTime = moment(d);
                                            startTime = moment(selectedDay).set({
                                                hour: startTime.hours(),
                                                minute: startTime.minutes(),
                                            });
                                            startTime = startTime.second(0).toISOString();
                                            this.setState({ startTime });
                                        }}
                                        placeholder="START TIME"
                                        height={89}
                                        mode="time"
                                        type="3"
                                        range={{
                                            from: startTime,
                                            to: endTime,
                                        }}
                                        topLabel
                                    />
                                </View>

                                <View style={s.materialDropdown_half}>
                                    <DropdownCalendar
                                        containerStyle={[s.materialDropdown]}
                                        date={moment(endTime).isValid() ? moment(endTime).toDate() : null}
                                        onSelect={(d) => {
                                            let endTime = moment(d);
                                            endTime = moment(selectedDay).set({
                                                hour: endTime.hours(),
                                                minute: endTime.minutes(),
                                            });
                                            this.setState({ endTime });
                                        }}
                                        placeholder="END TIME"
                                        height={89}
                                        mode="time"
                                        type="3"
                                        range={{
                                            from: startTime,
                                            to: endTime,
                                        }}
                                        topLabel
                                    />
                                </View>
                            </View>
                        ) : null}

                        <View style={s.modalButtons}>
                            <View style={s.cancelModalButton}>
                                <LinkButton title="CANCEL" onPress={this.handleCancelPress} />
                            </View>
                            <View style={s.submitModalButton}>
                                <PrimaryButton md title="SUBMIT FOR APPROVAL" onPress={this.handleSubmitPress} />
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        );
    }
}
