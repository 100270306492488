import moment from 'moment';
import React from 'react';
import { View, Text, Image } from 'react-native';

import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import { Img, Color } from '../../../../theme';

import s from './styles';

const ListItem = (props) => {
    const { item } = props;

    return (
        <View style={s.listItemWrapper}>
            <View style={s.listItemRow}>
                <View style={s.commBox}>
                    <Image source={Img.logoClock} style={s.logoImg} />
                    <View style={s.alarm}>
                        <Icon name="bell" size={16} color={Color.white} />
                    </View>
                </View>
                <View style={s.listItemColumn}>
                    <Text style={s.listItemCommTitle}>{item.title}</Text>
                    <View>
                        <Text style={[s.listItemTitle, s.listItemComMessage]}>{item.message}</Text>
                    </View>
                </View>
                <Text style={s.listItemHours}>{`${moment(item.date).format('MM/DD/YY')}`}</Text>
            </View>
        </View>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
};

export default ListItem;
