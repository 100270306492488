import { StyleSheet } from 'react-native';
import { Font, Color } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        padding: 20,
        alignItems: 'center',
    },
    dropdownWrapper: {
        width: '100%',
        alignItems: 'flex-start',
    },
    dropdownContainer: {
        minWidth: 250,
    },
    label: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font11,
        color: Color.light_grey_blue,
        textTransform: 'uppercase',
        marginTop: -5,
    },
    buttonWrapper: {
        width: '50%',
        marginVertical: 20,
        alignSelf: 'center',
    },
    listItem: {
        margin: 20,
        width: '100%',
    },
    textInput: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font15,
        width: '100%',
        color: Color.dark_navy_blue,
        marginBottom: 12,
    },
});
