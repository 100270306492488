import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, TouchableOpacity, View, ScrollView } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { NavigationBar, DropdownCalendar, FieldInput } from '../../../components/index';
import { setStepTwoData } from '../../../store/modules/accidentReporting';
import { Color } from '../../../theme';
import s from './styles';

class AccidentReportingAddNewStepTwoScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentAccident: PropTypes.object,
        setStepTwoData: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { currentAccident } = props;
        if (currentAccident) {
            this.state = {
                date: currentAccident.AccidentDetails.Date,
                weatherAndRoadConditions: currentAccident.AccidentDetails.WeatherAndRoadConditions,
                location: currentAccident.AccidentDetails.Location,
                accidentDetails: currentAccident.AccidentDetails.AccidentDetails,

                yourVehicle: currentAccident.DamageDescription.YourVehicle,
                towingCompanyNameAndPhoneNumber: currentAccident.DamageDescription.TowingCompanyNameAndPhoneNumber,
                otherVehicle: currentAccident.DamageDescription.OtherVehicle,
                otherVehicleTowingCompanyNameAndPhoneNumber:
                    currentAccident.DamageDescription.OtherVehicleTowingCompanyNameAndPhoneNumber,

                ownersName: currentAccident.OtherDriverInformation.OwnersName,
                ownersAddress: currentAccident.OtherDriverInformation.OwnersAddress,
                ownersPhone: currentAccident.OtherDriverInformation.OwnersPhone,
                vehicleMake: currentAccident.OtherDriverInformation.VehicleMake,
                vehicleModelAndYear: currentAccident.OtherDriverInformation.VehicleModelAndYear,
                vehicleColor: currentAccident.OtherDriverInformation.VehicleColor,
                licensePlateNumber: currentAccident.OtherDriverInformation.LicensePlateNumber,
                insuranceCompanyAndPolicyNumber: currentAccident.OtherDriverInformation.InsuranceCompanyAndPolicyNumber,
                agentNameAndPhoneNumber: currentAccident.OtherDriverInformation.AgentNameAndPhoneNumber,
                otherDriversName: currentAccident.OtherDriverInformation.OtherDriversName,
                otherDriversAddress: currentAccident.OtherDriverInformation.OtherDriversAddress,
                otherDriversPhone: currentAccident.OtherDriverInformation.OtherDriversPhone,
                numberOfPassengers: currentAccident.OtherDriverInformation.NumberOfPassengers,
                passengerInfo: currentAccident.OtherDriverInformation.PassengerInfo,
            };
        }
    }

    componentDidMount() {}

    editPassenger = (i, t, v) => {
        const { passengerInfo } = this.state;
        const newPassengers = cloneDeep(passengerInfo);
        if (t === 'name') {
            newPassengers[i].PassengerName = v;
        } else {
            newPassengers[i].PassengerPhoneNumber = v;
        }
        this.setState({ passengerInfo: newPassengers });
    };

    addPassenger = () => {
        const { passengerInfo } = this.state;
        const newPassengers = cloneDeep(passengerInfo);
        newPassengers.push({ PassengerName: '', PassengerPhoneNumber: '' });
        this.setState({ passengerInfo: newPassengers });
    };

    removePassenger = (i) => {
        const { passengerInfo } = this.state;
        const newPassengers = cloneDeep(passengerInfo);
        newPassengers.splice(i, 1);
        this.setState({ passengerInfo: newPassengers });
    };

    saveAndContinue = () => {
        const { navigation, setStepTwoData } = this.props;
        const data = cloneDeep(this.state);

        data.passengerInfo = data.passengerInfo.reduce((passengerInfo, p) => {
            if (p.PassengerName !== '' || p.PassengerPhoneNumber !== '') {
                passengerInfo.push(p);
            }
            return passengerInfo;
        }, []);

        setStepTwoData(data);
        navigation.navigate('AccidentReportingAddNewStepThree');
    };

    render() {
        const {
            date,
            weatherAndRoadConditions,
            location,
            accidentDetails,

            yourVehicle,
            towingCompanyNameAndPhoneNumber,
            otherVehicle,
            otherVehicleTowingCompanyNameAndPhoneNumber,

            ownersName,
            ownersAddress,
            ownersPhone,
            vehicleMake,
            vehicleModelAndYear,
            vehicleColor,
            licensePlateNumber,
            insuranceCompanyAndPolicyNumber,
            agentNameAndPhoneNumber,
            otherDriversName,
            otherDriversAddress,
            otherDriversPhone,
            numberOfPassengers,
            passengerInfo,
        } = this.state;
        return (
            <View style={s.wrapper}>
                <NavigationBar title="AUTO ACCIDENT REPORT" backIcon {...this.props} />
                <View style={s.menuContainer}>
                    <View style={s.menuDotOne} />
                    <View style={s.menuDotTwo} />
                    <View style={s.menuDotThree} />
                </View>
                <ScrollView>
                    <View style={s.mainContainer}>
                        <Text style={s.title}>Accident Details</Text>
                        <DropdownCalendar
                            containerStyle={[s.calendarDropdown]}
                            date={date ? moment(date).toDate() : null}
                            onSelect={(d) => this.setState({ date: d })}
                            placeholder="DAY/DATE/TIME AM/PM"
                            mode="datetime"
                            topLabel
                        />
                        <FieldInput
                            title="WEATHER/ROAD CONDITIONS"
                            initialValues={weatherAndRoadConditions}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ weatherAndRoadConditions: text });
                                },
                            }}
                        />
                        <FieldInput
                            title="LOCATION OF ACCIDENT"
                            initialValues={location}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ location: text });
                                },
                            }}
                        />
                        <FieldInput
                            multiline
                            title="ACCIDENT DETAILS"
                            initialValues={accidentDetails}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ accidentDetails: text });
                                },
                            }}
                        />

                        <Text style={s.title}>Damage Description</Text>
                        <FieldInput
                            title="YOUR VEHICLE"
                            initialValues={yourVehicle}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ yourVehicle: text });
                                },
                            }}
                        />
                        <FieldInput
                            multiline
                            title="TOWING COMPANY NAME & PHONE"
                            initialValues={towingCompanyNameAndPhoneNumber}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ towingCompanyNameAndPhoneNumber: text });
                                },
                            }}
                        />
                        <FieldInput
                            title="OTHER VEHICLE"
                            initialValues={otherVehicle}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ otherVehicle: text });
                                },
                            }}
                        />
                        <FieldInput
                            multiline
                            title="TOWING COMPANY NAME & PHONE"
                            initialValues={otherVehicleTowingCompanyNameAndPhoneNumber}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ otherVehicleTowingCompanyNameAndPhoneNumber: text });
                                },
                            }}
                        />

                        <Text style={s.title}>Other Driver/Vehicle Information</Text>
                        <FieldInput
                            title="OWNER'S NAME"
                            initialValues={ownersName}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ ownersName: text });
                                },
                            }}
                        />
                        <FieldInput
                            title="OWNER'S ADDRESS"
                            initialValues={ownersAddress}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ ownersAddress: text });
                                },
                            }}
                        />
                        <FieldInput
                            title="OWNER'S PHONE"
                            initialValues={ownersPhone}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ ownersPhone: text });
                                },
                            }}
                        />
                        <FieldInput
                            title="VEHICLE MAKE"
                            initialValues={vehicleMake}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ vehicleMake: text });
                                },
                            }}
                        />
                        <FieldInput
                            title="VEHICLE MODEL & YEAR"
                            initialValues={vehicleModelAndYear}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ vehicleModelAndYear: text });
                                },
                            }}
                        />
                        <FieldInput
                            title="VEHICLE COLOR"
                            initialValues={vehicleColor}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ vehicleColor: text });
                                },
                            }}
                        />
                        <FieldInput
                            title="LICENSE PLATE NUMBER"
                            initialValues={licensePlateNumber}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ licensePlateNumber: text });
                                },
                            }}
                        />
                        <FieldInput
                            title="INS. COMPANY/POLICY#"
                            initialValues={insuranceCompanyAndPolicyNumber}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ insuranceCompanyAndPolicyNumber: text });
                                },
                            }}
                        />
                        <FieldInput
                            title="AGENT NAME & PHONE"
                            initialValues={agentNameAndPhoneNumber}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ agentNameAndPhoneNumber: text });
                                },
                            }}
                        />
                        <FieldInput
                            title="OTHER DRIVERS NAME"
                            initialValues={otherDriversName}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ otherDriversName: text });
                                },
                            }}
                        />
                        <FieldInput
                            title="OTHER DRIVERS ADDRESS"
                            initialValues={otherDriversAddress}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ otherDriversAddress: text });
                                },
                            }}
                        />
                        <FieldInput
                            title="OTHER DRIVERS PHONE"
                            initialValues={otherDriversPhone}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ otherDriversPhone: text });
                                },
                            }}
                        />
                        <FieldInput
                            multiline
                            title="# PASSENGERS"
                            initialValues={numberOfPassengers}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ numberOfPassengers: text });
                                },
                            }}
                        />
                        <View style={s.listItemWrapper}>
                            {passengerInfo.map((p, i) => {
                                return (
                                    <View style={s.listItem} key={i}>
                                        <View style={s.listItemLeft}>
                                            <FieldInput
                                                style={s.fieldInternalStyle}
                                                initialValues={p.PassengerName}
                                                title="PASSENGER NAME"
                                                meta={{
                                                    error: null,
                                                    touched: false,
                                                }}
                                                input={{
                                                    onChange: (text) => {
                                                        this.editPassenger(i, 'name', text);
                                                    },
                                                }}
                                            />
                                            <FieldInput
                                                style={s.fieldInternalStyle}
                                                initialValues={p.PassengerPhoneNumber}
                                                title="PASSENGER PHONE NUMBER"
                                                meta={{
                                                    error: null,
                                                    touched: false,
                                                }}
                                                input={{
                                                    onChange: (text) => {
                                                        this.editPassenger(i, 'address', text);
                                                    },
                                                }}
                                            />
                                        </View>
                                        <View style={s.listItemRight}>
                                            <TouchableOpacity
                                                onPress={() => this.removePassenger(i)}
                                                style={s.removeButtonContainer}
                                            >
                                                <View style={s.removeButton}>
                                                    <Icon name="minus" size={20} color={Color.pinkish_orange} />
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                );
                            })}

                            <TouchableOpacity onPress={this.addPassenger}>
                                <View style={s.buttonCancel}>
                                    <Text style={s.buttonTextCancel}>
                                        <Icon name="plus" size={15} />
                                        ADD NEW
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        </View>

                        <TouchableOpacity onPress={this.saveAndContinue}>
                            <View style={s.button}>
                                <Text style={s.buttonText}>CONTINUE</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </ScrollView>
            </View>
        );
    }
}

const mapStateToProps = ({ accidentReporting }) => ({
    currentAccident: accidentReporting.currentTicket,
});

export default connect(mapStateToProps, {
    setStepTwoData,
})(AccidentReportingAddNewStepTwoScreen);
