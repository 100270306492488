import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NetInfo from '@react-native-community/netinfo';
import { View, Text, FlatList, Modal, ActivityIndicator } from 'react-native';
import { connect } from 'react-redux';
import { isEmpty, cloneDeep } from 'lodash';
import { showMessage } from 'react-native-flash-message';
import { getProject } from '../../../store/modules/account';
import {
    saveReport,
    setDailyReport,
    cleanUp,
    startDailyReportSaveLoading,
} from '../../../store/modules/dailyReports';
import { NavigationBar, ListItem, PrimaryButton, FieldInput, ConfirmationDialog } from '../../../components/index';
import { Color } from '../../../theme';
import s from './styles';

class DailyReportScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentReport: PropTypes.object,
        currentProject: PropTypes.object,
        user: PropTypes.object,
        currentTimeCard: PropTypes.object,
        isLoading: PropTypes.bool,
        isSaveLoading: PropTypes.bool,
        isChanged: PropTypes.bool,
        saveReport: PropTypes.func,
        setDailyReport: PropTypes.func,
        cleanUp: PropTypes.func,
        startDailyReportSaveLoading: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            serviceProjectName: '',
            showConfirmationDialog: false,
            confirmationText: '',
        };
    }

    componentDidMount() {
        const { currentReport } = this.props;
        const stateToUpdate = {};

        if (currentReport.ProjectName) {
            stateToUpdate.serviceProjectName = currentReport.ProjectName;
        }

        if (!isEmpty(stateToUpdate)) {
            this.setState(stateToUpdate);
        }
    }

    _returnNewReportItems() {
        const { navigation, currentReport, currentProject } = this.props;
        let list = [];
        if (currentProject && currentReport) {
            list = [
                {
                    id: 'workLogs',
                    title: 'Work Logs',
                    icon: 'account-outline',
                    quantity: null,
                    onPress: () => {
                        navigation.navigate('DailyWorkLogSelector');
                    },
                },
                {
                    id: 'notes',
                    title: 'Notes',
                    icon: 'file-document-edit-outline',
                    quantity: `${currentReport.Notes.length}`,
                    onPress: () => {
                        navigation.navigate('DailyNotes');
                    },
                },
                {
                    id: 'survey',
                    title: 'Survey',
                    icon: 'clipboard-text-outline',
                    quantity: `${currentReport.Survey.length}`,
                    onPress: () => {
                        navigation.navigate('DailySurvey');
                    },
                },
            ];
        }
        return list;
    }

    saveReport = async (completed) => {
        const {
            saveReport,
            navigation,
            currentReport,
            currentProject,
            user,
            currentTimeCard,
            cleanUp,
            startDailyReportSaveLoading,
        } = this.props;
        const { serviceProjectName } = this.state;
        const { currentCostCode } = currentTimeCard;
        const finalReport = cloneDeep(currentReport);
        finalReport.Completed = completed;

        if (
            currentProject.ProjectType &&
            currentProject.ProjectType === 'W' &&
            (isEmpty(currentTimeCard.workOrderNumber) ||
                isEmpty(currentTimeCard.workOrderYear) ||
                isEmpty(serviceProjectName))
        ) {
            this.setState({
                showConfirmationDialog: true,
                confirmationText:
                    // eslint-disable-next-line max-len
                    'Please make sure the Service Project Name and the Work Order Number (in the description tab on the clock screen) is filled out',
            });
            return;
        }
        startDailyReportSaveLoading();

        const connectionState = await NetInfo.fetch();
        if (connectionState.isConnected) {
            getProject(currentProject.id).then(async (actualProject) => {
                saveReport({
                    currentReport: finalReport,
                    actualCurrentProject: actualProject[0],
                    currentCostCode,
                    user,
                    currentTimeCard,
                    serviceProjectName,
                    saveOffline: !connectionState.isConnected,
                });
                cleanUp();
                navigation.navigate('Reports');
            });
        } else {
            saveReport({
                currentReport: finalReport,
                currentCostCode,
                user,
                currentTimeCard,
                saveOffline: true,
            });
            cleanUp();
            showMessage({
                message:
                    // eslint-disable-next-line max-len
                    'No data connection detected. Your report has been saved on your device and will be sent when you have a data connection.',
                backgroundColor: Color.faded_orange,
                color: Color.dark_navy_blue,                    
                duration: 10000,
                animationDuration: 0,
                hideOnPress: true,
                hideStatusBar: true,
            });
            navigation.navigate('Reports');
        }
    };

    render() {
        const { isLoading, isSaveLoading, isChanged, navigation, cleanUp, currentProject, currentReport } = this.props;
        const { showConfirmationDialog, serviceProjectName, confirmationText } = this.state;
        const backConfirmText =
            // eslint-disable-next-line max-len
            'You are about to back out of your report. All not saved data will be lost. Are you sure you want to do this ?';
        const backTitle = 'Warning';
        return (
            <View style={[s.wrapper]}>
                <NavigationBar
                    {...this.props}
                    title="DAILY REPORT"
                    backIcon
                    backIconFunction={() => {
                        cleanUp();
                        navigation.goBack(null);
                    }}
                    backConfirm={isChanged ? { title: backTitle, text: backConfirmText } : null}
                />
                <View style={s.menuTopContainer}>
                    <Text style={s.topMenu_title}>{moment().format('MM/DD/YY')}</Text>
                </View>
                <View style={[s.mainContainer]}>
                    {currentProject && currentProject.ProjectType && currentProject.ProjectType === 'W' ? (
                        <View style={s.workOrderNumber}>
                            <FieldInput
                                sm
                                initialValues={serviceProjectName || null}
                                style={{ height: 50, paddingTop: 0 }}
                                placeholder="SERVICE PROJECT NAME"
                                input={{
                                    onChange: (text) => {
                                        this.setState({ serviceProjectName: text });
                                    },
                                }}
                                meta={{
                                    error: null,
                                    touched: false,
                                }}
                            />
                        </View>
                    ) : null}
                    <View style={s.listWrapper}>
                        <FlatList
                            data={this._returnNewReportItems()}
                            contentContainerStyle={{ paddingBottom: 0 }}
                            keyExtractor={(item) => item.id}
                            style={{ elevation: 20, width: '100%' }}
                            renderItem={({ item }) => {
                                return <ListItem item={item} />;
                            }}
                        />
                        <View style={s.buttonsWrapper}>
                            <View style={s.saveButtonWrapper}>
                                <PrimaryButton
                                    title="SAVE FOR LATER"
                                    isLoading={isSaveLoading || isLoading}
                                    disabled={isSaveLoading || isLoading || !currentReport}
                                    onPress={() => this.saveReport(false)}
                                    saveForLaterButton
                                />
                            </View>
                            <View style={s.saveButtonWrapper}>
                                <PrimaryButton
                                    title="SUBMIT"
                                    isLoading={isSaveLoading || isLoading}
                                    disabled={isSaveLoading || isLoading || !currentReport}
                                    onPress={() => this.saveReport(true)}
                                />
                            </View>
                        </View>
                    </View>
                </View>
                <Modal visible={isLoading} transparent>
                    <View style={s.modalLoading}>
                        <ActivityIndicator color={Color.blue} size={40} />
                    </View>
                </Modal>
                <ConfirmationDialog
                    isOpen={showConfirmationDialog}
                    onPress={() => this.setState({ showConfirmationDialog: false })}
                    // eslint-disable-next-line max-len
                    text={confirmationText}
                />
            </View>
        );
    }
}

const mapStateToProps = ({ dailyReports, account, currentTimeCard }) => ({
    currentReport: dailyReports.currentReport,
    currentProject: dailyReports.currentProject,
    isChanged: dailyReports.isChanged,
    isLoading: dailyReports.isLoading,
    isSaveLoading: dailyReports.isSaveLoading,
    user: account.user,
    currentTimeCard,
});

export default connect(mapStateToProps, {
    saveReport,
    setDailyReport,
    cleanUp,
    startDailyReportSaveLoading,
})(DailyReportScreen);
