import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { View, Text, FlatList, TouchableOpacity, Modal } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
// import Dash from 'react-native-dash';
import { NavigationBar, ListItem } from '../../../components/index';

import s from './styles';
import { Color } from '../../../theme';

class DailyTaskingListScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
    };

    state = {
        isNewTask: false,
    };

    static _returnItems() {
        return [
            {
                id: '3243546hb5',
                title: 'Completed Tasking list',
                data: [
                    {
                        id: 'asdaf43-34',
                        title: 'Air Cooled Chiller',
                    },
                    {
                        id: '3480sd39-3da',
                        title: 'RTU',
                    },
                ],
            },
            {
                id: '3243546h_345b5',
                title: 'In progress Tasking list',
                data: [
                    {
                        id: '34809-3asda',
                        title: 'Condenser',
                        attachments: 0,
                    },
                ],
            },
        ];
    }

    _returnItem(item, i, length) {
        const { navigation } = this.props;
        return (
            <TouchableOpacity
                key={item.id}
                style={s.itemWrapper}
                onPress={() => {
                    navigation.navigate('DailyNewTask');
                }}
            >
                <Text style={s.itemTitle}>{item.title}</Text>
            </TouchableOpacity>
        );
    }

    static _returnTitleComponent(title) {
        return <Text style={s.sectionHeader}>{title}</Text>;
    }

    _returnTasksArray() {
        const { navigation } = this.props;
        const list = [
            {
                id: 'jk m,kezmiu44',
                title: 'Air Cooled Chiller',
                onPress: () => {
                    this.setState({ isNewTask: false });
                    navigation.navigate('DailyNewTask');
                },
            },
            {
                id: 'ushbjknlcmej44',
                title: 'RTU',
                onPress: () => {
                    this.setState({ isNewTask: false });
                    navigation.navigate('DailyNewTask');
                },
            },
            {
                id: 'ushbjknkmlsd4lcmej44',
                title: 'Condenser',
                onPress: () => {
                    this.setState({ isNewTask: false });
                    navigation.navigate('DailyNewTask');
                },
            },
            {
                id: 'soij4cvt09o4c',
                title: 'MUA',
                onPress: () => {
                    this.setState({ isNewTask: false });
                    navigation.navigate('DailyNewTask');
                },
            },
            {
                id: 'coisv9504',
                title: 'Boiler',
                onPress: () => {
                    this.setState({ isNewTask: false });
                    navigation.navigate('DailyNewTask');
                },
            },
            {
                id: 'nsjkoi490i5f',
                title: 'Colling Tower',
                onPress: () => {
                    this.setState({ isNewTask: false });
                    navigation.navigate('DailyNewTask');
                },
            },
            {
                id: 'fsnlkrio5094',
                title: 'Pump',
                onPress: () => {
                    this.setState({ isNewTask: false });
                    navigation.navigate('DailyNewTask');
                },
            },
            {
                id: 'sm rlnkciovj5904',
                title: 'Unit Heater',
                onPress: () => {
                    this.setState({ isNewTask: false });
                    navigation.navigate('DailyNewTask');
                },
            },
            {
                id: 'sm23948fd3',
                title: 'Water-cooled Chiller',
                onPress: () => {
                    this.setState({ isNewTask: false });
                    navigation.navigate('DailyNewTask');
                },
            },
            {
                id: 'scr4rcziru4',
                title: 'Water-cooled Boiler',
                onPress: () => {
                    this.setState({ isNewTask: false });
                    navigation.navigate('DailyNewTask');
                },
            },
        ];
        return list;
    }

    render() {
        const { isNewTask } = this.state;

        return (
            <View style={[s.wrapper]}>
                <NavigationBar {...this.props} title="TASKING LIST" backIcon />
                <View style={s.menuTopContainer}>
                    <Text style={s.topMenu_title}>{moment().format('DD/MM/YY')}</Text>
                </View>
                <View style={[s.mainContainer]}>
                    {DailyTaskingListScreen._returnItems().map((category) => {
                        return (
                            <View style={s.categoryWrapper} key={category.id}>
                                {DailyTaskingListScreen._returnTitleComponent(category.title)}
                                <View style={s.categoryItemsWrapper}>
                                    {category.data.map((item, i) => {
                                        return this._returnItem(item, i, category.data.length);
                                    })}
                                </View>
                            </View>
                        );
                    })}
                    <TouchableOpacity style={s.addButton} onPress={() => this.setState({ isNewTask: true })}>
                        <Icon name="plus" color={Color.white} size={30} />
                    </TouchableOpacity>
                </View>
                <Modal visible={isNewTask}>
                    <View style={[s.wrapper, { top: -10 }]}>
                        <NavigationBar {...this.props} title="TASK LIST" backIcon />
                        <View style={[s.mainContainer]}>
                            <FlatList
                                data={this._returnTasksArray()}
                                contentContainerStyle={{ paddingBottom: 0 }}
                                keyExtractor={(item) => item && item.id}
                                style={{ elevation: 20, width: '100%' }}
                                renderItem={({ item }) => {
                                    if (item) {
                                        return <ListItem item={item} />;
                                    }
                                    return null;
                                }}
                            />
                        </View>
                    </View>
                </Modal>
            </View>
        );
    }
}

export default DailyTaskingListScreen;
