import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, FlatList, Text } from 'react-native';
import { connect } from 'react-redux';
import { Calendar } from 'react-native-calendars';
import TimeOffRequestModal from '../components/TimeOffRequestModal/TimeOffRequestModal';
import { NavigationBar, PrimaryButton } from '../../../components/index';
import ShiftItem from '../components/ShiftItem';
import TimeOffRequestItem from '../components/TimeOffRequestItem';

import { shiftRequestTimeOff } from '../../../store/modules/schedule';
import { selectScheduleCalendarDate, getShifts } from '../../../store/modules/schedule';

import s from './styles';

class Schedule extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        isLoading: PropTypes.bool,
        user: PropTypes.object,
        shiftTimeOffRequests: PropTypes.array,
        calendarMarkedDates: PropTypes.object,
        selectScheduleCalendarDate: PropTypes.func,
        getShifts: PropTypes.func,
        shiftRequestTimeOff: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            dayShifts: this.filterShifts(),
            dayTimeOffRequests: this.filterTimeOffRequests(),
            isTimeOffRequestModalOpen: false,
            selectedDay: moment(),
        };
    }

    componentDidUpdate(prevProps) {
        const { shiftTimeOffRequests } = this.props;
        const { selectedDay } = this.state;
        if (shiftTimeOffRequests.length != prevProps.shiftTimeOffRequests.length) {
            this.setState({
                dayTimeOffRequests: this.filterTimeOffRequests(selectedDay),
            });
        }
    }

    handleCancelTimeOffRequest = () => {
        this.setState({ isTimeOffRequestModalOpen: false });
    };

    handleSubmitTimeOffRequest = (notes, startTime, endTime) => {
        const { user, shiftRequestTimeOff } = this.props;
        const shift = {
            id: null,
            StartTime: moment(startTime).toISOString(),
            EndTime: moment(endTime).toISOString(),
        };
        this.setState({ isTimeOffRequestModalOpen: false });
        shiftRequestTimeOff(shift, notes, user);
    };

    filterShifts = (selectedDate = null) => {
        const { calendarMarkedDates } = this.props;

        if (!selectedDate) {
            selectedDate = moment().format('YYYY-MM-DD');
        }

        const filteredShifts = calendarMarkedDates[selectedDate] ? calendarMarkedDates[selectedDate].shifts : [];
        return filteredShifts;
    };

    filterTimeOffRequests = (selectedDate = null) => {
        const { shiftTimeOffRequests } = this.props;

        if (selectedDate) {
            selectedDate = moment(selectedDate);
        } else {
            selectedDate = moment();
        }

        const filteredTimeOffRequests = shiftTimeOffRequests.filter((req) =>
            moment(req.ShiftStartTime).isSame(selectedDate, 'day'),
        );
        return filteredTimeOffRequests;
    };

    handleDaySelection = (day) => {
        const { selectScheduleCalendarDate } = this.props;
        this.setState({
            dayShifts: this.filterShifts(day.dateString),
            dayTimeOffRequests: this.filterTimeOffRequests(day.dateString),
            selectedDay: moment(day.dateString),
        });
        selectScheduleCalendarDate(day.dateString);
    };

    handleMonthChange = (day) => {
        const { user, getShifts } = this.props;
        getShifts(user._id, day.dateString);
    };

    handleShiftPress = (shift) => {
        const { navigation } = this.props;
        navigation.navigate('ShiftDetails', { shift });
    };

    render() {
        const { dayShifts, dayTimeOffRequests, isTimeOffRequestModalOpen, selectedDay } = this.state;
        const { calendarMarkedDates, isLoading } = this.props;

        return (
            <View style={s.wrapper}>
                <NavigationBar {...this.props} title="My Schedule" backIcon {...this.props} />
                <Calendar
                    theme={{
                        arrowHeight: 20,
                        arrowWidth: 20,
                    }}
                    enableSwipeMonths
                    minDate={moment().format('YYYY-MM-DD')}
                    markedDates={calendarMarkedDates}
                    onDayPress={(day) => this.handleDaySelection(day)}
                    onMonthChange={(day) => this.handleMonthChange(day)}
                />

                <View style={s.mainContainer}>
                    <FlatList
                        style={s.listWrapper}
                        data={dayShifts}
                        renderItem={({ item }) => {
                            return <ShiftItem shift={item} onShiftPress={this.handleShiftPress} />;
                        }}
                        keyExtractor={(item, i) => (item ? item.id : i)}
                        contentContainerStyle={s.listContentContainer}
                        ListEmptyComponent={<Text style={s.listTitle}>No Shifts Available</Text>}
                    />

                    <FlatList
                        style={s.listWrapper}
                        data={dayTimeOffRequests}
                        renderItem={({ item }) => {
                            return <TimeOffRequestItem timeOffRequest={item} />;
                        }}
                        keyExtractor={(item, i) => (item ? item.id : i)}
                        contentContainerStyle={s.listContentContainer}
                        ListEmptyComponent={<Text style={s.listTitle}>No Time Off Requested</Text>}
                    />
                    {dayShifts.length < 1 ? (
                        <PrimaryButton
                            onPress={() => this.setState({ isTimeOffRequestModalOpen: true })}
                            title="Request Day Off"
                        />
                    ) : null}

                    <TimeOffRequestModal
                        isOpen={isTimeOffRequestModalOpen}
                        isLoading={isLoading}
                        onCancel={this.handleCancelTimeOffRequest}
                        onSubmit={this.handleSubmitTimeOffRequest}
                        selectedDay={selectedDay.toDate()}
                    />
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ account, schedule }) => ({
    user: account.user,
    calendarMarkedDates: schedule.calendarMarkedDates,
    isLoading: schedule.isLoading,
    shiftTimeOffRequests: schedule.shiftTimeOffRequests,
});

export default connect(mapStateToProps, {
    selectScheduleCalendarDate,
    getShifts,
    shiftRequestTimeOff,
})(Schedule);
