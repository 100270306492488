import { StyleSheet, Dimensions } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        paddingVertical: 20,
        height: '100%',
        width: '100%',
        paddingHorizontal: '5%',
    },
    listItemWrapper: {
        width: '100%',
        backgroundColor: Color.white,
        borderRadius: 6,
        flexDirection: 'row',
        alignItems: 'center',
        padding: 15,
        marginBottom: 10,
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    listItemTitleText: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue,
    },
    listItemDateText: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font10,
        color: '#b2becc',
    },
    listItemIcon: {
        fontSize: Font.size.font30,
        color: Color.turquoise,
        marginRight: 20,
    },
    menuTopContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'column',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    menuTopTitle: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font12,
        color: Color.dark_navy_blue,
        marginBottom: 5.5,
        marginTop: -5.5,
    },
    pdfViewer: {
        flex: 1,
        marginLeft:'40%',
        marginBottom: 20,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height - 200,
    },
    adminIcon: {
        fontSize: Font.size.font24,
        color: '#b2becc',
        marginTop: 6,
        marginLeft: 120,
    },
});
