import { StyleSheet } from 'react-native';
import { Color } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        paddingTop: 20,
        height: '100%',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bottomButtonsBlock: {
        backgroundColor: Color.pale_grey_two,
        alignItems: 'center',
        paddingVertical: 15,
    },
    addButton: {
        width: 43,
        height: 43,
        borderRadius: 22,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.turquoise,
        elevation: 5,
    },
});
