import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Animated, Easing, ScrollView, Linking, Platform } from 'react-native';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import AntDesignIcon from 'react-native-vector-icons/AntDesign';
import { connect } from 'react-redux';
import { NavigationActions } from 'react-navigation';
import { clearClockOutError } from '../../store/modules/currentTimeCard';
import { clearUnsavedTimeCards, syncTimeCard } from '../../store/modules/timeCards';

import { userLogOut } from '../../store/modules/account';
import { getAllShifts } from '../../store/modules/schedule';
import { getPayrollCheckDates } from '../../store/modules/account';
import hasAccessTo from '../../utils/auth';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { Config } from '../../config';
import { Color } from '../../theme';
import s from './styles';

const isIos = Platform.OS === 'ios';

class LeftMenu extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        activeItemKey: PropTypes.string,
        isOpen: PropTypes.bool,
        currentTimeCard: PropTypes.object,
        user: PropTypes.object,
        settings: PropTypes.object,
        unsavedCards: PropTypes.array,
        timeCardsIsSyncing: PropTypes.bool,
        firstPayPeriod: PropTypes.object,
        syncTimeCard: PropTypes.func,
        userLogOut: PropTypes.func,
        clearUnsavedTimeCards: PropTypes.func,
        clearClockOutError: PropTypes.func,
        getAllShifts: PropTypes.func,
        getPayrollCheckDates: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            openAnimatedValue: new Animated.Value(props.isOpen ? 1 : 0),
        };
    }

    componentDidUpdate(prevProps) {
        const { isOpen } = this.props;
        if (prevProps.isOpen !== isOpen) {
            this.open();
        }
    }

    open() {
        const { isOpen } = this.props;
        const { openAnimatedValue } = this.state;
        openAnimatedValue.setValue(isOpen ? 0 : 1);
        Animated.timing(openAnimatedValue, {
            toValue: isOpen ? 1 : 0,
            duration: 500,
            easing: Easing.linear,
        }).start();
    }

    navigateTo = (source, route, subRoute = null, params = null, isClose = false, skipAccessCheck = false) => {
        const { navigation, user } = this.props;
        if (skipAccessCheck || hasAccessTo(user, source)) {
            if (subRoute && params) {
                navigation.navigate(
                    route,
                    {},
                    NavigationActions.navigate({
                        routeName: subRoute,
                        params,
                    }),
                );
            } else if (subRoute) {
                navigation.navigate(route, {}, NavigationActions.navigate({ routeName: subRoute }));
            } else {
                navigation.navigate(route);
            }

            if (isClose) {
                navigation.closeDrawer();
            }
        } else {
            navigation.navigate('LockedModule');
        }
    };

    logout = async () => {
        const { userLogOut, navigation } = this.props;
        navigation.navigate('StartStack');
        await this.postUnsavedTimeCards();
        userLogOut();
    };

    async postUnsavedTimeCards() {
        const { user, unsavedCards, timeCardsIsSyncing, syncTimeCard, firstPayPeriod } = this.props;

        if (unsavedCards && unsavedCards.length && !timeCardsIsSyncing) {
            return syncTimeCard(unsavedCards, user, firstPayPeriod, true);
        }

        return true;
    }

    openEmail = () => {
        Linking.openURL('mailto:support@trades2work.zendesk.com');
    };

    openEmailSendTimeCard = () => {
        const { unsavedCards, clearUnsavedTimeCards } = this.props;
        if (unsavedCards.length > 0) {
            Linking.openURL(
                `mailto:triston@jaybirdgroup.com?subject=Unsaved Time Cards&body=${JSON.stringify(unsavedCards)}`,
            );
            clearUnsavedTimeCards();
        }
    };

    openEmailClearCurrentTimeCard = () => {
        const { clearClockOutError, currentTimeCard } = this.props;
        const message = `Current Time Card\n${JSON.stringify(currentTimeCard)}`;
        Linking.openURL(`mailto:triston@jaybirdgroup.com?subject=Unsaved Time Cards&body=${message}`);
        clearClockOutError();
    };

    render() {
        const { navigation, activeItemKey, user, settings, getAllShifts, getPayrollCheckDates } = this.props;
        return (
            <View style={s.menu} contentContainerStyle={s.container}>
                <View style={s.menuTopContainer}>
                    <View>
                        <Text style={s.userName}>{user && user.fullName}</Text>
                        <Text style={s.userMail}>{user && user.email}</Text>
                        <Text style={s.userMail}>{settings && settings.company}</Text>
                    </View>
                    <TouchableOpacity onPress={() => navigation.closeDrawer()}>
                        <View style={s.closeMenuIcon}>
                            <Icon name="close" color={Color.white} size={isIos ? 32 : 26} />
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={s.menuContainer}>
                    <ScrollView>
                        <View style={s.menuTop}>
                            {hasAccessTo(user, Config.TIMECARDS) && (
                                <TouchableOpacity
                                    onPress={() => {
                                        this.navigateTo(Config.TIMECARDS, 'TimeTracking', null, null, true, true);
                                    }}
                                >
                                    <View style={s.menuItem}>
                                        <Icon
                                            name="timer"
                                            style={[
                                                s.menuItemIcon,
                                                activeItemKey === 'TimeTracking' && s.menuItemIconActive,
                                            ]}
                                        />
                                        <Text
                                            style={[
                                                s.menuItemText,
                                                activeItemKey === 'TimeTracking' && s.menuItemTextActive,
                                            ]}
                                        >
                                            Time Tracking
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            )}

                            {hasAccessTo(user, null, 'use-bulk-tcm') && (
                                <TouchableOpacity
                                    onPress={() => {
                                        this.navigateTo(Config.TIMECARDS, 'BulkTimeCardEdit', null, null, true, true);
                                    }}
                                >
                                    <View style={s.menuItem}>
                                        <Icon
                                            name="timer"
                                            style={[
                                                s.menuItemIcon,
                                                activeItemKey === 'BulkTimeCardEdit' && s.menuItemIconActive,
                                            ]}
                                        />
                                        <Text
                                            style={[
                                                s.menuItemText,
                                                activeItemKey === 'BulkTimeCardEdit' && s.menuItemTextActive,
                                            ]}
                                        >
                                            Bulk Time Card Edits
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            )}
                            <TouchableOpacity
                                onPress={() => {
                                    getAllShifts(user._id);
                                    this.navigateTo(null, 'Schedule', 'ScheduleDashboard', null, true, true);
                                }}
                            >
                                <View style={s.menuItem}>
                                    <Icon
                                        name="timer"
                                        style={[s.menuItemIcon, activeItemKey === 'Schedule' && s.menuItemIconActive]}
                                    />
                                    <Text
                                        style={[s.menuItemText, activeItemKey === 'Schedule' && s.menuItemTextActive]}
                                    >
                                        Schedule
                                    </Text>
                                </View>
                            </TouchableOpacity>

                            <TouchableOpacity
                                onPress={() => {
                                    if (user.meta.PayFrequency) {
                                        getPayrollCheckDates(user.meta.PayFrequency);
                                    }
                                    this.navigateTo(null, 'PayrollCheckDate', 'PayrollCheckDate', null, true, true);
                                }}
                            >
                                <View style={s.menuItem}>
                                    <Icon
                                        name="calendar-multiple-check"
                                        style={[
                                            s.menuItemIcon,
                                            activeItemKey === 'PayrollCheckDate' && s.menuItemIconActive,
                                        ]}
                                    />
                                    <Text
                                        style={[
                                            s.menuItemText,
                                            activeItemKey === 'PayrollCheckDate' && s.menuItemTextActive,
                                        ]}
                                    >
                                        Pay Check Dates
                                    </Text>
                                </View>
                            </TouchableOpacity>

                            {hasAccessTo(user, Config.REPORTS) && (
                                <TouchableOpacity
                                    onPress={() => {
                                        this.navigateTo(
                                            Config.REPORTS,
                                            'TimeTracking',
                                            'ReportsStack',
                                            null,
                                            false,
                                            true,
                                        );
                                    }}
                                >
                                    <View style={s.menuItem}>
                                        <Icon
                                            name="file-document-outline"
                                            style={[
                                                s.menuItemIcon,
                                                activeItemKey === 'DailyReporting' && s.menuItemIconActive,
                                            ]}
                                        />
                                        <Text
                                            style={[
                                                s.menuItemText,
                                                activeItemKey === 'DailyReporting' && s.menuItemTextActive,
                                            ]}
                                        >
                                            Daily Reporting
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            )}
                            {hasAccessTo(user, Config.PROJECTS) && (
                                <TouchableOpacity
                                    onPress={() => {
                                        this.navigateTo(
                                            Config.PROJECTS,
                                            'ProjectsStandalone',
                                            'Projects',
                                            {
                                                standalone: true,
                                                isTimeCardEdit: false,
                                                isInjuryReportEdit: false,
                                            },
                                            false,
                                            true,
                                        );
                                    }}
                                >
                                    <View style={s.menuItem}>
                                        <Icon
                                            name="file-document-outline"
                                            style={[
                                                s.menuItemIcon,
                                                activeItemKey === 'ProjectsStandalone' && s.menuItemIconActive,
                                            ]}
                                        />
                                        <Text
                                            style={[
                                                s.menuItemText,
                                                activeItemKey === 'ProjectsStandalone' && s.menuItemTextActive,
                                            ]}
                                        >
                                            {Config.USE_DEPARTMENTS_JOBS ? 'Departments' : 'Projects'}
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            )}
                            {hasAccessTo(user, Config.CUT_SHEETS) && (
                                <TouchableOpacity
                                    onPress={() => {
                                        navigation.navigate('ItemFabrication');
                                    }}
                                >
                                    <View style={s.menuItem}>
                                        <Icon
                                            name="circular-saw"
                                            style={[
                                                s.menuItemIcon,
                                                activeItemKey === 'ItemFabrication' && s.menuItemIconActive,
                                            ]}
                                        />
                                        <Text
                                            style={[
                                                s.menuItemText,
                                                activeItemKey === 'ItemFabrication' && s.menuItemTextActive,
                                            ]}
                                        >
                                            Item Fabrication
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            )}
                            {/* <TouchableOpacity
              onPress={() => {
                navigation.navigate(
                  'OutstandingReports',
                  {},
                  NavigationActions.navigate({ routeName: 'OutstandingReportsList' }),
                )
              }}>
              <View style={s.menuItem}>
                <Icon
                  name="folder-clock-outline"
                  style={[
                    s.menuItemIcon,
                    activeItemKey === 'DailyReporting' && s.menuItemIconActive,
                  ]}
                />
                <Text
                  style={[
                    s.menuItemText,
                    activeItemKey === 'DailyReporting' && s.menuItemTextActive,
                  ]}>
                  Outstanding Reports/Timecards
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('ItemRequest')
              }}>
              <View style={s.menuItem}>
                <Icon
                  name="calendar-clock"
                  style={[
                    s.menuItemIcon,
                    activeItemKey === 'ItemRequest' && s.menuItemIconActive,
                  ]}
                />
                <Text
                  style={[
                    s.menuItemText,
                    activeItemKey === 'ItemRequest' && s.menuItemTextActive,
                  ]}>
                  Item Request
                </Text>
              </View>
            </TouchableOpacity> */}
                            {/* <TouchableOpacity
                onPress={() => {
                  navigation.navigate('TimeAndMaterial')
                }}
              >
                <View style={s.menuItem}>
                  <Icon
                    name="layers-outline"
                    style={[s.menuItemIcon, activeItemKey === 'TimeAndMaterial' && s.menuItemIconActive]}
                  />
                  <Text style={[s.menuItemText, activeItemKey === 'TimeAndMaterial' && s.menuItemTextActive]}>
                    Cost per Material
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('ChangeOrders', {}, NavigationActions.navigate({ routeName: 'ReportsStack' }))
                }}
              >
                <View style={s.menuItem}>
                  <Icon
                    name="swap-vertical"
                    style={[s.menuItemIcon, activeItemKey === 'ChangeOrders' && s.menuItemIconActive]}
                  />
                  <Text style={[s.menuItemText, activeItemKey === 'ChangeOrders' && s.menuItemTextActive]}>
                    Change Orders
                  </Text>
                </View>
              </TouchableOpacity> */}
                            {hasAccessTo(user, Config.REIMBURSEMENT_ITEMS) &&
                                hasAccessTo(user, Config.REIMBURSEMENT_REQUESTS) && (
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.navigateTo(
                                                Config.REIMBURSEMENT_REQUESTS,
                                                'ReimbursementList',
                                                null,
                                                null,
                                                true,
                                                true,
                                            );
                                        }}
                                    >
                                        <View style={s.menuItem}>
                                            <Icon
                                                name="credit-card-refund-outline"
                                                style={[
                                                    s.menuItemIcon,
                                                    activeItemKey === 'Reimbursement' && s.menuItemIconActive,
                                                ]}
                                            />
                                            <Text
                                                style={[
                                                    s.menuItemText,
                                                    activeItemKey === 'Reimbursement' && s.menuItemTextActive,
                                                ]}
                                            >
                                                Reimbursement
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                )}

                            {hasAccessTo(user, Config.TIME_OFF) && (
                                <TouchableOpacity onPress={() => this.navigateTo(Config.TIME_OFF, 'PaidTimeOff')}>
                                    <View style={s.menuItem}>
                                        <Icon
                                            name="calendar-check-outline"
                                            style={[
                                                s.menuItemIcon,
                                                activeItemKey === 'PaidTimeOff' && s.menuItemIconActive,
                                            ]}
                                        />
                                        <Text
                                            style={[
                                                s.menuItemText,
                                                activeItemKey === 'PaidTimeOff' && s.menuItemTextActive,
                                            ]}
                                        >
                                            Paid Time Off
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            )}
                            {hasAccessTo(user, Config.CUSTOM_REPORTS) && (
                                <TouchableOpacity
                                    onPress={() => this.navigateTo(Config.CUSTOM_REPORTS, 'CustomReportScreen')}
                                >
                                    <View style={s.menuItem}>
                                        <Icon
                                            name="calendar-check-outline"
                                            style={[
                                                s.menuItemIcon,
                                                activeItemKey === 'CustomReportScreen' && s.menuItemIconActive,
                                            ]}
                                        />
                                        <Text
                                            style={[
                                                s.menuItemText,
                                                activeItemKey === 'CustomReportScreen' && s.menuItemTextActive,
                                            ]}
                                        >
                                            Custom Reports
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            )}
                            {hasAccessTo(user, Config.ACCIDENT_REPORTING) && (
                                <TouchableOpacity
                                    onPress={() => {
                                        this.navigateTo(Config.ACCIDENT_REPORTING, 'AccidentReporting');
                                    }}
                                >
                                    <View style={s.menuItem}>
                                        <Icon
                                            name="airbag"
                                            style={[
                                                s.menuItemIcon,
                                                activeItemKey === 'AccidentReporting' && s.menuItemIconActive,
                                            ]}
                                        />
                                        <Text
                                            style={[
                                                s.menuItemText,
                                                activeItemKey === 'AccidentReporting' && s.menuItemTextActive,
                                            ]}
                                        >
                                            Accident Reporting
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            )}
                            {hasAccessTo(user, Config.TOOLBOX_TALKS) && (
                                <TouchableOpacity onPress={() => this.navigateTo(Config.TOOLBOX_TALKS, 'ToolboxTalk')}>
                                    <View style={s.menuItem}>
                                        <Icon
                                            name="calendar-check-outline"
                                            style={[
                                                s.menuItemIcon,
                                                activeItemKey === 'ToolboxTalk' && s.menuItemIconActive,
                                            ]}
                                        />
                                        <Text
                                            style={[
                                                s.menuItemText,
                                                activeItemKey === 'ToolboxTalk' && s.menuItemTextActive,
                                            ]}
                                        >
                                            Toolbox Talks
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            )}
                            {hasAccessTo(user, Config.INJURY_REPORTING_TICKETS) && (
                                <TouchableOpacity
                                    onPress={() => this.navigateTo(Config.INJURY_REPORTING_TICKETS, 'InjuryReporting')}
                                >
                                    <View style={s.menuItem}>
                                        <Icon
                                            name="clipboard-text-outline"
                                            style={[
                                                s.menuItemIcon,
                                                activeItemKey === 'InjuryReporting' && s.menuItemIconActive,
                                            ]}
                                        />
                                        <Text
                                            style={[
                                                s.menuItemText,
                                                activeItemKey === 'InjuryReporting' && s.menuItemTextActive,
                                            ]}
                                        >
                                            Injury Reporting
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            )}
                            {hasAccessTo(user, Config.EMPLOYEE_HANDBOOK) && (
                                <TouchableOpacity
                                    onPress={() => this.navigateTo(Config.EMPLOYEE_HANDBOOK, 'EmployeeHandbook')}
                                >
                                    <View style={s.menuItem}>
                                        <AntDesignIcon
                                            name="book"
                                            style={[
                                                s.menuItemIcon,
                                                { fontSize: 29 },
                                                activeItemKey === 'EmployeeHandbook' && s.menuItemIconActive,
                                            ]}
                                        />
                                        <Text
                                            style={[
                                                s.menuItemText,
                                                activeItemKey === 'EmployeeHandbook' && s.menuItemTextActive,
                                            ]}
                                        >
                                            Employee Handbook
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            )}

                            <TouchableOpacity onPress={this.openEmail}>
                                <View style={s.menuItem}>
                                    <Icon name="face-agent" style={[s.menuItemIconSupport]} />
                                    <Text style={[s.menuItemTextSupport]}>Support</Text>
                                </View>
                            </TouchableOpacity>
                            {hasAccessTo(user, Config.TIMECARDS) && (
                                <TouchableOpacity onPress={this.openEmailSendTimeCard}>
                                    <View style={s.menuItem}>
                                        <Icon name="export" style={[s.menuItemIconSupport, { paddingLeft: 2 }]} />
                                        <Text style={[s.menuItemTextSupport, { marginLeft: 21 }]}>
                                            Send Offline Time Card
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            )}
                            {hasAccessTo(user, Config.TIMECARDS) && (
                                <TouchableOpacity onPress={this.openEmailClearCurrentTimeCard}>
                                    <View style={s.menuItem}>
                                        <Icon name="export" style={[s.menuItemIconSupport, { paddingLeft: 2 }]} />
                                        <Text style={[s.menuItemTextSupport, { marginLeft: 21 }]}>
                                            Clear Current Time Card
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            )}
                            {/* <TouchableOpacity
              onPress={() => {
                navigation.navigate(
                  'AdminViewer',
                  {},
                  NavigationActions.navigate({ routeName: 'AdminViewerList' }),
                )
              }}>
              <View style={s.menuItem}>
                <Icon
                  name="clipboard-text-outline"
                  style={[
                    s.menuItemIcon,
                    activeItemKey === 'AdminViewer' && s.menuItemIconActive,
                  ]}
                />
                <Text
                  style={[
                    s.menuItemText,
                    activeItemKey === 'AdminViewer' && s.menuItemTextActive,
                  ]}>
                  Admin Viewer
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate(
                  'HowTo',
                  {},
                  NavigationActions.navigate({ routeName: 'HowToList' }),
                )
              }}>
              <View style={s.menuItem}>
                <Icon
                  name="clipboard-text-outline"
                  style={[
                    s.menuItemIcon,
                    activeItemKey === 'HowTo' && s.menuItemIconActive,
                  ]}
                />
                <Text
                  style={[
                    s.menuItemText,
                    activeItemKey === 'HowTo' && s.menuItemTextActive,
                  ]}>
                  How To
                </Text>
              </View>
            </TouchableOpacity> */}
                            <TouchableOpacity
                                onPress={() => {
                                    navigation.navigate(
                                        'HowTo',
                                        {},
                                        NavigationActions.navigate({ routeName: 'HowToList' }),
                                    );
                                }}
                            >
                                <View style={s.menuItem}>
                                    <Icon
                                        name="help-circle"
                                        style={[s.menuItemIcon, activeItemKey === 'HowTo' && s.menuItemIconActive]}
                                    />
                                    <Text style={[s.menuItemText, activeItemKey === 'HowTo' && s.menuItemTextActive]}>
                                        About
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={s.menuButton}>
                            <PrimaryButton white title="LOGOUT" icon="logout-variant" onPress={this.logout} />
                        </View>
                    </ScrollView>
                </View>
                <Text style={s.versionText}>{Config.ENVIRONMENT}</Text>
            </View>
        );
    }
}

const mapStateToProps = ({ account, currentTimeCard, timeCards }) => ({
    user: account.user,
    firstPayPeriod: timeCards.firstPayPeriod,
    currentTimeCard,
    unsavedCards: timeCards.unsavedCards,
    timeCardsIsSyncing: timeCards.timeCardsIsSyncing,
    settings: account.settings,
});

export default connect(mapStateToProps, {
    userLogOut,
    clearUnsavedTimeCards,
    clearClockOutError,
    getAllShifts,
    syncTimeCard,
    getPayrollCheckDates,
})(LeftMenu);
