export default {
    TYPE: {
        TEXT_FIELD: 'TEXT_FIELD',
        DROPDOWN_LIST: 'DROPDOWN_LIST',
        ITEMS_ARRAY_LIST: 'ITEMS_ARRAY_LIST',
    },
    SIZE: {
        FULL_WIDTH: 'FULL_WIDTH',
        HALF_WIDTH: 'HALF_WIDTH',
        THIRD_WIDTH: 'THIRD_WIDTH',
        TWO_THIRD_WIDTH: 'TWO_THIRD_WIDTH',
    },
};
