import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { ActivityIndicator, Image, Modal, Text, TouchableOpacity, View } from 'react-native';
import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import { showMessage } from 'react-native-flash-message';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { isEmpty } from 'lodash';
import { Color } from '../../theme';
import LinkButton from '../LinkButton/LinkButton';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import SignatureCanvas from 'react-signature-canvas';
import { dataURItoBlob } from '../../utils/helpers';

import s from './styles';

export default class SignModal extends Component {
    static propTypes = {
        generalRules: PropTypes.object,
        attachments: PropTypes.object,
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        isAttachments: PropTypes.bool,
        isLoading: PropTypes.bool,
        onCancel: PropTypes.func,
        onSave: PropTypes.func,
    };

    state = {
        isSigned: false,
    };
    signature = null;

    _showErrorMessage(isCamera, errorCode, errorMessage) {
        let message = 'Storage Access Denied';
        let description = 'Cannot add attachments until storage access granted';

        if (isCamera) {
            message = 'Storage or Camera Access Denied';
            description = 'Cannot add attachments until storage and camera access granted';
        }

        if (errorCode && errorCode === 'camera_unavailable') {
            message = 'Camera Unavailable';
            description = 'Cannot add attachments';
        } else if (errorCode && errorCode === 'others') {
            message = 'Error';
            description = errorMessage;
        }

        showMessage({
            message,
            description,
            type: 'danger',
            duration: 10000,
            animationDuration: 700,
            hideOnPress: true,
            hideStatusBar: true,
            backgroundColor: Color.red,
        });
    }

    handleClearPress = () => {
        this.signature.clear();
    };

    onSubmit = () => {
        const { onSave } = this.props;
        const dataUrl = this.signature.toDataURL();
        onSave(dataURItoBlob(dataUrl));
    };

    selectPhotoFromLibrary = async () => {
        const { attachments } = this.props;
        const { uploadStart, saveAttachments } = attachments;

        const response = await launchImageLibrary({
            mediaType: 'photo',
            quality: 1,
            maxWidth: 900,
            maxHeight: 900,
            includeBase64: true,
            selectionLimit: 0,
        });
        const { didCancel, errorCode, errorMessage, assets } = response;
        if (!didCancel && !errorCode) {
            uploadStart();
            saveAttachments(assets);
        } else {
            this._showErrorMessage(false, errorCode, errorMessage);
        }
    };

    createPhoto = async () => {
        const { attachments } = this.props;
        const { uploadStart, saveAttachments } = attachments;

        const response = await launchCamera({
            mediaType: 'photo',
            quality: 1,
            maxWidth: 900,
            maxHeight: 900,
            includeBase64: true,
            selectionLimit: 0,
            saveToPhotos: true,
        });
        const { didCancel, errorCode, errorMessage, assets } = response;
        if (!didCancel && !errorCode) {
            uploadStart();
            saveAttachments(assets);
        } else {
            this._showErrorMessage(true, errorCode, errorMessage);
        }
    };

    _returnAttachment(attachment, i) {
        const isConnected = true;
        const { attachments } = this.props;
        const { removeAttachment } = attachments;
        if (isConnected) {
            return (
                <View style={s.attachmentItem} key={i}>
                    <Image source={{ uri: attachment.File }} style={s.attachmentImage} />
                    <TouchableOpacity style={s.attachmentButton} onPress={() => removeAttachment(attachment.File)}>
                        <Icon name="close" size={25} color={Color.white} />
                    </TouchableOpacity>
                </View>
            );
        }
        return (
            <View style={s.offline} key={i}>
                <Icon name="wifi-off" size={30} color={Color.cloudy_blue} />
                <Text style={s.offlineText}>You are offline, connect to internet</Text>
            </View>
        );
    }

    render() {
        const { isSigned } = this.state;
        const { isOpen, onCancel, isAttachments, attachments, title, isLoading, generalRules } = this.props;
        return (
            <Modal visible={isOpen} animationType="fade" transparent presentationStyle="overFullScreen">
                <View style={s.modalWrapper}>
                    <View style={[s.modalBlock, isAttachments && s.modalBlock_attachments]}>
                        <Text style={s.modalTitle}>{title}</Text>

                        {isLoading ? (
                            <View style={s.modalSignature}>
                                <ActivityIndicator size={40} color={Color.blue} />
                            </View>
                        ) : (
                            <View style={s.modalSignature}>
                                <SignatureCanvas
                                    ref={(c) => {
                                        this.signature = c;
                                    }}
                                    canvasProps={{
                                        height: 200,
                                        style: {
                                            maxWidth: 900,
                                            height: 200,
                                            width: '100%',
                                            borderBottom: '1px solid #a8a8a8',
                                            zIndex: 50,
                                        },
                                    }}
                                    style={s.signature}
                                    onEnd={() => this.setState({ isSigned: true })}
                                />
                                <Text style={s.signaturePlaceholder}>Please sign here</Text>
                            </View>
                        )}

                        {isAttachments && (
                            <View style={s.uploadBlock}>
                                <View style={s.uploadBlockHeader}>
                                    <Text style={s.uploadBlockTitle}>ATTACH COMPLETE PHOTO</Text>
                                    <View style={{ flexDirection: 'row' }}>
                                        <TouchableOpacity
                                            style={s.uploadIcon}
                                            onPress={() => this.selectPhotoFromLibrary()}
                                        >
                                            <Icon name="attachment" color={Color.light_navy} size={16} />
                                        </TouchableOpacity>
                                        <TouchableOpacity style={s.uploadIcon} onPress={() => this.createPhoto()}>
                                            <Icon name="camera" color={Color.light_navy} size={16} />
                                        </TouchableOpacity>
                                    </View>
                                </View>
                                {(!isEmpty(attachments.attachments) || !isEmpty(attachments.uploadings)) && (
                                    <View style={s.attachmentsWrapper}>
                                        {attachments.attachments &&
                                            attachments.attachments.map((a, i) => {
                                                return this._returnAttachment(a, i);
                                            })}
                                        {attachments.uploadings &&
                                            attachments.uploadings.map((u, i) => {
                                                return (
                                                    <View style={s.attachmentItem} key={i}>
                                                        <ActivityIndicator size={40} color={Color.blue} />
                                                    </View>
                                                );
                                            })}
                                    </View>
                                )}
                            </View>
                        )}

                        <Text style={s.textAttestation}>
                            I hereby attest that the time card submitted accurately reflects the hours worked during the
                            specified period. I have reviewed the records and confirm that they align with the work
                            performed during that time frame.
                        </Text>
                        {generalRules && generalRules.showAttestation && generalRules.attestationText ? (
                            <Text style={s.textAttestation}>{generalRules.attestationText}</Text>
                        ) : null}
                        <View style={s.modalButtons}>
                            <View>
                                <View style={s.cancelModalButton}>
                                    <LinkButton title="CLEAR" onPress={this.handleClearPress} />
                                </View>
                            </View>
                            <View style={s.buttonsWrapper}>
                                <View style={s.cancelModalButton}>
                                    <LinkButton title="CANCEL" onPress={onCancel} />
                                </View>
                                <View style={s.submitModalButton}>
                                    <PrimaryButton
                                        md
                                        title="SUBMIT FOR APPROVAL"
                                        onPress={this.onSubmit}
                                        disabled={!isSigned || isLoading}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        );
    }
}
