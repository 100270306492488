export default {
    GET_TICKETS: {
        LOADING: 'GET_ACCIDENT_REPORTING_TICKETS',
        SUCCESS: 'GET_ACCIDENT_REPORTING_TICKETS_SUCCESS',
        ERROR: 'GET_ACCIDENT_REPORTING_TICKETS_ERROR',
    },
    CREATE_TICKET: 'CREATE_ACCIDENT_REPORTING_TICKET',
    SET_TICKET: 'SET_ACCIDENT_REPORTING_TICKET',
    FINISH_EDITING: 'ACCIDENT_REPORTING_TICKET_FINISH_EDITING',
    SAVE_STEP_TWO: 'SAVE_STEP_TWO',
    SUBMIT_TICKET: {
        LOADING: 'SUBMIT_ACCIDENT_REPORTING_TICKET',
        SUCCESS: 'SUBMIT_ACCIDENT_REPORTING_TICKET_SUCCESS',
        ERROR: 'SUBMIT_ACCIDENT_REPORTING_TICKET_ERROR',
    },
    REMOVE_ATTACHMENT: 'REMOVE_ACCIDENT_REPORTING_TICKET_ATTACHMENT',
    SAVE_ATTACHMENT: 'SAVE_ACCIDENT_REPORTING_TICKET_ATTACHMENT',
};
