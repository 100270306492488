import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, Modal, TouchableOpacity, ScrollView } from 'react-native';
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import NetInfo from '@react-native-community/netinfo';
import ImageViewer from 'react-native-image-zoom-viewer';
import { NavigationBar, Filter } from '../../../components/index';
import FILTERS from '../../../config/filtersCNST';
import s from './styles';
import { Color } from '../../../theme/index';

class GalleryScreen extends Component {
    static propTypes = {
        currentProject: PropTypes.object,
        viewingProject: PropTypes.object,
        currentReport: PropTypes.object,
        currentTimeCard: PropTypes.object,
        reports: PropTypes.array,
    };

    state = {
        currentFilter: FILTERS.ALL_TIME,
        filterValue: '',
        isConnected: true,
        imageSelected: false,
        imageIndex: 0,
    };

    componentDidMount() {
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            this.setState({ isConnected });
        });
    }

    _selectImage(images, selectedImage) {
        const imageIndex = images.findIndex((image) => {
            return selectedImage.File === image.url;
        });
        this.setState({ imageSelected: true, imageIndex });
    }

    _returnItems() {
        const { currentProject, reports, viewingProject } = this.props;
        const { currentFilter, filterValue } = this.state;
        let items = [];
        let images = [];
        const id = currentProject ? currentProject.id : viewingProject ? viewingProject.id : null;
        if (id) {
            const projectReports = reports.filter((r) => r.Project === id);
            projectReports.forEach((report) => {
                const data = [];
                report.Notes.forEach((note) => {
                    note.Attachment.forEach((attachment) => {
                        data.push(attachment);
                        images.push({ url: attachment.File });
                    });
                });
                report.Survey.forEach((survey) => {
                    survey.Attachment.forEach((attachment) => {
                        data.push(attachment);
                        images.push({ url: attachment.File });
                    });
                });
                items.push({
                    title: report.Date,
                    data,
                });
            });
            if (currentFilter !== FILTERS.ALL_TIME) {
                images = [];
                items = items.filter((item) => {
                    const itemDate = moment(item.title, 'MM-DD-YYYY');
                    if (currentFilter === FILTERS.DAY) {
                        if (moment(itemDate).isSame(moment(filterValue), 'day')) {
                            item.data.forEach((attachment) => {
                                images.push({ url: attachment.File });
                            });
                            return true;
                        }
                        return false;
                    }
                    if (currentFilter === FILTERS.WEEK) {
                        const startOfWeek = moment(filterValue).startOf('week');
                        const endOfWeek = moment(filterValue).endOf('week');
                        if (moment(itemDate).isBetween(startOfWeek, endOfWeek, null, '[]')) {
                            item.data.forEach((attachment) => {
                                images.push({ url: attachment.File });
                            });
                            return true;
                        }
                        return false;
                    }
                    if (currentFilter === FILTERS.MONTH) {
                        const startOfMonth = moment(filterValue).startOf('month');
                        const endOfMonth = moment(filterValue).endOf('month');
                        if (moment(itemDate).isBetween(startOfMonth, endOfMonth, null, '[]')) {
                            item.data.forEach((attachment) => {
                                images.push({ url: attachment.File });
                            });
                            return true;
                        }
                        return false;
                    }
                    return null;
                });
            }
        }
        return { items, images };
    }

    render() {
        const { currentFilter, isConnected, imageSelected, imageIndex } = this.state;
        const { items, images } = this._returnItems();
        const filter = (
            <Filter
                currentFilter={currentFilter}
                onFilterSelect={(filter, filterValue) => this.setState({ currentFilter: filter, filterValue })}
            />
        );

        return (
            <View style={[s.wrapper]}>
                <NavigationBar {...this.props} title="GALLERY" backIcon filterComponent={filter} />
                {!imageSelected ? (
                    <View style={[s.mainContainer]}>
                        <ScrollView contentContainerStyle={{ paddingBottom: 80 }}>
                            {isConnected ? (
                                items.map((item) => {
                                    if (item.data.length > 0) {
                                        return (
                                            <View style={s.dateItems}>
                                                <Text style={s.dateTitle}>{item.title}</Text>
                                                <View style={s.dateItemsWrapper}>
                                                    {item.data.map((data) => {
                                                        return (
                                                            <TouchableOpacity
                                                                onPress={() => this._selectImage(images, data)}
                                                            >
                                                                <Image source={{ uri: data.File }} style={s.dateItem} />
                                                            </TouchableOpacity>
                                                        );
                                                    })}
                                                </View>
                                            </View>
                                        );
                                    }
                                    return null;
                                })
                            ) : (
                                <View style={s.offline}>
                                    <Icon name="wifi-off" size={70} color={Color.cloudy_blue} />
                                    <Text style={s.offlineText}>You are offline, connect to internet</Text>
                                </View>
                            )}
                        </ScrollView>
                    </View>
                ) : (
                    <Modal transparent>
                        <ImageViewer
                            onSwipeDown={() => this.setState({ imageSelected: false })}
                            imageUrls={images}
                            index={imageIndex}
                            saveToLocalByLongPress
                            enableSwipeDown
                        />
                    </Modal>
                )}
            </View>
        );
    }
}

const mapStateToProps = ({ dailyReports }) => ({
    currentProject: dailyReports.currentProject,
    viewingProject: dailyReports.viewingProject,
    reports: dailyReports.reports,
});

export default connect(mapStateToProps)(GalleryScreen);
