import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, Modal, TouchableOpacity } from 'react-native';

import { LinkButton, PrimaryButton } from '../../../../components/index';

import s from '../styles';

class ModalShapeSelection extends Component {
    static propTypes = {
        isOpen: PropTypes.bool,
        shapes: PropTypes.array,
        selectedShapeName: PropTypes.string,
        onSelect: PropTypes.func,
        onClose: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedShape: props.selectedShapeName,
        };
    }

    componentDidUpdate(prevProps) {
        const { selectedShapeName: prevSelectedShapeName } = prevProps;
        const { selectedShapeName } = this.props;

        if (prevSelectedShapeName !== selectedShapeName) {
            this.setState({
                selectedShape: selectedShapeName,
            });
        }
    }

    handleSelectShape = (shapeName) => {
        this.setState({
            selectedShape: shapeName,
        });
    };

    handleSelectShapePress = () => {
        const { shapes, onSelect } = this.props;
        const { selectedShape } = this.state;

        const shape = shapes.find((s) => s.name === selectedShape);
        onSelect(shape);
    };

    render() {
        const { isOpen, shapes, onClose } = this.props;
        const { selectedShape } = this.state;
        return (
            <Modal visible={isOpen} transparent>
                <View style={s.modalWrapper}>
                    <View style={[s.formWrapper, s.modalForm]}>
                        <Text style={s.modalTitle}>SELECT ONE SHAPE</Text>
                        <View style={s.shapesWrapper}>
                            {shapes.map((shape) => {
                                const style = [s.shapeModal];
                                if (shape.name === selectedShape) {
                                    style.push(s.selectShape);
                                }

                                const LocalSVG = shape.svg;

                                return (
                                    <View key={shape.name} style={style}>
                                        <TouchableOpacity
                                            style={s.modalCloseSpace}
                                            onPress={() => this.handleSelectShape(shape.name)}
                                        >
                                            <LocalSVG />
                                        </TouchableOpacity>
                                    </View>
                                );
                            })}
                        </View>
                        <View style={s.modalButtonsWrapper}>
                            <View style={s.modalButton}>
                                <LinkButton title="CANCEL" onPress={onClose} />
                            </View>
                            <View>
                                <PrimaryButton title="SELECT" onPress={this.handleSelectShapePress} />
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        );
    }
}

export default ModalShapeSelection;
