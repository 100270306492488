import { cloneDeep, isNil } from 'lodash';
import moment from 'moment';
import { showMessage } from 'react-native-flash-message';
import CNST from '../constants';
import Api from '../../utils/apiMiddleware';
import { Config } from '../../config';
import { uploadImage } from '../helpers/common';
import { generateId } from '../../utils/helpers';

import { Color } from '../../theme';

export function getAccidentReportTickets(userId) {
    return (dispatch) => {
        dispatch({
            type: CNST.ACCIDENT_REPORTING.GET_TICKETS.LOADING,
        });
        return Api()
            .post(`/sources/${Config.ACCIDENT_REPORTING}/data`, {
                relatedSearch: false,
                limit: 9999,
                searchCriteria: {
                    User: userId,
                },
            })
            .then((response) => {
                return dispatch({
                    type: CNST.ACCIDENT_REPORTING.GET_TICKETS.SUCCESS,
                    data: response.data,
                });
            })
            .catch((error) => {
                return dispatch({
                    type: CNST.ACCIDENT_REPORTING.GET_TICKETS.ERROR,
                    error,
                });
            });
    };
}

export function setAccidentReportTicket(item) {
    return (dispatch) => {
        dispatch({
            type: CNST.ACCIDENT_REPORTING.SET_TICKET,
            item,
        });
    };
}

export function createAccidentReportTicket(data = {}) {
    const { user } = data;
    return (dispatch) => {
        return dispatch({
            type: CNST.ACCIDENT_REPORTING.SET_TICKET,
            item: {
                id: null,
                User: user,
                AccidentDetails: {
                    Date: null,
                    WeatherAndRoadConditions: '',
                    Location: '',
                    AccidentDetails: '',
                },
                DamageDescription: {
                    YourVehicle: '',
                    TowingCompanyNameAndPhoneNumber: '',
                    OtherVehicle: '',
                    OtherVehicleTowingCompanyNameAndPhoneNumber: '',
                },
                OtherDriverInformation: {
                    OwnersName: '',
                    OwnersAddress: '',
                    OwnersPhone: '',
                    VehicleMake: '',
                    VehicleModelAndYear: '',
                    VehicleColor: '',
                    LicensePlateNumber: '',
                    InsuranceCompanyAndPolicyNumber: '',
                    AgentNameAndPhoneNumber: '',
                    OtherDriversName: '',
                    OtherDriversAddress: '',
                    OtherDriversPhone: '',
                    NumberOfPassengers: '',
                    PassengerInfo: [
                        {
                            PassengerName: '',
                            PassengerPhoneNumber: '',
                        },
                    ],
                },
                PoliceInformation: {
                    OfficerName: '',
                    Department: '',
                    PhoneNumber: '',
                    BadgeNumber: '',
                    OtherInfo: '',
                },
                WitnessInformation: [
                    {
                        Name: '',
                        Address: '',
                        HomePhone: '',
                        WorkPhone: '',
                    },
                ],
                Attachment: [],
                CaseNumber: '',
                Status: 'P',
                AdminNotes: '',
                LastUpdated: moment().toDate(),
            },
        });
    };
}

export function setStepTwoData(data) {
    return (dispatch) => {
        const item = {
            AccidentDetails: {
                Date: data.date,
                WeatherAndRoadConditions: data.weatherAndRoadConditions,
                Location: data.location,
                AccidentDetails: data.accidentDetails,
            },
            DamageDescription: {
                YourVehicle: data.yourVehicle,
                TowingCompanyNameAndPhoneNumber: data.towingCompanyNameAndPhoneNumber,
                OtherVehicle: data.otherVehicle,
                OtherVehicleTowingCompanyNameAndPhoneNumber: data.otherVehicleTowingCompanyNameAndPhoneNumber,
            },
            OtherDriverInformation: {
                OwnersName: data.ownersName,
                OwnersAddress: data.ownersAddress,
                OwnersPhone: data.ownersPhone,
                VehicleMake: data.vehicleMake,
                VehicleColor: data.vehicleColor,
                LicensePlateNumber: data.licensePlateNumber,
                InsuranceCompanyAndPolicyNumber: data.insuranceCompanyAndPolicyNumber,
                AgentNameAndPhoneNumber: data.agentNameAndPhoneNumber,
                OtherDriversName: data.otherDriversName,
                OtherDriversAddress: data.otherDriversAddress,
                OtherDriversPhone: data.otherDriversPhone,
                VehicleModelAndYear: data.vehicleModelAndYear,
                NumberOfPassengers: data.numberOfPassengers,
                PassengerInfo: data.passengerInfo,
            },
        };
        dispatch({
            type: CNST.ACCIDENT_REPORTING.SAVE_STEP_TWO,
            item,
        });
    };
}

export function saveAttachment(image, currentTicketAttachments, uploaded) {
    return (dispatch) => {
        let attachmentIndex = -1;
        if (image.id) {
            attachmentIndex = currentTicketAttachments.findIndex((a) => a.id === image.id);
        }

        if (uploaded) {
            const attachment = { File: image.file.url, FileAssetId: image._id };
            if (attachmentIndex >= 0) {
                currentTicketAttachments.splice(attachmentIndex, 1, attachment);
            } else {
                currentTicketAttachments.push(attachment);
            }
        } else if (attachmentIndex >= 0) {
            currentTicketAttachments.splice(attachmentIndex, 1, image);
        } else {
            currentTicketAttachments.push(image);
        }

        return dispatch({
            type: CNST.ACCIDENT_REPORTING.SAVE_ATTACHMENT,
            attachments: currentTicketAttachments,
        });
    };
}

export function removeAttachment(attachment, currentTicketAttachments) {
    return (dispatch) => {
        let attachments = currentTicketAttachments;

        if (attachment.id) {
            attachments = attachments.filter((a) => a.id !== attachment.id);
        } else {
            attachments = attachments.filter((a) => a.File !== attachment.File);
        }

        return dispatch({
            type: CNST.ACCIDENT_REPORTING.REMOVE_ATTACHMENT,
            attachments,
        });
    };
}

export function saveAccidentReportAttachments(data = {}) {
    const { ticket } = data;
    return async (dispatch) => {
        dispatch({ type: CNST.ACCIDENT_REPORTING.SUBMIT_TICKET.LOADING });
        let success = true;
        const uploadingQueue = [];
        const uploadHandler = (asset, attachment, item) => {
            if (asset && asset.file) {
                const existsIndex = item.Attachment.findIndex((a) => a.id === attachment.id);
                if (existsIndex >= 0) {
                    item.Attachment.splice(existsIndex, 1, {
                        File: asset.file.url,
                        FileAssetId: asset._id,
                    });
                } else {
                    item.Attachment.push({
                        File: asset.file.url,
                        FileAssetId: asset._id,
                    });
                }
            } else {
                success = false;
            }
            return asset;
        };

        const addToUploadingQueue = (attachment, type, item) => {
            const uploadFunc = uploadImage(attachment, attachment.file, type, item.id);
            const uploadPromise = uploadFunc().then((response) => {
                return uploadHandler(response.asset, attachment, item);
            });

            uploadingQueue.push(uploadPromise);
        };

        // upload attachments
        for (const attachment of ticket.Attachment) {
            if (attachment.id && !isNil(attachment.uploaded) && !attachment.uploaded) {
                addToUploadingQueue(attachment, 'note', ticket);
            }
        }

        if (uploadingQueue.length > 0) {
            await Promise.all(uploadingQueue);
        }

        if (!success) {
            dispatch({
                type: CNST.ACCIDENT_REPORTING.SUBMIT_TICKET.ERROR,
            });
        }

        return success;
    };
}

export function saveAccidentReportTicket(data = {}) {
    const { ticket, user, isConnected } = data;
    return (dispatch) => {
        dispatch({ type: CNST.ACCIDENT_REPORTING.SUBMIT_TICKET.LOADING });

        const isNew = ticket.id === null;
        // eslint-disable-next-line no-unused-vars
        const { id, unsavedId, unsaved, timeCreated, timeModified, sourceId, hasProposedChanges, ...data } = ticket;

        if (isConnected) {
            return Api()
                .put(`/sources/${Config.ACCIDENT_REPORTING}/data/${isNew ? 'new' : ticket.id}`, {
                    data,
                    modifiedBy: user.username,
                })
                .then((response) => {
                    showMessage({
                        message: 'Success',
                        description: 'The accident report has been uploaded. A admin will be in content',
                        type: 'success',
                        position: 'right',
                        hideStatusBar: true,
                        duration: 1500,
                        backgroundColor: Color.turquoise,
                    });

                    dispatch({
                        type: CNST.ACCIDENT_REPORTING.SUBMIT_TICKET.SUCCESS,
                        item: response,
                        unsavedId: ticket.unsavedId,
                        isNew,
                    });
                    return true;
                })
                .catch(() => {
                    ticket.unsaved = true;
                    if (!ticket.unsavedId) {
                        ticket.unsavedId = generateId();
                    }
                    showMessage({
                        message: 'Error',
                        description:
                            // eslint-disable-next-line max-len
                            'An error occurred while saving report. Your report has been saved on your device and will be sent later.',
                        type: 'danger',
                        icon: 'danger',
                        position: 'right',
                        hideStatusBar: true,
                        duration: 1500,
                        backgroundColor: Color.red,
                    });
                    return dispatch({
                        type: CNST.ACCIDENT_REPORTING.SUBMIT_TICKET.SUCCESS,
                        item: ticket,
                        unsavedId: ticket.unsavedId,
                        isNew,
                    });
                });
        }

        ticket.unsaved = true;
        if (!ticket.unsavedId) {
            ticket.unsavedId = generateId();
        }

        return dispatch({
            type: CNST.ACCIDENT_REPORTING.SUBMIT_TICKET.SUCCESS,
            item: ticket,
            unsavedId: ticket.unsavedId,
            isNew,
        });
    };
}

export function finishEditing() {
    return (dispatch) => {
        dispatch({
            type: CNST.ACCIDENT_REPORTING.FINISH_EDITING,
        });
    };
}

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = {
    accidentReportTickets: [],
    currentTicket: null,
    isLoading: false,
    isSaveLoading: false,
};

export default function injuryReporting(state = cloneDeep(initialState), action) {
    switch (action.type) {
        case CNST.ACCIDENT_REPORTING.GET_TICKETS.LOADING:
            return {
                ...state,
                ...{
                    isLoading: true,
                },
            };
        case CNST.ACCIDENT_REPORTING.GET_TICKETS.SUCCESS: {
            const unsavedTickets = state.accidentReportTickets.filter((ticket) => !ticket.id && ticket.unsaved);

            return {
                ...state,
                ...{
                    accidentReportTickets: [...action.data, ...unsavedTickets],
                    isLoading: false,
                },
            };
        }

        case CNST.ACCIDENT_REPORTING.GET_TICKETS.ERROR:
            return {
                ...state,
                ...{
                    error: action.error,
                    isLoading: false,
                },
            };
        case CNST.ACCIDENT_REPORTING.SET_TICKET:
            return {
                ...state,
                ...{
                    currentTicket: action.item,
                    isInjuryReportEdit: true,
                },
            };
        case CNST.ACCIDENT_REPORTING.SAVE_STEP_TWO:
            return {
                ...state,
                ...{
                    currentTicket: { ...state.currentTicket, ...action.item },
                },
            };
        case CNST.ACCIDENT_REPORTING.SUBMIT_TICKET.LOADING:
            return {
                ...state,
                isLoading: true,
                isSaveLoading: true,
            };
        case CNST.ACCIDENT_REPORTING.SUBMIT_TICKET.SUCCESS: {
            const { item, unsavedId } = action;
            let accidentReportTickets = null;
            let isExists = false;

            if (unsavedId) {
                isExists = state.accidentReportTickets.find((ticket) => ticket.unsavedId === unsavedId);
            }

            if (!isExists && item.id) {
                isExists = state.accidentReportTickets.find((r) => r.id === item.id);
            }

            if (isExists) {
                accidentReportTickets = state.accidentReportTickets.map((t) => {
                    if (unsavedId && t.unsavedId === unsavedId) {
                        // update ticket in state if user update not saved ticket
                        return {
                            ...t,
                            ...item,
                        };
                    }

                    if (item.id && t.id === item.id) {
                        // update ticket in state after successfully save
                        return {
                            ...t,
                            ...item,
                        };
                    }
                    // leave ticket as is
                    return t;
                });
            } else {
                accidentReportTickets = [...state.accidentReportTickets, item];
            }
            return {
                ...state,
                ...{
                    isLoading: false,
                    isSaveLoading: false,
                    accidentReportTickets,
                },
            };
        }
        case CNST.ACCIDENT_REPORTING.SUBMIT_TICKET.ERROR: {
            return {
                ...state,
                ...{
                    isLoading: false,
                    isSaveLoading: false,
                },
            };
        }
        case CNST.ACCIDENT_REPORTING.FINISH_EDITING: {
            return {
                ...state,
                ...{
                    isInjuryReportEdit: false,
                },
            };
        }
        case CNST.ACCIDENT_REPORTING.REMOVE_ATTACHMENT:
        case CNST.ACCIDENT_REPORTING.SAVE_ATTACHMENT: {
            return {
                ...state,
                ...{
                    currentTicket: {
                        ...state.currentTicket,
                        Attachment: action.attachments,
                    },
                    isChanged: true,
                },
            };
        }
        case CNST.ACCOUNT.LOGOUT.SUCCESS:
            return {
                ...state,
                ...cloneDeep(initialState),
                accidentReportTickets: state.accidentReportTickets.filter((t) => t.unsaved),
            };
        default:
            return state;
    }
}
