import ACCOUNT from './account';
import CURRENT_TIME_CARD from './currentTimeCard';
import TIME_CARDS from './timeCards';
import DAILY_REPORTS from './dailyReports';
import TIME_AND_MATERIAL from './timeAndMaterial';
import CHANGE_ORDERS from './changeOrder';
import TIME_OFF from './timeOff';
import TIME_CARD_EDIT from './timeCardEdit';
import INJURY_REPORTING from './injuryReporting';
import REIMBURSEMENTS from './reimbursements';
import ACCIDENT_REPORTING from './accidentReportings';
import HOW_TO from './howTo';
import TOOLBOX_TALKS from './toolboxTalks';
import CUSTOM_REPORT from './customReports';
import CUT_SHEETS from './cutSheets';
import SCHEDULE from './schedule';

export default {
    ACCOUNT,
    CURRENT_TIME_CARD,
    TIME_CARDS,
    DAILY_REPORTS,
    TIME_AND_MATERIAL,
    CHANGE_ORDERS,
    TIME_OFF,
    TIME_CARD_EDIT,
    INJURY_REPORTING,
    ACCIDENT_REPORTING,
    HOW_TO,
    TOOLBOX_TALKS,
    CUSTOM_REPORT,
    CUT_SHEETS,
    SCHEDULE,
    REIMBURSEMENTS,
    SET_DATA_APP_LOADING: 'SET_DATA_APP_LOADING',
    INIT_APP: 'INIT_APP',
};
