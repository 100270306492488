import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ImageBackground, Text, View, BackHandler } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { getTimeCards } from '../../../store/modules/timeCards';
import moment from 'moment';
import { ListItem, NavigationBar, PrimaryButton } from '../../../components/index';

import s from './styles';
import { Img } from '../../../theme';
import {
    saveTimeCardProject,
    clockIn,
    switchCards,
    selectCurrentTimeAndMaterial,
    setCurrentClockInType,
    setDescription,
    setWorkOrderYear,
    setWorkOrderNumber,
    setSwitch,
    changeCostCode,
    setTimecardChange,
    setLoading,
} from '../../../store/modules/currentTimeCard';
import { setCurrentCostCode, setCurrentProject } from '../../../store/modules/dailyReports';
import { syncTimeCard, setCurrentDailyQuestion, setDailyQuestionActive } from '../../../store/modules/timeCards';
import { Config } from '../../../config';

class ClockInScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentProject: PropTypes.object,
        currentCostCode: PropTypes.object,
        currentTimeCard: PropTypes.object,
        user: PropTypes.object,
        unsavedCards: PropTypes.array,
        timeCardsIsSyncing: PropTypes.bool,
        switchCard: PropTypes.object,
        timeCardBeforeProjectChange: PropTypes.object,
        dailyQuestions: PropTypes.array,
        dailyQuestionAnswerDate: PropTypes.string,
        isLoading: PropTypes.bool,
        isSwitch: PropTypes.bool,
        isChange: PropTypes.bool,
        setCurrentCostCode: PropTypes.func,
        setCurrentClockInType: PropTypes.func,
        saveTimeCardProject: PropTypes.func,
        clockIn: PropTypes.func,
        setDescription: PropTypes.func,
        setTimecardChange: PropTypes.func,
        switchCards: PropTypes.func,
        setCurrentDailyQuestion: PropTypes.func,
        setSwitch: PropTypes.func,
        setCurrentProject: PropTypes.func,
        setWorkOrderNumber: PropTypes.func,
        setWorkOrderYear: PropTypes.func,
        syncTimeCard: PropTypes.func,
        setLoading: PropTypes.func,
        latestDailyQuestions: PropTypes.array,
        setDailyQuestionActive: PropTypes.func,
        firstPayPeriod: PropTypes.object,
        timeAndMaterials: PropTypes.array,
        getTimeCards: PropTypes.func,
        timeCardRules: PropTypes.object,
        timeCards: PropTypes.array,
    };

    state = {
        chosenItem: 0,
        doneProcessing: false,
    };

    componentDidMount() {
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleHardwareBackPress);
    }

    componentWillUnmount() {
        this.backHandler.remove();
    }

    handleHardwareBackPress = () => {
        this.backFunction();
        return true;
    };

    _returnClockInItemsArray() {
        const { navigation, currentProject, currentCostCode, setCurrentCostCode, setCurrentClockInType } = this.props;
        const { chosenItem } = this.state;

        const project = currentProject;
        const costCode = currentCostCode;

        let costCodeTitle = 'Cost code';
        let projectTitle = 'Project';
        if (Config.USE_DEPARTMENTS_JOBS) {
            costCodeTitle = 'Job';
            projectTitle = 'Department';
        }

        const list = {
            project: {
                title: project ? project.ProjectName : projectTitle,
                icon: 'file-document-outline',
                required: !project,
                onPress: () => {
                    navigation.navigate('Projects', {
                        standalone: false,
                        isTimeCardEdit: false,
                        isInjuryReportEdit: false,
                    });
                },
            },
            costCode: {
                title: !isEmpty(costCode) ? `${costCode.Code} ${costCode.Title}` : costCodeTitle,
                required: false,
                radio: true,
                isSelected: chosenItem === 1,
                isDisabled: isEmpty(project),
                onPress: () => {
                    this.setState({ chosenItem: 1 });
                    setCurrentCostCode({
                        code: null,
                    });
                    selectCurrentTimeAndMaterial({
                        timeAndMaterial: null,
                    });
                    setCurrentClockInType('C');
                    navigation.navigate('CostCode', { isTimeCardEdit: false });
                },
            },
            costPerMaterial: {
                title: 'Time and material',
                required: false,
                radio: true,
                isSelected: chosenItem === 2,
                isDisabled: isEmpty(project),
                onPress: () => {
                    this.setState({ chosenItem: 2 });
                    setCurrentCostCode({
                        code: null,
                    });
                    setCurrentClockInType('T');
                    navigation.navigate('TimeAndMaterialItemClockInScreen');
                },
            },
            changeOrder: {
                title: 'Change order',
                required: false,
                radio: true,
                isSelected: chosenItem === 3,
                isDisabled: isEmpty(project),
                onPress: () => {
                    setCurrentCostCode({
                        code: null,
                    });
                    selectCurrentTimeAndMaterial({
                        timeAndMaterial: null,
                    });
                    this.setState({ chosenItem: 3 });
                    setCurrentClockInType('O');
                    navigation.navigate('ChangeOrderClockInScreen');
                },
            },
            description: {
                title: 'Description',
                icon: 'playlist-edit',
                required: false,
                onPress: () => {
                    navigation.navigate('ClockInDescription');
                },
            },
            changeCostCode: {
                title: `Change ${costCodeTitle.toLowerCase()}`,
                icon: 'file-document-edit-outline',
                required: false,
                isSelected: chosenItem === 3,
                onPress: () => {
                    navigation.navigate('ClockInDescription');
                },
            },
        };
        return list;
    }

    onDone = async () => {
        this.setState({ doneProcessing: true });
        const {
            navigation,
            saveTimeCardProject,
            clockIn,
            currentTimeCard,
            setDescription,
            switchCard,
            isSwitch,
            isChange,
            setTimecardChange,
            switchCards,
            getTimeCards,
            user,
        } = this.props;
        const { doneProcessing } = this.state;
        if (doneProcessing) {
            return;
        }

        const { lastTimeCard } = await getTimeCards({ user });
        this.setDailyQuestionActive();
        const data = {
            currentTimeCard,
        };
        if (isSwitch) {
            switchCards(switchCard, data).then(() => {
                this.postUnsavedTimeCards();
                navigation.navigate('Clock');
                this.setState({ doneProcessing: false });
            });
        } else if (isChange) {
            saveTimeCardProject({ currentTimeCard }).then(() => {
                setTimecardChange(false);
                this.postUnsavedTimeCards();
                navigation.navigate('Clock');
                this.setState({ doneProcessing: false });
            });
        } else {
            if (!lastTimeCard || (lastTimeCard && lastTimeCard.EndTime !== null)) {
                setDescription({ description: '' });
                clockIn(data).then(() => {
                    this.postUnsavedTimeCards();
                    navigation.navigate('Clock');
                    this.setState({ doneProcessing: false });
                });
            } else {
                this.postUnsavedTimeCards();
                navigation.navigate('Clock');
                window.location.reload();
                this.setState({ doneProcessing: false });
            }
        }
    };

    setDailyQuestionActive = () => {
        const { dailyQuestionAnswerDate, setCurrentDailyQuestion, latestDailyQuestions, setDailyQuestionActive } =
            this.props;
        let isDailyQuestionActive = false;
        const currentQuestion = this.returnCurrentQuestion();
        if (
            currentQuestion &&
            (latestDailyQuestions.length < 0 ||
                JSON.stringify(latestDailyQuestions) !== JSON.stringify(currentQuestion.Questions))
        ) {
            setCurrentDailyQuestion(currentQuestion);
            isDailyQuestionActive = true;
        } else if (
            dailyQuestionAnswerDate &&
            currentQuestion &&
            !(currentQuestion.Frequency === 'D'
                ? moment(dailyQuestionAnswerDate).isSame(moment(), 'day')
                : currentQuestion.Frequency === 'W'
                ? moment(dailyQuestionAnswerDate).isSame(moment(), 'week')
                : moment(dailyQuestionAnswerDate).isSame(moment(), 'month'))
        ) {
            setCurrentDailyQuestion(currentQuestion);
            isDailyQuestionActive = true;
        }
        setDailyQuestionActive(isDailyQuestionActive);
    };

    returnCurrentQuestion = () => {
        const { dailyQuestions, user } = this.props;
        let questionCategory = null;
        if (dailyQuestions.length > 0) {
            dailyQuestions.forEach((q) => {
                if (q.IsActive === true && q.Roles.includes(user.role) && q.Questions.length > 0) {
                    questionCategory = q;
                }
            });
        }
        return questionCategory;
    };

    backFunction = () => {
        const {
            isChange,
            isSwitch,
            switchCard,
            timeCardBeforeProjectChange,
            setSwitch,
            setCurrentProject,
            setCurrentCostCode,
            setWorkOrderNumber,
            setWorkOrderYear,
            setDescription,
            setTimecardChange,
            navigation,
        } = this.props;

        let { currentTimeCard } = isSwitch ? switchCard : {};
        if (isChange) {
            currentTimeCard = timeCardBeforeProjectChange;
        }

        if (currentTimeCard) {
            const { currentProject, currentCostCode, description, workOrderNumber, workOrderYear } = currentTimeCard;
            setCurrentProject(currentProject);
            setCurrentCostCode({ code: currentCostCode });
            setWorkOrderNumber({ workOrderNumber });
            setWorkOrderYear({ workOrderYear });
            setDescription({ description });
        }

        if (isSwitch) {
            setSwitch(false, null);
        } else if (isChange) {
            setTimecardChange(false, null);
        }
        navigation.pop();
    };

    postUnsavedTimeCards() {
        const { user, unsavedCards, timeCardsIsSyncing, syncTimeCard, firstPayPeriod } = this.props;

        if (unsavedCards && unsavedCards.length && !timeCardsIsSyncing) {
            syncTimeCard(unsavedCards, user, firstPayPeriod);
        }
    }

    handleScreenDidFocus = () => {
        const { setLoading } = this.props;
        setLoading(false);
    };

    getCurrentWeekRange() {
        const { timeCardRules } = this.props;
        const startOfWeek = moment().startOf('week').weekday(timeCardRules.workWeekStart);
        const endOfWeek = moment(startOfWeek).add(6, 'days');

        return { startOfWeek, endOfWeek };
    }

    isWarnForOverTime() {
        const { timeCardRules, timeCards } = this.props;
        let weekTotal = 0;
        let warn = false;
        if (
            timeCardRules.timeCardWarning &&
            timeCardRules.timeCardWarning.warnUsersWhenCloseToOverTime &&
            timeCardRules.timeCardWarning.decimalHours > 0
        ) {
            const { startOfWeek, endOfWeek } = this.getCurrentWeekRange();
            timeCards.forEach((card) => {
                const startTime = moment(card.StartTime);

                if (startTime.isBetween(startOfWeek, endOfWeek, null, '[]')) {
                    weekTotal += card.Duration.WorkTimeHours;
                }
            });

            warn = timeCardRules.timeCardWarning.decimalHours <= weekTotal;
        }

        return warn;
    }

    render() {
        const { currentProject, isSwitch, isLoading, timeAndMaterials, currentCostCode } = this.props;
        const { doneProcessing } = this.state;
        const project = currentProject;
        const items = this._returnClockInItemsArray();
        const warnForOverTime = this.isWarnForOverTime();

        return (
            <ImageBackground source={Img.bg} style={[s.wrapper]}>
                <NavigationEvents onDidFocus={this.handleScreenDidFocus} />
                <NavigationBar
                    {...this.props}
                    title={isSwitch ? 'SWITCH' : 'CLOCK IN'}
                    backIcon
                    backIconFunction={this.backFunction}
                />
                <View style={[s.mainContainer]}>
                    <View style={s.listWrapper}>
                        <Text style={s.listTitle}>Job Details</Text>
                        <ListItem item={items.project} clockIn />
                        {!isEmpty(project) && (
                            <View style={s.blockWrapper}>
                                {currentProject && currentProject.ProjectEstimates.length ? (
                                    <ListItem item={items.costCode} style={s.listItem} clockIn />
                                ) : null}
                                {timeAndMaterials && timeAndMaterials.length ? (
                                    <ListItem item={items.costPerMaterial} style={s.listItem} clockIn />
                                ) : null}
                                <View style={s.divider} />
                            </View>
                        )}
                        {!isEmpty(currentProject) && <ListItem item={items.description} clockIn />}

                        {warnForOverTime ? (
                            <Text style={s.weeklyOvertimeLimitLabel}>
                                (You are nearing or have reached the weekly overtime limit. You can still clock in.)
                            </Text>
                        ) : null}

                        <View style={s.buttonWrapper}>
                            <PrimaryButton
                                title={isSwitch ? 'SWITCH' : 'DONE'}
                                isLoading={isLoading}
                                onPress={this.onDone}
                                disabled={
                                    isEmpty(currentProject) ||
                                    (currentProject &&
                                        currentProject.ProjectEstimates.length > 0 &&
                                        isEmpty(currentCostCode)) ||
                                    doneProcessing
                                }
                                red={warnForOverTime}
                            />
                        </View>
                    </View>
                </View>
            </ImageBackground>
        );
    }
}

const mapStateToProps = ({ account, currentTimeCard, timeCards, timeAndMaterials }) => ({
    currentProject: currentTimeCard.currentProject,
    currentCostCode: currentTimeCard.currentCostCode,
    isSwitch: currentTimeCard.isSwitch,
    currentTimeCard,
    user: account.user,
    userID: account.user._id,
    dailyQuestions: account.dailyQuestions,
    isLoading: currentTimeCard.isLoading,
    dailyQuestionAnswerDate: timeCards.dailyQuestionAnswerDate,
    latestDailyQuestions: timeCards.dailyQuestions,
    switchCard: currentTimeCard.switchCard,
    timeCardBeforeProjectChange: currentTimeCard.timeCardBeforeProjectChange,
    isChange: currentTimeCard.isChange,
    unsavedCards: timeCards.unsavedCards,
    timeCardsIsSyncing: timeCards.timeCardsIsSyncing,
    firstPayPeriod: timeCards.firstPayPeriod,
    costCodes: account.costCodes,
    timeAndMaterials: timeAndMaterials.timeAndMaterials,
    timeCardRules: account.settings.timeCardRules,
    timeCards: timeCards.timeCards,
});

const mapDispatchToProps = (dispatch) => {
    const bindActions = bindActionCreators(
        {
            saveTimeCardProject,
            clockIn,
            setCurrentProject,
            setCurrentCostCode,
            setCurrentClockInType,
            selectCurrentTimeAndMaterial,
            setDescription,
            setWorkOrderNumber,
            setWorkOrderYear,
            setCurrentDailyQuestion,
            setSwitch,
            changeCostCode,
            setTimecardChange,
            switchCards,
            syncTimeCard,
            setLoading,
            setDailyQuestionActive,
            getTimeCards,
        },
        dispatch,
    );
    bindActions.dispatch = dispatch;
    return bindActions;
};

export default connect(mapStateToProps, mapDispatchToProps)(ClockInScreen);
