import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, View, SectionList, Text } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import { getAllShifts, selectScheduleCalendarDate } from '../../../store/modules/schedule';
import { userLogin } from '../../../store/modules/account';

import { NavigationBar } from '../../../components/index';
import ShiftItem from '../components/ShiftItem';

import { Color } from '../../../theme';
import s from './styles';

class ScheduleDashboard extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        user: PropTypes.object,
        upcomingShifts: PropTypes.array,
        availableShifts: PropTypes.array,
        isLoading: PropTypes.bool,
        isDataLoading: PropTypes.bool,
        isAutoLoginLoading: PropTypes.bool,
        getAllShifts: PropTypes.func,
        selectScheduleCalendarDate: PropTypes.func,
        userLogin: PropTypes.func,
    };

    componentDidMount() {
        const { user, getAllShifts } = this.props;
        if (user) {
            getAllShifts(user._id);
        }
    }

    handleShiftPress = (shift) => {
        const { navigation } = this.props;
        navigation.navigate('ShiftDetails', { shift });
    };

    handleViewAllShiftsPress = (isShiftAvailable) => {
        const { navigation, selectScheduleCalendarDate } = this.props;
        const screen = isShiftAvailable ? 'AvailableShifts' : 'Schedule';

        if (screen === 'Schedule') {
            selectScheduleCalendarDate(moment().format('YYYY-MM-DD'));
        }

        navigation.navigate(screen);
    };

    handleRefreshNeed = () => {
        const { user, getAllShifts } = this.props;
        getAllShifts(user._id);
    };

    render() {
        const { navigation, isDataLoading, isAutoLoginLoading, isLoading, upcomingShifts, availableShifts } =
            this.props;
        const hideUINavigation = navigation.getParam('hideUINavigation');

        const upcomingAvailableShifts = availableShifts.slice(0, 2);

        const sections = [];
        if (upcomingShifts.length > 0) {
            sections.push({
                title: 'Upcoming Shifts',
                data: upcomingShifts,
                screen: 'Schedule',
            });
        }

        if (upcomingAvailableShifts.length > 0) {
            sections.push({
                title: 'Available Shifts',
                data: upcomingAvailableShifts,
                screen: 'AvailableShifts',
            });
        }

        return (
            <View style={s.wrapper}>
                <NavigationBar {...this.props} title="DASHBOARD" menuIcon={!hideUINavigation} />
                {isDataLoading || isAutoLoginLoading ? (
                    <ActivityIndicator
                        color={Color.blue}
                        size={40}
                        style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
                    />
                ) : (
                    <View style={s.mainContainer}>
                        <SectionList
                            style={s.listWrapper}
                            refreshing={isLoading}
                            sections={sections}
                            renderItem={({ item }) => {
                                return (
                                    <ShiftItem
                                        shift={item}
                                        onViewAllPress={this.handleViewAllShiftsPress}
                                        onShiftPress={this.handleShiftPress}
                                    />
                                );
                            }}
                            keyExtractor={(item, i) => `${item.id}_${i}`}
                            renderSectionHeader={({ section: { title } }) => <Text style={s.listTitle}>{title}</Text>}
                            contentContainerStyle={s.listContentContainer}
                            ListEmptyComponent={<Text style={s.listTitle}>Nothing Available</Text>}
                            onRefresh={this.handleRefreshNeed}
                        />
                    </View>
                )}
            </View>
        );
    }
}

const mapStateToProps = ({ account, schedule, currentTimeCard }) => {
    return {
        isAutoLoginLoading: account.isAutoLoginLoading,
        isDataLoading: currentTimeCard.isDataLoading,
        isLoading: schedule.isLoading,
        upcomingShifts: schedule.upcomingShifts,
        availableShifts: schedule.availableShifts,
        user: account.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    const bindActions = bindActionCreators(
        {
            getAllShifts,
            selectScheduleCalendarDate,
            userLogin,
        },
        dispatch,
    );
    bindActions.dispatch = dispatch;
    return bindActions;
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDashboard);
