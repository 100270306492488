import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import s from './styles';

export default class ListItem extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        onPress: PropTypes.func,
    };

    render() {
        const { title, onPress } = this.props;
        return (
            <TouchableOpacity style={s.listItemWrapper} onPress={onPress}>
                <Text style={s.title}>{title}</Text>
                <Icon name="chevron-right" size={28} style={[s.chevronRight]} />
            </TouchableOpacity>
        );
    }
}
