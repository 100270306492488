import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, FlatList } from 'react-native';
import { connect } from 'react-redux';
import { NavigationBar, WorkLogListItem } from '../../../components/index';

import { convertTimeStringToSeconds } from '../../../utils/helpers';
import s from './styles';
// import FILTERS from '../../../config/filtersCNST'
// const filters = [FILTERS.ALL_TIME, FILTERS.MONTH, FILTERS.WEEK, FILTERS.DAY]

class DailyWorkLogScreen extends Component {
    static propTypes = {
        currentProject: PropTypes.object,
        currentReport: PropTypes.object,
        currentTimeCard: PropTypes.object,
        costCodes: PropTypes.array,
    };

    state = {
        // filter: null,
        // currentFilter: FILTERS.ALL_TIME,
    };

    returnCostCodeTitle(id) {
        const { costCodes } = this.props;
        let title = '';
        costCodes.forEach((c) => {
            if (c.id === id) {
                title = `${c.Code} ${c.Title}`;
            }
        });
        return title;
    }

    returnUsed(estimate) {
        const { currentReport, currentTimeCard } = this.props;
        let totalTime = 0;
        currentReport.UsedEstimates.forEach((e) => {
            if (e.CostCode === estimate.CostCode) {
                const sec = convertTimeStringToSeconds(e.UsedTime);
                totalTime += sec;
            }
        });
        if (currentTimeCard.currentCostCode.id === estimate.CostCode) {
            let workTime = 0;
            currentTimeCard.clockIn.forEach((item, i) => {
                const clocksOut = [...currentTimeCard.clockOut, moment().format()];
                workTime += moment(clocksOut[i]).diff(moment(item), 'second');
            });
            totalTime += workTime;
        }
        return totalTime;
    }

    _returnNewReportItems() {
        const { currentProject } = this.props;
        const list = [];
        currentProject.ProjectEstimates.forEach((e) => {
            list.push({
                id: e.CostCode,
                title: this.returnCostCodeTitle(e.CostCode),
                estimated: e.Estimate,
                used: this.returnUsed(e),
            });
        });
        return list;
    }

    _returnTotalEstimate() {
        const { currentProject } = this.props;
        let sec = 0;
        currentProject.ProjectEstimates.forEach((e) => {
            sec += convertTimeStringToSeconds(e.Estimate);
        });
        const hours = Math.floor(sec / 3600);
        const minutes = Math.floor((sec % 3600) / 60);
        return `${hours > 0 ? `${hours}h` : ''} ${minutes > 0 ? `${minutes}m` : ''}`;
    }

    _returnTotalWorkHours() {
        const items = this._returnNewReportItems();
        let totalTime = 0;
        items.forEach((item) => {
            totalTime += item.used;
        });

        totalTime = moment().startOf('day').add(totalTime, 'seconds');
        const hours = moment(totalTime).hours();
        const minutes = moment(totalTime).minutes();
        return `${hours > 0 ? `${hours}h` : ''} ${minutes > 0 ? `${minutes}m` : `${moment(totalTime).seconds()}s`}`;
    }

    render() {
        // const { currentFilter } = this.state
        return (
            <View style={[s.wrapper]}>
                <NavigationBar
                    {...this.props}
                    title="WORK LOGS"
                    backIcon
                    // filterComponent={
                    //   <Filter
                    //     filterList={filters}
                    //     filterTitle="FILTER BY DATE"
                    //     currentFilter={currentFilter}
                    //     filterIcon={<Icon name="update" color={Color.blue} size={25} />}
                    //     onFilterSelect={filter => this.setState({currentFilter: filter})}
                    //   />
                    // }
                />
                <View style={s.menuTopContainer}>
                    <Text style={s.topMenu_title}>{moment().format('DD/MM/YY')}</Text>
                </View>
                <View style={[s.mainContainer]}>
                    <View style={s.totalRow}>
                        <View style={s.totalCol}>
                            <Text style={[s.totalTitle]}>{this._returnTotalEstimate()}</Text>
                            <Text style={[s.totalSubtitle]}>ESTIMATED TIME</Text>
                        </View>
                        <View style={s.totalCol}>
                            <Text style={[s.totalTitle, s.totalText_blue]}>{this._returnTotalWorkHours()}</Text>
                            <Text style={[s.totalSubtitle, s.totalText_blue]}>TOTAL WORK HOURS</Text>
                        </View>
                    </View>
                    <FlatList
                        data={this._returnNewReportItems()}
                        contentContainerStyle={{ paddingBottom: 150 }}
                        keyExtractor={(item) => item.id}
                        style={{ elevation: 20, width: '100%' }}
                        renderItem={({ item }) => {
                            return <WorkLogListItem item={item} />;
                        }}
                    />
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ dailyReports, account, currentTimeCard }) => ({
    costCodes: account.costCodes,
    currentProject: dailyReports.currentProject,
    currentReport: dailyReports.currentReport,
    currentTimeCard,
});

export default connect(mapStateToProps, {})(DailyWorkLogScreen);
