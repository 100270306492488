export default {
    GET_TYPES: 'GET_INJURY_TICKET_TYPES',
    GET_TICKETS: 'GET_INJURY_TICKETS',
    CREATE_TICKET: 'CREATE_INJURY_TICKET',
    SET_INJURY_TICKET: 'SET_INJURY_TICKET',
    FINISH_EDITING: 'INJURY_TICKET_FINISH_EDITING',
    SET_PROJECT: 'SET_PROJECT',
    SUBMIT_TICKET: {
        LOADING: 'SUBMIT_INJURY_TICKET',
        SUCCESS: 'SUBMIT_INJURY_TICKET_SUCCESS',
        ERROR: 'SUBMIT_INJURY_TICKET_ERROR',
    },
    LOADING: {
        START: 'INJURY_LOADING_START',
        FINISH: 'INJURY_LOADING_FINISH',
    },
};
