import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, SectionList, Text, View } from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import { showMessage } from 'react-native-flash-message';
import { ListItem } from '../../../components';
import { formatTimeFromSeconds } from '../../../utils/helpers';
import s from './styles';
import { Color } from '../../../theme';

class TitleComponent extends Component {
    static propTypes = {
        date: PropTypes.string,
        clockInTime: PropTypes.string,
        isClockedIn: PropTypes.bool,
        totalTime: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.state = {
            time: 0,
        };
    }

    componentDidMount() {
        const { clockInTime, isClockedIn, totalTime } = this.props;
        if (isClockedIn) {
            const time = moment().diff(moment(clockInTime), 'seconds') + totalTime;
            this.setState({ time });
            this.timer = setInterval(this.timeChange, 1000);
        } else {
            this.setState({ time: totalTime });
            clearInterval(this.timer);
        }
    }

    componentDidUpdate(prevProps) {
        const { isClockedIn, totalTime, clockInTime } = this.props;
        if ((!isClockedIn && prevProps.isClockedIn) || totalTime !== prevProps.totalTime) {
            this.setState({ time: totalTime });
            clearInterval(this.timer);
        } else if (isClockedIn && !prevProps.isClockedIn) {
            const time = moment().diff(moment(clockInTime), 'seconds') + totalTime;
            this.setState({ time });
            this.timer = setInterval(this.timeChange, 1000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    timeChange = () => {
        const { time } = this.state;
        this.setState({ time: time + 1 });
    };

    render() {
        const { date } = this.props;
        const { time } = this.state;
        const calendarDate = moment(date).calendar(null, {
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'MMM D, dddd',
            lastDay: '[Yesterday]',
            lastWeek: 'MMM D, dddd',
            sameElse: 'MMM D, dddd',
        });
        return (
            <View style={s.listTitleWrapper}>
                <Text style={s.listTitle}>{calendarDate}</Text>
                <Text style={[s.listTitle, s.listTitleRight]}>{formatTimeFromSeconds(time)}</Text>
            </View>
        );
    }
}

export default class Entires extends Component {
    static propTypes = {
        isLoading: PropTypes.bool,
        cards: PropTypes.array,
        user: PropTypes.object,
        getTimeCardModifications: PropTypes.func,
        payPeriods: PropTypes.array,
        getTimeCardsByPayPeriod: PropTypes.func,
        lastGetTimeCardsStartDate: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    reloadTimeCards = () => {
        const { user, payPeriods, getTimeCardModifications, getTimeCardsByPayPeriod } = this.props;
        this.setState({ isLoading: true });
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                getTimeCardModifications(user);

                const lastPayPeriod = payPeriods[payPeriods.length - 1];
                if (lastPayPeriod) {
                    getTimeCardsByPayPeriod({
                        user,
                        startDate: lastPayPeriod.StartDate,
                        endDate: lastPayPeriod.EndDate,
                        isReplace: true,
                    }).then((r) => {
                        if (r === true) {
                            this.setState({ isLoading: false });
                        }
                    });
                }
            } else {
                this.setState({ isLoading: false });
                showMessage({
                    message: 'Connection Failure',
                    description: 'We are unable to get the most recent time cards.',
                    type: 'danger',
                    duration: 5000,
                    animationDuration: 700,
                    hideOnPress: true,
                    hideStatusBar: true,
                    backgroundColor: Color.red,
                });
            }
        });
    };

    getMoreTimeCards = () => {
        const { isLoading, user, payPeriods, lastGetTimeCardsStartDate, getTimeCardsByPayPeriod } = this.props;
        if (isLoading) {
            return
        }

        if (lastGetTimeCardsStartDate === null) {
            const previousPayPeriod = payPeriods[payPeriods.length - 1];
            getTimeCardsByPayPeriod({
                user,
                startDate: previousPayPeriod.StartDate,
                endDate: previousPayPeriod.EndDate,
            });
        } else {
            let index = payPeriods.findIndex((period) =>
                moment(period.StartDate).isSame(lastGetTimeCardsStartDate, 'day'),
            );

            if (payPeriods[index - 1]) {
                const previousPayPeriod = payPeriods[index - 1];
                getTimeCardsByPayPeriod({
                    user,
                    startDate: previousPayPeriod.StartDate,
                    endDate: previousPayPeriod.EndDate,
                });
            }
        }
    };

    render() {
        const { isLoading } = this.state;
        const { cards, isLoading: pIsLoading, user } = this.props;
        return (
            <SectionList
                sections={cards}
                keyExtractor={(item, i) => item.id + i}
                renderItem={({ item }) => <ListItem item={item} timeCard user={user} />}
                renderSectionHeader={({ section: { date, totalTime, isClockedIn, clockInTime } }) => (
                    <TitleComponent
                        date={date}
                        totalTime={totalTime}
                        isClockedIn={isClockedIn}
                        clockInTime={clockInTime}
                    />
                )}
                ListFooterComponent={() => {
                    if (pIsLoading) {
                        return <ActivityIndicator color={Color.cobalt_50} size={20} />;
                    }
                    return null;
                }}
                contentContainerStyle={s.mainContainerEntries}
                refreshing={isLoading}
                onRefresh={this.reloadTimeCards}
                onEndReached={this.getMoreTimeCards}
                onEndReachedThreshold={0.5}
            />
        );
    }
}
