import { StyleSheet } from 'react-native';
import { Font, Color } from '../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        overflow: 'scroll',
        paddingHorizontal: '5%',
        paddingTop: 20,
        paddingBottom: 100,
    },
    buttonWrapper: {
        width: 180,
        marginTop: 20,
        alignSelf: 'center',
    },
    listWrapper: {
        paddingTop: 20,
        height: '82%',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    menuTopContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    topMenu_title: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font12,
        fontFamily: Font.type.regular,
    },
    modalLoading: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.white_50,
    },
    formWrapper: {
        width: '100%',
        backgroundColor: Color.white,
        borderRadius: 5,
        padding: 17,
        marginBottom: 9,
        alignItems: 'center',
        justifyContent: 'center',
        elevation: 4,
    },
    formWrapper_photoUpload: {
        minHeight: 155,
        backgroundColor: Color.light_periwinkle,
        alignItems: 'center',
        justifyContent: 'center',
    },
    formTitle: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
        alignSelf: 'flex-start',
    },
    photoUploadIcon: {
        color: Color.light_navy,
        fontSize: 40,
        marginLeft: 10,
    },
    photoUploadPlus: {
        width: 20,
        height: 20,
        backgroundColor: Color.light_periwinkle,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: -20,
        marginRight: -25,
        borderRadius: 12.5,
    },
    photoUploadPlusIcon: {
        color: Color.light_navy,
        fontSize: 20,
    },
    materialItem: {
        marginVertical: 12.5,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
    },
    materialItemFields: {
        flex: 1,
        paddingRight: 20,
    },
    materialDropdown: {
        backgroundColor: Color.white,
        borderRadius: 6,
        minHeight: 0,
        width: '100%',
        marginBottom: 10,
        elevation: 5,
        paddingHorizontal: 10,
        flexDirection: 'column',
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    materialDropdown_half: {
        marginBottom: 10,
        width: '48%',
    },
    fieldInternalStyle: {
        borderColor: Color.light_grey,
        borderWidth: 2,
        shadowColor: Color.transparent,
        elevation: 0,
        paddingHorizontal: 10,
        height: 50,
    },
    deleteButton: {
        width: 25,
        height: 25,
        borderRadius: 12.5,
        borderWidth: 1,
        borderColor: Color.pinkish_orange,
        justifyContent: 'center',
        alignItems: 'center',
    },
    deleteIcon: {
        color: Color.pinkish_orange,
    },
    materialItemAdd: {
        width: '100%',
        height: 45,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        borderRadius: 5,
        borderColor: Color.light_navy_20,
        borderStyle: 'dashed',
        borderWidth: 1,
    },
    materialItemAddIcon: {
        color: Color.light_navy,
        fontSize: Font.size.font20,
        marginRight: 10,
    },
    materialItemAddText: {
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
        color: Color.light_navy,
    },
    emailAdd: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10,
    },
    emailAddField: {
        flex: 1,
        marginVertical: 10,
        paddingRight: 10,
    },
    emailAddButton: {
        width: 25,
        height: 25,
        borderRadius: 12.5,
        borderWidth: 1,
        borderColor: Color.light_navy,
        justifyContent: 'center',
        alignItems: 'center',
    },
    emailAddIcon: {
        color: Color.light_navy,
    },
    modalSignature: {
        width: '100%',
        height: 110,
    },
    signature: {
        width: '100%',
        height: '100%',
    },
    signaturePlaceholder: {
        position: 'absolute',
        left: '10%',
        width: '80%',
        top: 65,
        borderTopWidth: 1,
        paddingTop: 7,
        borderColor: Color.brown_grey,
        color: Color.brown_grey,
        fontFamily: Font.type.light,
        textAlign: 'center',
    },
    attachmentsWrapper: {
        width: '100%',
        flexDirection: 'row',
        marginTop: 10,
        flexWrap: 'wrap',
    },
    attachmentItem: {
        height: 150,
        width: 150,
        marginRight: 10,
        marginBottom: 10,
    },
    attachmentImage: {
        height: '100%',
        width: '100%',
    },
    attachmentButton: {
        width: 30,
        height: 30,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        right: 5,
        top: 5,
        backgroundColor: Color.pale_grey,
    },
    loadingModalWrapper: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Color.dark_navy_blue_50,
    },
    uploadBlock: {
        backgroundColor: Color.white,
        borderRadius: 6,
        padding: 10,
        width: '100%',
        elevation: 5,
        marginBottom: 10,
    },
    uploadBlockHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    uploadBlockTitle: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
        alignSelf: 'flex-start',
        marginTop: 6,
    },
    uploadIcon: {
        backgroundColor: Color.light_navy_10,
        width: 25,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12.5,
        marginLeft: 10,
    },
    dropdownCalendarWrapper: {
        zIndex: 3,
    },
    timesRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        zIndex: 2,
    },
    breaksRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        paddingRight: 40,
    },
    timeRow: {
        marginTop: 10,
        marginBottom: 20,
    },
    timeRow_range: {
        marginBottom: 5,
    },
    timeText: {
        color: Color.dark_navy_blue_30,
        fontSize: Font.size.font12,
        fontFamily: Font.type.regular,
    },
    textAttestation: {
        color: Color.dark_navy_blue_30,
        fontSize: Font.size.font12,
        fontFamily: Font.type.regular,
        textAlign: 'center',
    },
    timeText_warn: {
        color: Color.faded_orange,
    },
    dateRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
        paddingRight: 5,
    },
    dateRowTitle: {
        fontSize: Font.size.font10,
        fontFamily: Font.type.regular,
        color: Color.grey,
    },
    dateRowHours: {
        fontSize: Font.size.font12,
        fontFamily: Font.type.regular,
        color: Color.blue,
        paddingHorizontal: 10,
    },
    rowTitle: {
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
        color: Color.light_blue_grey,
        marginBottom: 10,
        marginLeft: 5,
    },
    rangeDaysContainer: {
        borderRadius: 6,
        backgroundColor: Color.white,
        padding: 10,
        elevation: 6,
        marginBottom: 10,
        width: '100%',
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        zIndex: 1,
    },
    breakDelete: {
        position: 'absolute',
        right: 0,
        top: 10,
    },
});
