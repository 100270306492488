import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, FlatList } from 'react-native';
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { setUserTime } from '../../../store/modules/dailyReports';
import { NavigationBar } from '../../../components/index';

import { generateId } from '../../../utils/helpers';

import s from './styles';
import { Color } from '../../../theme/index';

class DailyWorkLogContractorsScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentReport: PropTypes.object,
        setUserTime: PropTypes.func,
    };

    state = { items: [] };

    componentDidMount() {
        this._returnItems();
    }

    componentDidUpdate(prevProps) {
        const { currentReport } = this.props;
        if (currentReport.UsersTime.length !== prevProps.currentReport.UsersTime.length) {
            this._returnItems();
        }
    }

    _returnItems() {
        const { currentReport } = this.props;
        const items = [];
        currentReport.UsersTime.forEach((userTime) => {
            const listItem = {
                id: userTime.id,
                Note: userTime.Note,
                User: userTime.User,
                Hours: userTime.Hours,
            };
            items.push(listItem);
        });
        this.setState({ items });
    }

    _returnItem(userTime) {
        const { navigation, setUserTime, currentReport } = this.props;
        const currentUserTime = currentReport.UsersTime.find((ut) => ut.id === userTime.id);

        return (
            <TouchableOpacity
                style={s.itemWrapper}
                onPress={() => {
                    setUserTime(currentUserTime);
                    navigation.navigate('DailyWorkLogContractor');
                }}
            >
                <Text style={s.itemTitle}>{userTime.User.substr(0, 20)}</Text>
            </TouchableOpacity>
        );
    }

    addUserTime = () => {
        const { navigation, setUserTime } = this.props;
        const userTime = {
            id: generateId(),
            Note: null,
            User: null,
            Hours: null,
        };
        setUserTime(userTime);
        navigation.navigate('DailyWorkLogContractor');
    };

    render() {
        const { items } = this.state;
        return (
            <View style={[s.wrapper]}>
                <NavigationBar {...this.props} title="Users Time" backIcon />
                <View style={s.menuTopContainer}>
                    <Text style={s.topMenu_title}>{moment().format('MM/DD/YY')}</Text>
                </View>
                <View style={[s.mainContainer]}>
                    <FlatList
                        data={items}
                        keyExtractor={(item, i) => item.id + i}
                        renderItem={({ item }) => this._returnItem(item)}
                        contentContainerStyle={{ paddingBottom: 0, alignSelfL: 'center' }}
                        style={{ width: '100%' }}
                    />
                    <TouchableOpacity style={s.addButton} onPress={this.addUserTime}>
                        <Icon name="plus" color={Color.white} size={30} />
                    </TouchableOpacity>
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ dailyReports }) => ({
    currentReport: dailyReports.currentReport,
});

export default connect(mapStateToProps, { setUserTime })(DailyWorkLogContractorsScreen);
