import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Keyboard, AppState, ActivityIndicator } from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import { connect } from 'react-redux';
import FlashMessage, { showMessage } from 'react-native-flash-message';
import { isEmpty, cloneDeep } from 'lodash';
import Qs from 'qs';
import Navigator from '../navigation/navigator';
import {
    getCostCodes,
    loadProjects,
    getDailyQuestions,
    saveProfilePhoto,
    initApp,
    userUpdate,
    getNotifications,
    userAutoLogin,
} from '../store/modules/account';
import { saveCustomReportItem } from '../store/modules/customReports';
import {
    getTimeCards,
    syncTimeCard,
    syncUnsavedSignedPeriod,
    submitDailyQuestion,
    getPayPeriods,
} from '../store/modules/timeCards';
import { addLocation, clockOut, breakIn, setDataAppLoading } from '../store/modules/currentTimeCard';
import { submitReimbursementRequest } from '../store/modules/reimbursements';
import { uploadImage } from '../store/helpers/common';
import { saveInjuryTicket } from '../store/modules/injuryReporting';
import { setCurrentProject, setCurrentCostCode, saveReport } from '../store/modules/dailyReports';
import { saveTimeOffItem, getPtoBuckets } from '../store/modules/timeOff';
import { cleanTimeCardEdit, saveTimeCardEdit } from '../store/modules/timeCardEdit';
import { saveToolboxTalks } from '../store/modules/toolboxTalks';
import { saveAccidentReportAttachments, saveAccidentReportTicket } from '../store/modules/accidentReporting';
import { Color } from '../theme';

class MobileMainContainer extends Component {
    static propTypes = {
        cachedPhoto: PropTypes.object,
        currentTimeCard: PropTypes.object,
        isAutoLoginLoading: PropTypes.bool,
        user: PropTypes.object,
        unsavedCards: PropTypes.array,
        unsavedTimeCardEdits: PropTypes.array,
        unsavedSignedPeriods: PropTypes.array,
        reports: PropTypes.array,
        toolboxTalksTickets: PropTypes.array,
        accidentReportTickets: PropTypes.array,
        injuryTickets: PropTypes.array,
        timeOffItems: PropTypes.array,
        timeCardsIsSyncing: PropTypes.bool,
        signedPeriodsIsSyncing: PropTypes.bool,
        isReportSaveLoading: PropTypes.bool,
        isAccidentReportSaveLoading: PropTypes.bool,
        initApp: PropTypes.func,
        loadProjects: PropTypes.func,
        getCostCodes: PropTypes.func,
        getTimeCards: PropTypes.func,
        getDailyQuestions: PropTypes.func,
        setDataAppLoading: PropTypes.func,
        saveProfilePhoto: PropTypes.func,
        syncTimeCard: PropTypes.func,
        clockOut: PropTypes.func,
        setCurrentProject: PropTypes.func,
        setCurrentCostCode: PropTypes.func,
        uploadImage: PropTypes.func,
        saveReport: PropTypes.func,
        saveAccidentReportTicket: PropTypes.func,
        saveAccidentReportAttachments: PropTypes.func,
        saveTimeCardEdit: PropTypes.func,
        syncUnsavedSignedPeriod: PropTypes.func,
        saveToolboxTalks: PropTypes.func,
        saveInjuryTicket: PropTypes.func,
        saveTimeOffItem: PropTypes.func,
        submitDailyQuestion: PropTypes.func,
        locallySavedDailyQuestions: PropTypes.array,
        firstPayPeriod: PropTypes.object,
        getPayPeriods: PropTypes.func,
        userUpdate: PropTypes.func,
        completedCustomReportItems: PropTypes.array,
        saveCustomReportItem: PropTypes.func,
        getNotifications: PropTypes.func,
        userAutoLogin: PropTypes.func,
        notifications: PropTypes.array,
        submitReimbursementRequest: PropTypes.func,
        reimbursementRequests: PropTypes.array,
        getPtoBuckets: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            appState: AppState.currentState,
            outdated: false,
        };

        this.syncTimeCardsInterval = null;
    }

    async componentDidMount() {
        const { userUpdate } = this.props;
        initApp();
        cleanTimeCardEdit();
        AppState.addEventListener('change', this._handleAppStateChange);

        await this.checkAutoLogin();

        this.navigation = Navigator.createRootNavigator(this.props.user);
        // force to re-render after navigator created
        this.setState({});

        const netState = await NetInfo.fetch();

        if (netState.isConnected) {
            this.postUnsavedTimeCards();
            this.postUnsavedReports();
            this.postUnsavedTimecardsModifications();
            this.postUnsavedSignedPeriods();
            this.postUnsavedPaidTimeOffTickets();
            this.postToolboxTalks();
            this.postUnsavedAccidentReports();
            this.postUnsavedInjuryTickets();
            this.postUnsavedDailyQuestionResponses();
            this.postUnsavedCustomReports();
            this.postUnsavedReimbursementRequests();
            if (this.props.user) {
                userUpdate(this.props.user._id);
            }

            if (!isEmpty(this.props.cachedPhoto)) {
                uploadImage(this.props.cachedPhoto.data, this.props.cachedPhoto.img, 'avatar').then(
                    ({ asset, error }) => {
                        if (!error) {
                            saveProfilePhoto(this.props.user, asset);
                        }
                    },
                );
            }
        }

        this.syncTimeCardsInterval = setInterval(() => this.postUnsavedTimeCards(), 60000);
        this.showNotifications(this.props.notifications);

        window.addEventListener('message', this._handleNavigationMessages.bind(this), false);
    }

    showNotifications(notifications) {
        for (const notification of notifications) {
            showMessage({
                message: notification.Title,
                description: notification.Message,
                type: notification.Type,
                duration: 5000,
                animationDuration: 700,
                hideOnPress: true,
                hideStatusBar: true,
                backgroundColor: Color.faded_orange,
                color: Color.dark_navy_blue,
            });
        }
    }

    async checkAutoLogin() {
        if (window.location.search) {
            const parsedQueries = Qs.parse(window.location.search, { ignoreQueryPrefix: true });
            if (parsedQueries.token) {
                await this.props.userAutoLogin(this.props.user, parsedQueries.token);
            }
        }

        if (!isEmpty(this.props.user)) {
            await this.getData();
        }
    }

    componentDidUpdate(prevProps) {
        const { user, notifications } = this.props;
        const { user: prevUser } = prevProps;

        if (
            (isEmpty(prevUser) && !isEmpty(user)) ||
            (!isEmpty(prevUser) && isEmpty(user)) ||
            (!isEmpty(user) && JSON.stringify(user.permissions) !== JSON.stringify(prevUser.permissions))
        ) {
            this.navigation = Navigator.createRootNavigator(user);
            // force to re-render after navigator created
            this.setState({});
        }

        if (notifications && prevProps.notifications && notifications.length !== prevProps.notifications.length) {
            this.showNotifications(notifications);
        }

        if (isEmpty(prevUser) && !isEmpty(user)) {
            this.getData();
        }
    }

    componentWillUnmount() {
        AppState.removeEventListener('change', this._handleAppStateChange);
        window.removeEventListener('message', this._handleNavigationMessages);
    }

    locationPermissionClockOut() {
        const { clockOut, setCurrentProject, setCurrentCostCode, currentTimeCard } = this.props;
        clockOut({
            currentTimeCard,
        });
        setCurrentProject(currentTimeCard.currentProject);
        setCurrentCostCode(currentTimeCard.currentCostCode);
    }

    _handleAppStateChange = (nextAppState) => {
        const { user, userUpdate } = this.props;
        const { appState } = this.state;
        if (appState.match(/inactive|background/) && nextAppState === 'active') {
            // check connection and try to push not saved time cards
            NetInfo.fetch().then((state) => {
                if (state.isConnected) {
                    this.postUnsavedTimeCards();
                    if (user) {
                        userUpdate(user._id);
                    }
                }
            });
        }
        this.setState({ appState: nextAppState });

        if (nextAppState === 'background' && this.syncTimeCardsInterval) {
            clearInterval(this.syncTimeCardsInterval);
            this.syncTimeCardsInterval = null;
        } else if (nextAppState === 'active') {
            if (!this.syncTimeCardsInterval) {
                this.syncTimeCardsInterval = setInterval(() => this.postUnsavedTimeCards(true), 60000);
            }
        }
    };

    _handleNavigationMessages = (event) => {
        if (event?.data?.action === 'navigate') {
            const url = event?.data?.url;
            if (url) {
                // parse url
                const urlInfo = new URL(url);
                this._handleDeepLink(urlInfo.pathname);
            }
        }
    };

    _handleDeepLink = (pathname) => {
        let route = null;
        let subRoute = null;
        switch (pathname) {
            case '/clock':
                route = 'TimeTracking';
                subRoute = 'Clock';
                break;
            case '/time-cards':
                route = 'TimeTracking';
                subRoute = 'TimeCards';
                break;
            case '/schedule':
                route = 'Schedule';
                subRoute = 'ScheduleDashboard';
                break;
            case '/pto':
                route = 'PaidTimeOff';
                subRoute = 'PaidTimeOffList';
                break;
            case '/reimbursement':
                // TODO: assign route and subRoute after adding reimbursement functionality
                break;
        }

        if (route && this.navigation) {
            Navigator.navigate(route, subRoute, { hideUINavigation: true });
        }
    };

    postUnsavedPaidTimeOffTickets = async () => {
        const { user, timeOffItems, saveTimeOffItem } = this.props;
        const unsavedTimeOffItems = timeOffItems.filter((r) => r.unsaved);

        for (const timeOff of unsavedTimeOffItems) {
            saveTimeOffItem({ timeOff, user });
        }
    };

    postUnsavedCustomReports = async () => {
        const { completedCustomReportItems, saveCustomReportItem, user } = this.props;
        const unsavedCompletedCustomReportItems = completedCustomReportItems.filter((r) => r.unsaved);
        for (const report of unsavedCompletedCustomReportItems) {
            saveCustomReportItem(report, user);
        }
    };

    postUnsavedTimeCards = async (silent) => {
        const { user, unsavedCards, timeCardsIsSyncing, syncTimeCard, firstPayPeriod } = this.props;

        if (!isEmpty(user) && unsavedCards && unsavedCards.length && !timeCardsIsSyncing) {
            await syncTimeCard(unsavedCards, user, firstPayPeriod, silent);
        }

        return true;
    };

    postUnsavedReports = async () => {
        const { user, reports, isReportSaveLoading, saveReport } = this.props;
        const unsavedReports = reports.filter((r) => r.unsaved);

        if (!isReportSaveLoading && !isEmpty(user)) {
            for (const report of unsavedReports) {
                const finalReport = cloneDeep(report);

                saveReport({
                    currentReport: finalReport,
                    user,
                    saveOffline: false,
                    silent: true,
                });
            }
        }
    };

    postUnsavedAccidentReports = async () => {
        const {
            user,
            accidentReportTickets,
            isAccidentReportSaveLoading,
            saveAccidentReportTicket,
            saveAccidentReportAttachments,
        } = this.props;
        const unsavedAccidentReportTickets = accidentReportTickets.filter((t) => t.unsaved);
        if (!isAccidentReportSaveLoading && !isEmpty(user)) {
            for (const accidentReport of unsavedAccidentReportTickets) {
                const ticket = cloneDeep(accidentReport);
                const success = await saveAccidentReportAttachments({ ticket });
                saveAccidentReportTicket({ ticket, user, isConnected: success });
            }
        }
    };

    postUnsavedTimecardsModifications = async () => {
        const { unsavedTimeCardEdits, saveTimeCardEdit } = this.props;
        for (const card of unsavedTimeCardEdits) {
            saveTimeCardEdit(null, card, null, null);
        }
    };

    postUnsavedSignedPeriods = () => {
        const { user, signedPeriodsIsSyncing, unsavedSignedPeriods, syncUnsavedSignedPeriod } = this.props;
        if (!signedPeriodsIsSyncing && !isEmpty(user)) {
            syncUnsavedSignedPeriod(unsavedSignedPeriods, user);
        }
    };

    postToolboxTalks = () => {
        const { user, toolboxTalksTickets, saveToolboxTalks } = this.props;
        const unsavedToolboxTalksTickets = toolboxTalksTickets.filter((t) => t.unsaved);
        if (!isEmpty(user)) {
            for (const ticket of unsavedToolboxTalksTickets) {
                saveToolboxTalks({ user, ticket: cloneDeep(ticket) });
            }
        }
    };

    postUnsavedInjuryTickets = async () => {
        const { user, injuryTickets, saveInjuryTicket } = this.props;
        const unInjuryTickets = injuryTickets.filter((r) => r.unsaved);
        if (!isEmpty(user)) {
            for (const ticket of unInjuryTickets) {
                saveInjuryTicket({ user, ticket });
            }
        }
    };

    postUnsavedDailyQuestionResponses = () => {
        const { submitDailyQuestion, locallySavedDailyQuestions } = this.props;
        for (const savedDailyQuestion of locallySavedDailyQuestions) {
            submitDailyQuestion(savedDailyQuestion);
        }
    };

    postUnsavedReimbursementRequests = () => {
        const { user, reimbursementRequests, submitReimbursementRequest } = this.props;
        const unsavedReimbursementRequests = reimbursementRequests.filter((r) => r.unsaved);
        if (!isEmpty(user)) {
            for (const unsavedReimbursementRequest of unsavedReimbursementRequests) {
                submitReimbursementRequest({ request: unsavedReimbursementRequest, user }, true);
            }
        }
    };

    async getData() {
        const {
            user,
            unsavedCards,
            getNotifications,
            loadProjects,
            getCostCodes,
            getTimeCards,
            getDailyQuestions,
            setDataAppLoading,
            getPayPeriods,
            getPtoBuckets,
        } = this.props;
        setDataAppLoading(true);

        const netState = await NetInfo.fetch();
        if (netState.isConnected) {
            await Promise.all([
                getNotifications(),
                getPayPeriods(),
                loadProjects(0),
                getCostCodes(),
                getTimeCards({ user, unsavedCards }),
                getDailyQuestions(),
                getPtoBuckets(),
            ]);
            setDataAppLoading(false);
        } else {
            setDataAppLoading(false);
        }
    }

    render() {
        const { outdated } = this.state;
        const NavigatorComponent = this.navigation;
        return (
            <View style={{ flex: 1 }}>
                {!this.navigation && (
                    <ActivityIndicator
                        color={Color.blue}
                        size={40}
                        style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
                    />
                )}
                {!outdated && this.navigation && (
                    <NavigatorComponent
                        ref={(navigatorRef) => {
                            Navigator.setTopLevelNavigator(navigatorRef);
                        }}
                        onNavigationStateChange={() => {
                            Keyboard.dismiss();
                        }}
                    />
                )}
                <FlashMessage position="top" />
            </View>
        );
    }
}

const mapStateToProps = ({
    account,
    currentTimeCard,
    timeCards,
    dailyReports,
    timeCardEdit,
    toolboxTalks,
    accidentReporting,
    injuryReporting,
    timeOff,
    customReports,
    reimbursements,
}) => ({
    user: account.user,
    isAutoLoginLoading: account.isAutoLoginLoading,
    cachedPhoto: account.cachedPhoto,
    isMenuOpen: account.isMenuOpen,
    timeCards: timeCards.timeCards,
    currentTimeCard,
    unsavedCards: timeCards.unsavedCards,
    unsavedDailyQuestions: timeCards.unsavedDailyQuestions,
    unsavedTimeCardEdits: timeCardEdit.unsaved,
    timeCardsIsSyncing: timeCards.timeCardsIsSyncing,
    signedPeriodsIsSyncing: timeCards.signedPeriodsIsSyncing,
    unsavedSignedPeriods: timeCards.unsavedSignedPeriods,
    reports: dailyReports.reports,
    isReportSaveLoading: dailyReports.isSaveLoading,
    timeOffItems: timeOff.timeOffItems,
    toolboxTalksTickets: toolboxTalks.toolboxTalksTickets,
    accidentReportTickets: accidentReporting.accidentReportTickets,
    isAccidentReportSaveLoading: accidentReporting.isSaveLoading,
    injuryTickets: injuryReporting.injuryTickets,
    locallySavedDailyQuestions: timeCards.locallySavedDailyQuestions,
    firstPayPeriod: timeCards.firstPayPeriod,
    completedCustomReportItems: customReports.completedCustomReportItems,
    notifications: account.notifications,
    reimbursementRequests: reimbursements.reimbursementRequests,
});

export default connect(mapStateToProps, {
    getCostCodes,
    loadProjects,
    getTimeCards,
    syncTimeCard,
    breakIn,
    addLocation,
    clockOut,
    setCurrentProject,
    setCurrentCostCode,
    getDailyQuestions,
    uploadImage,
    saveProfilePhoto,
    cleanTimeCardEdit,
    submitDailyQuestion,
    initApp,
    setDataAppLoading,
    saveReport,
    saveAccidentReportTicket,
    saveAccidentReportAttachments,
    saveTimeCardEdit,
    syncUnsavedSignedPeriod,
    saveTimeOffItem,
    saveToolboxTalks,
    saveInjuryTicket,
    getPayPeriods,
    userUpdate,
    saveCustomReportItem,
    getNotifications,
    userAutoLogin,
    submitReimbursementRequest,
    getPtoBuckets,
})(MobileMainContainer);
