import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../../theme';

export default StyleSheet.create({
    listItemWrapper: {
        width: '90%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 13,
        paddingTop: 12,
        paddingBottom: 17,
        marginBottom: 10,
        backgroundColor: Color.white,
        borderRadius: 6,
        height: 90,
        elevation: 5,
        alignSelf: 'center',
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    listItemWrapper_comment: {
        height: 110,
    },
    // listItemButton: {
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     width: 30,
    //     marginLeft: 20,
    //     paddingTop: '1%',
    //     height: '100%',
    // },
    // listItemButton_comment: {
    //     paddingTop: 0,
    //     paddingBottom: 15,
    // },
    checkBox: {
        width: 15,
        height: 15,
        backgroundColor: Color.light_grey,
        borderRadius: 2,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 12.5,
        marginTop: -15,
    },
    checkBox_checked: {
        backgroundColor: Color.light_navy,
    },
    iconChecked: {
        color: Color.white,
        fontSize: Font.size.font9,
    },
    listItemBody: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    listItemInfo: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    listItemInfoItem: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        marginRight: 20,
    },
    listItemInfoData: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font10,
        color: Color.light_grey_blue,
    },
    listItemInfoIcon: {
        fontSize: Font.size.font9,
        color: Color.light_grey_blue,
        marginTop: 3,
        marginRight: 2,
    },
    listItemTitle: {
        color: Color.dark_navy_blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
    },
    listItemSurveyName: {
        color: Color.light_grey_blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        paddingTop: '8%',
        paddingRight: '5%',
    },
    listItemDate: {
        color: Color.light_grey_blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font12,
    },
    successText: {
        fontFamily: Font.type.bold,
        color: Color.dark_navy_blue,
        fontSize: Font.size.font9,
        marginRight: 3,
        fontWeight: 'bold',
    },
    successText_ready: {
        color: Color.turquoise,
    },
    successText_error: {
        color: Color.pinkish_orange,
    },
    commentText: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font9,
        fontFamily: Font.type.regular,
        marginLeft: 5,
    },
    cardType: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: Color.light_navy,
        height: 17,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 8,
        marginTop: 12,
    },
    cardTypeText: {
        color: Color.blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font8,
    },
    cardTypeOffline: {
        borderColor: '#ffecb2',
        backgroundColor: '#fefbef',
        marginLeft: 10,
    },
    cardTypeOfflineText: {
        color: '#f3a34d',
        fontFamily: Font.type.regular,
        fontSize: Font.size.font10,
    },
    cardTypeStatusText: {
        color: Color.red,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font10,
    },
});
