import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme';

export default StyleSheet.create({
    listItemWrapper: {
        width: '90%',
        height: 89.5,
        marginBottom: 10,
        marginLeft: 22.5,
        marginRight: 21.5,
        backgroundColor: Color.white,
        borderRadius: 6,
        elevation: 5,
    },
    listItemWrapperDenied: {
        width: '90%',
        height: 109.5,
        marginBottom: 10.5,
        marginLeft: 22.5,
        marginRight: 21.5,
        backgroundColor: Color.white,
        borderRadius: 6,
        elevation: 5,
    },
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        paddingBottom: 125,
        backgroundColor: Color.pale_grey_three,
    },
    addButton: {
        width: 43,
        height: 43,
        borderRadius: 22,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.light_navy,
        elevation: 12,
        shadowColor: Color.light_grey_blue,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.5,
        shadowRadius: 5,
    },
    bottomButtonsBlock: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        position: 'absolute',
        bottom: 0,
        elevation: 12,
        height: 100,
        left: '45%',
    },
    mainContainer: {
        paddingTop: 21.5,
        height: '100%',
        width: '100%',
        alignItems: 'center',
    },
    listItemDateText: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font10,
        color: Color.dark_navy_blue,
        letterSpacing: 0.45,
        textAlign: 'left',
        paddingLeft: 18.5,
        marginTop: 14,
    },
    listItemStatusPending: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font9,
        color: Color.light_grey_blue,
        letterSpacing: 0.41,
        textAlign: 'left',
        paddingLeft: 18.5,
        marginTop: 10,
    },
    listItemStatusApproved: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font9,
        color: Color.turquoise,
        letterSpacing: 0.41,
        textAlign: 'left',
        paddingLeft: 18.5,
        marginTop: 12,
    },
    listItemStatusDenied: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font9,
        color: Color.pinkish_orange,
        letterSpacing: 0.41,
        textAlign: 'left',
        paddingLeft: 18.5,
        marginTop: 12,
    },
    listItemComments: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font9,
        color: Color.light_grey_blue,
        letterSpacing: 0.023,
        paddingLeft: 18.5,
        marginTop: 10,
    },
    menuContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        paddingHorizontal: 15,
        alignItems: 'center',
    },
    menuTitle: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font12,
        color: Color.dark_navy_blue,
        marginLeft: '28%',
    },
});
