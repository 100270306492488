export default {
    SET_CURRENT_PROJECT: 'SET_CURRENT_PROJECT',
    SET_VIEWING_PROJECT: 'SET_VIEWING_PROJECT',
    SET_USER_VIEWING_PROJECT: 'SET_USER_VIEWING_PROJECT',
    SET_CURRENT_COST_CODE: 'SET_CURRENT_COST_CODE',
    CREATE_DAILY_REPORT: 'CREATE_DAILY_REPORT',
    SET_NOTE: 'SET_NOTE',
    SET_USER_TIME: 'SET_USER_TIME',
    SAVE_USER_TIME: 'SAVE_USER_TIME',
    SAVE_NOTE: 'SAVE_NOTE',
    ADD_ESTIMATE: 'ADD_ESTIMATE',
    SAVE_SURVEY: 'SAVE_SURVEY',
    SET_DAILY_REPORT: 'SET_DAILY_REPORT',
    SAVE_NOTE_IMAGE: 'SAVE_NOTE_IMAGE',
    REMOVE_NOTE_IMAGE: 'REMOVE_NOTE_IMAGE',
    UPLOAD_START: 'UPLOAD_START',
    UPLOAD_FINISH: 'UPLOAD_FINISH',
    CLEAN_UP: 'DAILY_REPORT_CLEANUP',
    SUBMIT_DAILY_REPORT: {
        LOADING: 'SUBMIT_DAILY_REPORT',
        SUCCESS: 'SUBMIT_DAILY_REPORT_SUCCESS',
        ERROR: 'SUBMIT_DAILY_REPORT_ERROR',
    },
    FILE_UPLOAD: {
        START: 'FILE_UPLOAD',
        SUCCESS: 'FILE_UPLOAD_SUCCESS',
        ERROR: 'FILE_UPLOAD_ERROR',
        FINISH: 'FILE_UPLOAD_FINISH',
    },
    ATTACHMENT_UPLOAD: {
        START: 'ATTACHMENT_UPLOAD_START',
        SUCCESS: 'ATTACHMENT_UPLOAD_SUCCESS',
        ERROR: 'ATTACHMENT_UPLOAD_ERROR',
    },
    GET_REPORTS: 'GET_REPORTS',
    LOADING: {
        START: 'LOADING_START',
        FINISH: 'LOADING_FINISH',
    },
    GET_USER_NAME: 'GET_USER_NAME',
};
