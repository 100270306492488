import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, FlatList } from 'react-native';
import { connect } from 'react-redux';
import { NavigationBar, ListItem } from '../../../components/index';
import { Config } from '../../../config';
import s from './styles';

class DailyWorkLogSelectorScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentReport: PropTypes.object,
        currentProject: PropTypes.object,
    };

    _returnNewReportItems() {
        const { navigation, currentReport, currentProject } = this.props;
        let list = [];
        const costCodeTitle = Config.USE_DEPARTMENTS_JOBS ? 'Job' : 'Cost Code'
        if (currentProject && currentReport) {
            list = [
                {
                    id: 'costCodeLogs',
                    title: `${costCodeTitle} Logs`,
                    icon: 'account-outline',
                    quantity: `${currentProject.ProjectEstimates.length}`,
                    locked: true,
                    onPress: () => {
                        // navigation.navigate('DailyWorkLog')
                    },
                },
                {
                    id: 'contractorLabor',
                    title: 'User Time',
                    icon: 'clipboard-text-outline',
                    quantity: `${currentReport.UsersTime.length}`,
                    onPress: () => {
                        navigation.navigate('DailyWorkLogContractors');
                    },
                },
            ];
        }
        return list;
    }

    render() {
        return (
            <View style={[s.wrapper]}>
                <NavigationBar {...this.props} title="Work Logs" backIcon />
                <View style={s.menuTopContainer}>
                    <Text style={s.topMenu_title}>{moment().format('MM/DD/YY')}</Text>
                </View>
                <View style={[s.mainContainer]}>
                    <View style={s.listWrapper}>
                        <FlatList
                            data={this._returnNewReportItems()}
                            contentContainerStyle={{ paddingBottom: 0 }}
                            keyExtractor={(item) => item.id}
                            style={{ elevation: 20, width: '100%' }}
                            renderItem={({ item }) => {
                                return <ListItem item={item} />;
                            }}
                        />
                    </View>
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ dailyReports }) => ({
    currentReport: dailyReports.currentReport,
    currentProject: dailyReports.currentProject,
});

export default connect(mapStateToProps, {})(DailyWorkLogSelectorScreen);
