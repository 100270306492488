import { StyleSheet } from 'react-native';
import { Font, Color } from '../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        overflow: 'scroll',
        paddingHorizontal: '5%',
        paddingTop: 20,
        paddingBottom: 100,
    },
    buttonWrapper: {
        width: 180,
        marginTop: 20,
        alignSelf: 'center',
    },
    buttonWrapperSave: {
        width: '85%',
        marginTop: 20,
        alignSelf: 'center',
        paddingBottom: '1%',
    },
    buttonWrapperRemove: {
        width: '85%',
        marginTop: 20,
        alignSelf: 'center',
        paddingBottom: '1%',
    },
    materialDropdown: {
        backgroundColor: Color.white,
        borderRadius: 6,
        minHeight: 0,
        width: '100%',
        marginBottom: 10,
        elevation: 5,
        paddingHorizontal: 10,
        flexDirection: 'column',
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    materialDropdown_half: {
        marginBottom: 10,
        width: '48%',
    },
    fieldInternalStyle: {
        borderColor: Color.light_grey,
        borderWidth: 2,
        shadowColor: Color.transparent,
        elevation: 0,
        paddingHorizontal: 10,
        height: 50,
    },
    dropdownCalendarWrapper: {
        zIndex: 3,
    },
    timesRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        zIndex: 2,
    },
    breaksRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        paddingRight: 40,
    },
    timeRow: {
        marginTop: 10,
        marginBottom: 20,
    },
    textAttestation: {
        color: Color.dark_navy_blue_30,
        fontSize: Font.size.font12,
        fontFamily: Font.type.regular,
        textAlign: 'center',
    },
    dateRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
        paddingRight: 5,
    },
    dateRowTitle: {
        fontSize: Font.size.font10,
        fontFamily: Font.type.regular,
        color: Color.grey,
    },
    dateRowHours: {
        fontSize: Font.size.font12,
        fontFamily: Font.type.regular,
        color: Color.blue,
        paddingHorizontal: 10,
    },
    date: {
        fontSize: Font.size.font15,
        fontFamily: Font.type.bold,
        color: Color.blue,
        paddingHorizontal: 10,
        alignSelf: 'center',
        marginTop: 20,
        marginBottom: 20,
    },
    rangeDaysContainer: {
        borderRadius: 6,
        backgroundColor: Color.white,
        padding: 10,
        elevation: 6,
        marginBottom: 10,
        width: '100%',
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        zIndex: 1,
    },
    breakDelete: {
        position: 'absolute',
        right: 0,
        top: 10,
    },
    block: {
        backgroundColor: Color.white,
        marginBottom: 15,
        paddingHorizontal: 15,
        borderWidth: 1,
        borderColor: Color.light_blue_grey,
        borderRadius: 10,
    },
});
