import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    topRow: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        height: 75,
        paddingTop: 20,
        paddingHorizontal: 20,
        backgroundColor: Color.white,
    },

    twoSide: {
        justifyContent: 'space-between',
    },

    childRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },

    linkItem: {
        marginRight: 30,
    },

    linkText: {
        color: Color.white,
    },

    accTopRow: {
        backgroundColor: Color.blue,
    },

    darkTopRow: {
        backgroundColor: Color.dark,
    },

    iconWrapper: {
        width: 25,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
    },

    arrowBack: {
        color: Color.turquoise,
    },

    arrowBack_disabled: {
        color: Color.cloudy_blue,
    },

    menuIcon: {
        color: Color.turquoise,
        fontSize: Font.size.font23,
    },

    iconLight: {
        color: 'rgba(255,255,255,0.5)',
        fontSize: Font.size.font25,
    },

    iconDark: {
        color: 'rgba(153,153,153,0.7)',
    },

    bottomRow: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },

    leftComponentWrapper: {
        zIndex: 1,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '25%',
    },

    titleComponentWrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
    },

    titleComponentFullWidth: {
        paddingLeft: 0,
        width: '100%',
    },

    rightComponentWrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '25%',
    },

    title: {
        color: Color.blue,
        fontSize: Font.size.font13,
        textAlign: 'center',
        fontFamily: Font.type.black,
        marginRight: 0,
        textTransform: 'uppercase',
    },
    titleLogo: {
        height: 30,
        width: 50,
    },
    colorWhite: {
        color: Color.white,
    },
    modalBackGround: {
        flex: 1,
    },
    dropdownWrapper: {
        width: 180,
        paddingVertical: 9,
        backgroundColor: Color.white,
        borderRadius: 6,
        position: 'absolute',
        top: 70,
        right: 21,
        elevation: 8,
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.15,
        shadowRadius: 5,
    },
    dropDownItem: {
        width: '100%',
        height: 36,
        backgroundColor: Color.very_light_blue,
        paddingHorizontal: 20,
        justifyContent: 'center',
    },
    dropDownItemText: {
        color: Color.light_navy,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font12,
    },
    searchOpen: {
        position: 'absolute',
        width: '97%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundColor: Color.white,
        left: 20,
        zIndex: 10,
    },
    searchField: {
        flex: 1,
        borderBottomWidth: 1,
        borderBottomColor: Color.cloudy_blue,
        marginRight: 10,
        marginLeft: 10,
        marginTop: 5,
    },
    confirmationModal: {
        backgroundColor: Color.dark_navy_blue_50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    confirmationForm: {
        width: '80%',
        backgroundColor: Color.white,
        borderRadius: 6,
        padding: 20,
        alignItems: 'center',
    },
    confirmationTitle: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font20,
        color: Color.pinkish_orange,
        marginBottom: 20,
    },
    confirmationText: {
        fontFamily: Font.type.light,
        fontSize: Font.size.font18,
        color: Color.dark_navy_blue,
        marginBottom: 20,
    },
    confirmationButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
        width: '80%',
    },
    confirmationButton: {
        width: '50%',
        height: 50,
        justifyContent: 'center',
    },
    searchFieldContainer: {
        elevation: 0,
        color: Color.blue,
        width: '100%',
        paddingTop: 0,
    },
    searchFieldInput: {
        marginBottom: 0,
        height: 40,
        marginTop: 0,
    },
    companiesDropdown: {
        marginLeft: 20,
    },
});
