export default {
    SET_CURRENT_PROJECT: 'SET_CURRENT_PROJECT',
    SET_CURRENT_COST_CODE: 'SET_CURRENT_COST_CODE',
    SET_DESCRIPTION: 'SET_DESCRIPTION',
    SET_WORK_ORDER_NUMBER: 'SET_WORK_ORDER_NUMBER',
    SET_WORK_ORDER_YEAR: 'SET_WORK_ORDER_YEAR',
    CLOCK_IN: {
        LOADING: 'CLOCK_IN_LOADING',
        SUCCESS: 'CLOCK_IN',
    },
    CLOCK_OUT: {
        LOADING: 'CLOCK_OUT_LOADING',
        SUCCESS: 'CLOCK_OUT',
    },
    BREAK_IN: {
        LOADING: 'BREAK_IN_LOADING',
        SUCCESS: 'BREAK_IN',
    },
    BREAK_OUT: {
        LOADING: 'BREAK_OUT_LOADING',
        SUCCESS: 'BREAK_OUT',
    },
    ADD_LOCATION: 'ADD_LOCATION',
    SET_CLOCK_IN_TYPE: 'SET_CLOCK_IN_TYPE',
    SET_CURRENT_TAM: 'SET_CURRENT_TAM',
    SET_SWITCH: 'SET_SWITCH',
    CHANGE_CLOCK_IN_TIME: 'CHANGE_CLOCK_IN_TIME',
    CHANGE_PROJECT: 'CHANGE_PROJECT',
    CHANGE_COST_CODE: 'CHANGE_COST_CODE',
    CHANGE_BREAK_TIME: 'CHANGE_BREAK_TIME',
    SWITCH_TIME_CARD: 'SWITCH_TIME_CARD',
    CHANGE_MIS_CHOSEN_PROJECT: 'CHANGE_MIS_CHOSEN_PROJECT',
    CHANGE_PROJECT_DONE: 'CHANGE_PROJECT_DONE',
    SET_LOADING: 'SET_LOADING',
    SET_BREAK_IN_LOADING: 'SET_BREAK_IN_LOADING',
    SET_BREAK_OUT_LOADING: 'SET_BREAK_OUT_LOADING',
};
