import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 70,
        paddingHorizontal: '5%',
    },
    buttonWrapper: {
        width: 180,
        marginBottom: 50,
    },
    listTitle: {
        marginTop: 25,
        fontSize: Font.size.font14,
        fontFamily: Font.type.bold,
        color: Color.blue,
        marginBottom: 15,
    },
    bottomPadding: {
        height: 100,
    },
});
