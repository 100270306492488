export default {
    GET_PTO_BUCKETS: 'GET_PTO_BUCKETS',
    GET_TIME_OFF_ITEMS: 'GET_TIME_OFF_ITEMS',
    CREATE_TIME_OFF_ITEM: 'CREATE_TIME_OFF_ITEM',
    SET_TIME_OFF_ITEM: 'SET_TIME_OFF_ITEM',
    SAVE_TIME_OFF_ITEM_IMAGE: 'SAVE_TIME_OFF_ITEM_IMAGE',
    REMOVE_TIME_OFF_ITEM_IMAGE: 'REMOVE_TIME_OFF_ITEM_IMAGE',
    SUBMIT_TIME_OFF_ITEM: {
        LOADING: 'SUBMIT_TIME_OFF_ITEM',
        SUCCESS: 'SUBMIT_TIME_OFF_ITEM_SUCCESS',
        ERROR: 'SUBMIT_TIME_OFF_ITEM_ERROR',
    },
    LOADING: {
        START: 'SUBMIT_TIME_OFF_ITEM_START',
        FINISH: 'SUBMIT_TIME_OFF_ITEM_FINISH',
    },
    CLEAN_UP: 'TIME_OFF_CLEAN_UP',
};
