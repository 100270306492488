import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme';

export default StyleSheet.create({
    mainContainer: {
        paddingTop: 20,
        paddingBottom: 30,
        height: '100%',
        width: '100%',
        paddingHorizontal: '5%',
    },
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    title: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.blue,
        textAlign: 'left',
        marginBottom: 20,
        marginLeft: 15,
        marginTop: 15,
    },
    menuContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        justifyContent: 'center',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        paddingHorizontal: 15,
    },
    menuDotOne: {
        backgroundColor: Color.pale_grey_three,
        height: 9.5,
        width: 9.5,
        borderRadius: 5,
        marginTop: 22.5,
    },
    menuDotTwo: {
        backgroundColor: Color.pale_grey_three,
        marginLeft: 13.5,
        height: 9.5,
        width: 9.5,
        borderRadius: 5,
        marginTop: 22.5,
    },
    menuDotThree: {
        backgroundColor: Color.turquoise,
        borderRadius: 5,
        marginLeft: 12.5,
        height: 9.5,
        width: 9.5,
        marginTop: 22.5,
    },
    fieldInternalStyle: {
        borderColor: Color.light_grey,
        borderWidth: 2,
        shadowColor: Color.transparent,
        elevation: 0,
        height: 50,
    },
    button: {
        height: 50,
        width: 180,
        marginTop: 20,
        marginBottom: 80,
        alignSelf: 'center',
    },
    buttonText: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.white,
        textTransform: 'uppercase',
    },
    buttonCancel: {
        height: 50,
        backgroundColor: Color.white,
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: Color.turquoise,
        borderRadius: 7.5,
        alignItems: 'center',
        paddingTop: 17,
    },
    buttonTextCancel: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.turquoise,
    },
    listItemWrapper: {
        width: '100%',
        marginBottom: 10,
        backgroundColor: Color.white,
        borderRadius: 6,
        padding: 20,
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    listItemTitle: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
        marginBottom: 11,
    },
    listItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        width: '100%',
    },
    listItemLeft: {
        width: '90%',
    },
    listItemRight: {
        width: '10%',
    },
    removeButton: {
        borderColor: Color.pinkish_orange,
        borderStyle: 'solid',
        borderWidth: 2,
        height: 25,
        width: 25,
        alignItems: 'center',
        borderRadius: 12.5,
    },
    removeButtonContainer: {
        marginLeft: 20,
    },
    attachments: {
        width: '100%',
        height: 50,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: Color.white,
        elevation: 10,
        borderRadius: 8,
        paddingHorizontal: 20,
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    attachmentsText: {
        fontSize: Font.size.font11,
        color: Color.light_grey_blue,
        fontFamily: Font.type.regular,
    },
    attachmentsWrapper: {
        width: '100%',
        flexDirection: 'row',
        marginTop: 10,
        flexWrap: 'wrap',
    },
    attachmentItem: {
        width: 150,
        height: 150,
        marginRight: 10,
        marginBottom: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    attachmentImage: {
        height: '100%',
        width: '100%',
    },
    attachmentButton: {
        width: 30,
        height: 30,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        right: 5,
        top: 5,
        backgroundColor: Color.pale_grey,
    },
});
