import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    modalWrapper: {
        width: '100%',
        height: '100%',
        backgroundColor: Color.dark_navy_blue_50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalBlock: {
        backgroundColor: Color.white,
        borderRadius: 10,
        width: '90%',
        height: 330,
        alignItems: 'center',
    },
    editModalBlock: {
        height: 270,
    },
    editBlock_long: {
        height: 450,
    },
    editBlock_short: {
        height: 380,
    },
    editBlock_subcontractors: {
        height: 280,
    },
    editBlock_subcontractors_edit: {
        height: 210,
    },
    scrollContainer: {
        alignItems: 'center',
        padding: 15,
        paddingTop: 25,
    },
    modalTitle: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font12,
        color: Color.purple_brown,
        marginLeft: 5,
    },
    modalSignature: {
        width: '100%',
        height: 110,
    },
    signature: {
        width: '100%',
        height: '100%',
    },
    signaturePlaceholder: {
        position: 'absolute',
        left: '10%',
        width: '80%',
        top: 65,
        borderTopWidth: 1,
        paddingTop: 7,
        borderColor: Color.brown_grey,
        color: Color.brown_grey,
        fontFamily: Font.type.light,
        textAlign: 'center',
    },
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 20,
        width: '100%',
    },
    modalButtonsEdit: {
        marginTop: 0,
        width: '100%',
        justifyContent: 'space-between',
        paddingHorizontal: 10,
    },
    submitModalButton: {
        width: 180,
        marginLeft: 40,
    },
    cancelModalButton: {
        marginTop: -15,
    },
    editButtons: {
        flex: 1,
        marginLeft: 10,
    },
    editModalButton: {
        width: 60,
        marginRight: 25,
        marginTop: 18,
    },
    materialItem: {
        marginVertical: 12.5,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
    },
    materialItemFields: {
        flex: 1,
        paddingRight: 20,
    },
    materialItemFieldsEdit: {
        paddingRight: 0,
    },
    materialDropdown: {
        width: '100%',
        marginBottom: 10,
        borderWidth: 1,
        borderColor: Color.light_blue_grey,
        elevation: 0,
    },
    materialDropdown_multiline: {
        height: 100,
    },
    formWrapper: {
        width: '100%',
        backgroundColor: Color.white,
        borderRadius: 5,
        padding: 17,
        marginBottom: 9,
        alignItems: 'center',
        justifyContent: 'center',
        elevation: 4,
    },
    formTitle: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
        alignSelf: 'flex-start',
    },
    deleteButton: {
        width: 25,
        height: 25,
        borderRadius: 12.5,
        borderWidth: 1,
        borderColor: Color.pinkish_orange,
        justifyContent: 'center',
        alignItems: 'center',
    },
    deleteIcon: {
        color: Color.pinkish_orange,
    },
    materialItemAdd: {
        width: '100%',
        height: 45,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        borderRadius: 5,
        borderColor: Color.light_navy_20,
        borderStyle: 'dashed',
        borderWidth: 1,
    },
    materialItemAddIcon: {
        color: Color.light_navy,
        fontSize: Font.size.font20,
        marginRight: 10,
    },
    materialItemAddText: {
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
        color: Color.light_navy,
    },
});
