import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    container: {
        backgroundColor: Color.black,
        color: Color.black,
        width: '100%',
        height: '100%',
    },
    optionWrapper: {
        alignItems: 'center',
        height: 50,
        borderColor: Color.light_grey,
        borderWidth: 2,
        flexDirection: 'row',
        marginBottom: 10,
        borderRadius: 8,
    },
    optionWrapperSelected: {
        backgroundColor: '#f6f7fa',
    },
    circle: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 20,
        height: 20,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: Color.light_grey,
        marginLeft: 15,
    },
    circleSelected: {
        borderColor: Color.turquoise,
    },
    innerCircle: {
        width: 10,
        height: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: Color.turquoise,
        backgroundColor: Color.turquoise,
    },
    optionLabel: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
        marginLeft: 15,
    },
    // otherFieldInputWrapper: {
    //     height: 50,
    //     margin: 0,
    //     padding: 0,
    //     paddingHorizontal: 10,
    // },
    fieldInt: {
        borderColor: Color.light_grey,
        borderWidth: 2,
        shadowColor: Color.transparent,
    },
});
