import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ImageBackground, View, Text } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { setDescription, setWorkOrderNumber, setWorkOrderYear } from '../../../store/modules/currentTimeCard';
import { NavigationBar, PrimaryButton, TopGradient, FieldInput } from '../../../components/index';

import s from './styles';
import { Color, Img } from '../../../theme';

class ClockInDescriptionScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        workOrderNumber: PropTypes.string,
        description: PropTypes.string,
        isSwitch: PropTypes.bool,
        setDescription: PropTypes.func,
        setWorkOrderNumber: PropTypes.func,
        setWorkOrderYear: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            description: '',
            workOrderNumber: props.workOrderNumber,
            isUpdated: false,
        };
    }

    componentDidMount() {
        const { description } = this.props;
        this.setState({ description });
    }

    submitDescription = () => {
        const {
            setDescription,
            setWorkOrderNumber,
            setWorkOrderYear,
            navigation,
            workOrderNumber: prevWorkOrderNumber,
        } = this.props;
        const { description, workOrderNumber } = this.state;
        let workOrderYear = '';

        if (workOrderNumber || workOrderNumber !== prevWorkOrderNumber) {
            // try to set work order year automatically
            if (Number(workOrderNumber) > 22011) {
                workOrderYear = '22';
            } else if (Number(workOrderNumber) >= 17913) {
                workOrderYear = '21';
            } else {
                workOrderYear = '20';
            }
        }

        setWorkOrderNumber({ workOrderNumber });
        setWorkOrderYear({ workOrderYear });
        setDescription({ description });
        navigation.goBack();
    };

    render() {
        const { description, workOrderNumber, isUpdated } = this.state;
        const { isSwitch } = this.props;
        const backTitle = 'Warning';
        const backConfirmText =
            // eslint-disable-next-line max-len
            'You have made some changes. If you would like to keep the changes please hit the CANCEL button then the SAVE button. If you do not want to save the changes hit the OK button';
        return (
            <ImageBackground source={Img.bg} style={[s.wrapper]}>
                <NavigationBar
                    {...this.props}
                    backConfirm={isUpdated ? { title: backTitle, text: backConfirmText } : null}
                    title={isSwitch ? 'SWITCH' : 'CLOCK IN'}
                    backIcon
                />
                <View style={[s.mainContainer]}>
                    <FieldInput
                        title="WORK ORDER NUMBER"
                        initialValues={workOrderNumber}
                        input={{
                            onChange: (text) => {
                                this.setState({ workOrderNumber: text });
                                this.setState({ isUpdated: true });
                            },
                        }}
                        meta={{
                            error: null,
                            touched: false,
                        }}
                        maxLength={5}
                    />
                    <Text style={s.label}>
                        (Enter the last 5 digits of the Work Order. Do not include the year.)
                    </Text>
                    <FieldInput
                        title="ENTER YOUR DESCRIPTION HERE"
                        multiline
                        input={{
                            onChange: (text) => {
                                this.setState({ description: text });
                                this.setState({ isUpdated: true });
                            },
                        }}
                        meta={{
                            error: null,
                            touched: false,
                        }}
                        initialValues={description}
                    />

                    <View style={s.buttonWrapper}>
                        <PrimaryButton title="SAVE" onPress={this.submitDescription} />
                    </View>
                </View>
            </ImageBackground>
        );
    }
}

const mapStateToProps = ({ currentTimeCard }) => ({
    description: currentTimeCard.description,
    workOrderNumber: currentTimeCard.workOrderNumber,
    currentProject: currentTimeCard.currentProject,
    isSwitch: currentTimeCard.isSwitch,
});

const mapDispatchToProps = (dispatch) => {
    const bindActions = bindActionCreators(
        {
            setDescription,
            setWorkOrderNumber,
            setWorkOrderYear,
        },
        dispatch,
    );
    bindActions.dispatch = dispatch;
    return bindActions;
};

export default connect(mapStateToProps, mapDispatchToProps)(ClockInDescriptionScreen);
