import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import { NavigationActions } from 'react-navigation';
import moment from 'moment';

import { NavigationBar, SignModal } from '../../../components/index';

import { uploadImage } from '../../../store/helpers/common';
import { uploadFinish, uploadStart } from '../../../store/modules/dailyReports';
import { saveToolboxTalksUserSign, saveToolboxTalks } from '../../../store/modules/toolboxTalks';

import s from './styles';

class ToolboxTalkTicketDetailsScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentTicket: PropTypes.object,
        user: PropTypes.object,
        toolboxTypes: PropTypes.array,
        signedTickets: PropTypes.array,
        saveToolboxTalksUserSign: PropTypes.func,
        uploadImage: PropTypes.func,
    };

    state = {
        isModalOpen: false,
        isModalLoading: false,
    };

    _onSaveEvent = (imageBuffer) => {
        const { uploadImage, currentTicket, user, navigation, saveToolboxTalksUserSign } = this.props;
        const name = `${currentTicket.ToolBoxTalkName}_${user.fullName}`;
        const data = {
            name: `${name}`,
            type: 'image',
            fileName: `${name}.png`,
            types: ['image'],
            subtypes: [],
            tags: [
                {
                    value: 'image',
                    protected: true,
                },
            ],
        };
        this.setState({ isModalLoading: true });
        uploadImage(data, imageBuffer, 'sign').then(({ asset, error }) => {
            if (error) {
                return;
            }
            const ticket = {
                ToolBoxTalkName: currentTicket.ToolBoxTalkName,
                ToolBoxTalk: currentTicket.ToolBoxTalk,
                FieldForeman: currentTicket.FieldForeman,
                User: user._id,
                Project: currentTicket.Project,
                Sign: {
                    Image: asset.file.url,
                    ImageAssetId: asset._id,
                },
                Date: moment().toDate(),
            };
            saveToolboxTalksUserSign({ user, ticket }).then(() => {
                this.setState({ isModalOpen: false, isModalLoading: false });

                navigation.navigate(
                    'ToolboxTalk',
                    {},
                    NavigationActions.navigate({
                        routeName: 'ToolboxTalkList',
                    }),
                );
            });
        });
    };

    returnToolboxType() {
        const { toolboxTypes, currentTicket } = this.props;
        let type = {};
        toolboxTypes.forEach((t) => {
            if (t.id === currentTicket.ToolBoxTalk) {
                type = t;
            }
        });
        return type;
    }

    checkSigned() {
        const { user, signedTickets, currentTicket } = this.props;
        let isSigned = false;
        signedTickets.forEach((ticket) => {
            if (
                currentTicket.ToolBoxTalkName === ticket.ToolBoxTalkName &&
                currentTicket.Project === ticket.Project &&
                user._id === ticket.User
            ) {
                isSigned = true;
            }
        });

        return isSigned;
    }

    returnButton(isSigned) {
        const { navigation } = this.props;
        if (isSigned) {
            return (
                <TouchableOpacity
                    onPress={() => {
                        navigation.navigate(
                            'ToolboxTalk',
                            {},
                            NavigationActions.navigate({
                                routeName: 'ToolboxTalkList',
                            }),
                        );
                    }}
                >
                    <View style={s.button}>
                        <Text style={s.buttonText}>ALREADY SIGNED</Text>
                    </View>
                </TouchableOpacity>
            );
        }
        return (
            <TouchableOpacity onPress={() => this.setState({ isModalOpen: true })}>
                <View style={s.button}>
                    <Text style={s.buttonText}>SIGN OFF</Text>
                </View>
            </TouchableOpacity>
        );
    }

    render() {
        const { isModalOpen, isModalLoading } = this.state;
        const toolBoxType = this.returnToolboxType();
        const isSigned = this.checkSigned();
        return (
            <View style={s.wrapper}>
                <NavigationBar {...this.props} title="TOOLBOX TALK" backIcon />
                <ScrollView>
                    <View style={s.mainContainer}>
                        <Text style={s.titleText}>{toolBoxType.ToolBoxTalkName}</Text>
                        <Text style={s.contentText}>{toolBoxType.ToolBoxTalk}</Text>

                        {/* <TextInput
              style={s.textInputWithPlaceholder}
              placeholder="SAFETY RECOMMENDATIONS"
            />
            <TextInput
              style={s.textInputWithPlaceholder}
              placeholder="JOB SPECIFIC TOPICS"
            />
            <TextInput
              style={s.textInputWithPlaceholder}
              placeholder="M.S.D.S. REVIEWED"
            /> */}

                        {this.returnButton(isSigned)}
                        <SignModal
                            isOpen={isModalOpen}
                            onSave={this._onSaveEvent}
                            title="SIGN TOOLBOX TALK"
                            isLoading={isModalLoading}
                            onCancel={() => this.setState({ isModalOpen: false })}
                        />
                    </View>
                </ScrollView>
            </View>
        );
    }
}
const mapStateToProps = ({ account, toolboxTalks, currentTimeCard }) => ({
    user: account.user,
    users: account.users,
    toolboxTypes: toolboxTalks.types,
    currentTicket: toolboxTalks.currentTicket,
    currentProject: currentTimeCard.currentProject,
    signedTickets: toolboxTalks.signedTickets,
});

export default connect(mapStateToProps, {
    uploadFinish,
    uploadImage,
    uploadStart,
    saveToolboxTalks,
    saveToolboxTalksUserSign,
})(ToolboxTalkTicketDetailsScreen);
