import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import s from './styles';

export default class YesNoAnswer extends Component {
    static propTypes = {
        big: PropTypes.bool,
        isTwo: PropTypes.bool,
        answer: PropTypes.string,
        onChange: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            a: props.answer,
        };
    }

    render() {
        const { onChange, isTwo, big } = this.props;
        const { a } = this.state;
        return (
            <View style={[s.buttonWrapper, big && s.buttonWrapper_big, !big && isTwo && s.buttonWrapper_two]}>
                {!isTwo && (
                    <TouchableOpacity
                        style={[s.button, s.button_first, big && s.button_first_big, a === 'N/A' && s.button_active]}
                        onPress={() => {
                            onChange('N/A');
                            this.setState({ a: 'N/A' });
                        }}
                    >
                        <Text style={[s.buttonText, big && s.buttonText_big, a === 'N/A' && s.buttonText_active]}>
                            N/A
                        </Text>
                    </TouchableOpacity>
                )}
                <TouchableOpacity
                    style={[
                        s.button,
                        big && !isTwo && s.button_mid_big,
                        big && isTwo && s.button_first_big,
                        !big && isTwo && s.button_first,
                        a === 'Yes' && s.button_active,
                    ]}
                    onPress={() => {
                        onChange('Yes');
                        this.setState({ a: 'Yes' });
                    }}
                >
                    <Text style={[s.buttonText, big && s.buttonText_big, a === 'Yes' && s.buttonText_active]}>YES</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[s.button, s.button_last, big && s.button_last_big, a === 'No' && s.button_active]}
                    onPress={() => {
                        onChange('No');
                        this.setState({ a: 'No' });
                    }}
                >
                    <Text style={[s.buttonText, big && s.buttonText_big, a === 'No' && s.buttonText_active]}>NO</Text>
                </TouchableOpacity>
            </View>
        );
    }
}
