import moment from 'moment';
import React, { PureComponent } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { any } from 'prop-types';

import s from './styles';

export default class WorkLogListItem extends PureComponent {
    static propTypes = {
        item: any,
    };

    _returnTime(seconds) {
        let time = moment().startOf('day').seconds(seconds);
        const hours = moment(time).hours();
        const minutes = moment(time).minutes();
        if (hours >= 1) {
            time = `${hours}h`;
        } else if (minutes >= 1) {
            time = `${minutes}m`;
        } else {
            time = `${seconds}s`;
        }
        return time;
    }

    returnEstimatedSeconds(e) {
        let total = 0;
        const hourPosition = e.indexOf('h', 0);
        const minPosition = e.indexOf('m', 0);
        if (e.includes('m') && e.includes('h')) {
            const hoursToSec = e.substring(0, hourPosition) * 3600;
            const minToSec = e.indexOf(hourPosition, minPosition) * 60;
            total = hoursToSec + minToSec;
        } else if (!e.includes('m') && e.includes('h')) {
            const hoursToSec = e.substring(0, hourPosition) * 3600;
            total = hoursToSec;
        } else {
            const minToSec = e.indexOf(0, minPosition) * 60;
            total = minToSec;
        }
        return total;
    }

    render() {
        const { item } = this.props;
        const percent = Math.round((item.used / this.returnEstimatedSeconds(item.estimated)) * 100 * 10) / 10;

        return (
            <TouchableOpacity onPress={item.onPress} style={[s.listItemWrapper]}>
                <View style={[s.listItemRow, s.listItemRow_border]}>
                    <Text style={s.listItemTitle}>{item.title}</Text>
                </View>
                <View style={s.listItemStatusWrapper}>
                    <View
                        style={[
                            s.listItemStatusLine,
                            {
                                width: `${percent > 100 ? 100 : percent}%`,
                            },
                        ]}
                    />
                </View>
                <View style={s.listItemRow}>
                    <View style={[s.listItemColumn]}>
                        <Text style={s.listItemBottomText}>{`Estimated time: ${item.estimated}`}</Text>
                    </View>
                    <View style={s.listItemColumn}>
                        <Text style={[s.listItemBottomText, s.listItemBottomText_blue]}>
                            {`Time used: ${this._returnTime(item.used)} (${percent}%)`}
                        </Text>
                    </View>
                </View>
            </TouchableOpacity>
        );
    }
}
