import { StyleSheet, Dimensions } from 'react-native';
import { Color, Font } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        paddingHorizontal: '5%',
    },
    listItemWrapper: {
        paddingHorizontal: 15,
        paddingVertical: 10,
        minHeight: 80.5,
        backgroundColor: Color.white,
        cursor: 'pointer',
        borderRadius: 6,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
        // position: 'relative',
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        width: '100%',
    },
    listItemTitle: {
        color: Color.dark_navy_blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        width: '100%',
    },
    listItemStatusOpen: {
        color: Color.turquoise,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font11,
    },
    listItemStatusClosed: {
        color: Color.pinkish_orange,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font11,
    },
    listItemDetails: {
        color: Color.grey,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font11,
        width: '100%',
    },
    listItemStatusUnsaved: {
        color: Color.faded_orange,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font9,
    },
    listItemViewPDF: {
        color: Color.blue,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font11,
    },
    bottomButtonsBlock: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        position: 'absolute',
        top: 31,
        right: 31,
        elevation: 12,
        height: 100,
    },
    addButton: {
        width: 31,
        height: 31,
        justifyContent: 'center',
        alignItems: 'center',
        elevation: 12,
    },
    closeIcon: {
        height: 80.5,
        backgroundColor: Color.pinkish_orange,
        width: 77,
        position: 'relative',
        top: -58,
        right: 60,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        alignItems: 'center',
        paddingTop: 30,
    },
    closeText: {
        color: Color.white,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
    },
    createModal: {
        flex: 1,
        backgroundColor: Color.light_grey_blue_05,
    },
    createModalContainer: {
        width: '90%',
        left: '5%',
        top: '10%',
        backgroundColor: Color.pale_grey_two,
        borderRadius: 7.5,
        alignItems: 'center',
        paddingVertical: 20,
        padding: '5%',
    },
    dropdownContainer: {
        backgroundColor: Color.white,
        borderRadius: 6,
        height: 50,
        width: '100%',
    },
    dropdownLabel: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
    },
    dropdownLabel_item: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font15,
    },
    buttonCancel: {
        height: 50,
        flex: 0.4,
        borderRadius: 7.5,
        alignItems: 'center',
        paddingTop: 17,
    },
    button: {
        flex: 0.4,
        height: 50,
        backgroundColor: Color.turquoise,
        borderRadius: 7.5,
        alignItems: 'center',
        paddingTop: 17,
    },
    buttonText: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.white,
    },
    buttonTextCancel: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.blue,
    },
    buttonsRow: {
        marginVertical: 20,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    modal: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: Color.white,
    },
    pdfContainer: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 25,
    },
    pdfViewer: {
        flex: 1,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    },
    closeButton: {
        width: 43,
        height: 43,
        borderRadius: 22,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        backgroundColor: Color.turquoise,
        top: 0,
        right: 20,
        elevation: 12,
        zIndex: 999,
        shadowColor: Color.light_grey_blue,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.5,
        shadowRadius: 5,
    },
    downloadButton: {
        left: 20,
    },
    printButton: {
        left: 80,
    },
    swipeButtonsContainer: {
        height: '100%',
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    swipeButton: {
        height: '100%',
        width: 100,
        justifyContent: 'center',
        alignItems: 'center',
    },
    swipeButton_open: {
        backgroundColor: Color.turquoise,
        borderTopLeftRadius: 7.5,
        borderBottomLeftRadius: 7.5,
    },
    swipeButton_close: {
        backgroundColor: Color.faded_orange,
        borderTopRightRadius: 7.5,
        borderBottomRightRadius: 7.5,
    },
    swipeButtonText: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font13,
        color: Color.white,
    },
    loadingIndicator: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2000,
    },
});
