import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, SectionList } from 'react-native';
import { connect } from 'react-redux';
import { showMessage } from 'react-native-flash-message';
import { isEmpty } from 'lodash';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import NetInfo from '@react-native-community/netinfo';
import {
    getTimeAndMaterial,
    setTimeAndMaterial,
    saveTimeAndMaterial,
    saveCompleteImage,
    removeCompleteImage,
    createTimeAndMaterial,
    timeAndMaterialLoading,
    timeAndMaterialFinishLoading,
} from '../../../store/modules/timeAndMaterial';
import { selectCurrentTimeAndMaterial } from '../../../store/modules/currentTimeCard';

import { NavigationBar, SignModal } from '../../../components/index';
import ListItem from './ListItem';
import { convertTimeStringToSeconds } from '../../../utils/helpers';

import s from './styles';
import { Color } from '../../../theme/index';

import { uploadImage, resizeImage } from '../../../store/helpers/common';
import { uploadStart } from '../../../store/modules/dailyReports';
import { Config } from '../../../config';

class ItemFabricationListScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentProject: PropTypes.object,
        user: PropTypes.object,
        timeAndMaterials: PropTypes.array,
        currentUploading: PropTypes.array,
        currentClockInType: PropTypes.string,
        isFocused: PropTypes.bool,
        isSelecting: PropTypes.bool,
        isLoading: PropTypes.bool,
        userCanCreateTimeAndMaterial: PropTypes.bool,
        getTimeAndMaterial: PropTypes.func,
        setTimeAndMaterial: PropTypes.func,
        saveTimeAndMaterial: PropTypes.func,
        saveCompleteImage: PropTypes.func,
        removeCompleteImage: PropTypes.func,
        createTimeAndMaterial: PropTypes.func,
        timeAndMaterialLoading: PropTypes.func,
        timeAndMaterialFinishLoading: PropTypes.func,
        selectCurrentTimeAndMaterial: PropTypes.func,
        uploadImage: PropTypes.func,
        resizeImage: PropTypes.func,
        uploadStart: PropTypes.func,
    };

    state = {
        search: '',
        isModalOpen: '',
        currentTAM: null,
    };

    componentDidMount() {
        const { currentProject } = this.props;
        if (!isEmpty(currentProject)) {
            this.reloadMaterials();
        }
    }

    componentDidUpdate(prevProps) {
        const { currentProject, isFocused } = this.props;
        if (
            (!isEmpty(currentProject) &&
                !isEmpty(prevProps.currentProject) &&
                currentProject.id !== prevProps.currentProject.id) ||
            (!isEmpty(currentProject) && isEmpty(prevProps.currentProject))
        ) {
            this.reloadMaterials();
        }
        if (!prevProps.isFocused && isFocused && !isEmpty(currentProject)) {
            this.reloadMaterials();
        }
    }

    reloadMaterials = () => {
        const { getTimeAndMaterial, currentProject, timeAndMaterialLoading, timeAndMaterialFinishLoading } = this.props;
        timeAndMaterialLoading();
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                getTimeAndMaterial(currentProject.id).then(() => {
                    timeAndMaterialFinishLoading();
                });
            } else {
                timeAndMaterialFinishLoading();
                showMessage({
                    message: 'Connection Failure',
                    description: 'We are unable to connect. Please make sure that you have a data connection.',
                    type: 'danger',
                    duration: 5000,
                    animationDuration: 700,
                    // autoHide: false,
                    hideOnPress: true,
                    hideStatusBar: true,
                    backgroundColor: Color.red,
                });
            }
        });
    };

    returnTotalTime(estimates) {
        let time = 0;
        estimates.forEach((estimate) => {
            time += convertTimeStringToSeconds(estimate.UsedTime);
        });
        return time;
    }

    _returnItems() {
        const { timeAndMaterials, isSelecting, currentClockInType } = this.props;
        const { search } = this.state;
        const inProgressItems = [];
        const doneItems = [];
        const items = [];
        timeAndMaterials.sort((TAM1, TAM2) => {
            if (TAM1.timeCreated < TAM2.timeCreated) return 1;
            if (TAM1.timeCreated > TAM2.timeCreated) return -1;
            return 0;
        });
        timeAndMaterials.forEach((tam) => {
            if (isEmpty(search) || tam.Title.toLowerCase().includes(search.toLowerCase())) {
                const onPress = () => {
                    if (isSelecting && currentClockInType === 'O') {
                        this.selectCurrentTAM(tam);
                    } else {
                        this.setItem(tam);
                    }
                };
                const item = {
                    id: tam.id,
                    isChecked: tam.Complete.Complete,
                    date: moment(tam.timeCreated),
                    title: tam.Title,
                    userName: tam.UserName,
                    isApproved: tam.ProjectMangerApproval === 'A',
                    quantity: tam.ItemsUsed.length,
                    onPress,
                    onCheck: () => !tam.Complete.Complete && this.setState({ isModalOpen: true, currentTAM: tam.id }),
                };
                if (tam.Complete.Complete) {
                    doneItems.push(item);
                } else {
                    inProgressItems.push(item);
                }
            }
        });
        if (inProgressItems.length > 0) {
            items.push({
                title: 'In progress',
                data: inProgressItems,
            });
        }
        if (doneItems.length > 0) {
            items.push({
                title: 'Done',
                data: doneItems,
            });
        }
        return items;
    }

    setItem = (item) => {
        const { setTimeAndMaterial, navigation } = this.props;
        setTimeAndMaterial(item);
        navigation.navigate('TimeAndMaterialItem');
    };

    selectCurrentTAM = (item) => {
        const { selectCurrentTimeAndMaterial, navigation } = this.props;
        selectCurrentTimeAndMaterial(item);
        navigation.goBack();
    };

    createMaterial = () => {
        const { createTimeAndMaterial, navigation, currentProject, user } = this.props;
        createTimeAndMaterial({ currentProject, user });
        navigation.navigate('TimeAndMaterialNewItem');
    };

    _onSaveEvent = async (image) => {
        const { timeAndMaterials, user, saveTimeAndMaterial, uploadImage } = this.props;
        const { currentTAM } = this.state;
        const timeAndMaterial = currentTAM ? timeAndMaterials.find((e) => e.id === currentTAM) : null;
        if (!isEmpty(timeAndMaterial)) {
            const data = {
                name: `${timeAndMaterial.id}_time_and_material_complete_sign`,
                type: 'image/jpg',
                fileName: `${timeAndMaterial.id}_time_and_material_complete_sign.jpg`,
                types: ['image'],
                subtypes: [],
                tags: [
                    {
                        value: 'image',
                        protected: true,
                    },
                ],
            };
            const file = await resizeImage(image);
            const filetype = 'time_and_material_complete';
            uploadImage(data, file, filetype, timeAndMaterial.id).then(({ asset, error }) => {
                if (error) {
                    return;
                }
                timeAndMaterial.Complete.Complete = true;
                timeAndMaterial.Complete.UserSignature = asset.file.url;
                saveTimeAndMaterial({ timeAndMaterial, user });
                this.setState({ isModalOpen: false, currentTAM: null });
            });
        }
    };

    saveAttachments(items) {
        const { uploadImage, saveCompleteImage, timeAndMaterials } = this.props;

        const { currentTAM } = this.state;
        const currentTimeAndMaterial = currentTAM ? timeAndMaterials.find((e) => e.id === currentTAM) : null;
        if (!isEmpty(currentTimeAndMaterial)) {
            items.forEach(async (item, i) => {
                const currentLength = currentTimeAndMaterial.Description.Attachment.length;
                const data = {
                    name: `${currentTimeAndMaterial.id}_time_and_material_complete_${currentLength + i}`,
                    type: item.type,
                    fileName: `${currentTimeAndMaterial.id}_time_and_material_complete_${currentLength + i}.jpg`,
                    types: ['image'],
                    subtypes: [],
                    tags: [
                        {
                            value: 'image',
                            protected: true,
                        },
                    ],
                };

                const filetype = 'time_and_material_complete';
                uploadImage(data, item.base64, filetype, currentTimeAndMaterial.id).then(({ asset, error }) => {
                    if (!isEmpty(error)) {
                        showMessage({
                            message: error.message,
                            description: '',
                            type: 'danger',
                            icon: 'danger',
                            position: 'right',
                            hideStatusBar: true,
                            backgroundColor: Color.red,
                        });
                    } else {
                        saveCompleteImage(asset, currentTimeAndMaterial);
                    }
                });
            });
        }
    }

    render() {
        const { isModalOpen, currentTAM } = this.state;
        const {
            currentProject,
            isLoading,
            timeAndMaterials,
            currentUploading,
            uploadStart,
            removeCompleteImage,
            userCanCreateTimeAndMaterial,
        } = this.props;
        const items = this._returnItems();
        const tam = currentTAM ? timeAndMaterials.find((e) => e.id === currentTAM) : null;
        const currentAttachment = tam ? tam.Complete.Attachment : null;
        const uploadings = currentUploading.filter((u) => u.type === 'time_and_material_complete');
        const projectTitle = Config.USE_DEPARTMENTS_JOBS ? 'Department' : 'Project';
        return (
            <View style={[s.wrapper]}>
                <NavigationBar
                    {...this.props}
                    title="DASHBOARD"
                    menuIcon
                    searchIcon
                    onSearch={(val) => this.setState({ search: val })}
                />
                <View style={s.menuTopContainer}>
                    <Text style={s.topMenu_title}>
                        {isEmpty(currentProject) ? `You didn't clock in in to any ${projectTitle}` : currentProject.ProjectName}
                    </Text>
                </View>
                <View style={[s.mainContainer]}>
                    <SectionList
                        sections={items}
                        keyExtractor={(item, i) => (item ? item.id : i)}
                        refreshing={isLoading}
                        onRefresh={this.reloadMaterials}
                        style={{ width: '100%', height: '100%' }}
                        contentContainerStyle={{
                            paddingTop: 20,
                            paddingHorizontal: 20,
                            paddingBottom: 250,
                        }}
                        renderSectionHeader={({ section: { title } }) => <Text style={s.listTitle}>{title}</Text>}
                        renderItem={({ item, i }) => {
                            return <ListItem item={item} i={i} itemFabrication isLast={item.id === items.length - 1} />;
                        }}
                    />
                    {!isEmpty(currentProject) && userCanCreateTimeAndMaterial && (
                        <View style={s.bottomButtonsBlock}>
                            <TouchableOpacity style={s.addButton} onPress={this.createMaterial}>
                                <Icon name="plus" color={Color.white} size={30} />
                            </TouchableOpacity>
                        </View>
                    )}
                </View>
                <SignModal
                    isOpen={isModalOpen}
                    onSave={this._onSaveEvent}
                    isLoading={isLoading}
                    title="SIGN TIME AND MATERIAL"
                    isAttachments
                    attachments={{
                        attachments: currentAttachment,
                        uploadings,
                        saveAttachments: (items) => this.saveAttachments(items),
                        removeAttachment: (image) => removeCompleteImage(image, tam),
                        uploadStart,
                    }}
                    onCancel={() => {
                        this.setState({ isModalOpen: false });
                    }}
                />
            </View>
        );
    }
}

const mapStateToProps = ({ timeAndMaterials, dailyReports, account, currentTimeCard }) => ({
    timeAndMaterials: timeAndMaterials.timeAndMaterials,
    currentProject: dailyReports.currentProject,
    currentUploading: dailyReports.currentUploading,
    isLoading: timeAndMaterials.isLoading,
    user: account.user,
    projects: account.projects,
    isSelecting: currentTimeCard.isSelectingClockInType,
    currentClockInType: currentTimeCard.currentClockInType,
    userCanCreateTimeAndMaterial: account.user.meta.AllowedToCreateCO,
});

export default connect(mapStateToProps, {
    getTimeAndMaterial,
    setTimeAndMaterial,
    saveTimeAndMaterial,
    saveCompleteImage,
    removeCompleteImage,
    createTimeAndMaterial,
    timeAndMaterialLoading,
    timeAndMaterialFinishLoading,
    selectCurrentTimeAndMaterial,
    uploadStart,
    uploadImage,
})(ItemFabricationListScreen);
