import { StyleSheet } from 'react-native';
import { Font, Color } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        width: '100%',
    },
    categoryWrapper: {
        width: '100%',
        alignItems: 'center',
    },
    sectionHeader: {
        marginVertical: 15,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.light_navy,
        width: '90%',
    },
    row: {
        width: '90%',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    col_33: {
        width: '32.9%',
    },
    col_50: {
        width: '49.8%',
    },
    col_66: {
        width: '65%',
    },
});
