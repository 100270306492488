import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme';

export default StyleSheet.create({
    listItemWrapper: {
        width: '90%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 13,
        paddingTop: 12,
        paddingBottom: 17,
        marginBottom: 10,
        backgroundColor: Color.white,
        borderRadius: 6,
        elevation: 5,
        alignSelf: 'center',
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    listItemBody: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '100%',
    },
    listItemTitle: {
        color: Color.dark_navy_blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
    }
});
