import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, View, ScrollView } from 'react-native';
import moment from 'moment';

import { NavigationBar } from '../../../components';
import { Config } from '../../../config';

import s from './styles';

class AdminViewerTimeCardDetailsScreen extends Component {
    static propTypes = {
        adminCards: PropTypes.object,
        user: PropTypes.object,
    };

    _returnTotalTime() {
        const { adminCards } = this.props;
        let dayTotalTime = 0;
        adminCards.data.forEach((card) => {
            dayTotalTime += card.totalTime;
        });
        return <Text style={s.titleTime}>{moment().startOf('day').seconds(dayTotalTime).format('HH:mm')}</Text>;
    }

    _returnCards() {
        const { adminCards } = this.props;
        const cards = [];
        const { data } = adminCards;
        const costCodeTitle = Config.USE_DEPARTMENTS_JOBS ? 'JOB' : 'COST CODE';
        for (let i = 0; i < data.length; i++) {
            if (data[i].type === 'O') {
                cards.push(
                    <View style={s.listItemWrapper}>
                        <View style={[s.listItemTimeCardHeader, { flexDirection: 'row' }]}>
                            <Text style={s.cardTimes}>
                                {`${moment(data[i].timeStart).format('LT')} - ${moment(data[i].timeEnd).format('LT')}`}
                            </Text>
                            <Text style={s.cardTotalTime}>
                                {moment().startOf('day').seconds(data[i].dayTotalTime).format('HH:mm')}
                            </Text>
                        </View>
                        <Text style={s.title}>{data[i].title}</Text>
                        <View style={s.cardType}>
                            <Text style={s.cardTypeText}>CHANGE ORDER</Text>
                        </View>
                    </View>,
                );
            } else if (data[i].type === 'T') {
                cards.push(
                    <View style={s.listItemWrapper}>
                        <View style={[s.listItemTimeCardHeader, { flexDirection: 'row' }]}>
                            <Text style={s.cardTimes}>
                                {`${moment(data[i].timeStart).format('LT')} - ${moment(data[i].timeEnd).format('LT')}`}
                            </Text>
                            <Text style={s.cardTotalTime}>
                                {moment().startOf('day').seconds(data[i].dayTotalTime).format('HH:mm')}
                            </Text>
                        </View>
                        <Text style={s.title}>{data[i].title}</Text>
                        <View style={s.cardType}>
                            <Text style={s.cardTypeText}>TIME AND MATERIAL</Text>
                        </View>
                    </View>,
                );
            } else if (!data[i].EndTime) {
                cards.push(
                    <View style={s.listItemWrapper}>
                        <View style={[s.listItemTimeCardHeader, { flexDirection: 'row' }]}>
                            <Text style={s.cardTimes}>
                                {`${moment(data[i].StartTime).format('LT')} - ${moment().format('LT')}`}
                            </Text>
                            <Text style={s.cardTotalTime}>
                                {moment().startOf('day').seconds(adminCards.totalTime).format('HH:mm')}
                            </Text>
                        </View>
                        <Text style={s.title}>{data[i].title}</Text>
                        <View style={s.cardType}>
                            <Text style={s.cardTypeText}>{costCodeTitle}</Text>
                        </View>
                    </View>,
                );
            } else {
                cards.push(
                    <View style={s.listItemWrapper}>
                        <View style={[s.listItemTimeCardHeader, { flexDirection: 'row' }]}>
                            <Text style={s.cardTimes}>
                                {`${moment(data[i].timeStart).format('LT')} - ${moment(data[i].timeEnd).format('LT')}`}
                            </Text>
                            <Text style={s.cardTotalTime}>
                                {moment().startOf('day').seconds(data[i].dayTotalTime).format('HH:mm')}
                            </Text>
                        </View>
                        <Text style={s.title}>{data[i].title}</Text>
                        <View style={s.cardType}>
                            <Text style={s.cardTypeText}>{costCodeTitle}</Text>
                        </View>
                    </View>,
                );
            }
        }
        return cards;
    }

    render() {
        const { adminCards, user } = this.props;
        return (
            <View style={s.wrapper}>
                <NavigationBar title={`${user.name.first} ${user.name.last}`} backIcon {...this.props} />
                <ScrollView style={s.mainContainer}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={s.titleDate}>{moment(adminCards.data[0].timeStart).format('MM/DD')}</Text>
                        {this._returnTotalTime()}
                    </View>
                    {this._returnCards()}
                </ScrollView>
            </View>
        );
    }
}

const mapStateToProps = ({ timeCards }) => ({
    user: timeCards.user,
    adminCards: timeCards.adminCards,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdminViewerTimeCardDetailsScreen);
