import React, { PureComponent } from 'react';
import { Text } from 'react-native';
import { string, bool } from 'prop-types';

import s from './styles';

export default class TabBarLabel extends PureComponent {
    static propTypes = {
        labelName: string,
        focused: bool,
    };

    render() {
        const { focused, labelName } = this.props;
        return <Text style={[s.label, focused && s.labelFocused]}>{labelName}</Text>;
    }
}
