import { StyleSheet } from 'react-native';
import { Font, Color } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        width: '100%',
        paddingTop: 16,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    form: {
        width: '90%',
    },
    logo: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingTop: '10%',
        paddingBottom: '10%',
    },
    logoImg: {
        width: 150,
        height: 50,
    },
    menuTopContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    topMenu_title: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
    },
    addButton: {
        width: 43,
        height: 43,
        borderRadius: 22,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        backgroundColor: Color.light_navy,
        bottom: 160,
        left: '50%',
        marginLeft: -21.5,
        elevation: 12,
        shadowColor: Color.light_grey_blue,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.5,
        shadowRadius: 5,
    },
    buttonWrapper: {
        width: 180,
        marginBottom: 300,
        marginTop: 20,
    },
    iconWrapper: {
        width: 25,
        height: 25,
        borderRadius: 13,
        backgroundColor: Color.light_navy_10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    formItem: {
        width: '100%',
    },
    attachments: {
        width: '100%',
        height: 50,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: Color.white,
        elevation: 10,
        borderRadius: 6,
        paddingHorizontal: 20,
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    attachmentsText: {
        fontSize: Font.size.font11,
        color: Color.light_grey_blue,
        fontFamily: Font.type.regular,
    },
    attachmentsWrapper: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
    },
    attachmentItem: {
        width: 150,
        height: 150,
        marginRight: 10,
        marginBottom: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    attachmentImage: {
        height: '100%',
        width: '100%',
    },
    attachmentButton: {
        width: 30,
        height: 30,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        right: 5,
        top: 5,
        backgroundColor: Color.pale_grey,
    },
    uploadingWrapper: {
        position: 'absolute',
        width: 150,
        height: 150,
        top: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.white_50,
    },
    uploadingLabel: {
        backgroundColor: Color.white_50,
        padding: 5,
        borderRadius: 5,
    },
    offline: {
        flex: 0.33,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderRadius: 3,
        borderColor: Color.cloudy_blue,
        marginRight: 5,
        padding: 5,
    },
    offlineText: {
        textAlign: 'center',
        color: Color.cloudy_blue,
        fontSize: Font.size.font11,
    },
});
