import { View, Modal } from 'react-native';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlashMessage from 'react-native-flash-message';

import s from './styles';
import { Color } from '../../theme';

export default class Canvas extends Component {
    static propTypes = {
        image: PropTypes.object,
        visible: PropTypes.bool,
        saveImage: PropTypes.func,
        // closeImageButton: PropTypes.func,
    };

    onSave = (path) => {
        const { image, saveImage } = this.props;
        this.canvas._sketchCanvas.getBase64('jpg', false, true, false, true, (err, result) => {
            if (err) {
                this.flashMessage.showMessage({
                    message: 'Error',
                    description: 'An error occurred while saving image. Please try again.',
                    type: 'danger',
                    hideStatusBar: false,
                    duration: 3000,
                    backgroundColor: Color.red,
                });
            } else {
                const changedImage = {
                    ...image,
                    file: result,
                    path,
                };
                saveImage(changedImage);
            }
        });
    };

    render() {
        const { visible } = this.props;
        return (
            <Modal visible={visible} style={s.container}>
                <View style={s.container} />
                <FlashMessage
                    ref={(ref) => {
                        this.flashMessage = ref;
                    }}
                    position="top"
                />
            </Modal>
        );
    }
}
