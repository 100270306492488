import { StyleSheet } from 'react-native';
import { Font, Color } from '../../../theme';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        width: '100%',
        height: '100%',
        paddingTop: 20,
        paddingBottom: 100,
        paddingHorizontal: '5%',
        alignItems: 'center',
    },
    menuTopContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    topMenu_title: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
    },
    itemTitle: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
        marginBottom: 20,
    },
    itemTitleRequired: {
        color: Color.red,
    },
    itemRequiredMessage: {
        color: Color.red,
        fontSize: Font.size.font13,
        fontFamily: Font.type.regular,
    },
    itemRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    iconWrapper: {
        width: 25,
        height: 25,
        borderRadius: 13,
        backgroundColor: Color.light_navy_10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconSectionWrapper: {
        marginRight: 10,
    },
    surveyItem: {
        width: '100%',
        backgroundColor: Color.white,
        paddingHorizontal: 20,
        paddingVertical: 20,
        marginTop: 15,
        marginBottom: 10,
        elevation: 15,
        borderRadius: 6,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    attachmentsWrapper: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
    },
    attachmentItem: {
        height: 150,
        width: 150,
        marginRight: 10,
        marginBottom: 10,
    },
    attachmentImage: {
        height: '100%',
        width: '100%',
    },
    attachmentButton: {
        width: 30,
        height: 30,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        right: 5,
        top: 5,
        backgroundColor: Color.pale_grey,
    },
    uploadingWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.white_50,
    },
    uploadingLabel: {
        backgroundColor: Color.white_50,
        padding: 5,
        borderRadius: 5,
    },
    buttonWrapper: {
        width: 180,
        marginTop: 20,
        marginBottom: 50,
        alignSelf: 'center',
    },
    reportName: {
        textAlign: 'left',
        color: Color.blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font15,
    },
    sectionName: {
        textAlign: 'left',
        marginLeft: 10,
        width: '75%',
        color: Color.blue,
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
    },
    sectionNameWithAttachments: {
        width: '60%',
    },
    headerRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: 15,
    },
    requiredText: {
        color: Color.light_navy,
        fontSize: Font.size.font13,
        fontFamily: Font.type.regular,
        fontStyle: 'italic',
        textAlign: 'right',
        marginRight: '5%',
    },
    fillInFieldInput: {
        borderColor: Color.light_grey,
        borderWidth: 2,
        shadowColor: Color.transparent,
        elevation: 0,
    },
    collapseIcon: {
        color: Color.turquoise,
        paddingRight: '5%',
    },
    section: {
        backgroundColor: Color.light_navy_10,
        padding: 5,
        marginBottom: 1,
    },
    sectionActions: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});
