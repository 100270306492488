import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text, TouchableOpacity, View, BackHandler } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import { connect } from 'react-redux';

import { isEmpty } from 'lodash';
import {
    addBreak,
    changeBreak,
    changeCardEdit,
    cleanTimeCardEdit,
    removeBreak,
    saveTimeCardEdit,
} from '../../store/modules/timeCardEdit';
import {
    FieldInput,
    ListItem,
    NavigationBar,
    PrimaryButton,
    ConfirmationDialog,
    DropdownCalendar,
} from '../../components';
import { Config } from '../../config';
import { Color } from '../../theme/index';
import s from './styles';

const timeArray = [];
for (let i = 0; i < 24; i++) {
    const item = moment()
        .utcOffset(0)
        .set({
            hour: i,
            minute: 0,
            second: 0,
            millisecond: 0,
        })
        .format('HH:MM a');
    timeArray.push(item);
}
class TimeCardEditScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        timeCardEdit: PropTypes.object,
        user: PropTypes.object,
        projects: PropTypes.array,
        costCodes: PropTypes.array,
        timeCards: PropTypes.array,
        cleanTimeCardEdit: PropTypes.func,
        saveTimeCardEdit: PropTypes.func,
        changeCardEdit: PropTypes.func,
        addBreak: PropTypes.func,
        removeBreak: PropTypes.func,
        changeBreak: PropTypes.func,
        generalRules: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            confirmationDialog: false,
            confirmationText: '',
        };
    }

    componentDidMount() {
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
            this.navigationBar.goBack();
            return true;
        });
    }

    shouldComponentUpdate(nextProps) {
        if (!nextProps.timeCardEdit.isTimeCardEdit) {
            return false;
        }
        return true;
    }

    componentWillUnmount() {
        this.backHandler.remove();
    }

    returnCostCodeTitle(code) {
        const { costCodes } = this.props;
        const costCode = costCodes.find((c) => c.id === code);
        if (costCode) {
            return `${costCode.Code} ${costCode.Title}`;
        }
        return '';
    }

    returnProjectTitle(projectId, projectDetails) {
        const { projects } = this.props;
        let project = null;
        let projectNameString = null;
        if (projectDetails) {
            project = projectDetails;
        } else {
            project = projects.find((c) => c.id === projectId);
        }

        if (project) {
            const { ProjectNumber, ProjectName } = project;

            if (ProjectNumber && ProjectName) {
                projectNameString = `${ProjectNumber}-${ProjectName}`;
            }
            if (ProjectNumber) {
                projectNameString = ProjectNumber;
            }
            if (ProjectName) {
                projectNameString = ProjectName;
            }
        }

        return projectNameString;
    }

    returnClockInItemsArray() {
        const { navigation, timeCardEdit } = this.props;
        const { costCode, project, projectDetails, projectName } = timeCardEdit;
        const list = {
            project: {
                title: !isEmpty(project)
                    ? !isEmpty(projectName)
                        ? projectName
                        : this.returnProjectTitle(project, projectDetails)
                    : '',
                required: !project,
                inactiveText: isEmpty(project),
                editScreen: true,
                onPress: () => {
                    navigation.navigate('TimeCardEditProjects', { standalone: false, isTimeCardEdit: true });
                },
            },
            costCode: {
                title: !isEmpty(costCode) ? this.returnCostCodeTitle(costCode) : '',
                inactiveText: isEmpty(costCode),
                editScreen: true,
                isDisabled: isEmpty(project),
                onPress: () => {
                    navigation.navigate('TimeCardEditCostCodes', { isTimeCardEdit: true });
                },
            },
        };
        return list;
    }

    // return seconds
    returnTotalBreak() {
        const { timeCardEdit } = this.props;
        const { breaks } = timeCardEdit;
        let time = 0;
        if (!isEmpty(breaks)) {
            breaks.forEach((b) => {
                time += moment(b.EndTime).diff(moment(b.StartTime), 'seconds');
            });
        }
        return time;
    }

    returnTotalClockInTime() {
        const { timeCardEdit } = this.props;
        let time = '';
        const totalBreak = this.returnTotalBreak();
        let totalTime = null;
        if (!!timeCardEdit.clockIn && !!timeCardEdit.clockOut) {
            totalTime = moment(timeCardEdit.clockOut).diff(moment(timeCardEdit.clockIn), 'seconds') - totalBreak;
            totalTime = moment().startOf('day').add(totalTime, 'seconds');

            let totalHours = totalTime.hours();
            let totalMinutes = totalTime.minutes();

            if (totalHours < 10) {
                totalHours = `0${totalHours}`;
            }

            if (totalMinutes < 10) {
                totalMinutes = `0${totalMinutes}`;
            }

            time = `${totalHours}:${totalMinutes}`;
        }
        if (totalTime) {
            return time;
        }

        return '00:00';
    }

    returnTotalBreakTime() {
        let time = '';
        const totalBreak = this.returnTotalBreak();
        if (totalBreak === 0) {
            time = '00:00';
        } else if (totalBreak < 60) {
            time = `00:${totalBreak > 10 ? totalBreak : `0${totalBreak}`}`;
        } else {
            let hours = Math.trunc(totalBreak / 60);
            let minutes = totalBreak % 60;

            if (hours < 10) {
                hours = `0${hours}`;
            }

            if (minutes < 10) {
                minutes = `0${minutes}`;
            }

            time = `${hours}:${minutes}`;
        }
        return time;
    }

    // -- GENERAL --
    close() {
        this.setState({ confirmationDialog: false });
        this.setState({ confirmationText: '' });
    }

    onSave = () => {
        const { user, timeCardEdit, saveTimeCardEdit, navigation, timeCards } = this.props;
        const { project, projectDetails } = timeCardEdit;
        const totalTime = this.returnTotalClockInTime();
        let userError = false;

        const projectLabel = Config.USE_DEPARTMENTS_JOBS ? 'department' : 'project';

        // validate date
        if (!timeCardEdit.date) {
            this.setState({
                confirmationDialog: true,
                confirmationText: 'Please enter time card date',
            });
            userError = true;
        }

        // validate project
        if (!userError && !timeCardEdit.project) {
            this.setState({
                confirmationDialog: true,
                confirmationText: `Please enter time card ${projectLabel}`,
            });
            userError = true;
        }

        // validate clock in time
        if (!userError && !timeCardEdit.clockIn) {
            this.setState({
                confirmationDialog: true,
                confirmationText: 'Please enter time card clock in time',
            });
            userError = true;
        }

        // validate clock out time
        if (!userError && !timeCardEdit.clockOut) {
            this.setState({
                confirmationDialog: true,
                confirmationText: 'Please enter time card clock out time',
            });
            userError = true;
        }

        if (!userError && (timeCardEdit.note.length <= 4 || timeCardEdit.note.trim().length === 0)) {
            this.setState({
                confirmationDialog: true,
                confirmationText:
                    "Please enter a reason why in the comment box. It must be greater than 5 characters and can't be spaces",
            });
            userError = true;
        }

        const editTimeCardStartTime = moment(timeCardEdit.clockIn).toISOString();
        const editTimeCardEndTime = moment(timeCardEdit.clockOut).toISOString();
        if (!userError) {
            // validate break times
            for (let i = 0; i < timeCardEdit.breaks.length; i++) {
                const editTimeCardStartBreakTime = moment(timeCardEdit.breaks[i].StartTime).toISOString();
                const editTimeCardEndBreakTime = moment(timeCardEdit.breaks[i].EndTime).toISOString();
                // break time is within start and end time
                if (
                    !moment(editTimeCardStartBreakTime).isBetween(editTimeCardStartTime, editTimeCardEndTime) ||
                    !moment(editTimeCardEndBreakTime).isBetween(editTimeCardStartTime, editTimeCardEndTime)
                ) {
                    this.setState({
                        confirmationDialog: true,
                        confirmationText: 'One of your break time(s) is not within your clock in and clock out time',
                    });
                    userError = true;
                    break;
                }
                if (editTimeCardStartBreakTime > editTimeCardEndBreakTime) {
                    this.setState({
                        confirmationDialog: true,
                        confirmationText: 'Please enter an end break time that is after the start break time.',
                    });
                    userError = true;
                }
                if (editTimeCardStartBreakTime === editTimeCardEndBreakTime) {
                    this.setState({
                        confirmationDialog: true,
                        confirmationText: 'Please make sure your break time(s) are more than at least a minute apart.',
                    });
                    userError = true;
                }
            }
        }

        if (!userError) {
            for (const timeCard of timeCards) {
                const currentTimeCardStartTime = moment(timeCard.StartTime).toISOString();
                const currentTimeCardEndTime = moment(timeCard.EndTime).toISOString();
                // validate that we do not have a overlap in the time cards.
                if (
                    timeCardEdit.timeCardDayId !== timeCard.WorkTimeId &&
                    (editTimeCardStartTime === currentTimeCardStartTime ||
                        editTimeCardEndTime === currentTimeCardEndTime ||
                        moment(editTimeCardStartTime).isBetween(currentTimeCardStartTime, currentTimeCardEndTime) ||
                        moment(editTimeCardEndTime).isBetween(currentTimeCardStartTime, currentTimeCardEndTime))
                ) {
                    this.setState({
                        confirmationDialog: true,
                        confirmationText:
                            'You have selected a time range that conflicts with another time card. Please try again.',
                    });
                    userError = true;
                    break;
                }
                if (editTimeCardStartTime > editTimeCardEndTime) {
                    this.setState({
                        confirmationDialog: true,
                        confirmationText: 'Please enter an end time that is after the start time.',
                    });
                    userError = true;
                    break;
                }
            }
        }
        if (!userError) {
            const projectNameString = this.returnProjectTitle(project, projectDetails);
            saveTimeCardEdit(user, timeCardEdit, totalTime, projectNameString).then((resp) => {
                if (resp) {
                    navigation.navigate('TimeCards');
                }
            });
        }
    };

    onBack = () => {
        const { navigation, cleanTimeCardEdit } = this.props;
        cleanTimeCardEdit();
        navigation.navigate('TimeCards');
    };

    render() {
        const { timeCardEdit, changeCardEdit, addBreak, removeBreak, changeBreak, generalRules } = this.props;
        const { isLoading, note, clockIn, clockOut, date, workOrder, id, breaks } = timeCardEdit;
        const { confirmationDialog, confirmationText } = this.state;

        const items = this.returnClockInItemsArray();

        const backConfirmText =
            // eslint-disable-next-line max-len
            'You are about to back out of your time card editing. All not saved data will be lost. Are you sure you want to do this?';
        const backTitle = 'Warning';
        const totalClockInTime = this.returnTotalClockInTime();
        const totalBreakTime = this.returnTotalBreakTime();

        const projectLabel = Config.USE_DEPARTMENTS_JOBS ? 'DEPARTMENT' : 'PROJECT';
        const costCodeLabel = Config.USE_DEPARTMENTS_JOBS ? 'JOB' : 'COST CODE';

        return (
            <View style={[s.wrapper]}>
                <NavigationBar
                    ref={(c) => {
                        this.navigationBar = c;
                    }}
                    {...this.props}
                    title={id ? 'EDIT TIME CARD' : 'ADD TIME CARD'}
                    backIcon
                    backConfirm={{
                        title: backTitle,
                        text: backConfirmText,
                    }}
                    backIconFunction={this.onBack}
                />
                <View style={s.mainContainer}>
                    <View style={s.dropdownCalendarWrapper}>
                        <DropdownCalendar
                            containerStyle={[s.materialDropdown]}
                            date={date}
                            onSelect={(date) => changeCardEdit({ date })}
                            placeholder="DATE"
                            height={89}
                            topLabel
                            mode="date"
                        />
                    </View>
                    <ListItem item={items.project} label={projectLabel} />
                    <ListItem item={items.costCode} label={costCodeLabel} />
                    <FieldInput
                        title="OPTIONAL WORK ORDER #"
                        input={{
                            onChange: (text) => {
                                changeCardEdit({ workOrder: text });
                            },
                        }}
                        meta={{
                            error: null,
                            touched: false,
                        }}
                        initialValues={workOrder}
                    />
                    {timeCardEdit.date ? (
                        <>
                            <View style={s.timesRow}>
                                <View style={s.materialDropdown_half}>
                                    <DropdownCalendar
                                        containerStyle={[s.materialDropdown]}
                                        date={!!date && moment(clockIn).isValid() ? moment(clockIn).toDate() : null}
                                        onSelect={(d) => {
                                            let clockIn = moment(d);
                                            clockIn = moment(date).set({
                                                hour: clockIn.hours(),
                                                minute: clockIn.minutes(),
                                            });
                                            clockIn = clockIn.second(0).toISOString();
                                            changeCardEdit({ clockIn });
                                        }}
                                        placeholder={
                                            clockIn
                                                ? `CLOCK IN TIME ${moment(clockIn).format('MM/DD/YY')}`
                                                : 'CLOCK IN TIME'
                                        }
                                        height={89}
                                        mode="time"
                                        type="3"
                                        range={{
                                            from: clockIn,
                                            to: clockOut,
                                        }}
                                        topLabel
                                    />
                                </View>

                                <View style={s.materialDropdown_half}>
                                    <DropdownCalendar
                                        containerStyle={[s.materialDropdown]}
                                        date={
                                            !!clockOut && moment(clockOut).isValid() ? moment(clockOut).toDate() : null
                                        }
                                        onSelect={(d) => {
                                            let clockOut = moment(d);
                                            clockOut = moment(date).set({
                                                hour: clockOut.hours(),
                                                minute: clockOut.minutes(),
                                            });
                                            clockOut = clockOut.second(0).toISOString();
                                            changeCardEdit({ clockOut });
                                        }}
                                        placeholder={
                                            clockOut
                                                ? `CLOCK OUT TIME ${moment(clockOut).format('MM/DD/YY')}`
                                                : 'CLOCK OUT TIME'
                                        }
                                        height={89}
                                        mode="time"
                                        type="3"
                                        range={{
                                            from: clockIn,
                                            to: clockOut,
                                        }}
                                        topLabel
                                    />
                                </View>
                            </View>
                            <View style={s.rangeDaysContainer}>
                                <View style={s.dateRow}>
                                    <Text style={s.dateRowTitle}>BREAKS</Text>
                                    <Text style={s.dateRowHours}>{totalBreakTime}</Text>
                                </View>
                                {!isEmpty(breaks) &&
                                    breaks.map((b, i) => {
                                        return (
                                            <View style={[s.timesRow, { zIndex: breaks.length + 1 - i }]} key={i}>
                                                <View style={s.breaksRow}>
                                                    <View style={s.materialDropdown_half}>
                                                        <DropdownCalendar
                                                            containerStyle={[s.fieldInternalStyle]}
                                                            date={moment(b.StartTime).toDate()}
                                                            onSelect={(d) => {
                                                                let time = moment(d);
                                                                time = moment(date).set({
                                                                    hour: time.hours(),
                                                                    minute: time.minutes(),
                                                                });
                                                                changeBreak({
                                                                    time,
                                                                    breaks,
                                                                    index: i,
                                                                    type: 1,
                                                                });
                                                            }}
                                                            placeholder={
                                                                b.StartTime
                                                                    ? `START TIME ${moment(b.StartTime).format(
                                                                          'MM/DD/YY',
                                                                      )}`
                                                                    : 'START TIME'
                                                            }
                                                            type="3"
                                                            height={89}
                                                            mode="time"
                                                            topLabel
                                                        />
                                                    </View>
                                                    <View style={s.materialDropdown_half}>
                                                        <DropdownCalendar
                                                            containerStyle={[s.fieldInternalStyle]}
                                                            date={moment(b.EndTime).toDate()}
                                                            onSelect={(d) => {
                                                                let time = moment(d);
                                                                time = moment(date).set({
                                                                    hour: time.hours(),
                                                                    minute: time.minutes(),
                                                                });
                                                                changeBreak({
                                                                    time,
                                                                    breaks,
                                                                    index: i,
                                                                    type: 2,
                                                                });
                                                            }}
                                                            placeholder={
                                                                b.EndTime
                                                                    ? `END TIME ${moment(b.EndTime).format('MM/DD/YY')}`
                                                                    : 'END TIME'
                                                            }
                                                            height={89}
                                                            type="3"
                                                            mode="time"
                                                            topLabel
                                                        />
                                                    </View>
                                                </View>
                                                <TouchableOpacity
                                                    onPress={() => removeBreak({ index: i, breaks })}
                                                    style={s.breakDelete}
                                                >
                                                    <Icon name="delete-circle" size={30} color={Color.pinkish_orange} />
                                                </TouchableOpacity>
                                            </View>
                                        );
                                    })}

                                <View style={s.timeRow}>
                                    <TouchableOpacity
                                        onPress={() => addBreak({ breaks, date: timeCardEdit.date })}
                                        style={{ alignSelf: 'center' }}
                                    >
                                        <Icon name="plus-circle" color={Color.turquoise} size={40} />
                                    </TouchableOpacity>
                                </View>
                            </View>
                            <View style={s.dateRow}>
                                <Text style={s.dateRowHours}>{`TOTAL CLOCK IN TIME: ${totalClockInTime}`}</Text>
                            </View>
                        </>
                    ) : null}
                    <FieldInput
                        title="COMMENT"
                        multiline
                        input={{
                            onChange: (text) => {
                                changeCardEdit({ note: text });
                            },
                        }}
                        meta={{
                            error: null,
                            touched: false,
                        }}
                        initialValues={note}
                    />
                    <Text style={s.textAttestation}>
                        I hereby attest that the time card submitted accurately reflects the hours worked during the
                        specified period. I have reviewed the records and confirm that they align with the work
                        performed during that time frame.
                    </Text>
                    {generalRules && generalRules.showAttestation && generalRules.attestationText ? (
                        <Text style={s.textAttestation}>{generalRules.attestationText}</Text>
                    ) : null}
                    <View style={s.buttonWrapper}>
                        <PrimaryButton title="SAVE" isLoading={isLoading} onPress={this.onSave} />
                    </View>
                </View>
                <ConfirmationDialog
                    isOpen={confirmationDialog}
                    onPress={() => this.close()}
                    confirmLabel="OK"
                    text={confirmationText}
                />
            </View>
        );
    }
}

const mapStateToProps = ({ timeCards, account, timeCardEdit }) => ({
    timeCards: timeCards.timeCards,
    user: account.user,
    projects: account.projects,
    costCodes: account.costCodes,
    timeCardEdit,
    generalRules: account.settings.generalRules,
});

export default connect(mapStateToProps, {
    saveTimeCardEdit,
    cleanTimeCardEdit,
    changeCardEdit,
    addBreak,
    removeBreak,
    changeBreak,
})(TimeCardEditScreen);
