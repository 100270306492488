import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, TouchableWithoutFeedback, Modal, FlatList } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { isEmpty } from 'lodash';
import s from './styles';
import { Color } from '../../theme';

export default class DropdownList extends Component {
    static propTypes = {
        items: PropTypes.array,
        title: PropTypes.string,
        dropStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        selectedItem: PropTypes.object,
        containerStyle: PropTypes.object,
        disabled: PropTypes.bool,
        placeholder: PropTypes.string,
        withIds: PropTypes.bool,
        topLabel: PropTypes.bool,
        onItemSelect: PropTypes.func,
    };

    state = {
        isDropdownOpen: false,
    };

    render() {
        const { isDropdownOpen } = this.state;
        const {
            items,
            selectedItem,
            onItemSelect,
            placeholder,
            containerStyle,
            withIds,
            topLabel,
            title,
            dropStyle,
            disabled = false,
        } = this.props;
        const label = !isEmpty(selectedItem) ? (withIds ? selectedItem.title : selectedItem) : placeholder || '';
        const isTopLabel = !isEmpty(selectedItem) && topLabel;
        const inputStyles = [s.touchableRow]
        if (dropStyle && Array.isArray(dropStyle)) {
            inputStyles.push(...dropStyle)
        } else if (dropStyle) {
            inputStyles.push(dropStyle)
        }
        return (
            <View
                style={[containerStyle && containerStyle]}
                ref={(ref) => {
                    this.container = ref;
                }}
            >
                {isTopLabel && <Text style={s.topLabel}>{placeholder}</Text>}
                <TouchableOpacity
                    disabled={disabled}
                    onPress={() => {
                        this.setState({ isDropdownOpen: true });
                    }}
                >
                    <View style={inputStyles}>
                        {title && (
                            <Text style={s.fieldTitle}>{title}</Text>
                        )}
                        <Text style={[s.dropdownLabel, isTopLabel && s.dropdownLabel_item]}>{label}</Text>
                        <Icon name="chevron-down" size={25} color={Color.turquoise} />
                    </View>
                </TouchableOpacity>
                <Modal visible={isDropdownOpen} transparent>
                    <TouchableWithoutFeedback onPress={() => this.setState({ isDropdownOpen: false })}>
                        <View style={s.dropdownWrapper}>
                            <FlatList
                                data={items}
                                contentContainerStyle={[s.dropdownContent]}
                                renderItem={(item, i) => {
                                    return (
                                        <TouchableOpacity
                                            onPress={() => {
                                                onItemSelect(item.item);
                                                this.setState({
                                                    isDropdownOpen: false,
                                                });
                                            }}
                                            style={s.dropDownItem}
                                            key={i}
                                        >
                                            <Text style={s.dropDownItemText}>
                                                {withIds ? item.item.title : item.item}
                                            </Text>
                                        </TouchableOpacity>
                                    );
                                }}
                            />
                        </View>
                    </TouchableWithoutFeedback>
                </Modal>
            </View>
        );
    }
}
