import { StyleSheet, Dimensions } from 'react-native';
import { Font, Color } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        paddingTop: 30,
        height: '100%',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    listItemWrapper: {
        backgroundColor: Color.pale_grey_two,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
    menuContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        justifyContent: 'center',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        paddingHorizontal: 15,
    },
    menuItem: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    menuitemText: {
        color: '#8b9198',
        fontFamily: Font.type.bold,
        fontSize: Font.size.font11,
        marginHorizontal: 25,
        position: 'relative',
        textTransform: 'uppercase',
    },
    menuActiveItemSlash: {
        width: 20,
        height: 1.5,
        backgroundColor: Color.cobalt,
        position: 'absolute',
        bottom: 0,
        left: '50%',
        marginLeft: -10,
    },
    logo: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingTop: '10%',
        paddingBottom: '10%',
    },
    logoImg: {
        width: 150,
        height: 50,
    },
    form: {
        height: '70%',
    },
    bottomButtonsBlock: {
        backgroundColor: Color.pale_grey_two,
        alignItems: 'center',
        paddingVertical: 15,
    },
    addButton: {
        width: 43,
        height: 43,
        borderRadius: 22,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.turquoise,
        elevation: 5,
    },
    weatherButton: {
        width: 120,
        height: 18,
        opacity: 0.25,
        marginTop: 10,
    },
    weatherButtonImage: {
        width: 120,
        height: 18,
    },
    closeButton: {
        width: 43,
        height: 43,
        borderRadius: 22,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        backgroundColor: Color.blue,
        top: 0,
        right: 20,
        elevation: 12,
        zIndex: 999,
    },
    pdfContainer: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 25,
    },
    pdfViewer: {
        flex: 1,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    },
    listTitle: {
        color: Color.blue,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font12,
        marginBottom: 12.5,
        marginTop: 4,
        paddingLeft: '5%',
    },
});
