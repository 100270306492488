import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import s from './styles';

const ListItem = ({ item }) => {
    return (
        <TouchableOpacity onPress={() => item.onPress()} style={[s.listItemWrapper]}>
            <Text numberOfLines={1} style={s.listItemTitle}>
                {item.title}
            </Text>
            <View style={s.listItemInfo}>
                <View style={s.listItemInfoItem}>
                    <Icon
                        name={item.type === 1 ? 'layers' : item.type === 2 ? 'clock' : 'calendar'}
                        style={s.listItemInfoIcon}
                    />
                    <Text style={s.listItemInfoData}>
                        {item.type === 1 ? `${item.quantity}` : item.type === 2 ? item.timeUsed : item.date}
                    </Text>
                </View>
            </View>
        </TouchableOpacity>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
};

export default ListItem;
