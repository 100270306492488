import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    mainContainer: {
        height: '100%',
        paddingTop: 20,
        alignItems: 'center',
        paddingBottom: 20,
    },
    timer: {
        fontSize: Font.size.font46,
        color: Color.turquoise,
        fontWeight: '600',
        fontFamily: Font.type.regular,
    },
    timerGrey: {
        fontSize: Font.size.font18,
        color: Color.grey,
        fontFamily: Font.type.regular,
        alignSelf: 'center',
    },
    buttonWrapper: {
        marginTop: 20,
    },
    currentProjectWrapper: {
        alignItems: 'center',
        marginTop: '2%',
        width: '100%',
    },
    currentProjectLabel: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font10,
        color: Color.light_grey_blue,
    },
    currentProjectName: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue,
        marginHorizontal: 10,
    },
    currentProjectChevron: {
        position: 'absolute',
        right: -10,
        top: 5,
        fontSize: Font.size.font26,
        color: Color.turquoise,
    },
});
