import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    modalWrapper: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.dark_navy_blue_50,
    },
    attachmentModal: {
        width: '80%',
        backgroundColor: Color.white,
        borderRadius: 6,
        padding: 20,
    },
    attachmentModalTitle: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font20,
        color: Color.blue,
        marginBottom: 20,
        textAlign: 'center',
    },
    attachmentModalText: {
        fontSize: Font.size.font18,
        fontFamily: Font.type.light,
        color: Color.dark_navy_blue,
        marginBottom: 20,
        textAlign: 'center',
    },
    attachmentItem: {
        width: '100%',
        height: 50,
        paddingHorizontal: 22.5,
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: Color.pale_grey_two,
    },
    attachmentPlusIconWrapper: {
        backgroundColor: Color.white,
        borderRadius: 6,
        width: 12,
        height: 12,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        right: 0,
        bottom: 0,
    },
    attachmentTitle: {
        fontFamily: Font.type.regular,
        color: Color.light_navy,
        fontSize: Font.size.font12,
        marginLeft: 15,
        width: '100%',
    },
    cancelModalButton: {
        width: '100%',
        paddingVertical: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
