import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    primaryButtonForm: {
        width: '100%',
        padding: 10,
        backgroundColor: Color.blue,
        borderRadius: 10,
        height: 50,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        elevation: 5,
        shadowColor: Color.grey,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.5,
        shadowRadius: 5,
    },
    primaryButtonForm_disabled: {
        backgroundColor: Color.pale_grey,
        color: Color.white,
    },
    primaryButtonForm_break: {
        backgroundColor: Color.faded_orange,
        borderColor: Color.faded_orange,
    },
    primaryButtonForm_SaveForLaterButton: {
        backgroundColor: Color.turquoise,
        borderColor: Color.turquoise,
    },
    primaryButtonForm_red: {
        backgroundColor: Color.red,
        borderColor: Color.red,
    },
    primaryButtonForm_orange: {
        backgroundColor: Color.pinkish_orange,
        borderColor: Color.pinkish_orange,
    },
    primaryButtonForm_white: {
        backgroundColor: Color.white,
        borderColor: Color.white,
    },
    primaryButtonForm_outline: {
        backgroundColor: 'transparent',
        borderColor: Color.blue,
        borderWidth: 1,
        elevation: 0,
    },
    primaryButtonForm_attention: {
        backgroundColor: Color.pinkish_orange,
        borderColor: Color.pinkish_orange,
    },
    primaryButtonForm_outline_break: {
        backgroundColor: 'transparent',
        borderColor: Color.faded_orange,
        borderWidth: 1,
        elevation: 0,
    },
    primaryButtonForm_md: {
        height: 50,
        marginBottom: 15,
        borderRadius: 6,
    },
    primaryButtonTextForm: {
        fontSize: Font.size.font14,
        color: Color.white,
        fontFamily: Font.type.bold,
        textTransform: 'uppercase',
    },
    primaryButtonTextForm_md: {
        fontSize: Font.size.font13,
    },
    primaryButtonTextForm_outline: {
        color: Color.blue,
    },
    primaryButtonTextForm_break: {
        color: Color.faded_orange,
    },
    primaryButtonTextForm_white: {
        color: Color.blue,
    },
    primaryButtonIcon: {
        color: Color.white,
        marginLeft: 20,
    },
    primaryButtonIcon_outline: {
        color: Color.light_navy,
    },
    primaryButtonIcon_break: {
        color: Color.faded_orange,
    },
    primaryButtonIcon_white: {
        color: Color.blue,
    },
    indicator: {
        marginLeft: 10,
    },
});
