import React from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import s from './styles';

import { getStatusText } from '../../../../utils/helpers';

const TimeOffRequestItem = (props) => {
    const { timeOffRequest } = props;

    const shiftTimeOffStatusText = getStatusText(timeOffRequest.Status);

    return (
        <View style={s.listItemWrapper}>
            <View style={{ width: '100%' }}>
                <View style={s.listItemRow}>
                    <View style={s.flexRow}>
                        <View style={s.upcomingBox}>
                            <Text style={s.listItemDayUpcoming}>{timeOffRequest.WeekDay}</Text>
                            <Text style={s.listItemDayNum}>{timeOffRequest.Day}</Text>
                        </View>
                        <View style={s.listItemColumn}>
                            <Text style={s.listItemProject}>Time Off</Text>
                            <View style={s.listItemTimeBlock}>
                                <Icon name="clock-outline" size={16} color="#8b9198" />
                                <Text style={[s.listItemTitle, s.listItemTime]}>{timeOffRequest.TimeRange}</Text>
                            </View>
                            <Text
                                style={[
                                    s.listItemTitle,
                                    s.statusText,
                                    timeOffRequest.Status === 'A' && s.statusTextApproved,
                                    timeOffRequest.Status === 'D' && s.statusTextDenied,
                                    timeOffRequest.Status === 'U' && s.statusTextPending,
                                ]}
                            >
                                {shiftTimeOffStatusText}
                            </Text>
                        </View>
                    </View>
                    <View>
                        <Text style={s.listItemHours}>{timeOffRequest.Hours}</Text>
                    </View>
                </View>
            </View>
        </View>
    );
};

TimeOffRequestItem.propTypes = {
    timeOffRequest: PropTypes.object,
};

export default TimeOffRequestItem;
