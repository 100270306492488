import { cloneDeep, isNil } from 'lodash';
import moment from 'moment';
import CNST from '../constants';
import { generateId, saveItemToStorage } from '../../utils/helpers';
import { clearLastTimeCard, createTimeCardData } from './timeCards';
import { Config } from '../../config';

function saveCard(data = {}) {
    return (dispatch, getStore) => {
        const { timecards, resultFunc } = data;

        return new Promise(async (resolve) => {
            // save work time data as soon as possible, before getting location
            const currentTimeCards = timecards.map((t) => cloneDeep(t));
            dispatch(
                createTimeCardData({
                    currentTimeCards,
                }),
            );

            // force to store unsaved cards to device storage
            const store = getStore();
            await saveItemToStorage('timeCards', 'unsavedCards', store.timeCards.unsavedCards);

            if (resultFunc) {
                await resultFunc(currentTimeCards);
            }
            resolve();
        });
    };
}

function getUpdatedStateClockInSuccess(isSwitch, isLoading, timecard, state) {
    const updatedState = {
        ...timecard,
        isLoading,
    };

    if (isSwitch) {
        updatedState.isSwitch = false;
        updatedState.switchCard = null;
    }

    return {
        ...state,
        ...updatedState,
    };
}

function getUpdatedStateClockOutSuccess(state) {
    return {
        ...state,
        ...{
            currentProject: null,
            currentCostCode: null,
            currentTAM: null,
            workOrderNumber: '',
            workOrderYear: '',
            isClockIn: false,
            isTimeCard: false,
            clockIn: [],
            clockOut: [],
            breakIn: [],
            breakOut: [],
            location: [],
            currentClockInType: '',
            isSelectingClockInType: false,
            isSwitch: false,
            WorkTimeId: null,
            isLoading: false,
        },
    };
}

function getUpdatedStateBreakInSuccess(timecard, isBreakInLoading, state) {
    return {
        ...state,
        ...timecard,
        isBreakInLoading,
    };
}

function getUpdatedStateBreakOutSuccess(timecard, isBreakOutLoading, state) {
    return {
        ...state,
        ...timecard,
        isBreakOutLoading,
    };
}

// ------------------------------------
// Actions
// ------------------------------------

export function setCurrentProject(data = {}) {
    const { project } = data;
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.SET_CURRENT_PROJECT,
            project,
        });
    };
}

export function setCurrentCostCode(data = {}) {
    const { code } = data;
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.SET_CURRENT_COST_CODE,
            code,
        });
    };
}

export function clockIn(data = {}) {
    const { currentTimeCard } = data;
    return async (dispatch, getState) => {
        const timecards = [];
        const timecard = {
            ...currentTimeCard,
            ...{
                WorkTimeId: generateId(),
                clockIn: [moment().toISOString()],
                isClockIn: true,
                isTimeCard: true,
                haveClockOutTime: false,
            },
        };
        timecards.push(timecard);
        dispatch({
            type: CNST.CURRENT_TIME_CARD.CLOCK_IN.LOADING,
        });

        // force save current time card redux state after clock in as soon as possible
        await saveItemToStorage(
            'currentTimeCard',
            null,
            getUpdatedStateClockInSuccess(false, false, timecard, getState().currentTimeCard),
        );

        return dispatch(
            saveCard({
                timecards,
                resultFunc: async (cards) => {
                    await saveItemToStorage(
                        'currentTimeCard',
                        null,
                        getUpdatedStateClockInSuccess(false, false, cards[0], getState().currentTimeCard),
                    );
                    dispatch({
                        type: CNST.CURRENT_TIME_CARD.CLOCK_IN.SUCCESS,
                        timecard: cards[0],
                    });
                },
            }),
        );
    };
}

export function saveTimeCardProject(data = {}) {
    const { currentTimeCard } = data;
    return async (dispatch, getState) => {
        const timecards = [currentTimeCard];
        dispatch({
            type: CNST.CURRENT_TIME_CARD.CLOCK_IN.LOADING,
        });

        await saveItemToStorage(
            'currentTimeCard',
            null,
            getUpdatedStateClockInSuccess(false, false, currentTimeCard, getState().currentTimeCard),
        );

        return dispatch(
            saveCard({
                timecards,
                resultFunc: async (cards) => {
                    await saveItemToStorage(
                        'currentTimeCard',
                        null,
                        getUpdatedStateClockInSuccess(false, false, cards[0], getState().currentTimeCard),
                    );
                    dispatch({
                        type: CNST.CURRENT_TIME_CARD.CLOCK_IN.SUCCESS,
                        timecard: cards[0],
                    });
                },
            }),
        );
    };
}

export function cleanUpTimeCard() {
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.CLOCK_OUT.SUCCESS,
        });
    };
}

export function clockOut(data = {}) {
    const { currentTimeCard } = data;
    return async (dispatch, getState) => {
        const timecard = {
            ...currentTimeCard,
            ...{
                clockOut: [...currentTimeCard.clockOut, moment().toISOString()],
                haveClockOutTime: true,
            },
        };

        dispatch({
            type: CNST.CURRENT_TIME_CARD.CLOCK_OUT.LOADING,
        });

        // hack... force save next state to storage before user close the app after successful clock out
        await saveItemToStorage('currentTimeCard', null, getUpdatedStateClockOutSuccess(getState().currentTimeCard));
        return dispatch(
            saveCard({
                timecards: [timecard],
                resultFunc: () => {
                    dispatch(clearLastTimeCard());
                    dispatch({
                        type: CNST.CURRENT_TIME_CARD.CLOCK_OUT.SUCCESS,
                    });
                },
            }),
        );
    };
}

export function clearClockOutError() {
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.CLOCK_OUT.SUCCESS,
        });
        return dispatch(clearLastTimeCard());
    };
}

export function adminClockOut() {
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.CLOCK_OUT.SUCCESS,
        });
    };
}

export function breakIn(data = {}) {
    const { currentTimeCard } = data;
    return async (dispatch, getState) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.BREAK_IN.LOADING,
        });
        const timecard = {
            ...currentTimeCard,
            ...{
                clockOut: [...currentTimeCard.clockOut, moment().toISOString()],
                breakIn: [...currentTimeCard.breakIn, moment().toISOString()],
                isClockIn: false,
            },
        };

        let updatedState = getUpdatedStateBreakInSuccess(timecard, false, getState().currentTimeCard);

        await saveItemToStorage('currentTimeCard', null, updatedState);

        return new Promise((resolve) => {
            dispatch(
                saveCard({
                    timecards: [timecard],
                    resultFunc: async (c) => {
                        updatedState = getUpdatedStateBreakInSuccess(c[0], false, getState().currentTimeCard);
                        await saveItemToStorage('currentTimeCard', null, updatedState);
                        dispatch({
                            type: CNST.CURRENT_TIME_CARD.BREAK_IN.SUCCESS,
                            timecard: c[0],
                        });
                        resolve();
                    },
                }),
            );
        });
    };
}

export function breakOut(data = {}) {
    const { currentTimeCard } = data;
    return async (dispatch, getState) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.BREAK_OUT.LOADING,
        });
        const timecard = {
            ...currentTimeCard,
            ...{
                breakOut: [...currentTimeCard.breakOut, moment().toISOString()],
                clockIn: [...currentTimeCard.clockIn, moment().toISOString()],
                isClockIn: true,
            },
        };

        await saveItemToStorage(
            'currentTimeCard',
            null,
            getUpdatedStateBreakOutSuccess(timecard, false, getState().currentTimeCard),
        );

        return new Promise((resolve) => {
            dispatch(
                saveCard({
                    timecards: [timecard],
                    resultFunc: async (c) => {
                        await saveItemToStorage(
                            'currentTimeCard',
                            null,
                            getUpdatedStateBreakOutSuccess(c[0], false, getState().currentTimeCard),
                        );
                        dispatch({
                            type: CNST.CURRENT_TIME_CARD.BREAK_OUT.SUCCESS,
                            timecard: c[0],
                        });
                        resolve();
                    },
                }),
            );
        });
    };
}

export function addLocation(lat, long) {
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.ADD_LOCATION,
            item: {
                TimeStamp: moment().toISOString(),
                Lat: lat,
                Long: long,
            },
        });
    };
}

export function setDescription(data = {}) {
    const { description } = data;
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.SET_DESCRIPTION,
            description,
        });
    };
}

export function setWorkOrderNumber(data = {}) {
    const { workOrderNumber } = data;
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.SET_WORK_ORDER_NUMBER,
            workOrderNumber,
        });
    };
}

export function setWorkOrderYear(data = {}) {
    const { workOrderYear } = data;
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.SET_WORK_ORDER_YEAR,
            workOrderYear,
        });
    };
}

export function setCurrentClockInType(type) {
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.SET_CLOCK_IN_TYPE,
            clockInType: type,
        });
    };
}

export function selectCurrentTimeAndMaterial(item) {
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.SET_CURRENT_TAM,
            item,
        });
    };
}

export function setSwitch(isSwitch, data) {
    const switchCard = cloneDeep(data);
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.SET_SWITCH,
            isSwitch,
            data: switchCard,
        });
    };
}

export function switchCards(switchCard, newCard) {
    return async (dispatch, getState) => {
        const clockOutCard = {
            ...switchCard.currentTimeCard,
            ...{
                clockOut: [...switchCard.currentTimeCard.clockOut, moment().toISOString()],
                haveClockOutTime: true,
            },
        };
        const clockInCard = {
            ...newCard.currentTimeCard,
            ...{
                WorkTimeId: generateId(),
                clockIn: [moment().toISOString()],
                isClockIn: true,
                isTimeCard: true,
                haveClockOutTime: false,
                clockOut: [],
                breakIn: [],
                breakOut: [],
                location: [],
            },
        };
        dispatch({
            type: CNST.CURRENT_TIME_CARD.CLOCK_IN.LOADING,
        });

        await saveItemToStorage(
            'currentTimeCard',
            null,
            getUpdatedStateClockInSuccess(true, false, clockInCard, getState().currentTimeCard),
        );

        return dispatch(
            saveCard({
                timecards: [clockOutCard, clockInCard],
                resultFunc: async (cards) => {
                    await saveItemToStorage(
                        'currentTimeCard',
                        null,
                        getUpdatedStateClockInSuccess(true, false, cards[1], getState().currentTimeCard),
                    );
                    dispatch({
                        type: CNST.CURRENT_TIME_CARD.CLOCK_IN.SUCCESS,
                        timecard: cards[1],
                        isSwitch: true,
                    });
                },
            }),
        );
    };
}

export function changeClockInTime(newClockInTime) {
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.CHANGE_CLOCK_IN_TIME,
            newClockInTime,
        });
    };
}

export function changeProject(project) {
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.CHANGE_PROJECT,
            project,
        });
    };
}

export function changeCostCode(costCode) {
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.CHANGE_COST_CODE,
            costCode,
        });
    };
}

export function changeBreakTime(breakIn, breakOut, clockIn) {
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.CHANGE_BREAK_TIME,
            breakIn,
            breakOut,
            clockIn,
        });
    };
}

export function setTimecardChange(isChange, currentTimeCard) {
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.CHANGE_MIS_CHOSEN_PROJECT,
            isChange,
            currentTimeCard,
        });
    };
}

export function setDataAppLoading(isLoading) {
    return (dispatch) => {
        dispatch({
            type: CNST.SET_DATA_APP_LOADING,
            isLoading,
        });
    };
}

export function setLoading(isLoading) {
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.SET_LOADING,
            isLoading,
        });
    };
}

export function setBreakInLoading(isBreakInLoading) {
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.SET_BREAK_IN_LOADING,
            isBreakInLoading,
        });
    };
}

export function setBreakOutLoading(isBreakOutLoading) {
    return (dispatch) => {
        dispatch({
            type: CNST.CURRENT_TIME_CARD.SET_BREAK_OUT_LOADING,
            isBreakOutLoading,
        });
    };
}

// ------------------------------------
// Reducers
// ------------------------------------
const initialState = {
    currentUserId: null,
    currentProject: null,
    currentCostCode: null,
    currentTAM: null,
    workOrderNumber: '',
    workOrderYear: '',
    isClockIn: false,
    haveClockOutTime: false,
    isTimeCard: false,
    clockIn: [],
    clockOut: [],
    breakIn: [],
    breakOut: [],
    location: [],
    currentClockInType: '',
    isSelectingClockInType: false,
    isSwitch: false,
    WorkTimeId: null,
    isLoading: false,
    isDataLoading: false,
    isBreakInLoading: false,
    isBreakOutLoading: false,
    isChange: false,
    switchCard: null,
    timeCardBeforeProjectChange: null,
};

export default function currentTimeCardReducer(state = cloneDeep(initialState), action) {
    switch (action.type) {
        case CNST.ACCOUNT.LOGIN.SUCCESS: {
            if (isNil(state.currentUserId) || action.user._id !== state.currentUserId) {
                return { ...cloneDeep(initialState), currentUserId: action.user._id };
            }

            return state;
        }
        case CNST.CURRENT_TIME_CARD.CHANGE_MIS_CHOSEN_PROJECT: {
            const { isChange, currentTimeCard } = action;
            return {
                ...state,
                isChange: action.isChange,
                timeCardBeforeProjectChange: isChange ? currentTimeCard : null,
            };
        }
        case CNST.CURRENT_TIME_CARD.CLOCK_IN.LOADING:
        case CNST.CURRENT_TIME_CARD.CLOCK_OUT.LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case CNST.CURRENT_TIME_CARD.BREAK_IN.LOADING:
            return {
                ...state,
                isBreakInLoading: true,
            };
        case CNST.CURRENT_TIME_CARD.BREAK_OUT.LOADING:
            return {
                ...state,
                isBreakOutLoading: true,
            };
        case CNST.CURRENT_TIME_CARD.CLOCK_IN.SUCCESS: {
            const { isSwitch, timecard } = action;
            return getUpdatedStateClockInSuccess(isSwitch, false, timecard, state);
        }
        case CNST.CURRENT_TIME_CARD.SET_SWITCH:
            return {
                ...state,
                ...{
                    isSwitch: action.isSwitch,
                    switchCard: action.data,
                },
            };
        case CNST.CURRENT_TIME_CARD.CLOCK_OUT.SUCCESS:
            return getUpdatedStateClockOutSuccess(state);
        case CNST.CURRENT_TIME_CARD.BREAK_IN.SUCCESS:
            return getUpdatedStateBreakInSuccess(action.timecard, true, state);
        case CNST.CURRENT_TIME_CARD.BREAK_OUT.SUCCESS:
            return getUpdatedStateBreakOutSuccess(action.timecard, true, state);
        case CNST.CURRENT_TIME_CARD.ADD_LOCATION:
            return {
                ...state,
                location: [...state.location, action.item],
            };
        case CNST.CURRENT_TIME_CARD.SET_CURRENT_PROJECT:
            return {
                ...state,
                ...{
                    currentProject: action.project,
                    currentCostCode: null,
                },
            };
        case CNST.CURRENT_TIME_CARD.SET_CURRENT_COST_CODE:
            return {
                ...state,
                ...{
                    currentCostCode: action.code,
                    isSelectingClockInType: false,
                },
            };
        case CNST.CURRENT_TIME_CARD.SET_CURRENT_TAM:
            return {
                ...state,
                ...{
                    currentTAM: action.item,
                    isSelectingClockInType: false,
                },
            };
        case CNST.CURRENT_TIME_CARD.SET_WORK_ORDER_NUMBER:
            return { ...state, ...{ workOrderNumber: action.workOrderNumber } };
        case CNST.CURRENT_TIME_CARD.SET_WORK_ORDER_YEAR:
            return { ...state, ...{ workOrderYear: action.workOrderYear } };
        case CNST.CURRENT_TIME_CARD.SET_DESCRIPTION:
            return { ...state, ...{ description: action.description } };
        case CNST.CURRENT_TIME_CARD.SET_CLOCK_IN_TYPE:
            return {
                ...state,
                ...{
                    currentClockInType: action.clockInType,
                    isSelectingClockInType: true,
                },
            };
        case CNST.CURRENT_TIME_CARD.w:
            if (!action.isSwitch) {
                return {
                    ...state,
                    ...{
                        isSwitch: action.isSwitch,
                        switchCard: null,
                    },
                };
            }
            return {
                ...state,
                ...{
                    isSwitch: action.isSwitch,
                    switchCard: action.data,
                },
            };
        case CNST.CURRENT_TIME_CARD.CHANGE_CLOCK_IN_TIME:
            return {
                ...state,
                ...{
                    clockIn: [action.newClockInTime],
                },
            };
        case CNST.CURRENT_TIME_CARD.CHANGE_PROJECT:
            return {
                ...state,
                ...{
                    currentProject: action.project,
                },
            };
        case CNST.CURRENT_TIME_CARD.CHANGE_COST_CODE:
            return {
                ...state,
                ...{
                    currentCostCode: action.costCode,
                },
            };
        case CNST.CURRENT_TIME_CARD.CHANGE_BREAK_TIME:
            return {
                ...state,
                ...{
                    breakIn: action.breakIn,
                    breakOut: action.breakOut,
                    clockIn: action.clockIn,
                },
            };
        case CNST.INIT_APP:
            return {
                ...state,
                isLoading: false,
            };
        case CNST.SET_DATA_APP_LOADING:
            return {
                ...state,
                isDataLoading: action.isLoading,
            };
        case CNST.CURRENT_TIME_CARD.SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        case CNST.CURRENT_TIME_CARD.SET_BREAK_IN_LOADING:
            return {
                ...state,
                isBreakInLoading: action.isBreakInLoading,
            };
        case CNST.CURRENT_TIME_CARD.SET_BREAK_OUT_LOADING:
            return {
                ...state,
                isBreakOutLoading: action.isBreakOutLoading,
            };
        case CNST.TIME_CARDS.GET_TIMECARDS.SUCCESS: {
            const { lastTimeCard, unsavedCards, relatedData } = action;

            if (lastTimeCard && !lastTimeCard.EndTime && unsavedCards) {
                // check if we have this work time unsaved
                const unsavedCard = unsavedCards.find((uc) => uc.WorkTimeId === lastTimeCard.WorkTimeId);
                if (!unsavedCard) {
                    let currentProject = null;
                    let currentCostCode = null;

                    if (
                        lastTimeCard.Project &&
                        relatedData[Config.PROJECTS] &&
                        relatedData[Config.PROJECTS][lastTimeCard.Project]
                    ) {
                        currentProject = relatedData[Config.PROJECTS][lastTimeCard.Project];
                    }

                    if (
                        lastTimeCard.CostCode &&
                        relatedData[Config.COST_CODES] &&
                        relatedData[Config.COST_CODES][lastTimeCard.CostCode]
                    ) {
                        currentCostCode = relatedData[Config.COST_CODES][lastTimeCard.CostCode];
                    }

                    let isClockIn = true;
                    const clockIn = [lastTimeCard.StartTime];
                    const clockOut = [];
                    const breakIn = [];
                    const breakOut = [];

                    const lastBreak =
                        lastTimeCard.Breaks.length > 0 ? lastTimeCard.Breaks[lastTimeCard.Breaks.length - 1] : null;
                    if (lastBreak && !lastBreak.EndTime) {
                        isClockIn = false;
                    }

                    for (const breakItem of lastTimeCard.Breaks) {
                        breakIn.push(breakItem.StartTime);
                        clockOut.push(breakItem.StartTime);

                        if (breakItem.EndTime) {
                            breakOut.push(breakItem.EndTime);
                            clockIn.push(breakItem.EndTime);
                        }
                    }

                    let workOrderNumber = '';
                    let workOrderYear = '';
                    if (lastTimeCard.WorkOrder && lastTimeCard.WorkOrder.includes('-')) {
                        [workOrderYear, workOrderNumber] = lastTimeCard.WorkOrder.split('-');
                        if (workOrderNumber && workOrderNumber.length > 2) {
                            // remove first two characters from workOrderNumber
                            // because it's hardcoded 00 and will added later again while saving
                            workOrderNumber = workOrderNumber.substring(2);
                        }
                    }

                    return {
                        currentProject,
                        currentCostCode,
                        currentTAM: null,
                        workOrderNumber,
                        workOrderYear,
                        isClockIn,
                        haveClockOutTime: false,
                        isTimeCard: true,
                        clockIn,
                        clockOut,
                        breakIn,
                        breakOut,
                        location: [...lastTimeCard.Location, ...state.location],
                        currentClockInType: lastTimeCard.ClockInType,
                        isSelectingClockInType: false,
                        isSwitch: false,
                        WorkTimeId: lastTimeCard.WorkTimeId,
                        isLoading: false,
                        isDataLoading: false,
                        isBreakInLoading: false,
                        isBreakOutLoading: false,
                        isChange: false,
                        switchCard: null,
                        timeCardBeforeProjectChange: null,
                        isMileageTrackingEnabled: currentCostCode && currentCostCode.ForceMileageTracking,
                        totalMileage: lastTimeCard.TotalMileage || 0,
                        description: lastTimeCard.Description,
                    };
                }
            }

            return state;
        }
        case 'persist/REHYDRATE': {
            if (action.payload) {
                const { account, currentTimeCard } = action.payload;
                if (account.user) {
                    return { ...currentTimeCard, currentUserId: account.user._id };
                }
                return { ...currentTimeCard };
            }
            return state;
        }
        default:
            return state;
    }
}
