import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, FlatList } from 'react-native';
import { connect } from 'react-redux';
import { setReport } from '../../../store/modules/customReports';
import { ListItem, NavigationBar, BottomGradient } from '../../../components/index';
import s from './styles';

class CustomReportReportsScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        customReports: PropTypes.array,
        setReport: PropTypes.func,
    };

    _returnCustomReports = () => {
        const { customReports, setReport, navigation } = this.props;
        return customReports.map((report) => {
            return {
                id: report.id,
                title: `${report.CustomReportName}`,
                onPress: () => {
                    const reportAnswers = {
                        CustomReportName: report.CustomReportName,
                        QuestionCategory: report.QuestionCategory,
                        Sections: report.Sections.map((section) => {
                            return {
                                Section: section.Section,
                                PhotoRequired: section.PhotoRequired,
                                AdminOnly: section.AdminOnly,
                                Attachments: [],
                                Collapsed: true,
                                Questions: section.Questions.map((question) => ({
                                    ...question,
                                    Attachments: [],
                                    Value: null,
                                    AdminOnly: question.AdminOnly,
                                })),
                            };
                        }),
                    };
                    setReport(reportAnswers);
                    navigation.navigate('CustomReport');
                },
            };
        });
    };

    render() {
        return (
            <View style={s.wrapper}>
                <NavigationBar {...this.props} title="NEW REPORT" backIcon />
                <View style={[s.mainContainer]}>
                    <BottomGradient />
                    <FlatList
                        ListHeaderComponent={<Text style={s.listTitle}>Please select</Text>}
                        data={this._returnCustomReports()}
                        keyExtractor={(item) => item.id}
                        style={{ width: '100%' }}
                        renderItem={({ item }) => {
                            return <ListItem item={item} />;
                        }}
                        ListFooterComponent={<View style={s.bottomPadding} />}
                    />
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ customReports }) => ({
    customReports: customReports.customReports,
    customers: customReports.customers,
});

export default connect(mapStateToProps, {
    setReport,
})(CustomReportReportsScreen);
