import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, View, TouchableOpacity } from 'react-native';
import { bindActionCreators } from 'redux';
import { isEmpty, cloneDeep } from 'lodash';
import { NavigationActions, withNavigationFocus } from 'react-navigation';
import { connect } from 'react-redux';
import moment from 'moment';
import NetInfo from '@react-native-community/netinfo';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { selectTimeCardEdit } from '../../../store/modules/timeCardEdit';
import { Color } from '../../../theme';
import { ConfirmationDialog, NavigationBar, TabsMenu } from '../../../components/index';
import { getReimbursementRequestsByPayPeriod } from '../../../store/modules/reimbursements';
import Entires from './Entires';
import Weeks from './Weeks';
import PayPeriod from './PayPeriod';
import {
    setLoading,
    setRefreshed,
    signPayPeriod,
    getTimeCardModifications,
    setCurrentTab,
    addUnsavedSignedPeriod,
    getTimeCardsByPayPeriod,
    getPayPeriods,
} from '../../../store/modules/timeCards';
import { getTimeOffCards } from '../../../store/modules/timeOff';
import { userLogin } from '../../../store/modules/account';

import { uploadImage } from '../../../store/helpers/common';
import { formatTimeFromSeconds } from '../../../utils/helpers';
import hasAccessTo from '../../../utils/auth';
import { Config } from '../../../config';

import s from '../../TimeCardEditScreen/styles';

const tabs = [
    {
        id: 1,
        title: 'ENTRIES',
    },
    {
        id: 2,
        title: 'WEEK',
    },
    {
        id: 3,
        title: 'PAY PERIOD',
    },
];

class TimeCardsScreen extends Component {
    static propTypes = {
        activeTab: PropTypes.number,
        isRefresh: PropTypes.bool,
        isFocused: PropTypes.bool,
        isLoading: PropTypes.bool,
        isDataLoading: PropTypes.bool,
        isAutoLoginLoading: PropTypes.bool,
        navigation: PropTypes.object,
        timeCards: PropTypes.array,
        timeOffItems: PropTypes.array,
        timeCardModifications: PropTypes.array,
        ptoBuckets: PropTypes.object,
        user: PropTypes.object,
        unsavedCards: PropTypes.array,
        payPeriods: PropTypes.array,
        currentWorkOrderNumber: PropTypes.string,
        currentWorkTimeId: PropTypes.string,
        currentWorkOrderYear: PropTypes.string,
        generalRules: PropTypes.object,
        lastGetTimeCardsStartDate: PropTypes.string,
        isTimeCardsReplaced: PropTypes.bool,
        getTimeCardModifications: PropTypes.func,
        setRefreshed: PropTypes.func,
        selectTimeCardEdit: PropTypes.func,
        setCurrentTab: PropTypes.func,
        setLoading: PropTypes.func,
        signPayPeriod: PropTypes.func,
        uploadImage: PropTypes.func,
        addUnsavedSignedPeriod: PropTypes.func,
        getTimeOffCards: PropTypes.func,
        getTimeCardsByPayPeriod: PropTypes.func,
        getPayPeriods: PropTypes.func,
        reimbursementRequestsByPayPeriod: PropTypes.array,
        getReimbursementRequestsByPayPeriod: PropTypes.func,
        userLogin: PropTypes.func,
    };

    static insertCard({ cards, date, totalTime, currentCard }) {
        const existingCard = cards.find((c) => c.date === date);
        if (existingCard) {
            existingCard.data.push(currentCard);
            existingCard.totalTime += totalTime;
            // SET CLOCK IN STATUS FOR SECTION IF AT LEAST ONE CART IS CURRENTLY CLOCKED IN
            if (!currentCard.timeEnd) {
                existingCard.isClockedIn = true;
                existingCard.clockInTime = currentCard.timeStart;
            }
        } else {
            cards.push({
                date,
                totalTime,
                data: [currentCard],
                isClockedIn: !currentCard.timeEnd,
                clockInTime: !currentCard.timeEnd ? currentCard.timeStart : '',
            });
        }
    }

    static timeOffTotalTime(totalTime) {
        const split = totalTime.split(' ');
        let hours = split[0].split('');
        hours.pop();
        if (hours.length === 1) {
            hours.push('0');
            hours.reverse();
        }
        hours = hours.join('');
        let minutes = split[1].split('');
        minutes.pop();
        if (minutes.length === 1) {
            minutes.push('0');
            minutes.reverse();
        }
        minutes = minutes.join('');
        totalTime = `${hours}:${minutes}`;
        return totalTime;
    }

    constructor(props) {
        super(props);

        this.state = {
            cards: [],
            showConfirmationDialog: false,
        };
    }

    componentDidMount() {
        const { user, getTimeCardModifications, getTimeOffCards, getTimeCardsByPayPeriod, payPeriods } = this.props;
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                getTimeCardModifications(user);
                getTimeOffCards(user._id);

                const lastPayPeriod = payPeriods[payPeriods.length - 1];
                if (lastPayPeriod) {
                    getTimeCardsByPayPeriod({
                        user,
                        startDate: lastPayPeriod.StartDate,
                        endDate: lastPayPeriod.EndDate,
                    });
                }
            } else {
                this._returnTimeCards();
            }
        });
    }

    componentDidUpdate(prevProps) {
        const {
            isRefresh,
            isFocused,
            timeCards,
            setRefreshed,
            getTimeCardModifications,
            getTimeOffCards,
            user,
            unsavedCards,
            currentWorkOrderNumber,
            payPeriods,
            getPayPeriods,
            getTimeCardsByPayPeriod,
        } = this.props;

        if (
            timeCards.length !== prevProps.timeCards.length ||
            unsavedCards.length !== prevProps.unsavedCards.length ||
            currentWorkOrderNumber !== prevProps.currentWorkOrderNumber
        ) {
            this._returnTimeCards();
            getPayPeriods();
        }

        if (isRefresh) {
            this._returnTimeCards();
            setRefreshed();
        }

        if (!prevProps.isFocused && isFocused) {
            NetInfo.fetch().then((state) => {
                const { isConnected } = state;
                if (isConnected) {
                    getTimeCardModifications(user);
                    getTimeOffCards(user._id);
                }
            });
        }

        // get time cards for the last pay period after login and pay periods are loaded
        // or if new pay periods is added
        if (
            (prevProps.payPeriods.length === 0 && payPeriods.length > 0) ||
            (prevProps.payPeriods.length > 0 && prevProps.payPeriods.length !== payPeriods.length)
        ) {
            getTimeCardsByPayPeriod({
                user,
                startDate: payPeriods[payPeriods.length - 1].StartDate,
                endDate: payPeriods[payPeriods.length - 1].EndDate,
                isReplace: true,
            });
        }
    }

    createTimeCard = () => {
        const { navigation, selectTimeCardEdit } = this.props;
        selectTimeCardEdit();
        navigation.navigate('TimeCardEdit', {}, NavigationActions.navigate({ routeName: 'TimeCardEdit' }));
    };

    _returnTimeOffArray() {
        const { timeOffItems, ptoBuckets } = this.props;
        const daysOff = [];

        timeOffItems.forEach((timeOffRequest) => {
            if (timeOffRequest.TicketStatus === 'A') {
                timeOffRequest.TimeOffDays.forEach((timeOffDay) => {
                    const ptoBucket = ptoBuckets[timeOffDay.PtoBucket];
                    let title = null;
                    let type = null;

                    if (ptoBucket && ptoBucket.InternalName === 'sick') {
                        title = 'Sick Time Off';
                        type = 'S';
                    } else if (ptoBucket && ptoBucket.InternalName === 'personal') {
                        title = 'Personal Time Off';
                        type = 'P';
                    } else if (ptoBucket && ptoBucket.InternalName === 'vacation') {
                        title = 'Vacation Time Off';
                        type = 'V';
                    } else if (ptoBucket) {
                        title = ptoBucket.Name;
                        type = 'OP';
                    }

                    const paidTimeOf = {
                        data: [
                            {
                                title,
                                type,
                                timeStart: null,
                                timeEnd: null,
                                totalTime: 0,
                                ptoTime: timeOffDay.Hours,
                            },
                        ],
                        date: moment(timeOffDay.Date).format('YYYY-MM-DD'),
                        totalTime: 0,
                        ptoTime: timeOffDay.Hours,
                        type: title,
                    };
                    daysOff.push(paidTimeOf);
                });
            }
        });
        return daysOff;
    }

    _returnTimeCards() {
        const { navigation, timeCards, selectTimeCardEdit, timeCardModifications, unsavedCards, user } = this.props;
        const timeOff = this._returnTimeOffArray();
        const cards = [...timeOff];
        if (!isEmpty(timeCards)) {
            timeCards.forEach((timeCard) => {
                const cardModification = timeCardModifications.find(
                    (m) => m.TimeCardDay === timeCard.WorkTimeId && !m.Change,
                );
                const unsavedCard = unsavedCards.find((m) => m.WorkTimeId === timeCard.WorkTimeId);
                if ((!cardModification || (cardModification && cardModification.Status === 'A')) && !unsavedCard) {
                    this._createAndInsertTimeCard(timeCard, cards, false);
                }
            });
            const unSavedTime = cloneDeep(unsavedCards).reverse();
            const filteredUnsavedTimeCards = unSavedTime.filter(
                (unsavedTime, index, self) => index === self.findIndex((t) => t.WorkTimeId === unsavedTime.WorkTimeId),
            );
            filteredUnsavedTimeCards.forEach((timeCard) => {
                const cardModification = timeCardModifications.find(
                    (m) => m.TimeCardDay === timeCard.WorkTimeId && !m.Change,
                );
                if (!cardModification) {
                    this._createAndInsertTimeCard(timeCard, cards, true);
                }
            });

            timeCardModifications.forEach((timeCardMod) => {
                const timeCard = timeCards.find((m) => m.WorkTimeId === timeCardMod.TimeCardDay && !m.Change);
                let totalTime = moment(timeCardMod.EndTime).diff(moment(timeCardMod.StartTime), 'seconds');
                let totalBreakTime = 0;
                for (let i = 0; i < timeCardMod.Breaks.length; i++) {
                    totalBreakTime += moment(timeCardMod.Breaks[i].EndTime).diff(
                        moment(timeCardMod.Breaks[i].StartTime),
                        'seconds',
                    );
                }
                totalTime -= totalBreakTime;
                if (timeCardMod.Status === 'U' || timeCardMod.Status === 'D' || timeCardMod.Status === 'N') {
                    const title = `${timeCardMod.ProjectName}`;
                    const currentCard = {
                        id: timeCardMod.id,
                        title,
                        type: timeCardMod.ClockInType,
                        timeStart: timeCardMod.StartTime,
                        timeEnd: timeCardMod.EndTime,
                        totalTime: formatTimeFromSeconds(totalTime, true),
                        totalBreakTime,
                        isDisabled: false,
                        isClockedIn: false,
                        oldTimeCard: timeCard,
                        timeCardModifications: true,
                        onPress: () => {
                            if (hasAccessTo(user, Config.TIMECARD_MODIFICATIONS)) {
                                selectTimeCardEdit(timeCardMod, 2);
                                navigation.navigate('TimeCardEdit');
                            }
                        },
                    };
                    switch (timeCardMod.Status) {
                        case 'U':
                            currentCard.status = 'EDIT IS PENDING APPROVAL';
                            break;
                        case 'D':
                            currentCard.status = 'EDIT IS DENIED';
                            break;
                        case 'N':
                            currentCard.status = 'ADMIN NEEDS MORE INFORMATION';
                            break;
                        default:
                            currentCard.status = '';
                            break;
                    }
                    this.insertTimeCard(cards, timeCardMod, currentCard, timeCard ? timeCard.Duration.WorkTime : 0);
                }
            });
        }
        cards.sort((a, b) => {
            if (a.date < b.date) {
                return -1;
            }
            if (a.date > b.date) {
                return 1;
            }
            return 0;
        });

        cards.reverse();
        cards.forEach((card) => {
            card.data.reverse();
        });
        this.setState({ cards });
    }

    _createAndInsertTimeCard(timeCard, cards, offline) {
        const {
            navigation,
            selectTimeCardEdit,
            user,
            currentWorkTimeId,
            currentWorkOrderYear,
            currentWorkOrderNumber,
        } = this.props;
        const isClockedIn = isEmpty(timeCard.EndTime);
        const title = timeCard.ProjectNumber
            ? `${timeCard.ProjectNumber}-${timeCard.ProjectName}`
            : timeCard.ProjectName;
        const currentCard = {
            id: timeCard.Project + 1,
            title,
            workOrderNumber:
                currentWorkTimeId === timeCard.WorkTimeId
                    ? `${currentWorkOrderYear}-${currentWorkOrderNumber}`
                    : timeCard.WorkOrder,
            type: timeCard.ClockInType,
            timeStart: timeCard.StartTime,
            timeEnd: timeCard.EndTime,
            offline,
            totalTime: timeCard.Duration.WorkTime,
            totalBreakTime: timeCard.Duration.BreakTime ? timeCard.Duration.BreakTime : 0,
            isDisabled: isClockedIn,
            isClockedIn: isEmpty(timeCard.EndTime),
            timeCardModifications: false,
            onPress: () => {
                if (offline) {
                    this.setState({ showConfirmationDialog: true });
                } else if (hasAccessTo(user, Config.TIMECARD_MODIFICATIONS)) {
                    selectTimeCardEdit(timeCard, 1);
                    navigation.navigate('TimeCardEdit');
                }
            },
        };

        if (Object.prototype.hasOwnProperty.call(timeCard, 'valid')) {
            currentCard.valid = timeCard.valid;
        }
        this.insertTimeCard(cards, timeCard, currentCard, !currentCard.isClockedIn ? timeCard.Duration.WorkTime : 0);
    }

    insertTimeCard(cards, timeCard, currentCard, totalTime) {
        TimeCardsScreen.insertCard({
            cards,
            date: moment(timeCard.StartTime).format('YYYY-MM-DD'),
            totalTime,
            currentCard,
        });
    }

    _returnTab() {
        const { cards } = this.state;
        const {
            user,
            getTimeCardModifications,
            signPayPeriod,
            uploadImage,
            isLoading,
            setLoading,
            activeTab,
            addUnsavedSignedPeriod,
            timeCardModifications,
            payPeriods,
            generalRules,
            getTimeCardsByPayPeriod,
            lastGetTimeCardsStartDate,
            isTimeCardsReplaced,
            reimbursementRequestsByPayPeriod,
            getReimbursementRequestsByPayPeriod,
        } = this.props;
        switch (activeTab) {
            case 3:
                return (
                    <PayPeriod
                        cards={cards}
                        signPayPeriod={signPayPeriod}
                        user={user}
                        getTimeCardModifications={getTimeCardModifications}
                        setLoading={setLoading}
                        isLoading={isLoading}
                        uploadImage={uploadImage}
                        addUnsavedSignedPeriod={addUnsavedSignedPeriod}
                        timeCardModifications={timeCardModifications}
                        payPeriods={payPeriods}
                        generalRules={generalRules}
                        getTimeCardsByPayPeriod={getTimeCardsByPayPeriod}
                        lastGetTimeCardsStartDate={lastGetTimeCardsStartDate}
                        isTimeCardsReplaced={isTimeCardsReplaced}
                        reimbursementRequestsByPayPeriod={reimbursementRequestsByPayPeriod}
                        getReimbursementRequestsByPayPeriod={getReimbursementRequestsByPayPeriod}
                    />
                );
            case 2:
                return (
                    <Weeks
                        cards={cards}
                        isLoading={isLoading}
                        payPeriods={payPeriods}
                        user={user}
                        getTimeCardsByPayPeriod={getTimeCardsByPayPeriod}
                        lastGetTimeCardsStartDate={lastGetTimeCardsStartDate}
                        isTimeCardsReplaced={isTimeCardsReplaced}
                    />
                );
            case 1:
            default:
                return (
                    <Entires
                        cards={cards}
                        isLoading={isLoading}
                        getTimeCardModifications={getTimeCardModifications}
                        user={user}
                        payPeriods={payPeriods}
                        getTimeCardsByPayPeriod={getTimeCardsByPayPeriod}
                        lastGetTimeCardsStartDate={lastGetTimeCardsStartDate}
                    />
                );
        }
    }

    render() {
        const { navigation, isDataLoading, isAutoLoginLoading, setCurrentTab, activeTab, user } = this.props;
        const { showConfirmationDialog } = this.state;
        const hideUINavigation = navigation.getParam('hideUINavigation');

        let content;
        if (isDataLoading || isAutoLoginLoading) {
            content = (
                <ActivityIndicator
                    color={Color.blue}
                    size={40}
                    style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
                />
            );
        } else {
            content = (
                <>
                    <TabsMenu
                        tabs={tabs}
                        activeTab={activeTab}
                        onTabPress={(t) => {
                            setCurrentTab(t);
                        }}
                    />

                    {this._returnTab()}
                </>
            );
        }

        return (
            <View style={[s.wrapper]}>
                <NavigationBar
                    {...this.props}
                    title="TIME CARDS"
                    menuIcon={!hideUINavigation}
                    rightComponent={
                        activeTab === 1 &&
                        hasAccessTo(user, Config.TIMECARD_MODIFICATIONS) && (
                            <TouchableOpacity onPress={this.createTimeCard}>
                                <Icon name="plus" color={Color.turquoise} size={30} />
                            </TouchableOpacity>
                        )
                    }
                />
                <ConfirmationDialog
                    isOpen={showConfirmationDialog}
                    onPress={() => this.setState({ showConfirmationDialog: false })}
                    // eslint-disable-next-line max-len
                    text="This entry is saved on the device because of low to now service. It will try to upload the recorded in the background, on clock in or clock out."
                />
                {content}
            </View>
        );
    }
}

const mapStateToProps = ({ timeCards, account, currentTimeCard, timeOff, reimbursements }) => ({
    currentTimeCard,
    timeCards: timeCards.timeCards,
    unsavedCards: timeCards.unsavedCards,
    isRefresh: timeCards.isRefresh,
    isLoading: timeCards.isLoading,
    isDataLoading: currentTimeCard.isDataLoading,
    isAutoLoginLoading: account.isAutoLoginLoading,
    projects: account.projects,
    user: account.user,
    currentProject: currentTimeCard.currentProject,
    currentCostCode: currentTimeCard.currentCostCode,
    currentClockInType: currentTimeCard.currentClockInType,
    currentClockInTime: currentTimeCard.clockIn[0],
    currentWorkOrderNumber: currentTimeCard.workOrderNumber,
    currentWorkTimeId: currentTimeCard.WorkTimeId,
    currentWorkOrderYear: currentTimeCard.workOrderYear,
    timeCardModifications: timeCards.timeCardModifications,
    activeTab: timeCards.activeTab,
    timeOffItems: timeOff.timeOffItems,
    ptoBuckets: timeOff.ptoBuckets,
    payPeriods: timeCards.payPeriods,
    generalRules: account.settings.generalRules,
    lastGetTimeCardsStartDate: timeCards.lastGetTimeCardsStartDate,
    isTimeCardsReplaced: timeCards.isTimeCardsReplaced,
    reimbursementRequestsByPayPeriod: reimbursements.reimbursementRequestsByPayPeriod,
});

const mapDispatchToProps = (dispatch) => {
    const bindActions = bindActionCreators(
        {
            setRefreshed,
            signPayPeriod,
            uploadImage,
            setLoading,
            selectTimeCardEdit,
            getTimeCardModifications,
            setCurrentTab,
            addUnsavedSignedPeriod,
            getTimeOffCards,
            getPayPeriods,
            getTimeCardsByPayPeriod,
            getReimbursementRequestsByPayPeriod,
            userLogin,
        },
        dispatch,
    );
    bindActions.dispatch = dispatch;
    return bindActions;
};

export default withNavigationFocus(connect(mapStateToProps, mapDispatchToProps)(TimeCardsScreen));
