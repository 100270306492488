import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, FlatList, TouchableOpacity, View, Text } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { createTimeOffItem, getTimeOffCards, setTimeOffItem } from '../../../store/modules/timeOff';
import { userUpdate, getDepartment, userLogin } from '../../../store/modules/account';

import { NavigationBar } from '../../../components/index';
import ListItem from './ListItem';

import s from './styles';
import { Color } from '../../../theme/index';

class PaidTimeOffListScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        user: PropTypes.object,
        timeOffItems: PropTypes.array,
        assignUsers: PropTypes.array,
        isLoading: PropTypes.bool,
        isDataLoading: PropTypes.bool,
        isAutoLoginLoading: PropTypes.bool,
        createTimeOffItem: PropTypes.func,
        getTimeOffCards: PropTypes.func,
        setTimeOffItem: PropTypes.func,
        userUpdate: PropTypes.func,
        getDepartment: PropTypes.func,
        userLogin: PropTypes.func,
    };

    state = {
        ticketStatus: 'U',
    };

    componentDidMount() {
        this.onRefresh();
    }

    _returnItems() {
        const { timeOffItems } = this.props;
        const items = [];
        const timeOffList = cloneDeep(timeOffItems);
        const sortedList = timeOffList.sort((a, b) => new Date(a.StartTime) - new Date(b.StartTime));
        sortedList.forEach((t) => {
            const item = {
                id: t.id,
                totalVacationHours: t.TotalVacationHours,
                totalPersonalHours: t.TotalPersonalHours,
                totalSickHours: t.TotalSickHours,
                offline: t.unsaved === true,
                startTime: t.StartTime,
                endTime: t.EndTime,
                ticketStatus: t.TicketStatus,
                adminFeedback: t.AdminFeedback,

                onPress: () => this.setTimeOff(t),
            };
            items.push(item);
        });
        return items;
    }

    setTimeOff = (item) => {
        const { setTimeOffItem, navigation } = this.props;
        setTimeOffItem(item);
        navigation.navigate('PaidTimeOffItem');
    };

    createTimeOff = () => {
        const { createTimeOffItem, navigation, user } = this.props;
        createTimeOffItem({ user });
        navigation.navigate('PaidTimeOffItem');
    };

    onRefresh = () => {
        const { user, getTimeOffCards, userUpdate, getDepartment } = this.props;
        getTimeOffCards(user._id);
        userUpdate(user._id);
        if (user && user.meta.Department) {
            getDepartment(user.meta.Department);
        }
    };

    setTicketStatus = (status) => {
        this.setState({ ticketStatus: status });
    };

    renderTicketStatusSelector = () => {
        const { ticketStatus } = this.state;
        return (
            <View style={s.statusSelectorContainer}>
                <TouchableOpacity
                    style={[
                        s.statusButton,
                        ticketStatus === 'U' ? s.pendingButton : null, // Default selected style
                    ]}
                    onPress={() => this.setTicketStatus('U')}
                >
                    <Text style={s.statusButtonText}>Pending</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[
                        s.statusButton,
                        ticketStatus === 'D' ? s.declinedButton : null, // Apply declined color if selected
                    ]}
                    onPress={() => this.setTicketStatus('D')}
                >
                    <Text style={s.statusButtonText}>Declined</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[
                        s.statusButton,
                        ticketStatus === 'A' ? s.approvedButton : null, // Apply approved color if selected
                    ]}
                    onPress={() => this.setTicketStatus('A')}
                >
                    <Text style={s.statusButtonText}>Approved</Text>
                </TouchableOpacity>
            </View>
        );
    };

    render() {
        const { navigation, isLoading, isDataLoading, isAutoLoginLoading, user } = this.props;
        const items = this._returnItems().filter((item) => item.ticketStatus === this.state.ticketStatus); // Filter based on ticket status
        const hideUINavigation = navigation.getParam('hideUINavigation');
        return (
            <View style={[s.wrapper]}>
                <NavigationBar {...this.props} title="DASHBOARD" menuIcon={!hideUINavigation} />
                {isDataLoading || isAutoLoginLoading ? (
                    <ActivityIndicator
                        color={Color.blue}
                        size={40}
                        style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
                    />
                ) : (
                    <View style={[s.mainContainer]}>
                        {user.meta && user.meta.PTO ? (
                            <View style={s.row}>
                                <View style={[s.item, s.firstItem]}>
                                    <Text style={{ color: 'white' }}>Personal : {user.meta.PTO.Personal}</Text>
                                </View>
                                <View style={s.item}>
                                    <Text style={{ color: 'white' }}>Sick : {user.meta.PTO.Sick}</Text>
                                </View>
                                <View style={[s.item, s.lastItem]}>
                                    <Text style={{ color: 'white' }}>Vacation : {user.meta.PTO.Vacation}</Text>
                                </View>
                            </View>
                        ) : null}
                        {this.renderTicketStatusSelector()}
                        <FlatList
                            data={items}
                            keyExtractor={(item, i) => (item ? item.id : i)}
                            refreshing={isLoading}
                            onRefresh={this.onRefresh}
                            style={{ width: '100%', height: '100%' }}
                            renderItem={({ item, i }) => {
                                return <ListItem item={item} i={i} paidTimeOff isLast={item.id === items.length - 1} />;
                            }}
                        />
                        <View style={s.bottomButtonsBlock}>
                            <TouchableOpacity style={s.addButton} onPress={this.createTimeOff}>
                                <Icon name="plus" color={Color.white} size={30} />
                            </TouchableOpacity>
                        </View>
                    </View>
                )}
            </View>
        );
    }
}

const mapStateToProps = ({ timeOff, account, currentTimeCard }) => ({
    currentTimeCard,
    isAutoLoginLoading: account.isAutoLoginLoading,
    isDataLoading: currentTimeCard.isDataLoading,
    timeOffItems: timeOff.timeOffItems,
    assignUsers: timeOff.assignUsers,
    test: timeOff,
    isLoading: timeOff.isLoading,
    user: account.user,
});

const mapDispatchToProps = (dispatch) => {
    const bindActions = bindActionCreators(
        {
            getTimeOffCards,
            createTimeOffItem,
            setTimeOffItem,
            userUpdate,
            getDepartment,
            userLogin,
        },
        dispatch,
    );
    bindActions.dispatch = dispatch;
    return bindActions;
};

export default connect(mapStateToProps, mapDispatchToProps)(PaidTimeOffListScreen);
