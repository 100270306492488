import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, ScrollView } from 'react-native';
import { connect } from 'react-redux';
import { NavigationActions } from 'react-navigation';
import NetInfo from '@react-native-community/netinfo';
import { bindActionCreators } from 'redux';

import { NavigationBar } from '../../../components';

import ListItem from './ListItem';
import { getUsers } from '../../../store/modules/account';
import { setUser } from '../../../store/modules/timeCards';

import s from './styles';

const checkOwner = (user) => {
    if (user.ownedBy.name === 'aircomfort') {
        return user;
    }
    return false;
};

class AdminViewerUserScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        users: PropTypes.array,
        getUsers: PropTypes.func,
        setUser: PropTypes.func,
    };

    componentDidMount() {
        const { getUsers } = this.props;
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                getUsers();
            }
        });
    }

    listItemOnPress(id) {
        const { navigation, users, setUser } = this.props;
        const user = users.filter((d) => d._id === id);
        setUser(user[0]);

        navigation.navigate(
            'AdminViewer',
            {},
            NavigationActions.navigate({
                routeName: 'AdminViewerTimeCards',
            }),
        );
    }

    render() {
        const { navigation, users } = this.props;
        const accUsers = users.filter(checkOwner);
        return (
            <View style={s.wrapper}>
                <NavigationBar title="USERS" menuIcon {...this.props} />
                <ScrollView style={s.mainContainer}>
                    {accUsers.map((user, i) => {
                        return (
                            <ListItem
                                key={i}
                                title={`${user.name.first} ${user.name.last}`}
                                navigation={navigation}
                                onPress={() => this.listItemOnPress(user._id)}
                            />
                        );
                    })}
                </ScrollView>
            </View>
        );
    }
}

const mapStateToProps = ({ account }) => ({
    users: account.users,
});

const mapDispatchToProps = (dispatch) => {
    const bindActions = bindActionCreators(
        {
            setUser,
            getUsers,
        },
        dispatch,
    );
    bindActions.dispatch = dispatch;
    return bindActions;
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminViewerUserScreen);
