import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, ImageBackground } from 'react-native';
import { connect } from 'react-redux';
import { showMessage } from 'react-native-flash-message';
import { isEmpty } from 'lodash';

import { userForgotPassword } from '../../../store/modules/account';

import { NavigationBar } from '../../../components';

import { Color } from '../../../theme/index';

import s from './styles';
import { Img } from '../../../theme';
import { ForgotPasswordForm } from '../../../forms';

class ForgotPasswordScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        form: PropTypes.object,
        isLoading: PropTypes.bool,
        userForgotPassword: PropTypes.func,
    };

    navigateToLogin = () => {
        const { navigation } = this.props;
        navigation.navigate('Login');
    };

    onSubmit = () => {
        const { userForgotPassword, form } = this.props;
        if (!isEmpty(form.values)) {
            const { email } = form.values;

            userForgotPassword({ email }).then((res) => {
                if (res.success) {
                    showMessage({
                        message: `${res.message}`,
                        description: 'Check your email',
                        type: 'success',
                        duration: 5000,
                        autoHide: false,
                        hideOnPress: true,
                        backgroundColor: Color.turquoise,
                    });
                    this.navigateToLogin();
                } else {
                    let message = 'Error';
                    let description = 'Some error ocurred. Please try again.';

                    if (res.statusCode === 404) {
                        message = 'There is no user with this email';
                        description = 'Please check the email you entered.';
                    } else if (res.statusCode === 403) {
                        // we got forbidden error response, which means user not active
                        message = 'User is inactive';
                        description = 'Please contact manager.';
                    }

                    showMessage({
                        message,
                        description,
                        type: 'danger',
                        duration: 5000,
                        autoHide: true,
                        hideOnPress: true,
                        hideStatusBar: true,
                        backgroundColor: Color.red,
                    });
                }
            });
        }
    };

    render() {
        const { navigation, isLoading } = this.props;
        return (
            <ImageBackground source={Img.bg} style={[s.wrapper]}>
                <NavigationBar {...this.props} title="FORGOT PASSWORD" backIcon />
                <View style={[s.mainContainer]} alignItems="center">
                    <Text style={s.forgotPasswordTitle}>
                        Enter the email address and you’ll receive a message with instructions on how to reset it.
                    </Text>
                    <View style={s.form}>
                        <ForgotPasswordForm
                            isLoading={isLoading}
                            handleSubmit={this.onSubmit}
                            navigation={navigation}
                            containerStyle={{ width: '100%' }}
                        />
                    </View>
                </View>
            </ImageBackground>
        );
    }
}

const mapStateToProps = ({ form, account }) => ({
    form: form.forgotPassword,
    isLoading: account.isLoading,
});

export default connect(mapStateToProps, {
    userForgotPassword,
})(ForgotPasswordScreen);
