import React, { PureComponent } from 'react';
import { View } from 'react-native';

import { Field, reduxForm } from 'redux-form';
import { func, object, bool } from 'prop-types';

import { FieldInput, PrimaryButton } from '../../components';
import { required, email } from '../../utils/fieldValidation';

import s from './styles';

class ForgotPasswordForm extends PureComponent {
    static propTypes = {
        handleSubmit: func,
        isLoading: bool,
        containerStyle: object,
    };

    render() {
        const { handleSubmit, isLoading, containerStyle } = this.props;

        return (
            <View style={[s.wrapper, { ...containerStyle }]}>
                <Field
                    name="email"
                    autoFocus
                    title="EMAIL"
                    type="email-address"
                    keyboardType="email-address"
                    component={FieldInput}
                    autoCorrect={false}
                    autoCapitalize="none"
                    validate={[required, email]}
                />
                <PrimaryButton title="SEND" onPress={handleSubmit} isLoading={isLoading} icon="arrow-right" />
            </View>
        );
    }
}

export default reduxForm({
    form: 'forgotPassword',
})(ForgotPasswordForm);
