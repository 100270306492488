export default {
    GET_ITEMS: 'GET_REIMBURSEMENT_ITEMS',
    GET_REQUESTS: {
        LOADING: 'GET_REIMBURSEMENT_REQUESTS',
        SUCCESS: 'GET_REIMBURSEMENT_REQUESTS_SUCCESS',
        ERROR: 'GET_REIMBURSEMENT_REQUESTS_ERROR',
    },
    SUBMIT_REQUEST: {
        LOADING: 'SUBMIT_REIMBURSEMENT_REQUEST',
        SUCCESS: 'SUBMIT_REIMBURSEMENT_SUCCESS',
        ERROR: 'SUBMIT_REIMBURSEMENT_ERROR',
    },
    GET_REQUESTS_PAY_PERIOD: {
        LOADING: 'GET_REIMBURSEMENT_REQUESTS_PAY_PERIOD',
        SUCCESS: 'GET_REIMBURSEMENT_REQUESTS_PAY_PERIOD_SUCCESS',
        ERROR: 'GET_REIMBURSEMENT_REQUESTS_PAY_PERIOD_ERROR',
    },
    SET_REQUEST: 'SET_REIMBURSEMENT_REQUEST',
    CLEAR_REQUEST: 'CLEAR_REIMBURSEMENT_REQUEST',
};
