import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        paddingTop: 20,
        height: '100%',
        width: '100%',
        alignItems: 'center',
        paddingHorizontal: '5%',
    },
    titleText: {
        color: Color.dark_navy_blue,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        width: '90%',
        marginBottom: 15,
    },
    contentText: {
        color: Color.dark_navy_blue,
        fontFamily: Font.type.light,
        fontSize: Font.size.font14,
        width: '90%',
        marginBottom: 15,
    },
    textInputWithPlaceholder: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font12,
        backgroundColor: Color.white,
        borderRadius: 5,
        textAlignVertical: 'top',
        width: '90%',
        height: 106,
        paddingTop: 20.5,
        paddingLeft: 14.5,
        marginBottom: 9,
    },
    button: {
        height: 50,
        width: 180,
        backgroundColor: Color.turquoise,
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        marginTop: 20,
        marginBottom: 30,
        alignSelf: 'center',
        shadowColor: Color.light_grey_blue,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.5,
        shadowRadius: 5,
    },
    buttonText: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.white,
        textTransform: 'uppercase',
    },
});
