import { cloneDeep, isNil } from 'lodash';
import produce from 'immer';
import NetInfo from '@react-native-community/netinfo';
import { showMessage } from 'react-native-flash-message';

import CNST from '../constants';
import Api from '../../utils/apiMiddleware';
import { Config } from '../../config';
import { uploadImage, isUploadingOverdue } from '../helpers/common';
import { generateId } from '../../utils/helpers';
import Queue from '../helpers/queue';

import { Color } from '../../theme';

const uploadingQueue = new Queue();

function uploadCheck(ticket) {
    const results = {
        isNeedUpload: false,
        uploading: false,
    };
    for (const cutSheet of ticket.CutSheetList) {
        if (cutSheet.Shape) {
            const { id, uploaded, uploading, uploadingStartTime } = cutSheet.Shape;
            const uploadingOverdue = isUploadingOverdue(uploadingStartTime);

            if (id && !isNil(uploaded) && !uploaded && (!uploading || uploadingOverdue)) {
                results.isNeedUpload = true;
            }

            if (id && !isNil(uploaded) && !uploaded && uploading && !uploadingOverdue) {
                results.uploading = true;
            }
        }
    }

    for (const customOption of ticket.CustomOptions) {
        const { id, uploaded, uploading, uploadingStartTime } = customOption.Shape;
        const uploadingOverdue = isUploadingOverdue(uploadingStartTime);

        if (id && !isNil(uploaded) && !uploaded && (!uploading || uploadingOverdue)) {
            results.isNeedUpload = true;
        }

        if (id && !isNil(uploaded) && !uploaded && uploading && !uploadingOverdue) {
            results.uploading = true;
        }
    }

    return results;
}

function saveTicketImages(ticket, onImageStartUpload, onImageUploaded, onImageUploadError, onComplete) {
    let isNeedUpload = false;

    uploadingQueue.setOnComplete = (success) => {
        onComplete(success);
    };

    const uploadHandler = (asset, shape, item) => {
        if (asset && asset.file) {
            onImageUploaded(asset, shape.id, ticket);
            item.Shape = asset.file.url;
            item.ShapeAssetId = asset._id;
            return true;
        }
        onImageUploadError(shape.id, ticket);
        return false;
    };

    const addToUploadingQueue = (shape, item) => {
        isNeedUpload = true;
        onImageStartUpload(shape.id, ticket);
        const uploadAttachment = async () => {
            const response = await uploadImage(shape, shape.file)();
            return uploadHandler(response.asset, shape, item);
        };

        uploadingQueue.push({
            func: uploadAttachment,
            args: [],
        });
    };

    // upload shapes in shape options
    for (const cutSheet of ticket.CutSheetList) {
        if (cutSheet.Shape) {
            const { id, uploaded, uploading, uploadingStartTime } = cutSheet.Shape;
            const uploadingOverdue = isUploadingOverdue(uploadingStartTime);
            if (id && !isNil(uploaded) && !uploaded && (!uploading || uploadingOverdue)) {
                addToUploadingQueue(cutSheet.Shape, cutSheet);
            }
        }
    }

    // upload shapes in custom options
    for (const customOption of ticket.CustomOptions) {
        const { id, uploaded, uploading, uploadingStartTime } = customOption.Shape;
        const uploadingOverdue = isUploadingOverdue(uploadingStartTime);
        if (id && !isNil(uploaded) && !uploaded && (!uploading || uploadingOverdue)) {
            addToUploadingQueue(customOption.Shape, customOption);
        }
    }

    return isNeedUpload;
}

function saveCutSheetItemOffline(item, status, dispatch, isShowMessage = true) {
    item.unsaved = true;
    if (!item.unsavedId) {
        item.unsavedId = generateId();
    }
    if (status) {
        item.status = status;
    }
    if (isShowMessage) {
        showMessage({
            message: 'Error',
            description:
                // eslint-disable-next-line max-len
                'You do not currently have a data connection. This item fabrication ticket will save as soon has you have connection',
            type: 'danger',
            icon: 'danger',
            position: 'right',
            hideStatusBar: true,
            duration: 1500,
            backgroundColor: Color.red,
        });
    }

    dispatch({
        type: CNST.CUT_SHEETS.SUBMIT_CUT_SHEETS_ITEM.ERROR,
        item,
    });
}

export function cutSheetsLoading() {
    return (dispatch) => {
        dispatch({
            type: CNST.CUT_SHEETS.LOADING.START,
        });
    };
}

export function cutSheetsFinishLoading() {
    return (dispatch) => {
        dispatch({
            type: CNST.CUT_SHEETS.LOADING.FINISH,
        });
    };
}

export function getCutSheets() {
    return (dispatch) => {
        return Api()
            .get(`/sources/${Config.CUT_SHEETS}/data`, { limit: 500 })
            .then((response) => {
                dispatch({
                    type: CNST.CUT_SHEETS.GET_CUT_SHEETS_ITEMS,
                    data: response.data,
                });
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    };
}

export function setCutSheetItem(item) {
    return (dispatch) => {
        dispatch({
            type: CNST.CUT_SHEETS.SET_CUT_SHEETS_ITEM,
            item,
        });
    };
}

export function clearCutSheetItem() {
    return (dispatch) => {
        dispatch({
            type: CNST.CUT_SHEETS.CLEAR_CUT_SHEETS_ITEM,
        });
    };
}

export function saveCutSheetItem(cutSheet, user) {
    let cutSheetTicketToSave = cloneDeep(cutSheet);
    return (dispatch, getState) => {
        dispatch({ type: CNST.CUT_SHEETS.SUBMIT_CUT_SHEETS_ITEM.LOADING });

        const save = () => {
            const isNew = isNil(cutSheetTicketToSave.id);
            // eslint-disable-next-line no-unused-vars
            const { id, status, unsaved, unsavedId, ...data } = cutSheetTicketToSave;

            return NetInfo.fetch().then((state) => {
                const { isConnected } = state;
                if (isConnected) {
                    return Api()
                        .put(`/sources/${Config.CUT_SHEETS}/data/${isNew ? 'new' : id}`, {
                            data,
                            modifiedBy: user.username,
                        })
                        .then((response) => {
                            showMessage({
                                message: 'Success',
                                description: 'Your item fabrication ticket has been uploaded',
                                type: 'success',
                                position: 'right',
                                hideStatusBar: true,
                                duration: 1500,
                                backgroundColor: Color.turquoise,
                            });

                            dispatch({
                                type: CNST.CUT_SHEETS.SUBMIT_CUT_SHEETS_ITEM.SUCCESS,
                                item: response,
                                unsavedTicketId: unsavedId,
                            });
                            return true;
                        })
                        .catch(() => {
                            saveCutSheetItemOffline(cutSheetTicketToSave, null, dispatch);
                            return true;
                        });
                }

                saveCutSheetItemOffline(cutSheetTicketToSave, null, dispatch);
                return true;
            });
        };

        const uploadStatus = uploadCheck(cutSheetTicketToSave);
        if (uploadStatus.isNeedUpload) {
            // save item in storage while images uploading
            saveCutSheetItemOffline(cutSheetTicketToSave, 'Attachments uploading', dispatch, false);
            cutSheetTicketToSave = cloneDeep(cutSheetTicketToSave);
            return saveTicketImages(
                cutSheetTicketToSave,
                (shapeId, ticket) => {
                    dispatch({
                        type: CNST.CUT_SHEETS.SHAPE_UPLOAD.START,
                        shapeId,
                        ticket,
                    });
                },
                (asset, shapeId, ticket) => {
                    dispatch({
                        type: CNST.CUT_SHEETS.SHAPE_UPLOAD.SUCCESS,
                        asset,
                        shapeId,
                        ticket,
                    });
                },
                (shapeId, ticket) => {
                    dispatch({
                        type: CNST.CUT_SHEETS.SHAPE_UPLOAD.ERROR,
                        shapeId,
                        ticket,
                    });
                },
                (success) => {
                    if (success) {
                        // getting the latest ticket info to save
                        const { cutSheetItems } = getState().cutSheets;
                        if (cutSheetTicketToSave.id) {
                            cutSheetTicketToSave = cutSheetItems.find((item) => item.id === cutSheetTicketToSave.id);
                        } else {
                            cutSheetTicketToSave = cutSheetItems.find(
                                (item) => item.unsavedId === cutSheetTicketToSave.unsavedId,
                            );
                        }
                        save();
                    }
                },
            );
        }

        if (uploadStatus.uploading) {
            saveCutSheetItemOffline(cutSheetTicketToSave, 'Attachments uploading', dispatch, false);
            return true;
        }

        return save();
    };
}

export function cleanUp() {
    return (dispatch) => {
        dispatch({
            type: CNST.CUT_SHEETS.CLEAN_UP,
        });
    };
}

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = {
    cutSheetItems: [],
    currentCutSheetItem: null,
    isLoading: false,
    assignUsers: [],
};

export default produce((state, action) => {
    switch (action.type) {
        case CNST.CUT_SHEETS.CLEAN_UP:
            state.currentCutSheetItem = action.data;
            state.isLoading = false;
            break;
        case CNST.CUT_SHEETS.GET_CUT_SHEETS_ITEMS:
            state.cutSheetItems = action.data;
            break;
        case CNST.CUT_SHEETS.CREATE_TIME_OFF_ITEM:
        case CNST.CUT_SHEETS.SET_CUT_SHEETS_ITEM:
            state.currentCutSheetItem = action.item;
            break;
        case CNST.CUT_SHEETS.CLEAR_CUT_SHEETS_ITEM:
            state.currentCutSheetItem = null;
            break;
        case CNST.CUT_SHEETS.SUBMIT_CUT_SHEETS_ITEM.SUCCESS: {
            const { item, unsavedTicketId } = action;
            let items = state.cutSheetItems;
            let found = false;
            items.forEach((cutSheetItem, i) => {
                if (
                    (item.id && cutSheetItem.id === item.id) ||
                    (unsavedTicketId && cutSheetItem.unsavedId === unsavedTicketId)
                ) {
                    items[i] = item;
                    found = true;
                }
            });
            if (!found) {
                items = [...state.cutSheetItems, item];
            }
            state.isLoading = false;
            state.cutSheetItems = items;
            break;
        }
        case CNST.CUT_SHEETS.SUBMIT_CUT_SHEETS_ITEM.ERROR: {
            const { item } = action;
            let found = false;
            state.cutSheetItems.forEach((t, i) => {
                if ((item.id && t.id === item.id) || (item.unsavedId && t.unsavedId === item.unsavedId)) {
                    state.cutSheetItems[i] = item;
                    found = true;
                }
            });
            if (!found) {
                state.cutSheetItems = [...state.cutSheetItems, item];
            }
            state.isLoading = false;
            break;
        }
        case CNST.CUT_SHEETS.SHAPE_UPLOAD.START: {
            const { ticket, shapeId } = action;
            const { id, unsavedId } = ticket;

            const ticketIndex = state.cutSheetItems.findIndex((item) => {
                return (unsavedId && item.unsavedId === unsavedId) || (id && item.id === id);
            });

            if (ticketIndex >= 0) {
                const ticketToUpdate = state.cutSheetItems[ticketIndex];

                let shapeFound = false;
                for (const cutSheet of ticketToUpdate.CutSheetList) {
                    if (cutSheet.Shape && cutSheet.Shape.id === shapeId) {
                        cutSheet.Shape.uploading = true;
                        cutSheet.Shape.uploadingStartTime = new Date();
                        shapeFound = true;
                    }
                }

                if (!shapeFound) {
                    for (const customOption of ticketToUpdate.CustomOptions) {
                        if (customOption.Shape.id === shapeId) {
                            customOption.Shape.uploading = true;
                            customOption.Shape.uploadingStartTime = new Date();
                        }
                    }
                }
            }
            break;
        }
        case CNST.CUT_SHEETS.SHAPE_UPLOAD.ERROR: {
            const { ticket, shapeId } = action;
            const { id, unsavedId } = ticket;

            const ticketIndex = state.cutSheetItems.findIndex((item) => {
                return (unsavedId && item.unsavedId === unsavedId) || (id && item.id === id);
            });

            if (ticketIndex >= 0) {
                const ticketToUpdate = state.cutSheetItems[ticketIndex];

                let shapeFound = false;
                for (const cutSheet of ticketToUpdate.CutSheetList) {
                    if (cutSheet.Shape && cutSheet.Shape.id === shapeId) {
                        cutSheet.Shape.uploading = false;
                        cutSheet.Shape.uploadingStartTime = null;
                        shapeFound = true;
                    }
                }

                if (!shapeFound) {
                    for (const customOption of ticketToUpdate.CustomOptions) {
                        if (customOption.Shape.id === shapeId) {
                            customOption.Shape.uploading = false;
                            customOption.Shape.uploadingStartTime = null;
                        }
                    }
                }
            }
            break;
        }

        case CNST.CUT_SHEETS.SHAPE_UPLOAD.SUCCESS: {
            const { asset, ticket, shapeId } = action;
            const { id, unsavedId } = ticket;

            const ticketIndex = state.cutSheetItems.findIndex((item) => {
                return (unsavedId && item.unsavedId === unsavedId) || (id && item.id === id);
            });

            if (ticketIndex >= 0) {
                const ticketToUpdate = state.cutSheetItems[ticketIndex];

                let shapeFound = false;
                for (const cutSheet of ticketToUpdate.CutSheetList) {
                    if (cutSheet.Shape && cutSheet.Shape.id === shapeId) {
                        cutSheet.Shape = asset.file.url;
                        cutSheet.ShapeAssetId = asset._id;
                        shapeFound = true;
                    }
                }

                if (!shapeFound) {
                    for (const customOption of ticketToUpdate.CustomOptions) {
                        if (customOption.Shape.id === shapeId) {
                            customOption.Shape = asset.file.url;
                            customOption.ShapeAssetId = asset._id;
                        }
                    }
                }
            }
            break;
        }
        case CNST.CUT_SHEETS.LOADING.START:
            state.isLoading = true;
            break;
        case CNST.CUT_SHEETS.LOADING.FINISH:
            state.isLoading = false;
            break;
        case CNST.ACCOUNT.LOGOUT.SUCCESS: {
            const newState = cloneDeep(initialState);
            newState.cutSheetItems = state.cutSheetItems.filter((t) => t.unsaved);
            return newState;
        }
        default:
            break;
    }
}, initialState);
