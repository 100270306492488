import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity } from 'react-native';
import { NavigationActions } from 'react-navigation';
import s from './styles';

const ListItem = (props) => {
    const { item, navigation } = props;
    switch (item.status) {
        case 'P':
            return (
                <TouchableOpacity
                    style={s.listItemWrapper}
                    onPress={() => {
                        navigation.navigate(
                            'ItemRequest',
                            {},
                            NavigationActions.navigate({
                                routeName: 'ItemRequestItemsRequested',
                            }),
                        );
                    }}
                    key={item.id}
                >
                    <Text style={s.listItemDateText}>{item.title}</Text>
                    <Text style={s.listItemComments}>
                        {item.date}
                        <Text>{`${item.quantity} items`}</Text>
                    </Text>
                    <Text style={s.listItemStatusPending}>PENDING APPROVAL</Text>
                </TouchableOpacity>
            );
        case 'A':
            return (
                <TouchableOpacity
                    style={s.listItemWrapper}
                    onPress={() => {
                        navigation.navigate(
                            'ItemRequest',
                            {},
                            NavigationActions.navigate({
                                routeName: 'ItemRequestItemsRequested',
                            }),
                        );
                    }}
                    key={item.id}
                >
                    <Text style={s.listItemDateText}>{item.title}</Text>
                    <Text style={s.listItemComments}>
                        {item.date}
                        <Text>{`${item.quantity} items`}</Text>
                    </Text>
                    <Text style={s.listItemStatusApproved}>APPROVED</Text>
                </TouchableOpacity>
            );
        case 'D':
            return (
                <TouchableOpacity
                    style={s.listItemWrapperDenied}
                    onPress={() => {
                        navigation.navigate(
                            'ItemRequest',
                            {},
                            NavigationActions.navigate({
                                routeName: 'ItemRequestItemsRequested',
                            }),
                        );
                    }}
                    key={item.id}
                >
                    <Text style={s.listItemDateText}>{item.title}</Text>
                    <Text style={s.listItemComments}>
                        {item.date}
                        <Text>{`${item.quantity} items`}</Text>
                    </Text>
                    <Text style={s.listItemStatusDenied}>DENIED</Text>
                    <Text style={s.listItemComments}>Admin feedback</Text>
                </TouchableOpacity>
            );
        case 'I':
            return (
                <View style={s.listItemWrapperItem} key={item.id}>
                    <Text style={s.listItemDateText}>{item.title}</Text>
                    <Text style={s.listItemComments}>{item.quantity}</Text>
                </View>
            );
        default:
            return null;
    }
};

ListItem.propTypes = {
    navigation: PropTypes.object,
    item: PropTypes.object,
};

export default ListItem;
