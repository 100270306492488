import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, View, ScrollView } from 'react-native';

import { changeScreen } from '../../../store/modules/howTo';
import { NavigationBar } from '../../../components';
import s from './styles';

class HowToDetailsScreen extends Component {
    static propTypes = {
        item: PropTypes.array,
        title: PropTypes.string,
        changeScreen: PropTypes.func,
    };

    _returnItems() {
        const { item } = this.props;
        const items = [];
        for (let i = 0; i < item.length; i++) {
            if (item[i].type === 'steps') {
                items.push(
                    <Text key={i + 1} style={s.title}>
                        {item[i].title}
                    </Text>,
                );
                for (let x = 0; x < item[i].steps.length; x++) {
                    items.push(
                        <Text key={(i + 1) * 100 + x} style={s.listText}>
                            {`\u2022 ${item[i].steps[x]}`}
                        </Text>,
                    );
                }
                if (i !== item.length - 1) {
                    items.push(<View key={(i + 1) * 1000} style={s.underLine} />);
                }
            }
        }
        return items;
    }

    render() {
        const { changeScreen, title } = this.props;
        return (
            <View style={s.wrapper}>
                <NavigationBar
                    title={title}
                    backIcon
                    backIconFunction={() => {
                        changeScreen('dashboard');
                    }}
                    {...this.props}
                />
                <ScrollView>
                    <View style={s.mainContainer}>{this._returnItems()}</View>
                </ScrollView>
            </View>
        );
    }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
    changeScreen,
})(HowToDetailsScreen);
