import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        paddingBottom: 140,
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        paddingHorizontal: '5%',
    },
    listItemWrapper: {
        width: '100%',
        height: 90,
        marginBottom: 10,
        backgroundColor: Color.white,
        borderRadius: 6,
        elevation: 5,
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    listItemDateText: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue,
        textAlign: 'left',
        paddingLeft: 18.5,
        marginTop: 14,
    },
    listItemStatusPending: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font9,
        color: Color.light_grey_blue,
        letterSpacing: 0.41,
        textAlign: 'left',
        paddingLeft: 18.5,
        marginTop: 12,
    },
    listItemStatusClosed: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font9,
        color: Color.turquoise,
        letterSpacing: 0.41,
        textAlign: 'left',
        paddingLeft: 18.5,
        marginTop: 12,
    },
    listItemStatusUnsaved: {
        fontFamily: Font.type.bold,
        color: Color.faded_orange,
        fontSize: Font.size.font9,
        marginRight: 18.5,
        fontWeight: 'bold',
        marginTop: 12,
    },
    listItemComments: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font9,
        color: Color.light_grey_blue,
        letterSpacing: 0.023,
        paddingLeft: 18.5,
        marginTop: 9,
    },
    bottomButtonsBlock: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        position: 'absolute',
        bottom: -55,
        elevation: 12,
    },
    addButton: {
        width: 43,
        height: 43,
        borderRadius: 22,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.turquoise,
        elevation: 12,
        shadowColor: Color.light_grey_blue,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.5,
        shadowRadius: 5,
    },
});
