import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { number, func, array } from 'prop-types';

import s from './styles';

const TabsMenu = ({ activeTab, onTabPress, tabs }) => {
    let menuItemsClass;
    switch (tabs.length) {
        case 4:
            menuItemsClass = s.menuItem_4;
            break;
        case 3:
            menuItemsClass = s.menuItem_3;
            break;
        case 2:
            menuItemsClass = s.menuItem_2;
            break;
        default:
            menuItemsClass = s.menuItem_1;
            break;
    }
    return (
        <View style={s.menuContainer}>
            {tabs.map((tab, i) => {
                return (
                    <TouchableOpacity key={i} onPress={() => onTabPress(tab.id)} style={[s.menuItem, menuItemsClass]}>
                        <Text style={[s.menuitemText, activeTab === tab.id && s.menuitemTextActive]}>{tab.title}</Text>
                        {activeTab === tab.id && <View style={s.menuActiveItemSlash} />}
                    </TouchableOpacity>
                );
            })}
        </View>
    );
};

TabsMenu.propTypes = {
    activeTab: number,
    onTabPress: func,
    tabs: array,
};

export default TabsMenu;
