import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, Modal, ScrollView, Text, View, FlatList } from 'react-native';
// import Dash from 'react-native-dash';
import { connect } from 'react-redux';
import NetInfo from '@react-native-community/netinfo';
import { cloneDeep } from 'lodash';
import { DropdownList, FieldInput, NavigationBar, PrimaryButton, YesNoAnswer } from '../../../components/index';
import { getUsers } from '../../../store/modules/account';
import { saveInjuryTicket, finishEditing } from '../../../store/modules/injuryReporting';
import { Color } from '../../../theme/index';
import s from './styles';

class InjuryReportingItemScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentTicket: PropTypes.object,
        user: PropTypes.object,
        currentTimeCard: PropTypes.object,
        isLoading: PropTypes.bool,
        projects: PropTypes.array,
        injuryTicketTypes: PropTypes.array,
        users: PropTypes.array,
        getUsers: PropTypes.func,
        saveInjuryTicket: PropTypes.func,
        finishEditing: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { currentTicket } = props;
        this.state = {
            injuryType: this.setInjuryType(currentTicket.InjuryType),
            answers: currentTicket.Answers,
            isUpdated: false,
            users: [
                { id: 0, title: 'Your Self' },
                { id: 1, title: 'Other' },
            ],
            whoCreatedTheTicket: currentTicket.WhoSawIt ? { id: 1, title: 'Other' } : { id: 0, title: 'Your Self' },
            whoCreatedTheTicketName: this.getUser(currentTicket.WhoSawIt),
        };
    }

    componentDidMount() {
        const { getUsers } = this.props;
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                getUsers();
            }
        });
    }

    returnProjectName(project) {
        const { currentTicket, projects } = this.props;
        if (!currentTicket.Project) {
            const foundProjects = projects.filter((p) => p.id === project);
            return foundProjects[0].ProjectName;
        }
        return '';
    }

    setInjuryType(id) {
        const { injuryTicketTypes } = this.props;
        let injuryType = null;
        injuryTicketTypes.forEach((ticketType) => {
            if (ticketType.id === id) {
                injuryType = { id: ticketType.id, title: ticketType.Type };
            }
        });
        return injuryType;
    }

    returnInjuryTypes() {
        const { injuryTicketTypes } = this.props;
        return injuryTicketTypes.map((item) => {
            return {
                id: item.id,
                title: item.Type,
            };
        });
    }

    setUserWhoCreatedTheTicket(type) {
        if (type.title === 'Your Self') {
            this.setState({ whoCreatedTheTicketName: null });
        }
        this.setState({ whoCreatedTheTicket: type });
    }

    setUserWhoCreatedName(type) {
        this.setState({ whoCreatedTheTicketName: type });
    }

    setInjuryTypeQuestions(type) {
        const { injuryTicketTypes } = this.props;
        const answers = [];

        injuryTicketTypes.forEach((ticketType) => {
            if (ticketType.id === type.id) {
                for (let i = 0; i < ticketType.Questions.length; i++) {
                    answers.push({
                        Question: ticketType.Questions[i],
                        Answer: 'N/A',
                        Description: '',
                    });
                }
            }
        });
        this.setState({ injuryType: type, answers });
    }

    updateAnswer(data = {}) {
        const { item, value, description } = data;
        const { answers } = this.state;

        const newAnswers = cloneDeep(answers);
        const newItem = {
            Question: item.Question,
            Answer: value || item.value,
            Description: description || item.description,
        };

        newAnswers.forEach((a, i) => {
            if (a.Question === newItem.Question) {
                newAnswers[i] = newItem;
            }
        });

        this.setState({
            answers: newAnswers,
            isUpdated: true,
        });
    }

    _returnQuestion(item) {
        return (
            <View style={s.surveyItem}>
                <Text style={s.itemTitle}>{item.Question}</Text>
                <View style={s.itemRow}>
                    <YesNoAnswer answer={item.Answer} onChange={(value) => this.updateAnswer({ item, value })} />
                </View>
                <FieldInput
                    multiline
                    title="PLEASE DESCRIBE"
                    input={{
                        onChange: (description) => {
                            this.updateAnswer({ item, description });
                        },
                    }}
                    meta={{
                        error: null,
                        touched: false,
                    }}
                    initialValues={item.Description}
                />
            </View>
        );
    }

    validate() {
        return false;
    }

    getUser(id) {
        const { users } = this.props;
        let userWhoCreatedTheTicket = null;
        users.forEach((user) => {
            if (user._id === id) {
                userWhoCreatedTheTicket = {
                    id: user._id,
                    title: `${user.name.first} ${user.name.last}`,
                };
            }
        });
        return userWhoCreatedTheTicket;
    }

    renderUserList() {
        const { users } = this.props;
        const { whoCreatedTheTicket, whoCreatedTheTicketName } = this.state;

        const userList = users.map((user) => {
            return {
                id: user._id,
                title: `${user.name.first} ${user.name.last}`,
            };
        });
        if (whoCreatedTheTicket.title === 'Other') {
            return (
                <DropdownList
                    items={userList}
                    containerStyle={s.materialDropdown}
                    selectedItem={whoCreatedTheTicketName}
                    onItemSelect={(item) => this.setUserWhoCreatedName(item)}
                    title="USER WHO WAS INJURED"
                    withIds
                />
            );
        }
        return null;
    }

    onSave = () => {
        const { injuryType, answers, whoCreatedTheTicketName } = this.state;
        const { currentTicket, saveInjuryTicket, user, currentTimeCard } = this.props;
        const whoCreatedIt = whoCreatedTheTicketName ? whoCreatedTheTicketName.id : null;
        const ticket = {
            ...currentTicket,
            ...{
                InjuryType: injuryType.id,
                WhoSawIt: whoCreatedIt,
                Answers: answers,
                Project: currentTicket.Project ? currentTicket.Project : currentTimeCard.currentProject.id,
            },
        };
        saveInjuryTicket({ ticket, user });
        this.onBack();
    };

    onBack = () => {
        const { finishEditing, navigation } = this.props;
        finishEditing();
        navigation.goBack();
    };

    render() {
        const { isLoading } = this.props;
        const { users, injuryType, answers, isUpdated, whoCreatedTheTicket } = this.state;

        const isValid = this.validate();

        const backConfirmText =
            // eslint-disable-next-line max-len
            'You are about to back out of your injury reporting. All not saved data will be lost. Are you sure you want to do this ?';
        const backTitle = 'Warning';

        return (
            <ScrollView style={[s.wrapper]}>
                <NavigationBar
                    {...this.props}
                    title="INJURY REPORT"
                    backIcon
                    backConfirm={{
                        title: backTitle,
                        text: backConfirmText,
                    }}
                    backIconFunction={this.onBack}
                />
                <View style={[s.mainContainer]}>
                    <DropdownList
                        items={this.returnInjuryTypes()}
                        containerStyle={s.materialDropdown}
                        selectedItem={injuryType}
                        onItemSelect={(item) => this.setInjuryTypeQuestions(item)}
                        title="INJURY TYPE"
                        withIds
                    />
                    <DropdownList
                        items={users}
                        containerStyle={s.materialDropdown}
                        selectedItem={whoCreatedTheTicket}
                        onItemSelect={(item) => this.setUserWhoCreatedTheTicket(item)}
                        title="WHO WAS INJURED"
                        withIds
                    />
                    {this.renderUserList()}
                    <FlatList
                        data={answers}
                        contentContainerStyle={{ paddingTop: 10, paddingBottom: 7.5, }}
                        keyExtractor={(item) => item.id}
                        style={{ elevation: 20, width: '100%', flexGrow: 0, }}
                        renderItem={({ item }) => this._returnQuestion(item)}
                        ItemSeparatorComponent={() => {
                            return (
                                null
                            );
                        }}
                    />
                    <View style={s.buttonWrapper}>
                        <PrimaryButton title="SAVE" onPress={this.onSave} disabled={isValid && isUpdated} />
                    </View>
                </View>
                <Modal visible={isLoading} transparent>
                    <View style={s.loadingModalWrapper}>
                        <ActivityIndicator color={Color.white} size={40} />
                    </View>
                </Modal>
            </ScrollView>
        );
    }
}

const mapStateToProps = ({ injuryReporting, account, currentTimeCard }) => ({
    user: account.user,
    users: account.users,
    currentTicket: injuryReporting.currentTicket,
    injuryTicketTypes: injuryReporting.injuryTicketTypes,
    isLoading: injuryReporting.isLoading,
    currentTimeCard,
    injuryTickets: injuryReporting.injuryTickets,
});

export default connect(mapStateToProps, {
    saveInjuryTicket,
    finishEditing,
    getUsers,
})(InjuryReportingItemScreen);
