import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    mainContainer: {
        height: '100%',
    },
    forgotPasswordTitle: {
        paddingHorizontal: '10%',
        color: Color.dark_navy_blue,
        fontSize: Font.size.font15,
        letterSpacing: 0.68,
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 40,
        fontFamily: Font.type.regular,
    },
});
