import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { showMessage } from 'react-native-flash-message';
import NetInfo from '@react-native-community/netinfo';
import { Color } from '../theme';
// import RNFetchBlob from 'rn-fetch-blob';
import mime from 'mime-types';

export function convertTimeStringToSeconds(str) {
    let hoursToSec = 0;
    let minToSec = 0;
    let sec = 0;
    if (!str == null && !str.isEmpty()) {
        const hourPosition = str.indexOf('h', 0);
        const minPosition = str.indexOf('m', 0);
        if (str.includes('m') && str.includes('h')) {
            hoursToSec = str.substring(0, hourPosition) * 3600;
            minToSec = str.substring(hourPosition + 1, minPosition) * 60;
            sec += hoursToSec + minToSec;
        } else if (!str.includes('m') && str.includes('h')) {
            hoursToSec = str.substring(0, hourPosition) * 3600;
            sec += hoursToSec;
        } else {
            minToSec = str.substring(0, minPosition) * 60;
            sec += minToSec;
        }
    }
    return sec;
}

export function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
}

export function formatTimeFromSeconds(seconds, includeTotalSeconds = true) {
    let totalHours = Math.trunc(seconds / 3600);
    let totalMinutes = Math.trunc(seconds / 60) - totalHours * 60;
    let totalSeconds = seconds - totalHours * 3600 - totalMinutes * 60;

    totalHours = totalHours < 10 ? `0${totalHours}` : totalHours;
    totalMinutes = totalMinutes < 10 ? `0${totalMinutes}` : totalMinutes;
    totalSeconds = totalSeconds < 10 ? `0${totalSeconds}` : totalSeconds;

    if (includeTotalSeconds) {
        return `${totalHours}:${totalMinutes}:${totalSeconds}`;
    }

    return `${totalHours}:${totalMinutes}`;
}

export function checkArray(arr1, arr2) {
    // Just need to check for a change in time.(No Change in amount of breaks, putting a user on a break)
    for (let i = arr1.length; i--; ) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
}

export function generateId() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export async function getItemFromStorage(reducerName, fieldName) {
    const storage = await AsyncStorage.getItem('persist:root');
    const storageData = JSON.parse(storage);
    const reducerData = JSON.parse(storageData[reducerName]);

    if (fieldName) {
        return reducerData[fieldName];
    }

    return reducerData;
}

export async function saveItemToStorage(reducerName, fieldName, data) {
    const storage = await AsyncStorage.getItem('persist:root');
    const storageData = JSON.parse(storage);
    const reducerData = JSON.parse(storageData[reducerName]);

    if (fieldName) {
        reducerData[fieldName] = data;
        storageData[reducerName] = JSON.stringify(reducerData);
    } else {
        storageData[reducerName] = JSON.stringify(data);
    }

    const updatedStorageData = JSON.stringify(storageData);

    await AsyncStorage.setItem('persist:root', updatedStorageData);
}

export async function downloadImage(attachment) {
    const newImgUri = attachment.lastIndexOf('/');
    const imageName = attachment.substring(newImgUri);
    // const { dirs } = RNFetchBlob.fs;
    // const path = Platform.OS === 'ios' ? dirs.MainBundleDir + imageName : dirs.PictureDir + imageName;
    let image = null;
    let mimeType = mime.lookup(imageName);
    if (!mimeType) {
        mimeType = 'image/jpeg';
    }

    /*
    await RNFetchBlob.config({
        timeout: 20000,
        fileCache: true,
        appendExt: 'png',
        indicator: true,
        IOSBackgroundTask: true,
        path,
        addAndroidDownloads: {
            useDownloadManager: true,
            notification: true,
            path,
            description: 'Image',
            mime: mimeType,
        },
    })
        .fetch('GET', attachment)
        .then(async (res) => {
            image = { base64Image: await res.base64(), path, type: mimeType };
        })
        .catch((err) => {
            console.log(err);
            showMessage({
                message: 'Error',
                description: 'Not able to download image',
                type: 'danger',
                duration: 10000,
                animationDuration: 700,
                hideOnPress: true,
                hideStatusBar: true,
                backgroundColor: Color.red,
            });
        });

    */
    return image;
}

export async function checkIpLock(
    settings,
    errorMessage = 'Login Error',
    errorDesc = 'Login is not allowed for this IP address',
) {
    let isIpCorrect = true;
    if (settings && settings.generalRules && settings.generalRules.allowedIpAddresses.length) {
        try {
            const res = await axios.get('https://ipv4.icanhazip.com');
            const { data } = res;
            isIpCorrect = false;
            for (const ip of settings.generalRules.allowedIpAddresses) {
                if (data.trim() === ip) {
                    isIpCorrect = true;
                    break;
                }
            }
        } catch (error) {
            isIpCorrect = false;
        }
    }

    if (!isIpCorrect) {
        showMessage({
            message: errorMessage,
            description: errorDesc,
            type: 'danger',
            duration: 5000,
            animationDuration: 700,
            hideOnPress: true,
            hideStatusBar: true,
            backgroundColor: Color.red,
        });
    }

    return isIpCorrect;
}

export function showErrorMessage(message, description, duration = 5000) {
    showMessage({
        message,
        description,
        type: 'danger',
        duration,
        animationDuration: 700,
        hideOnPress: true,
        hideStatusBar: true,
        backgroundColor: Color.red,
    });
}

export function showSuccessMessage(message, description, duration = 3000) {
    showMessage({
        message,
        description,
        type: 'success',
        duration,
        animationDuration: 700,
        hideOnPress: true,
        hideStatusBar: true,
        backgroundColor: Color.turquoise,
    });
}

export async function checkConnectivity(message = null, description = null, showMessage = true) {
    const state = await NetInfo.fetch();
    if (!state.isConnected && showMessage) {
        showErrorMessage(message || 'No Internet Connection', description || 'Please check your internet connection');
    }
    return state.isConnected;
}

export function getStatusText(status) {
    switch (status) {
        case 'A':
            return 'APPROVED';

        case 'D':
            return 'DENIED';

        case 'U':
            return 'PENDING APPROVAL';

        default:
            return '';
    }
}

export function roundNumber(value, scale = 2) {
    if (!('' + value).includes('e')) {
        return +(Math.round(value + 'e+' + scale) + 'e-' + scale);
    }

    const arr = ('' + value).split('e');
    let sig = '';
    if (+arr[1] + scale > 0) {
        sig = '+';
    }

    return +(Math.round(+arr[0] + 'e' + sig + (+arr[1] + scale)) + 'e-' + scale);
}
