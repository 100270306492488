import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, View, FlatList } from 'react-native';
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {
    createCustomReportItem,
    getCustomers,
    getCustomReports,
    getCompletedCustomReports,
} from '../../../store/modules/customReports';
import { NavigationBar } from '../../../components/index';
import s from './styles';
import { Color } from '../../../theme/index';
import ListItem from './ListItem';

class CustomReportsListScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        user: PropTypes.object,
        createCustomReportItem: PropTypes.func,
        getCustomers: PropTypes.func,
        getCustomReports: PropTypes.func,
        customers: PropTypes.array,
        getCompletedCustomReports: PropTypes.func,
        completedCustomReportItems: PropTypes.array,
        customReports: PropTypes.array,
    };

    state = {};

    componentDidMount() {
        const { getCustomers, getCustomReports, getCompletedCustomReports } = this.props;
        getCompletedCustomReports();
        getCustomers();
        getCustomReports();
    }

    createCustomReport = () => {
        const { createCustomReportItem, navigation, user, getCustomReports } = this.props;
        getCustomReports();
        createCustomReportItem({ user });
        navigation.navigate('CustomReportReports');
    };

    getReports = () => {
        const { completedCustomReportItems, navigation, createCustomReportItem } = this.props;
        const data = completedCustomReportItems.map((u) => {
            return {
                Questions: u.Questions,
                CustomReportName: u.CustomReportName,
                user: u.user,
                id: u.id,
                status: u.status,
                unsaved: u.unsaved,
                onPress: () => {
                    const data = {
                        user: u.user,
                        report: u,
                        id: u.id,
                    };
                    createCustomReportItem(data);
                    navigation.navigate('CustomReport');
                },
            };
        });
        return data;
    };

    render() {
        const { customReports } = this.props;
        const reports = this.getReports();
        return (
            <View style={[s.wrapper]}>
                <NavigationBar {...this.props} title="DASHBOARD" menuIcon />
                <View style={[s.mainContainer]}>
                    <FlatList
                        data={reports}
                        keyExtractor={(item, i) => (item ? item.id : i)}
                        onRefresh={this.onRefresh}
                        style={{ width: '100%', height: '100%', }}
                        contentContainerStyle={{ paddingTop: 20, }}
                        renderItem={({ item, i }) => {
                            return <ListItem item={item} i={i} isLast={item.id === reports.length - 1} />;
                        }}
                    />
                    {customReports.length > 0 ? (
                        <View style={s.bottomButtonsBlock}>
                            <TouchableOpacity style={s.addButton} onPress={this.createCustomReport}>
                                <Icon name="plus" color={Color.white} size={30} />
                            </TouchableOpacity>
                        </View>
                    ) : null}
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ account, customReports }) => ({
    user: account.user,
    customers: customReports.customers,
    completedCustomReportItems: customReports.completedCustomReportItems,
    customReports: customReports.customReports,
});

export default connect(mapStateToProps, {
    getCompletedCustomReports,
    createCustomReportItem,
    getCustomers,
    getCustomReports,
})(CustomReportsListScreen);
