import { StyleSheet } from 'react-native';
import { Font, Color } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        alignItems: 'center',
        paddingHorizontal: '5%',
    },
    buttonsWrapper: {
        width: '100%',
        flexDirection: 'row',
        paddingTop: 28,
        justifyContent: 'space-between',
    },
    saveButtonWrapper: {
        width: '47%',
    },
    listWrapper: {
        paddingTop: 20,
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    menuTopContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    topMenu_title: {
        color: Color.blue,
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
    },
    modalLoading: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.white_50,
    },
    workOrderNumber: {
        width: '90%',
        paddingTop: '5%',
    },
});
