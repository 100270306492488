import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        overflow: 'scroll',
        paddingHorizontal: '5%',
        paddingTop: 20,
        paddingBottom: 100,
    },
    SquareShapeView: {
        paddingBottom: 15,
        borderRadius: 5,
        width: '100%',
        backgroundColor: '#ffffff',
        alignSelf: 'center',
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    commonKeyProperties: {
        marginTop: 15,
        marginLeft: 14.5,
        letterSpacing: 0.5,
        marginRight: 0,
        color: Color.red,
    },
    commonBreak: {
        marginTop: 15,
        marginLeft: 14.5,
        letterSpacing: 0.5,
        marginRight: 0,
        fontSize: Font.size.font9,
    },
    commonLabels: {
        fontSize: Font.size.font11,
        color: Color.light_grey_blue,
        fontFamily: Font.type.regular,
    },
    title: {
        color: Color.grey,
        fontSize: Font.size.font10,
        fontFamily: Font.type.regular,
    },
    text: {
        color: Color.blue,
        fontSize: Font.size.font13,
        fontFamily: Font.type.regular,
    },
});
