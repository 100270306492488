import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NetInfo from '@react-native-community/netinfo';
import { FlatList, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { showMessage } from 'react-native-flash-message';
import { isEmpty } from 'lodash';
import { selectCurrentTimeAndMaterial } from '../../../store/modules/currentTimeCard';
import {
    getTimeAndMaterial,
    timeAndMaterialFinishLoading,
    timeAndMaterialLoading,
} from '../../../store/modules/timeAndMaterial';
import { BottomGradient, ListItem, NavigationBar } from '../../../components';
import s from './styles';
import { Color } from '../../../theme'

class TimeAndMaterialItemClockInScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        project: PropTypes.object,
        timeAndMaterials: PropTypes.array,
        isSwitch: PropTypes.bool,
        isLoading: PropTypes.bool,
        selectCurrentTimeAndMaterial: PropTypes.func,
        getTimeAndMaterial: PropTypes.func,
        timeAndMaterialFinishLoading: PropTypes.func,
        timeAndMaterialLoading: PropTypes.func,
    };

    state = {};

    componentDidMount() {
        const { project } = this.props;
        if (!isEmpty(project)) {
            this.reloadMaterials();
        }
    }

    componentDidUpdate(prevProps) {
        const { project, isSwitch } = this.props;
        if (
            (!isSwitch && !prevProps.project && project) ||
            (!isSwitch && prevProps.project && project && prevProps.project.id !== project.id)
        ) {
            this.reloadMaterials(isSwitch);
        }
    }

    _returnTimeAndMaterial() {
        const { timeAndMaterials, isLoading, isSwitch } = this.props;
        const items = timeAndMaterials.map((tam) => {
            return {
                id: tam.sourceId,
                title: tam.Title,
                isDisabled: isLoading,
                onPress: () => {
                    this.selectCurrentTAM(tam, isSwitch);
                },
            };
        });
        return items;
    }

    selectCurrentTAM = (item) => {
        const { selectCurrentTimeAndMaterial, navigation, isSwitch } = this.props;
        selectCurrentTimeAndMaterial(item, isSwitch);
        navigation.navigate('CostCode', { isTimeCardEdit: false });
    };

    reloadMaterials = () => {
        const { getTimeAndMaterial, project, timeAndMaterialLoading, timeAndMaterialFinishLoading } = this.props;
        timeAndMaterialLoading();
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                getTimeAndMaterial(project.id).then(() => {
                    timeAndMaterialFinishLoading();
                });
            } else {
                timeAndMaterialFinishLoading();
                showMessage({
                    message: 'Connection Failure',
                    description: 'We are unable to connect. Please make sure that you have a data connection.',
                    type: 'danger',
                    duration: 5000,
                    animationDuration: 700,
                    // autoHide: false,
                    hideOnPress: true,
                    hideStatusBar: true,
                    backgroundColor: Color.red,
                });
            }
        });
    };

    render() {
        const { isLoading } = this.props;
        const TAM = this._returnTimeAndMaterial();
        return (
            <View style={s.wrapper}>
                <NavigationBar {...this.props} title="TIME AND MATERIALS" backIcon />
                <BottomGradient />
                <View style={[s.mainContainer]}>
                    {TAM.length === 0 && <Text style={s.noItems}> NO TIME AND MATERIAL TICKETS FOR THIS PROJECT</Text>}
                    <FlatList
                        ListHeaderComponent={TAM.length > 0 && <Text style={s.listTitle}>Time and Material</Text>}
                        data={TAM}
                        refreshing={isLoading}
                        onRefresh={this.reloadMaterials}
                        keyExtractor={(item) => item.id}
                        style={{
                            width: '100%',
                            paddingBottom: 100,
                        }}
                        renderItem={({ item }) => {
                            return <ListItem item={item} />;
                        }}
                        ListFooterComponent={<View style={s.bottomPadding} />}
                    />
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ currentTimeCard, timeAndMaterials }) => ({
    project: currentTimeCard.currentProject,
    timeAndMaterials: timeAndMaterials.timeAndMaterials,
    currentTimeCardTest: currentTimeCard,
    isLoading: timeAndMaterials.isLoading,
    isSwitch: currentTimeCard.isSwitch,
});

export default connect(mapStateToProps, {
    getTimeAndMaterial,
    selectCurrentTimeAndMaterial,
    timeAndMaterialLoading,
    timeAndMaterialFinishLoading,
})(TimeAndMaterialItemClockInScreen);
