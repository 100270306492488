export default {
    GET_TYPES: {
        LOADING: 'GET_TOOLBOX_TALKS_TYPES',
        SUCCESS: 'GET_TOOLBOX_TALKS_TYPES_SUCCESS',
        ERROR: 'GET_TOOLBOX_TALKS_TYPES_ERROR',
    },
    GET_TICKETS: {
        LOADING: 'GET_TOOLBOX_TALKS_TICKETS',
        SUCCESS: 'GET_TOOLBOX_TALKS_TICKETS_SUCCESS',
        ERROR: 'GET_TOOLBOX_TALKS_TICKETS_ERROR',
    },
    CREATE_TICKET: 'CREATE_TOOLBOX_TALKS_TICKET',
    SET_TICKET: 'SET_TOOLBOX_TALKS_TICKET',
    FINISH_EDITING: 'TOOLBOX_TALKS_TICKET_FINISH_EDITING',
    SAVE_STEP_TWO: 'SAVE_STEP_TWO',
    SUBMIT_TICKET: {
        LOADING: 'SUBMIT_TOOLBOX_TALKS_TICKET',
        SUCCESS: 'SUBMIT_TOOLBOX_TALKS_TICKET_SUCCESS',
        ERROR: 'SUBMIT_TOOLBOX_TALKS_TICKET_ERROR',
    },
    SUBMIT_SIGNED_TICKET: {
        LOADING: 'SUBMIT_SIGNED_TICKET',
        SUCCESS: 'SUBMIT_SIGNED_TICKET_SUCCESS',
        ERROR: 'SUBMIT_SIGNED_TICKET_ERROR',
    },
    REMOVE_ATTACHMENT: 'REMOVE_TOOLBOX_TALKS_ATTACHMENT',
    SAVE_ATTACHMENT: 'SAVE_TOOLBOX_TALKS_ATTACHMENT',
    GET_SIGNED: 'GET_SIGNED',
};
