export default {
    blue: 'rgb(0, 45, 80)',
    cloudy_blue: 'rgba(0, 45, 80, 0.6)',
    light_grey_blue: 'rgba(0, 45, 80, 0.6)',
    turquoise: '#4a8b40',
    grey: 'rgb(138, 141, 142)',
    pale_grey: 'rgb(170, 175, 176)',
    white: '#ffffff',
    faded_orange: '#f6a044',
    pale_lilac: '#ebebef',
    red: '#ff0000',
    pinkish_orange: '#ef775b',

    white_two: '#fefefe',
    pale_grey_two: '#f6f7fa',
    pale_grey_three: '#edeff5',
    light_grey_blue_05: 'rgba(173, 174, 180, 0.7)',
    light_blue_grey: '#c1c8d0',
    light_periwinkle: '#e3e5ea',
    light_grey: '#ebeef3',
    brown_grey: '#a8a8a8',
    very_light_blue: '#e8edf4',
    white_50: 'rgba(255, 255, 255, 0.5)',
    light_navy: '#195190',
    light_navy_45: 'rgba(25, 81, 144, 0.45)',
    light_navy_20: 'rgba(25, 81, 144, 0.2)',
    light_navy_10: 'rgba(25, 81, 144, 0.1)',
    light_navy_05: 'rgba(25, 81, 144, 0.05)',
    middle_navy: '#144174',
    cobalt_50: 'rgba(26,63,152, 0.5)',
    dark_navy_blue: '#00000f',
    dark_navy_blue_50: 'rgba(0, 0, 15, 0.5)',
    dark_navy_blue_30: 'rgba(0, 0, 15, 0.3)',
    purple_brown: '#201d1e',
    transparent: 'transparent',
    light_blue_3: '#EBF7F8',
    orange: '#fc7415',
};
