import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    listItemWidth: {
        width: '100%',
    },
    listItemWrapper: {
        paddingHorizontal: 10,
        paddingVertical: 10,
        marginBottom: 10,
        backgroundColor: Color.white,
        borderRadius: 8,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        elevation: 5,
        alignSelf: 'center',
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        minHeight: 60,
        width: '100%',
    },
    fieldLabel: {
        fontFamily: Font.type.regular,
        color: Color.grey,
        fontSize: Font.size.font10,
        alignSelf: 'center',
        position: 'absolute',
        top: 6,
        left: 11,
    },
    listItemWrapper_timeCard: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
    },
    listItemWrapper_radio: {
        borderRadius: 0,
        marginBottom: 0,
        elevation: 0,
        width: '100%',
    },
    listItemWrapper_editScreen: {
        width: '100%',
    },
    iconWrapper: {
        width: 30,
        height: 30,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.light_navy_05,
        marginRight: 10,
        marginLeft: -5,
    },
    userWrapper: {
        flexDirection: 'row',
    },
    listItemIcon: {
        color: Color.turquoise,
        alignSelf: 'center',
    },
    listItemTitle: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        textTransform: 'capitalize',
        color: Color.dark_navy_blue,
        flex: 1,
        width: '80%',
        alignSelf: 'center',
    },
    ListItemSelf: {
        backgroundColor: Color.light_navy,
        color: Color.white,
    },
    listItemTitle_timeCard: {
        marginTop: 6,
    },
    listItemTitle_editScreen: {
        color: Color.blue,
    },
    listItemColumn: {
        flexDirection: 'row',
        alignItems: 'space-between',
        justifyContent: 'center',
        width: '100%',
    },
    listItemColumnLeft: {
        width: '90%',
    },
    listItemColumnRight: {
        justifyContent: 'flex-end',
        width: '10%',
    },
    listItemColumn_timeCard: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    listItemColumn_status: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
    },
    listItemColumnTM_status: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
    },
    labelRequired: {
        color: Color.turquoise,
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
        marginTop: 1,
        marginRight: 15,
        alignSelf: 'center',
    },
    labelQuantity: {
        color: Color.turquoise,
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
        alignSelf: 'center',
    },
    chevronRight: {
        color: Color.blue,
        alignSelf: 'center',
    },
    chevronRight_timeCard: {
        marginTop: 4,
        // marginBottom: -10,
    },
    chevronRight_User: {
        marginTop: 5,
    },
    listItemTimeCardHeader: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 5,
        height: 15,
    },
    cardTimes: {
        fontFamily: Font.type.regular,
        color: Color.grey,
        fontSize: Font.size.font10,
    },
    workOrderNumber: {
        fontFamily: Font.type.regular,
        color: Color.dark_navy_blue,
        fontSize: Font.size.font11,
    },
    cardTotalTime: {
        fontFamily: Font.type.regular,
        color: Color.blue,
        fontSize: Font.size.font11,
    },
    cardTotalBreakTime: {
        fontFamily: Font.type.regular,
        color: Color.light_navy,
        fontSize: Font.size.font11,
    },
    roundBlock: {
        width: 20,
        height: 20,
        borderRadius: 10,
        backgroundColor: Color.turquoise,
        justifyContent: 'space-between',
        alignItems: 'center',
        marginRight: 10,
    },
    checkIcon: {
        fontSize: Font.size.font16,
        paddingTop: 2,
    },
    roundBlockDisabled: {
        backgroundColor: Color.light_navy_05,
    },
    cardTypeWrapper: {
        flex: 1,
        flexWrap: 'nowrap',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    cardType: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: Color.turquoise,
        height: 17,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 8,
        marginTop: 12,
    },
    cardTypeText: {
        color: Color.turquoise,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font8,
    },
    cardTypeOffline: {
        borderColor: '#ffecb2',
        backgroundColor: '#fefbef',
        marginLeft: 10,
    },
    cardTypeOfflineText: {
        color: '#f3a34d',
        fontFamily: Font.type.regular,
        fontSize: Font.size.font8,
    },
    cardTypeNotValid: {
        borderColor: '#FFB2B2',
        backgroundColor: '#FCEFEF',
        marginLeft: 10,
    },
    cardTypeNotValidText: {
        color: '#F24D4D',
        fontFamily: Font.type.regular,
        fontSize: Font.size.font8,
    },
    cardTypeTimeChanged: {
        marginTop: 0,
        borderColor: Color.blue,
        marginLeft: 10,
    },
    cardTypeTimeChangedText: {
        fontSize: Font.size.font8,
        color: Color.blue,
        fontFamily: Font.type.regular,
        justifyContent: 'center',
        alignItems: 'center',
    },
    statusText: {
        fontSize: Font.size.font9,
        color: Color.faded_orange,
        fontFamily: Font.type.bold,
    },
    weatherButton: {
        width: 120,
        height: 18,
        opacity: 0.25,
        marginTop: 10,
        paddingRight: 40,
    },
    weatherButtonImage: {
        width: 120,
        height: 18,
    },
    userPhoto: {
        width: 30,
        height: 30,
        borderRadius: 400 / 2,
    },
    timeCardEditsLabel: {
        color: Color.grey,
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
        backgroundColor: Color.white,
        marginRight: 5,
        paddingRight: 5,
    },
});
