import React, { Component } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavigationBar, FieldInput, DropdownList, PrimaryButton } from '../../../components/index';

import { submitReimbursementRequest } from '../../../store/modules/reimbursements';
import { roundNumber } from '../../../utils/helpers';
import s from './styles';

class ReimbursementScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        user: PropTypes.object,
        currentReimbursementRequest: PropTypes.object,
        reimbursementItems: PropTypes.array,
        submitReimbursementRequest: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { currentReimbursementRequest, reimbursementItems } = this.props;

        const dropdownReimbursementItems = [];
        let reimbursementItem = null;
        let selectedDropdownReimbursementItem = null;
        for (const item of reimbursementItems) {
            const dropdownReimbursementItem = { id: item.id, title: item.Name };

            dropdownReimbursementItems.push(dropdownReimbursementItem);

            if (currentReimbursementRequest && item.id === currentReimbursementRequest.Item) {
                reimbursementItem = item;
                selectedDropdownReimbursementItem = dropdownReimbursementItem;
            }
        }

        this.state = {
            dropdownReimbursementItems,
            selectedDropdownReimbursementItem,
            amount: currentReimbursementRequest ? currentReimbursementRequest.Amount.toString() : '',
            isAllowToEditPrice: reimbursementItem ? reimbursementItem.IsAllowedToEditPrice : false,
            price: currentReimbursementRequest ? currentReimbursementRequest.Price.toString() : '',
            note: currentReimbursementRequest ? currentReimbursementRequest.Note : null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { reimbursementItems, user } = this.props;
        const { selectedDropdownReimbursementItem } = this.state;

        if (
            (prevState.selectedDropdownReimbursementItem === null && selectedDropdownReimbursementItem) ||
            prevState.selectedDropdownReimbursementItem.id !== selectedDropdownReimbursementItem.id
        ) {
            const item = reimbursementItems.find((i) => i.id === selectedDropdownReimbursementItem.id);
            if (item) {
                let itemPrice = item.DefaultPrice;
                for (const userPrice of item.UsersPrices) {
                    if (user._id === userPrice.User) {
                        itemPrice = userPrice.Price;
                    }
                }

                this.setState({
                    isAllowToEditPrice: item.IsAllowedToEditPrice,
                    price: itemPrice.toString(),
                });
            }
        }
    }

    save = () => {
        const { user, currentReimbursementRequest, submitReimbursementRequest, navigation } = this.props;
        const { selectedDropdownReimbursementItem, note, price, amount } = this.state;

        const parsedPrice = Number(price);
        const parsedAmount = Number(amount);

        let request = null;
        if (currentReimbursementRequest) {
            request = {
                ...currentReimbursementRequest,
                Item: selectedDropdownReimbursementItem ? selectedDropdownReimbursementItem.id : null,
                Amount: parsedAmount,
                Price: parsedPrice,
                TotalCost: parsedAmount * parsedPrice,
                Note: note,
            };
        } else {
            request = {
                id: null,
                User: user._id,
                Item: selectedDropdownReimbursementItem ? selectedDropdownReimbursementItem.id : null,
                Amount: parsedAmount,
                Price: parsedPrice,
                TotalCost: parsedAmount * parsedPrice,
                Note: note,
                Status: 'U',
                UploadedToPayRoll: false,
            };
        }

        submitReimbursementRequest({ request, user }).then((success) => {
            if (success) {
                navigation.navigate({ routeName: 'ReimbursementList' });
            }
        });
    };

    render() {
        const { currentReimbursementRequest } = this.props;
        const {
            selectedDropdownReimbursementItem,
            amount,
            note,
            price,
            isAllowToEditPrice,
            dropdownReimbursementItems,
        } = this.state;
        return (
            <>
                <NavigationBar
                    {...this.props}
                    title={currentReimbursementRequest ? 'EDIT REQUEST' : 'ADD NEW REQUEST'}
                    backIcon
                />
                <ScrollView onScroll={this.handleScroll} style={[s.wrapper]}>
                    <View style={s.mainContainer}>
                        <View style={s.dropdownWrapper}>
                            <DropdownList
                                items={dropdownReimbursementItems}
                                containerStyle={[s.dropdownContainer]}
                                selectedItem={selectedDropdownReimbursementItem}
                                onItemSelect={(item) => {
                                    this.setState({ selectedDropdownReimbursementItem: item });
                                }}
                                title="Reimbursement"
                                withIds
                            />
                        </View>
                        <FieldInput
                            title="AMOUNT"
                            keyboardType="numeric"
                            input={{
                                onChange: (text) => {
                                    this.setState({ amount: text });
                                },
                            }}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            initialValues={amount}
                        />
                        {selectedDropdownReimbursementItem ? (
                            <>
                                <FieldInput
                                    title="PRICE"
                                    initialValues={price}
                                    editable={isAllowToEditPrice}
                                    keyboardType="numeric"
                                    input={{
                                        onChange: (text) => {
                                            this.setState({ price: text });
                                        },
                                    }}
                                    meta={{
                                        error: null,
                                        touched: false,
                                    }}
                                />
                                <View style={s.listItem}>
                                    <Text style={s.label}>COST</Text>
                                    <Text style={s.textInput}>{`$${roundNumber(amount * price)}`}</Text>
                                </View>
                                <FieldInput
                                    title="NOTES"
                                    multiline
                                    input={{
                                        onChange: (text) => {
                                            this.setState({ note: text });
                                        },
                                    }}
                                    meta={{
                                        error: null,
                                        touched: false,
                                    }}
                                    initialValues={note}
                                />
                                <View style={s.buttonWrapper}>
                                    <PrimaryButton title="SAVE" onPress={this.save} />
                                </View>
                            </>
                        ) : null}
                    </View>
                </ScrollView>
            </>
        );
    }
}

const mapStateToProps = ({ account, reimbursements }) => ({
    user: account.user,
    currentReimbursementRequest: reimbursements.currentReimbursementRequest,
    reimbursementItems: reimbursements.reimbursementItems,
});

export default connect(mapStateToProps, { submitReimbursementRequest })(ReimbursementScreen);
