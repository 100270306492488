import moment from 'moment';
import React from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import s from './styles';

const ListItem = (props) => {
    const { item } = props;
    const {  StartDate, EndDate, CheckDate } = item;
    return (
        <View onPress={item.onPress}>
            <View style={s.listItemWrapper}>
                <View style={s.listItemBody}>
                    <Text numberOfLines={1} style={s.listItemTitle}>
                        Period: {moment(StartDate).format('MM/DD/YY')} - {moment(EndDate).format('MM/DD/YY')}
                    </Text>
                    <Text numberOfLines={1} style={s.listItemTitle}>
                        Check Date : {moment(CheckDate).format('MM/DD/YY')}
                    </Text>
                </View>
            </View>
        </View>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
};

export default ListItem;
