import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    mainContainer: {
        height: '100%',
        overflow: 'scroll',
        alignItems: 'center',
        paddingBottom: 100,
    },
    buttonWrapper: {
        marginTop: 20,
        width: 180,
    },
    listWrapper: {
        paddingTop: 30,
        width: '100%',
        alignItems: 'center',
        paddingHorizontal: '5%',
    },
    listTitle: {
        fontSize: Font.size.font14,
        fontFamily: Font.type.bold,
        color: Color.blue,
        width: '100%',
        textAlign: 'left',
        marginBottom: 10,
    },
    blockWrapper: {
        backgroundColor: Color.white,
        borderRadius: 6,
        width: '100%',
        elevation: 5,
        marginBottom: 10,
    },
    listItem: {
        backgroundColor: Color.transparent,
    },
    divider: {
        width: '100%',
        height: 1,
        backgroundColor: Color.very_light_blue,
    },
    disabledBlock: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: Color.light_grey_blue_05,
        marginRight: -10,
        marginBottom: -10,
        borderRadius: 6,
    },
    weeklyOvertimeLimitLabel: {
        textAlign: 'center',
        fontSize: Font.size.font12,
        color: Color.dark_navy_blue_30,
    },
});
