import React, { Component } from 'react';
import { View, FlatList, TouchableOpacity } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {
    getReimbursementItems,
    getReimbursementRequests,
    setCurrentReimbursementRequest,
    clearCurrentReimbursementRequest,
} from '../../../store/modules/reimbursements';
import { userLogin } from '../../../store/modules/account';

import { NavigationBar } from '../../../components/index';
import ListItem from './ListItem';

import s from './styles';
import { Color } from '../../../theme/index';

class ReimbursementListScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        user: PropTypes.object,
        isLoading: PropTypes.bool,
        reimbursementRequests: PropTypes.array,
        reimbursementItems: PropTypes.array,
        getReimbursementItems: PropTypes.func,
        getReimbursementRequests: PropTypes.func,
        setCurrentReimbursementRequest: PropTypes.func,
        clearCurrentReimbursementRequest: PropTypes.func,
        userLogin: PropTypes.func,
    };

    componentDidMount() {
        const { getReimbursementItems, getReimbursementRequests, user } = this.props;
        getReimbursementItems();
        getReimbursementRequests(user._id);
    }

    handleRefreshNeed = () => {
        const { getReimbursementItems, getReimbursementRequests, user } = this.props;
        getReimbursementItems();
        getReimbursementRequests(user._id);
    };

    _returnItems() {
        const { navigation, reimbursementRequests, setCurrentReimbursementRequest, reimbursementItems } = this.props;
        let userRequestedItems = [];
        userRequestedItems = reimbursementRequests.map((request) => {
            const item = reimbursementItems.find((obj) => obj.id === request.Item);
            return {
                id: request.id || request.unsavedId,
                title: item ? item.Name : null,
                cost: request.TotalCost,
                status: request.Status,
                unsaved: request.unsaved,
                onPress: () => {
                    setCurrentReimbursementRequest(request);
                    navigation.navigate('ReimbursementRequest');
                },
            };
        });

        return userRequestedItems;
    }

    createRequest = () => {
        const { navigation, clearCurrentReimbursementRequest } = this.props;
        clearCurrentReimbursementRequest();
        navigation.navigate('ReimbursementRequest');
    };

    render() {
        const { isLoading } = this.props;
        const items = this._returnItems();

        return (
            <>
                <NavigationBar {...this.props} title="Reimbursement Requests" menuIcon />
                <View style={s.wrapper}>
                    <View style={s.mainContainer}>
                        <FlatList
                            data={items}
                            keyExtractor={(item) => item.id}
                            refreshing={isLoading}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            onRefresh={this.handleRefreshNeed}
                            renderItem={({ item, i }) => {
                                return (
                                    <ListItem item={item} i={i} Reimbursement isLast={item.id === items.length - 1} />
                                );
                            }}
                        />
                    </View>
                </View>
                <View style={s.bottomButtonsBlock}>
                    <TouchableOpacity style={s.addButton} onPress={() => this.createRequest()}>
                        <Icon name="plus" color={Color.white} size={30} />
                    </TouchableOpacity>
                </View>
            </>
        );
    }
}

const mapStateToProps = ({ reimbursements, account, currentTimeCard }) => ({
    currentTimeCard,
    isLoading: reimbursements.isLoading,
    reimbursementRequests: reimbursements.reimbursementRequests,
    user: account.user,
    reimbursementItems: reimbursements.reimbursementItems,
});

const mapDispatchToProps = (dispatch) => {
    const bindActions = bindActionCreators(
        {
            getReimbursementItems,
            getReimbursementRequests,
            setCurrentReimbursementRequest,
            clearCurrentReimbursementRequest,
            userLogin,
        },
        dispatch,
    );
    bindActions.dispatch = dispatch;
    return bindActions;
};

export default connect(mapStateToProps, mapDispatchToProps)(ReimbursementListScreen);
