import { StyleSheet } from 'react-native';
import { Font, Color } from '../../theme';

export default StyleSheet.create({
    modalWrapper: {
        width: '100%',
        height: '100%',
        backgroundColor: Color.dark_navy_blue_50,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modal: {
        width: '90%',
        borderRadius: 10,
        padding: 15,
        backgroundColor: Color.pale_grey,
    },
    modalTitle: {
        color: Color.purple_brown,
        fontSize: Font.size.font12,
        fontFamily: Font.type.bold,
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    dropdownContainer: {
        backgroundColor: Color.white,
        borderRadius: 6,
        height: 50,
        marginVertical: 20,
        width: '100%',
    },
    dropdownLabel: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
    },
    touchableRow: {
        paddingHorizontal: 19,
        paddingVertical: 15,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        alignItems: 'center',
    },
    dropdownText: {
        marginTop: -50,
        borderBottomWidth: 0,
    },
    dropdownWrapper: {
        width: '88%',
        paddingVertical: 9,
        backgroundColor: Color.white,
        borderRadius: 6,
        elevation: 8,
    },
    dropDownItem: {
        width: '100%',
        height: 36,
        backgroundColor: Color.very_light_blue,
        paddingHorizontal: 20,
        justifyContent: 'center',
    },
    dropDownItemText: {
        color: Color.light_navy,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font12,
    },
    itemTextStyle: {
        color: Color.light_grey_blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font11,
    },
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    submitModalButton: {
        width: 130,
    },
    cancelModalButton: {
        width: 60,
        marginRight: 25,
        marginTop: 18,
    },
    weekSelector: {
        width: '100%',
        paddingVertical: 40,
        paddingHorizontal: 10,
        marginBottom: 20,
        borderRadius: 6,
        backgroundColor: Color.white,
        alignItems: 'center',
        justifyContent: 'space-between',
        alignSelf: 'center',
    },
    weekSelectorContainer: {
        width: 20,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: -1,
    },
    weekSelectorDateContainer: {
        width: 230,
    },
    weekSelectorText: {
        fontFamily: Font.type.regular,
        color: Color.cloudy_blue,
    },
    monthContainer: {
        padding: 10,
        borderRadius: 6,
        marginBottom: 20,
    },
    monthTextStyle: {
        fontFamily: Font.type.regular,
        color: Color.cobalt_50,
    },
    monthTextStyle_disabled: {
        color: Color.cloudy_blue,
    },
    currentMonthTextStyle: {
        color: Color.light_navy,
    },
    dayPickerContainer: {
        width: '100%',
        backgroundColor: Color.white,
        padding: 10,
        borderRadius: 6,
        marginBottom: 20,
    },
});
