import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import s from './styles';
import { Color } from '../../../../theme';

const ListItem = (props) => {
    const { item } = props;
    const returnNote = () => {
        if (item.adminFeedback) {
            return (
                <View style={{ flexDirection: 'row', marginTop: 4, marginBottom: -5 }}>
                    <Icon name="message-reply-text" color={Color.light_grey_blue} size={14} />
                    <Text style={s.commentText}>{item.adminFeedback}</Text>
                </View>
            );
        }
        return null;
    };
    let approveText = '';
    switch (item.isApproved) {
        case 'O':
            approveText = 'OPEN';
            break;
        case 'D':
            approveText = 'CLOSED';
            break;
        case 'U':
            approveText = 'OPEN';
            break;
        default:
            break;
    }
    
    return (
        <TouchableOpacity onPress={item.onPress}>
            <View style={[s.listItemWrapper, item.note && s.listItemWrapper_comment]}>
                <View style={s.listItemBody}>
                    <Text numberOfLines={1} style={s.listItemTitle}>
                        {item.type}
                    </Text>
                    <Text style={s.listItemInfo}>{moment(item.date).format('MM/DD/YY')}</Text>
                    <View style={{ flexDirection: 'row' }}>
                        <Text
                            style={[
                                s.successText,
                                item.isApproved === 'A' && s.successText_ready,
                                item.isApproved === 'D' && s.successText_error,
                            ]}
                        >
                            {approveText}
                        </Text>
                    </View>
                    {item.unsavedId ? (
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={[s.listItemStatusUnsaved]}>Saved On Device</Text>
                        </View>
                    ) : null}

                    {returnNote()}
                </View>
                <View style={[s.listItemButton, item.note && s.listItemButton_comment]}>
                    <Icon name="chevron-right" size={28} color={Color.turquoise} />
                </View>
            </View>
        </TouchableOpacity>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
};

export default ListItem;
