import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    fieldWrapper: {
        alignItems: 'flex-start',
        width: '100%',
        backgroundColor: Color.white,
        borderRadius: 8,
        elevation: 3,
        marginBottom: 10,
        padding: 10,
        paddingBottom: 0,
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    fieldTitle: {
        fontFamily: Font.type.regular,
        color: Color.grey,
        fontSize: Font.size.font10,
        marginTop: -5,
    },
    textInput: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        width: '100%',
        color: Color.blue,
        marginBottom: 10,
        paddingHorizontal: 5,
        paddingVertical: 2,
    },
    textInputWithPlaceholder: {
        fontSize: Font.size.font11,
        height: 50,
    },
    textInputMultiline: {
        textAlign: 'left',
        fontSize: Font.size.font14,
        textAlignVertical: 'top',
    },
    textInputError: {
        color: Color.pinkish_orange,
    },
    passwordField: {
        flexDirection: 'row',
        width: '100%',
    },
    showPasswordWrapper: {
        height: 30,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
    },
    showPasswordIcon: {
        marginLeft: 0,
        marginTop: 2,
    },
});
