import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, ImageBackground, Image, BackHandler } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavigationEvents } from 'react-navigation';
import { isEqual } from 'lodash';

import moment from 'moment';
import { NavigationBar, PrimaryButton, TopGradient } from '../../../components/index';
import { breakOut, setBreakInLoading } from '../../../store/modules/currentTimeCard';
import { syncTimeCard } from '../../../store/modules/timeCards';
import { userLogin } from '../../../store/modules/account';

import s from './styles';
import { Img } from '../../../theme';

class BreakScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        user: PropTypes.object,
        firstPayPeriod: PropTypes.object,
        currentTimeCard: PropTypes.object,
        unsavedCards: PropTypes.array,
        breakInArray: PropTypes.array,
        isBreakOutLoading: PropTypes.bool,
        timeCardsIsSyncing: PropTypes.bool,
        breakOut: PropTypes.func,
        setBreakInLoading: PropTypes.func,
        syncTimeCard: PropTypes.func,
        userLogin: PropTypes.func,
    };

    timer = null;

    state = {
        timer: null,
    };

    componentDidMount() {
        this.setTimer();
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleHardwareBackPress);
    }

    componentDidUpdate(prevProps) {
        const { currentTimeCard, navigation } = this.props;
        const { currentTimeCard: prevCurrentTimeCard } = prevProps;
        // check if we need go to break
        if (
            !isEqual(currentTimeCard.breakIn, prevCurrentTimeCard.breakIn) ||
            !isEqual(currentTimeCard.breakOut, prevCurrentTimeCard.breakOut)
        ) {
            if (
                currentTimeCard.breakIn.length > 0 &&
                currentTimeCard.breakIn.length === currentTimeCard.breakOut.length
            ) {
                this.clearTimer();
                navigation.navigate('Clock');
            }
        }
    }

    componentWillUnmount() {
        this.clearTimer();
        this.backHandler.remove();
    }

    handleHardwareBackPress = () => {
        return true;
    };

    getElapsedTime() {
        const { breakInArray } = this.props;
        const lastTimestamp = breakInArray[breakInArray.length - 1];
        return moment().diff(moment(lastTimestamp), 'second');
    }

    setTimer() {
        this.clearTimer();
        this.setState({
            timer: this.getElapsedTime(),
        });
        this.timer = setInterval(this.clockTimer, 1000);
    }

    clockTimer = () => {
        this.setState({
            timer: this.getElapsedTime(),
        });
    };

    clearTimer() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    endBreak = () => {
        const { navigation, breakOut, currentTimeCard } = this.props;
        this.clearTimer();

        breakOut({ currentTimeCard }).then(() => {
            this.postUnsavedTimeCards();
            navigation.navigate('Clock');
        });
    };

    postUnsavedTimeCards() {
        const { user, unsavedCards, timeCardsIsSyncing, syncTimeCard, firstPayPeriod } = this.props;

        if (unsavedCards && unsavedCards.length && !timeCardsIsSyncing) {
            syncTimeCard(unsavedCards, user, firstPayPeriod, true);
        }
    }

    handleScreenDidFocus = () => {
        const { setBreakInLoading } = this.props;
        setBreakInLoading(false);
    };

    _returnBottomContainer() {
        const { isBreakOutLoading } = this.props;
        return (
            <View style={s.buttonWrapper}>
                <Image source={Img.break} style={s.break_logo} />
                <PrimaryButton
                    isLoading={isBreakOutLoading}
                    breakButtonFull
                    onPress={this.endBreak}
                    title="END BREAK"
                    md
                />
            </View>
        );
    }

    returnClock() {
        const { timer } = this.state;
        if (timer) {
            return moment('2015-01-01').startOf('day').seconds(timer).format('HH:mm:ss');
        }
        return '00:00:00';
    }

    render() {
        return (
            <ImageBackground source={Img.bg} style={[s.wrapper]}>
                <NavigationEvents onDidFocus={this.handleScreenDidFocus} />
                <TopGradient />
                <NavigationBar {...this.props} title="BREAK" menuIcon />
                <View style={[s.mainContainer]}>
                    <Text style={s.timer}>{this.returnClock()}</Text>
                    {this._returnBottomContainer()}
                </View>
            </ImageBackground>
        );
    }
}

const mapStateToProps = ({ account, currentTimeCard, timeCards }) => ({
    user: account.user,
    firstPayPeriod: timeCards.firstPayPeriod,
    currentTimeCard,
    isClockIn: currentTimeCard.isClockIn,
    breakInArray: currentTimeCard.breakIn,
    currentProject: currentTimeCard.currentProject,
    isBreakOutLoading: currentTimeCard.isBreakOutLoading,
    unsavedCards: timeCards.unsavedCards,
    timeCardsIsSyncing: timeCards.timeCardsIsSyncing,
});

const mapDispatchToProps = (dispatch) => {
    const bindActions = bindActionCreators(
        {
            breakOut,
            setBreakInLoading,
            syncTimeCard,
            userLogin,
        },
        dispatch,
    );
    bindActions.dispatch = dispatch;
    return bindActions;
};

export default connect(mapStateToProps, mapDispatchToProps)(BreakScreen);
