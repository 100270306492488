import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, FlatList } from 'react-native';
import { connect } from 'react-redux';
import NetInfo from '@react-native-community/netinfo';
import { setUserViewingReports } from '../../store/modules/dailyReports';
import { setCurrentCostCode } from '../../store/modules/currentTimeCard';
import { setTimeCardEditCostCode } from '../../store/modules/timeCardEdit';
import { getCostCodes, getUsers } from '../../store/modules/account';
import { ListItem, NavigationBar, BottomGradient } from '../../components/index';

import s from './styles';

class UsersScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        costCodes: PropTypes.array,
        project: PropTypes.object,
        editTimeCardProject: PropTypes.object,
        getCostCodes: PropTypes.func,
        setCurrentCostCode: PropTypes.func,
        setUserViewingReports: PropTypes.func,
        getUsers: PropTypes.func,
        users: PropTypes.array,
        user: PropTypes.object,
    };

    state = { search: null };

    componentDidMount() {
        const { getUsers } = this.props;
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                getUsers('active');
            }
        });
    }

    componentDidUpdate() {}

    onBack() {
        const { navigation } = this.props;

        navigation.pop();
    }

    _returnUsers() {
        const { users, setUserViewingReports, user } = this.props;
        const { search } = this.state;
        let userList = [];
        userList = users.reduce((filtered, u) => {
            const title = u.name ? `${u.name.first} ${u.name.last}`.trim() : '';
            if (!search || (search && title.includes(search))) {
                filtered.push({
                    id: u._id,
                    title,
                    photo: u.meta ? (u.meta.UserPhoto ? u.meta.UserPhoto : null) : null,
                    onPress: () => {
                        setUserViewingReports(u);
                        this.onBack();
                    },
                    loggedInAccount: u._id === user._id,
                });
            }
            return filtered;
        }, []);

        return userList;
    }

    searchUsers = (newSearch) => {
        const { search } = this.state;
        const searchString = newSearch || newSearch === '' ? newSearch : search;
        this.setState({ search: searchString });
    };

    render() {
        return (
            <View style={s.wrapper}>
                <NavigationBar
                    {...this.props}
                    title="USERS"
                    backIcon
                    backIconFunction={() => this.onBack()}
                    searchIcon
                    onSearch={(t) => this.searchUsers(t)}
                />
                <View style={[s.mainContainer]}>
                    <BottomGradient />
                    <FlatList
                        data={this._returnUsers()}
                        keyExtractor={(item) => item.id}
                        style={{ width: '100%', paddingBottom: 150 }}
                        renderItem={({ item }) => {
                            return <ListItem item={item} users />;
                        }}
                        ListFooterComponent={<View style={s.bottomPadding} />}
                    />
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ account, currentTimeCard, timeCardEdit }) => ({
    costCodes: account.costCodes,
    project: currentTimeCard.currentProject,
    isSwitch: currentTimeCard.isSwitch,
    editTimeCardProject: timeCardEdit.projectDetails,
    users: account.users,
    user: account.user,
});

export default connect(mapStateToProps, {
    setCurrentCostCode,
    setTimeCardEditCostCode,
    getCostCodes,
    getUsers,
    setUserViewingReports,
})(UsersScreen);
