import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import account from './modules/account';
import currentTimeCard from './modules/currentTimeCard';
import timeCards from './modules/timeCards';
import dailyReports from './modules/dailyReports';
import timeAndMaterials from './modules/timeAndMaterial';
import changeOrder from './modules/changeOrder';
import timeOff from './modules/timeOff';
import timeCardEdit from './modules/timeCardEdit';
import injuryReporting from './modules/injuryReporting';
import accidentReporting from './modules/accidentReporting';
import howTo from './modules/howTo';
import toolboxTalks from './modules/toolboxTalks';
import customReports from './modules/customReports';
import cutSheets from './modules/cutSheets';
import schedule from './modules/schedule';
import reimbursements from './modules/reimbursements';

export default combineReducers({
    form,
    account,
    currentTimeCard,
    timeCards,
    dailyReports,
    timeAndMaterials,
    changeOrder,
    timeOff,
    timeCardEdit,
    injuryReporting,
    accidentReporting,
    howTo,
    toolboxTalks,
    customReports,
    cutSheets,
    schedule,
    reimbursements,
});
