import { cloneDeep } from 'lodash';
import moment from 'moment';

import CNST from '../constants';
import Api from '../../utils/apiMiddleware';
import { Config } from '../../config';
import { checkConnectivity, showSuccessMessage, showErrorMessage, roundNumber } from '../../utils/helpers';

export function getAllShifts(userId) {
    return async (dispatch) => {
        dispatch({
            type: CNST.SCHEDULE.GET_SHIFTS.LOADING,
        });

        const getShiftsStartTime = moment();
        const getShiftsEndTime = moment().add(1, 'month').endOf('month');

        // move to next Saturday if start of the current month is not a Saturday, to match to calendar view
        if (getShiftsEndTime.day() !== 6) {
            getShiftsEndTime.day(6);
        }

        const getAvailableShiftsStartTime = moment();
        const getAvailableShiftsEndTime = moment().add(1, 'month');

        const isConnected = await checkConnectivity(
            'Connection Failure',
            'We are unable to get the most recent shifts. Please try again.',
        );

        if (isConnected) {
            try {
                const [getShiftsResponse, getAvailableShiftsResponse, getShiftTimeOffRequestsResponse] =
                    await Promise.all([
                        Api().post(`/sources/${Config.SHIFTS}/data`, {
                            limit: 100,
                            fetchRelatedData: true,
                            startDate: getShiftsStartTime.toISOString(),
                            endDate: getShiftsEndTime.toISOString(),
                            sort: 'StartTime',
                            searchCriteria: { UserId: userId },
                        }),
                        Api().post(`/sources/${Config.SHIFTS}/data`, {
                            limit: 100,
                            fetchRelatedData: true,
                            startDate: getAvailableShiftsStartTime.toISOString(),
                            endDate: getAvailableShiftsEndTime.toISOString(),
                            sort: 'StartTime',
                            searchCriteria: { UserId: '' },
                        }),
                        Api().post(`/sources/${Config.SHIFT_TIME_OFF_REQUESTS}/data`, {
                            limit: 100,
                            startDate: getAvailableShiftsStartTime.toISOString(),
                            rangeFilterField: 'ShiftStartTime',
                            searchCriteria: { UserId: userId },
                            sort: '-timeCreated',
                        }),
                    ]);
                dispatch({
                    type: CNST.SCHEDULE.GET_ALL_SHIFTS.SUCCESS,
                    shifts: getShiftsResponse.data,
                    shiftsRelatedData: getShiftsResponse.relatedData,
                    availableShifts: getAvailableShiftsResponse.data,
                    availableShiftsRelatedData: getAvailableShiftsResponse.relatedData,
                    shiftTimeOffRequests: getShiftTimeOffRequestsResponse.data,
                    getShiftsStartTime,
                    getShiftsEndTime,
                    getAvailableShiftsStartTime,
                    getAvailableShiftsEndTime,
                });
                return true;
            } catch (error) {
                dispatch({
                    type: CNST.SCHEDULE.GET_SHIFTS.ERROR,
                });

                let errorDescription = 'We are unable to get shifts. Please try again later.';
                if (error && error.message) {
                    errorDescription = error.message;
                }

                showErrorMessage('Get shifts failed', errorDescription);
            }
        } else {
            dispatch({
                type: CNST.SCHEDULE.GET_SHIFTS.ERROR,
            });
        }

        return false;
    };
}

export function getShifts(userId, dateInMonthToGet) {
    return async (dispatch) => {
        dispatch({
            type: CNST.SCHEDULE.GET_SHIFTS.LOADING,
        });

        const currentDate = moment();
        let startTime = moment(dateInMonthToGet).startOf('month');
        const endTime = startTime.clone().endOf('month');

        if (currentDate > startTime && currentDate > endTime) {
            // the month to get shifts is already in the past, so skip getting shifts
            return true;
        }

        if (currentDate > startTime) {
            startTime = currentDate;
        } else if (startTime.day() !== 0) {
            // move to last Sunday if start of the current month is not a Sunday, to match to calendar view
            startTime.day(0);
            if (currentDate > startTime) {
                startTime = currentDate;
            }
        }

        // move to next Saturday if start of the current month is not a Saturday, to match to calendar view
        if (endTime.day() !== 6) {
            endTime.day(6);
        }

        const isConnected = await checkConnectivity(
            'Connection Failure',
            'We are unable to get shifts. Please try again.',
        );
        if (isConnected) {
            try {
                const response = await Api().post(`/sources/${Config.SHIFTS}/data`, {
                    limit: 100,
                    fetchRelatedData: true,
                    startDate: startTime.toISOString(),
                    endDate: endTime.toISOString(),
                    sort: 'StartTime',
                    searchCriteria: { UserId: userId },
                });

                dispatch({
                    type: CNST.SCHEDULE.GET_SHIFTS.SUCCESS,
                    data: response.data,
                    relatedData: response.relatedData,
                    startTime,
                    endTime,
                });

                return true;
            } catch (error) {
                dispatch({
                    type: CNST.SCHEDULE.GET_SHIFTS.ERROR,
                });

                let errorDescription = 'We are unable to get shifts. Please try again later.';
                if (error && error.message) {
                    errorDescription = error.message;
                }

                showErrorMessage('Get shifts failed', errorDescription);
            }
        } else {
            dispatch({
                type: CNST.SCHEDULE.GET_SHIFTS.ERROR,
            });
        }
        return false;
    };
}

export function getAvailableShifts(startTime = null) {
    return async (dispatch) => {
        dispatch({
            type: CNST.SCHEDULE.GET_AVAILABLE_SHIFTS.LOADING,
        });

        let isNeedAppend = false;
        if (!startTime) {
            startTime = moment();
        } else {
            startTime = moment(startTime);
            isNeedAppend = true;
        }

        const endTime = startTime.clone().add(1, 'month');

        const isConnected = await checkConnectivity(
            'Connection Failure',
            'We are unable to get shifts. Please try again.',
        );

        if (isConnected) {
            try {
                const response = await Api().post(`/sources/${Config.SHIFTS}/data`, {
                    limit: 100,
                    fetchRelatedData: true,
                    startDate: startTime.toISOString(),
                    endDate: endTime.toISOString(),
                    sort: 'StartTime',
                    searchCriteria: { UserId: '' },
                });

                dispatch({
                    type: CNST.SCHEDULE.GET_AVAILABLE_SHIFTS.SUCCESS,
                    data: response.data,
                    relatedData: response.relatedData,
                    startTime,
                    endTime,
                    isNeedAppend,
                });
                return true;
            } catch (error) {
                dispatch({
                    type: CNST.SCHEDULE.GET_AVAILABLE_SHIFTS.ERROR,
                });
                let errorDescription = 'We are unable to get shifts. Please try again later.';
                if (error && error.message) {
                    errorDescription = error.message;
                }

                showErrorMessage('Get shifts failed', errorDescription);
            }
        } else {
            dispatch({
                type: CNST.SCHEDULE.GET_AVAILABLE_SHIFTS.ERROR,
            });
        }

        return false;
    };
}

export function pickUpShift(shiftId, user) {
    return async (dispatch) => {
        dispatch({
            type: CNST.SCHEDULE.UPDATE_SHIFT.LOADING,
        });

        try {
            await Api().put(`/sources/${Config.SHIFTS}/data/${shiftId}`, {
                data: {
                    UserId: user._id,
                },
                modifiedBy: user.username,
            });
            dispatch({
                type: CNST.SCHEDULE.UPDATE_SHIFT.SUCCESS,
            });
            return true;
        } catch (error) {
            dispatch({
                type: CNST.SCHEDULE.UPDATE_SHIFT.ERROR,
            });

            let errorDescription = 'We are unable to pick up the shift. Please try again later.';
            if (error && error.message) {
                errorDescription = error.message;
            }

            showErrorMessage('Pick up shift failed', errorDescription);

            return false;
        }
    };
}

export function shiftRequestTimeOff(shift, notes, user) {
    return async (dispatch) => {
        dispatch({
            type: CNST.SCHEDULE.SUBMIT_SHIFT_TIME_OFF_REQUEST.LOADING,
        });
        const requestData = {
            UserId: user._id,
            ShiftId: shift.id,
            ShiftStartTime: shift.StartTime,
            ShiftEndTime: shift.EndTime,
            Status: 'U',
            Notes: notes,
        };

        try {
            const newRequest = await Api().put(`/sources/${Config.SHIFT_TIME_OFF_REQUESTS}/data/new`, {
                data: requestData,
                modifiedBy: user.username,
            });
            dispatch({
                type: CNST.SCHEDULE.SUBMIT_SHIFT_TIME_OFF_REQUEST.SUCCESS,
                newRequest,
            });
            showSuccessMessage('Success', 'Your time off request have been submitted');
            return true;
        } catch (error) {
            dispatch({
                type: CNST.SCHEDULE.SUBMIT_SHIFT_TIME_OFF_REQUEST.ERROR,
            });

            let errorDescription = 'We are unable to request time off for this shift. Please try again later.';
            if (error && error.message) {
                errorDescription = error.message;
            }

            showErrorMessage('Request time off failed', errorDescription);

            return false;
        }
    };
}

export function selectScheduleCalendarDate(selectedDate) {
    return async (dispatch) => {
        dispatch({
            type: CNST.SCHEDULE.SELECT_CALENDAR_DATE,
            selectedDate,
        });
    };
}

// ------------------------------------
// Reducers
// ------------------------------------
const initialState = {
    isLoading: false,
    upcomingShifts: [],
    availableShifts: [],
    calendarMarkedDates: {},
    shiftTimeOffRequests: [],
    shiftsRange: {
        startTime: null,
        endTime: null,
    },
    availableShiftsRange: {
        startTime: null,
        endTime: null,
    },
    selectedDate: '',
    noMoreAvailableShifts: false,
};

const getProjectAddress = (project) => {
    let address = '';

    if (project.Address) {
        const { StreetAddress, Zip, City, State } = project.Address;
        const addressParts = [];

        if (StreetAddress) {
            addressParts.push(StreetAddress.trim());
        }

        if (City) {
            addressParts.push(City.trim());
        }

        let stateZip = '';
        if (State) {
            stateZip = State;
        }

        if (Zip) {
            stateZip += ` ${Zip}`;
        }

        if (stateZip) {
            addressParts.push(stateZip.trim());
        }

        address = addressParts.join(', ');
    }

    return address;
};

const parseShifts = (shifts, relatedData, rangeStart) => {
    const parsedShifts = [];
    for (let i = 0; i < shifts.length; ++i) {
        const shift = shifts[i];
        const startTime = moment(shift.StartTime);
        const endTime = moment(shift.EndTime);
        const date = moment(shift.Date);

        if (startTime < rangeStart) {
            continue;
        }

        shift.ProjectName = '';
        shift.ProjectAddress = '';
        if (shift.ProjectId && relatedData[Config.PROJECTS] && relatedData[Config.PROJECTS][shift.ProjectId]) {
            const project = relatedData[Config.PROJECTS][shift.ProjectId];
            shift.ProjectName = project.ProjectName;
            shift.ProjectAddress = getProjectAddress(project);
        }

        shift.Date = date.format('YYYY-MM-DD');
        shift.DateFormatted = date.format('MMM D, YYYY');
        shift.Day = date.format('D');
        shift.WeekDay = date.format('ddd');
        shift.TimeRange = `${startTime.format('hh:mm a')} - ${endTime.format('hh:mm a')}`;
        shift.Hours = endTime.diff(startTime, 'hours', true).toFixed(2);
        shift.IsAvailable = !shift.UserId;

        if (shift.IsRepeat) {
            let repeatDesc = 'Repeated ';
            if (shift.RepeatInterval === 1) {
                switch (shift.RepeatType) {
                    case 'Day':
                        repeatDesc += 'daily';
                        break;
                    case 'Week':
                        repeatDesc += 'weekly';
                        break;
                    case 'Month':
                        repeatDesc += 'monthly';
                        break;
                    default:
                        break;
                }
            } else {
                repeatDesc += `every ${shift.RepeatInterval} ${shift.RepeatType.toLowerCase()}`;
            }

            if (shift.RepeatEndDate) {
                repeatDesc += ` until ${moment(shift.RepeatEndDate).format('MMM D, YYYY')}`;
            }

            shift.RepeatDesc = repeatDesc;
        }

        parsedShifts.push(shift);
    }

    return parsedShifts;
};

const parseShiftTimeOffRequest = (shiftTimeOffRequest) => {
    shiftTimeOffRequest.Hours = roundNumber(
        moment(shiftTimeOffRequest.ShiftEndTime).diff(shiftTimeOffRequest.ShiftStartTime, 'hours', true),
    );

    shiftTimeOffRequest.TimeRange =
        moment(shiftTimeOffRequest.ShiftStartTime).format('hh:mm A') +
        '-' +
        moment(shiftTimeOffRequest.ShiftEndTime).format('hh:mm A');
    shiftTimeOffRequest.Day = moment(shiftTimeOffRequest.ShiftStartTime).date();
    shiftTimeOffRequest.WeekDay = moment(shiftTimeOffRequest.ShiftStartTime).format('ddd');

    return shiftTimeOffRequest;
};

const parseShiftTimeOffRequests = (shiftTimeOffRequests) => {
    for (let i = 0; i < shiftTimeOffRequests.length; ++i) {
        const shiftTimeOffRequest = shiftTimeOffRequests[i];
        parseShiftTimeOffRequest(shiftTimeOffRequest);
    }

    return shiftTimeOffRequests;
};

const parseCalendarMarkedDates = (shifts, currentSelectedDate, currentMarkedDates = null) => {
    let markedDates = {};

    for (let i = 0; i < shifts.length; ++i) {
        const shift = shifts[i];

        if (markedDates[shift.Date]) {
            markedDates[shift.Date].shifts.push(shift);
        } else {
            markedDates[shift.Date] = {
                selected: false,
                marked: true,
                shifts: [shift],
            };
        }
    }

    if (currentMarkedDates) {
        markedDates = Object.assign({}, currentMarkedDates, markedDates);
    }
    // restore selected
    if (markedDates[currentSelectedDate]) {
        markedDates[currentSelectedDate].selected = true;
    } else {
        markedDates[currentSelectedDate] = {
            selected: true,
            marked: false,
            shifts: [],
        };
    }

    return markedDates;
};

export default function scheduleReducer(state = cloneDeep(initialState), action) {
    switch (action.type) {
        case CNST.SCHEDULE.GET_SHIFTS.LOADING:
        case CNST.SCHEDULE.GET_AVAILABLE_SHIFTS.LOADING:
        case CNST.SCHEDULE.UPDATE_SHIFT.LOADING:
        case CNST.SCHEDULE.SUBMIT_SHIFT_TIME_OFF_REQUEST.LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case CNST.SCHEDULE.GET_SHIFTS.SUCCESS: {
            const { data, relatedData, startTime, endTime } = action;
            const parsedShifts = parseShifts(data, relatedData, startTime);
            return {
                ...state,
                calendarMarkedDates: parseCalendarMarkedDates(
                    parsedShifts,
                    state.selectedDate,
                    state.calendarMarkedDates,
                ),
                shiftsRange: {
                    startTime,
                    endTime,
                },
                isLoading: false,
            };
        }
        case CNST.SCHEDULE.GET_AVAILABLE_SHIFTS.SUCCESS: {
            const { data, relatedData, startTime, endTime, isNeedAppend } = action;
            const shifts = parseShifts(data, relatedData, startTime);

            const updatedState = { isLoading: false };
            if (isNeedAppend) {
                updatedState.noMoreAvailableShifts = shifts.length === 0;
                updatedState.availableShifts = [...state.availableShifts, ...shifts];
                updatedState.availableShiftsRange = {
                    startTime: state.availableShiftsRange.startTime,
                    endTime,
                };
            } else {
                updatedState.noMoreAvailableShifts = false;
                updatedState.availableShifts = shifts;
                updatedState.availableShiftsRange = {
                    startTime,
                    endTime,
                };
            }

            return {
                ...state,
                ...updatedState,
            };
        }
        case CNST.SCHEDULE.GET_ALL_SHIFTS.SUCCESS: {
            const {
                shifts,
                shiftsRelatedData,
                availableShifts,
                availableShiftsRelatedData,
                shiftTimeOffRequests,
                getShiftsStartTime,
                getShiftsEndTime,
                getAvailableShiftsStartTime,
                getAvailableShiftsEndTime,
            } = action;
            const parsedShifts = parseShifts(shifts, shiftsRelatedData, getShiftsStartTime);
            const currentDate = moment().format('YYYY-MM-DD');
            return {
                ...state,
                upcomingShifts: parsedShifts.slice(0, 2),
                availableShifts: parseShifts(availableShifts, availableShiftsRelatedData, getAvailableShiftsStartTime),
                calendarMarkedDates: parseCalendarMarkedDates(parsedShifts, currentDate),
                shiftTimeOffRequests: parseShiftTimeOffRequests(shiftTimeOffRequests),
                shiftsRange: {
                    startTime: getShiftsStartTime,
                    endTime: getShiftsEndTime,
                },
                availableShiftsRange: {
                    startTime: getAvailableShiftsStartTime,
                    endTime: getAvailableShiftsEndTime,
                },
                selectedDate: currentDate,
                noMoreAvailableShifts: false,
                isLoading: false,
            };
        }
        case CNST.SCHEDULE.UPDATE_SHIFT.SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case CNST.SCHEDULE.SUBMIT_SHIFT_TIME_OFF_REQUEST.SUCCESS:
            return {
                ...state,
                isLoading: false,
                shiftTimeOffRequests: [parseShiftTimeOffRequest(action.newRequest), ...state.shiftTimeOffRequests],
            };
        case CNST.SCHEDULE.SELECT_CALENDAR_DATE: {
            const { selectedDate } = action;
            const { calendarMarkedDates, selectedDate: currentSelectedDate } = state;

            calendarMarkedDates[currentSelectedDate].selected = false;

            if (calendarMarkedDates[selectedDate]) {
                calendarMarkedDates[selectedDate].selected = true;
            } else {
                calendarMarkedDates[selectedDate] = {
                    selected: true,
                    marked: false,
                    shifts: [],
                };
            }

            return {
                ...state,
                selectedDate,
                calendarMarkedDates,
            };
        }

        case CNST.SCHEDULE.GET_SHIFTS.ERROR:
        case CNST.SCHEDULE.GET_AVAILABLE_SHIFTS.ERROR:
        case CNST.SCHEDULE.UPDATE_SHIFT.ERROR:
        case CNST.SCHEDULE.SUBMIT_SHIFT_TIME_OFF_REQUEST.ERROR:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state;
    }
}
