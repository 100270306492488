import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    menu: {
        flex: 1,
    },
    container: {
        flex: 1,
        justifyContent: 'space-between',
        backgroundColor: Color.light_navy,
    },
    menuTopContainer: {
        width: '100%',
        paddingLeft: 27,
        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: Color.blue,
        zIndex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    menuContainer: {
        backgroundColor: Color.pale_grey_two,
        flex: 1.5,
        width: '100%',
        paddingHorizontal: 24,
        paddingTop: 30,
        paddingBottom: 15,
        zIndex: 1,
        justifyContent: 'space-between',
    },
    closeMenuIcon: {
        alignSelf: 'flex-start',
        marginRight: 15,
    },
    userName: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font16,
        color: Color.white,
    },
    userMail: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font13,
        color: Color.white_50,
        marginTop: 5,
    },
    menuItem: {
        flexDirection: 'row',
        marginBottom: 20,
    },
    menuItemText: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue_50,
        marginLeft: 23,
        opacity: 0.5,
        marginTop: 5,
    },
    menuItemTextActive: {
        color: Color.blue,
        opacity: 1,
    },
    menuItemIcon: {
        opacity: 0.5,
        color: Color.dark_navy_blue_50,
        fontSize: 30,
    },
    menuItemIconActive: {
        opacity: 1,
        color: Color.blue,
    },
    menuItemIconSupport: {
        opacity: 0.5,
        color: Color.dark_navy_blue_50,
        fontSize: 30,
    },
    menuItemTextSupport: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue_50,
        marginLeft: 23,
        opacity: 0.5,
        marginTop: 5,
    },
    versionText: {
        width: '100%',
        backgroundColor: Color.pale_grey_two,
        textAlign: 'center',
        fontFamily: Font.type.light,
        fontSize: Font.size.font11,
        color: Color.grey,
        paddingBottom: 20,
    },
    menuButton: {
        width: '96%',
        marginTop: 20,
        marginBottom: 15,
        paddingHorizontal: '2%',
        alignSelf: 'center',
    },
});
