import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, FlatList, Text, ActivityIndicator } from 'react-native';
import { connect } from 'react-redux';

import { NavigationBar } from '../../../components/index';
import ShiftItem from '../components/ShiftItem';

import { getAvailableShifts } from '../../../store/modules/schedule';

import s from './styles';
import Color from '../../../theme/colors';

class AvailableShifts extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        isLoading: PropTypes.bool,
        noMoreAvailableShifts: PropTypes.bool,
        availableShifts: PropTypes.array,
        availableShiftsRange: PropTypes.object,
        getAvailableShifts: PropTypes.func,
    };

    state = {};

    handleRefreshNeed = () => {
        const { getAvailableShifts } = this.props;
        getAvailableShifts();
    };

    handleShiftPress = (shift) => {
        const { navigation } = this.props;
        navigation.navigate('ShiftDetails', { shift });
    };

    handleListEndReached = () => {
        const { isLoading, noMoreAvailableShifts, availableShiftsRange, getAvailableShifts } = this.props;
        if (!isLoading && !noMoreAvailableShifts) {
            getAvailableShifts(availableShiftsRange.endTime);
        }
    };

    render() {
        const { availableShifts, isLoading } = this.props;

        return (
            <View style={s.wrapper}>
                <NavigationBar {...this.props} title="Available Shifts" backIcon {...this.props} />
                <View style={[s.mainContainer]}>
                    <FlatList
                        style={s.listWrapper}
                        data={availableShifts}
                        refreshing={isLoading}
                        renderItem={({ item }) => {
                            return <ShiftItem shift={item} onShiftPress={this.handleShiftPress} />;
                        }}
                        keyExtractor={(item, i) => `${item.id}_${i}`}
                        contentContainerStyle={s.listContentContainer}
                        ListEmptyComponent={<Text style={s.listTitle}>Nothing Available</Text>}
                        onRefresh={this.handleRefreshNeed}
                        ListFooterComponent={() => {
                            if (isLoading) {
                                return <ActivityIndicator color={Color.cobalt_50} size={20} />;
                            }
                            return null;
                        }}
                        // onEndReachedThreshold={0.1}
                        onEndReached={this.handleListEndReached}
                    />
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ schedule }) => ({
    isLoading: schedule.isLoading,
    noMoreAvailableShifts: schedule.noMoreAvailableShifts,
    availableShifts: schedule.availableShifts,
    availableShiftsRange: schedule.availableShiftsRange,
});

export default connect(mapStateToProps, { getAvailableShifts })(AvailableShifts);
