import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import s from './styles';
import { Color } from '../../../../theme';

const ShiftItem = (props) => {
    const { shift, onViewAllPress, onShiftPress } = props;
    return (
        <TouchableOpacity style={s.listItemWrapper} onPress={() => onShiftPress && onShiftPress(shift)}>
            <View style={{ width: '100%' }}>
                <View style={s.listItemRow}>
                    <View style={s.flexRow}>
                        <View style={shift.IsAvailable ? s.availableBox : s.upcomingBox}>
                            <Text style={shift.IsAvailable ? s.listItemDayAvailable : s.listItemDayUpcoming}>
                                {shift.WeekDay}
                            </Text>
                            <Text style={s.listItemDayNum}>{shift.Day}</Text>
                        </View>
                        <View style={s.listItemColumn}>
                            <Text style={s.listItemProject}>{shift.ProjectName}</Text>
                            <Text style={s.listItemTitle}>{shift.ProjectAddress}</Text>
                            <View style={s.listItemTimeBlock}>
                                <Icon name="clock-outline" size={16} color="#8b9198" />
                                <Text style={[s.listItemTitle, s.listItemTime]}>{shift.TimeRange}</Text>
                            </View>
                        </View>
                    </View>
                    <View>
                        <Text style={s.listItemHours}>{shift.Hours}</Text>
                    </View>
                </View>

                {onViewAllPress && (
                    <View style={s.buttonRow}>
                        <View />
                        <TouchableOpacity onPress={() => onViewAllPress(shift.IsAvailable)}>
                            <View style={s.listItemRowViewAll}>
                                <Text style={s.listItemButtonViewAll}>View All</Text>
                                <Icon name="chevron-right" size={25} color={Color.turquoise} />
                            </View>
                        </TouchableOpacity>
                    </View>
                )}
            </View>
        </TouchableOpacity>
    );
};

ShiftItem.propTypes = {
    shift: PropTypes.object,
    onViewAllPress: PropTypes.func,
    onShiftPress: PropTypes.func,
};

export default ShiftItem;
