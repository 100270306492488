import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, ScrollView, Text } from 'react-native';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import { pickUpShift, getAllShifts, shiftRequestTimeOff } from '../../../store/modules/schedule';

import { NavigationBar, PrimaryButton } from '../../../components/index';
import TimeOffRequestModal from '../components/TimeOffRequestModal/TimeOffRequestModal';
import { getStatusText } from '../../../utils/helpers';

import s from './styles';

class ShiftDetails extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        user: PropTypes.object,
        isLoading: PropTypes.bool,
        shiftTimeOffRequests: PropTypes.array,
        pickUpShift: PropTypes.func,
        getAllShifts: PropTypes.func,
        shiftRequestTimeOff: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { navigation, shiftTimeOffRequests } = this.props;
        this.shift = navigation.getParam('shift', null);

        this.state = {
            isTimeOffRequestModalOpen: false,
            shiftTimeOffRequest: shiftTimeOffRequests.find((request) => {
                return request.ShiftStartTime === this.shift.StartTime && request.ShiftEndTime === this.shift.EndTime;
            }),
        };
    }

    componentDidUpdate(prevProps) {
        const { shiftTimeOffRequests } = this.props;
        const { shiftTimeOffRequests: prevShiftTimeOffRequests } = prevProps;

        if (!isEqual(shiftTimeOffRequests, prevShiftTimeOffRequests)) {
            this.setState({
                shiftTimeOffRequest: shiftTimeOffRequests.find((request) => {
                    return (
                        request.ShiftStartTime === this.shift.StartTime && request.ShiftEndTime === this.shift.EndTime
                    );
                }),
            });
        }
    }

    handleActionPress = () => {
        const { navigation, user, pickUpShift, getAllShifts } = this.props;

        if (this.shift.IsAvailable) {
            pickUpShift(this.shift.id, user).then((success) => {
                if (success) {
                    getAllShifts(user._id);
                    navigation.goBack();
                }
            });
        } else {
            navigation.goBack();
        }
    };

    handleTimeOffRequestPress = () => {
        this.setState({ isTimeOffRequestModalOpen: true });
    };

    handleSubmitTimeOffRequest = (notes) => {
        const { user, shiftRequestTimeOff } = this.props;
        this.setState({ isTimeOffRequestModalOpen: false });
        shiftRequestTimeOff(this.shift, notes, user);
    };

    handleCancelTimeOffRequest = () => {
        this.setState({ isTimeOffRequestModalOpen: false });
    };

    render() {
        const { isLoading } = this.props;
        const { isTimeOffRequestModalOpen, shiftTimeOffRequest } = this.state;

        let shiftTimeOffStatusText = '';
        if (shiftTimeOffRequest) {
            shiftTimeOffStatusText = getStatusText(shiftTimeOffRequest.Status);
        }

        return (
            <>
                <NavigationBar {...this.props} title="SHIFT DETAILS" backIcon />
                <ScrollView style={[s.wrapper]}>
                    <View style={[s.mainContainer]}>
                        <View style={s.listItemWrapper}>
                            <View style={[s.listItemRow, s.flexRow]}>
                                <View>
                                    <Text style={s.listItemLabel}>Date</Text>
                                    <Text style={s.listItemDate}>{this.shift.DateFormatted}</Text>
                                </View>
                                <View style={s.timeDetails}>
                                    <Text style={s.listItemSubTitle}>{this.shift.TimeRange}</Text>
                                    {this.shift.IsRepeat && (
                                        <Text style={s.listItemSubTitle}>{this.shift.RepeatDesc}</Text>
                                    )}
                                </View>
                            </View>
                            <View style={s.listItemRow}>
                                <Text style={s.listItemLabel}>Location</Text>
                                <Text style={s.listItemTitle}>{this.shift.ProjectAddress}</Text>
                            </View>
                            <View style={s.listItemRow}>
                                <Text style={s.listItemLabel}>Name</Text>
                                <Text style={s.listItemTitle}>{this.shift.ProjectName}</Text>
                            </View>
                            {shiftTimeOffRequest && (
                                <View style={s.listItemRow}>
                                    <Text style={s.listItemLabel}>Time Off Request</Text>
                                    <Text
                                        style={[
                                            s.listItemTitle,
                                            s.statusText,
                                            shiftTimeOffRequest.Status === 'A' && s.statusTextApproved,
                                            shiftTimeOffRequest.Status === 'D' && s.statusTextDenied,
                                            shiftTimeOffRequest.Status === 'U' && s.statusTextPending,
                                        ]}
                                    >
                                        {shiftTimeOffStatusText}
                                    </Text>
                                    <Text style={s.listItemTitle}>{shiftTimeOffRequest.Notes}</Text>
                                </View>
                            )}
                            <View style={s.listItemRow}>
                                <Text style={s.listItemLabel}>Total Time</Text>
                                <Text style={s.listItemTotalTime}>{`${this.shift.Hours} h`}</Text>
                                <View style={s.buttonWrapper}>
                                    {this.shift.UserId &&
                                        (!shiftTimeOffRequest || shiftTimeOffRequest.Status === 'D') && (
                                            <PrimaryButton
                                                isLoading={isLoading}
                                                title="Request Time Off"
                                                md
                                                onPress={this.handleTimeOffRequestPress}
                                            />
                                        )}
                                    <PrimaryButton
                                        isLoading={isLoading}
                                        title={this.shift.IsAvailable ? 'Pick Up Shift' : 'Back'}
                                        md
                                        onPress={this.handleActionPress}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                </ScrollView>
                <TimeOffRequestModal
                    isOpen={isTimeOffRequestModalOpen}
                    isLoading={isLoading}
                    onCancel={this.handleCancelTimeOffRequest}
                    onSubmit={this.handleSubmitTimeOffRequest}
                    shift={this.shift}
                />
            </>
        );
    }
}

const mapStateToProps = ({ account, schedule }) => ({
    user: account.user,
    isLoading: schedule.isLoading,
    shiftTimeOffRequests: schedule.shiftTimeOffRequests,
});

export default connect(mapStateToProps, { pickUpShift, getAllShifts, shiftRequestTimeOff })(ShiftDetails);
