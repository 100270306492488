/**
 * Checks if a user has access to a specific source based on their permissions.
 * sourceId or permission must be provided.
 *
 * @param {object} user - The user object containing their permissions.
 * @param {string} sourceId - The ID of the source to check access for.
 * @param {string} [permission] - The specific permission to check for (optional).
 * @return {boolean} True if the user has access, false otherwise.
 */
export default function hasAccessTo(user, sourceId, permission = null) {
    let hasAccess = false;
    if (user && user.permissions) {
        const permissionNames = Object.keys(user.permissions);

        for (const permissionName of permissionNames) {
            if (permission && permission === permissionName) {
                hasAccess = true;
            } else if (sourceId) {
                const permissionDef = user.permissions[permissionName];
                if (permissionDef.datasetId && permissionDef.datasetId === sourceId) {
                    hasAccess = permissionDef.read;
                }
            }

            if (hasAccess) {
                break;
            }
        }
    }

    return hasAccess;
}
