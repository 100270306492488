import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, View, ScrollView, SectionList, Text } from 'react-native';
import { connect } from 'react-redux';

import { createTimeOffItem, getTimeOffCards, setTimeOffItem } from '../../../store/modules/timeOff';
import { userUpdate, getDepartment } from '../../../store/modules/account';

import { NavigationBar } from '../../../components/index';
import ListItem from './ListItem';

import s from './styles';

class Communications extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        user: PropTypes.object,
        timeOffItems: PropTypes.array,
        assignUsers: PropTypes.array,
        isLoading: PropTypes.bool,
        createTimeOffItem: PropTypes.func,
        getTimeOffCards: PropTypes.func,
        setTimeOffItem: PropTypes.func,
        userUpdate: PropTypes.func,
        getDepartment: PropTypes.func,
    };

    state = {
        message: [{ title: 'New Shifts Available', message: 'You have 5 new shifts available', date: new Date() }],
        notifications: [
            { title: 'New Shifts Available', message: 'You have 5 new shifts available', date: new Date() },
        ],
        selectedItem: 0,
    };

    componentDidMount() {
        this.onRefresh();
    }

    onRefresh = async () => {
        const { user, getTimeOffCards, userUpdate, getDepartment } = this.props;
        getTimeOffCards(user._id);
        await userUpdate(user._id, user.session);
        if (user && user.meta.Department) {
            getDepartment(user.meta.Department);
        }
    };

    render() {
        const { message, notifications, selectedItem } = this.state;

        const sections = [];

        if (selectedItem === 0 && (message.length > 0 || notifications.length > 0)) {
            sections.push({
                title: 'Communications',
                data: message.concat(notifications),
            });
        } else if (selectedItem === 1 && message.length > 0) {
            sections.push({
                title: 'Communications',
                data: message,
            });
        } else if (selectedItem === 2 && notifications.length > 0) {
            sections.push({
                title: 'Communications',
                data: notifications,
            });
        }

        return (
            <View style={s.listItemWrapper}>
                <NavigationBar {...this.props} title="COMMUNICATIONS" backIcon {...this.props} />

                <View style={s.menuContainer}>
                    <TouchableOpacity
                        // eslint-disable-next-line max-len
                        style={s.menuItem}
                        onPress={() => this.setState({ selectedItem: 0 })}
                    >
                        <Text style={s.menuitemText}>All</Text>
                        <View style={s.menuActiveItemSlash} />
                    </TouchableOpacity>

                    <TouchableOpacity
                        // eslint-disable-next-line max-len
                        style={s.menuItem}
                        onPress={() => this.setState({ selectedItem: 1 })}
                    >
                        <Text style={s.menuitemText}>MESSAGES</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        // eslint-disable-next-line max-len
                        style={s.menuItem}
                        onPress={() => this.setState({ selectedItem: 2 })}
                    >
                        <Text style={s.menuitemText}>notificationsS</Text>
                    </TouchableOpacity>
                </View>
                <ScrollView style={[s.wrapper]}>
                    <View style={[s.mainContainer]}>
                        <SectionList
                            sections={sections}
                            renderItem={({ item, section }) => {
                                if (item) {
                                    return <ListItem item={item} section={section} />;
                                }
                                return null;
                            }}
                            keyExtractor={(item, i) => (item ? item.id : i)}
                            // renderSectionHeader={({ section: { title } }) => <Text style={s.listTitle}>{title}</Text>}
                            contentContainerStyle={{
                                paddingBottom: 30,
                            }}
                            ListEmptyComponent={<Text style={s.listTitle}>Nothing Available</Text>}
                            // refreshing={isPageLoading}
                            // ListFooterComponent={() => {
                            //   if (isPageLoading) {
                            //     return <ActivityIndicator color={Color.cobalt_50} size={40} />
                            //   }
                            //   return null
                            // }}
                            onEndReachedThreshold={0.1}
                            // onEndReached={() => {
                            //   if (!isPageLoading && !searchLoading && currentPage < totalPages) {
                            //     this.getProjects()
                            //   }
                            // }}
                        />
                    </View>
                </ScrollView>
            </View>
        );
    }
}

const mapStateToProps = ({ timeOff, account }) => ({
    timeOffItems: timeOff.timeOffItems,
    assignUsers: timeOff.assignUsers,
    test: timeOff,
    isLoading: timeOff.isLoading,
    user: account.user,
});

export default connect(mapStateToProps, {
    getTimeOffCards,
    createTimeOffItem,
    setTimeOffItem,
    userUpdate,
    getDepartment,
})(Communications);
