import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 70,
    },
    buttonWrapper: {
        width: 180,
    },
    listTitle: {
        marginTop: 25,
        fontSize: Font.size.font12,
        fontFamily: Font.type.bold,
        color: Color.light_navy,
        marginVertical: 10,
    },
    bottomPadding: {
        height: 100,
    },
});
