import React, { PureComponent } from 'react';
import { View } from 'react-native';

import { Field, reduxForm } from 'redux-form';
import { func, object, bool } from 'prop-types';

import { FieldInput, PrimaryButton } from '../../components';
import { required } from '../../utils/fieldValidation';

import s from './styles';

class ResetPasswordForm extends PureComponent {
    static propTypes = {
        handleSubmit: func,
        isLoading: bool,
        containerStyle: object,
    };

    render() {
        const { handleSubmit, isLoading, containerStyle } = this.props;

        return (
            <View style={[s.wrapper, { ...containerStyle }]}>
                <Field
                    name="password"
                    title="NEW PASSWORD"
                    type="password"
                    component={FieldInput}
                    autoCapitalize="none"
                    validate={required}
                />
                <Field
                    name="confirmPassword"
                    title="CONFIRM NEW PASSWORD"
                    type="password"
                    component={FieldInput}
                    autoCapitalize="none"
                    validate={required}
                />
                <PrimaryButton title="SET PASSWORD" onPress={handleSubmit} isLoading={isLoading} icon="arrow-right" />
            </View>
        );
    }
}

export default reduxForm({
    form: 'resetPassword',
})(ResetPasswordForm);
