export default {
    GET_CUT_SHEETS_ITEMS: 'GET_CUT_SHEETS_ITEMS',
    CREATE_CUT_SHEETS_ITEM: 'CREATE_CUT_SHEETS_ITEM',
    SET_CUT_SHEETS_ITEM: 'SET_CUT_SHEETS_ITEM',
    CLEAR_CUT_SHEETS_ITEM: 'CLEAR_CUT_SHEETS_ITEM',
    SAVE_CUT_SHEETS_ITEM_IMAGE: 'SAVE_CUT_SHEETS_ITEM_IMAGE',
    REMOVE_CUT_SHEETS_ITEM_IMAGE: 'REMOVE_CUT_SHEETS_ITEM_IMAGE',
    SET_ASSIGN_USERS: 'SET_ASSIGN_USERS',
    SUBMIT_CUT_SHEETS_ITEM: {
        LOADING: 'SUBMIT_CUT_SHEETS_ITEM',
        SUCCESS: 'SUBMIT_CUT_SHEETS_ITEM_SUCCESS',
        ERROR: 'SUBMIT_CUT_SHEETS_ITEM_ERROR',
    },
    SHAPE_UPLOAD: {
        START: 'SHAPE_UPLOAD_START',
        SUCCESS: 'SHAPE_UPLOAD_SUCCESS',
        ERROR: 'SHAPE_UPLOAD_ERROR',
    },
    LOADING: {
        START: 'SUBMIT_CUT_SHEETS_ITEM_START',
        FINISH: 'SUBMIT_CUT_SHEETS_ITEM_FINISH',
    },
    CLEAN_UP: 'CUT_SHEETS_CLEAN_UP',
};
