import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../../theme';

export default StyleSheet.create({
    listItemWrapper: {
        width: '90%',
        paddingHorizontal: 13,
        paddingVertical: 15,
        marginBottom: 10,
        backgroundColor: Color.white,
        borderRadius: 6,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        elevation: 5,
        alignSelf: 'center',
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    fabricationItem: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    body: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
        marginTop: 3,
        marginBottom: 8,
        alignItems: 'center',
    },
    subBody: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
        marginBottom: 8,
        alignItems: 'center',
    },
    listItemTitle: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue,
        alignSelf: 'flex-start',
        flex: 1,
        maxWidth: '95%',
    },
    listItemTitle_withIcon: {
        maxWidth: '80%',
    },
    listItemTitle_withIcon_required: {
        maxWidth: '60%',
    },
    listItemTitle_timeCard: {
        marginTop: 6,
    },
    listItemColumn: {
        flexDirection: 'row',
    },
    listItemColumn_timeCard: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    chevronRight: {
        fontSize: Font.size.font24,
        color: Color.turquoise,
        marginTop: 4,
    },
    listItemFabricationHeader: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    cardDate: {
        fontFamily: Font.type.regular,
        color: Color.light_grey_blue,
        fontSize: Font.size.font10,
    },
    itemFabricationStatus: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
    },
    successIcon: {
        borderRadius: 5,
        backgroundColor: Color.turquoise,
        justifyContent: 'center',
        alignItems: 'center',
        width: 9,
        height: 9,
    },
    successText: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font9,
        color: Color.light_grey_blue,
    },
    successText_ready: {
        color: Color.turquoise,
        marginLeft: 5,
        marginTop: -3,
    },
    cardTypeStatusText: {
        color: Color.red,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font10,
        paddingRight: 10,
    },
    cardTypeOfflineText: {
        color: '#f3a34d',
        fontFamily: Font.type.regular,
        fontSize: Font.size.font10,
    },
});
