import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme/index';

export default StyleSheet.create({
    bg: {
        width: '100%',
        height: '100%',
    },
    forgotPasswordTitle: {
        paddingHorizontal: '10%',
        color: Color.dark_navy_blue,
        fontSize: Font.size.font15,
        letterSpacing: 0.68,
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 40,
        fontFamily: Font.type.regular,
    },
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    mainContainer: {
        height: '100%',
    },
    logo: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingTop: '10%',
        paddingBottom: '10%',
    },
    logoImg: {
        width: 150,
        height: 50,
    },
    form: {
        height: '70%',
    },

    moduleLocked: {
        fontSize: Font.size.font21,
        color: Color.light_navy,
        textAlign: 'center',
    },
    contact: {
        fontSize: Font.size.font15,
        color: Color.light_navy,
        textAlign: 'center',
    },
    lockIconPadding: {
        alignContent: 'center',
        paddingTop: '30%',
        paddingLeft: '36%',
    },
});
