import React from 'react';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { createStackNavigator } from 'react-navigation-stack';
import { createDrawerNavigator } from 'react-navigation-drawer';
import { createAppContainer, createSwitchNavigator, NavigationActions } from 'react-navigation';
import { Platform } from 'react-native';

import { isEmpty } from 'lodash';
import {
    LoginScreen,
    TimeCardsScreen,
    TimeCardEditScreen,
    ClockScreen,
    ClockInScreen,
    ProjectsScreen,
    ClockInDescriptionScreen,
    CostCodeScreen,
    ForgotPasswordScreen,
    BreakScreen,
    DailyReportsScreen,
    DailyReportScreen,
    DailySurveyScreen,
    DailyNotesScreen,
    DailyWorkLogScreen,
    DailyWorkLogSelectorScreen,
    DailyWorkLogContractorsScreen,
    DailyWorkLogContractorScreen,
    DailyTaskingListScreen,
    GalleryScreen,
    DailyNewTaskScreen,
    ProjectInfoScreen,
    NewNoteScreen,
    // ChangeOrderItemScreen,
    // ChangeOrdersListScreen,
    // ChangeOrderItemPropertiesScreen,
    ItemFabricationScreen,
    ItemFabricationListScreen,
    // TimeAndMaterialListScreen,
    // TimeAndMaterialItemScreen,
    // TimeAndMaterialNewItemScreen,
    TimeAndMaterialItemClockInScreen,
    PaidTimeOffListScreen,
    PaidTimeOffItemScreen,
    ToolboxTalkDashboard,
    ToolboxTalkTicketDetailsScreen,
    ToolboxTalkCreateTicketScreen,
    InjuryReportingListScreen,
    InjuryReportingItemScreen,
    AccidentReportingListScreen,
    AccidentReportingAddNewStepOneScreen,
    AccidentReportingAddNewStepTwoScreen,
    AccidentReportingAddNewStepThreeScreen,
    // AdminViewerTimeCardDetailsScreen,
    // AdminViewerTimeCardsScreen,
    // AdminViewerUsersScreen,
    // ItemRequestDashboard,
    // ItemRequestItemsRequested,
    // ItemRequestAddNew,
    HowToListScreen,
    // OutstandingDailyReportsDashboard,
    OutstandingTimeCardScreen,
    EmployeeHandbookDashboard,
    UsersScreen,
    ResetPasswordScreen,
    LockedModuleScreen,
    CustomReportsListScreen,
    CustomReportReportsScreen,
    CustomReportScreen,
    ScheduleDashboardScreen,
    CommunicationsScreen,
    ScheduleScreen,
    AvailableShiftsScreen,
    ShiftDetailsScreen,
    PayrollCheckDate,
    BulkTimeCardEditScreen,
    ReimbursementScreen,
    ReimbursementListScreen,
} from '../screens';

import { TabBarLabel, TabBarIcon, LeftMenu } from '../components';
import hasAccessTo from '../utils/auth';
import { Config } from '../config';

import { Color } from '../theme';

const isIos = Platform.OS === 'ios';

let _navigator;

/**
 * StartStack
 */

const StartStack = createStackNavigator(
    {
        Login: { screen: LoginScreen },
        ForgotPassword: { screen: ForgotPasswordScreen },
        ResetPasswordScreen: {
            screen: ResetPasswordScreen,
            path: `${Config.CONSUMER_WEB_HOST}/login/reset/:email/:resetKey`,
        },
    },
    {
        initialRouteName: 'Login',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
    },
);
/**
 * WatchStack
 */

const ClockStack = createStackNavigator(
    {
        Clock: { screen: ClockScreen },
        ClockIn: { screen: ClockInScreen },
        Projects: { screen: ProjectsScreen },
        ClockInDescription: { screen: ClockInDescriptionScreen },
        CostCode: { screen: CostCodeScreen },
        Break: { screen: BreakScreen },
        ProjectInfo: { screen: ProjectInfoScreen },
        TimeAndMaterialItemClockInScreen: {
            screen: TimeAndMaterialItemClockInScreen,
        },
    },
    {
        initialRouteName: 'Clock',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
        tabBarVisible: true,
    },
);

/**
 * SettingsStack
 */

const TimeCardsStack = createStackNavigator(
    {
        TimeCards: { screen: TimeCardsScreen },
        TimeCardEdit: { screen: TimeCardEditScreen },
        TimeCardEditProjects: { screen: ProjectsScreen },
        TimeCardEditCostCodes: { screen: CostCodeScreen },
    },
    {
        initialRouteName: 'TimeCards',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
    },
);

/**
 * Daily reports
 */

const DailyReportingStack = createStackNavigator(
    {
        Reports: { screen: DailyReportsScreen },
        Gallery: { screen: GalleryScreen },
        DailyReport: { screen: DailyReportScreen },
        DailySurvey: { screen: DailySurveyScreen },
        DailyNotes: { screen: DailyNotesScreen },
        DailyNote: { screen: NewNoteScreen },
        DailyWorkLog: { screen: DailyWorkLogScreen },
        DailyWorkLogSelector: { screen: DailyWorkLogSelectorScreen },
        DailyWorkLogContractors: { screen: DailyWorkLogContractorsScreen },
        DailyWorkLogContractor: { screen: DailyWorkLogContractorScreen },
        DailyTaskingList: { screen: DailyTaskingListScreen },
        DailyNewTask: { screen: DailyNewTaskScreen },
        SwitchProjectsViewing: { screen: ProjectsScreen },
        Users: { screen: UsersScreen },
    },
    {
        initialRouteName: 'Reports',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
        tabBarVisible: true,
    },
);

/*
const ChangeOrderStack = createStackNavigator(
  {
    ChangeOrderList: ChangeOrdersListScreen,
    ChangeOrderItem: ChangeOrderItemScreen,
    ChangeOrderItemProperties: ChangeOrderItemPropertiesScreen,
  },
  {
    initialRouteName: 'ChangeOrderList',
    headerMode: 'none',
    cardStyle: { opacity: 1 },
    tabBarVisible: true,
  },
)
*/

const PaidTimeOffStack = createStackNavigator(
    {
        PaidTimeOffList: PaidTimeOffListScreen,
        PaidTimeOffItem: PaidTimeOffItemScreen,
    },
    {
        initialRouteName: 'PaidTimeOffList',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
        tabBarVisible: true,
    },
);

const CustomReportsStack = createStackNavigator(
    {
        CustomReportsList: CustomReportsListScreen,
        CustomReportReports: CustomReportReportsScreen,
        CustomReport: CustomReportScreen,
    },
    {
        initialRouteName: 'CustomReportsList',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
        tabBarVisible: true,
    },
);

const InjuryReportingStack = createStackNavigator(
    {
        InjuryReportingList: InjuryReportingListScreen,
        InjuryReportingItem: InjuryReportingItemScreen,
    },
    {
        initialRouteName: 'InjuryReportingList',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
        tabBarVisible: true,
    },
);

const ItemFabricationStack = createStackNavigator(
    {
        ItemFabricationList: ItemFabricationListScreen,
        ItemFabricationItem: ItemFabricationScreen,
    },
    {
        initialRouteName: 'ItemFabricationList',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
        tabBarVisible: true,
    },
);

/*
const TimeAndMaterialStack = createStackNavigator(
  {
    TimeAndMaterialList: TimeAndMaterialListScreen,
    TimeAndMaterialItem: TimeAndMaterialItemScreen,
    TimeAndMaterialNewItem: TimeAndMaterialNewItemScreen,
  },
  {
    initialRouteName: 'TimeAndMaterialList',
    headerMode: 'none',
    cardStyle: { opacity: 1 },
    tabBarVisible: true,
  },
)
*/

const AccidentReportingStack = createStackNavigator(
    {
        AccidentReportingList: AccidentReportingListScreen,
        AccidentReportingAddNewStepOne: AccidentReportingAddNewStepOneScreen,
        AccidentReportingAddNewStepTwo: AccidentReportingAddNewStepTwoScreen,
        AccidentReportingAddNewStepThree: AccidentReportingAddNewStepThreeScreen,
    },
    {
        initialRouteName: 'AccidentReportingList',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
        tabBarVisible: true,
    },
);

const ReimbursementStack = createStackNavigator(
    {
        ReimbursementList: ReimbursementListScreen,
        ReimbursementRequest: ReimbursementScreen,
    },
    {
        initialRouteName: 'ReimbursementList',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
        tabBarVisible: true,
    },
);

/*
const ItemRequestStack = createStackNavigator(
  {
    ItemRequestList: ItemRequestDashboard,
    ItemRequestItemsRequested,
    ItemRequestAddNew,
  },
  {
    initialRouteName: 'ItemRequestList',
    headerMode: 'none',
    cardStyle: { opacity: 1 },
    tabBarVisible: true,
  },
)
*/

const ToolboxTalkStack = createStackNavigator(
    {
        ToolboxTalkList: ToolboxTalkDashboard,
        ToolboxTalkTicketDetails: ToolboxTalkTicketDetailsScreen,
        ToolboxTalkCreateTicket: ToolboxTalkCreateTicketScreen,
    },
    {
        initialRouteName: 'ToolboxTalkList',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
        tabBarVisible: true,
    },
);

const HowToStack = createStackNavigator(
    {
        HowToList: HowToListScreen,
    },
    {
        initialRouteName: 'HowToList',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
        tabBarVisible: true,
    },
);

const LockedModuleStack = createStackNavigator(
    {
        LockedModule: LockedModuleScreen,
    },
    {
        initialRouteName: 'LockedModule',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
        tabBarVisible: true,
    },
);

/*
const AdminViewerStack = createStackNavigator(
  {
    AdminViewerList: AdminViewerUsersScreen,
    AdminViewerTimeCards: AdminViewerTimeCardsScreen,
    AdminViewerTimeCardDetails: AdminViewerTimeCardDetailsScreen,
  },
  {
    initialRouteName: 'AdminViewerList',
    headerMode: 'none',
    cardStyle: { opacity: 1 },
    tabBarVisible: true,
  },
)
*/

const OutstandingDailyReportsStack = createStackNavigator(
    {
        // OutstandingReportsList: OutstandingDailyReportsDashboard,
        OutstandingTimeCards: OutstandingTimeCardScreen,
    },
    {
        initialRouteName: 'OutstandingTimeCards',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
        tabBarVisible: true,
    },
);

const EmployeeHandbookStack = createStackNavigator(
    {
        EmployeeHandbookDashboard,
    },
    {
        initialRouteName: 'EmployeeHandbookDashboard',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
        tabBarVisible: true,
    },
);

const ScheduleStack = createStackNavigator(
    {
        ScheduleDashboard: { screen: ScheduleDashboardScreen },
        Communications: { screen: CommunicationsScreen },
        Schedule: { screen: ScheduleScreen },
        AvailableShifts: { screen: AvailableShiftsScreen },
        ShiftDetails: { screen: ShiftDetailsScreen },
    },
    {
        initialRouteName: 'ScheduleDashboard',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
    },
);

const PayrollCheckDateStack = createStackNavigator(
    {
        PayrollCheckDate: { screen: PayrollCheckDate },
    },
    {
        initialRouteName: 'PayrollCheckDate',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
    },
);

const BulkTimeCardEditStack = createStackNavigator(
    {
        BulkTimeCardEdit: { screen: BulkTimeCardEditScreen },
        BulkTimeCardEditProjects: { screen: ProjectsScreen },
        BulkTimeCardEditCostCodes: { screen: CostCodeScreen },
    },
    {
        initialRouteName: 'BulkTimeCardEdit',
        headerMode: 'none',
        cardStyle: { opacity: 1 },
    },
);

const createProjectsStack = (params = {}) => {
    return createStackNavigator(
        {
            Projects: { screen: ProjectsScreen },
            ProjectInfo: { screen: ProjectInfoScreen },
        },
        {
            initialRouteName: 'Projects',
            initialRouteParams: params,
            headerMode: 'none',
            cardStyle: { opacity: 1 },
            tabBarVisible: true,
        },
    );
};

/**
 * Tabs
 */
const createTabNavigator = (user) => {
    const routes = {};
    let initialRouteName = 'ClockStack';
    const pathname = window.location.pathname;
    if (pathname === '/time-cards') {
        initialRouteName = 'TimeCardsStack';
    }

    if ((user && hasAccessTo(user, Config.TIMECARDS)) || !user) {
        routes.ClockStack = { screen: ClockStack };
        routes.TimeCardsStack = { screen: TimeCardsStack };
    } else {
        initialRouteName = 'ReportsStack';
    }

    if ((user && hasAccessTo(user, Config.REPORTS)) || !user) {
        routes.ReportsStack = { screen: DailyReportingStack };
    }

    if (Object.keys(routes).length === 0) {
        return null;
    }

    const navigationOptions = () => {
        let tabBarVisible = true;

        if (['/time-cards', '/clock'].includes(window.location.pathname)) {
            tabBarVisible = false;
        }
        return {
            tabBarVisible,
        };
    };

    ClockStack.navigationOptions = navigationOptions;
    TimeCardsStack.navigationOptions = navigationOptions;

    const tabs = createBottomTabNavigator(routes, {
        initialRouteName,
        animationEnabled: false,
        lazy: false,
        swipeEnabled: false,
        defaultNavigationOptions: ({ navigation }) => ({
            tabBarIcon: (tabBarItem) => {
                const { focused, tintColor } = tabBarItem;
                const { routeName } = navigation.state;
                let iconName;
                switch (routeName) {
                    case 'ClockStack':
                        iconName = 'clock-outline';
                        break;
                    case 'TimeCardsStack':
                        iconName = 'card-text-outline';
                        break;
                    default:
                        iconName = 'chart-line-variant';
                }
                return <TabBarIcon iconName={iconName} focused={focused} tintColor={tintColor} />;
            },
            tabBarLabel: (tabBarItem) => {
                const { focused } = tabBarItem;
                const { routeName } = navigation.state;
                let labelName;

                switch (routeName) {
                    case 'ClockStack':
                        labelName = 'Clock';
                        break;
                    case 'TimeCardsStack':
                        labelName = 'Time Cards';
                        break;
                    default:
                        labelName = 'Reports';
                }
                return <TabBarLabel focused={focused} labelName={labelName} />;
            },
            tabBarOnPress: ({ navigation, defaultHandler }) => {
                // prevent switching to initial route when clicking on the same tab
                const parentNavigation = navigation.dangerouslyGetParent();
                const prevRoute = parentNavigation.state.routes[parentNavigation.state.index];
                const nextRoute = navigation.state;
                if (prevRoute.routeName !== nextRoute.routeName) {
                    defaultHandler();
                }
            },
        }),
        tabBarOptions: {
            style: {
                backgroundColor: Color.white,
                cursor: 'pointer',
                height: isIos ? '10%' : 60,
                paddingBottom: isIos ? 0 : 10,
                borderTopColor: Color.white,
                borderTopWidth: 0,
                marginBottom: 0,
                shadowColor: Color.grey,
                shadowOffset: isIos ? { width: 0, height: 0 } : { width: 0, height: -25 },
                shadowOpacity: isIos ? 0.1 : 0.3,
                shadowRadius: isIos ? 25 : 50,
                elevation: 40,
            },
            activeTintColor: Color.white,
            inactiveTintColor: Color.grey,
        },
    });
    return tabs;
};

const getMainRouteName = () => {
    const pathname = window.location.pathname;
    let route = null;

    switch (pathname) {
        case '/clock':
            route = 'TimeTracking';
            break;
        case '/time-cards':
            route = 'TimeTracking';
            break;
        case '/schedule':
            route = 'Schedule';
            break;
        case '/pto':
            route = 'PaidTimeOff';
            break;
        case '/reimbursement':
            // TODO: assign route and subRoute after adding reimbursement functionality
            break;
    }

    return route;
};

/**
 *Main
 * */
const createMainNavigator = (user) => {
    let initialRouteName = '';
    const routes = {
        HowTo: HowToStack,
        Reimbursement: ReimbursementStack,
        LockedModule: LockedModuleStack,
        Schedule: ScheduleStack,
        PayrollCheckDate: PayrollCheckDateStack,
        BulkTimeCardEdit: BulkTimeCardEditStack,
    };
    const tabs = createTabNavigator(user);
    if (tabs) {
        routes.TimeTracking = tabs;
        initialRouteName = 'TimeTracking';
    }

    if ((user && hasAccessTo(user, Config.TIMECARDS)) || !user) {
        routes.OutstandingReports = OutstandingDailyReportsStack;
    }

    if ((user && hasAccessTo(user, Config.PROJECTS)) || !user) {
        routes.ProjectsStandalone = createProjectsStack(
            initialRouteName ? {} : { standalone: true, isTimeCardEdit: false, isInjuryReportEdit: false },
        );
        if (!initialRouteName) {
            initialRouteName = 'ProjectsStandalone';
        }
    }

    if ((user && hasAccessTo(user, Config.CUT_SHEETS)) || !user) {
        routes.ItemFabrication = ItemFabricationStack;
        if (!initialRouteName) {
            initialRouteName = 'ItemFabrication';
        }
    }

    if ((user && hasAccessTo(user, Config.TIME_OFF)) || !user) {
        routes.PaidTimeOff = PaidTimeOffStack;
        if (!initialRouteName) {
            initialRouteName = 'PaidTimeOff';
        }
    }

    if ((user && hasAccessTo(user, Config.CUSTOM_REPORTS)) || !user) {
        routes.CustomReportScreen = CustomReportsStack;
        if (!initialRouteName) {
            initialRouteName = 'CustomReportScreen';
        }
    }

    if ((user && hasAccessTo(user, Config.ACCIDENT_REPORTING)) || !user) {
        routes.AccidentReporting = AccidentReportingStack;
        if (!initialRouteName) {
            initialRouteName = 'AccidentReporting';
        }
    }

    if ((user && hasAccessTo(user, Config.TOOLBOX_TALKS)) || !user) {
        routes.ToolboxTalk = ToolboxTalkStack;
        if (!initialRouteName) {
            initialRouteName = 'ToolboxTalk';
        }
    }

    if ((user && hasAccessTo(user, Config.INJURY_REPORTING_TICKETS)) || !user) {
        routes.InjuryReporting = InjuryReportingStack;
        if (!initialRouteName) {
            initialRouteName = 'InjuryReporting';
        }
    }

    if ((user && hasAccessTo(user, Config.EMPLOYEE_HANDBOOK)) || !user) {
        routes.EmployeeHandbook = EmployeeHandbookStack;
        if (!initialRouteName) {
            initialRouteName = 'EmployeeHandbook';
        }
    }

    if (!initialRouteName) {
        initialRouteName = 'HowTo';
    }

    const routeName = getMainRouteName();
    if (routeName) {
        initialRouteName = routeName;
    }

    const main = createDrawerNavigator(routes, {
        initialRouteName,
        drawerWidth: () => {
            return 500;
        },
        drawerHeight: '100%',
        contentComponent: LeftMenu,
    });

    return main;
};

const AppNavigator = (user) => {
    let routeName = 'Main';

    if (isEmpty(user)) {
        // user not logged in - start with login screen
        routeName = 'StartStack';
    }

    const main = createMainNavigator(user);

    return createSwitchNavigator(
        {
            StartStack: { screen: StartStack, path: '' },
            Main: { screen: main },
        },
        {
            initialRouteName: routeName,
            backBehavior: 'none',
        },
    );
};

const createRootNavigator = (user) => createAppContainer(AppNavigator(user));

function setTopLevelNavigator(navigatorRef) {
    _navigator = navigatorRef;
}

function navigate(routeName, subRoute, params) {
    _navigator.dispatch(
        NavigationActions.navigate({
            routeName,
            params,
            action: subRoute ? NavigationActions.navigate({ routeName: subRoute, params }) : null,
        }),
    );
}

export default { createRootNavigator, navigate, setTopLevelNavigator };
