import { StyleSheet, Dimensions, Platform } from 'react-native';
import { Font, Color } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        paddingBottom: 220,
    },
    logo: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingTop: '10%',
        paddingBottom: '10%',
    },
    logoImg: {
        width: 150,
        height: 50,
    },
    form: {
        height: '70%',
    },
    menuTopContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'column',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 20,
    },
    topMenu_title: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
    },
    topMenu_title_tracking: {
        color: Color.greySteel,
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
    },
    viewingProjectTitle: {
        fontSize: Font.size.font10,
        fontFamily: Font.type.regular,
        color: '#b2becc',
        paddingBottom: 10,
        paddingTop: 15,
        paddingLeft: '5%',
    },
    viewingProject: {
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
        color: Color.blue,
        paddingLeft: '5%',
    },
    viewProjectInfo: {
        display: 'flex',
    },
    projectNames: {
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
        color: Color.blue,
        paddingLeft: '6%',
        paddingBottom: '2%',
    },
    bottomButtonsBlock: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        position: 'absolute',
        bottom: 110,
        elevation: 12,
        height: 80,
        width: '100%',
    },
    addButton: {
        width: 43,
        height: 43,
        borderRadius: 22,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.turquoise,
        elevation: 5,
        shadowColor: Color.light_grey_blue,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.5,
        shadowRadius: 5,
    },
    weatherButton: {
        width: 120,
        height: 18,
        opacity: 0.25,
        marginTop: 20,
    },
    weatherButtonImage: {
        width: 120,
        height: 18,
    },
    closeButton: {
        width: 43,
        height: 43,
        borderRadius: 22,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        backgroundColor: Color.turquoise,
        top: 0,
        right: 20,
        elevation: 12,
        zIndex: 999,
    },
    editProject: {
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        right: 20,
        elevation: 12,
        zIndex: 999,
        paddingTop: 20,
    },
    editUser: {
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        right: 20,
        elevation: 12,
        zIndex: 999,
        paddingTop: 10,
    },
    pdfContainer: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 25,
    },
    pdfViewer: {
        flex: 1,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    },
    menuTopContainerUsers: {
        backgroundColor: Color.very_light_blue,
        width: '100%',
        height: 50,
        borderTopRightRadius: 30,
        borderTopLeftRadius: 30,
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '5%',
    },
    topMenu_user: {
        color: Color.greySteel,
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
        paddingLeft: 10,
    },
    topMenuUserPhoto: {
        width: 40,
        height: 40,
        borderRadius: 400 / 2,
    },
});
