import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { View, Text, FlatList, KeyboardAvoidingView, Platform, TouchableOpacity, Image } from 'react-native';
import { connect } from 'react-redux';
import { isEmpty, cloneDeep, isNil } from 'lodash';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { showMessage } from 'react-native-flash-message';
import { saveCustomReportItem } from '../../../store/modules/customReports';
import {
    NavigationBar,
    FieldInput,
    YesNoAnswer,
    MultipleChoseAnswer,
    PrimaryButton,
    ConfirmationDialog,
    FileUploadModal,
    Canvas,
} from '../../../components/index';
import s from './styles';
import { Color } from '../../../theme/index';
import { generateId, downloadImage } from '../../../utils/helpers';
import { isUploadingOverdue } from '../../../store/helpers/common';

const _getAttachmentData = (item, attachmentsCount) => {
    return {
        id: generateId(),
        uploading: false,
        uploaded: false,
        name: `${moment().format('DD_MM_YYYY')}_custom_report_attachment_${attachmentsCount}`,
        type: item.type,
        fileName: `${moment().format('DD_MM_YYYY')}_custom_report_attachment_${attachmentsCount}.jpg`,
        types: ['custom report answer attachment'],
        subtypes: [],
        tags: [
            {
                value: 'custom report answer attachment',
                protected: true,
                category: 'type',
            },
        ],
        file: item.base64,
        path: item.uri,
    };
};

class CustomReportScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentReport: PropTypes.object,
        currentCustomReportItem: PropTypes.object,
        user: PropTypes.object,
        saveCustomReportItem: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { currentCustomReportItem } = this.props;
        const report = cloneDeep(currentCustomReportItem.Survey);

        // set ids
        for (let i = 0; i < report.Sections.length; i++) {
            const section = report.Sections[i];
            section.id = generateId();

            for (let j = 0; j < section.Questions.length; j++) {
                const question = section.Questions[j];
                question.id = generateId();
            }
        }

        this.state = {
            isUpdated: false,
            report,
            confirmationDialog: false,
            confirmationText: 'Please make sure to fill out all required items.',
            isAttachmentModal: false,
            currentSection: null,
            currentQuestion: null,
            allRequiredFieldsFiledOut: true,
            isImageSelected: false,
            selectedImage: null,
        };
    }

    updateReport(data = {}) {
        const { item, value, description } = data;
        const { report } = this.state;
        const newReport = cloneDeep(report);
        const newItem = {
            Label: item.Label,
            Options: item.Options,
            PhotoRequired: item.PhotoRequired,
            QuestionType: item.QuestionType,
            Required: item.Required,
            Value: value || description,
            Attachments: item.Attachments || [],
        };
        newReport.Sections.forEach((s, i) => {
            s.Questions.forEach((q, index) => {
                if (q.Label === newItem.Label) {
                    newReport.Sections[i].Questions[index] = {
                        ...newReport.Sections[i].Questions[index],
                        ...newItem,
                    };
                }
            });
        });
        this.setState({
            report: newReport,
            isUpdated: true,
        });
    }

    checkRequiredFields() {
        const { report } = this.state;
        let allRequiredFieldsFiledOut = true;
        for (const section of report.Sections) {
            for (const question of section.Questions) {
                if (question.Required === true && !question.Value && !question.AdminOnly) {
                    allRequiredFieldsFiledOut = false;
                }
            }
        }
        this.setState({ allRequiredFieldsFiledOut });
        return allRequiredFieldsFiledOut;
    }

    save = () => {
        const { navigation, saveCustomReportItem, user } = this.props;
        const { report } = this.state;
        const allRequiredFieldsFiledOut = this.checkRequiredFields();
        if (allRequiredFieldsFiledOut) {
            const reportToSave = {
                ...report,
                Sections: report.Sections.map((section) => {
                    // skip id, collapsed field
                    const { id, Collapsed, Attachments, Questions, ...other } = section;

                    other.Attachments = Attachments.map((attachment) => {
                        const { downloading, Attachment, AttachmentAssetId, ...updatedAttachment } = attachment;
                        if (Attachment) {
                            return { Attachment, AttachmentAssetId };
                        }

                        return updatedAttachment;
                    });

                    other.Questions = Questions.map((question) => {
                        const { id, Attachments, ...updatedQuestion } = question;

                        updatedQuestion.Attachments = Attachments.map((attachment) => {
                            const { downloading, Attachment, AttachmentAssetId, ...updatedAttachment } = attachment;
                            if (Attachment) {
                                return { Attachment, AttachmentAssetId };
                            }

                            return updatedAttachment;
                        });

                        return updatedQuestion;
                    });
                    return other;
                }),
            };
            saveCustomReportItem(reportToSave, user);
            navigation.navigate('CustomReportsList');
        } else {
            this.setState({ confirmationDialog: true });
        }
    };

    renderFillInTheBlank(item) {
        return (
            <FieldInput
                multiline
                title="PLEASE DESCRIBE"
                style={s.fillInFieldInput}
                input={{
                    onChange: (description) => {
                        this.updateReport({ item, description });
                    },
                }}
                meta={{
                    error: null,
                    touched: false,
                }}
                initialValues={item.Value}
            />
        );
    }

    renderTrueOrFalse(item) {
        return <YesNoAnswer isTwo answer={item.Value} onChange={(value) => this.updateReport({ item, value })} />;
    }

    renderMultipleChose(item) {
        return (
            <MultipleChoseAnswer
                answer={item.Value}
                item={item}
                onChange={(value) => this.updateReport({ item, value })}
            />
        );
    }

    removeAttachment(removedAttachment, id, isSection) {
        const { uploaded, uploading, uploadingStartTime } = removedAttachment;
        const uploadingOverdue = isUploadingOverdue(uploadingStartTime);

        let showErrorMessage = false;
        let errorMessage;

        if (removedAttachment.id && !isNil(uploaded) && !uploaded && uploading && !uploadingOverdue) {
            errorMessage = 'This attachment is currently uploading. Please wait.';
            showErrorMessage = true;
        } else if (removedAttachment.downloading) {
            errorMessage = 'This attachment is currently downloading. Please wait.';
            showErrorMessage = true;
        }

        if (showErrorMessage) {
            showMessage({
                message: errorMessage,
                backgroundColor: Color.faded_orange,
                color: Color.dark_navy_blue,
                duration: 10000,
                animationDuration: 0,
                hideOnPress: true,
                hideStatusBar: true,
            });

            return false;
        }

        const { report } = this.state;
        const newReport = cloneDeep(report);

        let attachmentsItem = null;
        if (isSection) {
            attachmentsItem = newReport.Sections.find((s) => s.id === id);
        } else {
            for (const section of newReport.Sections) {
                attachmentsItem = section.Questions.find((q) => q.id === id);
                if (attachmentsItem) {
                    break;
                }
            }
        }

        if (removedAttachment.id) {
            attachmentsItem.Attachments = attachmentsItem.Attachments.filter((a) => a.id !== removedAttachment.id);
        } else {
            attachmentsItem.Attachments = attachmentsItem.Attachments.filter((a) => a.File !== removedAttachment.File);
        }

        this.setState({
            report: newReport,
            isUpdated: true,
        });

        return true;
    }

    _updateAttachment(attachment, itemId, isSection) {
        const { report } = this.state;
        const updatedReport = cloneDeep(report);

        let item = null;
        for (let i = 0; i < updatedReport.Sections.length; i++) {
            const section = updatedReport.Sections[i];

            if (isSection && section.id === itemId) {
                item = section;
                break;
            }

            if (!isSection && !item) {
                for (let j = 0; j < section.Questions.length; j++) {
                    const question = section.Questions[j];
                    if (question.id === itemId) {
                        item = question;
                        break;
                    }
                }
            }
        }

        if (item) {
            for (let i = 0; i < item.Attachments.length; i++) {
                if (attachment.AttachmentAssetId === item.Attachments[i].AttachmentAssetId) {
                    item.Attachments[i] = {
                        ...item.Attachments[i],
                        ...attachment,
                    };
                }
            }
        }

        return updatedReport;
    }

    _selectOrDownloadImage = (attachment, item, isSection) => {
        const updatedState = {};

        if (isSection) {
            updatedState.currentSection = item;
            updatedState.currentQuestion = null;
        } else {
            updatedState.currentSection = null;
            updatedState.currentQuestion = item;
        }

        if (attachment.Attachment && !attachment.file) {
            const { downloading } = attachment;
            if (!downloading) {
                this.setState({
                    report: this._updateAttachment(
                        {
                            ...attachment,
                            downloading: true,
                        },
                        item.id,
                        isSection,
                    ),
                });

                downloadImage(attachment.Attachment).then((image) => {
                    if (image) {
                        const { base64Image, path, type } = image;
                        if (base64Image && path) {
                            const updatedAttachment = {
                                ...attachment,
                                downloading: false,
                                ..._getAttachmentData(
                                    {
                                        type,
                                        base64: base64Image,
                                        uri: path,
                                    },
                                    item.Attachments.length + 1,
                                ),
                            };
                            updatedState.report = this._updateAttachment(updatedAttachment, item.id, isSection);
                            updatedState.selectedImage = updatedAttachment;
                            updatedState.isImageSelected = true;
                            this.setState(updatedState);
                        }
                    } else {
                        this.setState({
                            report: this._updateAttachment(
                                {
                                    ...attachment,
                                    downloading: false,
                                },
                                item.id,
                                isSection,
                            ),
                        });
                    }
                });
            }
        } else {
            updatedState.selectedImage = attachment;
            updatedState.isImageSelected = true;
            this.setState(updatedState);
        }
    };

    _renderAttachment(attachment, i, item, isSection) {
        return (
            <TouchableOpacity
                style={s.attachmentItem}
                key={`${item.id}_${i}`}
                onPress={() => this._selectOrDownloadImage(attachment, item, isSection)}
            >
                <Image
                    source={{
                        uri: attachment.Attachment ? attachment.Attachment : `data:image/png;base64,${attachment.file}`,
                    }}
                    style={s.attachmentImage}
                />
                {(attachment.uploading || attachment.downloading) && (
                    <View style={s.uploadingWrapper}>
                        <Text style={s.uploadingLabel}>{attachment.uploading ? 'uploading...' : 'downloading...'}</Text>
                    </View>
                )}
                <TouchableOpacity
                    style={s.attachmentButton}
                    onPress={() => this.removeAttachment(attachment, item.id, isSection)}
                >
                    <Icon name="close" size={25} color={Color.turquoise} />
                </TouchableOpacity>
            </TouchableOpacity>
        );
    }

    _renderQuestion(item) {
        const { allRequiredFieldsFiledOut } = this.state;
        if (!item.AdminOnly) {
            return (
                <View key={item.id} style={s.surveyItem}>
                    <View style={s.itemRow}>
                        <Text
                            style={[
                                s.itemTitle,
                                !allRequiredFieldsFiledOut && item.Required && !item.Value && s.itemTitleRequired,
                            ]}
                        >
                            {item.Required ? `${item.Label} *` : item.Label}
                        </Text>
                        {item.PhotoRequired ? (
                            <TouchableOpacity
                                onPress={() => {
                                    this.setState({
                                        isAttachmentModal: true,
                                        currentQuestion: item,
                                        currentSection: null,
                                    });
                                }}
                                style={s.iconWrapper}
                            >
                                <Icon name="attachment" color={Color.turquoise} size={20} />
                            </TouchableOpacity>
                        ) : null}
                    </View>
                    <View style={s.itemRow}>
                        {item.QuestionType === 'F' ? this.renderFillInTheBlank(item) : null}
                        {item.QuestionType === 'T' ? this.renderTrueOrFalse(item) : null}
                        {item.QuestionType === 'M' ? this.renderMultipleChose(item) : null}
                    </View>
                    <View style={[s.attachmentsWrapper, item.QuestionType === 'T' && { marginTop: 10 }]}>
                        {!isEmpty(item.Attachments) &&
                            item.Attachments.map((a, i) => {
                                return this._renderAttachment(a, i, item, false);
                            })}
                    </View>
                    {!allRequiredFieldsFiledOut && item.Required && !item.Value ? (
                        <Text style={[s.itemRequiredMessage, item.QuestionType === 'T' && { marginTop: 10 }]}>
                            Please don’t leave this field empty
                        </Text>
                    ) : null}
                </View>
            );
        }
        return null;
    }

    collapseSection(i) {
        const { report } = this.state;
        const newReport = cloneDeep(report);

        newReport.Sections[i].Collapsed = !newReport.Sections[i].Collapsed;

        this.setState({
            report: newReport,
        });
    }

    _renderSection = (item, index) => {
        if (!item.AdminOnly) {
            return (
                <View>
                    <TouchableOpacity onPress={() => this.collapseSection(index)}>
                        <View style={[s.itemRow, s.section]}>
                            <Text style={[s.sectionName, item.PhotoRequired && s.sectionNameWithAttachments]}>
                                {item.Section}
                            </Text>
                            <View style={s.sectionActions}>
                                {item.PhotoRequired && (
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.setState({
                                                isAttachmentModal: true,
                                                currentQuestion: null,
                                                currentSection: item,
                                            });
                                        }}
                                        style={[s.iconWrapper, s.iconSectionWrapper]}
                                    >
                                        <Icon name="attachment" color={Color.turquoise} size={20} />
                                    </TouchableOpacity>
                                )}
                                {item.Collapsed ? (
                                    <Icon name="arrow-down" style={s.collapseIcon} size={30} />
                                ) : (
                                    <Icon name="arrow-up" style={s.collapseIcon} size={30} />
                                )}
                            </View>
                        </View>
                    </TouchableOpacity>
                    {!item.Collapsed && (
                        <>
                            <View style={s.attachmentsWrapper}>
                                {!isEmpty(item.Attachments) &&
                                    item.Attachments.map((a, i) => {
                                        return this._renderAttachment(a, i, item, true);
                                    })}
                            </View>
                            {item.Questions.map((question) => {
                                return this._renderQuestion(question);
                            })}
                        </>
                    )}
                </View>
            );
        }
        return null;
    };

    setAttachments(items) {
        const { report, currentSection, currentQuestion } = this.state;
        const newReport = cloneDeep(report);

        let i = 1;

        let attachmentsItem = null;
        if (currentSection) {
            attachmentsItem = newReport.Sections.find((s) => s.id === currentSection.id);
        } else if (currentQuestion) {
            for (const section of newReport.Sections) {
                attachmentsItem = section.Questions.find((q) => q.id === currentQuestion.id);
                if (attachmentsItem) {
                    break;
                }
            }
        }
        let data = null;
        for (const item of items) {
            const currentLength = attachmentsItem.Attachments.length;
            data = _getAttachmentData(item, currentLength + i);
            attachmentsItem.Attachments.push(data);
            i++;
        }
        this.setState({
            isAttachmentModal: false,
            report: newReport,
            isUpdated: true,
        });
    }

    saveImageDrawing = (image) => {
        const { report, currentSection, currentQuestion } = this.state;
        const newReport = cloneDeep(report);

        let attachmentsItem = null;
        if (currentSection) {
            attachmentsItem = newReport.Sections.find((s) => s.id === currentSection.id);
        } else if (currentQuestion) {
            for (const section of newReport.Sections) {
                attachmentsItem = section.Questions.find((q) => q.id === currentQuestion.id);
                if (attachmentsItem) {
                    break;
                }
            }
        }

        attachmentsItem.Attachments.forEach((a, i) => {
            if (a.id === image.id) {
                delete image.Attachment;
                delete image.AttachmentAssetId;
                attachmentsItem.Attachments.splice(i, 1, image);
            }
        });

        this.setState({ isImageSelected: false, selectedImage: null, report: newReport, isUpdated: true });
    };

    close = () => {
        this.setState({ isImageSelected: false, selectedImage: null });
    };

    closeConfirmationDialog = () => {
        this.setState({ confirmationDialog: false });
    };

    requiredText() {
        const { report } = this.state;
        let message = '';
        let requiredCount = 0;
        let numberOfQuestions = 0;
        for (const section of report.Sections) {
            for (const question of section.Questions) {
                if (question.Required === true) {
                    requiredCount += 1;
                }

                numberOfQuestions += 1;
            }
        }

        if (requiredCount === numberOfQuestions) {
            message = '(All fields are required)';
        } else if (requiredCount > 0) {
            message = '* Required Information';
        }
        return message;
    }

    render() {
        const {
            isUpdated,
            report,
            confirmationText,
            confirmationDialog,
            isAttachmentModal,
            isImageSelected,
            selectedImage,
        } = this.state;
        const backConfirmText =
            'You are about to back out of report. All not saved data will be lost. Are you sure you want to do this?';
        const backTitle = 'Warning';
        const requiredText = this.requiredText();
        const footerComponent = (
            <View style={s.buttonWrapper}>
                <PrimaryButton title="SAVE" onPress={this.save} disabled={false} />
            </View>
        );
        return (
            <View style={[s.wrapper]}>
                <NavigationBar
                    {...this.props}
                    title="REPORT"
                    backIcon
                    backConfirm={isUpdated ? { title: backTitle, text: backConfirmText } : null}
                />
                <View style={s.menuTopContainer}>
                    <Text style={s.topMenu_title}>{moment().format('MM/DD/YY')}</Text>
                </View>

                <KeyboardAvoidingView style={[s.mainContainer]} behavior='height'>
                    <View style={s.headerRow}>
                        <Text style={s.reportName}>{report.CustomReportName}</Text>
                        <Text style={s.requiredText}>{requiredText}</Text>
                    </View>
                    <FlatList
                        data={report.Sections}
                        contentContainerStyle={{ paddingBottom: 0 }}
                        style={{ elevation: 20, width: '100%' }}
                        renderItem={({ item, index }) => this._renderSection(item, index)}
                        ListFooterComponent={footerComponent}
                    />
                </KeyboardAvoidingView>
                <ConfirmationDialog
                    isOpen={confirmationDialog}
                    onPress={this.closeConfirmationDialog}
                    text={confirmationText}
                />
                <FileUploadModal
                    isVisible={isAttachmentModal}
                    onCancel={() => this.setState({ isAttachmentModal: false })}
                    returnItems={(attachments) => this.setAttachments(attachments)}
                />
                <Canvas
                    visible={isImageSelected}
                    image={selectedImage}
                    closeImageButton={this.close}
                    saveImage={this.saveImageDrawing}
                />
            </View>
        );
    }
}

const mapStateToProps = ({ customReports, account }) => ({
    currentCustomReportItem: customReports.currentCustomReportItem,
    user: account.user,
});

export default connect(mapStateToProps, {
    saveCustomReportItem,
})(CustomReportScreen);
