import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../../theme';

export default StyleSheet.create({
    listItemWrapper: {
        justifyContent: 'space-between',
        paddingHorizontal: 10,
        paddingVertical: 12,
        marginBottom: 10,
        backgroundColor: Color.white,
        borderRadius: 6,
        width: '90%',
        elevation: 5,
        alignSelf: 'center',
    },
    body: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
        marginVertical: 5,
        alignItems: 'center',
    },
    subBody: {
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'right',
    },
    listItemTitle: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue,
        alignSelf: 'center',
        flexShrink: 1,
    },
    status: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
    },
    statusText: {
        fontFamily: Font.type.bold,
        color: Color.dark_navy_blue,
        fontSize: Font.size.font9,
        marginRight: 3,
        fontWeight: 'bold',
    },
    statusTextPending: {
        color: Color.faded_orange,
    },
    statusTextApproved: {
        color: Color.turquoise,
    },
    statusTextDenied: {
        color: Color.pinkish_orange,
    },
    statusBadge: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: Color.blue,
        height: 17,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 8,
    },
    statusBadgeOffline: {
        borderColor: '#ffecb2',
        backgroundColor: '#fefbef',
        marginLeft: 10,
    },
    statusBadgeOfflineText: {
        color: '#f3a34d',
        fontFamily: Font.type.regular,
        fontSize: Font.size.font8,
    },
});
