import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import { showMessage } from 'react-native-flash-message';
import produce from 'immer';
import NetInfo from '@react-native-community/netinfo';

import Api from '../../utils/apiMiddleware';
import { uploadImage } from '../helpers/common';
import { generateId } from '../../utils/helpers';
import CNST from '../constants';
import { Config } from '../../config';

import { Color } from '../../theme';

const parseTimeCards = (data) => {
    return data.reduce((allCards, card) => {
        for (const workTimeItem of card.WorkTimes) {
            let totalTime = 0;
            let breakTime = 0;
            if (workTimeItem.StartTime && workTimeItem.EndTime) {
                totalTime = moment(workTimeItem.EndTime).diff(moment(workTimeItem.StartTime), 'second');
            }

            workTimeItem.Breaks.forEach((b) => {
                if (b.StartTime && b.EndTime) {
                    breakTime += moment(b.EndTime).diff(moment(b.StartTime), 'second');
                }
            });

            const workTime = totalTime - breakTime;
            workTimeItem.Duration.WorkTime = workTime;
            workTimeItem.Duration.BreakTime = breakTime;
            workTimeItem.UserName = card.UserName;
            workTimeItem.TimeCardId = card.id;
            allCards.push(workTimeItem);
        }
        return allCards;
    }, []);
};

function updateTAMWorkTimes(data) {
    const { id, user, workTime } = data;
    let hours = workTime / 3600;
    const minutes = workTime / 60;
    const h = minutes > 52 ? (hours === 23 ? 0 : ++hours) : hours;
    Api()
        .get(`/sources/${Config.TAM}/data`, { searchField: 'id', search: id })
        .then((response) => {
            const tam = response.data[0];
            let isPersist = false;
            if (!tam.WorkTimes) {
                tam.WorkTimes = [];
            } else {
                tam.WorkTimes.forEach((t, i) => {
                    if (t.UserID === user._id) {
                        isPersist = true;
                        tam.WorkTimes[i].Hours = (parseFloat(tam.WorkTimes[i].Hours) + h).toFixed(2);
                        tam.WorkTimes[i].Date = moment().format('YYYY-MM-DD');
                    }
                });
            }
            if (!isPersist) {
                tam.WorkTimes.push({
                    UserID: user._id,
                    Hours: h.toFixed(2),
                    Date: moment().format('YYYY-MM-DD'),
                });
            }
            delete tam.timeModified;
            delete tam.timeCreated;
            delete tam.sourceId;
            delete tam.hasProposedChanges;

            Api()
                .put(`/sources/${Config.TAM}/data/${tam.id}`, {
                    data: tam,
                    modifiedBy: user.username,
                })
                .catch((error) => {
                    console.log(error);
                });
        })
        .catch((error) => {
            console.log('error', error);
            return false;
        });
}

function getLastTimeCard(responseData) {
    const lastTimeCard = responseData[0];
    // sort work times by start day ascending
    lastTimeCard.WorkTimes.sort((wt1, wt2) => {
        const startTime1 = moment(wt1.StartTime);
        const startTime2 = moment(wt2.StartTime);
        if (startTime1 < startTime2) {
            return -1;
        }
        if (startTime1 > startTime2) {
            return 1;
        }
        return 0;
    });

    const workTimes = lastTimeCard.WorkTimes;
    return workTimes[workTimes.length - 1];
}

function showUnsavedTimeCardError(hasEndTime, isError) {
    let message;

    if (isError) {
        message = 'An error occurred while saving';

        if (hasEndTime) {
            message += ' your time card.';
        } else {
            message += ' your clock in time.';
        }

        message += ' Your time card has been saved on your device and will be sent when you have a data connection.';
    } else {
        message = 'No Data Connection Detected';
        if (hasEndTime) {
            message +=
                ' Your time card has been saved on your device and will be sent when you have a data connection.';
        } else {
            message +=
                ' Your clock in time has been saved on your device and will be sent when you have a data connection.';
        }
    }

    showMessage({
        message,
        backgroundColor: Color.faded_orange,
        color: Color.dark_navy_blue,
        duration: 10000,
        animationDuration: 0,
        hideOnPress: true,
        hideStatusBar: true,
    });
}

function validateWorkTime(workTime) {
    let valid = true;
    const { StartTime, EndTime, Breaks } = workTime;

    if (!StartTime) {
        valid = false;
    }

    if (EndTime) {
        const startClockIn = new Date(StartTime).getTime();
        const endClockOut = new Date(EndTime).getTime();

        if (startClockIn && endClockOut && startClockIn < endClockOut) {
            const totalSeconds = (endClockOut - startClockIn) / 1000;
            let totalBreakTime = 0;

            for (const breakInfo of Breaks) {
                // validate the break start and end times
                const breakStartTime = new Date(breakInfo.StartTime).getTime();

                if (breakInfo.EndTime) {
                    const breakEndTime = new Date(breakInfo.EndTime).getTime();
                    if (
                        breakStartTime &&
                        breakEndTime &&
                        breakStartTime < breakEndTime &&
                        breakStartTime >= startClockIn &&
                        breakStartTime <= endClockOut &&
                        breakEndTime >= startClockIn &&
                        breakEndTime <= endClockOut
                    ) {
                        totalBreakTime += (breakEndTime - breakStartTime) / 1000;
                    } else {
                        valid = false;
                    }
                }
            }

            if (totalSeconds < totalBreakTime) {
                valid = false;
            }
        } else {
            valid = false;
        }
    }

    return valid;
}

// ------------------------------------
// Actions
// ------------------------------------

export function getTimeCards(data = {}) {
    const { user, unsavedCards } = data;
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARDS.GET_TIMECARDS.LOADING,
        });
        return Api()
            .post(`/sources/${Config.TIMECARDS}/data`, {
                fetchRelatedData: true,
                fetchCustomData: false,
                relatedSearch: false,
                sort: '-StartTime',
                limit: 5,
                searchCriteria: {
                    UserName: user._id,
                },
            })
            .then((response) => {
                let lastTimeCard = null;
                if (response.data && response.data.length > 0) {
                    lastTimeCard = getLastTimeCard(response.data);
                }
                const userCardsToDisplay = parseTimeCards(response.data);
                dispatch({
                    type: CNST.TIME_CARDS.GET_TIMECARDS.SUCCESS,
                    cards: userCardsToDisplay,
                    groupedCards: response.data,
                    relatedData: response.relatedData,
                    lastTimeCard,
                    unsavedCards,
                });
                return { timeCards: userCardsToDisplay, lastTimeCard };
            })
            .catch((error) => {
                console.log('getTimeCards error', error);
                dispatch({
                    type: CNST.TIME_CARDS.FINISH_LOADING,
                });
                return error;
            });
    };
}

export function getTimeCardsByPayPeriod(data = {}) {
    const { user, startDate, endDate, isReplace } = data;
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARDS.GET_TIMECARDS_BY_PAY_PERIOD.LOADING,
        });

        const query = {
            fetchCustomData: false,
            relatedSearch: false,
            sort: '-StartTime',
            searchCriteria: {
                UserName: user._id,
            },
            rangeFilterField: 'WorkTimes.StartTime',
        };

        if (startDate && endDate) {
            query.startDate = startDate;
            query.endDate = endDate;
        }

        return Api()
            .post(`/sources/${Config.TIMECARDS}/data`, query)
            .then((response) => {
                const userCardsToDisplay = parseTimeCards(response.data);

                dispatch({
                    type: CNST.TIME_CARDS.GET_TIMECARDS_BY_PAY_PERIOD.SUCCESS,
                    groupedCards: response.data,
                    cards: userCardsToDisplay,
                    startDate,
                    isReplace,
                });
                return true;
            })
            .catch((error) => {
                console.log('error', error);
                dispatch({
                    type: CNST.TIME_CARDS.GET_TIMECARDS_BY_PAY_PERIOD.ERROR,
                });
                return error;
            });
    };
}

export function clearLastTimeCard() {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARDS.CLEAR_LAST_TIME_CARD,
        });
    };
}

export function getTimeCardModifications(user) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.GET_ITEMS.LOADING,
        });
        return Api()
            .post(`/sources/${Config.TIMECARD_MODIFICATIONS}/data`, {
                relatedSearch: false,
                limit: 9999,
                searchCriteria: {
                    User: user._id,
                },
            })
            .then((response) => {
                dispatch({
                    type: CNST.TIME_CARD_EDIT.GET_ITEMS.SUCCESS,
                    items: response.data,
                });
                return true;
            })
            .catch((error) => {
                console.log('error', error);
                return error;
            });
    };
}

export function syncTimeCard(workTimes, user, payPeriod, silent) {
    return async (dispatch) => {
        if (dispatch) {
            dispatch({
                type: CNST.TIME_CARDS.SYNC_CARDS.START,
            });
        }
        const hasEndTime = workTimes.some((wt) => wt.EndTime);

        try {
            const netState = await NetInfo.fetch();
            const { isConnected } = netState;

            if (isConnected) {
                // getting actual pay period data per user
                const currentTimeCards = {};
                for (const workTime of workTimes) {
                    if (workTime.UserId) {
                        // getting actual timecards data
                        const response = await Api().post(`/sources/${Config.TIMECARDS}/data`, {
                            fetchCustomData: false,
                            relatedSearch: false,
                            limit: 2,
                            sort: '-StartTime',
                            searchCriteria: {
                                UserName: workTime.UserId,
                            },
                        });
                        currentTimeCards[workTime.UserId] = response.data;
                    }
                }

                if (Object.keys(currentTimeCards).length === 0) {
                    const response = await Api().post(`/sources/${Config.TIMECARDS}/data`, {
                        fetchCustomData: false,
                        relatedSearch: false,
                        limit: 2,
                        sort: '-StartTime',
                        searchCriteria: {
                            UserName: user._id,
                        },
                    });
                    currentTimeCards[user._id] = response.data;
                }

                const timeCardsToSave = {};
                const unsavedIds = [];
                let currentTimeCard = null;
                for (const workTime of workTimes) {
                    const workTimeValid = validateWorkTime(workTime);
                    if (workTimeValid) {
                        const userId = workTime.UserId || user._id;
                        const myTimeCards = currentTimeCards[userId] || [];
                        currentTimeCard = myTimeCards.find((c) =>
                            moment(workTime.StartTime).isBetween(c.StartTime, c.EndTime),
                        );
                        if (currentTimeCard) {
                            if (!currentTimeCard.unsavedIds) {
                                currentTimeCard.unsavedIds = [];
                            }
                            let workTimeChanged = false;
                            const currentWorkTimeIndex = currentTimeCard.WorkTimes.findIndex(
                                (wt) => wt.WorkTimeId === workTime.WorkTimeId,
                            );
                            if (currentWorkTimeIndex >= 0) {
                                const currentWorkTime = currentTimeCard.WorkTimes[currentWorkTimeIndex];
                                if (currentWorkTime.EndTime) {
                                    // no need to update work time with already present end time
                                    unsavedIds.push(workTime.unsavedId);
                                } else {
                                    currentTimeCard.WorkTimes[currentWorkTimeIndex] = {
                                        ...workTime,
                                        valid: undefined,
                                    };
                                    currentTimeCard.unsavedIds.push(workTime.unsavedId);
                                    workTimeChanged = true;
                                }
                            } else {
                                currentTimeCard.WorkTimes.push({
                                    ...workTime,
                                    valid: undefined,
                                });
                                currentTimeCard.unsavedIds.push(workTime.unsavedId);
                                workTimeChanged = true;
                            }

                            if (workTimeChanged && currentTimeCard.id) {
                                timeCardsToSave[currentTimeCard.id] = {
                                    WorkTimes: currentTimeCard.WorkTimes,
                                    unsavedIds: currentTimeCard.unsavedIds,
                                };
                            }
                        } else {
                            // calculate start and end time. Each time card post every week
                            let startTime = null;
                            let endTime = null;
                            if (payPeriod && payPeriod.StartDate) {
                                startTime = moment(payPeriod.StartDate).startOf('day');
                                endTime = moment(payPeriod.StartDate).add(6, 'day').endOf('day');
                                const currentDay = moment();
                                while (!currentDay.isBetween(startTime, endTime)) {
                                    startTime = startTime.add(1, 'w');
                                    endTime = endTime.add(1, 'w');
                                }
                            } else {
                                startTime = moment().startOf('isoWeek');
                                endTime = moment().endOf('isoWeek');
                            }

                            startTime = startTime.toISOString();
                            endTime = endTime.toISOString();

                            currentTimeCard = {
                                UserName: userId,
                                StartTime: startTime,
                                EndTime: endTime,
                                Sign: {
                                    Image: null,
                                    Time: null,
                                    IsApproved: false,
                                },
                                WorkTimes: [],
                                unsavedIds: [],
                            };

                            if (
                                moment(workTime.StartTime).isBetween(currentTimeCard.StartTime, currentTimeCard.EndTime)
                            ) {
                                currentTimeCard.WorkTimes.push({
                                    ...workTime,
                                    valid: undefined,
                                });
                                currentTimeCard.unsavedIds.push(workTime.unsavedId);
                                myTimeCards.push(currentTimeCard);
                                timeCardsToSave.new = currentTimeCard;
                            }
                        }
                    } else {
                        dispatch({
                            type: CNST.TIME_CARDS.SAVE_CARD,
                            card: {
                                ...workTime,
                                valid: false,
                            },
                        });
                    }
                }

                if (Object.keys(timeCardsToSave).length > 0) {
                    for (const timeCardId of Object.keys(timeCardsToSave)) {
                        const workTimesToSave = timeCardsToSave[timeCardId].WorkTimes;
                        const currentUnsavedIds = [...unsavedIds, ...timeCardsToSave[timeCardId].unsavedIds];
                        delete timeCardsToSave[timeCardId].unsavedIds;
                        for (const workTime of workTimesToSave) {
                            if (workTime.ClockInType === 'T' && !isEmpty(workTime.CurrentTAM)) {
                                updateTAMWorkTimes({
                                    id: workTime.CurrentTAM.id,
                                    workTime,
                                    user: { _id: workTime.UserId || user._id, username: user.username },
                                });
                            }
                            delete workTime.CurrentTAM;
                            delete workTime.UserId;
                        }
                        const saveResponse = await Api().put(`/sources/${Config.TIMECARDS}/data/${timeCardId}`, {
                            data: timeCardsToSave[timeCardId],
                            modifiedBy: user.username,
                        });

                        if (hasEndTime) {
                            showMessage({
                                message: 'Success',
                                description: 'Your time card has been received!',
                                duration: 10000,
                                backgroundColor: Color.turquoise,
                                animationDuration: 0,
                                hideOnPress: true,
                                hideStatusBar: true,
                            });
                        }
                        if (dispatch) {
                            dispatch({
                                type: CNST.TIME_CARDS.SYNC_CARDS.SUCCESS,
                                groupedCard: saveResponse,
                                unsavedIds: currentUnsavedIds,
                            });
                        }
                    }
                } else if (dispatch) {
                    // we don't have anything to save
                    dispatch({
                        type: CNST.TIME_CARDS.SYNC_CARDS.SUCCESS,
                        unsavedIds,
                    });
                }
            } else {
                // we don't have connection now
                if (!silent) {
                    showUnsavedTimeCardError(hasEndTime, false);
                }
                if (dispatch) {
                    dispatch({
                        type: CNST.TIME_CARDS.SYNC_CARDS.FINISH,
                    });
                }
                return false;
            }
        } catch (error) {
            console.log('error', error);
            if (!silent) {
                showUnsavedTimeCardError(hasEndTime, true);
            }
            if (dispatch) {
                dispatch({
                    type: CNST.TIME_CARDS.SYNC_CARDS.FINISH,
                });
            }
            return false;
        }
        return true;
    };
}

export function createTimeCardData(data = {}) {
    const { currentTimeCards } = data;

    const cards = currentTimeCards.map((currentTimeCard) => {
        let workTime = 0;
        let breakTime = 0;

        const breaks = currentTimeCard.breakIn.map((breakIn, i) => {
            breakTime += moment(currentTimeCard.breakOut[i]).diff(moment(breakIn), 'second');
            return {
                StartTime: breakIn,
                EndTime: currentTimeCard.breakOut[i],
            };
        });

        currentTimeCard.clockIn.forEach((clockIn, i) => {
            workTime += moment(currentTimeCard.clockOut[i]).diff(moment(clockIn), 'second');
        });

        const card = {
            UserId: currentTimeCard.currentUserId,
            WorkTimeId: currentTimeCard.WorkTimeId ? currentTimeCard.WorkTimeId : null,
            Project: currentTimeCard.currentProject ? currentTimeCard.currentProject.id : null,
            ProjectName: currentTimeCard.currentProject ? currentTimeCard.currentProject.ProjectName : null,
            ProjectNumber: currentTimeCard.currentProject ? currentTimeCard.currentProject.ProjectNumber : null,
            Location: currentTimeCard.location,
            ClockInType: currentTimeCard.currentClockInType,
            Duration: {
                WorkTime: workTime,
                BreakTime: breakTime,
            },
            StartTime: currentTimeCard.clockIn[0] || null,
            EndTime: currentTimeCard.haveClockOutTime
                ? currentTimeCard.clockOut[currentTimeCard.clockOut.length - 1]
                : null,
            WorkOrder:
                !isEmpty(currentTimeCard.workOrderNumber) && !isEmpty(currentTimeCard.workOrderYear)
                    ? `${currentTimeCard.workOrderYear}-00${currentTimeCard.workOrderNumber}`
                    : '',
            Description: currentTimeCard.description,
            Breaks: breaks,
            CurrentTAM: currentTimeCard.currentTAM || null,
            VersionNumber: '',
        };
        switch (currentTimeCard.currentClockInType) {
            case 'C': {
                card.CostCode = currentTimeCard.currentCostCode ? currentTimeCard.currentCostCode.id : null;
                break;
            }
            case 'T': {
                card.TimeAndMaterial = !isEmpty(currentTimeCard.currentTAM) ? currentTimeCard.currentTAM.id : '';
                card.CostCode = currentTimeCard.currentCostCode ? currentTimeCard.currentCostCode.id : null;
                break;
            }
            default: {
                card.CostCode = currentTimeCard.currentCostCode ? currentTimeCard.currentCostCode.id : null;
                break;
            }
        }
        return card;
    });

    return {
        type: CNST.TIME_CARDS.SAVE_CARDS,
        cards,
    };
}

export function setRefreshed() {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARDS.REFRESHED,
        });
    };
}

export function signPayPeriod(timeCardId, image, user, payPeriodId) {
    const sign = {
        Sign: {
            Image: image.file.url,
            ImageAssetId: image._id,
            Time: moment().toISOString(),
            IsApproved: false,
            PayPeriodId: payPeriodId,
        },
    };
    return (dispatch) => {
        return Api()
            .put(`/sources/${Config.TIMECARDS}/data/${timeCardId}`, {
                data: sign,
                modifiedBy: user.username,
            })
            .then((response) => {
                return dispatch({
                    type: CNST.TIME_CARDS.UPDATE_GROUPED_CARDS.SUCCESS,
                    groupedCard: response,
                });
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}

export function setLoading(isLoading) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARDS.UPDATE_GROUPED_CARDS.LOADING,
            isLoading,
        });
    };
}

export function clearUnsavedTimeCards() {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARDS.CLEAR_UNSAVED_TIMECARDS,
        });
    };
}

export function setUser(user) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARDS.SET_USER,
            data: user,
        });
    };
}

export function adminSetCards(cards) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARDS.ADMIN_SET_CARDS,
            data: cards,
        });
    };
}

export function setCurrentTab(newTab) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARDS.SET_TAB,
            data: newTab,
        });
    };
}

export function addUnsavedSignedPeriod(timeCardId, asset, imageData, payPeriodId) {
    const data = {
        id: timeCardId,
        asset,
        imageData,
        payPeriodId,
    };
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARDS.ADD_UNSAVED_SIGNED_PERIOD,
            data,
        });
    };
}

export function syncUnsavedSignedPeriod(unsavedSignedPeriods, user) {
    return async (dispatch) => {
        dispatch({
            type: CNST.TIME_CARDS.SYNC_UNSAVED_SIGNED_PERIOD.START,
        });

        for (const unsavedSignedPeriod of unsavedSignedPeriods) {
            const uploadFunc = uploadImage(unsavedSignedPeriod.asset, unsavedSignedPeriod.imageData, 'sign');
            const uploadResponse = await uploadFunc();
            if (uploadResponse.asset) {
                await signPayPeriod(
                    { id: unsavedSignedPeriod.id },
                    uploadResponse.asset,
                    user,
                    unsavedSignedPeriod.payPeriodId,
                )(dispatch);
            }
        }

        dispatch({
            type: CNST.TIME_CARDS.SYNC_UNSAVED_SIGNED_PERIOD.FINISH,
        });
    };
}

export function setDailyQuestionActive(isDailyQuestionActive) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARDS.SET_DAILY_CLOCK_IN_QUESTION_ACTIVE,
            isDailyQuestionActive,
        });
    };
}

export function setCurrentDailyQuestion(currentQuestions) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARDS.CURRENT_QUESTIONS_TO_ANSWER,
            currentQuestions: currentQuestions || {},
        });
    };
}

export function saveDailyQuestionLocally(dailyQuestionUserResponse, unsavedId, dispatch) {
    showMessage({
        message: 'Warning',
        description: 'No data connection detected. Your responses has been saved on your device',
        backgroundColor: Color.faded_orange,
        color: Color.dark_navy_blue,
        duration: 3000,
        animationDuration: 0,
        hideOnPress: true,
        hideStatusBar: true,
    });

    if (unsavedId) {
        dailyQuestionUserResponse.unsavedId = unsavedId;
    } else {
        dailyQuestionUserResponse.unsavedId = generateId();
    }

    return dispatch({
        type: CNST.TIME_CARDS.DAILY_QUESTIONS_SUBMIT_ERROR,
        dailyQuestionUserResponse,
    });
}

export function submitDailyQuestion(data) {
    return (dispatch) => {
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;

            const { unsavedId } = data;
            const dailyQuestionUserResponse = {
                User: data.User,
                TicketStatus: data.TicketStatus,
                Project: data.Project,
                ProjectManager: '',
                QuestionCategory: data.QuestionCategory,
                Response: data.Response,
                Date: data.Date,
            };

            if (isConnected) {
                return Api()
                    .put(`/sources/${Config.DAILY_QUESTIONS_RESPONSES}/data/new`, {
                        data: dailyQuestionUserResponse,
                    })
                    .then((response) => {
                        showMessage({
                            message: 'Success',
                            description: 'Your responses have been received',
                            duration: 10000,
                            backgroundColor: Color.turquoise,
                            animationDuration: 0,
                            hideOnPress: true,
                            hideStatusBar: true,
                        });

                        return dispatch({
                            type: CNST.TIME_CARDS.DAILY_QUESTIONS_SUBMIT_SUCCESS,
                            response,
                            unsavedId: data.unsavedId || null,
                        });
                    })
                    .catch(() => {
                        return saveDailyQuestionLocally(dailyQuestionUserResponse, unsavedId, dispatch);
                    });
            }
            return saveDailyQuestionLocally(dailyQuestionUserResponse, unsavedId, dispatch);
        });
    };
}

export function getPayPeriods() {
    return (dispatch) => {
        return Api()
            .get(`/sources/${Config.PAY_PERIODS}/data`, { limit: 9999, sort: 'StartDate' })
            .then((response) => {
                dispatch({
                    type: CNST.TIME_CARDS.GET_PAY_PERIODS,
                    data: response.data,
                });
                return true;
            })
            .catch((error) => {
                console.log('error', error);
                return error;
            });
    };
}

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = {
    payPeriods: [],
    firstPayPeriod: null,
    timeCards: [],
    lastTimeCard: null,
    timeCardModifications: [],
    groupedCards: [],
    editTimeCard: {},
    isRefresh: false,
    isLoading: false,
    user: {},
    activeTab: 1,
    adminCards: [],
    unsavedCards: [],
    unsavedSignedPeriods: [],
    timeCardsIsSyncing: false,
    signedPeriodsIsSyncing: false,
    isDailyQuestionActive: false,
    dailyQuestions: [],
    dailyQuestionsTitle: '',
    dailyQuestionAnswerDate: null,
    locallySavedDailyQuestions: [],
    lastGetTimeCardsStartDate: null,
    isTimeCardsReplaced: true,
};

export default produce((state, action) => {
    switch (action.type) {
        case CNST.TIME_CARDS.SET_DAILY_CLOCK_IN_QUESTION_ACTIVE:
            state.isDailyQuestionActive = action.isDailyQuestionActive;
            break;
        case CNST.TIME_CARDS.CURRENT_QUESTIONS_TO_ANSWER:
            state.dailyQuestions = action.currentQuestions.Questions;
            state.dailyQuestionsTitle = action.currentQuestions.QuestionCategory;
            state.dailyQuestionAnswerDate = moment();
            break;
        case CNST.TIME_CARDS.DAILY_QUESTIONS_SUBMIT_SUCCESS: {
            const { unsavedId } = action;
            if (unsavedId) {
                state.locallySavedDailyQuestions = state.locallySavedDailyQuestions.filter(
                    (d) => d.unsavedId !== unsavedId,
                );
            }
            state.isDailyQuestionActive = false;
            break;
        }
        case CNST.TIME_CARDS.DAILY_QUESTIONS_SUBMIT_ERROR: {
            const { dailyQuestionUserResponse } = action;
            let found = false;
            state.locallySavedDailyQuestions.forEach((ticket, i) => {
                if (dailyQuestionUserResponse.unsavedId && ticket.unsavedId === dailyQuestionUserResponse.unsavedId) {
                    state.locallySavedDailyQuestions[i] = dailyQuestionUserResponse;
                    found = true;
                }
            });
            if (!found) {
                state.locallySavedDailyQuestions = [...state.locallySavedDailyQuestions, dailyQuestionUserResponse];
            }
            state.isDailyQuestionActive = false;
            break;
        }
        case CNST.TIME_CARDS.ADMIN_SET_CARDS:
            state.adminCards = action.data;
            break;
        case CNST.TIME_CARDS.SET_USER:
            state.user = action.data;
            break;
        case CNST.TIME_CARDS.SET_TAB:
            state.activeTab = action.data;
            break;
        case CNST.TIME_CARDS.SYNC_CARDS.START:
            state.timeCardsIsSyncing = true;
            break;
        case CNST.TIME_CARDS.SYNC_CARDS.SUCCESS: {
            const { unsavedIds, groupedCard } = action;
            let { groupedCards } = state;
            state.unsavedCards = state.unsavedCards.filter((uCard) => !unsavedIds.includes(uCard.unsavedId));
            if (groupedCard) {
                const existingGroupedCard = groupedCards.find((gc) => gc.id === groupedCard.id);
                if (existingGroupedCard) {
                    groupedCards = groupedCards.map((gc) => {
                        if (gc.id !== groupedCard.id) {
                            return gc;
                        }
                        return {
                            ...gc,
                            ...groupedCard,
                        };
                    });
                } else {
                    groupedCards = [groupedCard, ...groupedCards];
                }
                state.groupedCards = groupedCards;
                state.timeCards = parseTimeCards(groupedCards);
                state.lastTimeCard = getLastTimeCard(groupedCards);
            }
            state.timeCardsIsSyncing = false;
            break;
        }
        case CNST.TIME_CARDS.SYNC_CARDS.FINISH:
            state.timeCardsIsSyncing = false;
            break;
        case CNST.TIME_CARDS.SAVE_CARD: {
            const { card } = action;
            const cardIndex = state.unsavedCards.findIndex((c) => c.unsavedId === card.unsavedId);
            if (cardIndex >= 0) {
                state.unsavedCards[cardIndex] = card;
            }
            break;
        }
        case CNST.TIME_CARDS.CLEAR_UNSAVED_TIMECARDS: {
            state.unsavedCards = [];
            break;
        }
        case CNST.TIME_CARDS.SAVE_CARDS: {
            const { cards } = action;
            for (const card of cards) {
                card.unsavedId = generateId();
            }

            for (const card of cards) {
                const cardIndex = state.unsavedCards.findIndex((c) => c.WorkTimeId === card.WorkTimeId);
                if (cardIndex >= 0) {
                    state.unsavedCards[cardIndex] = card;
                } else {
                    state.unsavedCards.push(card);
                }
            }
            break;
        }
        case CNST.TIME_CARDS.UPDATE_GROUPED_CARDS.SUCCESS: {
            const { groupedCards, unsavedSignedPeriods } = state;
            groupedCards.forEach((item, i) => {
                if (item.id === action.groupedCard.id) {
                    groupedCards[i] = action.groupedCard;
                }
            });
            const existsIndex = unsavedSignedPeriods.findIndex((p) => p.id === action.groupedCard.id);
            if (existsIndex >= 0) {
                unsavedSignedPeriods.splice(existsIndex, 1);
            }
            state.groupedCards = groupedCards;
            state.unsavedSignedPeriods = unsavedSignedPeriods;
            state.isLoading = false;
            break;
        }
        case CNST.TIME_CARD_EDIT.GET_ITEMS.SUCCESS:
            state.timeCardModifications = action.items;
            state.isLoading = false;
            break;
        case CNST.TIME_CARD_EDIT.SAVE_TIMECARD.SUCCESS:
            state.timeCardModifications.push(action.item);
            // force time cards screen to refresh
            state.isRefresh = true;
            break;
        case CNST.TIME_CARD_EDIT.SAVE_TIMECARD.ERROR: {
            const { cardEditItem } = action;

            if (cardEditItem) {
                // force time cards screen to refresh
                state.isRefresh = true;
            }

            break;
        }
        case CNST.TIME_CARDS.CLEAR_LAST_TIME_CARD:
            state.lastTimeCard = null;
            break;
        case CNST.TIME_CARDS.GET_TIMECARDS.SUCCESS:
            state.timeCards = action.cards;
            state.groupedCards = action.groupedCards;
            state.isRefresh = true;
            state.isLoading = false;
            state.lastTimeCard = action.lastTimeCard;
            state.lastGetTimeCardsStartDate = null;
            state.isTimeCardsReplaced = true;
            break;
        case CNST.TIME_CARDS.GET_TIMECARDS_BY_PAY_PERIOD.SUCCESS: {
            if (action.isReplace) {
                state.timeCards = [];
            }

            // Filter out any cards that are already in the state
            const newCards = action.cards.filter(
                (newCard) => !state.timeCards.some((existingCard) => existingCard.WorkTimeId === newCard.WorkTimeId),
            );

            if (newCards.length > 0) {
                state.timeCards.push(...newCards);
            }

            const payPeriod = state.payPeriods.find((p) => p.StartDate === action.startDate);
            if (payPeriod) {
                const timeCard = action.groupedCards.find((c) => c.StartTime === payPeriod.StartDate);
                if (timeCard) {
                    payPeriod.isSigned = timeCard.times.every((time) => !!time.Sign.Image);
                    payPeriod.isApproved = timeCard.times.every((time) => time.Sign.IsApproved);
                    payPeriod.timeCards = timeCard.times;
                    payPeriod.regularWorkTime = timeCard.workTime;
                    payPeriod.overWorkTime = timeCard.overWorkTime;
                    payPeriod.doubleOverWorkTime = timeCard.doubleOverWorkTime;
                } else {
                    payPeriod.timeCards = [];
                    payPeriod.regularWorkTime = 0;
                    payPeriod.overWorkTime = 0;
                    payPeriod.doubleOverWorkTime = 0;
                }
            }

            state.isRefresh = true;
            state.isLoading = false;
            state.lastGetTimeCardsStartDate = action.startDate;
            state.isTimeCardsReplaced = !!action.isReplace;
            break;
        }
        case CNST.TIME_CARDS.GET_TIMECARDS_BY_PAY_PERIOD.LOADING:
        case CNST.TIME_CARDS.GET_TIMECARDS.LOADING:
        case CNST.TIME_CARD_EDIT.GET_ITEMS.LOADING:
            state.isLoading = true;
            break;
        case CNST.TIME_CARDS.UPDATE_GROUPED_CARDS.LOADING:
            state.isLoading = action.isLoading;
            break;
        case CNST.TIME_CARDS.REFRESHED:
            state.isRefresh = false;
            break;
        case CNST.TIME_CARDS.FINISH_LOADING:
            state.isLoading = false;
            state.isRefresh = false;
            break;
        case CNST.TIME_CARDS.ADD_UNSAVED_SIGNED_PERIOD: {
            const { unsavedSignedPeriods } = state;
            const { id } = action.data;
            const existsIndex = unsavedSignedPeriods.findIndex((p) => p.id === id);
            if (existsIndex >= 0) {
                unsavedSignedPeriods.splice(existsIndex, 1, action.data);
            } else {
                unsavedSignedPeriods.push(action.data);
            }
            state.unsavedSignedPeriods = unsavedSignedPeriods;
            state.isLoading = false;
            break;
        }
        case CNST.TIME_CARDS.SYNC_UNSAVED_SIGNED_PERIOD.START:
            state.signedPeriodsIsSyncing = true;
            break;
        case CNST.TIME_CARDS.SYNC_UNSAVED_SIGNED_PERIOD.FINISH:
            state.signedPeriodsIsSyncing = false;
            break;
        case CNST.ACCOUNT.LOGOUT.SUCCESS: {
            const newState = cloneDeep(initialState);
            newState.unsavedCards = state.unsavedCards;
            newState.unsavedSignedPeriods = state.unsavedSignedPeriods;
            return newState;
        }
        case CNST.TIME_CARDS.GET_TIMECARDS_BY_PAY_PERIOD.ERROR:
            state.isRefresh = false;
            state.isLoading = false;
            break;
        case CNST.TIME_CARDS.GET_PAY_PERIODS: {
            for (let i = 0; i < action.data.length; i++) {
                const payPeriod = action.data[i];

                const existingPayPeriodIndex = state.payPeriods.findIndex((p) => p.id === payPeriod.id);
                if (existingPayPeriodIndex >= 0) {
                    state.payPeriods[existingPayPeriodIndex] = {
                        ...state.payPeriods[existingPayPeriodIndex],
                        ...payPeriod,
                    };
                } else {
                    state.payPeriods.push(payPeriod);
                }
            }

            state.firstPayPeriod = action.data.length > 0 ? action.data[0] : null;
            break;
        }
        default:
            break;
    }
}, initialState);
