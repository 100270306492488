export default {
    SAVE_CARD: 'SAVE_CARD',
    SAVE_CARDS: 'SAVE_CARDS',
    SYNC_CARDS: {
        START: 'SYNC_CARDS_START',
        SUCCESS: 'SYNC_CARDS_SUCCESS',
        FINISH: 'SYNC_CARDS_FINISH',
    },
    CLEAR_UNSAVED_TIMECARDS: 'CLEAR_UNSAVED_TIMECARDS',
    GET_TIMECARDS: {
        LOADING: 'GET_TIMECARDS',
        SUCCESS: 'GET_TIMECARDS_SUCCESS',
        ERROR: 'GET_TIMECARDS_ERROR',
    },
    REFRESHED: 'REFRESHED',
    FINISH_LOADING: 'FINISH_LOADING',
    UPDATE_GROUPED_CARDS: {
        LOADING: 'UPDATE_GROUPED_CARDS',
        SUCCESS: 'UPDATE_GROUPED_CARDS_SUCCESS',
        ERROR: 'UPDATE_GROUPED_CARDS_ERROR',
    },
    SET_USER: 'SET_USER',
    CLEAR_LAST_TIME_CARD: 'CLEAR_LAST_TIME_CARD',
    SET_TAB: 'SET_TAB',
    ADMIN_SET_CARDS: 'ADMIN_SET_CARDS',
    SET_DAILY_CLOCK_IN_QUESTION_ACTIVE: 'SET_DAILY_CLOCK_IN_QUESTION_ACTIVE',
    CURRENT_QUESTIONS_TO_ANSWER: 'CURRENT_QUESTIONS_TO_ANSWER',
    DAILY_QUESTIONS_SUBMIT_SUCCESS: 'DAILY_QUESTIONS_SUBMIT_SUCCESS',
    DAILY_QUESTIONS_SUBMIT_ERROR: 'DAILY_QUESTIONS_SUBMIT_ERROR',
    ADD_UNSAVED_SIGNED_PERIOD: 'ADD_UNSAVED_SIGNED_PERIOD',
    SYNC_UNSAVED_SIGNED_PERIOD: {
        START: 'SYNC_UNSAVED_SIGNED_PERIOD_START',
        FINISH: 'SYNC_UNSAVED_SIGNED_PERIOD_FINISH',
    },
    GET_TIMECARDS_BY_PAY_PERIOD: {
        LOADING: 'GET_TIMECARDS_BY_PAY_PERIOD',
        SUCCESS: 'GET_TIMECARDS_BY_PAY_PERIOD_SUCCESS',
        ERROR: 'GET_TIMECARDS_BY_PAY_PERIOD_ERROR',
    },
    GET_PAY_PERIODS: 'GET_PAY_PERIODS',
};
