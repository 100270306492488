import React, { PureComponent } from 'react';
import { View, Text, ImageBackground } from 'react-native';
import { connect } from 'react-redux';
import Svg, { Path } from 'react-native-svg';
import { NavigationBar } from '../../components';
import s from './styles';
import { Img } from '../../theme';

class LockedModuleScreen extends PureComponent {
    render() {
        return (
            <ImageBackground source={Img.bg} style={[s.wrapper]}>
                <NavigationBar {...this.props} title="MODULE LOCKED" backIcon />
                <View>
                    <View style={s.lockIconPadding}>
                        <Svg xmlns="http://www.w3.org/2000/svg" width={120} height={154}>
                            <Path
                                stroke="#8CA8C7"
                                strokeWidth={12}
                                fill="none"
                                // eslint-disable-next-line max-len
                                d="M28.005 66.616V42.11c0-16.29 13.205-29.496 29.495-29.496S86.995 25.82 86.995 42.11v24.506"
                            />
                            <Path
                                fillRule="evenodd"
                                strokeWidth={2}
                                stroke="#8CA8C7"
                                fill="#FFF"
                                // eslint-disable-next-line max-len
                                d="M15 63h84c5.523 0 10 4.477 10 10v60c0 5.523-4.477 10-10 10H15c-5.523 0-10-4.477-10-10V73c0-5.523 4.477-10 10-10z"
                            />
                            <Path
                                fillRule="evenodd"
                                fill="#E8EDF4"
                                // eslint-disable-next-line max-len
                                d="M57.131 76.288c14.995 0 27.15 12.155 27.15 27.149 0 14.995-12.155 27.15-27.15 27.15-14.994 0-27.149-12.155-27.149-27.15 0-14.994 12.155-27.149 27.149-27.149z"
                            />
                            <Path
                                fillRule="evenodd"
                                strokeWidth={2}
                                stroke="#8CA8C7"
                                fill="#8CA8C7"
                                // eslint-disable-next-line max-len
                                d="M57.3 124.336a4.588 4.588 0 0 1-4.586-4.592v-.001a4.59 4.59 0 0 1 4.586-4.593 4.59 4.59 0 0 1 4.586 4.593 4.59 4.59 0 0 1-4.586 4.593zm2.602-12.499h-5.205c-.392 0-.717-.259-.728-.58l-.898-28.64c-.016-.521.379-.947.883-.947h6.692c.504 0 .899.426.883.947l-.899 28.64c-.01.321-.335.58-.728.58z"
                            />
                        </Svg>
                    </View>
                    <Text style={s.moduleLocked}>Module locked</Text>
                    <Text style={s.contact}>Please, contact admin.</Text>
                </View>
            </ImageBackground>
        );
    }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(LockedModuleScreen);
