import moment from 'moment';
import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { Text, TouchableOpacity, View, Image } from 'react-native';
// import Dash from 'react-native-dash';

import { any, bool, string, object, number } from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import hasAccessTo from '../../utils/auth';
import { Config } from '../../config';

import s from './styles';
import { Color, Img } from '../../theme';

export default class ListItem extends Component {
    static propTypes = {
        item: any,
        timeCard: bool,
        users: bool,
        label: string,
        user: object,
        style: object,
        index: number,
    };

    constructor(props) {
        super(props);
        this.timer = null;
        this.state = {
            time: 0,
        };
    }

    componentDidMount() {
        const { item, timeCard } = this.props;
        const { timeStart, timeEnd } = item;
        if (isEmpty(timeEnd) && timeCard) {
            const time = moment().diff(moment(timeStart), 'seconds');
            this.setState({ time });
            this.setTimer();
        }
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    setTimer() {
        this.clearTimer();
        this.timer = setInterval(this.timeChange, 1000);
    }

    timeChange = () => {
        const { time } = this.state;
        this.setState({ time: time + 1 });
    };

    clearTimer() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    _returnTimeCardHeader() {
        const { item } = this.props;
        const { timeStart, timeEnd, totalTime, status, type, ptoTime } = item;
        const { time } = this.state;

        const startTime = moment(timeStart).format('hh:mm:ss A');
        const endTime = !isEmpty(timeEnd) ? moment(timeEnd).format('hh:mm:ss A') : 'Currently clocked in';

        let total;
        if (!isEmpty(timeEnd)) {
            total =
                type === 'P' || type === 'U'
                    ? totalTime
                    : status
                    ? totalTime
                    : moment().startOf('day').seconds(totalTime).format('HH:mm:ss');
        } else {
            total = moment().startOf('day').seconds(time).format('HH:mm:ss');
        }

        return (
            <View style={s.listItemTimeCardHeader}>
                {!timeStart && !timeEnd ? (
                    <Text style={s.cardTotalTime}>{ptoTime}</Text>
                ) : (
                    <>
                        <Text style={s.cardTimes}>{`${startTime} - ${endTime}`}</Text>
                        <Text style={s.cardTotalTime}>{total}</Text>
                    </>
                )}
            </View>
        );
    }

    _returnTimeCardFooter() {
        const { item } = this.props;
        const { workOrderNumber } = item;
        return (
            <View style={s.listItemTimeCardHeader}>
                <Text style={s.workOrderNumber}>
                    {workOrderNumber && workOrderNumber.length > 0 ? workOrderNumber : ''}
                </Text>
            </View>
        );
    }

    _returnItemType() {
        const { item } = this.props;
        const { type } = item;
        let offlineBadge = null;
        let notValidBadge = null;
        let typeTitle = '';
        if (item.offline) {
            offlineBadge = (
                <View style={[s.cardType, s.cardTypeOffline]}>
                    <Text style={[s.cardTypeText, s.cardTypeOfflineText]}>SAVED OFFLINE</Text>
                </View>
            );
        }

        if (Object.prototype.hasOwnProperty.call(item, 'valid') && !item.valid) {
            notValidBadge = (
                <View style={[s.cardType, s.cardTypeNotValid]}>
                    <Text style={[s.cardTypeText, s.cardTypeNotValidText]}>NOT VALID</Text>
                </View>
            );
        }

        const costCodeLabel = Config.USE_DEPARTMENTS_JOBS ? 'JOB' : 'COST CODE';
        switch (type) {
            case 'C':
                typeTitle = costCodeLabel;
                break;
            case 'T':
                typeTitle = 'TIME AND MATERIAL';
                break;
            case 'O':
                typeTitle = 'CHANGE ORDER';
                break;
            case 'S':
                typeTitle = 'SICK TIME OFF';
                break;
            case 'P':
                typeTitle = 'PERSONAL TIME OFF';
                break;
            case 'U':
                typeTitle = 'UNPAID TIME OFF';
                break;
            case 'V':
                typeTitle = 'VACATION TIME OFF';
                break;
            case 'OP':
                typeTitle = 'OTHER PTO';
                break;
            default:
                typeTitle = costCodeLabel;
                break;
        }

        return (
            <View style={[s.cardTypeWrapper]}>
                <View style={s.cardType}>
                    <Text style={s.cardTypeText}>{typeTitle}</Text>
                </View>
                {offlineBadge}
                {notValidBadge}
            </View>
        );
    }

    render() {
        const { item, timeCard, users, label, user, style, index } = this.props;
        const { workOrderNumber } = item;
        const isRadioSelected = item.radio ? item.isSelected : true;
        const checkIcon = item.isSelected ? (
            <View style={s.roundBlock}>
                <Icon name="check" color={Color.white} style={s.checkIcon} />
            </View>
        ) : (
            <View style={[s.roundBlock, s.roundBlockDisabled]} />
        );
        const isThereStatus = !isEmpty(item.status);
        const projectLabel = Config.USE_DEPARTMENTS_JOBS ? 'Department' : 'Project';
        return (
            <TouchableOpacity
                onPress={() => item.onPress(index)}
                disabled={item.isDisabled || !item.onPress}
                style={s.listItemWidth}
            >
                <View
                    style={[
                        s.listItemWrapper,
                        timeCard && s.listItemWrapper_timeCard,
                        item.radio && s.listItemWrapper_radio,
                        item.editScreen && s.listItemWrapper_editScreen,
                        item.loggedInAccount && s.ListItemSelf,
                        style && style,
                    ]}
                >
                    {label && <Text style={s.fieldLabel}>{label}</Text>}
                    {timeCard && this._returnTimeCardHeader()}
                    <View style={[s.listItemColumn, s.listItemColumnLeft, timeCard && s.listItemColumn_timeCard]}>
                        {!!item.icon && (
                            <View style={s.iconWrapper}>
                                <Icon name={item.icon} size={14} style={s.listItemIcon} />
                            </View>
                        )}
                        {!!item.radio && checkIcon}
                        {!users ? (
                            <Text
                                numberOfLines={1}
                                style={[
                                    s.listItemTitle,
                                    timeCard && s.listItemTitle_timeCard,
                                    !!item.icon && s.listItemTitle_withIcon,
                                    item.required && s.listItemTitle_required,
                                    !!item.icon && item.required && s.listItemTitle_withIcon_required,
                                    item.inactiveText && s.listItemTitle_editScreen,
                                ]}
                            >
                                {item.title}
                            </Text>
                        ) : (
                            <View style={s.userWrapper}>
                                <View style={s.iconWrapper}>
                                    {item.photo && typeof item.photo === 'string' ? (
                                        <Image
                                            source={{
                                                uri: item.photo,
                                            }}
                                            style={s.userPhoto}
                                        />
                                    ) : (
                                        <Image source={Img.noUserPhoto} size={14} style={s.userPhoto} />
                                    )}
                                </View>
                                <Text
                                    numberOfLines={1}
                                    style={[s.listItemTitle, item.loggedInAccount && s.ListItemSelf]}
                                >
                                    {item.loggedInAccount ? `${item.title} (you)` : item.title}
                                </Text>
                                <Icon name="chevron-right" size={30} style={[s.chevronRight, s.chevronRight_User]} />
                            </View>
                        )}

                        {timeCard &&
                            !item.offline &&
                            !item.isDisabled &&
                            item.onPress &&
                            hasAccessTo(user, Config.TIMECARD_MODIFICATIONS) && (
                                <View style={[s.listItemColumn, s.listItemColumnRight]}>
                                    <Icon
                                        name="chevron-right"
                                        size={30}
                                        style={[s.chevronRight, s.chevronRight_timeCard]}
                                    />
                                </View>
                            )}
                    </View>
                    {timeCard && !isEmpty(workOrderNumber) && this._returnTimeCardFooter()}
                    {timeCard && this._returnItemType()}
                    {!timeCard && !users && (
                        <View style={[s.listItemColumn, s.listItemColumnRight]}>
                            {item.required && <Text style={s.labelRequired}>Required</Text>}
                            {item.quantity && <Text style={s.labelQuantity}>{item.quantity}</Text>}
                            {isRadioSelected && (
                                <Icon name={item.locked ? 'lock' : 'chevron-right'} size={30} style={s.chevronRight} />
                            )}
                        </View>
                    )}
                    {isThereStatus && (
                        <View style={[s.listItemColumn, s.listItemColumn_status]}>
                            <View style={[s.cardTypeWrapper]}>
                                <Text style={[s.statusText]}>{item.status}</Text>
                                <View style={[s.cardType, s.cardTypeTimeChanged]}>
                                    <Text style={[s.cardTypeText, s.cardTypeTimeChangedText]}>
                                        {item.oldTimeCard ? 'MODIFIED TIME' : 'ADDED TIME'}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    )}
                    {isThereStatus && item.oldTimeCard && (
                        <View style={[s.listItemColumn, s.listItemColumnTM_status]}>
                            {item.oldTimeCard.ProjectName !== item.title ? (
                                <Text style={[s.timeCardEditsLabel]}>{projectLabel} Change</Text>
                            ) : null}

                            {item.oldTimeCard.StartTime !== item.timeStart ||
                            // eslint-disable-next-line max-len
                            item.oldTimeCard.EndTime !== item.timeEnd ? (
                                <Text style={[s.timeCardEditsLabel]}>Time Adjustment</Text>
                            ) : null}
                            {item.oldTimeCard.Duration.BreakTime !== item.totalBreakTime ? (
                                <Text style={[s.timeCardEditsLabel]}>Break time Adjustment</Text>
                            ) : null}
                        </View>
                    )}
                </View>
            </TouchableOpacity>
        );
    }
}
