import { StyleSheet } from 'react-native';
import { Font, Color } from '../../../theme';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        paddingTop: 16,
        alignItems: 'center',
    },
    logo: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingTop: '10%',
        paddingBottom: '10%',
    },
    logoImg: {
        width: 150,
        height: 50,
    },
    form: {
        height: '70%',
    },
    menuTopContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    topMenu_title: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
    },
    totalRow: {
        width: '100%',
        flexDirection: 'row',
        marginBottom: 20,
    },
    totalCol: {
        flex: 0.5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    totalTitle: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font26,
        fontFamily: Font.type.light,
    },
    totalSubtitle: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font12,
        fontFamily: Font.type.light,
    },
    totalText_blue: {
        color: Color.light_navy,
    },
    addButton: {
        width: 43,
        height: 43,
        borderRadius: 22,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        backgroundColor: Color.light_navy,
        bottom: 160,
        left: '50%',
        marginLeft: -21.5,
        elevation: 12,
        shadowColor: Color.light_grey_blue,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.5,
        shadowRadius: 5,
    },
});
