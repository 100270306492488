import { cloneDeep } from 'lodash';

import CNST from '../constants';
import Api from '../../utils/apiMiddleware';
import { Config } from '../../config';

export function changeOrderLoading() {
    return (dispatch) => {
        dispatch({
            type: CNST.CHANGE_ORDERS.LOADING.START,
        });
    };
}

export function changeOrderFinishLoading() {
    return (dispatch) => {
        dispatch({
            type: CNST.CHANGE_ORDERS.LOADING.FINISH,
        });
    };
}

// -------- Change orders ------- //
export function getChangeOrders(project) {
    return (dispatch) => {
        return Api()
            .get(`/sources/${Config.ORDER_CHANGES}/data`, { searchField: 'Project', search: project.id })
            .then((response) => {
                dispatch({
                    type: CNST.CHANGE_ORDERS.GET_CHANGE_ORDER_ITEMS,
                    data: response.data,
                });
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    };
}

export function setChangeOrderItem(item) {
    return (dispatch) => {
        dispatch({
            type: CNST.CHANGE_ORDERS.SET_CHANGE_ORDER_ITEM,
            item,
        });
    };
}

export function saveDescriptionImage(image, changeOrderItem) {
    return (dispatch) => {
        const item = changeOrderItem;
        item.Description.Attachment.push({ File: image.file.url, FileAssetId: image._id });
        return dispatch({
            type: CNST.CHANGE_ORDERS.SAVE_CHANGE_ORDER_ITEM_IMAGE,
            item,
        });
    };
}

export function saveGCImage(image, changeOrderItem) {
    return (dispatch) => {
        const item = changeOrderItem;
        item.GeneralContractor.Picture = image.file.url;
        return dispatch({
            type: CNST.CHANGE_ORDERS.SAVE_CHANGE_ORDER_ITEM_IMAGE,
            item,
        });
    };
}

export function saveCompleteImage(image, changeOrderItem) {
    return (dispatch) => {
        const item = changeOrderItem;
        item.Complete.Attachment.push({ File: image.file.url, FileAssetId: image._id });
        return dispatch({
            type: CNST.CHANGE_ORDERS.SAVE_CHANGE_ORDER_ITEM_IMAGE,
            item,
        });
    };
}

export function removeCompleteImage(image, changeOrderItem) {
    return (dispatch) => {
        const item = changeOrderItem;
        item.Complete.Attachment = item.Description.Attachment.filter((a) => a.File !== image);
        return dispatch({
            type: CNST.CHANGE_ORDERS.REMOVE_CHANGE_ORDER_ITEM_IMAGE,
            item,
        });
    };
}

export function removeDescriptionImage(image, changeOrderItem) {
    return (dispatch) => {
        const item = changeOrderItem;
        item.Description.Attachment = item.Description.Attachment.filter((a) => a.File !== image);
        return dispatch({
            type: CNST.CHANGE_ORDERS.REMOVE_CHANGE_ORDER_ITEM_IMAGE,
            item,
        });
    };
}

export function removeGCImage(image, changeOrderItem) {
    return (dispatch) => {
        const item = changeOrderItem;
        item.GeneralContractor.Attachment = item.Description.Attachment.filter((a) => a.File !== image);
        return dispatch({
            type: CNST.CHANGE_ORDERS.REMOVE_CHANGE_ORDER_ITEM_IMAGE,
            item,
        });
    };
}

export function createChangeOrderItem(data = {}) {
    const { user, currentProject } = data;
    return (dispatch) => {
        return dispatch({
            type: CNST.CHANGE_ORDERS.CREATE_CHANGE_ORDER_ITEM,
            item: {
                id: 0,
                TicketName: '',
                User: user._id,
                Project: currentProject.id,
                ProjectAddress: '',
                ListOfEstimates: [],
                Description: {
                    Description: '',
                    Attachment: [],
                },
                CustomerEmails: [],
                QuoteSent: false,
                StatementOfWorkPDF: '',
                StatementOfWorkSent: false,
                GMApproval: 'T',
                Paid: false,
                GeneralContractor: {
                    Name: '',
                    Signature: '',
                    Picture: '',
                },
                Subcontractor: {
                    Name: '',
                    Date: '',
                },
                Complete: {
                    Complete: false,
                    UserSignature: '',
                    Attachment: [],
                },
                TotalPrice: 0,
            },
        });
    };
}

export function saveChangeOrderItem(data = {}) {
    const { changeOrderItem, user } = data;
    const item = changeOrderItem;
    return (dispatch) => {
        dispatch({ type: CNST.CHANGE_ORDERS.SUBMIT_CHANGE_ORDER_ITEM.LOADING });
        const isNew = item.id === 0;
        delete item.timeCreated;
        delete item.sourceId;
        delete item.hasProposedChanges;
        if (isNew) {
            delete item.id;
        }
        return Api()
            .put(`/sources/${Config.ORDER_CHANGES}/data/${isNew ? 'new' : item.id}`, {
                data: item,
                modifiedBy: user.username,
            })
            .then((response) => {
                dispatch({
                    type: CNST.CHANGE_ORDERS.SUBMIT_CHANGE_ORDER_ITEM.SUCCESS,
                    item: response,
                    isNew,
                });
                return true;
            })
            .catch((error) => {
                console.log(error);
                return dispatch({
                    type: CNST.CHANGE_ORDERS.SUBMIT_CHANGE_ORDER_ITEM.ERROR,
                });
            });
    };
}

export function addSubcontractor({ items, currentChangeOrder, user }) {
    const newCurrentChangeOrder = currentChangeOrder;
    if (!newCurrentChangeOrder.Subcontractor) {
        newCurrentChangeOrder.Subcontractor = [];
    }
    items.forEach((item) => {
        newCurrentChangeOrder.Subcontractor.push(item);
    });
    return (dispatch) => {
        dispatch({ type: CNST.CHANGE_ORDERS.LOADING.START });
        dispatch({
            type: CNST.CHANGE_ORDERS.ADD_CHANGE_ORDER_ITEM,
            newCurrentChangeOrder,
        });
        dispatch(saveChangeOrderItem({ changeOrderItem: newCurrentChangeOrder, user }));
    };
}

export function editSubcontractor({ item, currentChangeOrder, user }) {
    const newCurrentChangeOrder = currentChangeOrder;
    newCurrentChangeOrder.Subcontractor.forEach((subcontractor, i) => {
        if (i === item.id) {
            newCurrentChangeOrder.Subcontractor[i] = item;
        }
    });

    return (dispatch) => {
        dispatch({ type: CNST.CHANGE_ORDERS.LOADING.START });
        dispatch({
            type: CNST.CHANGE_ORDERS.ADD_CHANGE_ORDER_ITEM,
            newCurrentChangeOrder,
        });
        dispatch(saveChangeOrderItem({ changeOrderItem: newCurrentChangeOrder, user }));
    };
}

export function cleanUp() {
    return (dispatch) => {
        dispatch({
            type: CNST.CHANGE_ORDERS.CLEAN_UP,
        });
    };
}

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = {
    changeOrderItems: [],
    currentChangeOrderItem: null,
    isLoading: false,
    currentUploading: [],
};

export default function dailyReports(state = cloneDeep(initialState), action) {
    switch (action.type) {
        case CNST.CHANGE_ORDERS.CLEAN_UP:
            return {
                ...state,
                ...{
                    currentChangeOrderItem: action.data,
                    isLoading: false,
                    currentUploading: [],
                },
            };
        case CNST.CHANGE_ORDERS.GET_CHANGE_ORDER_ITEMS:
            return { ...state, ...{ changeOrderItems: action.data } };
        case CNST.CHANGE_ORDERS.CREATE_CHANGE_ORDER_ITEM:
        case CNST.CHANGE_ORDERS.SET_CHANGE_ORDER_ITEM:
            return {
                ...state,
                ...{
                    currentChangeOrderItem: action.item,
                    currentUploading: [],
                },
            };
        case CNST.CHANGE_ORDERS.SUBMIT_CHANGE_ORDER_ITEM.SUCCESS: {
            const { item, isNew } = action;
            let changeOrderItems = null;
            if (isNew) {
                changeOrderItems = [...state.changeOrderItems, item];
            } else {
                changeOrderItems = state.changeOrderItems.map((t) => {
                    if (t.id !== item.id) {
                        return t;
                    }
                    return {
                        ...t,
                        ...item,
                    };
                });
            }
            return {
                ...state,
                ...{
                    isLoading: false,
                    changeOrderItems,
                },
            };
        }
        case CNST.CHANGE_ORDERS.SUBMIT_CHANGE_ORDER_ITEM.ERROR: {
            return {
                ...state,
                ...{
                    isLoading: false,
                },
            };
        }
        case CNST.CHANGE_ORDERS.REMOVE_CHANGE_ORDER_ITEM_IMAGE:
        case CNST.CHANGE_ORDERS.SAVE_CHANGE_ORDER_ITEM_IMAGE: {
            return {
                ...state,
                ...{
                    currentChangeOrderItem: cloneDeep(action.item),
                },
            };
        }
        case CNST.CHANGE_ORDERS.LOADING.START: {
            return {
                ...state,
                ...{
                    isLoading: true,
                },
            };
        }
        case CNST.CHANGE_ORDERS.LOADING.FINISH: {
            return {
                ...state,
                ...{
                    isLoading: false,
                },
            };
        }
        case CNST.CHANGE_ORDERS.ADD_CHANGE_ORDER_ITEM: {
            return {
                ...state,
                ...{
                    currentChangeOrderItem: action.newCurrentChangeOrder,
                    isLoading: false,
                },
            };
        }
        case CNST.ACCOUNT.LOGOUT.SUCCESS:
            return { ...state, ...cloneDeep(initialState) };
        default:
            return state;
    }
}
