// import RNFetchBlob from 'rn-fetch-blob';
import { cloneDeep, isNil, isEmpty } from 'lodash';
import { showMessage } from 'react-native-flash-message';
import CNST from '../constants';
import { Config } from '../../config';
import Api from '../../utils/apiMiddleware';
import { uploadImage, isUploadingOverdue } from '../helpers/common';
import { generateId } from '../../utils/helpers';
import Queue from '../helpers/queue';

import { Color } from '../../theme';

const uploadingQueue = new Queue();

function uploadCheck(currentReport) {
    const results = {
        isNeedUpload: false,
        uploading: false,
    };
    // upload notes attachments
    for (const note of currentReport.Notes) {
        for (const attachment of note.Attachment) {
            const { id, uploaded, uploading, uploadingStartTime } = attachment;
            const uploadingOverdue = isUploadingOverdue(uploadingStartTime);

            if (id && !isNil(uploaded) && !uploaded && (!uploading || uploadingOverdue)) {
                results.isNeedUpload = true;
            }

            if (id && !isNil(uploaded) && !uploaded && uploading && !uploadingOverdue) {
                results.uploading = true;
            }
        }
    }

    // upload survey attachments
    for (const survey of currentReport.Survey) {
        for (const attachment of survey.Attachment) {
            const { id, uploaded, uploading, uploadingStartTime } = attachment;
            const uploadingOverdue = isUploadingOverdue(uploadingStartTime);

            if (id && !isNil(uploaded) && !uploaded && !uploading && uploadingOverdue) {
                results.isNeedUpload = true;
            }

            if (id && !isNil(uploaded) && !uploaded && uploading && !uploadingOverdue) {
                results.uploading = true;
            }
        }
    }

    return results;
}

function saveReportImages(currentReport, onImageStartUpload, onImageUploaded, onImageUploadError, onComplete) {
    let isNeedUpload = false;

    uploadingQueue.setOnComplete = (success) => {
        onComplete(success);
    };

    const uploadHandler = (asset, attachment, type, item) => {
        if (asset && asset.file) {
            onImageUploaded(asset, attachment.id, currentReport, type);
            const existsIndex = item.Attachment.findIndex((a) => a.id === attachment.id);
            if (existsIndex >= 0) {
                item.Attachment.splice(existsIndex, 1, {
                    File: asset.file.url,
                    FileAssetId: asset._id,
                });
            } else {
                item.Attachment.push({
                    File: asset.file.url,
                    FileAssetId: asset._id,
                });
            }
            return true;
        }
        onImageUploadError(attachment.id, currentReport, type);
        return false;
    };

    const addToUploadingQueue = (attachment, type, item) => {
        isNeedUpload = true;
        onImageStartUpload(attachment.id, currentReport, type);
        const uploadAttachment = async () => {
            const uploadFunc = uploadImage(attachment, attachment.file, type, item.id);
            const response = await uploadFunc();
            return uploadHandler(response.asset, attachment, type, item);
        };

        uploadingQueue.push({
            func: uploadAttachment,
            args: [],
        });
    };

    // upload notes attachments
    for (const note of currentReport.Notes) {
        for (const attachment of note.Attachment) {
            const { id, uploaded, uploading, uploadingStartTime } = attachment;
            const uploadingOverdue = isUploadingOverdue(uploadingStartTime);
            if (id && !isNil(uploaded) && !uploaded && (!uploading || uploadingOverdue)) {
                addToUploadingQueue(attachment, 'note', note);
            }
        }
    }

    // upload survey attachments
    for (const survey of currentReport.Survey) {
        for (const attachment of survey.Attachment) {
            const { id, uploaded, uploading, uploadingStartTime } = attachment;
            const uploadingOverdue = isUploadingOverdue(uploadingStartTime);
            if (id && !isNil(uploaded) && !uploaded && (!uploading || uploadingOverdue)) {
                addToUploadingQueue(attachment, 'survey', survey);
            }
        }
    }

    return isNeedUpload;
}

// ------------------------------------
// Actions
// ------------------------------------

export function getReports(project, userId) {
    return (dispatch) => {
        return Api()
            .get(`/sources/${Config.REPORTS}/data`, {
                limit: 100,
                sort: '-timeCreated',
                searchField: 'Project',
                search: project,
            })
            .then((response) => {
                const data = response.data.filter((d) => d.User === userId);
                dispatch({
                    type: CNST.DAILY_REPORTS.GET_REPORTS,
                    data,
                });
                return data;
            })
            .catch((error) => {
                return error;
            });
    };
}

export function searchReports(searchTerm) {
    return (dispatch) => {
        return Api()
            .get(`/sources/${Config.REPORTS}/data`, {
                limit: 100,
                sort: '-timeCreated',
                searchField: 'ProjectName',
                search: searchTerm,
            })
            .then((response) => {
                // const data = response.data.filter((d) => d.User === userId)
                dispatch({
                    type: CNST.DAILY_REPORTS.GET_REPORTS,
                    data: response.data,
                });
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    };
}

export function createDailyReport(data = {}) {
    const { user, date, estimates, surveys } = data;
    return (dispatch) => {
        return dispatch({
            type: CNST.DAILY_REPORTS.CREATE_DAILY_REPORT,
            report: {
                User: user._id,
                UserName: `${user.fullName}`,
                Date: date,
                UsedEstimates: estimates,
                Notes: [],
                Survey: surveys,
                Weather: {
                    MinTemp: '',
                    MaxTemp: '',
                    CurrentWeather: '',
                },
                Approved: false,
                AdminApprover: [],
                Completed: false,
                UsedTime: 0,
                WorkOrder: '',
                UsersTime: [],
            },
        });
    };
}

export function saveImageToNote(image) {
    return (dispatch) => {
        return dispatch({
            type: CNST.DAILY_REPORTS.SAVE_NOTE_IMAGE,
            image,
        });
    };
}

export function startDailyReportSaveLoading() {
    return (dispatch) => {
        return dispatch({
            type: CNST.DAILY_REPORTS.SUBMIT_DAILY_REPORT.LOADING,
        });
    };
}

export function saveReport({
    actualCurrentProject,
    currentReport,
    currentCostCode,
    user,
    currentTimeCard,
    serviceProjectName,
    saveOffline,
    silent,
}) {
    return (dispatch, getState) => {
        const getReportDataToSave = (report, project, projectNameToOverride, costCode, clockInInfo) => {
            let projectId = null;
            let projectName = null;
            let costCodeId = null;
            let workOrder = null;
            if (project) {
                projectId = project.id;
                projectName = project.ProjectName;
            } else if (clockInInfo) {
                projectId = clockInInfo.currentProject.id;
                projectName = clockInInfo.currentProject.ProjectName;
            } else {
                projectId = report.Project;
                projectName = report.ProjectName;
            }

            if (!isEmpty(projectNameToOverride)) {
                projectName = projectNameToOverride;
            }

            if (costCode) {
                costCodeId = costCode.id;
            } else {
                costCodeId = report.CostCode;
            }

            if (clockInInfo && !isEmpty(clockInInfo.workOrderNumber) && !isEmpty(clockInInfo.workOrderYear)) {
                workOrder = `${clockInInfo.workOrderYear}-00${clockInInfo.workOrderNumber}`;
            } else if (report.WorkOrder) {
                workOrder = report.WorkOrder;
            } else {
                workOrder = '';
            }

            return {
                Project: projectId,
                ProjectName: projectName,
                CostCode: costCodeId,
                User: report.User,
                UserName: report.UserName,
                Date: report.Date,
                Notes: report.Notes,
                UsersTime: report.UsersTime,
                UsedEstimates: report.UsedEstimates,
                Survey: report.Survey,
                Weather: report.Weather,
                Completed: report.Completed,
                UsedTime: 0,
                WorkOrder: workOrder,
            };
        };

        const assignReportStatutes = (reportId, unsavedId, report, isUploading) => {
            report.unsaved = true;
            if (isUploading) {
                report.status = 'Attachments uploading';
            }

            if (unsavedId) {
                report.unsavedId = unsavedId;
            } else {
                report.unsavedId = generateId();
            }

            if (reportId) {
                report.id = reportId;
            }

            return report;
        };

        const save = (reportId, unsavedId, report) => {
            delete report.id;
            delete report.unsavedId;
            delete report.status;
            delete report.unsaved;
            return Api()
                .put(`/sources/${Config.REPORTS}/data/${reportId || 'new'}`, {
                    data: report,
                    modifiedBy: user.username,
                })
                .then((response) => {
                    return dispatch({
                        type: CNST.DAILY_REPORTS.SUBMIT_DAILY_REPORT.SUCCESS,
                        report: response,
                        unsavedId,
                    });
                })
                .catch(() => {
                    assignReportStatutes(reportId, unsavedId, report, false);
                    if (!silent) {
                        showMessage({
                            message: 'Error',
                            description:
                                // eslint-disable-next-line max-len
                                'An error occurred while saving report. Your report has been saved on your device and will be sent later.',
                            type: 'danger',
                            icon: 'danger',
                            position: 'right',
                            hideStatusBar: true,
                            duration: 1500,
                            backgroundColor: Color.red,
                        });
                    }
                    return dispatch({
                        type: CNST.DAILY_REPORTS.SUBMIT_DAILY_REPORT.SUCCESS,
                        report,
                    });
                });
        };

        let reportToSave = getReportDataToSave(
            currentReport,
            actualCurrentProject,
            serviceProjectName,
            currentCostCode,
            currentTimeCard,
        );

        if (saveOffline) {
            assignReportStatutes(currentReport.id, currentReport.unsavedId, reportToSave, false);
            return dispatch({
                type: CNST.DAILY_REPORTS.SUBMIT_DAILY_REPORT.SUCCESS,
                report: reportToSave,
            });
        }
        const uploadStatus = uploadCheck(reportToSave);
        if (uploadStatus.isNeedUpload) {
            if (!silent) {
                showMessage({
                    message:
                        // eslint-disable-next-line max-len
                        'Upload attachments is needed. Your report has been saved on your device and will be sent when upload has been finished.',
                    backgroundColor: Color.faded_orange,
                    color: Color.dark_navy_blue,
                    duration: 10000,
                    animationDuration: 0,
                    hideOnPress: true,
                    hideStatusBar: true,
                });
            }
            assignReportStatutes(currentReport.id, currentReport.unsavedId, reportToSave, true);
            dispatch({
                type: CNST.DAILY_REPORTS.SUBMIT_DAILY_REPORT.SUCCESS,
                report: reportToSave,
            });
            reportToSave = cloneDeep(reportToSave);
            return saveReportImages(
                reportToSave,
                (attachmentId, report, type) => {
                    dispatch({
                        type: CNST.DAILY_REPORTS.ATTACHMENT_UPLOAD.START,
                        attachmentId,
                        report,
                        attachmentType: type,
                    });
                },
                (asset, attachmentId, report, type) => {
                    dispatch({
                        type: CNST.DAILY_REPORTS.ATTACHMENT_UPLOAD.SUCCESS,
                        asset,
                        attachmentId,
                        report,
                        attachmentType: type,
                    });
                },
                (attachmentId, report, type) => {
                    dispatch({
                        type: CNST.DAILY_REPORTS.ATTACHMENT_UPLOAD.ERROR,
                        attachmentId,
                        report,
                        attachmentType: type,
                    });
                },
                (success) => {
                    if (success) {
                        // getting the latest report info to save
                        const { reports } = getState().dailyReports;
                        if (reportToSave.id) {
                            reportToSave = reports.find((r) => r.id === reportToSave.id);
                        } else {
                            reportToSave = reports.find((r) => r.unsavedId === reportToSave.unsavedId);
                        }
                        save(reportToSave.id, reportToSave.unsavedId, reportToSave);
                    }
                },
            );
        }

        if (uploadStatus.uploading) {
            // waiting while images will be uploaded, save report updates to redux state
            assignReportStatutes(currentReport.id, currentReport.unsavedId, reportToSave, true);
            return dispatch({
                type: CNST.DAILY_REPORTS.SUBMIT_DAILY_REPORT.SUCCESS,
                report: reportToSave,
            });
        }

        return save(currentReport.id, currentReport.unsavedId, reportToSave);
    };
}

export function cleanUp() {
    return (dispatch) => {
        dispatch({ type: CNST.DAILY_REPORTS.CLEAN_UP });
    };
}

export function getWeather(lat, long) {
    const url = `https://api.darksky.net/forecast/${Config.WEATHER_API_KEY}/${lat},${long}`;

    return Api()
        .get(url)
        .then((response) => {
            return response;
        })
        .catch((err) => {
            throw err.response;
        });
}

export function setNote(data = {}) {
    const { note } = data;
    return (dispatch) => {
        return dispatch({
            type: CNST.DAILY_REPORTS.SET_NOTE,
            note,
        });
    };
}

export function setUserTime(userTime) {
    return (dispatch) => {
        return dispatch({
            type: CNST.DAILY_REPORTS.SET_USER_TIME,
            userTime,
        });
    };
}

export function saveUserTime(userTime) {
    return (dispatch) => {
        return dispatch({
            type: CNST.DAILY_REPORTS.SAVE_USER_TIME,
            userTime,
        });
    };
}

export function saveNote(data = {}) {
    const { note } = data;
    return (dispatch) => {
        return dispatch({
            type: CNST.DAILY_REPORTS.SAVE_NOTE,
            note,
        });
    };
}

export function saveSurvey(survey) {
    return (dispatch) => {
        return dispatch({
            type: CNST.DAILY_REPORTS.SAVE_SURVEY,
            survey,
        });
    };
}

export function setCurrentProject(project) {
    return (dispatch) => {
        dispatch({
            type: CNST.DAILY_REPORTS.SET_CURRENT_PROJECT,
            project,
        });
    };
}

export function setViewingProject(project) {
    return (dispatch) => {
        dispatch({
            type: CNST.DAILY_REPORTS.SET_VIEWING_PROJECT,
            project,
        });
    };
}

export function setUserViewingReports(user) {
    return (dispatch) => {
        dispatch({
            type: CNST.DAILY_REPORTS.SET_USER_VIEWING_PROJECT,
            user,
        });
    };
}

export function setCurrentCostCode(data = {}) {
    const { code } = data;
    return (dispatch) => {
        dispatch({
            type: CNST.DAILY_REPORTS.SET_CURRENT_COST_CODE,
            code,
        });
    };
}

export function setDailyReport(report) {
    return (dispatch) => {
        dispatch({
            type: CNST.DAILY_REPORTS.SET_DAILY_REPORT,
            report,
        });
    };
}

export function uploadStart() {
    return (dispatch) => {
        dispatch({
            type: CNST.DAILY_REPORTS.UPLOAD_START,
        });
    };
}

export function uploadFinish() {
    return (dispatch) => {
        dispatch({
            type: CNST.DAILY_REPORTS.UPLOAD_FINISH,
        });
    };
}

export function removeAttachment(attachment) {
    return (dispatch) => {
        const { id, uploaded, uploading, uploadingStartTime, downloading } = attachment;
        const uploadingOverdue = isUploadingOverdue(uploadingStartTime);

        let showErrorMessage = false;
        let errorMessage;

        if (id && !isNil(uploaded) && !uploaded && uploading && !uploadingOverdue) {
            errorMessage = 'This attachment is currently uploading. Please wait.';
            showErrorMessage = true;
        } else if (downloading) {
            errorMessage = 'This attachment is currently downloading. Please wait.';
            showErrorMessage = true;
        }

        if (showErrorMessage) {
            showMessage({
                message: errorMessage,
                backgroundColor: Color.faded_orange,
                color: Color.dark_navy_blue,
                duration: 10000,
                animationDuration: 0,
                hideOnPress: true,
                hideStatusBar: true,
            });

            return false;
        }

        return dispatch({
            type: CNST.DAILY_REPORTS.REMOVE_NOTE_IMAGE,
            attachment,
        });
    };
}

export function dailyReportLoading() {
    return (dispatch) => {
        dispatch({
            type: CNST.DAILY_REPORTS.LOADING.START,
        });
    };
}

export function dailyReportFinishLoading() {
    return (dispatch) => {
        dispatch({
            type: CNST.DAILY_REPORTS.LOADING.FINISH,
        });
    };
}

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = {
    reports: [],
    currentProject: null,
    viewingProject: null,
    viewingUserReports: null,
    currentCostCode: null,
    currentReport: null,
    currentNote: null,
    currentUploading: [],
    isUploading: false,
    isLoading: false,
    isSaveLoading: false,
    isChanged: false,
};

export default function dailyReports(state = cloneDeep(initialState), action) {
    switch (action.type) {
        case CNST.DAILY_REPORTS.SUBMIT_DAILY_REPORT.LOADING: {
            return {
                ...state,
                isSaveLoading: true,
                isLoading: true,
            };
        }
        case CNST.DAILY_REPORTS.CLEAN_UP: {
            return {
                ...state,
                ...{
                    currentReport: null,
                    isLoading: false,
                    isSaveLoading: false,
                    isUploading: false,
                    currentUploading: [],
                    currentNote: null,
                    currentUserTime: null,
                    isChanged: false,
                },
            };
        }
        case CNST.DAILY_REPORTS.GET_USER_NAME: {
            const currentReports = state.reports;
            const users = action.data;
            for (let i = 0; i < currentReports.length; i++) {
                for (let j = 0; j < users.length; j++) {
                    if (currentReports[i].User === users[j]._id) {
                        currentReports[i].UserName = `${users[j].name.first} ${users[j].name.last}`.trim();
                    }
                }
            }
            return { ...state, ...{ reports: currentReports } };
        }
        case CNST.DAILY_REPORTS.GET_REPORTS: {
            // get current unsaved reports
            const unsavedReports = state.reports.filter((r) => r.unsaved);

            // get fresh reports except currently not saved to not overwrite unsaved data
            const updatedReports = action.data.reduce((reports, report) => {
                const isUnsaved = unsavedReports.some((r) => r.id === report.id);
                if (!isUnsaved) {
                    reports.push(report);
                }
                return reports;
            }, []);

            return {
                ...state,
                // merge current unsaved reports with fresh reports
                reports: [...unsavedReports, ...updatedReports],
            };
        }
        case CNST.DAILY_REPORTS.SET_CURRENT_PROJECT:
            return {
                ...state,
                ...{
                    currentProject: action.project,
                    currentUploading: [],
                },
            };
        case CNST.DAILY_REPORTS.SET_VIEWING_PROJECT:
            return {
                ...state,
                ...{
                    viewingProject: action.project,
                },
            };
        case CNST.DAILY_REPORTS.SET_USER_VIEWING_PROJECT:
            return {
                ...state,
                viewingUserReports: action.user,
            };
        case CNST.DAILY_REPORTS.SET_CURRENT_COST_CODE:
            return { ...state, ...{ currentCostCode: action.code } };
        case CNST.DAILY_REPORTS.CREATE_DAILY_REPORT:
        case CNST.DAILY_REPORTS.SET_DAILY_REPORT:
            return {
                ...state,
                ...{
                    currentReport: action.report,
                    currentUploading: [],
                },
            };
        case CNST.DAILY_REPORTS.SAVE_SURVEY: {
            const newReport = cloneDeep(state.currentReport);
            newReport.Survey = action.survey;
            return {
                ...state,
                ...{
                    currentReport: cloneDeep(newReport),
                    isChanged: true,
                },
            };
        }
        case CNST.DAILY_REPORTS.SET_NOTE: {
            const { note } = action;
            return {
                ...state,
                ...{
                    currentNote: note,
                },
            };
        }
        case CNST.DAILY_REPORTS.SET_USER_TIME: {
            const { userTime } = action;
            return {
                ...state,
                currentUserTime: userTime,
            };
        }
        case CNST.DAILY_REPORTS.SAVE_USER_TIME: {
            const { userTime } = action;
            const newReport = cloneDeep(state.currentReport);
            let isPersist = false;
            newReport.UsersTime.forEach((r, i) => {
                if (r.id === userTime.id) {
                    newReport.UsersTime[i] = userTime;
                    isPersist = true;
                }
            });
            if (!isPersist) {
                newReport.UsersTime.push(userTime);
            }
            return {
                ...state,
                currentReport: newReport,
                currentUserTime: null,
                isChanged: true,
            };
        }
        case CNST.DAILY_REPORTS.SAVE_NOTE: {
            const { note } = action;
            const newReport = state.currentReport;
            let isPersist = false;
            newReport.Notes.forEach((r, i) => {
                if (r.id === note.id) {
                    newReport.Notes[i] = note;
                    isPersist = true;
                }
            });
            if (!isPersist) {
                newReport.Notes.push(note);
            }
            return {
                ...state,
                ...{
                    currentReport: cloneDeep(newReport),
                    currentNote: null,
                    isChanged: true,
                },
            };
        }
        case CNST.DAILY_REPORTS.SUBMIT_DAILY_REPORT.SUCCESS: {
            const { report } = action;
            const unsavedId = action.unsavedId || report.unsavedId;
            let reports = null;
            let isExists = false;

            if (unsavedId) {
                isExists = state.reports.find((r) => r.unsavedId === unsavedId);
            }

            if (!isExists && report.id) {
                isExists = state.reports.find((r) => r.id === report.id);
            }

            if (isExists) {
                reports = state.reports.map((r) => {
                    if (unsavedId && r.unsavedId === unsavedId) {
                        return {
                            ...report,
                        };
                    }

                    if (report.id && r.id === report.id) {
                        return {
                            ...report,
                        };
                    }
                    return r;
                });
            } else {
                reports = [...state.reports, report];
            }

            let currentReport = null;
            // check if we need update current selected report
            if (state.currentReport) {
                currentReport = {
                    ...state.currentReport,
                };

                if (!currentReport.id && currentReport.unsavedId === unsavedId && report.id) {
                    // report was created
                    currentReport.id = report.id;
                    delete currentReport.status;
                    delete currentReport.unsaved;
                    delete currentReport.unsavedId;
                }
            }

            return {
                ...state,
                isLoading: false,
                isSaveLoading: false,
                reports,
                currentReport,
            };
        }
        case CNST.DAILY_REPORTS.SAVE_NOTE_IMAGE: {
            const { image } = action;
            const { currentNote } = state;

            let curAttachmentIndex = -1;
            let attachments;

            if (image.FileAssetId) {
                curAttachmentIndex = currentNote.Attachment.findIndex((a) => a.FileAssetId === image.FileAssetId);
            } else if (image.id) {
                curAttachmentIndex = currentNote.Attachment.findIndex((a) => a.id === image.id);
            }

            if (curAttachmentIndex >= 0) {
                attachments = currentNote.Attachment.map((attachment, index) => {
                    if (curAttachmentIndex === index) {
                        return image;
                    }

                    return attachment;
                });
            } else {
                attachments = [...currentNote.Attachment, image];
            }

            return {
                ...state,
                currentNote: {
                    ...currentNote,
                    Attachment: attachments,
                },
                isChanged: true,
                isUploading: false,
            };
        }
        case CNST.DAILY_REPORTS.REMOVE_NOTE_IMAGE: {
            const { attachment } = action;
            const { currentNote } = state;
            let attachments;

            if (attachment.id) {
                attachments = currentNote.Attachment.filter((a) => a.id !== attachment.id);
            } else {
                attachments = currentNote.Attachment.filter((a) => a.File !== attachment.File);
            }

            return {
                ...state,
                currentNote: {
                    ...currentNote,
                    Attachment: attachments,
                },
                isChanged: true,
                isUploading: false,
            };
        }
        case CNST.DAILY_REPORTS.FILE_UPLOAD.START: {
            return {
                ...state,
                ...{
                    currentUploading: cloneDeep([...state.currentUploading, action.item]),
                },
            };
        }
        case CNST.DAILY_REPORTS.FILE_UPLOAD.ERROR: {
            let newUploading = cloneDeep(state.currentUploading);
            newUploading = newUploading.filter((item) => item.id !== action.id);
            return {
                ...state,
                ...{
                    isUploading: false,
                    currentUploading: newUploading,
                },
            };
        }
        case CNST.DAILY_REPORTS.FILE_UPLOAD.SUCCESS: {
            const items = state.currentUploading.filter((a) => a.name !== action.name);
            return {
                ...state,
                ...{
                    currentUploading: cloneDeep(items),
                    isUploading: false,
                },
            };
        }
        case CNST.DAILY_REPORTS.ATTACHMENT_UPLOAD.START: {
            const { report, attachmentId, attachmentType } = action;
            const { id, unsavedId } = report;

            const setUploading = (attachments) => {
                return attachments.map((attachment) => {
                    if (attachment.id === attachmentId) {
                        return {
                            ...attachment,
                            uploading: true,
                            uploadingStartTime: new Date(),
                        };
                    }
                    return attachment;
                });
            };

            const processReport = (report) => {
                let fieldName = '';
                let items = null;
                if (attachmentType === 'note') {
                    fieldName = 'Notes';
                    items = report.Notes;
                }

                if (attachmentType === 'survey') {
                    fieldName = 'Survey';
                    items = report.Survey;
                }

                return {
                    ...report,
                    [fieldName]: items.map((item) => {
                        return {
                            ...item,
                            Attachment: setUploading(item.Attachment),
                        };
                    }),
                };
            };

            const reports = state.reports.map((r) => {
                if (unsavedId && r.unsavedId === unsavedId) {
                    return processReport(r);
                }

                if (id && r.id === id) {
                    return processReport(r);
                }
                return r;
            });

            let currentReport = null;
            // check if we need update current selected report
            if (state.currentReport) {
                if (
                    (unsavedId && state.currentReport.unsavedId === unsavedId) ||
                    (id && state.currentReport.id === id)
                ) {
                    currentReport = processReport(state.currentReport);
                }
            }

            let currentNote = null;
            if (state.currentNote && currentReport) {
                // update current note attachment info
                if (attachmentType === 'note') {
                    currentNote = {
                        ...state.currentNote,
                        Attachment: setUploading(state.currentNote.Attachment),
                    };
                } else {
                    currentNote = { ...state.currentNote };
                }
            }

            return {
                ...state,
                reports,
                currentReport,
                currentNote,
            };
        }
        case CNST.DAILY_REPORTS.ATTACHMENT_UPLOAD.ERROR: {
            const { report, attachmentId, attachmentType } = action;
            const { id, unsavedId } = report;

            const unSetUploading = (attachments) => {
                return attachments.map((attachment) => {
                    if (attachment.id === attachmentId) {
                        return {
                            ...attachment,
                            uploading: false,
                            uploadingStartTime: null,
                        };
                    }
                    return attachment;
                });
            };

            const processReport = (report) => {
                let fieldName = '';
                let items = null;
                if (attachmentType === 'note') {
                    fieldName = 'Notes';
                    items = report.Notes;
                }

                if (attachmentType === 'survey') {
                    fieldName = 'Survey';
                    items = report.Survey;
                }

                return {
                    ...report,
                    [fieldName]: items.map((item) => {
                        return {
                            ...item,
                            Attachment: unSetUploading(item.Attachment),
                        };
                    }),
                };
            };

            const reports = state.reports.map((r) => {
                if (unsavedId && r.unsavedId === unsavedId) {
                    return processReport(r);
                }

                if (id && r.id === id) {
                    return processReport(r);
                }
                return r;
            });

            let currentReport = null;
            // check if we need update current selected report
            if (state.currentReport) {
                if (
                    (unsavedId && state.currentReport.unsavedId === unsavedId) ||
                    (id && state.currentReport.id === id)
                ) {
                    currentReport = processReport(state.currentReport);
                }
            }

            let currentNote = null;
            if (state.currentNote && currentReport) {
                // update current note attachment info
                if (attachmentType === 'note') {
                    currentNote = {
                        ...state.currentNote,
                        Attachment: unSetUploading(state.currentNote.Attachment),
                    };
                } else {
                    currentNote = { ...state.currentNote };
                }
            }

            return {
                ...state,
                reports,
                currentReport,
                currentNote,
            };
        }
        case CNST.DAILY_REPORTS.ATTACHMENT_UPLOAD.SUCCESS: {
            const { asset, report, attachmentId, attachmentType } = action;
            const { id, unsavedId } = report;

            const replaceAttachment = (attachments) => {
                return attachments.map((attachment) => {
                    if (attachment.id === attachmentId) {
                        return {
                            File: asset.file.url,
                            FileAssetId: asset._id,
                        };
                    }
                    return attachment;
                });
            };

            const processReport = (report) => {
                let fieldName = '';
                let items = null;
                if (attachmentType === 'note') {
                    fieldName = 'Notes';
                    items = report.Notes;
                }

                if (attachmentType === 'survey') {
                    fieldName = 'Survey';
                    items = report.Survey;
                }

                return {
                    ...report,
                    [fieldName]: items.map((item) => {
                        return {
                            ...item,
                            Attachment: replaceAttachment(item.Attachment),
                        };
                    }),
                };
            };

            const reports = state.reports.map((r) => {
                if (unsavedId && r.unsavedId === unsavedId) {
                    return processReport(r);
                }

                if (id && r.id === id) {
                    return processReport(r);
                }
                return r;
            });

            let currentReport = null;
            // check if we need update current selected report
            if (state.currentReport) {
                if (
                    (unsavedId && state.currentReport.unsavedId === unsavedId) ||
                    (id && state.currentReport.id === id)
                ) {
                    currentReport = processReport(state.currentReport);
                }
            }

            let currentNote = null;
            if (state.currentNote && currentReport) {
                // update current note attachment info
                if (attachmentType === 'note') {
                    currentNote = {
                        ...state.currentNote,
                        Attachment: replaceAttachment(state.currentNote.Attachment),
                    };
                } else {
                    currentNote = { ...state.currentNote };
                }
            }

            return {
                ...state,
                reports,
                currentReport,
                currentNote,
            };
        }
        case CNST.DAILY_REPORTS.UPLOAD_START: {
            return {
                ...state,
                ...{
                    isUploading: true,
                },
            };
        }
        case CNST.DAILY_REPORTS.UPLOAD_FINISH: {
            return {
                ...state,
                ...{
                    isUploading: false,
                },
            };
        }
        case CNST.DAILY_REPORTS.LOADING.START: {
            return {
                ...state,
                ...{
                    isLoading: true,
                },
            };
        }
        case CNST.DAILY_REPORTS.LOADING.FINISH: {
            return {
                ...state,
                ...{
                    isLoading: false,
                },
            };
        }
        case CNST.ACCOUNT.LOGOUT.SUCCESS:
            return {
                ...state,
                ...cloneDeep(initialState),
                reports: state.reports.filter((r) => r.unsaved),
            };
        case CNST.DAILY_REPORTS.SUBMIT_DAILY_REPORT.ERROR: {
            return {
                ...state,
                isSaveLoading: false,
                isLoading: false,
            };
        }
        default:
            return state;
    }
}
