import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SectionList, Text, TouchableOpacity, View } from 'react-native';
import { withNavigationFocus } from 'react-navigation';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { editSubcontractor, addSubcontractor } from '../../../store/modules/changeOrder';

import { AddMaterialModal, NavigationBar } from '../../../components/index';
import ListItem from './ListItem/index';

import s from './styles';
import { Color } from '../../../theme';

class ChangeOrderItemPropertiesScreen extends Component {
    propTypes = {
        currentChangeOrder: PropTypes.object,
        user: PropTypes.object,
        editSubcontractor: PropTypes.func,
        addSubcontractor: PropTypes.func,
        deleteSubcontractor: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            editItem: null,
            itemType: 3,
        };
    }

    _returnSubcontractors() {
        const { currentChangeOrder } = this.props;
        let items = [];
        if (!isEmpty(currentChangeOrder) && !isEmpty(currentChangeOrder.Subcontractor)) {
            items = currentChangeOrder.Subcontractor.map((item, i) => {
                return {
                    id: `${i}`,
                    title: item.Name,
                    date: item.Date,
                    type: 3,
                    onPress: () => {
                        this.setState({ isModalOpen: true, editItem: item, itemType: 3, subcontractorId: i });
                    },
                };
            });
        }
        return items;
    }

    addItems(items, editItem) {
        const { currentChangeOrder, user, editSubcontractor, addSubcontractor } = this.props;
        const { itemType, subcontractorId } = this.state;
        if (itemType === 3) {
            if (editItem) {
                editSubcontractor({ item: items[0], subcontractorId, currentChangeOrder, user });
            } else {
                addSubcontractor({ items, currentChangeOrder, user });
            }
        }
        this.setState({ isModalOpen: false, editItem: null, subcontractorId: null });
    }

    deleteItem(item) {
        const { deleteSubcontractor, currentChangeOrder, user } = this.props;
        this.setState({ isModalOpen: false, editItem: null });
        deleteSubcontractor({ item, currentChangeOrder, user });
    }

    render() {
        const { isModalOpen, editItem, itemType } = this.state;
        const { currentChangeOrder } = this.props;
        const items = [
            {
                title: 'Subcontractors',
                data: this._returnSubcontractors(),
                button: currentChangeOrder.Complete.Complete === false && (
                    <View style={s.bottomButtonsBlock}>
                        <TouchableOpacity
                            style={s.addButton}
                            onPress={() => this.setState({ isModalOpen: true, itemType: 3 })}
                        >
                            <Icon name="plus" color={Color.white} size={30} />
                        </TouchableOpacity>
                    </View>
                ),
            },
        ];

        return (
            <View style={[s.wrapper]}>
                <NavigationBar {...this.props} title={currentChangeOrder.TicketName} backIcon />
                <View style={[s.mainContainer]}>
                    <SectionList
                        sections={items}
                        keyExtractor={(item) => item.id}
                        style={{ width: '100%' }}
                        contentContainerStyle={{ width: '100%', padding: 20, paddingBottom: 120 }}
                        renderSectionHeader={({ section: { title } }) => <Text style={s.listTitle}>{title}</Text>}
                        renderSectionFooter={({ section: { button } }) => button}
                        renderItem={({ item, i }) => {
                            return <ListItem key={i} item={item} i={i} isLast={item.id === items.length - 1} />;
                        }}
                    />
                </View>

                {currentChangeOrder.Complete.Complete === false && (
                    <AddMaterialModal
                        isOpen={isModalOpen}
                        itemType={itemType}
                        isEdit={!!editItem}
                        editItem={editItem}
                        onSubmit={(items) => this.addItems(items, editItem)}
                        onDelete={() => this.deleteItem(editItem)}
                        onCancel={() => {
                            this.setState({ isModalOpen: false, editItem: false });
                        }}
                    />
                )}
            </View>
        );
    }
}

const mapStateToProps = ({ changeOrder, account }) => ({
    user: account.user,
    currentChangeOrder: changeOrder.currentChangeOrderItem,
    isLoading: changeOrder.isLoading,
    costCodes: account.costCodes,
    projects: account.projects,
});

export default withNavigationFocus(
    connect(mapStateToProps, {
        editSubcontractor,
        addSubcontractor,
    })(ChangeOrderItemPropertiesScreen),
);
