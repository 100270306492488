import { StyleSheet } from 'react-native';
import { Color } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 140,
    },
    bottomButtonsBlock: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        position: 'absolute',
        bottom: 30,
        elevation: 12,
        height: 100,
    },
    addButton: {
        width: 43,
        height: 43,
        borderRadius: 22,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.turquoise,
        elevation: 12,
        shadowColor: Color.light_grey_blue,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.5,
        shadowRadius: 5,
    },
    row: {
        flexDirection: 'row', // Arrange items horizontally
        justifyContent: 'space-between', // Space evenly between items
        width: '90%',
        alignItems: 'center',
        paddingTop: 20,
    },
    item: {
        flex: 1, // Each item takes equal space
        justifyContent: 'center', // Center text within each item
        alignItems: 'center', // Center text vertically within each item
        backgroundColor: Color.turquoise,
        marginHorizontal: 5,
        padding: 10,
        color: 'white',
    },
    firstItem: {
        marginLeft: 0,
    },
    lastItem: {
        marginRight: 0,
    },
    statusSelectorContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 15,
        marginBottom: 15,
    },
    statusButton: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        marginHorizontal: 10,
        elevation: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        backgroundColor: Color.blue,
    },
    declinedButton: {
        backgroundColor: 'red',
    },
    approvedButton: {
        backgroundColor: 'green',
    },
    pendingButton: {
        backgroundColor: 'orange',
    },
    statusButtonText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
});
