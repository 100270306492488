import { ActivityIndicator, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEqual } from 'lodash';
import { showMessage } from 'react-native-flash-message';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import NetInfo from '@react-native-community/netinfo';
// import Dash from 'react-native-dash';

import { Color } from '../../../theme';
import { SignModal, PrimaryButton } from '../../../components';
import { formatTimeFromSeconds } from '../../../utils/helpers';
import { roundNumber } from '../../../utils/helpers';
import s from './styles';

export default class PayPeriod extends Component {
    static propTypes = {
        isLoading: PropTypes.bool,
        cards: PropTypes.array,
        user: PropTypes.object,
        timeCardModifications: PropTypes.array,
        payPeriods: PropTypes.array,
        generalRules: PropTypes.object,
        lastGetTimeCardsStartDate: PropTypes.string,
        isTimeCardsReplaced: PropTypes.bool,
        setLoading: PropTypes.func,
        signPayPeriod: PropTypes.func,
        uploadImage: PropTypes.func,
        addUnsavedSignedPeriod: PropTypes.func,
        getTimeCardsByPayPeriod: PropTypes.func,
        reimbursementRequestsByPayPeriod: PropTypes.array,
        getReimbursementRequestsByPayPeriod: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { user, getReimbursementRequestsByPayPeriod } = this.props;
        let currentPayPeriod = null;
        let currentPayPeriodIndex = -1;
        if (props.payPeriods && props.payPeriods.length > 0) {
            // by default the latest pay period is selected
            currentPayPeriodIndex = props.payPeriods.length - 1;
            currentPayPeriod = props.payPeriods[currentPayPeriodIndex];
            getReimbursementRequestsByPayPeriod(user._id, currentPayPeriod.id);
        }

        this.state = {
            hasNextPayPeriod: false,
            hasPreviousPayPeriod: props.payPeriods.length > 1,
            currentPayPeriodIndex,
            currentPayPeriod,
            isModalOpen: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { payPeriods } = props;
        const { currentPayPeriod } = state;

        if (currentPayPeriod) {
            const payPeriod = payPeriods.find((p) => p.id === currentPayPeriod.id);
            if (payPeriod && !isEqual(payPeriod, currentPayPeriod)) {
                state.currentPayPeriod = payPeriod;
            }
        }

        return state;
    }

    componentDidUpdate(prevProps, prevState) {
        const { user, isTimeCardsReplaced, payPeriods, getTimeCardsByPayPeriod, getReimbursementRequestsByPayPeriod } =
            this.props;
        const { currentPayPeriod } = this.state;
        if (isTimeCardsReplaced !== prevProps.isTimeCardsReplaced && isTimeCardsReplaced) {
            let currentPayPeriod = null;
            let currentPayPeriodIndex = -1;
            if (payPeriods && payPeriods.length > 0) {
                // by default the latest pay period is selected
                currentPayPeriodIndex = payPeriods.length - 1;
                currentPayPeriod = payPeriods[currentPayPeriodIndex];

                getTimeCardsByPayPeriod({
                    user,
                    startDate: currentPayPeriod.StartDate,
                    endDate: currentPayPeriod.EndDate,
                });
            }

            this.setState({
                hasNextPayPeriod: false,
                hasPreviousPayPeriod: payPeriods.length > 1,
                currentPayPeriodIndex,
                currentPayPeriod,
                isModalOpen: false,
            });
        }

        if (currentPayPeriod && currentPayPeriod.id !== prevState.currentPayPeriod.id) {
            getReimbursementRequestsByPayPeriod(user._id, currentPayPeriod.id);
        }
    }

    getReimbursementRequestsTotal() {
        const { reimbursementRequestsByPayPeriod } = this.props;
        const { currentPayPeriod } = this.state;
        let reimbursementTotal = 0;
        if (!currentPayPeriod) {
            return reimbursementTotal;
        }
        for (const reimbursementRequest of reimbursementRequestsByPayPeriod) {
            if (reimbursementRequest.Status === 'A') {
                reimbursementTotal += reimbursementRequest.TotalCost;
            }
        }
        return roundNumber(reimbursementTotal);
    }

    _getPayPeriodTitle() {
        const { currentPayPeriod } = this.state;

        if (currentPayPeriod) {
            const periodStart = moment(currentPayPeriod.StartDate).format('MMM DD');
            const periodEnd = moment(currentPayPeriod.EndDate).format('MMM DD');

            return `${periodStart} - ${periodEnd}`;
        }
        return '';
    }

    _renderDays() {
        const { currentPayPeriod } = this.state;
        const { cards } = this.props;
        const days = [];

        if (!currentPayPeriod) {
            return days;
        }

        const startDate = moment(currentPayPeriod.StartDate);
        const endDate = moment(currentPayPeriod.EndDate);

        days.push(
            <View key="header" style={s.heading}>
                <Text style={{ width: 100 }} />
                <Text style={s.reg}>REG</Text>
                <Text style={s.pto}>PTO</Text>
            </View>,
        );

        do {
            const currentDay = startDate.format('YYYY-MM-DD');

            let regDayTotal = 0;
            let ptoDayTotal = 0;
            cards.forEach((card) => {
                if (currentDay === card.date) {
                    regDayTotal += card.totalTime;
                    if (card.type) {
                        ptoDayTotal += card.ptoTime * 3600;
                    }
                }
            });

            let regDayTotalTextStyle = null;
            if (ptoDayTotal > 0 && regDayTotal > 0) {
                regDayTotalTextStyle = s.weekDayTimeRegPadding;
            } else if (ptoDayTotal > 0) {
                regDayTotalTextStyle = s.weekDayTime_inactiveRegPadding;
            } else if (regDayTotal > 0) {
                regDayTotalTextStyle = s.weekDayTimeReg;
            } else {
                regDayTotalTextStyle = s.weekDayTime_inactiveReg;
            }

            days.push(
                <View style={s.weekDay} key={currentDay}>
                    <View style={{ flexDirection: 'row', width: 100 }}>
                        <Text style={s.weekDayDate}>{startDate.format('MM/DD')}</Text>
                        <Text style={s.weekDayName}>{startDate.format('dddd')}</Text>
                    </View>
                    <Text style={regDayTotalTextStyle}>
                        {regDayTotal > 0 ? formatTimeFromSeconds(regDayTotal, false) : '-'}
                    </Text>

                    <Text style={ptoDayTotal > 0 ? s.weekDayTimePto : s.weekDayTime_inactivePto}>
                        {ptoDayTotal > 0 ? formatTimeFromSeconds(ptoDayTotal, false) : '-'}
                    </Text>
                </View>,
            );
        } while (startDate.add(1, 'day').isSameOrBefore(endDate, 'day'));

        return days;
    }

    _hasTimeCardModifications() {
        const { timeCardModifications } = this.props;
        const { currentPayPeriod } = this.state;

        if (!currentPayPeriod) {
            return false;
        }

        const startDate = moment(currentPayPeriod.StartDate);
        const endDate = moment(currentPayPeriod.EndDate);

        let hasTimeCardModifications = false;
        for (let i = 0; i < timeCardModifications.length; ++i) {
            const modificationStartTime = moment(timeCardModifications[i].StartTime);

            if (modificationStartTime.isBetween(startDate, endDate, undefined, '[]')) {
                hasTimeCardModifications = true;
                break;
            }
        }
        return hasTimeCardModifications;
    }

    _getWorkedTimes() {
        const { currentPayPeriod } = this.state;

        if (!currentPayPeriod) {
            return {
                regularWorkTime: 0,
                overWorkTime: 0,
                doubleWorkTime: 0,
            };
        }

        return {
            regularWorkTime: currentPayPeriod.regularWorkTime,
            overWorkTime: currentPayPeriod.overWorkTime - currentPayPeriod.doubleOverWorkTime,
            doubleWorkTime: currentPayPeriod.doubleOverWorkTime,
        };
    }

    _getTotalTime(type) {
        const { regularWorkTime, overWorkTime, doubleWorkTime } = this._getWorkedTimes();

        let totalTime = 0;

        if (type === 'regular') {
            totalTime = regularWorkTime;
        } else if (type === 'overtime') {
            totalTime = overWorkTime;
        } else {
            totalTime = doubleWorkTime;
        }

        return formatTimeFromSeconds(totalTime);
    }

    handleNextPeriodPress = () => {
        const { payPeriods } = this.props;
        const { currentPayPeriodIndex } = this.state;

        if (currentPayPeriodIndex === payPeriods.length - 1) {
            // we are on the lat pay period already
            return;
        }

        const nextIndex = currentPayPeriodIndex + 1;
        const nextPayPeriod = payPeriods[nextIndex];

        this.setState({
            hasNextPayPeriod: nextIndex < payPeriods.length - 1,
            hasPreviousPayPeriod: true,
            currentPayPeriodIndex: nextIndex,
            currentPayPeriod: nextPayPeriod,
        });
    };

    handlePreviousPeriodPress = () => {
        const { payPeriods, getTimeCardsByPayPeriod, user, lastGetTimeCardsStartDate } = this.props;
        const { currentPayPeriodIndex } = this.state;

        if (currentPayPeriodIndex === 0) {
            // we are on the first pay period already
            return;
        }

        const previousIndex = currentPayPeriodIndex - 1;
        const previousPayPeriod = payPeriods[previousIndex];

        if (
            (previousPayPeriod && user && lastGetTimeCardsStartDate === null) ||
            (previousPayPeriod &&
                user &&
                moment(lastGetTimeCardsStartDate).isAfter(moment(previousPayPeriod.StartDate)))
        ) {
            getTimeCardsByPayPeriod({
                user,
                startDate: previousPayPeriod.StartDate,
                endDate: previousPayPeriod.EndDate,
            });
        }

        this.setState({
            hasNextPayPeriod: true,
            hasPreviousPayPeriod: previousIndex > 0,
            currentPayPeriodIndex: previousIndex,
            currentPayPeriod: previousPayPeriod,
        });
    };

    _handleSignSave = (signImageBuffer) => {
        const { uploadImage, signPayPeriod, user, setLoading, addUnsavedSignedPeriod, getTimeCardsByPayPeriod } =
            this.props;
        const { currentPayPeriod } = this.state;

        const currentPayPeriodStartTime = moment(currentPayPeriod.StartDate).format('MM/DD/YYYY');
        const currentPayPeriodEndTime = moment(currentPayPeriod.EndDate).format('MM/DD/YYYY');

        const data = {
            name: `${user.fullName} time card sign (${currentPayPeriodStartTime} - ${currentPayPeriodEndTime})`,
            type: 'image/png',
            fileName: `${currentPayPeriod.id}.png`,
            types: ['image'],
            subtypes: [],
            tags: [
                {
                    value: 'image',
                    protected: true,
                },
            ],
        };
        setLoading(true);
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                uploadImage(data, signImageBuffer, 'sign').then(({ asset, error }) => {
                    if (error) {
                        setLoading(false);
                        showMessage({
                            message: 'Error',
                            description: 'An error occurred while uploading your signature. Please try again.',
                            type: 'danger',
                            icon: 'danger',
                            position: 'right',
                            duration: 3000,
                            hideStatusBar: true,
                            backgroundColor: Color.red,
                        });
                        return;
                    }

                    const signRequests = [];
                    currentPayPeriod.timeCards.forEach((timeCard) => {
                        if (!timeCard.Sign.Image) {
                            signRequests.push(signPayPeriod(timeCard.id, asset, user, currentPayPeriod.id));
                        }
                    });
                    if (signRequests.length > 0) {
                        Promise.all(signRequests).then(() => {
                            getTimeCardsByPayPeriod({
                                user,
                                startDate: currentPayPeriod.StartDate,
                                endDate: currentPayPeriod.EndDate,
                            });
                        });
                    }
                });
            } else {
                currentPayPeriod.timeCards.forEach((timeCard) => {
                    if (!timeCard.Sign.Image) {
                        addUnsavedSignedPeriod(timeCard.id, data, signImageBuffer, currentPayPeriod.id);
                    }
                });
                showMessage({
                    message:
                        // eslint-disable-next-line max-len
                        'No data connection detected. Your signature/approval has been saved on your device and will be sent when you have a data connection.',
                    backgroundColor: Color.faded_orange,
                    color: Color.dark_navy_blue,
                    duration: 10000,
                    animationDuration: 0,
                    hideOnPress: true,
                    hideStatusBar: true,
                });
            }
        });
        this.setState({ isModalOpen: false });
    };

    _getTimeOffTotal(type) {
        const { cards } = this.props;
        const { currentPayPeriod } = this.state;

        if (!currentPayPeriod) {
            return '0:00';
        }
        const startDate = currentPayPeriod.StartDate;
        const endDate = currentPayPeriod.EndDate;
        let totalTime = 0;
        for (const card of cards) {
            if (type === card.type && moment(card.date).isBetween(startDate, endDate, undefined, '[]')) {
                totalTime += card.ptoTime * 3600;
            }
        }

        return formatTimeFromSeconds(totalTime);
    }

    render() {
        const { isLoading, generalRules } = this.props;
        const { isModalOpen, hasPreviousPayPeriod, hasNextPayPeriod, currentPayPeriod } = this.state;
        const reimbursementTotal = this.getReimbursementRequestsTotal();
        const { isSigned, isApproved } = currentPayPeriod;
        const hasTimeCardModifications = this._hasTimeCardModifications();

        const renderedDays = this._renderDays();
        const noCards = currentPayPeriod.timeCards && currentPayPeriod.timeCards.length === 0;

        let status = 'NO TIME CARDS';
        if (!noCards) {
            if (isApproved) {
                status = 'APPROVED';
            } else if (isSigned) {
                status = 'WAITING FOR APPROVAL';
            } else {
                status = 'NOT SUBMITTED';
            }
        }

        return (
            <View style={s.mainContainer}>
                <View style={s.weekMenu}>
                    <TouchableOpacity
                        disabled={!hasPreviousPayPeriod}
                        style={{ opacity: hasPreviousPayPeriod ? 1 : 0 }}
                        onPress={this.handlePreviousPeriodPress}
                    >
                        <Icon name="chevron-left" style={s.weekMenuIcon} />
                    </TouchableOpacity>
                    <Text style={s.title}>{this._getPayPeriodTitle()}</Text>
                    <TouchableOpacity
                        disabled={!hasNextPayPeriod}
                        style={{ opacity: hasNextPayPeriod ? 1 : 0 }}
                        onPress={this.handleNextPeriodPress}
                    >
                        <Icon name="chevron-right" style={s.weekMenuIcon} />
                    </TouchableOpacity>
                </View>

                {isLoading ? (
                    <ActivityIndicator
                        size={35}
                        color={Color.blue}
                        style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
                    />
                ) : (
                    <>
                        <ScrollView style={s.formBlock}>
                            <View style={[s.formHeaderBlock, s.formHeaderBlock_paymentPeriod]}>
                                <View style={s.formHeaderBlockCol}>
                                    <Text
                                        style={[
                                            s.periodStatus,
                                            isSigned && s.periodStatus_waiting,
                                            isApproved && s.periodStatus_approved,
                                        ]}
                                    >
                                        {status}
                                    </Text>
                                </View>
                            </View>

                            <View style={s.formBlockDates}>{renderedDays}</View>

                            <View style={s.formBlockTotal}>
                                <Text style={s.weekTotal}>REGULAR</Text>
                                <Text style={s.weekTotal}>{this._getTotalTime('regular')}</Text>
                            </View>

                            <View style={s.formBlockTotal}>
                                <Text style={s.weekTotal}>OVERTIME</Text>
                                <Text style={s.weekTotal}>{this._getTotalTime('overtime')}</Text>
                            </View>

                            <View style={s.formBlockTotal}>
                                <Text style={s.weekTotal}>DOUBLE TIME</Text>
                                <Text style={s.weekTotal}>{this._getTotalTime('double')}</Text>
                            </View>

                            <View style={s.formBlockTotal}>
                                <Text style={s.weekTotal}>Personal Time Off</Text>
                                <Text style={s.weekTotal}>{this._getTimeOffTotal('Personal Time Off')}</Text>
                            </View>

                            <View style={s.formBlockTotal}>
                                <Text style={s.weekTotal}>Vacation Time off</Text>
                                <Text style={s.weekTotal}>{this._getTimeOffTotal('Vacation Time Off')}</Text>
                            </View>

                            <View style={s.formBlockTotal}>
                                <Text style={s.weekTotal}>Sick Time off</Text>
                                <Text style={s.weekTotal}>{this._getTimeOffTotal('Sick Time Off')}</Text>
                            </View>

                            <View style={s.formBlockTotal}>
                                <Text style={s.weekTotal}>Other Time Off</Text>
                                <Text style={s.weekTotal}>{this._getTimeOffTotal('Other PTO')}</Text>
                            </View>

                            {reimbursementTotal > 0 ? (
                                <View style={s.formBlockTotal}>
                                    <Text style={s.weekTotal}>Reimbursement Total </Text>
                                    <Text style={[[s.weekTotal, s.weekTotal_right]]}>{reimbursementTotal}</Text>
                                </View>
                            ) : null}
                            {hasTimeCardModifications && (
                                <View>
                                    <Text style={[s.timeCardEdits]}>
                                        The selected week has a time card modifications
                                    </Text>
                                </View>
                            )}
                        </ScrollView>
                        <View style={s.buttonWrapper}>
                            {!noCards && !isSigned && !isApproved && (
                                <PrimaryButton
                                    title="Sign Time Cards"
                                    onPress={() => {
                                        this.setState({ isModalOpen: true });
                                    }}
                                    isLoading={isLoading}
                                    icon="arrow-right"
                                />
                            )}
                        </View>
                    </>
                )}
                <SignModal
                    isOpen={isModalOpen}
                    title="Sign Time Card"
                    onSave={this._handleSignSave}
                    onCancel={() => {
                        this.setState({ isModalOpen: false });
                    }}
                    generalRules={generalRules}
                />
            </View>
        );
    }
}
