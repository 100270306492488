import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, SectionList } from 'react-native';
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { setNote } from '../../../store/modules/dailyReports';
import { NavigationBar } from '../../../components/index';

import { generateId } from '../../../utils/helpers';

import s from './styles';
import { Color } from '../../../theme';

class DailyNotesScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentReport: PropTypes.object,
        setNote: PropTypes.func,
    };

    state = { items: [] };

    componentDidMount() {
        this._returnItems();
    }

    componentDidUpdate(prevProps) {
        const { currentReport } = this.props;
        if (currentReport.Notes.length !== prevProps.currentReport.Notes.length) {
            this._returnItems();
        }
    }

    _returnItems() {
        const { currentReport } = this.props;
        const items = [];
        currentReport.Notes.forEach((note) => {
            let isPersist = false;
            const listItem = {
                id: note.id,
                title: note.Note,
                attachments: note.Attachment.length,
            };
            items.forEach((item) => {
                if (item.title === note.CategoryName) {
                    item.data.push(listItem);
                    isPersist = true;
                }
            });
            if (!isPersist) {
                items.push({
                    title: note.CategoryName,
                    data: [listItem],
                });
            }
        });
        this.setState({ items });
    }

    _returnItem(note) {
        const { navigation, setNote, currentReport } = this.props;
        let currentNote;
        currentReport.Notes.forEach((n) => {
            if (n.id === note.id) {
                currentNote = n;
            }
        });
        return (
            <TouchableOpacity
                style={s.itemWrapper}
                onPress={() => {
                    setNote({ note: currentNote });
                    navigation.navigate('DailyNote');
                }}
            >
                <Text style={s.itemTitle}>{note.title.substr(0, 20)}</Text>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={s.itemAttachments}>{note.attachments}</Text>
                    <Icon name="attachment" color={Color.light_navy} size={20} />
                </View>
            </TouchableOpacity>
        );
    }

    static _returnTitleComponent(title) {
        return <Text style={s.sectionHeader}>{title}</Text>;
    }

    createNote = () => {
        const { navigation, setNote } = this.props;
        const note = {
            id: generateId(),
            CategoryName: '',
            Note: '',
            Attachment: [],
        };
        setNote({ note });
        navigation.navigate('DailyNote');
    };

    render() {
        const { items } = this.state;
        return (
            <View style={[s.wrapper]}>
                <NavigationBar {...this.props} title="NOTES" backIcon />
                <View style={s.menuTopContainer}>
                    <Text style={s.topMenu_title}>{moment().format('MM/DD/YY')}</Text>
                </View>
                <View style={[s.mainContainer]}>
                    <SectionList
                        sections={items}
                        keyExtractor={(item, i) => item.id + i}
                        renderItem={({ item }) => this._returnItem(item)}
                        renderSectionHeader={({ section: { title } }) => DailyNotesScreen._returnTitleComponent(title)}
                        contentContainerStyle={{ paddingBottom: 0, alignSelfL: 'center' }}
                        style={{ width: '100%' }}
                    />
                    <TouchableOpacity style={s.addButton} onPress={this.createNote}>
                        <Icon name="plus" color={Color.white} size={30} />
                    </TouchableOpacity>
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ dailyReports }) => ({
    currentReport: dailyReports.currentReport,
});

export default connect(mapStateToProps, { setNote })(DailyNotesScreen);
