import { Defs, LinearGradient, Rect, Stop, Svg } from 'react-native-svg';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class TopGradient extends PureComponent {
    static propTypes = {
        color: PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.string]),
    };

    render() {
        const { color } = this.props;
        const c = color || 'rgb(255,255,255)';
        return (
            <Svg height="60%" width="100%" style={{ position: 'absolute', left: 0, top: 0, borderRadius: 0 }}>
                <Defs>
                    <LinearGradient id="grad" x1="0" y1="0" x2="0" y2="100%">
                        <Stop offset="0" stopColor={c} stopOpacity="1" />
                        <Stop offset="0.5" stopColor={c} stopOpacity="0.8" />
                        <Stop offset="1" stopColor={c} stopOpacity="0" />
                    </LinearGradient>
                </Defs>
                <Rect x="0" y="0" width="100%" height="100%" fill="url(#grad)" />
            </Svg>
        );
    }
}
