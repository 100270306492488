import { StyleSheet, Platform } from 'react-native';
import { Font, Color } from '../../../theme/index';

const isIOS = Platform.OS === 'ios';
export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        paddingTop: 16,
        alignItems: 'center',
    },
    logo: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingTop: '10%',
        paddingBottom: '10%',
    },
    logoImg: {
        width: 150,
        height: 50,
    },
    form: {
        height: '70%',
    },
    menuTopContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    topMenu_title: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
    },
    addButton: {
        width: 43,
        height: 43,
        borderRadius: 22,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        backgroundColor: Color.turquoise,
        bottom: 160,
        left: '50%',
        marginLeft: -21.5,
        elevation: 12,
        shadowColor: Color.light_grey_blue,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.5,
        shadowRadius: 5,
    },
    itemWrapper: {
        width: '90%',
        height: !isIOS ? 50 : 60,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 20,
        paddingHorizontal: 16,
        backgroundColor: Color.white,
        borderRadius: 10,
        elevation: 6,
        marginBottom: 15,
        alignSelf: 'center',
    },
    itemTitle: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font16,
        color: Color.dark_navy_blue,
    },
    itemAttachments: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font16,
        color: Color.light_navy,
        marginRight: 6,
    },
    sectionHeader: {
        marginBottom: 15,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.light_navy,
        width: '90%',
        left: '5%',
    },
});
