import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    View,
    Text,
    FlatList,
    TouchableOpacity,
    Image,
    ActivityIndicator,
    KeyboardAvoidingView,
    Platform,
} from 'react-native';

import { showMessage } from 'react-native-flash-message';

import { connect } from 'react-redux';
import { isEmpty, cloneDeep, isEqual, isNil } from 'lodash';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import { generateId, downloadImage } from '../../../utils/helpers';
import { isUploadingOverdue } from '../../../store/helpers/common';

import { saveSurvey, uploadStart } from '../../../store/modules/dailyReports';

import {
    NavigationBar,
    FieldInput,
    YesNoAnswer,
    FileUploadModal,
    PrimaryButton,
    Canvas,
} from '../../../components/index';

import s from './styles';
import { Color } from '../../../theme';

const _getAttachmentData = (item, currentSurvey, attachmentsCount) => {
    return {
        id: generateId(),
        uploading: false,
        uploaded: false,
        name: `${moment().format('DD_MM_YYYY')}_survey_${currentSurvey.id}_attachment_${attachmentsCount}`,
        type: item.type,
        fileName: `${moment().format('DD_MM_YYYY')}_survey_${currentSurvey.id}_attachment_${attachmentsCount}.jpg`,
        types: ['image'],
        subtypes: [],
        tags: [
            {
                value: 'image',
                protected: true,
            },
        ],
        file: item.base64,
        path: item.uri,
    };
};

class DailySurveyScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentReport: PropTypes.object,
        currentUploading: PropTypes.array,
        isUploading: PropTypes.bool,
        uploadStart: PropTypes.func,
        saveSurvey: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { currentReport } = this.props;
        this.state = {
            isAttachmentModal: false,
            currentSurvey: null,
            surveys: cloneDeep(currentReport.Survey).map((s) => ({
                id: generateId(),
                ...s,
            })),
            isUpdated: false,
            imageSelected: false,
            selectedImage: null,
        };
    }

    componentDidUpdate(prevProps) {
        const { currentReport: prevCurrentReport } = prevProps;
        const { currentReport } = this.props;

        if (!isEqual(prevCurrentReport.Survey, currentReport.Survey)) {
            this.setState({
                surveys: cloneDeep(currentReport.Survey).map((s) => ({
                    id: generateId(),
                    ...s,
                })),
            });
        }
    }

    updateSurvey(data = {}) {
        const { item, value, description, attachment } = data;
        const { surveys } = this.state;
        const newSurveys = cloneDeep(surveys);
        const newItem = {
            Label: item.Label,
            Value: value || item.Value,
            Description: description || item.Description,
            Attachment: attachment || item.Attachment,
        };
        newSurveys.forEach((s, i) => {
            if (s.Label === newItem.Label) {
                newSurveys[i] = {
                    ...newSurveys[i],
                    ...newItem,
                };
            }
        });
        this.setState({
            surveys: newSurveys,
            isUpdated: true,
        });
    }

    removeAttachment(removedAttachment, surveyId) {
        const { id, uploaded, uploading, uploadingStartTime, downloading } = removedAttachment;
        const uploadingOverdue = isUploadingOverdue(uploadingStartTime);

        let showErrorMessage = false;
        let errorMessage;

        if (id && !isNil(uploaded) && !uploaded && uploading && !uploadingOverdue) {
            errorMessage = 'This attachment is currently uploading. Please wait.';
            showErrorMessage = true;
        } else if (downloading) {
            errorMessage = 'This attachment is currently downloading. Please wait.';
            showErrorMessage = true;
        }

        if (showErrorMessage) {
            showMessage({
                message: errorMessage,
                backgroundColor: Color.faded_orange,
                color: Color.dark_navy_blue,
                duration: 10000,
                animationDuration: 0,
                hideOnPress: true,
                hideStatusBar: true,
            });

            return false;
        }

        const { surveys } = this.state;
        const newSurveys = cloneDeep(surveys);
        const survey = newSurveys.find((s) => s.id === surveyId);

        if (removedAttachment.id) {
            survey.Attachment = survey.Attachment.filter((a) => a.id !== removedAttachment.id);
        } else {
            survey.Attachment = survey.Attachment.filter((a) => a.File !== removedAttachment.File);
        }

        this.setState({
            surveys: newSurveys,
            isUpdated: true,
        });

        return true;
    }

    _updateAttachment(attachment, surveyId) {
        const { surveys } = this.state;
        const newSurveys = cloneDeep(surveys);
        const survey = newSurveys.find((s) => s.id === surveyId);

        for (let i = 0; i < survey.Attachment.length; i++) {
            if (attachment.FileAssetId === survey.Attachment[i].FileAssetId) {
                survey.Attachment[i] = {
                    ...survey.Attachment[i],
                    ...attachment,
                };
            }
        }

        return newSurveys;
    }

    _selectOrDownloadImage = async (attachment, item) => {
        const updatedState = {};

        if (attachment.File && !attachment.file) {
            const { downloading } = attachment;
            if (!downloading) {
                this.setState({
                    surveys: this._updateAttachment(
                        {
                            ...attachment,
                            downloading: true,
                        },
                        item.id,
                    ),
                });

                downloadImage(attachment.File).then((image) => {
                    if (image) {
                        const { base64Image, path, type } = image;
                        if (base64Image && path) {
                            const updatedAttachment = {
                                ...attachment,
                                downloading: false,
                                ..._getAttachmentData(
                                    {
                                        type,
                                        base64: base64Image,
                                        uri: path,
                                    },
                                    item.Attachment.length + 1,
                                ),
                            };
                            updatedState.surveys = this._updateAttachment(updatedAttachment, item.id);
                            updatedState.selectedImage = updatedAttachment;
                            updatedState.imageSelected = true;
                            this.setState(updatedState);
                        }
                    } else {
                        this.setState({
                            surveys: this._updateAttachment(
                                {
                                    ...attachment,
                                    downloading: false,
                                },
                                item.id,
                            ),
                        });
                    }
                });
            }
        } else {
            updatedState.selectedImage = attachment;
            updatedState.imageSelected = true;
            this.setState(updatedState);
        }
    };

    _returnAttachment(attachment, i, item) {
        return (
            <TouchableOpacity
                style={s.attachmentItem}
                key={i}
                onPress={() => this._selectOrDownloadImage(attachment, item)}
            >
                <Image
                    source={{ uri: attachment.File ? attachment.File : `data:image/png;base64,${attachment.file}` }}
                    style={s.attachmentImage}
                />
                {(attachment.uploading || attachment.downloading) && (
                    <View style={s.uploadingWrapper}>
                        <Text style={s.uploadingLabel}>{attachment.uploading ? 'uploading...' : 'downloading...'}</Text>
                    </View>
                )}
                <TouchableOpacity style={s.attachmentButton} onPress={() => this.removeAttachment(attachment, item.id)}>
                    <Icon name="close" size={25} color={Color.turquoise} />
                </TouchableOpacity>
            </TouchableOpacity>
        );
    }

    saveImageDrawing = (image) => {
        const { surveys } = this.state;
        const newSurveys = cloneDeep(surveys);

        for (const s of newSurveys) {
            const foundIndex = s.Attachment.findIndex((a) => {
                return a.id === image.id;
            });

            if (foundIndex > -1) {
                delete image.File;
                delete image.FileAssetId;
                s.Attachment.splice(foundIndex, 1, image);
                break;
            }
        }

        this.setState({
            surveys: newSurveys,
            imageSelected: false,
            selectedImage: null,
        });
    };

    close = () => {
        this.setState({ imageSelected: false, selectedImage: null });
    };

    async setAttachments(items) {
        const { surveys, currentSurvey } = this.state;
        const newSurveys = cloneDeep(surveys);

        let i = 1;
        for (const item of items) {
            let currentLength = 0;
            surveys.forEach((s) => {
                if (s.Label === currentSurvey.Label) {
                    currentLength = s.Attachment.length;
                }
            });
            const data = _getAttachmentData(item, currentSurvey, currentLength + i);
            newSurveys.forEach((s) => {
                if (s.Label === currentSurvey.Label) {
                    s.Attachment.push(data);
                }
            });
            i++;
        }

        this.setState({
            isAttachmentModal: false,
            surveys: newSurveys,
            isUpdated: true,
        });
    }

    save = () => {
        const { saveSurvey, navigation } = this.props;
        const { surveys } = this.state;
        saveSurvey(
            surveys.map((s) => {
                // remove temp id key
                const { id, Attachment, ...surveyToSave } = s;

                surveyToSave.Attachment = Attachment.map((attachment) => {
                    const { downloading, File, FileAssetId, ...updatedAttachment } = attachment;
                    if (File) {
                        return { File, FileAssetId };
                    }

                    return updatedAttachment;
                });

                return surveyToSave;
            }),
        );
        navigation.goBack();
    };

    _returnItem(item) {
        const { currentUploading } = this.props;
        return (
            <View style={s.surveyItem}>
                <Text style={s.itemTitle}>{item.Label}</Text>
                <View style={s.itemRow}>
                    <YesNoAnswer answer={item.Value} onChange={(value) => this.updateSurvey({ item, value })} />
                    <TouchableOpacity
                        onPress={() => this.setState({ isAttachmentModal: true, currentSurvey: item })}
                        style={s.iconWrapper}
                    >
                        <Icon name="attachment" color={Color.turquoise} size={20} />
                    </TouchableOpacity>
                </View>
                <FieldInput
                    multiline
                    placeholder="Please Describe"
                    style={{
                        borderWidth: 1,
                        borderColor: Color.light_grey_blue,
                        marginBottom: 10,
                        elevation: 0,
                    }}
                    input={{
                        onChange: (description) => {
                            this.updateSurvey({ item, description });
                        },
                    }}
                    meta={{
                        error: null,
                        touched: false,
                    }}
                    initialValues={item.Description}
                />
                <View style={s.attachmentsWrapper}>
                    {!isEmpty(item.Attachment) &&
                        item.Attachment.map((a, i) => {
                            return this._returnAttachment(a, i, item);
                        })}
                    {currentUploading.map((u, i) => {
                        if (u.id === item.id) {
                            return (
                                <View style={s.attachmentItem} key={i}>
                                    <ActivityIndicator size={40} color={Color.blue} />
                                </View>
                            );
                        }
                        return null;
                    })}
                </View>
            </View>
        );
    }

    render() {
        const { currentUploading, isUploading } = this.props;
        const { isAttachmentModal, isUpdated, surveys, imageSelected, selectedImage } = this.state;
        const isUploadings = !isEmpty(currentUploading);
        const backConfirmText =
            'You are about to back out of surveys. All not saved data will be lost. Are you sure you want to do this ?';
        const backTitle = 'Warning';
        const footerComponent = (
            <View style={s.buttonWrapper}>
                <PrimaryButton title="SAVE" onPress={this.save} disabled={!isEmpty(currentUploading)} />
            </View>
        );

        return (
            <View style={[s.wrapper]}>
                <NavigationBar
                    {...this.props}
                    title="DAILY SURVEY"
                    backIcon
                    disableBackIcon={isUploadings}
                    backConfirm={isUpdated ? { title: backTitle, text: backConfirmText } : null}
                />
                <View style={s.menuTopContainer}>
                    <Text style={s.topMenu_title}>{moment().format('MM/DD/YY')}</Text>
                </View>
                <KeyboardAvoidingView style={[s.mainContainer]} behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
                    <FlatList
                        data={surveys}
                        contentContainerStyle={{ paddingBottom: 0 }}
                        keyExtractor={(item) => item.id}
                        style={{ elevation: 20, width: '100%' }}
                        renderItem={({ item }) => this._returnItem(item)}
                        ListFooterComponent={footerComponent}
                    />
                </KeyboardAvoidingView>
                <FileUploadModal
                    isVisible={isAttachmentModal}
                    onCancel={() => this.setState({ isAttachmentModal: false })}
                    returnItems={(attachments) => this.setAttachments(attachments)}
                    isUploading={isUploading}
                    uploadStart={uploadStart}
                />
                <Canvas
                    visible={imageSelected}
                    image={selectedImage}
                    closeImageButton={this.close}
                    saveImage={this.saveImageDrawing}
                />
            </View>
        );
    }
}

const mapStateToProps = ({ account, dailyReports, currentTimeCard }) => ({
    currentTimeCard,
    costCodes: account.costCodes,
    currentReport: dailyReports.currentReport,
    currentUploading: dailyReports.currentUploading,
    isUploading: dailyReports.isUploading,
});

export default connect(mapStateToProps, {
    saveSurvey,
    uploadStart,
})(DailySurveyScreen);
