import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    mainContainer: {
        height: '100%',
        paddingTop: 40,
        alignItems: 'center',
    },
    timer: {
        fontSize: Font.size.font46,
        color: Color.faded_orange,
        fontWeight: '600',
        fontFamily: Font.type.regular,
    },
    timerGrey: {
        fontSize: Font.size.font18,
        color: Color.light_grey_blue,
        fontFamily: Font.type.regular,
    },
    buttonWrapper: {
        width: 180,
        marginTop: 20,
    },
    break_logo: {
        width: 113,
        height: 105,
        alignSelf: 'center',
        marginBottom: 30,
    },
    divider: {
        marginTop: 70,
        marginBottom: 24,
        color: Color.cloudy_blue,
        fontSize: Font.size.font11,
    },
    currentProjectWrapper: {
        alignItems: 'center',
        width: 280,
    },
    currentProjectLabel: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font10,
        color: Color.light_grey_blue,
    },
    currentProjectName: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue,
    },
    currentProjectChevron: {
        position: 'absolute',
        right: 0,
        top: 5,
        fontSize: Font.size.font26,
        color: Color.light_grey_blue,
    },
});
