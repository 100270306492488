import { StyleSheet } from 'react-native';
import { Font, Color } from '../../../theme';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        paddingTop: 16,
        alignItems: 'center',
    },
    dateItems: {
        width: '100%',
        paddingHorizontal: 22.5,
        marginBottom: 10,
    },
    dateTitle: {
        width: '100%',
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.light_navy,
    },
    dateItemsWrapper: {
        marginVertical: 13,
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
    },
    dateItem: {
        width: 100,
        height: 100,
        borderRadius: 6,
        marginRight: 20,
        marginBottom: 10,
    },
    offline: {
        flex: 0.33,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 5,
        padding: 5,
    },
    offlineText: {
        textAlign: 'center',
        color: Color.cloudy_blue,
        fontSize: Font.size.font15,
    },
});
