import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Modal, Text, TouchableOpacity, View, ScrollView } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import FieldInput from '../FieldInput/FieldInput';
import LinkButton from '../LinkButton/LinkButton';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

import s from './styles';

export default class AddMaterialModal extends Component {
    static propTypes = {
        isEdit: PropTypes.bool,
        isOpen: PropTypes.bool,
        itemType: PropTypes.number,
        editItem: PropTypes.object,

        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,
        onDelete: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            fields: [
                {
                    id: moment().unix(),
                    material: '',
                    quantity: '',
                },
            ],
            rentalItems: [
                {
                    id: moment().unix(),
                    ItemsUsed: '',
                    TimeUsed: '',
                    Description: '',
                },
            ],
            subcontractors: [
                {
                    id: moment().unix(),
                    Name: '',
                    Date: moment().format('MM-DD-YYYY'),
                },
            ],
        };
    }

    componentDidUpdate(prevProps) {
        const { editItem, isEdit, isOpen, itemType } = this.props;
        if (isOpen && !isEdit && prevProps.isOpen !== isOpen) {
            this.setState({
                fields: [
                    {
                        id: moment().unix(),
                        material: '',
                        quantity: '',
                    },
                ],
                rentalItems: [
                    {
                        id: moment().unix(),
                        ItemsUsed: '',
                        TimeUsed: '',
                        Description: '',
                    },
                ],
                subcontractors: [
                    {
                        id: moment().unix(),
                        Name: '',
                        Date: moment().format('MM-DD-YYYY'),
                    },
                ],
            });
        }
        if (isOpen && prevProps.isOpen !== isOpen && isEdit) {
            if (itemType === 2) {
                this.setState({
                    rentalItems: [editItem],
                });
            } else if (itemType === 3) {
                this.setState({
                    subcontractors: [editItem],
                });
            } else {
                this.setState({
                    fields: [editItem],
                });
            }
        }
    }

    onSubmit = () => {
        const { onSubmit, isEdit, itemType } = this.props;
        const { fields, rentalItems, subcontractors } = this.state;
        if (itemType === 1) {
            const fieldsToSave = [];
            fields.forEach((item) => {
                if (!isEmpty(item.material) && !isEmpty(item.quantity)) {
                    fieldsToSave.push(item);
                }
            });
            onSubmit(fieldsToSave, isEdit);
        } else if (itemType === 2) {
            onSubmit(rentalItems, isEdit);
        } else {
            onSubmit(subcontractors, isEdit);
        }
        this.setState({
            fields: [
                {
                    id: moment().unix(),
                    material: '',
                    quantity: '',
                },
            ],
            rentalItems: [
                {
                    id: moment().unix(),
                    ItemsUsed: '',
                    TimeUsed: '',
                    Description: '',
                },
            ],
            subcontractors: [
                {
                    id: moment().unix(),
                    Name: '',
                    Date: moment().format('MM-DD-YYYY'),
                },
            ],
        });
    };

    onCancel = () => {
        const { onCancel } = this.props;
        this.setState({
            fields: [
                {
                    id: moment().unix(),
                    material: '',
                    quantity: '',
                },
            ],
            rentalItems: [
                {
                    id: moment().unix(),
                    ItemsUsed: '',
                    TimeUsed: '',
                    Description: '',
                },
            ],
            subcontractors: [
                {
                    id: moment().unix(),
                    Name: '',
                    Date: moment().format('MM-DD-YYYY'),
                },
            ],
        });
        onCancel();
    };

    onDelete = () => {
        const { onDelete } = this.props;
        onDelete();
    };

    editText(id, type, val) {
        const { fields: newFields } = this.state;
        newFields.forEach((f, i) => {
            if (f.id === id) {
                if (type === 1) {
                    newFields[i].material = val;
                } else {
                    newFields[i].quantity = val;
                }
            }
        });
        this.setState({ fields: newFields });
    }

    editSubcontractor(id, val) {
        const { subcontractors: newFields } = this.state;
        newFields.forEach((f, i) => {
            if (i === id) {
                newFields[i].Name = val;
            }
        });
        this.setState({ subcontractors: newFields });
    }

    removeItem = (id) => {
        const { fields } = this.state;
        this.setState({ fields: fields.filter((a) => a.id !== id) });
    };

    removeRentalItem = (id) => {
        const { rentalItems } = this.state;
        this.setState({ rentalItems: rentalItems.filter((a) => a.id !== id) });
    };

    removeSubcontractor = (id) => {
        const { subcontractors } = this.state;
        this.setState({ subcontractors: subcontractors.filter((a) => a.id !== id) });
    };

    addItem = () => {
        const { fields } = this.state;
        fields.push({
            id: moment().unix(),
            material: '',
            quantity: '',
        });
        this.setState({ fields });
    };

    addRentalItem = () => {
        const { rentalItems } = this.state;
        rentalItems.push({
            id: moment().unix(),
            ItemsUsed: '',
            TimeUsed: '',
            Description: '',
        });
        this.setState({ rentalItems });
    };

    addSubcontractor = () => {
        const { subcontractors } = this.state;
        subcontractors.push({
            id: moment().unix(),
            Name: '',
            Date: moment().format('MM-DD-YYYY'),
        });
        this.setState({ subcontractors });
    };

    editRentalItem(id, type, val) {
        const { rentalItems } = this.state;
        rentalItems.forEach((f, i) => {
            if (f.id === id) {
                switch (type) {
                    case 1:
                        rentalItems[i].ItemsUsed = val;
                        break;
                    case 2:
                        rentalItems[i].TimeUsed = val;
                        break;
                    case 3:
                        rentalItems[i].Description = val;
                        break;
                    default:
                        break;
                }
            }
        });
        this.setState({ rentalItems });
    }

    returnItems = () => {
        const { isEdit } = this.props;
        const { fields } = this.state;
        return fields.map((item, i) => {
            return (
                <View style={s.materialItem} key={item.id}>
                    <View style={[s.materialItemFields, isEdit && s.materialItemFieldsEdit]} key={item.id}>
                        <FieldInput
                            placeholder="MATERIAL"
                            sm
                            style={s.materialDropdown}
                            input={{
                                onChange: (text) => {
                                    this.editText(item.id, 1, text);
                                },
                            }}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            initialValues={fields[i].material}
                        />
                        <FieldInput
                            placeholder="QUANTITY"
                            sm
                            style={s.materialDropdown}
                            input={{
                                onChange: (text) => {
                                    this.editText(item.id, 2, text);
                                },
                            }}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            initialValues={fields[i].quantity}
                        />
                    </View>
                    {!isEdit && (
                        <TouchableOpacity onPress={() => this.removeItem(item.id)} style={s.deleteButton}>
                            <Icon name="minus" style={s.deleteIcon} />
                        </TouchableOpacity>
                    )}
                </View>
            );
        });
    };

    _returnRentalItems = () => {
        const { isEdit } = this.props;
        const { rentalItems } = this.state;
        return rentalItems.map((f, i) => {
            return (
                <View style={s.materialItem} key={f.id}>
                    <View style={[s.materialItemFields, isEdit && s.materialItemFieldsEdit]} key={f.id}>
                        <FieldInput
                            placeholder="ITEMS USED"
                            sm
                            input={{
                                onChange: (text) => {
                                    this.editRentalItem(f.id, 1, text);
                                },
                            }}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            initialValues={rentalItems[i].ItemsUsed}
                            style={s.materialDropdown}
                        />
                        <FieldInput
                            placeholder="TIME USED"
                            sm
                            input={{
                                onChange: (text) => {
                                    this.editRentalItem(f.id, 2, text);
                                },
                            }}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            initialValues={rentalItems[i].TimeUsed}
                            style={s.materialDropdown}
                        />
                        <FieldInput
                            placeholder="DESCRIPTION"
                            multiline
                            sm
                            input={{
                                onChange: (text) => {
                                    this.editRentalItem(f.id, 3, text);
                                },
                            }}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            initialValues={rentalItems[i].Description}
                            style={[s.materialDropdown, s.materialDropdown_multiline]}
                        />
                    </View>
                    {!isEdit && (
                        <TouchableOpacity onPress={() => this.removeRentalItem(f.id)} style={s.deleteButton}>
                            <Icon name="minus" style={s.deleteIcon} />
                        </TouchableOpacity>
                    )}
                </View>
            );
        });
    };

    _returnSubcontractor = () => {
        const { isEdit } = this.props;
        const { subcontractors } = this.state;
        return subcontractors.map((item, i) => {
            return (
                <View style={s.materialItem} key={i}>
                    <View style={[s.materialItemFields, isEdit && s.materialItemFieldsEdit]} key={i}>
                        <FieldInput
                            placeholder="SUBCONTRACTOR NAME"
                            sm
                            style={s.materialDropdown}
                            input={{
                                onChange: (text) => {
                                    this.editSubcontractor(i, text);
                                },
                            }}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            initialValues={subcontractors[i].Name}
                        />
                    </View>
                    {!isEdit && (
                        <TouchableOpacity onPress={() => this.removeSubcontractor(item.id)} style={s.deleteButton}>
                            <Icon name="minus" style={s.deleteIcon} />
                        </TouchableOpacity>
                    )}
                </View>
            );
        });
    };

    render() {
        const { isOpen, isEdit, itemType } = this.props;

        const title = itemType === 1 ? 'MATERIAL AND QUANTITY' : itemType === 2 ? 'RENTAL ITEM' : 'SUBCONTRACTOR';
        const modalClassNames = [
            s.modalBlock,
            isEdit && s.editModalBlock,
            itemType === 2 && s.editBlock_long,
            itemType === 3 && s.editBlock_subcontractors,
            itemType === 2 && isEdit && s.editBlock_short,
            itemType === 3 && isEdit && s.editBlock_subcontractors_edit,
        ];
        let items = null;
        if (isOpen) {
            switch (itemType) {
                case 1:
                    items = this.returnItems();
                    break;
                case 2:
                    items = this._returnRentalItems();
                    break;
                case 3:
                    items = this._returnSubcontractor();
                    break;
                default:
                    break;
            }
        }
        return (
            <Modal visible={isOpen} animationType="fade" transparent presentationStyle="overFullScreen">
                <View style={s.modalWrapper}>
                    <View style={modalClassNames}>
                        <ScrollView style={{ width: '100%', height: '100%' }} contentContainerStyle={s.scrollContainer}>
                            <Text style={s.formTitle}>{title}</Text>
                            {!isEmpty(items) && items}
                            {!isEdit && (
                                <TouchableOpacity
                                    style={s.materialItemAdd}
                                    onPress={
                                        itemType === 1
                                            ? this.addItem
                                            : itemType === 2
                                                ? this.addRentalItem
                                                : this.addSubcontractor
                                    }
                                >
                                    <Icon name="plus" style={s.materialItemAddIcon} />
                                    <Text style={s.materialItemAddText}>ADD NEW</Text>
                                </TouchableOpacity>
                            )}
                            <View style={[s.modalButtons, isEdit && s.modalButtonsEdit]}>
                                <View style={[s.cancelModalButton, isEdit && s.editButtons]}>
                                    <LinkButton title="CANCEL" onPress={this.onCancel} />
                                </View>
                                {isEdit && (
                                    <View style={[s.submitModalButton, isEdit && s.editButtons]}>
                                        <PrimaryButton md breakButton title="DELETE" onPress={this.onDelete} />
                                    </View>
                                )}
                                <View style={[s.submitModalButton, isEdit && s.editButtons]}>
                                    <PrimaryButton md title="SAVE" onPress={this.onSubmit} />
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </View>
            </Modal>
        );
    }
}
