import { cloneDeep } from 'lodash';
import CNST from '../constants';

const initialState = {
    screen: 'dashboard',
};

export function changeScreen(screen) {
    return (dispatch) => {
        dispatch({
            type: CNST.HOW_TO.SET_SCREEN,
            screen,
        });
    };
}

export default function howToReducer(state = cloneDeep(initialState), action) {
    switch (action.type) {
        case CNST.HOW_TO.SET_SCREEN:
            return {
                ...state,
                ...{
                    screen: action.screen,
                },
            };
        default:
            return {
                state,
            };
    }
}
