export default {
    GET_CUSTOMERS: 'GET_CUSTOMERS',
    CREATE_CUSTOM_REPORT: 'CREATE_CUSTOM_REPORT',
    GET_CUSTOM_REPORTS: 'GET_CUSTOM_REPORTS',
    GET_COMPLETED_CUSTOM_REPORTS: 'GET_COMPLETED_CUSTOM_REPORTS',
    SET_CUSTOM_REPORT: 'SET_CUSTOM_REPORT',
    SUBMIT_CUSTOM_REPORT: {
        LOADING: 'SUBMIT_CUSTOM_REPORT',
        SUCCESS: 'SUBMIT_CUSTOM_REPORT_SUCCESS',
        ERROR: 'SUBMIT_CUSTOM_REPORT_ERROR',
    },
    ATTACHMENT_UPLOAD: {
        START: 'ATTACHMENT_UPLOAD_START',
        SUCCESS: 'ATTACHMENT_UPLOAD_SUCCESS',
        ERROR: 'ATTACHMENT_UPLOAD_ERROR',
    },
};
