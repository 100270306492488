import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        paddingTop: 23,
        height: '100%',
        width: '100%',
        alignItems: 'center',
    },
    dropdownContainer: {
        backgroundColor: Color.white,
        borderRadius: 6,
        paddingHorizontal: 19,
        height: 50,
        width: '90%',
        marginBottom: 10,
    },
    dropdownLabel: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
    },
    dropdownLabel_item: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font15,
    },
    topLabel: {
        fontFamily: Font.type.regular,
        color: Color.light_grey_blue,
        fontSize: Font.size.font9,
        marginBottom: -16,
        marginTop: 7,
    },
    touchableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: 50,
        alignItems: 'center',
    },
    textInputWithPlaceholder: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font11,
        backgroundColor: Color.white,
        borderRadius: 5,
        textAlignVertical: 'top',
        width: '90%',
        height: 50,
        paddingTop: 20.5,
        paddingLeft: 14.5,
        marginBottom: 9,
    },

    buttonText: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.white,
    },

    buttonTextCancel: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.light_navy,
    },
});
