class Queue {
    constructor(max) {
        this.queue = [];
        this.running = false;
        this.max = max || 10;
        this.success = null;
    }

    set setOnComplete(func) {
        this.onComplete = func;
    }

    push(task) {
        return new Promise((resolve, reject) => {
            this.queue.push({ taskDef: task, resolve, reject });
            if (!this.running) {
                this.running = true;
                this.run();
            }
        });
    }

    run() {
        const tasks = this.queue.splice(0, this.max);
        Promise.all(
            tasks.map((task) => {
                const { taskDef, resolve, reject } = task;
                const { instance, func, args } = taskDef;

                if (instance) {
                    return instance[func](...args).then((success) => {
                        if (this.success === null || this.success) {
                            this.success = success;
                        }

                        resolve();
                    }, reject);
                }

                return func(...args).then((success) => {
                    if (this.success === null || this.success) {
                        this.success = success;
                    }

                    resolve();
                }, reject);
            }),
        ).then(() => this.next());
    }

    next() {
        if (this.queue.length) {
            this.run();
        } else {
            this.running = false;
            if (this.onComplete) {
                this.onComplete(this.success);
            }

            this.success = null;
        }
    }
}

export default Queue;
