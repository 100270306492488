import React, { PureComponent } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { bool } from 'prop-types';

import s from './styles';

export default class Spinner extends PureComponent {
    static propTypes = {
        small: bool,
    };

    render() {
        const { small } = this.props;
        return (
            <View style={s.waitContainer}>
                <ActivityIndicator size={small ? 'small' : 'large'} color="#AAAAAA" />
            </View>
        );
    }
}
