import React, { PureComponent } from 'react';
import { Text, TouchableOpacity, ActivityIndicator } from 'react-native';
import { bool, string, func, any } from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import s from './styles';

export default class PrimaryButton extends PureComponent {
    static propTypes = {
        isLoading: bool,
        title: string,
        onPress: func,
        icon: any,
        attention: bool,
        outline: bool,
        breakButton: bool,
        breakButtonFull: bool,
        white: bool,
        saveForLaterButton: bool,
        md: bool,
        red: bool,
        disabled: bool,
        orange: bool,
    };

    render() {
        const {
            title,
            onPress,
            isLoading,
            icon,
            outline,
            attention,
            breakButton,
            breakButtonFull,
            white,
            md,
            disabled,
            saveForLaterButton,
            red,
            orange,
        } = this.props;
        return (
            <TouchableOpacity
                style={[
                    s.primaryButtonForm,
                    outline && s.primaryButtonForm_outline,
                    attention && s.primaryButtonForm_attention,
                    breakButton && s.primaryButtonForm_outline_break,
                    breakButtonFull && s.primaryButtonForm_break,
                    saveForLaterButton && s.primaryButtonForm_SaveForLaterButton,
                    white && s.primaryButtonForm_white,
                    md && s.primaryButtonForm_md,
                    red && s.primaryButtonForm_red,
                    orange && s.primaryButtonForm_orange,
                    (disabled || isLoading) && s.primaryButtonForm_disabled,
                ]}
                onPress={onPress}
                disabled={disabled || isLoading}
            >
                <Text
                    style={[
                        s.primaryButtonTextForm,
                        outline && s.primaryButtonTextForm_outline,
                        breakButton && s.primaryButtonTextForm_break,
                        md && s.primaryButtonTextForm_md,
                        white && s.primaryButtonTextForm_white,
                    ]}
                >
                    {title}
                </Text>
                {isLoading ? (
                    <ActivityIndicator size="small" color="#fff" style={s.indicator} />
                ) : (
                    !!icon && (
                        <Icon
                            name={icon}
                            size={20}
                            style={[
                                s.primaryButtonIcon,
                                outline && s.primaryButtonIcon_outline,
                                breakButton && s.primaryButtonIcon_break,
                                white && s.primaryButtonIcon_white,
                            ]}
                        />
                    )
                )}
            </TouchableOpacity>
        );
    }
}
