import { StyleSheet } from 'react-native';
import { Font } from '../../theme';
import Common from '../../styles';

export default StyleSheet.create({
    wrapper: {
        ...Common.wrapperForm,
    },
    primaryButton: {
        ...Common.primaryButtonForm,
    },
    primaryButtonText: {
        ...Common.primaryButtonTextForm,
    },
    accText: {
        ...Common.accTextForm,
    },
    title: {
        fontSize: Font.size.font21,
        alignSelf: 'flex-start',
        fontWeight: '300',
    },
});
