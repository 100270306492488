import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SectionList, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { withNavigationFocus } from 'react-navigation';
import {
    addItems,
    addRentalItems,
    addSubcontractor,
    deleteItem,
    deleteRentalItem,
    deleteSubcontractor,
    editMaterialItem,
    editRentalItem,
    editSubcontractor,
} from '../../../store/modules/timeAndMaterial';

import { AddMaterialModal, NavigationBar } from '../../../components/index';
import ListItem from './ListItem/index';

import s from './styles';
import { Color } from '../../../theme';

class TimeAndMaterialItemScreen extends Component {
    static propTypes = {
        currentTimeAndMaterial: PropTypes.object,
        user: PropTypes.object,
        addItems: PropTypes.func,
        addRentalItems: PropTypes.func,
        addSubcontractor: PropTypes.func,
        deleteItem: PropTypes.func,
        deleteRentalItem: PropTypes.func,
        deleteSubcontractor: PropTypes.func,
        editMaterialItem: PropTypes.func,
        editRentalItem: PropTypes.func,
        editSubcontractor: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            editItem: null,
            itemType: 1,
        };
    }

    _returnItems() {
        const { currentTimeAndMaterial } = this.props;
        let items = [];
        if (!isEmpty(currentTimeAndMaterial) && !isEmpty(currentTimeAndMaterial.ItemsUsed)) {
            items = currentTimeAndMaterial.ItemsUsed.map((item, i) => {
                return {
                    id: `${i}`,
                    title: item.ItemsUsed,
                    quantity: item.AmountUsed,
                    type: 1,
                    onPress: () => this.setState({ isModalOpen: true, editItem: item, itemType: 1 }),
                };
            });
        }
        return items;
    }

    _returnRentalItems() {
        const { currentTimeAndMaterial } = this.props;
        let items = [];
        if (!isEmpty(currentTimeAndMaterial) && !isEmpty(currentTimeAndMaterial.RentalItems)) {
            items = currentTimeAndMaterial.RentalItems.map((item, i) => {
                return {
                    id: `${i}`,
                    title: item.ItemsUsed,
                    timeUsed: item.TimeUsed,
                    type: 2,
                    onPress: () => this.setState({ isModalOpen: true, editItem: item, itemType: 2 }),
                };
            });
        }
        return items;
    }

    _returnSubcontractors() {
        const { currentTimeAndMaterial } = this.props;
        let items = [];
        if (!isEmpty(currentTimeAndMaterial) && !isEmpty(currentTimeAndMaterial.Subcontractor)) {
            items = currentTimeAndMaterial.Subcontractor.map((item, i) => {
                return {
                    id: `${i}`,
                    title: item.Name,
                    date: item.Date,
                    type: 3,
                    onPress: () => this.setState({
                        isModalOpen: true,
                        editItem: item,
                        itemType: 3,
                        subcontractorId: i,
                    }),
                };
            });
        }
        return items;
    }

    addItems(items, editItem) {
        const {
            addItems,
            editMaterialItem,
            currentTimeAndMaterial,
            user,
            addRentalItems,
            editRentalItem,
            editSubcontractor,
            addSubcontractor,
        } = this.props;
        const { itemType, subcontractorId } = this.state;
        if (itemType === 1) {
            if (editItem) {
                editMaterialItem({ item: items[0], currentTimeAndMaterial, user });
            } else {
                addItems({ items, currentTimeAndMaterial, user });
            }
        } else if (itemType === 2) {
            if (editItem) {
                editRentalItem({ item: items[0], currentTimeAndMaterial, user });
            } else {
                addRentalItems({ items, currentTimeAndMaterial, user });
            }
        } else if (itemType === 3) {
            if (editItem) {
                editSubcontractor({ item: items[0], subcontractorId, currentTimeAndMaterial, user });
            } else {
                addSubcontractor({ items, currentTimeAndMaterial, user });
            }
        }
        this.setState({ isModalOpen: false, editItem: null, subcontractorId: null });
    }

    deleteItem(item) {
        const { deleteItem, currentTimeAndMaterial, user, deleteRentalItem, deleteSubcontractor } = this.props;
        const { itemType } = this.state;
        if (itemType === 1) {
            deleteItem({ item, currentTimeAndMaterial, user });
        } else if (itemType === 2) {
            deleteRentalItem({ item, currentTimeAndMaterial, user });
        } else if (itemType === 3) {
            deleteSubcontractor({ item, currentTimeAndMaterial, user });
        }
        this.setState({ isModalOpen: false, editItem: null });
    }

    render() {
        const { isModalOpen, editItem, itemType } = this.state;
        const { currentTimeAndMaterial } = this.props;
        const items = [
            {
                title: 'Items used',
                data: this._returnItems(),
                button: currentTimeAndMaterial.Complete.Complete === false && (
                    <View style={s.bottomButtonsBlock}>
                        <TouchableOpacity
                            style={s.addButton}
                            onPress={() => this.setState({ isModalOpen: true, itemType: 1 })}
                        >
                            <Icon name="plus" color={Color.white} size={30} />
                        </TouchableOpacity>
                    </View>
                ),
            },
            {
                title: 'Rental items',
                data: this._returnRentalItems(),
                button: currentTimeAndMaterial.Complete.Complete === false && (
                    <View style={s.bottomButtonsBlock}>
                        <TouchableOpacity
                            style={s.addButton}
                            onPress={() => this.setState({ isModalOpen: true, itemType: 2 })}
                        >
                            <Icon name="plus" color={Color.white} size={30} />
                        </TouchableOpacity>
                    </View>
                ),
            },
            {
                title: 'Subcontractors',
                data: this._returnSubcontractors(),
                button: currentTimeAndMaterial.Complete.Complete === false && (
                    <View style={s.bottomButtonsBlock}>
                        <TouchableOpacity
                            style={s.addButton}
                            onPress={() => this.setState({ isModalOpen: true, itemType: 3 })}
                        >
                            <Icon name="plus" color={Color.white} size={30} />
                        </TouchableOpacity>
                    </View>
                ),
            },
        ];

        return (
            <View style={[s.wrapper]}>
                <NavigationBar {...this.props} title={currentTimeAndMaterial.Title} backIcon />
                <View style={[s.mainContainer]}>
                    <SectionList
                        sections={items}
                        keyExtractor={(item) => item.id}
                        style={{ width: '100%' }}
                        contentContainerStyle={{ width: '100%', padding: 20, paddingBottom: 120 }}
                        renderSectionHeader={({ section: { title } }) => <Text style={s.listTitle}>{title}</Text>}
                        renderSectionFooter={({ section: { button } }) => button}
                        renderItem={({ item, i }) => {
                            return <ListItem key={i} item={item} i={i} isLast={item.id === items.length - 1} />;
                        }}
                    />
                </View>

                {currentTimeAndMaterial.Complete.Complete === false && (
                    <AddMaterialModal
                        isOpen={isModalOpen}
                        itemType={itemType}
                        isEdit={!!editItem}
                        editItem={editItem}
                        onSubmit={(items) => this.addItems(items, editItem)}
                        onDelete={() => this.deleteItem(editItem)}
                        onCancel={() => {
                            this.setState({ isModalOpen: false, editItem: false });
                        }}
                    />
                )}
            </View>
        );
    }
}

const mapStateToProps = ({ timeAndMaterials, account }) => ({
    user: account.user,
    currentTimeAndMaterial: timeAndMaterials.currentTimeAndMaterial,
    isLoading: timeAndMaterials.isLoading,
    costCodes: account.costCodes,
    projects: account.projects,
});

export default withNavigationFocus(
    connect(mapStateToProps, {
        addItems,
        editMaterialItem,
        deleteItem,
        deleteRentalItem,
        deleteSubcontractor,
        addRentalItems,
        editRentalItem,
        editSubcontractor,
        addSubcontractor,
    })(TimeAndMaterialItemScreen),
);
