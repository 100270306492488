import moment from 'moment';
import React, { Component } from 'react';
import { View, Text, ScrollView, TouchableOpacity, Image } from 'react-native';
import Svg, { Circle, Rect } from 'react-native-svg';
import { isEmpty, cloneDeep, isNil } from 'lodash';
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import PropTypes from 'prop-types';
import { showMessage } from 'react-native-flash-message';
import {
    NavigationBar,
    FieldInput,
    YesNoAnswer,
    DropdownCalendar,
    DropdownList,
    PrimaryButton,
    FileUploadModal,
} from '../../../components/index';

import ModalShapeSelection from './components/ModalShapeSelection';

import { saveCutSheetItem } from '../../../store/modules/cutSheets';
import { Color } from '../../../theme/index';
import s from './styles';

import { generateId } from '../../../utils/helpers';
import { isUploadingOverdue } from '../../../store/helpers/common';

import Shape1 from '../../../images/shapes/shape_1.svg';
import Shape2 from '../../../images/shapes/shape_2.svg';
import Shape3 from '../../../images/shapes/shape_3.svg';
import Shape4 from '../../../images/shapes/shape_4.svg';
import Shape5 from '../../../images/shapes/shape_5.svg';
import Shape6 from '../../../images/shapes/shape_6.svg';
import Shape7 from '../../../images/shapes/shape_7.svg';
import Shape8 from '../../../images/shapes/shape_8.svg';
import Shape9 from '../../../images/shapes/shape_9.svg';
import Shape10 from '../../../images/shapes/shape_10.svg';
import Shape11 from '../../../images/shapes/shape_11.svg';
import Shape12 from '../../../images/shapes/shape_12.svg';
import Shape13 from '../../../images/shapes/shape_13.svg';
import Shape14 from '../../../images/shapes/shape_14.svg';
import Shape15 from '../../../images/shapes/shape_15.svg';
import Shape16 from '../../../images/shapes/shape_16.svg';
import Shape17 from '../../../images/shapes/shape_17.svg';

const shapes = [
    {
        name: 'shape_1',
        svg: Shape1,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCAzNy41IDM3LjUiICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHBhdGgKICAgICAgICBzdHJva2U9IiMxYTNmOTgiCiAgICAgICAgc3Ryb2tlV2lkdGg9IjEuNTI1OSIKICAgICAgICBmaWxsPSJ0cmFuc3BhcmVudCIKICAgICAgICBkPSJNMC44LDAuOGgzNnYzNkg3Ljl2LTcuMUgwLjhMMC44LDAuOHoiCiAgICAvPgo8L3N2Zz4K',
    },
    {
        name: 'shape_2',
        svg: Shape2,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCAzOCAzNS43IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHBhdGgKICAgICAgICBzdHJva2U9IiMxYTNmOTgiCiAgICAgICAgc3Ryb2tlV2lkdGg9IjEuNTI1OSIKICAgICAgICBmaWxsPSJ0cmFuc3BhcmVudCIKICAgICAgICBkPSJNNS4xLDI4LjFsLTQuMy00LjN2MEwyMy4zLDEuMUwzNy4zLDE1djE5LjlINS4xVjI4LjF6IgogICAgLz4KPC9zdmc+Cg==',
    },
    {
        name: 'shape_3',
        svg: Shape3,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCAzNy41IDM3LjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aAogICAgICAgIHN0cm9rZT0iIzFhM2Y5OCIKICAgICAgICBzdHJva2VXaWR0aD0iMS41MjU5IgogICAgICAgIGZpbGw9InRyYW5zcGFyZW50IgogICAgICAgIGQ9Ik0wLjgsMC44TDAuOCwwLjhjMTkuOSwwLDM2LDE2LjEsMzYsMzZINy45di03LjFIMC44QzAuOCwyOS42LDAuOCwwLjgsMC44LDAuOHoiCiAgICAvPgo8L3N2Zz4K',
    },
    {
        name: 'shape_4',
        svg: Shape4,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iNDciIGhlaWdodD0iNDQiIHZpZXdCb3g9IjAgMCA0NC4xIDQzLjciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aAogICAgICAgIHN0cm9rZT0iIzFhM2Y5OCIKICAgICAgICBzdHJva2VXaWR0aD0iMS41MjU5IgogICAgICAgIGZpbGw9InRyYW5zcGFyZW50IgogICAgICAgIGQ9Ik0xMS4yLDMwLjRjLTEuMy0xLTIuOS0xLjUtNC41LTEuNWgwVjAuOGgwYzkuNSwwLDE4LDMuNywyNC40LDkuN0wxMS4yLDMwLjR6IE0zMy41LDEyLjljNiw2LjQsOS43LDE1LDkuNywyNC40SDE1LjFjMC0xLjYtMC41LTMuMi0xLjUtNC41QzEzLjYsMzIuOCwzMy41LDEyLjksMzMuNSwxMi45eiBNMy4zLDFIMC44djI4aDIuNSBNMTUuMSw0MC40djIuNWgyOC4ydi0yLjUiCiAgICAvPgo8L3N2Zz4K',
    },
    {
        name: 'shape_5',
        svg: Shape5,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iNDYiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCA0NS41IDMyLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aAogICAgICAgIHN0cm9rZT0iIzFhM2Y5OCIKICAgICAgICBzdHJva2VXaWR0aD0iMS41MjU5IgogICAgICAgIGZpbGw9InRyYW5zcGFyZW50IgogICAgICAgIGQ9Ik00NC44LDI0LjNoLTcuMXY3LjFINy45di03LjFIMC44VjAuOGg0NHYxOS43TDQ0LjgsMjQuM3oiCiAgICAvPgo8L3N2Zz4K',
    },
    {
        name: 'shape_6',
        svg: Shape6,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCAzNCAzNS42IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHBhdGgKICAgICAgICBzdHJva2U9IiMxYTNmOTgiCiAgICAgICAgc3Ryb2tlV2lkdGg9IjEuNTI1OSIKICAgICAgICBmaWxsPSJ0cmFuc3BhcmVudCIKICAgICAgICBkPSJNMC44LDM0LjhoMjAuNWMwLDAtMC4zLTMuMSwwLjgtNi40YzEtMy4yLDMuNy02LjYsNC42LTcuOGMwLjktMS4yLDMuNS01LjYsNC45LTkuOWMxLjYtNSwxLjYtOS45LDEuNi05LjlIMTIuN2MwLDAtMC4xLDIuMy0wLjksNC43QzExLjEsNy43LDkuOSwxMCw5LjIsMTFjLTAuNywxLjEtNC45LDUuOS02LjksMTEuNkMwLjQsMjguMiwwLjgsMzQuOCwwLjgsMzQuOHoiCiAgICAvPgo8L3N2Zz4K',
    },
    {
        name: 'shape_7',
        svg: Shape7,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0OS43IDM2LjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aAogICAgICAgIHN0cm9rZT0iIzFhM2Y5OCIKICAgICAgICBzdHJva2VXaWR0aD0iMS41MjU5IgogICAgICAgIGZpbGw9InRyYW5zcGFyZW50IgogICAgICAgIGQ9Ik00MC43LDAuOUgyMi4zYzAsMC0wLjEsMy4zLTEuOCw2LjNjLTEuNCwyLjYtMy43LDQuMy01LjksNS4zYy0yLjIsMS03LjksNC41LTExLjEsMTAuMWMtMy4yLDUuNi0yLjgsMTMuMS0yLjgsMTMuMWgyOC42YzAsMCwwLjItNi41LDMuNC0xMWMxLjUtMi4xLDQuOS03LDYuNC0xMi4xQzQxLDYuOCw0MC43LDAuOSw0MC43LDAuOXogTTQzLDAuN2g2LjcgTTQ2LjEsMTYuNlYxLjNNNDMsMzUuOGg2LjcgTTQ2LjEsMjAuOHYxNC41IgogICAgLz4KPC9zdmc+Cg==',
    },
    {
        name: 'shape_8',
        svg: Shape8,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iNTEiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA1MC4zIDM2LjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aAogICAgICAgIHN0cm9rZT0iIzFhM2Y5OCIKICAgICAgICBzdHJva2VXaWR0aD0iMS41MjU5IgogICAgICAgIGZpbGw9InRyYW5zcGFyZW50IgogICAgICAgIGQ9Ik05LjMsMC45aDE4LjVjMCwwLDAuMSwzLjMsMS44LDYuNGMxLjUsMi43LDMuNyw0LjMsNS45LDUuNGMyLjIsMSw3LjksNC41LDExLjIsMTAuMmMzLjIsNS42LDIuOCwxMy4yLDIuOCwxMy4ySDIwLjdjMCwwLTAuMi02LjUtMy40LTExYy0xLjUtMi4xLTUtNy4xLTYuNS0xMi4xQzksNi45LDkuMywwLjksOS4zLDAuOXogTTAsMC44aDYuOCBNMy41LDE2LjdWMS4zIE0wLDM2aDYuOCBNMy41LDIxdjE0LjYiCiAgICAvPgo8L3N2Zz4K',
    },
    {
        name: 'shape_9',
        svg: Shape9,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0NCAzNS40IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHBhdGgKICAgICAgICBzdHJva2U9IiMxYTNmOTgiCiAgICAgICAgc3Ryb2tlV2lkdGg9IjEuNTI1OSIKICAgICAgICBmaWxsPSJ0cmFuc3BhcmVudCIKICAgICAgICBkPSJNMSwwLjhoMjAuNWwxMi43LDMzLjhIMTMuOEwxLDAuOHogTTI3LjEsMC43aDE3IE00MC41LDE1LjdWMC45IE0zNy42LDM0LjdINDQgTTQwLjYsMTkuNHYxNC44IgogICAgLz4KPC9zdmc+Cg==',
    },
    {
        name: 'shape_10',
        svg: Shape10,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iNDIiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MSAzNi43IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHBhdGgKICAgICAgICBzdHJva2U9IiMxYTNmOTgiCiAgICAgICAgc3Ryb2tlV2lkdGg9IjEuNTI1OSIKICAgICAgICBmaWxsPSJ0cmFuc3BhcmVudCIKICAgICAgICBkPSJNMzEuNCwwLjhIMTEuMUwxLDM1LjloMzAuNFYwLjh6IE0zNC4zLDAuN0g0MSBNMzcuNiwxNi4zVjEgTTM0LjMsMzUuOUg0MSBNMzcuNywyMC42djE1LjMiCiAgICAvPgo8L3N2Zz4K',
    },
    {
        name: 'shape_11',
        svg: Shape11,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iNDIiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MC41IDM2LjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aAogICAgICAgIHN0cm9rZT0iIzFhM2Y5OCIKICAgICAgICBzdHJva2VXaWR0aD0iMS41MjU5IgogICAgICAgIGZpbGw9InRyYW5zcGFyZW50IgogICAgICAgIGQ9Ik05LjYsMC44aDE5LjlsMTAsMzQuNUg5LjZWMC44eiBNMCwwLjdoNi42IE0yLjksMTYuMVYxIE0wLDM1LjNoNi42IE0zLjEsMjAuMnYxNS4xIgogICAgLz4KPC9zdmc+Cg==',
    },
    {
        name: 'shape_12',
        svg: Shape12,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0NC45IDM2LjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aAogICAgICAgIHN0cm9rZT0iIzFhM2Y5OCIKICAgICAgICBzdHJva2VXaWR0aD0iMS41MjU5IgogICAgICAgIGZpbGw9InRyYW5zcGFyZW50IgogICAgICAgIGQ9Ik0xNS44LDAuOGgyMUw0NCwzNS42SDguN0wxNS44LDAuOHogTTAsMC43aDEwLjYgTTMuMywxNS44VjEgTTAsMzUuNmg2IE0zLjQsMjF2MTUuMiIKICAgIC8+Cjwvc3ZnPgo=',
    },
    {
        name: 'shape_13',
        svg: Shape13,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iNTkiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA1OSAzNi40IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHBhdGgKICAgICAgICBzdHJva2U9IiMxYTNmOTgiCiAgICAgICAgc3Ryb2tlV2lkdGg9IjEuNTI1OSIKICAgICAgICBmaWxsPSJ0cmFuc3BhcmVudCIKICAgICAgICBkPSJNMSwxaDE2bDIwLjgsMzQuN0gxMS40TDEsMXogTTI4LjcsMC43SDU5IE00My4zLDE2LjFWMSBNMzkuNiwzNS42aDYuNyBNNDMuNCwyMC4zdjE1LjIiCiAgICAvPgo8L3N2Zz4K',
    },
    {
        name: 'shape_14',
        svg: Shape14,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA1OC41IDM3IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHBhdGgKICAgICAgICBzdHJva2U9IiMxYTNmOTgiCiAgICAgICAgc3Ryb2tlV2lkdGg9IjEuNTI1OSIKICAgICAgICBmaWxsPSJ0cmFuc3BhcmVudCIKICAgICAgICBkPSJNNTcuNSwxSDQxLjNMMjAuMSwzNi4yaDI2LjhDNDYuOSwzNi4yLDU3LjUsMSw1Ny41LDF6IE0wLDAuOGgzMC44IE0xNC45LDE2LjRWMSBNMTAuOCwzNi4yaDYuOCBNMTUsMjAuN3YxNS40IgogICAgLz4KPC9zdmc+Cg==',
    },
    {
        name: 'shape_15',
        svg: Shape15,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iNTQiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCA1Mi41IDI2LjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aAogICAgICAgIHN0cm9rZT0iIzFhM2Y5OCIKICAgICAgICBzdHJva2VXaWR0aD0iMS41MjU5IgogICAgICAgIGZpbGw9InRyYW5zcGFyZW50IgogICAgICAgIGQ9Ik00Mi4yLDRoMTAuMyBNNDcuMiwxMy40VjQuMiBNNDIuMiwyNS4yaDEwLjMgTTQ3LjMsMTcuNXY4LjMgTTMuNywwLjhoMzMuNnYyNUgxNS43TDMuNyw0LjZWMC44eiBNMCw0aDQuMU0zNi42LDRoMy43IgogICAgLz4KPC9zdmc+Cg==',
    },
    {
        name: 'shape_16',
        svg: Shape16,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iNTMiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA1MSAyNi43IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHBhdGgKICAgICAgICBzdHJva2U9IiMxYTNmOTgiCiAgICAgICAgc3Ryb2tlV2lkdGg9IjEuNTI1OSIKICAgICAgICBmaWxsPSJ0cmFuc3BhcmVudCIKICAgICAgICBkPSJNMCwzLjloMTAuNCBNNSwxMy41VjQuMiBNMCwyNi4xaDEwLjQgTTUuMiwxNy42VjI2IE0xNi40LDAuN2gzMC4zVjI2SDE2LjRWMC43eiBNMTIuMSwzLjloNC41IE00Ni4zLDMuOUg1MSIKICAgIC8+Cjwvc3ZnPgo=',
    },
    {
        name: 'shape_17',
        svg: Shape17,
        base64:
            /* eslint-disable-next-line max-len */
            'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0Ny4yIDM3LjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aAogICAgICAgIHN0cm9rZT0iIzFhM2Y5OCIKICAgICAgICBzdHJva2VXaWR0aD0iMS41MjU5IgogICAgICAgIGZpbGw9InRyYW5zcGFyZW50IgogICAgICAgIGQ9Ik04LjMsMC45aDIxLjFsMTMuNCwzNS45SDAuOUw4LjMsMC45eiBNNDYuMywxNC40VjAgTTQ2LjQsMjN2MTQuNCIKICAgIC8+Cjwvc3ZnPgo=',
    },
];

const typeList = [
    {
        id: 'Duct',
        title: 'Duct',
    },
    {
        id: 'Flex',
        title: 'Flex',
    },
    {
        id: 'EC',
        title: 'EC',
    },
    {
        id: 'PC',
        title: 'PC',
    },
    {
        id: 'ANG',
        title: 'ANG',
    },
];

const ductMaterialList = [
    {
        id: 'Galvanized',
        title: 'Galvanized',
    },
    {
        id: 'Paintlock',
        title: 'Paintlock',
    },
    {
        id: 'Aluminum',
        title: 'Aluminum',
    },
    {
        id: 'Stainless Steel',
        title: 'Stainless Steel',
    },
    {
        id: 'Black Steel',
        title: 'Black Steel',
    },
];

const ductPressureClassList = [
    {
        id: '1',
        title: '1',
    },
    {
        id: '2',
        title: '2',
    },
    {
        id: '3',
        title: '3',
    },
    {
        id: '4',
        title: '4',
    },
];

const linerList = [
    {
        id: '0',
        title: '0',
    },
    {
        id: '0.5',
        title: '0.5',
    },
    {
        id: '1',
        title: '1',
    },
    {
        id: '1.5',
        title: '1.5',
    },
    {
        id: '2',
        title: '2',
    },
];

const spiralSizeList = [
    {
        id: '4',
        title: '4',
    },
    {
        id: '6',
        title: '6',
    },
    {
        id: '8',
        title: '8',
    },
    {
        id: '10',
        title: '10',
    },
    {
        id: '12',
        title: '12',
    },
    {
        id: '14',
        title: '14',
    },
    {
        id: '16',
        title: '16',
    },
    {
        id: '20',
        title: '20',
    },
];

const couplersAndDuraflanges = [
    {
        id: 'Couplers',
        title: 'Couplers',
    },
    {
        id: 'Duraflanges',
        title: 'Duraflanges',
    },
];

class ItemFabricationScreen extends Component {
    constructor(props) {
        super(props);
        const { currentCutSheetItem } = this.props;
        const cutSheet = cloneDeep(currentCutSheetItem);

        let cutSheetList;
        let typeOptionList;
        let spiralOptionList;
        let customOptions;

        if (cutSheet) {
            cutSheetList = cutSheet.CutSheetList;
            typeOptionList = cutSheet.TypeOptionList;
            spiralOptionList = cutSheet.SpiralOptionList;
            customOptions = cutSheet.CustomOptions;
        } else {
            cutSheetList = [
                {
                    ShapeName: null,
                    Shape: null,
                    Tag: null,
                    Quantity: null,
                    Width: null,
                    Height: null,
                    Length: null,
                    Liner: null,
                },
            ];
            typeOptionList = [
                {
                    Type: null,
                    Tag: null,
                    Quantity: null,
                    Width: null,
                    Height: null,
                    Length: null,
                    End1: null,
                    End2: null,
                    Drive: null,
                    Liner: null,
                },
            ];
            spiralOptionList = [
                {
                    Size: null,
                    Quantity: null,
                    Diameter: null,
                    Length: null,
                    Feet: null,
                    Inches: null,
                    Tag: null,
                    CouplersAndDuraflanges: {
                        Quantity: null,
                        Size: null,
                        Type: null,
                    },
                },
            ];
            customOptions = [];
        }

        this.state = {
            isCustomShapeUploadModalOpen: false,
            isModalOpen: false,
            selectedShapeName: null,
            selectedIndex: 0,
            dateNeeded: cutSheet ? cutSheet.DateNeeded : null,
            ticketName: cutSheet ? cutSheet.TicketName : null,
            note: cutSheet ? cutSheet.Note : null,
            system: cutSheet ? cutSheet.System : null,
            wc: cutSheet ? cutSheet.WC : null,
            ductPressureClass: cutSheet ? cutSheet.DuctPressureClass : null,
            ductMaterial: cutSheet ? cutSheet.DuctMaterial : null,
            plasticWrap: cutSheet ? cutSheet.PlasticWrap : false,
            cutSheetList,
            typeOptionList,
            spiralOptionList,
            customOptions,
        };
    }

    static propTypes = {
        saveCutSheetItem: PropTypes.func,
        user: PropTypes.object,
        navigation: PropTypes.object,
        currentCutSheetItem: PropTypes.func,
        currentTimeCard: PropTypes.object,
    };

    isAssetUploading = (asset, name) => {
        if (asset) {
            const { id, uploaded, uploading, uploadingStartTime } = asset;
            const uploadingOverdue = isUploadingOverdue(uploadingStartTime);

            if (id && !isNil(uploaded) && !uploaded && uploading && !uploadingOverdue) {
                showMessage({
                    message: `This ${name} is currently uploading. Please wait.`,
                    backgroundColor: Color.faded_orange,
                    color: Color.dark_navy_blue,
                    duration: 10000,
                    animationDuration: 0,
                    hideOnPress: true,
                    hideStatusBar: true,
                });

                return true;
            }
        }
        return false;
    };

    addShapeOption() {
        const { cutSheetList } = this.state;
        const newCutSheetList = cloneDeep(cutSheetList);
        newCutSheetList.push({
            ShapeName: null,
            Shape: null,
            Tag: null,
            Quantity: null,
            Width: null,
            Height: null,
            Length: null,
            Liner: null,
        });
        this.setState({
            cutSheetList: newCutSheetList,
        });
    }

    removeShapeOption = (i) => {
        const { cutSheetList } = this.state;

        if (!this.isAssetUploading(cutSheetList[i].Shape, 'shape')) {
            const newCutSheetList = cloneDeep(cutSheetList);
            newCutSheetList.splice(i, 1);
            this.setState({ cutSheetList: newCutSheetList });
        }
    };

    addTypeOption() {
        const { typeOptionList } = this.state;
        const newTypeOptionList = cloneDeep(typeOptionList);
        newTypeOptionList.push({
            Type: null,
            Tag: null,
            Quantity: null,
            Width: null,
            Height: null,
            Length: null,
            End1: null,
            End2: null,
            Drive: null,
            Liner: null,
        });
        this.setState({
            typeOptionList: newTypeOptionList,
        });
    }

    removeTypeOption = (i) => {
        const { typeOptionList } = this.state;
        const newTypeOptionList = cloneDeep(typeOptionList);
        newTypeOptionList.splice(i, 1);
        this.setState({ typeOptionList: newTypeOptionList });
    };

    addSpiralOption() {
        const { spiralOptionList } = this.state;
        const newSpiralOptionList = cloneDeep(spiralOptionList);
        newSpiralOptionList.push({
            Size: null,
            Quantity: null,
            Diameter: null,
            Length: null,
            Feet: null,
            Inches: null,
            Tag: null,
            CouplersAndDuraflanges: {
                Quantity: null,
                Size: null,
                Type: null,
            },
        });
        this.setState({
            spiralOptionList: newSpiralOptionList,
        });
    }

    removeSpiralOption = (i) => {
        const { spiralOptionList } = this.state;
        const newSpiralOptionList = cloneDeep(spiralOptionList);
        newSpiralOptionList.splice(i, 1);
        this.setState({ spiralOptionList: newSpiralOptionList });
    };

    addCustomOption(items) {
        const { customOptions } = this.state;
        const newCustomOptions = cloneDeep(customOptions);

        let i = 1;
        const currentLength = newCustomOptions.length;
        for (const item of items) {
            const data = {
                id: generateId(),
                uploading: false,
                uploaded: false,
                name: `${moment().format('DD_MM_YYYY')}_custom_shape_${currentLength + i}`,
                type: item.type,
                fileName: `${moment().format('DD_MM_YYYY')}_custom_shape_${currentLength + i}.jpg`,
                types: ['shape image'],
                subtypes: [],
                tags: [
                    {
                        value: 'shape image',
                        protected: true,
                        category: 'type',
                    },
                ],
                file: item.base64,
                path: item.uri,
            };

            newCustomOptions.push({
                Shape: data,
            });

            i++;
        }

        this.setState({
            isCustomShapeUploadModalOpen: false,
            customOptions: newCustomOptions,
        });
    }

    removeCustomOption(customOption) {
        if (this.isAssetUploading(customOption.Shape, 'custom option')) {
            return false;
        }

        const { customOptions } = this.state;
        let newCustomOptions = cloneDeep(customOptions);

        if (typeof customOption.Shape !== 'string' && customOption.Shape.id) {
            newCustomOptions = newCustomOptions.filter((opt) => opt.Shape.id !== customOption.Shape.id);
        } else {
            newCustomOptions = newCustomOptions.filter((opt) => opt.Shape !== customOption.Shape);
        }

        this.setState({
            customOptions: newCustomOptions,
        });

        return true;
    }

    handleShapeSelectionPress = (shapeOptionIndex, cutSheet) => {
        // check if shape image is uploading
        if (!this.isAssetUploading(cutSheet.Shape, 'shape')) {
            this.setState({
                isModalOpen: true,
                selectedIndex: shapeOptionIndex,
                selectedShapeName: cutSheet.ShapeName,
            });
        }
    };

    handleShapeSelection = (shape) => {
        const { cutSheetList, selectedIndex } = this.state;
        const sheetList = cloneDeep(cutSheetList);
        const shapeOption = {
            ...cutSheetList[selectedIndex],
            ShapeName: shape.name,
            Shape: {
                id: generateId(),
                uploading: false,
                uploaded: false,
                name: `${moment().format('DD_MM_YYYY')}_shape_${selectedIndex + 1}`,
                type: 'image/svg+xml',
                fileName: `${moment().format('DD_MM_YYYY')}_shape_${selectedIndex + 1}.svg`,
                types: ['shape image'],
                subtypes: [],
                tags: [
                    {
                        value: 'shape image',
                        protected: true,
                        category: 'type',
                    },
                ],
                file: shape.base64,
            },
        };

        sheetList.splice(selectedIndex, 1, shapeOption);
        this.setState({ isModalOpen: false, selectedIndex: 0, cutSheetList: sheetList });
    };

    handleShapeSelectionClose = () => {
        this.setState({ isModalOpen: false });
    };

    save = () => {
        const { saveCutSheetItem, user, navigation, currentCutSheetItem, currentTimeCard } = this.props;
        const {
            dateNeeded,
            system,
            wc,
            ductPressureClass,
            ductMaterial,
            cutSheetList,
            typeOptionList,
            spiralOptionList,
            customOptions,
            plasticWrap,
            ticketName,
            note,
        } = this.state;
        const cutSheet = {
            id: currentCutSheetItem ? currentCutSheetItem.id : null,
            TicketName: ticketName,
            Job: currentTimeCard.currentProject ? currentTimeCard.currentProject.id : null,
            Detailler: user ? user._id : null,
            System: system,
            Notes: note,
            PlasticWrap: plasticWrap,
            DateNeeded: dateNeeded,
            DuctPressureClass: ductPressureClass,
            DuctMaterial: ductMaterial,
            WC: wc,
            CutSheetList: cutSheetList,
            TypeOptionList: typeOptionList,
            SpiralOptionList: spiralOptionList,
            CustomOptions: customOptions,
        };

        saveCutSheetItem(cutSheet, user);
        navigation.navigate('ItemFabricationList');
    };

    _renderShapeOptions() {
        const { cutSheetList } = this.state;
        return (
            <View>
                <View>
                    <Text style={s.blockTitle}>SHAPE OPTION</Text>
                </View>
                {!isEmpty(cutSheetList) &&
                    cutSheetList.map((c, i) => {
                        let SelectedShapeSVG = null;
                        if (c.ShapeName) {
                            const selectedShape = shapes.find((shape) => shape.name === c.ShapeName);
                            if (selectedShape) {
                                SelectedShapeSVG = selectedShape.svg;
                            }
                        }

                        return (
                            <View style={s.listItem} key={i}>
                                <View style={s.listItemLeft}>
                                    <TouchableOpacity
                                        style={[s.fieldInternalStyle, s.touchField]}
                                        onPress={() => {
                                            this.handleShapeSelectionPress(i, c);
                                        }}
                                    >
                                        {SelectedShapeSVG ? (
                                            <View style={s.shapeOutline}>
                                                <SelectedShapeSVG />
                                            </View>
                                        ) : (
                                            <Text style={s.label}>SELECT SHAPE</Text>
                                        )}
                                        <View style={s.shapeOutline}>
                                            <Svg height="48" width="48" viewBox="0 0 100 100">
                                                <Rect
                                                    x="23"
                                                    y="16"
                                                    width="40"
                                                    height="40"
                                                    stroke={Color.turquoise}
                                                    strokeWidth="4"
                                                    fill={Color.white}
                                                />
                                                <Circle
                                                    cx="30"
                                                    cy="50"
                                                    r="20"
                                                    stroke={Color.turquoise}
                                                    strokeWidth="4"
                                                    fill={Color.white}
                                                />
                                            </Svg>
                                        </View>
                                    </TouchableOpacity>
                                    <View style={s.row}>
                                        <FieldInput
                                            title="TAG"
                                            style={[s.fieldInt, s.field50]}
                                            input={{
                                                onChange: (text) => {
                                                    const cutSheets = cutSheetList.slice();
                                                    cutSheets[i].Tag = text;
                                                    this.setState({ cutSheetList: cutSheets });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.Tag}
                                        />
                                        <FieldInput
                                            title="QNTY"
                                            style={[s.fieldInt, s.field50]}
                                            input={{
                                                onChange: (text) => {
                                                    const cutSheets = cutSheetList.slice();
                                                    cutSheets[i].Quantity = text;
                                                    this.setState({ cutSheetList: cutSheets });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.Quantity}
                                        />
                                    </View>
                                    <View style={s.row}>
                                        <FieldInput
                                            title="W"
                                            style={[s.fieldInt, s.field30]}
                                            input={{
                                                onChange: (text) => {
                                                    const cutSheets = cutSheetList.slice();
                                                    cutSheets[i].Width = text;
                                                    this.setState({ cutSheetList: cutSheets });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.Width}
                                        />
                                        <FieldInput
                                            title="H"
                                            style={[s.fieldInt, s.field30]}
                                            input={{
                                                onChange: (text) => {
                                                    const cutSheets = cutSheetList.slice();
                                                    cutSheets[i].Height = text;
                                                    this.setState({ cutSheetList: cutSheets });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.Height}
                                        />
                                        <FieldInput
                                            title="L"
                                            style={[s.fieldInt, s.field30]}
                                            input={{
                                                onChange: (text) => {
                                                    const cutSheets = cutSheetList.slice();
                                                    cutSheets[i].Length = text;
                                                    this.setState({ cutSheetList: cutSheets });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.Length}
                                        />
                                    </View>
                                    <DropdownList
                                        title="LINER2"
                                        items={linerList}
                                        dropStyle={s.dropdownInternal}
                                        selectedItem={
                                            cutSheetList[i].Liner
                                                ? linerList.find((l) => l.title === cutSheetList[i].Liner)
                                                : null
                                        }
                                        onItemSelect={(item) => {
                                            const cutSheets = cutSheetList.slice();
                                            cutSheets[i].Liner = item.title;
                                            this.setState({ cutSheetList: cutSheets });
                                        }}
                                        withIds
                                    />
                                </View>
                                <View style={s.listItemRight}>
                                    <TouchableOpacity
                                        onPress={() => this.removeShapeOption(i)}
                                        style={s.removeButtonContainer}
                                    >
                                        <View style={s.removeButton}>
                                            <Icon name="minus" size={20} color={Color.pinkish_orange} />
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        );
                    })}

                <TouchableOpacity onPress={() => this.addShapeOption()}>
                    <View style={s.buttonCancel}>
                        <Text style={s.buttonTextCancel}>
                            <Icon name="plus" size={15} />
                            ADD NEW
                        </Text>
                    </View>
                </TouchableOpacity>
            </View>
        );
    }

    _renderTypeOptions() {
        const { typeOptionList } = this.state;
        return (
            <View>
                <View>
                    <Text style={s.blockTitle}>TYPE OPTION</Text>
                </View>
                {!isEmpty(typeOptionList) &&
                    typeOptionList.map((c, i) => {
                        return (
                            <View style={s.listItem} key={i}>
                                <View style={s.listItemLeft}>
                                    <DropdownList
                                        title="SELECT TYPE"
                                        items={typeList}
                                        dropStyle={s.dropdownInternal}
                                        selectedItem={
                                            typeOptionList[i].Type
                                                ? typeList.find((l) => l.title === typeOptionList[i].Type)
                                                : null
                                        }
                                        onItemSelect={(item) => {
                                            const cutSheets = typeOptionList.slice();
                                            typeOptionList[i].Type = item.title;
                                            this.setState({ typeOptionList: cutSheets });
                                        }}
                                        withIds
                                    />
                                    <View style={s.row}>
                                        <FieldInput
                                            title="TAG"
                                            style={[s.fieldInt, s.field50]}
                                            input={{
                                                onChange: (text) => {
                                                    const typeOption = typeOptionList.slice();
                                                    typeOption[i].Tag = text;
                                                    this.setState({ typeOptionList: typeOption });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.Tag}
                                        />
                                        <FieldInput
                                            title="QNTY"
                                            style={[s.fieldInt, s.field50]}
                                            input={{
                                                onChange: (text) => {
                                                    const typeOption = typeOptionList.slice();
                                                    typeOption[i].Quantity = text;
                                                    this.setState({ typeOptionList: typeOption });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.Quantity}
                                        />
                                    </View>
                                    <View style={s.row}>
                                        <FieldInput
                                            title="W"
                                            style={[s.fieldInt, s.field30]}
                                            input={{
                                                onChange: (text) => {
                                                    const typeOption = typeOptionList.slice();
                                                    typeOption[i].Width = text;
                                                    this.setState({ typeOptionList: typeOption });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.Width}
                                        />
                                        <FieldInput
                                            title="H"
                                            style={[s.fieldInt, s.field30]}
                                            input={{
                                                onChange: (text) => {
                                                    const typeOption = typeOptionList.slice();
                                                    typeOption[i].Height = text;
                                                    this.setState({ typeOptionList: typeOption });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.Height}
                                        />
                                        <FieldInput
                                            title="L"
                                            style={[s.fieldInt, s.field30]}
                                            input={{
                                                onChange: (text) => {
                                                    const typeOption = typeOptionList.slice();
                                                    typeOption[i].Length = text;
                                                    this.setState({ typeOptionList: typeOption });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.Length}
                                        />
                                    </View>
                                    <View style={s.row}>
                                        <FieldInput
                                            title="END 1"
                                            style={[s.fieldInt, s.field50]}
                                            input={{
                                                onChange: (text) => {
                                                    const typeOption = typeOptionList.slice();
                                                    typeOption[i].End1 = text;
                                                    this.setState({ typeOptionList: typeOption });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.End1}
                                        />
                                        <FieldInput
                                            title="END 2"
                                            style={[s.fieldInt, s.field50]}
                                            input={{
                                                onChange: (text) => {
                                                    const typeOption = typeOptionList.slice();
                                                    typeOption[i].End2 = text;
                                                    this.setState({ typeOptionList: typeOption });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.End2}
                                        />
                                    </View>
                                    <FieldInput
                                        title="DRIVE"
                                        style={s.fieldInt}
                                        input={{
                                            onChange: (text) => {
                                                const typeOption = typeOptionList.slice();
                                                typeOption[i].Drive = text;
                                                this.setState({ typeOptionList: typeOption });
                                            },
                                        }}
                                        meta={{
                                            error: null,
                                            touched: false,
                                        }}
                                        initialValues={c.Drive}
                                    />
                                    <DropdownList
                                        title="LINER"
                                        items={linerList}
                                        dropStyle={s.dropdownInternal}
                                        selectedItem={
                                            typeOptionList[i].Liner
                                                ? linerList.find((l) => l.title === typeOptionList[i].Liner)
                                                : null
                                        }
                                        onItemSelect={(item) => {
                                            const typeOption = typeOptionList.slice();
                                            typeOption[i].Liner = item.title;
                                            this.setState({ typeOptionList: typeOption });
                                        }}
                                        withIds
                                    />
                                </View>
                                <View style={s.listItemRight}>
                                    <TouchableOpacity
                                        onPress={() => this.removeTypeOption(i)}
                                        style={s.removeButtonContainer}
                                    >
                                        <View style={s.removeButton}>
                                            <Icon name="minus" size={20} color={Color.pinkish_orange} />
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        );
                    })}

                <TouchableOpacity onPress={() => this.addTypeOption()}>
                    <View style={s.buttonCancel}>
                        <Text style={s.buttonTextCancel}>
                            <Icon name="plus" size={15} />
                            ADD NEW
                        </Text>
                    </View>
                </TouchableOpacity>
            </View>
        );
    }

    _renderCAD(c, i) {
        const { spiralOptionList } = this.state;
        return (
            <View>
                <View>
                    <Text style={s.blockTitle}>COUPLERS AND DURAFLANGES</Text>
                </View>
                <View key={i}>
                    <View style={s.row}>
                        <FieldInput
                            title="QNTY"
                            style={[s.fieldInt, s.field50]}
                            input={{
                                onChange: (text) => {
                                    const cadOption = spiralOptionList.slice();
                                    cadOption[i].CouplersAndDuraflanges.Quantity = text;
                                    this.setState({ spiralOptionList: cadOption });
                                },
                            }}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            initialValues={c.CouplersAndDuraflanges.Quantity}
                        />
                        <FieldInput
                            title="SIZE"
                            style={[s.fieldInt, s.field50]}
                            input={{
                                onChange: (text) => {
                                    const cadOption = spiralOptionList.slice();
                                    cadOption[i].CouplersAndDuraflanges.Size = text;
                                    this.setState({ spiralOptionList: cadOption });
                                },
                            }}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            initialValues={c.CouplersAndDuraflanges.Size}
                        />
                    </View>
                    <DropdownList
                        title="SELECT TYPE"
                        items={couplersAndDuraflanges}
                        dropStyle={s.dropdownInternal}
                        selectedItem={
                            c.CouplersAndDuraflanges.Type
                                ? couplersAndDuraflanges.find((l) => l.title === c.CouplersAndDuraflanges.Type)
                                : null
                        }
                        onItemSelect={(item) => {
                            const cadOption = spiralOptionList.slice();
                            cadOption[i].CouplersAndDuraflanges.Type = item.title;
                            this.setState({ spiralOptionList: cadOption });
                        }}
                        withIds
                    />
                </View>
            </View>
        );
    }

    _renderSpiral() {
        const { spiralOptionList } = this.state;
        return (
            <View>
                <View>
                    <Text style={s.blockTitle}>SPIRAL DUCTWORK</Text>
                </View>
                {!isEmpty(spiralOptionList) &&
                    spiralOptionList.map((c, i) => {
                        return (
                            <View style={s.listItem} key={i}>
                                <View style={s.listItemLeft}>
                                    <DropdownList
                                        title="SELECT SIZE"
                                        items={spiralSizeList}
                                        dropStyle={s.dropdownInternal}
                                        selectedItem={
                                            spiralOptionList[i].Size
                                                ? spiralSizeList.find((l) => l.title === spiralOptionList[i].Size)
                                                : null
                                        }
                                        onItemSelect={(item) => {
                                            const spiral = spiralOptionList.slice();
                                            spiral[i].Size = item.title;
                                            this.setState({ spiralOptionList: spiral });
                                        }}
                                        withIds
                                    />
                                    <View style={s.row}>
                                        <FieldInput
                                            title="QNTY"
                                            style={[s.fieldInt, s.field50]}
                                            input={{
                                                onChange: (text) => {
                                                    const spiralOption = spiralOptionList.slice();
                                                    spiralOption[i].Quantity = text;
                                                    this.setState({ spiralOptionList: spiralOption });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.Quantity}
                                        />
                                        <FieldInput
                                            title="DIAMETER"
                                            style={[s.fieldInt, s.field50]}
                                            input={{
                                                onChange: (text) => {
                                                    const spiralOption = spiralOptionList.slice();
                                                    spiralOption[i].Diameter = text;
                                                    this.setState({ spiralOptionList: spiralOption });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.Diameter}
                                        />
                                    </View>
                                    <View style={s.row}>
                                        <FieldInput
                                            title="LENGTH"
                                            style={[s.fieldInt, s.field30]}
                                            input={{
                                                onChange: (text) => {
                                                    const spiralOption = spiralOptionList.slice();
                                                    spiralOption[i].Length = text;
                                                    this.setState({ spiralOptionList: spiralOption });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.Length}
                                        />
                                        <FieldInput
                                            title="FEET"
                                            style={[s.fieldInt, s.field30]}
                                            input={{
                                                onChange: (text) => {
                                                    const spiralOption = spiralOptionList.slice();
                                                    spiralOption[i].Feet = text;
                                                    this.setState({ spiralOptionList: spiralOption });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.Feet}
                                        />
                                        <FieldInput
                                            title="INCHES"
                                            style={[s.fieldInt, s.field30]}
                                            input={{
                                                onChange: (text) => {
                                                    const spiralOption = spiralOptionList.slice();
                                                    spiralOption[i].Inches = text;
                                                    this.setState({ spiralOptionList: spiralOption });
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={c.Inches}
                                        />
                                    </View>
                                    <FieldInput
                                        title="TAG"
                                        style={s.fieldInt}
                                        input={{
                                            onChange: (text) => {
                                                const spiralOption = spiralOptionList.slice();
                                                spiralOption[i].Tag = text;
                                                this.setState({ spiralOptionList: spiralOption });
                                            },
                                        }}
                                        meta={{
                                            error: null,
                                            touched: false,
                                        }}
                                        initialValues={c.Tag}
                                    />
                                    <View style={s.listItemInternalWrapper}>{this._renderCAD(c, i)}</View>
                                </View>
                                <View style={s.listItemRight}>
                                    <TouchableOpacity
                                        onPress={() => this.removeSpiralOption(i)}
                                        style={s.removeButtonContainer}
                                    >
                                        <View style={s.removeButton}>
                                            <Icon name="minus" size={20} color={Color.pinkish_orange} />
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        );
                    })}

                <TouchableOpacity onPress={() => this.addSpiralOption()}>
                    <View style={s.buttonCancel}>
                        <Text style={s.buttonTextCancel}>
                            <Icon name="plus" size={15} />
                            ADD NEW
                        </Text>
                    </View>
                </TouchableOpacity>
            </View>
        );
    }

    _renderCustomOptions() {
        const { customOptions } = this.state;
        return (
            <View>
                <View>
                    <Text style={s.blockTitle}>CUSTOM OPTION</Text>
                </View>
                <TouchableOpacity onPress={() => this.setState({ isCustomShapeUploadModalOpen: true })}>
                    <View style={s.buttonCancel}>
                        <Text style={s.buttonTextCancel}>
                            <Icon name="plus" size={15} />
                            ADD NEW
                        </Text>
                    </View>
                </TouchableOpacity>
                <View style={s.attachmentsWrapper}>
                    {customOptions.map((opt, index) => (
                        <TouchableOpacity style={s.attachmentItem} key={`custom_shape_${index}`}>
                            <Image
                                source={{ uri: opt.Shape.file ? `data:image/png;base64,${opt.Shape.file}` : opt.Shape }}
                                style={s.attachmentImage}
                            />
                            {opt.Shape.uploading && (
                                <View style={s.uploadingWrapper}>
                                    <Text style={s.uploadingLabel}>uploading...</Text>
                                </View>
                            )}
                            <TouchableOpacity style={s.attachmentButton} onPress={() => this.removeCustomOption(opt)}>
                                <Icon name="close" size={25} color={Color.turquoise} />
                            </TouchableOpacity>
                        </TouchableOpacity>
                    ))}
                </View>
            </View>
        );
    }

    render() {
        const { currentCutSheetItem } = this.props;
        const {
            isModalOpen,
            isCustomShapeUploadModalOpen,
            selectedShapeName,
            plasticWrap,
            ductPressureClass,
            dateNeeded,
            system,
            wc,
            ductMaterial,
            ticketName,
            note,
        } = this.state;

        return (
            <View onScroll={this.handleScroll} style={[s.wrapper]}>
                <NavigationBar
                    {...this.props}
                    title={currentCutSheetItem ? 'EDIT TICKET' : 'ADD NEW TICKET'}
                    backIcon
                />
                <View style={[s.mainContainer]}>
                    <FieldInput
                        title="TICKET NAME"
                        input={{
                            onChange: (text) => {
                                this.setState({ ticketName: text });
                            },
                        }}
                        meta={{
                            error: null,
                            touched: false,
                        }}
                        initialValues={ticketName}
                    />
                    <View style={s.questionRow}>
                        <Text style={s.label}>PLASTIC WRAP</Text>
                        <YesNoAnswer
                            isTwo
                            answer={plasticWrap ? 'Yes' : 'No'}
                            onChange={(value) => this.setState({ plasticWrap: value === 'Yes' })}
                        />
                    </View>
                    <DropdownCalendar
                        containerStyle={[s.calendarDropdown]}
                        date={dateNeeded}
                        onSelect={(date) => this.setState({ dateNeeded: moment(date).toISOString() })}
                        placeholder="DATE NEEDED"
                        topLabel
                        mode="date"
                    />
                    <FieldInput
                        title="SYSTEM"
                        input={{
                            onChange: (text) => {
                                this.setState({ system: text });
                            },
                        }}
                        meta={{
                            error: null,
                            touched: false,
                        }}
                        initialValues={system}
                    />
                    <View style={s.formItem}>
                        <DropdownList
                            title="DUCT MATERIAL"
                            items={ductMaterialList}
                            selectedItem={ductMaterial ? ductMaterialList.find((d) => d.title === ductMaterial) : null}
                            onItemSelect={(item) => {
                                this.setState({ ductMaterial: item.title });
                            }}
                            withIds
                        />
                    </View>
                    <View style={s.formItem}>
                        <DropdownList
                            title="DUCT PRESSURE CLASS"
                            items={ductPressureClassList}
                            selectedItem={
                                ductPressureClass ? ductPressureClassList.find((d) => d.title === ductPressureClass) : null
                            }
                            onItemSelect={(item) => {
                                this.setState({ ductPressureClass: item.title });
                            }}
                            withIds
                        />
                    </View>
                    <FieldInput
                        title="WC"
                        input={{
                            onChange: (text) => {
                                this.setState({ wc: text });
                            },
                        }}
                        meta={{
                            error: null,
                            touched: false,
                        }}
                        initialValues={wc}
                    />
                    <View style={s.listItemWrapper}>{this._renderShapeOptions()}</View>
                    <View style={s.listItemWrapper}>{this._renderTypeOptions()}</View>
                    <View style={s.listItemWrapper}>{this._renderSpiral()}</View>
                    <View style={s.listItemWrapper}>{this._renderCustomOptions()}</View>
                    <FieldInput
                        title="NOTE"
                        input={{
                            onChange: (text) => {
                                this.setState({ note: text });
                            },
                        }}
                        meta={{
                            error: null,
                            touched: false,
                        }}
                        initialValues={note}
                    />
                    <View style={s.buttonWrapper}>
                        <PrimaryButton title="SAVE" onPress={this.save} />
                    </View>
                    <ModalShapeSelection
                        isOpen={isModalOpen}
                        shapes={shapes}
                        selectedShapeName={selectedShapeName}
                        onSelect={this.handleShapeSelection}
                        onClose={this.handleShapeSelectionClose}
                    />
                    <FileUploadModal
                        isVisible={isCustomShapeUploadModalOpen}
                        onCancel={() => this.setState({ isCustomShapeUploadModalOpen: false })}
                        returnItems={(attachments) => this.addCustomOption(attachments)}
                    />
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ account, cutSheets, currentTimeCard }) => ({
    user: account.user,
    currentCutSheetItem: cutSheets.currentCutSheetItem,
    currentTimeCard,
});

export default connect(mapStateToProps, { saveCutSheetItem })(ItemFabricationScreen);
