import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text, View, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Color } from '../../theme';
import s from './styles';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

class DropdownCalendarInput extends Component {
    static propTypes = {
        mode: PropTypes.string,
        value: PropTypes.string,
        date: PropTypes.object,
        placeholder: PropTypes.string,
        containerStyle: PropTypes.array,
        topLabel: PropTypes.bool,
        onClick: PropTypes.func,
        onFocus: PropTypes.func,
        onChange: PropTypes.func,
        inputRef: PropTypes.func,
    };

    render() {
        const { inputRef, date, placeholder, containerStyle, topLabel, mode, onClick, onFocus, onChange, value } =
            this.props;

        const isTopLabel = !!date && topLabel;
        const iconCalendar = 'calendar-month-outline';
        const iconTime = 'clock-time-eight-outline';
        const iconSize = 17;

        const containerStyles = [s.dropdownContainer];
        if (containerStyle) {
            containerStyles.push(...containerStyle);
        }

        return (
            <View style={containerStyles}>
                {isTopLabel && <Text style={s.topLabel}>{placeholder}</Text>}
                <TouchableOpacity onPress={onClick}>
                    <View style={s.touchableRow}>
                        <input
                            className="dropdown-calendar-input"
                            style={s.dropdownLabelInput}
                            placeholder={placeholder}
                            type="text"
                            value={value}
                            ref={inputRef}
                            onFocus={onFocus}
                            onChange={onChange}
                            onClick={onClick}
                        />
                        <Icon name={mode === 'time' ? iconTime : iconCalendar} size={iconSize} color={Color.blue} />
                    </View>
                </TouchableOpacity>
            </View>
        );
    }
}

export default React.forwardRef((props, inputRef) => <DropdownCalendarInput {...props} inputRef={inputRef} />);
