import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
// import logger from 'redux-logger'
import AsyncStorage from '@react-native-async-storage/async-storage';

import makeRootReducer from './reducers';
import { generateId } from '../utils/helpers';

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    whitelist: [
        'account',
        'currentTimeCard',
        'timeCardsReducer',
        'dailyReports',
        'timeAndMaterials',
        'changeOrder',
        'timeOff',
        'timeCardEdit',
        'injuryReporting',
        'accidentReporting',
        'howTo',
        'timeCards',
        'toolboxTalks',
        'customReports',
        'cutSheets',
        'schedule',
        'reimbursements',
    ],
    blacklist: ['form'],
    timeout: null,
    migrate: (state) => {
        if (state && state.timeCards && state.timeCards.unsavedCards) {
            // check unsavedCards
            for (const card of state.timeCards.unsavedCards) {
                if (!card.WorkTimeId && card.AppId) {
                    card.WorkTimeId = card.AppId;
                } else if (!card.WorkTimeId) {
                    card.WorkTimeId = generateId();
                }

                if (!card.unsavedId) {
                    card.unsavedId = generateId();
                }
            }
        }

        if (state && state.timeCards) {
            state.timeCards.timeCardsIsSyncing = false;
        }

        if (state && state.timeCards && !state.timeCards.unsavedSignedPeriods) {
            state.timeCards.unsavedSignedPeriods = [];
        }

        if (state && state.timeCards && state.timeCards.signedPeriodsIsSyncing) {
            state.timeCards.signedPeriodsIsSyncing = false;
        }

        // migrate payPeriod
        if (state && state.account && state.account.payPeriod) {
            const { payPeriod } = state.account;
            state.account.firstPayPeriod = payPeriod;
        }

        if (state && state.account && state.account.setting) {
            state.account.settings = state.account.setting;
            delete state.account.setting;
        }

        // move payPeriods to timeCards
        if (state && state.account && state.account.payPeriods) {
            state.timeCards.payPeriods = state.account.payPeriods;
            state.timeCards.firstPayPeriod = state.account.firstPayPeriod;
            delete state.account.payPeriods;
            delete state.account.firstPayPeriod;
        }

        return Promise.resolve(state);
    },
};

const persistedReducer = persistReducer(persistConfig, makeRootReducer);

const enhancer = compose(
    applyMiddleware(
        thunk,
        // logger
    ),
);

export default function configureStore() {
    const store = createStore(persistedReducer, enhancer);
    const persistor = persistStore(store);

    return { store, persistor };
}
