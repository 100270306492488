import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        width: '100%',
        paddingHorizontal: '5%',
        paddingTop: 20,
        paddingBottom: 75,
    },
    buttonWrapper: {
        width: 180,
    },
    listWrapper: {
        paddingTop: 20,
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    listTitle: {
        fontSize: Font.size.font12,
        fontFamily: Font.type.bold,
        color: Color.light_navy,
        marginVertical: 10,
    },
    loadingModal: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.light_grey_blue_05,
        opacity: 0.5,
    },
});
