import moment from 'moment';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
// import Dash from 'react-native-dash';
import s from './styles';

import { Color } from '../../theme';

export default class DailyReportListItem extends PureComponent {
    static propTypes = {
        item: PropTypes.object,
        i: PropTypes.number,
        isLast: PropTypes.bool,
    };

    _returnTime() {
        const { item } = this.props;
        const time = moment().startOf('day').seconds(item.totalTime);
        const hours = moment(time).hours();
        const minutes = moment(time).minutes();
        let timeText = '';
        if (hours >= 1) {
            timeText += `${hours}h`;
        }
        if (minutes >= 1) {
            timeText += `${minutes}m`;
        }
        return timeText;
    }

    openPDF = () => {
        const { item } = this.props;
        item.openPDF();
    };

    static _returnWeatherIcon(weather) {
        switch (weather) {
            case 'clear-day':
                return 'weather-sunny';
            case 'rain':
                return 'weather-rainy';
            case 'snow':
                return 'weather-snowy-heavy';
            case 'sleet':
                return 'weather-snowy-rainy';
            case 'fog':
                return 'weather-fog';
            case 'cloudy':
                return 'weather-cloudy';
            case 'partly-cloudy-day':
                return 'weather-partly-cloudy';
            default:
                return 'weather-cloudy';
        }
    }

    _showMore() {
        const { item } = this.props;
        if (moment(item.date, 'MM/DD/YYYY').add(1, 'days') > moment()) {
            return (
                <TouchableOpacity onPress={item.onPress} style={s.listItemColumn}>
                    <Text style={s.listItemBottomText}>SHOW MORE</Text>
                    <Icon name="chevron-right" size={28} style={s.chevronRight} />
                </TouchableOpacity>
            );
        }

        return null;
    }

    render() {
        const { item, i, isLast } = this.props;
        return (
            <View
                key={item.id}
                style={[
                    s.listItemWrapper,
                    i === 0 && { marginTop: 15 },
                    isLast && { marginBottom: 130 },
                    item.unsaved && { height: 110 },
                ]}
            >
                {item.unsaved ? (
                    <View style={[s.listItemRow, s.listItemRowUnsaved]}>
                        <Text style={s.listItemUnsaved}>Not Submitted</Text>
                        {item.status && <Text style={s.listItemUnsaved}>{item.status}</Text>}
                    </View>
                ) : null}
                <View style={[s.listItemRow]}>
                    <Text style={s.listItemDate}>{item.date}</Text>
                    {!item.completed ? (
                        <Text style={s.listItemDate}>(Draft)</Text>
                    ) : (
                        <Text style={s.listItemWorkOrderNumber}>
                            {undefined !== item.workOrderNumber
                                ? item.workOrderNumber.length > 10
                                    ? `${item.workOrderNumber.substring(0, 10)}...`
                                    : item.workOrderNumber
                                : item.workOrderNumber}
                        </Text>
                    )}
                    <View style={s.listItemColumn}>
                        <View style={s.listItemColumn} />
                        <View style={[s.listItemColumn, { marginLeft: 22 }]}>
                            <Icon name="clock-outline" size={14} color={Color.grey} />
                            <Text style={[s.listGreyItem, { marginLeft: 5 }]}>{this._returnTime()}</Text>
                        </View>
                        <View style={[s.listItemColumn, { marginLeft: 23.5 }]}>
                            <Text style={s.listGreyItem}>
                                {`${item.weather.minTemp || 'NaN'}/${item.weather.maxTemp || 'NaN'} °F`}
                            </Text>
                            <Icon
                                name={DailyReportListItem._returnWeatherIcon(item.weather.type)}
                                size={14}
                                color={Color.grey}
                                style={{ marginLeft: 5 }}
                            />
                        </View>
                    </View>
                </View>
                <View style={s.listItemRow}>
                    {item.openPDF ? (
                        <TouchableOpacity onPress={() => item.openPDF()} style={[s.listItemColumn]}>
                            <Icon name="file-pdf-box" size={20} color={Color.turquoise} style={{ marginRight: 5 }} />
                            <Text style={s.listItemBottomText}>PDF</Text>
                        </TouchableOpacity>
                    ) : (
                        <View style={[s.listItemColumn]} />
                    )}
                    <Text>
                        {undefined !== item.userName
                            ? item.userName.length > 24
                                ? `${item.userName.substring(0, 18)}...`
                                : item.userName
                            : item.userName}
                    </Text>
                    {this._showMore()}
                </View>
            </View>
        );
    }
}
