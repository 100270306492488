export default {
    GET_CHANGE_ORDER_ITEMS: 'GET_CHANGE_ORDER_ITEMS',
    CREATE_CHANGE_ORDER_ITEM: 'CREATE_CHANGE_ORDER_ITEM',
    SET_CHANGE_ORDER_ITEM: 'SET_CHANGE_ORDER_ITEM',
    ADD_CHANGE_ORDER_ITEM: 'ADD_CHANGE_ORDER_ITEM',
    SAVE_CHANGE_ORDER_ITEM_IMAGE: 'SAVE_CHANGE_ORDER_ITEM_IMAGE',
    REMOVE_CHANGE_ORDER_ITEM_IMAGE: 'REMOVE_CHANGE_ORDER_ITEM_IMAGE',
    SUBMIT_CHANGE_ORDER_ITEM: {
        LOADING: 'SUBMIT_CHANGE_ORDER_ITEM',
        SUCCESS: 'SUBMIT_CHANGE_ORDER_ITEM_SUCCESS',
        ERROR: 'SUBMIT_CHANGE_ORDER_ITEM_ERROR',
    },
    LOADING: {
        START: 'SUBMIT_CHANGE_ORDER_ITEM_START',
        FINISH: 'SUBMIT_CHANGE_ORDER_ITEM_FINISH',
    },
    CLEAN_UP: 'CHANGE_ORDER_CLEAN_UP',
};
