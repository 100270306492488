import React, { Component } from 'react';
import { Text, TextInput, View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import s from './styles';
import { Color } from '../../theme';

export default class FieldInput extends Component {
    static propTypes = {
        input: PropTypes.object,
        title: PropTypes.string,
        meta: PropTypes.object,
        placeholder: PropTypes.string,
        type: PropTypes.string,
        typePassword: PropTypes.bool,
        keyboardType: PropTypes.string,
        autoCorrect: PropTypes.bool,
        autoCapitalize: PropTypes.string,
        style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        inputStyle: PropTypes.object,
        restInput: PropTypes.object,
        initialValues: PropTypes.string,
        multiline: PropTypes.bool,
        sm: PropTypes.bool,
        editable: PropTypes.bool,
        maxLength: PropTypes.number,
        isNumber: PropTypes.bool,
        onEndEditing: PropTypes.func,
    };

    static defaultProps = {
        isNumber: false,
    };

    constructor(props) {
        super(props);
        const { initialValues } = props;
        this.state = {
            text: initialValues || '',
            showPassword: false,
        };
    }

    componentDidMount() {
        const { type } = this.props;
        if (type === 'password') {
            this.setState({ showPassword: true });
        }
    }

    componentDidUpdate(prevProps) {
        const { initialValues, isNumber } = this.props;
        if (initialValues !== prevProps.initialValues && !isNumber) {
            this.setState({ text: initialValues || '' });
        }
    }

    onShowPassword = () => {
        this.setState((prevState) => ({
            passwordDisplayed: !prevState.passwordDisplayed,
        }));
    };

    /**
     *
     * @param {string} val
     * @returns
     */
    onChangeText = (val) => {
        const {
            isNumber,
            input: { onChange },
        } = this.props;

        if (isNumber) {
            if (val === '') {
                this.setState({ text: val });
                return onChange(null);
            }

            let parsedValue = null;
            if (val.indexOf(',') >= 0) {
                val = val.replace(',', '.');
            }

            const valParts = val.split('.');
            if (valParts.length === 2 && valParts[1] === '') {
                return this.setState({ text: val });
            }

            if (valParts.length === 3 && valParts[1] === '' && valParts[2] === '') {
                return this.setState({ text: valParts[0] + '.' });
            }

            parsedValue = parseFloat(val);
            if (!isNaN(parsedValue)) {
                if (val.endsWith('.0')) {
                    this.setState({ text: val });
                } else {
                    this.setState({ text: parsedValue.toString() });
                }
                onChange(parsedValue);
            }
        } else {
            this.setState({ text: val });
            onChange(val);
        }
    };

    onEndEditing = (val) => {
        const { onEndEditing } = this.props;
        if (onEndEditing) onEndEditing(val.nativeEvent.text);
    };

    renderTextInput() {
        const {
            input: { onChange, ...restInput },
            meta: { error, touched },
            type = 'default',
            keyboardType = 'default',
            placeholder,
            autoCorrect = true,
            autoCapitalize = 'sentences',
            multiline = false,
            sm,
            editable = true,
            inputStyle,
            maxLength,
        } = this.props;

        const { text, showPassword } = this.state;

        return (
            <TextInput
                {...restInput}
                style={[
                    s.textInput,
                    multiline && s.textInputMultiline,
                    touched && error && s.textInputError,
                    sm && s.textInputWithPlaceholder,
                    inputStyle && inputStyle,
                ]}
                type={type}
                placeholderTextColor={Color.grey}
                keyboardType={keyboardType}
                onChangeText={this.onChangeText}
                onEndEditing={this.onEndEditing}
                underlineColorAndroid="transparent"
                placeholder={placeholder}
                autoCorrect={autoCorrect}
                multiline={multiline}
                numberOfLines={multiline ? 5 : 1}
                autoCapitalize={autoCapitalize}
                secureTextEntry={showPassword}
                value={text}
                editable={editable}
                maxLength={maxLength || 999999}
            />
        );
    }

    render() {
        const { type = 'default', title, style } = this.props;
        const { showPassword } = this.state;

        const fieldWrapperStyles = [s.fieldWrapper];
        if (style && Array.isArray(style)) {
            fieldWrapperStyles.push(...style);
        } else if (style) {
            fieldWrapperStyles.push(style);
        }

        return (
            <View style={fieldWrapperStyles}>
                {title && (
                    <View>
                        <Text style={s.fieldTitle}>{title}</Text>
                    </View>
                )}
                {type === 'password' ? (
                    <View style={[s.passwordField]}>
                        {this.renderTextInput()}
                        <TouchableOpacity
                            style={[s.showPasswordWrapper]}
                            onPress={() => this.setState({ showPassword: !showPassword })}
                        >
                            <Icon
                                name={showPassword ? 'eye-off' : 'eye'}
                                style={s.showPasswordIcon}
                                size={20}
                                color={showPassword ? Color.grey : Color.blue}
                            />
                        </TouchableOpacity>
                    </View>
                ) : (
                    this.renderTextInput()
                )}
            </View>
        );
    }
}
