import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import { FieldInput } from '../index';
import s from './styles';

export default class MultipleChoseAnswer extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        item: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    _returnOptions() {
        const { onChange, item } = this.props;
        return item.Options.map((option) => {
            const isSelected = item.Value === option.Option;
            return (
                <TouchableOpacity
                    key={option.Option}
                    onPress={() => {
                        onChange(option.Option);
                    }}
                >
                    <View style={[s.optionWrapper, isSelected && s.optionWrapperSelected]}>
                        <View style={[s.circle, isSelected && s.circleSelected]}>
                            {isSelected && <View style={s.innerCircle} />}
                        </View>
                        <Text style={[s.optionLabel]}>{option.Option}</Text>
                    </View>
                </TouchableOpacity>
            );
        });
    }

    render() {
        const { item, onChange } = this.props;
        const selectedValue = item.Options.find((option) => item.Value === option.Option);
        return (
            <View style={[s.container]}>
                <View>{this._returnOptions()}</View>
                <FieldInput
                    title="OTHER"
                    style={s.fieldInt}
                    input={{
                        onChange: (description) => {
                            onChange(description);
                        },
                    }}
                    meta={{
                        error: null,
                        touched: false,
                    }}
                    initialValues={!selectedValue ? item.Value : null}
                />
            </View>
        );
    }
}
