import React, { Component } from 'react';
import { View, Text, FlatList, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showMessage } from 'react-native-flash-message';
import { isEmpty } from 'lodash';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import NetInfo from '@react-native-community/netinfo';

import {
    cutSheetsLoading,
    cutSheetsFinishLoading,
    getCutSheets,
    setCutSheetItem,
    clearCutSheetItem,
} from '../../../store/modules/cutSheets';

import { NavigationBar } from '../../../components/index';
import ListItem from './ListItem';

import s from './styles';
import { Color } from '../../../theme/index';
import { Config } from '../../../config';

class ItemFabricationListScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        cutSheetItems: PropTypes.array,
        currentProject: PropTypes.object,
        isLoading: PropTypes.bool,
        getCutSheets: PropTypes.func,
        cutSheetsLoading: PropTypes.func,
        cutSheetsFinishLoading: PropTypes.func,
        setCutSheetItem: PropTypes.func,
        clearCutSheetItem: PropTypes.func,
    };

    componentDidMount() {
        this.reloadReports();
    }

    reloadReports = () => {
        const { getCutSheets, cutSheetsLoading, cutSheetsFinishLoading } = this.props;
        cutSheetsLoading();
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                getCutSheets().then(() => {
                    cutSheetsFinishLoading();
                });
            } else {
                cutSheetsFinishLoading();
                showMessage({
                    message: 'Connection Failure',
                    description: 'We are unable to connect. Please make sure that you have a data connection.',
                    type: 'danger',
                    duration: 5000,
                    animationDuration: 700,
                    // autoHide: false,
                    hideOnPress: true,
                    hideStatusBar: true,
                    backgroundColor: Color.red,
                });
            }
        });
    };

    _returnItems() {
        const { navigation, cutSheetItems, setCutSheetItem } = this.props;
        let items = [];
        items = cutSheetItems.map((cutSheet, i) => {
            return {
                id: i,
                date: cutSheet.timeCreated,
                title: cutSheet.TicketName,
                onPress: () => {
                    setCutSheetItem(cutSheet);
                    navigation.navigate('ItemFabricationItem');
                },
            };
        });

        return items;
    }

    createNewCutSheet = () => {
        const { navigation, clearCutSheetItem } = this.props;
        clearCutSheetItem();
        navigation.navigate('ItemFabricationItem');
    };

    render() {
        const { currentProject, isLoading } = this.props;
        const items = this._returnItems();
        const projectTitle = Config.USE_DEPARTMENTS_JOBS ? 'department' : 'project'

        return (
            <View style={[s.wrapper]}>
                <NavigationBar {...this.props} title="DASHBOARD" menuIcon searchIcon />
                <View style={s.menuTopContainer}>
                    <Text style={s.topMenu_title}>
                        {isEmpty(currentProject) ? `You didn't clock in in to any ${projectTitle}` : currentProject.ProjectName}
                    </Text>
                </View>
                <View style={[s.mainContainer]}>
                    <FlatList
                        data={items}
                        keyExtractor={(item) => item.id}
                        refreshing={isLoading}
                        style={{ width: '100%', height: '100%', paddingTop: 20, marginBottom: '5%' }}
                        renderItem={({ item, i }) => {
                            return <ListItem item={item} i={i} itemFabrication isLast={item.id === items.length - 1} />;
                        }}
                    />
                    <View style={s.bottomButtonsBlock}>
                        <TouchableOpacity style={s.addButton} onPress={() => this.createNewCutSheet()}>
                            <Icon name="plus" color={Color.white} size={30} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ dailyReports, cutSheets }) => ({
    cutSheetItems: cutSheets.cutSheetItems,
    isLoading: cutSheets.isLoading,
    currentProject: dailyReports.currentProject,
});

export default connect(mapStateToProps, {
    getCutSheets,
    cutSheetsFinishLoading,
    cutSheetsLoading,
    setCutSheetItem,
    clearCutSheetItem,
})(ItemFabricationListScreen);
