import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme';

export default StyleSheet.create({
    listItemWrapper: {
        width: '90%',
        height: 50,
        marginBottom: 10,
        marginLeft: 22.5,
        marginRight: 21.5,
        backgroundColor: Color.white,
        borderRadius: 6,
        elevation: 5,
    },
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        paddingBottom: 125,
        backgroundColor: Color.pale_grey_three,
    },
    mainContainer: {
        paddingTop: 21.5,
        height: '100%',
        width: '100%',
        marginBottom: -100,
        // alignItems: 'center',
    },
    title: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue,
        textAlign: 'left',
        paddingLeft: 18.5,
        marginTop: 14,
    },
    chevronRight: {
        fontSize: Font.size.font24,
        color: Color.light_grey_blue,
        position: 'absolute',
        marginTop: 10,
        marginBottom: -10,
        right: 20,
    },
});
