import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../../theme';

export default StyleSheet.create({
    listItemWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        marginBottom: 20,
        backgroundColor: Color.white,
        borderRadius: 6,
        minHeight: 100,
        width: '95%',
        elevation: 5,
        alignSelf: 'center',
    },
    listItemRow: {
        flexDirection: 'row',
        width: '100%',
    },
    listItemColumn: {
        flexDirection: 'column',
        flexGrow: 1,
        paddingVertical: 5,
    },
    commBox: {
        borderRadius: 50,
        backgroundColor: Color.light_blue_3,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10,
        height: 50,
        width: 50,
    },
    alarm: {
        backgroundColor: Color.orange,
        borderRadius: 20,
        padding: 4,
        position: 'absolute',
        top: -5,
        right: -5,
    },
    logoImg: {
        height: 27,
        marginLeft: -8,
        width: 32,
    },
    listItemTitle: {
        color: '#8b9198',
        fontSize: Font.size.font14,
    },
    listItemCommTitle: {
        color: Color.turquoise,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font16,
    },
    listItemComMessage: {
        marginTop: 10,
    },
    listItemHours: {
        color: '#8b9198',
        fontFamily: Font.type.regular,
        textAlign: 'right',
        paddingRight: 10,
        paddingTop: 5,
    },
});
