import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, ScrollView, Text, TouchableOpacity } from 'react-native';
import Clipboard from '@react-native-community/clipboard';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

// import Dash from 'react-native-dash';
import { NavigationBar } from '../../../components/index';
import { Config } from '../../../config';

import s from './styles';

class ProjectInfoScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentTimeCard: PropTypes.object,
    };

    constructor(props) {
        super(props);
        const { navigation } = this.props;
        this.project = navigation.getParam('project', null);
    }

    _getCurrentProject() {
        const { currentTimeCard } = this.props;
        const { currentProject } = currentTimeCard;
        // the project passed with navigation from standalone projects screen (from side menu)
        if (this.project) {
            return this.project;
        }

        return currentProject;
    }

    _renderTopContainer() {
        const { currentTimeCard } = this.props;
        const { isClockIn } = currentTimeCard;

        if (isClockIn || this.project) {
            return (
                <View style={s.wrapper}>
                    <View style={[s.mainContainer]}>
                        <View style={{ alignItems: 'center' }} />
                    </View>
                </View>
            );
        }
        return null;
    }

    _renderAddress() {
        const currentProject = this._getCurrentProject();
        const { Address } = currentProject;

        if (Address.StreetAddress === '') {
            return <Text style={s.text}>No Info From the Admin</Text>;
        }

        if (Address.Zip && typeof Address.Zip === 'string' && Address.Zip.toLowerCase() === 'null') {
            Address.Zip = '';
        }

        let additionalAddress = '';
        if (Address.City) {
            additionalAddress += Address.City;
            if (Address.State || Address.Zip) {
                additionalAddress += ' |';
                if (Address.State) {
                    additionalAddress += ` ${Address.State.trim()}`;
                }

                if (Address.Zip) {
                    additionalAddress += ` ${Address.Zip}`;
                }
            }
        }

        return (
            <TouchableOpacity
                onPress={() => {
                    Clipboard.setString(`${Address.StreetAddress} ${Address.City} ${Address.State}`);
                }}
            >
                <Text style={s.text}>{Address.StreetAddress}</Text>
                <Text style={s.text}>{additionalAddress}</Text>
            </TouchableOpacity>
        );
    }

    _renderSuperintendent() {
        const currentProject = this._getCurrentProject();
        if (!currentProject.SuperintendentInfo.SuperintendentName) {
            return <Text style={s.text}>No Info From the Admin</Text>;
        }
        return (
            <>
                <Text style={s.text}>{currentProject.SuperintendentInfo.SuperintendentName}</Text>
                <Text style={s.text}>{currentProject.SuperintendentInfo.SuperintendentEmail}</Text>
                <Text style={s.text}>{currentProject.SuperintendentInfo.SuperintendentPhoneNumber}</Text>
            </>
        );
    }

    _renderMainContainer() {
        const { currentTimeCard } = this.props;
        const { isClockIn } = currentTimeCard;
        const currentProject = this._getCurrentProject();
        const projectLabel = Config.USE_DEPARTMENTS_JOBS ? 'DEPARTMENT' : 'PROJECT'

        if (isClockIn || this.project) {
            return (
                <ScrollView style={s.SquareShapeView}>
                    <View style={s.commonKeyProperties}>
                        <Text style={s.title}>{projectLabel} ADDRESS</Text>
                        {this._renderAddress()}
                    </View>
                    <View style={s.commonKeyProperties}>
                        <Text style={s.title}>{projectLabel} INFO</Text>
                        <Text style={s.text}>{currentProject.ProjectName}</Text>
                    </View>
                    <View style={s.commonKeyProperties}>
                        <Text style={s.title}>SUPERINTENDENT INFO</Text>
                        {this._renderSuperintendent()}
                    </View>

                    <View style={s.commonKeyProperties}>
                        <Text style={s.title}>ADDITIONAL NOTES </Text>
                        {!isEmpty(currentProject.AdditionalNotes) ? (
                            currentProject.AdditionalNotes.map((note, i) => {
                                if (!note.NoteLabel) {
                                    return (
                                        <Text key={i} style={s.text}>
                                            No Info From the Admin
                                        </Text>
                                    );
                                }
                                return (
                                    <Text style={s.text} key={i}>
                                        {`${note.NoteLabel}: ${note.Label}`}
                                    </Text>
                                );
                            })
                        ) : (
                            <Text style={s.text}>No Info From the Admin</Text>
                        )}
                    </View>
                </ScrollView>
            );
        }

        return null;
    }

    render() {
        const currentProject = this._getCurrentProject();

        return (
            <View style={[s.wrapper]}>
                {this._renderTopContainer()}
                <NavigationBar {...this.props} title={currentProject.ProjectName} backIcon />
                <View style={[s.mainContainer]}>
                    <View>{this._renderMainContainer()}</View>
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ currentTimeCard }) => ({
    currentTimeCard,
});

export default connect(mapStateToProps)(ProjectInfoScreen);
