import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    listItemWrapper: {
        width: '90%',
        padding: 19,
        paddingTop: 12,
        marginBottom: 10,
        backgroundColor: Color.white,
        borderRadius: 6,
        height: 94,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        elevation: 5,
        alignSelf: 'center',
    },
    listItemRow: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    listItemStatusWrapper: {
        width: '100%',
        height: 5,
        borderRadius: 2.5,
        backgroundColor: Color.pale_grey_two,
        marginTop: 15,
        marginBottom: 12,
    },
    listItemStatusLine: {
        height: '100%',
        borderRadius: 2.5,
        backgroundColor: Color.light_navy,
    },
    listItemBottomText: {
        color: Color.light_grey_blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font12,
        marginTop: 3,
    },
    listItemBottomText_blue: {
        color: Color.light_navy,
    },
    listItemDate: {
        fontSize: Font.size.font12,
        fontFamily: Font.type.regular,
        color: Color.dark_navy_blue,
    },
    iconWrapper: {
        width: 30,
        height: 30,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.light_navy_05,
        marginRight: 20,
    },
    listItemIcon: {
        color: Color.light_navy,
    },
    listItemTitle: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font13,
        color: Color.dark_navy_blue,
        alignSelf: 'flex-start',
    },
    listItemColumn: {
        flexDirection: 'row',
    },
    listItemColumn_timeCard: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    labelRequired: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font12,
        fontFamily: Font.type.regular,
        marginTop: 1,
        marginRight: 15,
    },
    chevronRight: {
        color: Color.light_navy,
        fontSize: Font.size.font24,
    },
    listItemTimeCardHeader: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5,
    },
    cardTimes: {
        fontFamily: Font.type.regular,
        color: Color.light_grey_blue,
        fontSize: Font.size.font10,
    },
    cardTotalTime: {
        fontFamily: Font.type.regular,
        color: Color.light_navy,
        fontSize: Font.size.font11,
    },
});
