import React, { Component } from 'react';
import { View, Text, ScrollView, ActivityIndicator } from 'react-native';
import { NavigationBar, FieldInput, DropdownList } from '../../../components/index';

import s from './styles';
import { FIELD_TYPE } from '../../../config/index';
import BottomGradient from '../../../components/BottomGradient/BottomGradient';

class DailyNewTaskScreen extends Component {
    static propTypes = {};

    state = {
        isReady: false,
        elements: [],
    };

    componentDidMount() {
        this._returnPageElements();
    }

    static _returnItems() {
        return [
            {
                id: 'yfuhcj94c4',
                title: 'Unit Information',
                data: [
                    {
                        id: 'asdaf43-34',
                        type: FIELD_TYPE.TYPE.TEXT_FIELD,
                        size: FIELD_TYPE.SIZE.FULL_WIDTH,
                        placeholder: 'MANUFACTOR',
                    },
                    {
                        id: 'sduirgbf4vt-3',
                        type: FIELD_TYPE.TYPE.TEXT_FIELD,
                        size: FIELD_TYPE.SIZE.FULL_WIDTH,
                        placeholder: 'REFRIGERANT',
                    },
                    {
                        id: 'frgthyhg4f44-33',
                        type: FIELD_TYPE.TYPE.TEXT_FIELD,
                        size: FIELD_TYPE.SIZE.TWO_THIRD_WIDTH,
                        placeholder: 'MODEL#',
                    },
                    {
                        id: 'srthyj66664-33',
                        type: FIELD_TYPE.TYPE.TEXT_FIELD,
                        size: FIELD_TYPE.SIZE.THIRD_WIDTH,
                        placeholder: 'LBS',
                    },
                    {
                        id: 'frgtyhgf45-33',
                        type: FIELD_TYPE.TYPE.TEXT_FIELD,
                        size: FIELD_TYPE.SIZE.FULL_WIDTH,
                        placeholder: 'SERIAL#',
                    },
                    {
                        id: 'f45gfcv5-33',
                        type: FIELD_TYPE.TYPE.TEXT_FIELD,
                        size: FIELD_TYPE.SIZE.FULL_WIDTH,
                        placeholder: 'NAMEPLATE VOLTAGE',
                    },
                    {
                        id: 'd4fgfd4-33',
                        type: FIELD_TYPE.TYPE.TEXT_FIELD,
                        size: FIELD_TYPE.SIZE.FULL_WIDTH,
                        placeholder: 'FACTORY CHARGE',
                    },
                    {
                        id: 'f4fg5dc5-33',
                        type: FIELD_TYPE.TYPE.TEXT_FIELD,
                        size: FIELD_TYPE.SIZE.FULL_WIDTH,
                        placeholder: 'SUPPLY VOLTAGE',
                    },
                    {
                        id: 'dfgtf54-33',
                        type: FIELD_TYPE.TYPE.ITEMS_ARRAY_LIST,
                        size: FIELD_TYPE.SIZE.FULL_WIDTH,
                        placeholder: 'REFRIGERANT ADDED',
                    },
                ],
            },
            {
                id: 'asdnic49mc',
                title: 'Method Used To Determine Charge',
                data: [
                    {
                        id: 'asdaf43-34',
                        type: FIELD_TYPE.TYPE.DROPDOWN_LIST,
                        size: FIELD_TYPE.SIZE.FULL_WIDTH,
                        placeholder: 'WEIGHED IN',
                        variants: ['1', '2', '3', '4'],
                    },
                ],
            },
            {
                id: 'cnijrf9c4f',
                title: 'Condenser Fans',
                data: [
                    {
                        id: 'd44fdc-34',
                        type: FIELD_TYPE.TYPE.TEXT_FIELD,
                        size: FIELD_TYPE.SIZE.FULL_WIDTH,
                        placeholder: 'MANUFACTOR',
                    },
                    {
                        id: 'd34f5fd5fc-34',
                        type: FIELD_TYPE.TYPE.TEXT_FIELD,
                        size: FIELD_TYPE.SIZE.FULL_WIDTH,
                        placeholder: 'MODEL#',
                    },
                ],
            },
        ];
    }

    static _returnItem(item) {
        let element;
        if (item.type === FIELD_TYPE.TYPE.TEXT_FIELD) {
            element = (
                <FieldInput
                    sm
                    placeholder={item.placeholder}
                    input={{
                        onChange: () => {
                            // this.setState({description: text});
                        },
                    }}
                    style={{ marginBottom: 10 }}
                    meta={{
                        error: null,
                        touched: false,
                    }}
                    // initialValues={description}
                />
            );
        } else if (item.type === FIELD_TYPE.TYPE.DROPDOWN_LIST) {
            element = (
                <DropdownList
                    items={item.variants}
                    selectedItem={null}
                    onItemSelect={() => {
                        // this.setState({selectedItem: item})
                    }}
                    placeholder={item.placeholder}
                />
            );
        } else if (FIELD_TYPE.TYPE.ITEMS_ARRAY_LIST) {
            element = <View />;
        }
        if (item.size !== FIELD_TYPE.SIZE.FULL_WIDTH) {
            let colWidth;
            if (item.size === FIELD_TYPE.SIZE.THIRD_WIDTH) {
                colWidth = s.col_33;
            } else if (item.size === FIELD_TYPE.SIZE.TWO_THIRD_WIDTH) {
                colWidth = s.col_66;
            } else {
                colWidth = s.col_50;
            }
            return (
                <View style={colWidth} key={item.id}>
                    {element}
                </View>
            );
        }
        return (
            <View style={s.row} key={item.id}>
                {element}
            </View>
        );
    }

    static _returnTitleComponent(title) {
        return <Text style={s.sectionHeader}>{title}</Text>;
    }

    static _returnCategoryItems(data) {
        const items = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].size !== FIELD_TYPE.SIZE.FULL_WIDTH) {
                items.push(
                    <View style={s.row}>
                        {DailyNewTaskScreen._returnItem(data[i])}
                        {DailyNewTaskScreen._returnItem(data[i + 1])}
                    </View>,
                );
                i++;
            } else {
                items.push(DailyNewTaskScreen._returnItem(data[i]));
            }
        }
        return items;
    }

    _returnPageElements() {
        const elements = DailyNewTaskScreen._returnItems().map((category) => {
            return (
                <View style={s.categoryWrapper} key={category.id}>
                    {DailyNewTaskScreen._returnTitleComponent(category.title)}
                    {DailyNewTaskScreen._returnCategoryItems(category.data)}
                </View>
            );
        });
        this.setState({
            elements,
            isReady: true,
        });
    }

    render() {
        const { isReady, elements } = this.state;

        return (
            <View style={[s.wrapper]}>
                <BottomGradient />
                <NavigationBar {...this.props} title="AIR COOLER CHILLER" backIcon />
                {isReady ? (
                    <ScrollView
                        style={[s.mainContainer]}
                        contentContainerStyle={{
                            paddingTop: 16,
                            alignItems: 'center',
                            width: '100%',
                            paddingBottom: 70,
                        }}
                    >
                        {elements}
                    </ScrollView>
                ) : (
                    <ActivityIndicator style={{ marginBottom: '50%' }} size={40} color={Color.blue} />
                )}
            </View>
        );
    }
}

export default DailyNewTaskScreen;
