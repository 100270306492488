import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, ScrollView, Image, ActivityIndicator } from 'react-native';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { uploadImage } from '../../../store/helpers/common';
import { generateId, downloadImage } from '../../../utils/helpers';
import { saveNote, saveImageToNote, removeAttachment, uploadStart } from '../../../store/modules/dailyReports';
import {
    NavigationBar,
    PrimaryButton,
    DropdownList,
    FieldInput,
    FileUploadModal,
    Canvas,
} from '../../../components/index';

import s from './styles';
import { Color } from '../../../theme';

const items = [
    'Notes, Issues, Concerns',
    'Site Safety Observation',
    'Quality Control Observation',
    'Construction Materials Delivered To Site (Name, Quantity)',
    'Testing/Inspections Performed',
    'List Of Verbal Instruction Given By Owner Architect, Or Engineer',
];

const _getAttachmentData = (item, currentNote, attachmentsCount) => {
    return {
        id: generateId(),
        uploading: false,
        uploaded: false,
        name: `${currentNote.id}_note_attachment_${attachmentsCount}`,
        type: item.type,
        fileName: `${currentNote.id}_note_attachment_${attachmentsCount}.jpg`,
        types: ['image'],
        subtypes: [],
        tags: [
            {
                value: 'image',
                protected: true,
            },
        ],
        file: item.base64,
        path: item.uri,
    };
};

class DailyNoteScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentNote: PropTypes.object,
        currentUploading: PropTypes.array,
        isUploading: PropTypes.bool,
        removeAttachment: PropTypes.func,
        saveNote: PropTypes.func,
        uploadImage: PropTypes.func,
        saveImageToNote: PropTypes.func,
        uploadStart: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedItem: props.currentNote.CategoryName,
            description: props.currentNote.Note,
            isAttachmentModal: false,
            isUpdated: false,
            imageSelected: false,
            selectedImage: null,
        };
    }

    save = () => {
        const { currentNote, saveNote, navigation } = this.props;
        const { selectedItem, description } = this.state;
        const note = {
            id: currentNote.id,
            CategoryName: selectedItem,
            Note: description,
            Attachment: currentNote.Attachment.map((attachment) => {
                const { downloading, File, FileAssetId, ...updatedAttachment } = attachment;
                if (File) {
                    return { File, FileAssetId };
                }

                return updatedAttachment;
            }),
        };

        saveNote({ note });
        navigation.navigate('DailyReport');
    };

    selectImage(attachment) {
        this.setState({
            selectedImage: attachment,
            imageSelected: true,
        });
    }

    saveImageDrawing = (image) => {
        const { saveImageToNote } = this.props;
        delete image.File;
        delete image.FileAssetId;
        saveImageToNote(image);
        this.setState({ imageSelected: false, selectedImage: null });
    };

    close = () => {
        this.setState({ imageSelected: false, selectedImage: null });
    };

    _selectOrDownloadImage = (attachment) => {
        const { currentNote, saveImageToNote } = this.props;
        if (attachment.File && !attachment.file) {
            const { downloading } = attachment;

            if (!downloading) {
                saveImageToNote({
                    ...attachment,
                    downloading: true,
                });

                downloadImage(attachment.File).then((image) => {
                    if (image) {
                        const { base64Image, path, type } = image;

                        if (base64Image && path) {
                            const updatedAttachment = {
                                ...attachment,
                                downloading: false,
                                ..._getAttachmentData(
                                    {
                                        type,
                                        base64: base64Image,
                                        uri: path,
                                    },
                                    currentNote,
                                    currentNote.Attachment.length + 1,
                                ),
                            };

                            saveImageToNote(updatedAttachment);
                            this.selectImage(updatedAttachment);
                        }
                    } else {
                        saveImageToNote({
                            ...attachment,
                            downloading: false,
                        });
                    }
                });
            }
        } else {
            this.selectImage(attachment);
        }
    };

    _returnAttachment(attachment, i) {
        const { removeAttachment } = this.props;
        return (
            <TouchableOpacity style={s.attachmentItem} key={i} onPress={() => this._selectOrDownloadImage(attachment)}>
                <Image
                    source={{ uri: attachment.File ? attachment.File : `data:image/png;base64,${attachment.file}` }}
                    style={s.attachmentImage}
                />
                {(attachment.uploading || attachment.downloading) && (
                    <View style={s.uploadingWrapper}>
                        <Text style={s.uploadingLabel}>{attachment.uploading ? 'uploading...' : 'downloading...'}</Text>
                    </View>
                )}
                <TouchableOpacity style={s.attachmentButton} onPress={() => removeAttachment(attachment)}>
                    <Icon name="close" size={25} color={Color.turquoise} />
                </TouchableOpacity>
            </TouchableOpacity>
        );
    }

    async saveAttachments(items) {
        const { saveImageToNote, currentNote } = this.props;
        let i = 1;
        for (const item of items) {
            const currentLength = currentNote.Attachment.length;
            const data = _getAttachmentData(item, currentNote, currentLength + i);
            saveImageToNote(data);
            i++;
        }
        this.setState({ isAttachmentModal: false, isUpdated: true });
    }

    render() {
        const { currentNote, currentUploading, uploadStart, isUploading } = this.props;
        const { selectedItem, description, isAttachmentModal, isUpdated, imageSelected, selectedImage } = this.state;
        const uploads = [];
        // eslint-disable-next-line max-len
        const backConfirmText =
            'You are about to back out of note. All not saved data will be lost. Are you sure you want to do this ?';
        const backTitle = 'Warning';

        currentUploading.forEach((u) => {
            if (u.id === currentNote.id) {
                uploads.push(u);
            }
        });
        return (
            <View style={[s.wrapper]}>
                <NavigationBar
                    {...this.props}
                    title="ADD NOTE"
                    backIcon
                    disableBackIcon={!isEmpty(currentUploading)}
                    backConfirm={isUpdated ? { title: backTitle, text: backConfirmText } : null}
                />
                <View>
                    <View style={s.menuTopContainer}>
                        <Text style={s.topMenu_title}>{moment().format('MM/DD/YY')}</Text>
                    </View>
                    <ScrollView style={{ height: '100%', width: '100%' }} contentContainerStyle={s.mainContainer}>
                        <View style={s.form}>
                            <View style={s.formItem}>
                                <DropdownList
                                    title="CATEGORY"
                                    items={items}
                                    selectedItem={selectedItem}
                                    onItemSelect={(item) => this.setState({ selectedItem: item, isUpdated: true })}
                                />
                            </View>
                            <FieldInput
                                title="NOTE"
                                multiline
                                style={{ elevation: 10, marginBottom: 10 }}
                                input={{
                                    onChange: (text) => {
                                        this.setState({ description: text, isUpdated: true });
                                    },
                                }}
                                meta={{
                                    error: null,
                                    touched: false,
                                }}
                                initialValues={description}
                            />
                            <View style={s.formItem}>
                                <TouchableOpacity onPress={() => this.setState({ isAttachmentModal: true })}>
                                    <View style={s.attachments}>
                                        <Text style={s.attachmentsText}>ATTACHMENTS</Text>
                                        <View style={s.iconWrapper}>
                                            <Icon name="attachment" color={Color.turquoise} size={20} />
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={s.attachmentsWrapper}>
                            {!isEmpty(currentNote) &&
                                currentNote.Attachment.map((a, i) => {
                                    return this._returnAttachment(a, i);
                                })}
                            {uploads.map((u, i) => {
                                if (u.id === currentNote.id) {
                                    return (
                                        <View style={s.attachmentItem} key={i}>
                                            <ActivityIndicator size={40} color={Color.blue} />
                                        </View>
                                    );
                                }
                                return null;
                            })}
                        </View>
                        <View style={s.buttonWrapper}>
                            <PrimaryButton
                                title="SAVE"
                                onPress={this.save}
                                disabled={!isEmpty(currentUploading) || isEmpty(selectedItem)}
                            />
                        </View>
                    </ScrollView>
                    <FileUploadModal
                        isVisible={isAttachmentModal}
                        onCancel={() => {
                            this.setState({ isAttachmentModal: false });
                        }}
                        returnItems={(attachments) => this.saveAttachments(attachments)}
                        isUploading={isUploading}
                        uploadStart={uploadStart}
                    />
                    <Canvas
                        visible={imageSelected}
                        image={selectedImage}
                        closeImageButton={this.close}
                        saveImage={this.saveImageDrawing}
                    />
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ dailyReports }) => ({
    currentNote: dailyReports.currentNote,
    currentUploading: dailyReports.currentUploading,
    isUploading: dailyReports.isUploading,
});

export default connect(mapStateToProps, { saveNote, uploadImage, saveImageToNote, removeAttachment, uploadStart })(
    DailyNoteScreen,
);
