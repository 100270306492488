import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { View } from 'react-native';
import { useFonts } from 'expo-font';
import { PersistGate } from 'redux-persist/integration/react';
import FlashMessage from 'react-native-flash-message';
import MobileMainContainer from './container/MobileMainContainer';
import configureStore from './store';

const { store, persistor } = configureStore();

const App = () => {

    const [fontsLoaded] = useFonts({
        'NunitoSans-Black': require('../assets/fonts/NunitoSans-Black.ttf'),
        'NunitoSans-ExtraBold': require('../assets/fonts/NunitoSans-ExtraBold.ttf'),
        'NunitoSans-Bold': require('../assets/fonts/NunitoSans-Bold.ttf'),
        'NunitoSans-Regular': require('../assets/fonts/NunitoSans-Regular.ttf'),
    });

    if (!fontsLoaded) {
        return null;
    }

    return (
        <Fragment>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <View style={{ flex: 1 }}>
                        <MobileMainContainer />
                        <FlashMessage position="top" />
                    </View>
                </PersistGate>
            </Provider>
        </Fragment>
    );
};

export default App;
