import { StyleSheet } from 'react-native';
import { Font, Color } from '../../../theme/index';

export default StyleSheet.create({
    bg: {
        width: '100%',
        height: '100%',
    },
    wrapper: {
        flex: 1,
        paddingHorizontal: 10,
        width: '100%',
        height: '100%',
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        paddingHorizontal: '5%',
    },
    errorText: {
        width: '100%',
        fontFamily: Font.type.regular,
        fontSize: Font.size.font12,
        color: Color.faded_orange,
        marginBottom: 10,
        marginLeft: '15%',
    },
    logo: {
        flex: 0.57,
        justifyContent: 'center',
        alignItems: 'center',
    },
    logoImg: {
        height: 71,
        width: 300,
    },
    form: {
        flex: 0.43,
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxWidth: 500,
        width: '100%',
    },
    versionText: {
        width: '100%',
        backgroundColor: Color.pale_grey_two,
        textAlign: 'center',
        fontFamily: Font.type.light,
        fontSize: Font.size.font11,
        color: Color.grey,
        marginTop: 40,
    },
    orText: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.grey,
        padding: 20,
    },
});
