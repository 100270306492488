import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NetInfo from '@react-native-community/netinfo';
import { ActivityIndicator, Image, Modal, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { showMessage } from 'react-native-flash-message';

import { uploadImage } from '../../../store/helpers/common';
import { uploadStart } from '../../../store/modules/dailyReports';

import {
    cleanUp,
    removeDescriptionImage,
    removeGCImage,
    saveDescriptionImage,
    saveGCImage,
    saveTimeAndMaterial,
    timeAndMaterialLoading,
} from '../../../store/modules/timeAndMaterial';

import { FieldInput, NavigationBar, PrimaryButton } from '../../../components/index';
import { Color } from '../../../theme/index';
import s from './styles';

const TYPE = {
    DESCRIPTION: 'DESCRIPTION',
    GC: 'GC',
};

class TimeAndMaterialNewItemScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentTimeAndMaterial: PropTypes.object,
        user: PropTypes.object,
        currentUploading: PropTypes.array,
        isLoading: PropTypes.bool,
        uploadImage: PropTypes.func,
        resizeImage: PropTypes.func,
        uploadStart: PropTypes.func,
        cleanUp: PropTypes.func,
        removeDescriptionImage: PropTypes.func,
        removeGCImage: PropTypes.func,
        saveDescriptionImage: PropTypes.func,
        saveGCImage: PropTypes.func,
        saveTimeAndMaterial: PropTypes.func,
        timeAndMaterialLoading: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            emails: [
                {
                    id: `${moment().unix()}`,
                    Value: '',
                },
            ],
            fields: [
                {
                    id: `${moment().unix()}`,
                    ItemsUsed: '',
                    AmountUsed: '',
                },
            ],
            rentalItems: [
                {
                    id: `${moment().unix()}`,
                    ItemsUsed: '',
                    TimeUsed: '',
                    Description: '',
                },
            ],
            name: '',
            notes: '',
            hours: '',
            subcontractors: [''],
            isSign: false,
            isConnected: true,
        };
    }

    componentDidMount() {
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            this.setState({ isConnected });
        });
    }

    _showErrorMessage(isCamera, errorCode, errorMessage) {
        let message = 'Storage Access Denied';
        let description = 'Cannot add attachments until storage access granted';

        if (isCamera) {
            message = 'Storage or Camera Access Denied';
            description = 'Cannot add attachments until storage and camera access granted';
        }

        if (errorCode && errorCode === 'camera_unavailable') {
            message = 'Camera Unavailable';
            description = 'Cannot add attachments';
        } else if (errorCode && errorCode === 'others') {
            message = 'Error';
            description = errorMessage;
        }

        showMessage({
            message,
            description,
            type: 'danger',
            duration: 10000,
            animationDuration: 700,
            hideOnPress: true,
            hideStatusBar: true,
            backgroundColor: Color.red,
        });
    }

    // ---- ATTACHMENTS ----
    selectPhotoFromLibrary = async (type) => {
        const { uploadStart } = this.props;

        const response = await launchImageLibrary({
            mediaType: 'photo',
            quality: 1,
            maxWidth: 900,
            maxHeight: 900,
            includeBase64: true,
            selectionLimit: 0,
        });
        const { didCancel, errorCode, errorMessage, assets } = response;
        if (!didCancel && !errorCode) {
            uploadStart();
            this.saveAttachments(assets, type);
        } else {
            this._showErrorMessage(false, errorCode, errorMessage);
        }
    };

    createPhoto = async (type) => {
        const { uploadStart } = this.props;

        const response = await launchCamera({
            mediaType: 'photo',
            quality: 1,
            maxWidth: 900,
            maxHeight: 900,
            includeBase64: true,
            selectionLimit: 0,
            saveToPhotos: true,
        });
        const { didCancel, errorCode, errorMessage, assets } = response;
        if (!didCancel && !errorCode) {
            uploadStart();
            this.saveAttachments(assets, type);
        } else {
            this._showErrorMessage(false, errorCode, errorMessage);
        }
    };

    saveAttachments(items, type) {
        const { currentTimeAndMaterial, uploadImage, saveDescriptionImage, saveGCImage } = this.props;
        items.forEach(async (item, i) => {
            const currentLength = currentTimeAndMaterial.Description.Attachment.length;
            const data = {
                name: `${currentTimeAndMaterial.id}_time_and_material_${currentLength + i}`,
                type: item.type,
                fileName: `${currentTimeAndMaterial.id}_time_and_material_${currentLength + i}.jpg`,
                types: ['image'],
                subtypes: [],
                tags: [
                    {
                        value: 'image',
                        protected: true,
                    },
                ],
            };
            const filetype = `time_and_material_${type}`;
            uploadImage(data, item.base64, filetype, currentTimeAndMaterial.id).then(({ asset, error }) => {
                if (!isEmpty(error)) {
                    showMessage({
                        message: 'Error',
                        description: error.message,
                        type: 'danger',
                        icon: 'danger',
                        position: 'right',
                        hideStatusBar: true,
                        backgroundColor: Color.red,
                    });
                } else if (type === TYPE.DESCRIPTION) {
                    saveDescriptionImage(asset, currentTimeAndMaterial);
                } else {
                    saveGCImage(asset, currentTimeAndMaterial);
                }
            });
        });
    }

    _returnAttachment(attachment, i, type) {
        const { isConnected } = this.state;
        const { currentTimeAndMaterial, removeDescriptionImage, removeGCImage } = this.props;
        const isApproved = currentTimeAndMaterial.ProjectManagerApproval === 'A';
        let onPress;
        if (type === TYPE.DESCRIPTION) {
            onPress = () => removeDescriptionImage(attachment.File, currentTimeAndMaterial);
        } else {
            onPress = () => removeGCImage(attachment.File, currentTimeAndMaterial);
        }
        if (isConnected) {
            return (
                <View style={s.attachmentItem} key={i}>
                    <Image source={{ uri: attachment.File }} style={s.attachmentImage} />
                    <TouchableOpacity style={s.attachmentButton} onPress={onPress} disabled={isApproved}>
                        <Icon name="close" size={25} color={Color.white} />
                    </TouchableOpacity>
                </View>
            );
        }
        return (
            <View style={s.offline} key={i}>
                <Icon name="wifi-off" size={30} color={Color.cloudy_blue} />
                <Text style={s.offlineText}>You are offline, connect to internet</Text>
            </View>
        );
    }

    // --- REST ITEMS --
    editMaterials(id, type, val) {
        const { fields } = this.state;
        fields.forEach((f, i) => {
            if (f.id === id) {
                if (type === 1) {
                    fields[i].ItemsUsed = val;
                } else {
                    fields[i].AmountUsed = val;
                }
            }
        });
        this.setState({ fields });
    }

    removeMaterials = (id) => {
        const { fields } = this.state;
        this.setState({ fields: fields.filter((a) => a.id !== id) });
    };

    addMaterial = () => {
        const { fields } = this.state;
        fields.push({
            id: `${moment().unix()}`,
            ItemsUsed: '',
            AmountUsed: '',
        });
        this.setState({ fields });
    };

    editRentalItem(id, type, val) {
        const { rentalItems } = this.state;
        rentalItems.forEach((f, i) => {
            if (f.id === id) {
                switch (type) {
                    case 1:
                        rentalItems[i].ItemsUsed = val;
                        break;
                    case 2:
                        rentalItems[i].TimeUsed = val;
                        break;
                    case 3:
                        rentalItems[i].DescriptionUsed = val;
                        break;
                    default:
                        break;
                }
            }
        });
        this.setState({ rentalItems });
    }

    removeRentalItem = (id) => {
        const { rentalItems } = this.state;
        this.setState({ rentalItems: rentalItems.filter((a) => a.id !== id) });
    };

    addRentalItem = () => {
        const { rentalItems } = this.state;
        rentalItems.push({
            id: `${moment().unix()}`,
            ItemsUsed: '',
            TimeUsed: '',
            Description: '',
        });
        this.setState({ rentalItems });
    };

    editEmails(id, val) {
        const { emails } = this.state;
        emails.forEach((f, i) => {
            if (f.id === id) {
                emails[i].Value = val;
            }
        });
        this.setState({ emails });
    }

    removeEmails = (id) => {
        const { emails } = this.state;
        this.setState({ emails: emails.filter((a) => a.id !== id) });
    };

    addEmails = () => {
        const { emails } = this.state;
        emails.push({
            id: `${moment().unix()}`,
            Value: '',
        });
        this.setState({ emails });
    };

    // -- GENERAL --
    _onSaveEvent = (result) => {
        const { name, notes, hours, emails, fields, rentalItems, subcontractors } = this.state;
        const {
            uploadImage,
            saveTimeAndMaterial,
            currentTimeAndMaterial,
            user,
            timeAndMaterialLoading,
            navigation,
            cleanUp,
        } = this.props;
        const timeAndMaterial = currentTimeAndMaterial;
        const file = result.encoded;

        let Subcontractor = [];
        if (subcontractors.length > 0) {
            Subcontractor = subcontractors.map((item) => {
                return {
                    id: moment().unix(),
                    Name: item,
                    Date: moment().format('MM-DD-YYYY'),
                };
            });
        }

        for (let i = 0; i < Subcontractor.length; i++) {
            if (Subcontractor[i].Name.length === 0) {
                Subcontractor.splice(i, 1);
            }
        }
        const data = {
            name: `${currentTimeAndMaterial.id}`,
            type: 'image',
            fileName: `${currentTimeAndMaterial.id}.png`,
            tags: [
                {
                    value: 'image',
                    protected: true,
                },
            ],
        };
        const RentalItems = rentalItems;
        for (let i = 0; i < RentalItems.length; i++) {
            if (RentalItems[i].ItemsUsed.length === 0) {
                RentalItems.splice(i, 1);
            }
        }
        const Emails = emails;
        for (let i = 0; i < Emails.length; i++) {
            if (Emails[i].Value.length === 0) {
                Emails.splice(i, 1);
            }
        }
        timeAndMaterialLoading();
        uploadImage(data, file, 'sign').then(({ asset, error }) => {
            if (error) {
                return;
            }

            timeAndMaterial.Title = name;
            timeAndMaterial.Description.Description = notes;
            timeAndMaterial.Hours = hours;
            timeAndMaterial.CustomerEmails = Emails;
            timeAndMaterial.ItemsUsed = fields;
            timeAndMaterial.RentalItems = RentalItems;
            timeAndMaterial.GeneralContractor = {
                ...timeAndMaterial.GeneralContractor,
                Name: user.username,
                Complete: true,
                Signature: asset.file.url,
            };

            timeAndMaterial.Subcontractor = Subcontractor;
            saveTimeAndMaterial({ timeAndMaterial, user }).then((resp) => {
                if (resp) {
                    navigation.navigate('TimeAndMaterialList');
                    cleanUp();
                }
            });
        });
    };

    save() {
        this.signature.saveImage();
        const { fields } = this.state;
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].ItemsUsed === '') {
                this.removeMaterials(fields[i].id);
            }
        }
    }

    setSubcontractor(text, id) {
        const { subcontractors } = this.state;
        subcontractors.forEach((s, i) => {
            if (i === id) {
                subcontractors[i] = text;
            }
        });
        this.setState({ subcontractors });
    }

    removeSubcontractor(id) {
        const { subcontractors } = this.state;
        this.setState({ subcontractors: subcontractors.splice(id, 1) });
    }

    addSubcontractor = () => {
        const { subcontractors } = this.state;
        const newSubcontractors = [...subcontractors, ''];
        this.setState({ subcontractors: newSubcontractors });
    };

    render() {
        const { isLoading, currentTimeAndMaterial, currentUploading } = this.props;
        const { name, notes, fields, emails, isSign, rentalItems, subcontractors } = this.state;
        const backConfirmText =
            // eslint-disable-next-line max-len
            'You are about to back out of your time and material. All not saved data will be lost. Are you sure you want to do this ?';
        const backTitle = 'Warning';
        const descAttachments = currentTimeAndMaterial ? currentTimeAndMaterial.Description.Attachment : [];
        let gcAttachments = [];
        if (currentTimeAndMaterial && currentTimeAndMaterial.GeneralContractor) {
            gcAttachments = !isEmpty(currentTimeAndMaterial.GeneralContractor.Picture)
                ? [{ File: currentTimeAndMaterial.GeneralContractor.Picture }]
                : [];
        }
        const isDisabled = !isSign || isEmpty(notes) || isEmpty(name) || !isEmpty(currentUploading);

        return (
            <ScrollView style={[s.wrapper]}>
                <NavigationBar
                    {...this.props}
                    title="ADD NEW TICKET"
                    backIcon
                    backConfirm={{ title: backTitle, text: backConfirmText }}
                />
                <View style={[s.mainContainer]}>
                    <FieldInput
                        placeholder="TICKET NAME"
                        sm
                        input={{
                            onChange: (text) => {
                                this.setState({ name: text });
                            },
                        }}
                        meta={{
                            error: null,
                            touched: false,
                        }}
                        initialValues={name}
                        editable
                    />
                    <FieldInput
                        placeholder="NOTES"
                        multiline
                        input={{
                            onChange: (text) => {
                                this.setState({ notes: text });
                            },
                        }}
                        meta={{
                            error: null,
                            touched: false,
                        }}
                        initialValues={notes}
                        editable
                    />
                    <View style={s.uploadBlock}>
                        <View style={s.uploadBlockHeader}>
                            <Text style={s.uploadBlockTitle}>ATTACH DESCRIPTION PHOTOS</Text>
                            <View style={{ flexDirection: 'row' }}>
                                <TouchableOpacity
                                    style={s.uploadIcon}
                                    onPress={() => this.selectPhotoFromLibrary(TYPE.DESCRIPTION)}
                                    disabled={false}
                                >
                                    <Icon name="attachment" color={Color.light_navy} size={16} />
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={s.uploadIcon}
                                    onPress={() => this.createPhoto(TYPE.DESCRIPTION)}
                                    disabled={false}
                                >
                                    <Icon name="camera" color={Color.light_navy} size={16} />
                                </TouchableOpacity>
                            </View>
                        </View>
                        {(!isEmpty(descAttachments) || !isEmpty(currentUploading)) && (
                            <View style={s.attachmentsWrapper}>
                                {descAttachments.map((a, i) => {
                                    return this._returnAttachment(a, i, TYPE.DESCRIPTION);
                                })}
                                {currentUploading.map((u, index) => {
                                    if (u.type === `time_and_material_${TYPE.DESCRIPTION}`) {
                                        return (
                                            <View key={index} style={s.attachmentItem}>
                                                <ActivityIndicator size={40} color={Color.blue} />
                                            </View>
                                        );
                                    }
                                    return null;
                                })}
                            </View>
                        )}
                    </View>
                    <View style={s.formWrapper}>
                        <Text style={s.formTitle}>MATERIAL AND QUANTITY</Text>
                        {fields.map((f) => {
                            return (
                                <View style={s.materialItem} key={f.id}>
                                    <View style={s.materialItemFields}>
                                        <FieldInput
                                            placeholder="MATERIAL"
                                            sm
                                            input={{
                                                onChange: (text) => {
                                                    this.editMaterials(f.id, 1, text);
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={f.ItemsUsed}
                                            style={s.materialDropdown}
                                            editable
                                        />
                                        <FieldInput
                                            placeholder="QUANTITY"
                                            sm
                                            input={{
                                                onChange: (text) => {
                                                    this.editMaterials(f.id, 2, text);
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={f.AmountUsed}
                                            style={s.materialDropdown}
                                            editable
                                        />
                                    </View>
                                    <TouchableOpacity
                                        onPress={() => this.removeMaterials(f.id)}
                                        style={s.deleteButton}
                                        disabled={false}
                                    >
                                        <Icon name="minus" style={s.deleteIcon} />
                                    </TouchableOpacity>
                                </View>
                            );
                        })}
                        <TouchableOpacity style={s.materialItemAdd} onPress={this.addMaterial} disabled={false}>
                            <Icon name="plus" style={s.materialItemAddIcon} />
                            <Text style={s.materialItemAddText}>ADD NEW</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={s.formWrapper}>
                        <Text style={s.formTitle}>RETAIL ITEMS</Text>
                        {rentalItems.map((f) => {
                            return (
                                <View style={s.materialItem} key={f.id}>
                                    <View style={s.materialItemFields}>
                                        <FieldInput
                                            placeholder="ITEMS USED"
                                            sm
                                            input={{
                                                onChange: (text) => {
                                                    this.editRentalItem(f.id, 1, text);
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={f.ItemsUsed}
                                            style={s.materialDropdown}
                                            editable
                                        />
                                        <FieldInput
                                            placeholder="TIME USED"
                                            sm
                                            input={{
                                                onChange: (text) => {
                                                    this.editRentalItem(f.id, 2, text);
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={f.TimeUsed}
                                            style={s.materialDropdown}
                                            editable
                                        />
                                        <FieldInput
                                            placeholder="DESCRIPTION"
                                            multiline
                                            sm
                                            input={{
                                                onChange: (text) => {
                                                    this.editRentalItem(f.id, 3, text);
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={f.Description}
                                            style={[s.materialDropdown, s.materialDropdown_multiline]}
                                            editable
                                        />
                                    </View>
                                    <TouchableOpacity
                                        onPress={() => this.removeRentalItem(f.id)}
                                        style={s.deleteButton}
                                        disabled={false}
                                    >
                                        <Icon name="minus" style={s.deleteIcon} />
                                    </TouchableOpacity>
                                </View>
                            );
                        })}
                        <TouchableOpacity style={s.materialItemAdd} onPress={this.addRentalItem} disabled={false}>
                            <Icon name="plus" style={s.materialItemAddIcon} />
                            <Text style={s.materialItemAddText}>ADD NEW</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={s.formWrapper}>
                        <Text style={[s.formTitle, { marginBottom: 15 }]}>EMAILS</Text>
                        {emails.map((e, i) => {
                            return (
                                <View style={s.emailAdd} key={e.id}>
                                    <FieldInput
                                        placeholder="EMAIL"
                                        style={{ flex: 1, marginRight: 11.5 }}
                                        sm
                                        input={{
                                            onChange: (text) => {
                                                this.editEmails(e.id, text);
                                            },
                                        }}
                                        meta={{
                                            error: null,
                                            touched: false,
                                        }}
                                        initialValues={e.Value}
                                        editable
                                    />
                                    {i + 1 < emails.length ? (
                                        <TouchableOpacity
                                            onPress={() => this.removeEmails(e.id)}
                                            style={s.emailAddButton}
                                            disabled={false}
                                        >
                                            <Icon name="minus" style={s.emailAddIcon} />
                                        </TouchableOpacity>
                                    ) : (
                                        <TouchableOpacity
                                            onPress={this.addEmails}
                                            style={s.emailAddButton}
                                            disabled={false}
                                        >
                                            <Icon name="plus" style={s.emailAddIcon} />
                                        </TouchableOpacity>
                                    )}
                                </View>
                            );
                        })}
                    </View>
                    <View style={s.formWrapper}>
                        <Text style={[s.formTitle, { marginBottom: 15 }]}>SUBCONTRACTORS</Text>
                        {subcontractors.map((name, i) => {
                            return (
                                <View style={s.emailAdd} key={i}>
                                    <FieldInput
                                        placeholder="SUBCONTRACTOR NAME"
                                        style={{ flex: 1, marginRight: 11.5 }}
                                        sm
                                        input={{
                                            onChange: (text) => {
                                                this.setSubcontractor(text, i);
                                            },
                                        }}
                                        meta={{
                                            error: null,
                                            touched: false,
                                        }}
                                        initialValues={name}
                                        editable
                                    />
                                    {i + 1 < subcontractors.length ? (
                                        <TouchableOpacity
                                            onPress={() => this.removeSubcontractor(i)}
                                            style={s.emailAddButton}
                                            disabled={false}
                                        >
                                            <Icon name="minus" style={s.emailAddIcon} />
                                        </TouchableOpacity>
                                    ) : (
                                        <TouchableOpacity
                                            onPress={this.addSubcontractor}
                                            style={s.emailAddButton}
                                            disabled={false}
                                        >
                                            <Icon name="plus" style={s.emailAddIcon} />
                                        </TouchableOpacity>
                                    )}
                                </View>
                            );
                        })}
                    </View>
                    <View style={s.formWrapper}>
                        <Text style={s.signatureInfo}>
                            By signing below, I represent that I am authorized to enter into this Change Order Agreement
                            on behalf of the Contractor. This Change Order will be performed on a time and material
                            basis with standard overhead and profit. The Contractor agrees to pay for this Changer Order
                            according to the terms of the Subcontract Agreement.
                        </Text>
                        <View style={s.modalSignature}></View>
                    </View>
                    <View style={s.uploadBlock}>
                        <View style={s.uploadBlockHeader}>
                            <Text style={s.uploadBlockTitle}>ATTACH GENERAL CONTRACTOR PHOTO</Text>
                            <View style={{ flexDirection: 'row' }}>
                                <TouchableOpacity
                                    style={s.uploadIcon}
                                    onPress={() => this.selectPhotoFromLibrary(TYPE.GC)}
                                    disabled={false}
                                >
                                    <Icon name="attachment" color={Color.light_navy} size={16} />
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={s.uploadIcon}
                                    onPress={() => this.createPhoto(TYPE.GC)}
                                    disabled={false}
                                >
                                    <Icon name="camera" color={Color.light_navy} size={16} />
                                </TouchableOpacity>
                            </View>
                        </View>
                        {(!isEmpty(gcAttachments) || !isEmpty(currentUploading)) && (
                            <View style={s.attachmentsWrapper}>
                                {gcAttachments.map((a, i) => {
                                    return this._returnAttachment(a, i, TYPE.GC);
                                })}
                                {currentUploading.map((u, i) => {
                                    if (u.type === `time_and_material_${TYPE.GC}`) {
                                        return (
                                            <View style={s.attachmentItem} key={i}>
                                                <ActivityIndicator size={40} color={Color.blue} />
                                            </View>
                                        );
                                    }
                                    return null;
                                })}
                            </View>
                        )}
                    </View>
                    <View style={s.buttonWrapper}>
                        <PrimaryButton
                            title="SAVE"
                            onPress={() => {
                                this.save();
                            }}
                            disabled={isDisabled}
                        />
                    </View>
                </View>
                <Modal visible={isLoading} transparent>
                    <View style={s.loadingModalWrapper}>
                        <ActivityIndicator color={Color.white} size={40} />
                    </View>
                </Modal>
            </ScrollView>
        );
    }
}

const mapStateToProps = ({ dailyReports, account, timeAndMaterials }) => ({
    currentReport: dailyReports.currentReport,
    currentProject: dailyReports.currentProject,
    currentUploading: dailyReports.currentUploading,
    isLoading: timeAndMaterials.isLoading,
    user: account.user,
    currentTimeAndMaterial: timeAndMaterials.currentTimeAndMaterial,
});

export default connect(mapStateToProps, {
    saveTimeAndMaterial,
    saveDescriptionImage,
    saveGCImage,
    removeDescriptionImage,
    removeGCImage,
    timeAndMaterialLoading,
    uploadStart,
    uploadImage,
    cleanUp,
})(TimeAndMaterialNewItemScreen);
