import { StyleSheet } from 'react-native';
import { Font, Color } from '../../../theme';

export default StyleSheet.create({
    wrapper: {
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        width: '100%',
        paddingTop: 16,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    form: {
        width: '90%',
    },
    menuTopContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    topMenu_title: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
    },
    buttonWrapper: {
        width: 180,
        marginBottom: 300,
        marginTop: 20,
    },
    formItem: {
        width: '100%',
        marginBottom: 10,
    },
});
