import moment from 'moment';
import React, { PureComponent } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { any } from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import s from './styles';
import { Color } from '../../../../theme';

export default class ListItem extends PureComponent {
    static propTypes = {
        item: any,
    };

    render() {
        const { item } = this.props;
        return (
            <TouchableOpacity onPress={item.onPress}>
                <View style={[s.listItemWrapper, s.fabricationItem]}>
                    <View style={s.listItemFabricationHeader}>
                        <Text style={s.cardDate}>{moment(item.date).format('MM/DD/YY')}</Text>
                    </View>
                    <View style={s.body}>
                        <Text numberOfLines={1} style={s.listItemTitle}>
                            {item.title}
                        </Text>
                        <Icon name="chevron-right" size={28} style={s.chevronRight} />
                    </View>
                    {item.status || item.unsaved ? (
                        <View style={s.subBody}>
                            {item.status ? <Text style={s.cardTypeStatusText}>{item.status.toUpperCase()}</Text> : null}
                            {item.unsaved ? <Text style={[s.cardTypeOfflineText]}>SAVED OFFLINE</Text> : null}
                        </View>
                    ) : null}
                    <View style={s.itemFabricationStatus}>
                        {item.isReady ? (
                            <View style={s.itemFabricationStatus}>
                                <View style={s.successIcon}>
                                    <Icon name="check" color={Color.white} size={7} />
                                </View>
                                <Text style={[s.successText, s.successText_ready]}>Ready</Text>
                            </View>
                        ) : (
                            <Text style={s.successText}>IN PROGRESS</Text>
                        )}
                    </View>
                </View>
            </TouchableOpacity>
        );
    }
}
