import axios from 'axios';
import { Config } from '../config';

class ApiError extends Error {
    constructor(dxpErrorResponse) {
        super(dxpErrorResponse.message);

        this.error = dxpErrorResponse.error;
        this.statusCode = dxpErrorResponse.statusCode;
    }
}

export default function Api() {
    const request = (method, path, params = {}, data = {}, headers = {}, options = {}) => {
        const requestHeaders = Object.assign(
            {
                Accept: 'application/json; charset=UTF-8',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            headers,
        );

        if (!options.skipAuth) {
            requestHeaders.Authorization = `Bearer ${Config.DXP_TOKEN}`;
        }

        const config = {
            url: path,
            method,
            baseURL: Config.DXP_ROOT_URL,
            headers: requestHeaders,
            params,
            data,
            timeout: 60000,
        };

        return axios(config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    throw new ApiError(error.response.data);
                }

                throw error;
            });
    };

    return {
        /**
         * Make get request
         * @param {string} path - url path
         * @param {object} params - query params to path with url
         * @param {object} headers - headers to pass in request
         * @param {object} options - additional options (skipAuth - skip auth header)
         * @returns {Promise<object>} - the object with response data
         */
        get: (path, params, headers = {}, options = {}) => {
            return request('get', path, params, {}, headers, options);
        },
        /**
         * Make post request
         * @param {string} path - url path
         * @param {object} data - payload data to pass in request body
         * @param {object} headers - headers to pass in request
         * @param {object} options - additional options (skipAuth - skip auth header)
         * @returns {Promise<object>} - the object with response data
         */
        post: (path, data, headers = {}, options = {}) => {
            return request('post', path, {}, data, headers, options);
        },
        /**
         * Make put request
         * @param {string} path - url path
         * @param {object} data - payload data to pass in request body
         * @param {object} headers - headers to pass in request
         * @param {object} options - additional options (skipAuth - skip auth header)
         * @returns {Promise<object>} - the object with response data
         */
        put: (path, data, headers = {}, options = {}) => {
            return request('put', path, {}, data, headers, options);
        },
    };
}
