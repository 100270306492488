import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, FlatList, ScrollView, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { showMessage } from 'react-native-flash-message';
import { difference, isEmpty, isEqual } from 'lodash';
import NetInfo from '@react-native-community/netinfo';
import { setCurrentProject } from '../../../store/modules/currentTimeCard';
import { setTimeCardEditProject, setBulkTimeCardEditProject } from '../../../store/modules/timeCardEdit';
import { setInjuryReportProject } from '../../../store/modules/injuryReporting';
import { loadProjects, getProject, cacheRecentProjects } from '../../../store/modules/account';
import { setViewingProject } from '../../../store/modules/dailyReports';
import { ListItem, NavigationBar } from '../../../components/index';

import s from './styles';
import { Color } from '../../../theme';

import { Config } from '../../../config';

class ProjectsScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        projects: PropTypes.array,
        cachedRecentProjects: PropTypes.array,
        timeCards: PropTypes.array,
        currentPage: PropTypes.number,
        totalPages: PropTypes.number,
        isSwitch: PropTypes.bool,
        invalidateRecentProjects: PropTypes.bool,
        loadProjects: PropTypes.func,
        setCurrentProject: PropTypes.func,
        cacheRecentProjects: PropTypes.func,
        setTimeCardEditProject: PropTypes.func,
        setInjuryReportProject: PropTypes.func,
        setViewingProject: PropTypes.func,
        setBulkTimeCardEditProject: PropTypes.func,
    };

    state = {
        search: '',
        isLoading: false,
        projectList: [],
        recentProjects: [],
        recentItemsLoading: false,
        searchLoading: false,
    };

    constructor(props) {
        super(props);
        const { navigation } = this.props;
        this.standalone = navigation.getParam('standalone', false);
        this.isTimeCardEdit = navigation.getParam('isTimeCardEdit', false);
        this.isTimeCardBulkEdit = navigation.getParam('isTimeCardBulkEdit', false);
        this.isInjuryReportEdit = navigation.getParam('isInjuryReportEdit', false);
        this.isDailyReport = navigation.getParam('isDailyReport', false);
        this.index = navigation.getParam('index', false);
    }

    componentDidMount() {
        const { projects, currentPage } = this.props;
        if (currentPage === 0) {
            this.getProjects(currentPage);
            if (projects && projects.length) {
                this.updateProjectsList();
            }
        } else {
            this.updateProjectsList();
        }
        this.loadRecentItems();
    }

    componentDidUpdate(prevProps) {
        const { navigation, projects, currentPage } = this.props;
        if (!isEqual(prevProps.projects, projects)) {
            this.updateProjectsList(currentPage === 1 ? [] : prevProps.projects);
        }

        this.standalone = navigation.getParam('standalone', false);
        this.isTimeCardEdit = navigation.getParam('isTimeCardEdit', false);
        this.isTimeCardBulkEdit = navigation.getParam('isTimeCardBulkEdit', false);
        this.isInjuryReportEdit = navigation.getParam('isInjuryReportEdit', false);
        this.isDailyReport = navigation.getParam('isDailyReport', false);
        this.index = navigation.getParam('index', false);
    }

    static getProjectTitle(project) {
        let title = '- empty name -';
        const { ProjectNumber, ProjectName } = project;

        if (ProjectNumber && ProjectName) {
            title = `${project.ProjectNumber}-${project.ProjectName}`;
        } else if (ProjectNumber) {
            title = ProjectNumber;
        } else if (ProjectName) {
            title = ProjectName;
        }

        return title;
    }

    getProjects = (page, newSearch) => {
        const { loadProjects, currentPage } = this.props;
        const { search } = this.state;

        const searchString = newSearch || newSearch === '' ? newSearch : search;
        const projectsPage = page || page === 0 ? page : currentPage;
        const projectTitle = Config.USE_DEPARTMENTS_JOBS ? 'department' : 'project';

        if (newSearch || newSearch === '') {
            this.setState({ searchLoading: true });
        } else {
            this.setState({ isLoading: true });
        }

        const showNoConnectionMessage = () => {
            showMessage({
                message:
                    // eslint-disable-next-line max-len
                    `No data connection detected. If your ${projectTitle} is not available, please sign in to Unassigned and contact Payroll.`,
                type: 'default',
                backgroundColor: Color.faded_orange,
                color: Color.dark_navy_blue,
                duration: 5000,
                animationDuration: 700,
                hideOnPress: true,
                hideStatusBar: true,
            });
        };

        this.setState({ search: newSearch });
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                loadProjects(projectsPage, searchString).then((response) => {
                    if (response instanceof Error) {
                        showNoConnectionMessage();
                    }
                    this.setState({
                        isLoading: false,
                        searchLoading: false,
                        search: searchString,
                    });
                });
            } else {
                this.setState({ isLoading: false, searchLoading: false });
                showNoConnectionMessage();
            }
        });
    };

    loadRecentItems() {
        const {
            navigation,
            timeCards,
            projects,
            isSwitch,
            setCurrentProject,
            setTimeCardEditProject,
            setInjuryReportProject,
            cacheRecentProjects,
            cachedRecentProjects,
            invalidateRecentProjects,
            setViewingProject,
            setBulkTimeCardEditProject,
        } = this.props;
        const recentItems = [];
        const projectsPromises = [];
        let recentProjects = {};
        NetInfo.fetch().then(async (state) => {
            const { isConnected } = state;
            // update cached projects if isConnected
            if (isConnected) {
                this.setState({ recentItemsLoading: true });

                for (const timeCard of timeCards) {
                    if (timeCard.Project && !recentProjects[timeCard.Project]) {
                        // try to get fresh project data from already loaded list
                        let project = projects.find((p) => p.id === timeCard.Project);
                        if (!project && !invalidateRecentProjects) {
                            // try to get project from already cached projects
                            project = cachedRecentProjects.find((p) => p.id === timeCard.Project);
                        }

                        if (project) {
                            recentProjects[project.id] = project;
                        } else {
                            projectsPromises.push(getProject(timeCard.Project));
                            recentProjects[timeCard.Project] = {};
                        }
                    }
                }

                const newProjects = await Promise.all(projectsPromises);
                newProjects.forEach((p) => {
                    if (p.length) {
                        const project = p[0];
                        recentProjects[project.id] = project;
                    }
                });
                recentProjects = Object.values(recentProjects);
                cacheRecentProjects(recentProjects);
            } else {
                recentProjects = cachedRecentProjects;
            }

            recentProjects.forEach((project) => {
                if (!isEmpty(project) && project.IsActive) {
                    recentItems.push({
                        id: project.id,
                        title: ProjectsScreen.getProjectTitle(project),
                        onPress: () => {
                            if (this.standalone) {
                                navigation.navigate('ProjectInfo', { project });
                                return;
                            }
                            if (this.isTimeCardBulkEdit) {
                                setBulkTimeCardEditProject(project, this.index);
                                navigation.navigate('BulkTimeCardEdit');
                            } else if (this.isTimeCardEdit) {
                                setTimeCardEditProject(project);
                                navigation.navigate('TimeCardEdit');
                            } else if (this.isDailyReport) {
                                setViewingProject(project);
                                navigation.navigate('Reports');
                            } else if (this.isInjuryReportEdit) {
                                setInjuryReportProject(project.id);
                                navigation.navigate('InjuredReportingItem');
                            } else {
                                setViewingProject(project);
                                setCurrentProject({
                                    project,
                                    isSwitch,
                                });
                                navigation.navigate('ClockIn');
                            }
                        },
                    });
                }
            });
            this.setState({ recentProjects: recentItems, recentItemsLoading: false });
        });
    }

    updateProjectsList(prevProjects) {
        const {
            navigation,
            setCurrentProject,
            setTimeCardEditProject,
            setInjuryReportProject,
            projects,
            isSwitch,
            setViewingProject,
            setBulkTimeCardEditProject,
        } = this.props;

        let { projectList } = this.state;
        const newProjects = difference(projects, prevProjects);
        projectList = !isEmpty(prevProjects) ? [...projectList] : [];

        newProjects.forEach((project) => {
            if (project.IsActive) {
                projectList.push({
                    id: project.id,
                    title: ProjectsScreen.getProjectTitle(project),
                    onPress: () => {
                        if (this.standalone) {
                            navigation.navigate('ProjectInfo', { project });
                            return;
                        }

                        if (this.isTimeCardBulkEdit) {
                            setBulkTimeCardEditProject(project, this.index);
                            navigation.navigate('BulkTimeCardEdit');
                        } else if (this.isTimeCardEdit) {
                            setTimeCardEditProject(project);
                            navigation.navigate('TimeCardEdit');
                        } else if (this.isInjuryReportEdit) {
                            setInjuryReportProject(project.id);
                            navigation.navigate('InjuredReportingItem');
                        } else if (this.isDailyReport) {
                            setViewingProject(project);
                            navigation.navigate('Reports');
                        } else {
                            setViewingProject(project);
                            setCurrentProject({
                                project,
                                isSwitch,
                            });
                            navigation.navigate('ClockIn');
                        }
                    },
                });
            }
        });
        this.setState({ projectList });
    }

    isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
        const paddingToBottom = 20;
        return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
    };

    onBack() {
        const { navigation } = this.props;
        if (this.isTimeCardBulkEdit) {
            navigation.pop();
            navigation.navigate('BulkTimeCardEdit');
        } else if (this.isTimeCardEdit) {
            navigation.pop();
            navigation.navigate('TimeCardEdit');
        } else if (this.isInjuryReportEdit) {
            navigation.pop();
            navigation.navigate('InjuredReportingItem');
        } else if (this.isDailyReport) {
            navigation.pop();
            navigation.navigate('Reports');
        } else {
            navigation.pop();
            navigation.navigate('ClockIn');
        }
    }

    render() {
        const { totalPages, currentPage } = this.props;
        const { isLoading, projectList, recentProjects, recentItemsLoading, searchLoading, search } = this.state;

        const recentItems = isEmpty(search)
            ? recentProjects
            : recentProjects.filter((c) => c.title.toLowerCase().includes(search.toLowerCase()));

        const title = Config.USE_DEPARTMENTS_JOBS ? 'DEPARTMENTS' : 'PROJECTS';

        return (
            <ScrollView
                style={s.wrapper}
                onScroll={({ nativeEvent }) => {
                    if (this.isCloseToBottom(nativeEvent) && !isLoading && !searchLoading && currentPage < totalPages) {
                        this.getProjects();
                    }
                }}
                scrollEventThrottle={400}
            >
                <NavigationBar
                    {...this.props}
                    title={title}
                    menuIcon={this.standalone}
                    backIcon={!this.standalone}
                    searchIcon
                    onSearch={(t) => this.getProjects(0, t)}
                    backIconFunction={() => this.onBack()}
                />
                <View style={[s.mainContainer]}>
                    {(recentProjects.length > 0 || recentItemsLoading) && (
                        <View>
                            <Text style={s.listTitle}>Recent projects</Text>
                            {recentItemsLoading ? (
                                <ActivityIndicator
                                    style={{ marginTop: 10, marginBottom: 20 }}
                                    color={Color.blue}
                                    size={40}
                                />
                            ) : (
                                <FlatList
                                    data={recentItems}
                                    renderItem={({ item }) => {
                                        if (item) {
                                            return <ListItem item={item} />;
                                        }
                                        return null;
                                    }}
                                    keyExtractor={(item, i) => (item ? item.id : i)}
                                    style={{ width: '100%' }}
                                    contentContainerStyle={{
                                        paddingBottom: 0,
                                        alignSelfL: 'center',
                                    }}
                                />
                            )}
                        </View>
                    )}
                    {(projectList.length > 0 || searchLoading) && (
                        <View>
                            <Text style={s.listTitle}>All Projects</Text>
                            {searchLoading ? (
                                <ActivityIndicator
                                    style={{ marginTop: 10, marginBottom: 20 }}
                                    color={Color.blue}
                                    size={40}
                                />
                            ) : (
                                <FlatList
                                    isLoading={isLoading}
                                    data={projectList}
                                    refreshing={isLoading}
                                    keyExtractor={(item, i) => (item ? item.id : i)}
                                    style={{ width: '100%' }}
                                    renderItem={({ item }) => {
                                        if (item) {
                                            return <ListItem item={item} />;
                                        }
                                        return null;
                                    }}
                                    contentContainerStyle={{
                                        paddingBottom: 30,
                                    }}
                                    ListFooterComponent={
                                        isLoading && (
                                            <ActivityIndicator style={{ marginTop: 30 }} size={40} color={Color.blue} />
                                        )
                                    }
                                />
                            )}
                        </View>
                    )}
                </View>
            </ScrollView>
        );
    }
}

const mapStateToProps = ({ account, timeCards, currentTimeCard }) => ({
    projects: account.projects,
    timeCards: timeCards.timeCards,
    isSwitch: currentTimeCard.isSwitch,
    currentPage: account.projectsCurrentPage,
    totalPages: account.projectsTotalPages,
    cachedRecentProjects: account.recentProjects,
    invalidateRecentProjects: account.invalidateRecentProjects,
});

export default connect(mapStateToProps, {
    setCurrentProject,
    setTimeCardEditProject,
    setInjuryReportProject,
    loadProjects,
    cacheRecentProjects,
    setViewingProject,
    setBulkTimeCardEditProject,
})(ProjectsScreen);
