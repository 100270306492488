import { cloneDeep } from 'lodash';
import produce from 'immer';
import { showMessage } from 'react-native-flash-message';

import CNST from '../constants';
import { Config } from '../../config';
import { generateId, showErrorMessage } from '../../utils/helpers';
import { Color } from '../../theme';
import Api from '../../utils/apiMiddleware';

function saveRequestOffLine(request, dispatch) {
    request.unsaved = true;
    if (!request.unsavedId) {
        request.unsavedId = generateId();
    }

    dispatch({
        type: CNST.REIMBURSEMENTS.SUBMIT_REQUEST.ERROR,
        request,
    });
}

function isRequestValid(request) {
    if (!request.Item) {
        showErrorMessage('Validation error', 'Please select reimbursement item');
        return false;
    }

    if (!request.Amount) {
        showErrorMessage('Validation error', 'Please enter reimbursement amount');
        return false;
    }

    if (!request.Price) {
        showErrorMessage('Validation error', 'Please enter reimbursement price');
        return false;
    }

    return true;
}

export function setCurrentReimbursementRequest(request) {
    return (dispatch) => {
        dispatch({
            type: CNST.REIMBURSEMENTS.SET_REQUEST,
            request,
        });
    };
}

export function clearCurrentReimbursementRequest() {
    return (dispatch) => {
        dispatch({
            type: CNST.REIMBURSEMENTS.CLEAR_REQUEST,
        });
    };
}

export function getReimbursementItems() {
    return async (dispatch) => {
        return Api()
            .get(`/sources/${Config.REIMBURSEMENT_ITEMS}/data`, { limit: 500, relatedSearch: false })
            .then((response) => {
                dispatch({
                    type: CNST.REIMBURSEMENTS.GET_ITEMS,
                    data: response.data,
                });
                return response.data;
            })
            .catch(() => {
                showMessage({
                    message: 'Error',
                    description:
                        'You do not currently have a data connection. We are not able to get reimbursement items.',
                    type: 'danger',
                    icon: 'danger',
                    position: 'right',
                    hideStatusBar: true,
                    duration: 1500,
                    backgroundColor: Color.red,
                });
                return false;
            });
    };
}

export function getReimbursementRequestsByPayPeriod(userId, id) {
    return async (dispatch) => {
        dispatch({
            type: CNST.REIMBURSEMENTS.GET_REQUESTS_PAY_PERIOD.LOADING,
        });

        try {
            Api()
                .post(`/sources/${Config.REIMBURSEMENT_REQUESTS}/data`, {
                    relatedSearch: false,
                    limit: 500,
                    searchCriteria: {
                        User: userId,
                        PayPeriod: id,
                    },
                })
                .then((response) => {
                    dispatch({
                        type: CNST.REIMBURSEMENTS.GET_REQUESTS_PAY_PERIOD.SUCCESS,
                        data: response.data,
                    });

                    return response.data;
                });
        } catch (error) {
            console.log(error);
            dispatch({
                type: CNST.REIMBURSEMENTS.GET_REQUESTS_PAY_PERIOD.ERROR,
            });
            return error;
        }
    };
}

export function getReimbursementRequests(userId) {
    return async (dispatch) => {
        dispatch({
            type: CNST.REIMBURSEMENTS.GET_REQUESTS.LOADING,
        });

        try {
            Api()
                .post(`/sources/${Config.REIMBURSEMENT_REQUESTS}/data`, {
                    relatedSearch: false,
                    limit: 500,
                    searchCriteria: {
                        User: userId,
                    },
                })
                .then((response) => {
                    dispatch({
                        type: CNST.REIMBURSEMENTS.GET_REQUESTS.SUCCESS,
                        data: response.data,
                    });

                    return response.data;
                });
        } catch (error) {
            dispatch({
                type: CNST.REIMBURSEMENTS.GET_REQUESTS.ERROR,
            });
            return error;
        }
    };
}

export function submitReimbursementRequest(data = {}, silent = false) {
    const { request, user } = data;

    const dataToSave = {
        User: request.User,
        Item: request.Item,
        Amount: request.Amount,
        Price: request.Price,
        TotalCost: request.TotalCost,
        Note: request.Note,
        Status: request.Status,
        UploadedToPayRoll: request.UploadedToPayRoll,
    };

    return async (dispatch) => {
        if (!isRequestValid(request)) {
            return false;
        }

        dispatch({ type: CNST.REIMBURSEMENTS.SUBMIT_REQUEST.LOADING });
        const isNew = request.id === null;

        return Api()
            .put(`/sources/${Config.REIMBURSEMENT_REQUESTS}/data/${isNew ? 'new' : request.id}`, {
                data: dataToSave,
                modifiedBy: user.username,
            })
            .then((response) => {
                dispatch({
                    type: CNST.REIMBURSEMENTS.SUBMIT_REQUEST.SUCCESS,
                    request: response,
                    unsavedId: request.unsavedId,
                });
                if (!silent) {
                    showMessage({
                        message: 'Success',
                        description: 'Your reimbursement request have been submitted',
                        duration: 10000,
                        backgroundColor: Color.turquoise,
                        animationDuration: 0,
                        hideOnPress: true,
                        hideStatusBar: true,
                    });
                }

                return true;
            })
            .catch(() => {
                if (!silent) {
                    showMessage({
                        message: 'Connection Failure',
                        description:
                            'You do not currently have a data connection. ' +
                            'This reimbursement request will be submitted as soon as you have a connection.',
                        type: 'danger',
                        icon: 'danger',
                        position: 'right',
                        hideStatusBar: true,
                        duration: 1500,
                        backgroundColor: Color.red,
                    });
                    saveRequestOffLine(request, dispatch);
                }
            });
    };
}

// ------------------------------------
// Reducers
// ------------------------------------
const initialState = {
    isLoading: false,
    reimbursementItems: [],
    reimbursementRequests: [],
    currentReimbursementRequest: null,
    reimbursementRequestsByPayPeriod: [],
};

export default produce((state, action) => {
    switch (action.type) {
        case CNST.REIMBURSEMENTS.GET_REQUESTS.LOADING:
        case CNST.REIMBURSEMENTS.GET_REQUESTS_PAY_PERIOD.LOADING:
        case CNST.REIMBURSEMENTS.SUBMIT_REQUEST.LOADING:
            state.isLoading = true;
            break;
        case CNST.REIMBURSEMENTS.GET_REQUESTS_PAY_PERIOD.SUCCESS:
            state.reimbursementRequestsByPayPeriod = action.data;
            state.isLoading = false;
            break;
        case CNST.REIMBURSEMENTS.GET_REQUESTS.SUCCESS:
            state.reimbursementRequests = action.data;
            state.isLoading = false;
            break;
        case CNST.REIMBURSEMENTS.GET_ITEMS:
            state.reimbursementItems = action.data;
            break;
        case CNST.REIMBURSEMENTS.SUBMIT_REQUEST.SUCCESS: {
            const { request, unsavedId } = action;
            let found = false;
            for (let i = 0; i < state.reimbursementRequests.length; i++) {
                const curRequest = state.reimbursementRequests[i];
                if ((request.id && curRequest.id === request.id) || (unsavedId && curRequest.unsavedId === unsavedId)) {
                    state.reimbursementRequests[i] = request;
                    found = true;
                    break;
                }
            }
            if (!found) {
                state.reimbursementRequests.push(request);
            }
            state.isLoading = false;
            break;
        }
        case CNST.REIMBURSEMENTS.CLEAR_REQUEST:
            state.currentReimbursementRequest = null;
            break;
        case CNST.REIMBURSEMENTS.SET_REQUEST:
            state.currentReimbursementRequest = action.request;
            break;
        case CNST.REIMBURSEMENTS.GET_REQUESTS.ERROR:
            state.isLoading = false;
            break;
        case CNST.REIMBURSEMENTS.GET_REQUESTS_PAY_PERIOD.ERROR:
            state.isLoading = false;
            break;
        case CNST.REIMBURSEMENTS.SUBMIT_REQUEST.ERROR: {
            const { request } = action;
            let found = false;
            for (let i = 0; i < state.reimbursementRequests.length; i++) {
                const curRequest = state.reimbursementRequests[i];
                if (
                    (request.id && curRequest.id === request.id) ||
                    (request.unsavedId && curRequest.unsavedId === request.unsavedId)
                ) {
                    state.reimbursementRequests[i] = request;
                    found = true;
                    break;
                }
            }

            if (!found) {
                state.reimbursementRequests.push(request);
            }

            state.isLoading = false;
            break;
        }
        case CNST.ACCOUNT.LOGOUT.SUCCESS:
            return cloneDeep(initialState);
        default:
            break;
    }
}, initialState);
