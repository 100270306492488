import { cloneDeep } from 'lodash';

import CNST from '../constants';
import Api from '../../utils/apiMiddleware';
import { Config } from '../../config';

export function timeAndMaterialLoading() {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_AND_MATERIAL.LOADING.START,
        });
    };
}

export function timeAndMaterialFinishLoading() {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_AND_MATERIAL.LOADING.FINISH,
        });
    };
}

// -------- Time and materials -------

export function getTimeAndMaterial(project) {
    return (dispatch) => {
        return Api()
            .get(`/sources/${Config.TAM}/data`, {
                limit: 300,
                searchField: 'Project',
                search: project,
            })
            .then((response) => {
                const { data } = response;
                dispatch({
                    type: CNST.TIME_AND_MATERIAL.GET_TIME_AND_MATERIAL,
                    data,
                });
                return data;
            })
            .catch((error) => {
                return error;
            });
    };
}

export function setTimeAndMaterial(item) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_AND_MATERIAL.SET_TIME_AND_MATERIAL,
            item,
        });
    };
}

export function saveDescriptionImage(image, changeOrderItem) {
    return (dispatch) => {
        const item = changeOrderItem;
        item.Description.Attachment.push({ File: image.file.url, FileAssetId: image._id });
        return dispatch({
            type: CNST.TIME_AND_MATERIAL.SAVE_TIME_AND_MATERIAL_IMAGE,
            item,
        });
    };
}

export function saveGCImage(image, changeOrderItem) {
    return (dispatch) => {
        const item = changeOrderItem;
        item.GeneralContractor.Picture = image.file.url;
        return dispatch({
            type: CNST.TIME_AND_MATERIAL.SAVE_TIME_AND_MATERIAL_IMAGE,
            item,
        });
    };
}

export function saveCompleteImage(image, changeOrderItem) {
    return (dispatch) => {
        const item = changeOrderItem;
        item.Complete.Attachment.push({ File: image.file.url, FileAssetId: image._id });
        return dispatch({
            type: CNST.TIME_AND_MATERIAL.SAVE_TIME_AND_MATERIAL_IMAGE,
            item,
        });
    };
}

export function removeDescriptionImage(image, changeOrderItem) {
    return (dispatch) => {
        const item = changeOrderItem;
        item.Description.Attachment = item.Description.Attachment.filter((a) => a.File !== image);
        return dispatch({
            type: CNST.TIME_AND_MATERIAL.REMOVE_TIME_AND_MATERIAL_IMAGE,
            item,
        });
    };
}
export function removeGCImage(image, changeOrderItem) {
    return (dispatch) => {
        const item = changeOrderItem;
        item.GeneralContractor.Attachment = item.Description.Attachment.filter((a) => a.File !== image);
        return dispatch({
            type: CNST.TIME_AND_MATERIAL.REMOVE_TIME_AND_MATERIAL_IMAGE,
            item,
        });
    };
}

export function removeCompleteImage(image, tam) {
    return (dispatch) => {
        const item = tam;
        item.Complete.Attachment = item.Description.Attachment.filter((a) => a.File !== image);
        return dispatch({
            type: CNST.TIME_AND_MATERIAL.REMOVE_TIME_AND_MATERIAL_IMAGE,
            item,
        });
    };
}

export function createTimeAndMaterial(data = {}) {
    const { user, currentProject } = data;
    return (dispatch) => {
        return dispatch({
            type: CNST.TIME_AND_MATERIAL.CREATE_TIME_AND_MATERIAL,
            item: {
                id: 0,
                ProjectMangerApproval: 'T',
                Project: currentProject.id,
                ProjectName: currentProject.ProjectName,
                User: user._id,
                Description: {
                    Description: '',
                    Attachment: [],
                },
                GeneralContractor: {
                    Name: '',
                    Signature: '',
                    Picture: '',
                },
                Subcontractor: {
                    Name: '',
                    Date: '',
                },
                CustomerEmails: [],
                StatementOfWorkSent: false,
                StatementOfWorkPDF: {},
                ItemsUsed: [],
                RentalItems: [],
                Complete: {
                    Complete: false,
                    UserSignature: '',
                    Attachment: [],
                },
                WorkTimes: [],
                PriceBreakOut: false,
                ItemsUsedAndPrice: [],
                TotalPrice: 0,
                TotalHours: 0,
                TotalPriceForHoursWorked: 0,
                TotalPriceForItems: 0,
                EstimatedHours: 0,
                QuoteSent: false,
                InvoicePDF: {},
                Paid: false,
            },
        });
    };
}

export function saveTimeAndMaterial(data = {}) {
    const { timeAndMaterial, user } = data;
    const item = timeAndMaterial;
    return (dispatch) => {
        dispatch({ type: CNST.TIME_AND_MATERIAL.SUBMIT_TIME_AND_MATERIAL.LOADING });
        const isNew = timeAndMaterial.id === 0;
        delete item.timeCreated;
        delete item.sourceId;
        delete item.hasProposedChanges;
        if (isNew) {
            delete item.id;
        }
        return Api()
            .put(`/sources/${Config.TAM}/data/${isNew ? 'new' : timeAndMaterial.id}`, {
                data: item,
                modifiedBy: user.username,
            })
            .then((response) => {
                dispatch({
                    type: CNST.TIME_AND_MATERIAL.SUBMIT_TIME_AND_MATERIAL.SUCCESS,
                    item: response,
                    isNew: !timeAndMaterial.id,
                });
                return true;
            })
            .catch((error) => {
                console.log(error);
                return dispatch({
                    type: CNST.TIME_AND_MATERIAL.SUBMIT_TIME_AND_MATERIAL.ERROR,
                });
            });
    };
}

export function addItems({ items, currentTimeAndMaterial, user }) {
    const newCurrentTimeAndMaterial = currentTimeAndMaterial;
    items.forEach((item) => {
        newCurrentTimeAndMaterial.ItemsUsed.push({
            ItemsUsed: item.material,
            AmountUsed: item.quantity,
        });
    });
    return (dispatch) => {
        dispatch({ type: CNST.TIME_AND_MATERIAL.LOADING.START });
        dispatch({
            type: CNST.TIME_AND_MATERIAL.ADD_ITEMS,
            newCurrentTimeAndMaterial,
        });
        dispatch(saveTimeAndMaterial({ timeAndMaterial: newCurrentTimeAndMaterial, user }));
        dispatch({
            type: CNST.TIME_AND_MATERIAL.LOADING.FINISH,
        });
    };
}

export function editMaterialItem({ item, currentTimeAndMaterial, user }) {
    const newCurrentTimeAndMaterial = currentTimeAndMaterial;
    newCurrentTimeAndMaterial.ItemsUsed.forEach((itemUsed, i) => {
        if (itemUsed.id === item.id) {
            newCurrentTimeAndMaterial.ItemsUsed[i] = {
                id: item.id,
                ItemsUsed: item.material,
                AmountUsed: item.quantity,
            };
        }
    });
    return (dispatch) => {
        dispatch({ type: CNST.TIME_AND_MATERIAL.LOADING.START });
        dispatch({
            type: CNST.TIME_AND_MATERIAL.ADD_ITEMS,
            newCurrentTimeAndMaterial,
        });
        dispatch(saveTimeAndMaterial({ timeAndMaterial: newCurrentTimeAndMaterial, user }));
        dispatch({
            type: CNST.TIME_AND_MATERIAL.LOADING.FINISH,
        });
    };
}

export function addRentalItems({ items, currentTimeAndMaterial, user }) {
    const newCurrentTimeAndMaterial = currentTimeAndMaterial;
    items.forEach((item) => {
        if (!newCurrentTimeAndMaterial.RentalItems) {
            newCurrentTimeAndMaterial.RentalItems = [];
        }
        newCurrentTimeAndMaterial.RentalItems.push(item);
    });
    return (dispatch) => {
        dispatch({ type: CNST.TIME_AND_MATERIAL.LOADING.START });
        dispatch({
            type: CNST.TIME_AND_MATERIAL.ADD_ITEMS,
            newCurrentTimeAndMaterial,
        });
        dispatch(saveTimeAndMaterial({ timeAndMaterial: newCurrentTimeAndMaterial, user }));
        dispatch({
            type: CNST.TIME_AND_MATERIAL.LOADING.FINISH,
        });
    };
}

export function editRentalItem({ item, currentTimeAndMaterial, user }) {
    const newCurrentTimeAndMaterial = currentTimeAndMaterial;
    newCurrentTimeAndMaterial.RentalItems.forEach((itemUsed, i) => {
        if (itemUsed.id === item.id) {
            newCurrentTimeAndMaterial.RentalItems[i] = item;
        }
    });
    return (dispatch) => {
        dispatch({ type: CNST.TIME_AND_MATERIAL.LOADING.START });
        dispatch({
            type: CNST.TIME_AND_MATERIAL.ADD_ITEMS,
            newCurrentTimeAndMaterial,
        });
        dispatch(saveTimeAndMaterial({ timeAndMaterial: newCurrentTimeAndMaterial, user }));
        dispatch({
            type: CNST.TIME_AND_MATERIAL.LOADING.FINISH,
        });
    };
}

export function addSubcontractor({ items, currentTimeAndMaterial, user }) {
    const newCurrentTimeAndMaterial = currentTimeAndMaterial;
    if (!newCurrentTimeAndMaterial.Subcontractor) {
        newCurrentTimeAndMaterial.Subcontractor = [];
    }
    items.forEach((item) => {
        newCurrentTimeAndMaterial.Subcontractor.push(item);
    });
    return (dispatch) => {
        dispatch({ type: CNST.TIME_AND_MATERIAL.LOADING.START });
        dispatch({
            type: CNST.TIME_AND_MATERIAL.ADD_ITEMS,
            newCurrentTimeAndMaterial,
        });
        dispatch(saveTimeAndMaterial({ timeAndMaterial: newCurrentTimeAndMaterial, user }));
        dispatch({
            type: CNST.TIME_AND_MATERIAL.LOADING.FINISH,
        });
    };
}

export function editSubcontractor({ item, currentTimeAndMaterial, user }) {
    const newCurrentTimeAndMaterial = currentTimeAndMaterial;
    newCurrentTimeAndMaterial.Subcontractor.forEach((subcontractor, i) => {
        if (i === item.id) {
            newCurrentTimeAndMaterial.Subcontractor[i] = item;
        }
    });
    return (dispatch) => {
        dispatch({ type: CNST.TIME_AND_MATERIAL.LOADING.START });
        dispatch({
            type: CNST.TIME_AND_MATERIAL.ADD_ITEMS,
            newCurrentTimeAndMaterial,
        });
        dispatch(saveTimeAndMaterial({ timeAndMaterial: newCurrentTimeAndMaterial, user }));
        dispatch({
            type: CNST.TIME_AND_MATERIAL.LOADING.FINISH,
        });
    };
}

export function deleteItem({ item, currentTimeAndMaterial, user }) {
    const newCurrentTimeAndMaterial = currentTimeAndMaterial;
    newCurrentTimeAndMaterial.ItemsUsed = newCurrentTimeAndMaterial.ItemsUsed.filter((i) => i.id !== item.id);
    return (dispatch) => {
        dispatch({ type: CNST.TIME_AND_MATERIAL.LOADING.START });
        dispatch({
            type: CNST.TIME_AND_MATERIAL.ADD_ITEMS,
            newCurrentTimeAndMaterial,
        });
        dispatch(saveTimeAndMaterial({ timeAndMaterial: newCurrentTimeAndMaterial, user }));
        dispatch({
            type: CNST.TIME_AND_MATERIAL.LOADING.FINISH,
        });
    };
}

export function deleteRentalItem({ item, currentTimeAndMaterial, user }) {
    const newCurrentTimeAndMaterial = currentTimeAndMaterial;
    newCurrentTimeAndMaterial.RentalItems = newCurrentTimeAndMaterial.RentalItems.filter((i) => i.id !== item.id);
    return (dispatch) => {
        dispatch({ type: CNST.TIME_AND_MATERIAL.LOADING.START });
        dispatch({
            type: CNST.TIME_AND_MATERIAL.ADD_ITEMS,
            newCurrentTimeAndMaterial,
        });
        dispatch(saveTimeAndMaterial({ timeAndMaterial: newCurrentTimeAndMaterial, user }));
    };
}

export function deleteSubcontractor({ item, currentTimeAndMaterial, user }) {
    const newCurrentTimeAndMaterial = currentTimeAndMaterial;
    newCurrentTimeAndMaterial.Subcontractor = newCurrentTimeAndMaterial.Subcontractor.filter((i) => i.id !== item.id);
    return (dispatch) => {
        dispatch({ type: CNST.TIME_AND_MATERIAL.LOADING.START });
        dispatch({
            type: CNST.TIME_AND_MATERIAL.ADD_ITEMS,
            newCurrentTimeAndMaterial,
        });
        dispatch(saveTimeAndMaterial({ timeAndMaterial: newCurrentTimeAndMaterial, user }));
    };
}

export function cleanUp() {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_AND_MATERIAL.CLEAN_UP,
        });
    };
}

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = {
    timeAndMaterials: [],
    currentTimeAndMaterial: null,
    isLoading: false,
    currentUploading: [],
};

export default function dailyReports(state = cloneDeep(initialState), action) {
    switch (action.type) {
        case CNST.TIME_AND_MATERIAL.CLEAN_UP:
            return {
                ...state,
                ...{
                    currentTimeAndMaterial: action.data,
                    isLoading: false,
                    currentUploading: [],
                },
            };
        case CNST.TIME_AND_MATERIAL.GET_TIME_AND_MATERIAL:
            return { ...state, ...{ timeAndMaterials: action.data } };
        case CNST.TIME_AND_MATERIAL.CREATE_TIME_AND_MATERIAL:
        case CNST.TIME_AND_MATERIAL.SET_TIME_AND_MATERIAL:
            return {
                ...state,
                ...{
                    currentTimeAndMaterial: action.item,
                    currentUploading: [],
                },
            };
        case CNST.TIME_AND_MATERIAL.SUBMIT_TIME_AND_MATERIAL.SUCCESS: {
            const { item, isNew } = action;
            let timeAndMaterials = null;
            if (isNew) {
                timeAndMaterials = [...state.timeAndMaterials, item];
            } else {
                timeAndMaterials = state.timeAndMaterials.map((t) => {
                    if (t.id !== item.id) {
                        return t;
                    }
                    return {
                        ...t,
                        ...item,
                    };
                });
            }
            return {
                ...state,
                ...{
                    isLoading: false,
                    timeAndMaterials,
                },
            };
        }
        case CNST.TIME_AND_MATERIAL.SUBMIT_TIME_AND_MATERIAL.ERROR: {
            return {
                ...state,
                ...{
                    isLoading: false,
                },
            };
        }
        case CNST.TIME_AND_MATERIAL.REMOVE_TIME_AND_MATERIAL_IMAGE:
        case CNST.TIME_AND_MATERIAL.SAVE_TIME_AND_MATERIAL_IMAGE: {
            return {
                ...state,
                ...{
                    currentNote: cloneDeep(action.note),
                },
            };
        }
        case CNST.DAILY_REPORTS.REMOVE_SURVEY_IMAGE:
        case CNST.DAILY_REPORTS.SAVE_SURVEY_IMAGE: {
            return {
                ...state,
                ...{
                    currentReport: cloneDeep(action.report),
                },
            };
        }
        case CNST.TIME_AND_MATERIAL.LOADING.START: {
            return {
                ...state,
                ...{
                    isLoading: true,
                },
            };
        }
        case CNST.TIME_AND_MATERIAL.LOADING.FINISH: {
            return {
                ...state,
                ...{
                    isLoading: false,
                },
            };
        }
        case CNST.TIME_AND_MATERIAL.ADD_ITEMS: {
            return {
                ...state,
                ...{
                    currentTimeAndMaterial: action.newCurrentTimeAndMaterial,
                    isLoading: false,
                },
            };
        }
        case CNST.CURRENT_TIME_CARD.SET_CURRENT_PROJECT:
            return { ...state, ...{ timeAndMaterials: [] } };
        case CNST.ACCOUNT.LOGOUT.SUCCESS:
            return { ...state, ...cloneDeep(initialState) };
        default:
            return state;
    }
}
