import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, ScrollView, FlatList, Text } from 'react-native';
import { NavigationBar } from '../../components';
import ListItem from './ListItem';
import s from './styles';

class PayrollCheckDate extends Component {
    static propTypes = {
        payRollCheckDates: PropTypes.array,
    };

    state = {};

    componentDidMount() {}

    render() {
        const { payRollCheckDates } = this.props;

        return (
            <View style={s.wrapper}>
                <NavigationBar title="Payroll Check Dates" menuIcon {...this.props} />
                <ScrollView style={s.mainContainer}>
                    {payRollCheckDates.length ? (
                        <FlatList
                            data={payRollCheckDates}
                            keyExtractor={(item, i) => (item ? item.id : i)}
                            style={{ width: '100%', height: '100%' }}
                            contentContainerStyle={{ paddingTop: 20 }}
                            renderItem={({ item, i }) => {
                                return (
                                    <ListItem
                                        item={item}
                                        i={i}
                                        paidTimeOff
                                        isLast={item.id === payRollCheckDates.length - 1}
                                    />
                                );
                            }}
                        />
                    ) : (
                        <Text style={s.listTitle}>Nothing Available</Text>
                    )}
                </ScrollView>
            </View>
        );
    }
}

const mapStateToProps = ({ account }) => ({
    payRollCheckDates: account.payRollCheckDates,
});

export default connect(mapStateToProps, {})(PayrollCheckDate);
