export default {
    logo: require('../images/logo/jc_clock.png'),
    logoClock: require('../images/logo/logo-cod-clock.png'),
    dark_sky_logo: require('../images/logo/dark_sky_logo.png'),
    bg: require('../images/background/bg_jc.jpg'),
    break: require('../images/logo/break.png'),
    someImg: require('../images/some_images/some_image_1.png'),
    someImg_2: require('../images/some_images/some_image_2.png'),
    someImg_3: require('../images/some_images/some_image_3.png'),
    noUserPhoto: require('../images/some_images/no_user_photo.png'),
};
