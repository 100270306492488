import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme';

export default StyleSheet.create({
    title: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue,
        textAlign: 'left',
    },
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        paddingBottom: 125,
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
    },
    logo: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingTop: '10%',
        paddingBottom: '10%',
    },
    logoImg: {
        width: 150,
        height: 50,
    },
    form: {
        height: '70%',
    },
    listTitle: {
        fontSize: Font.size.font12,
        fontFamily: Font.type.bold,
        color: Color.light_navy,
    },
    listTitleRight: {
        marginRight: 5,
    },
    listTitleWrapper: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: '5%',
        marginBottom: 10,
        marginTop: 20,
    },
    weekMenu: {
        width: '100%',
        paddingHorizontal: '5%',
        marginTop: 20,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    weekMenuIcon: {
        color: Color.blue,
        fontSize: Font.size.font23,
    },
    currentWeek: {
        color: Color.blue,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font12,
        marginTop: 3,
    },
    formBlock: {
        backgroundColor: Color.white,
        borderRadius: 10,
        width: '90%',
        marginLeft: '5%',
        elevation: 12,
        height: 330,
    },
    formBlockTitle: {
        color: Color.dark_navy_blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
    },
    weekTotal: {
        fontSize: Font.size.font11,
        color: Color.light_navy,
        fontFamily: Font.type.bold,
        textAlign: 'right',
    },
    formBlockDates: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        borderColor: Color.pale_grey_two,
        borderStyle: 'dotted',
        borderWidth: 2,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderRadius: 3,
        position: 'relative',
        paddingHorizontal: 20,
        paddingTop: 3,
    },
    weekDay: {
        flexDirection: 'row',
        width: '100%',
        height: 32,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    weekDayDate: {
        fontFamily: Font.type.regular,
        color: Color.light_grey_blue,
        fontSize: Font.size.font12,
        marginRight: 10,
    },
    weekDayName: {
        fontFamily: Font.type.regular,
        color: Color.light_navy,
        fontSize: Font.size.font12,
    },
    weekDayTime: {
        fontFamily: Font.type.regular,
        color: Color.light_navy,
        fontSize: Font.size.font11,
    },
    weekDayTime_inactive: {
        color: Color.light_grey_blue,
    },
    payPeriodBlock: {
        height: 376,
    },
    formBlockTotal: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 41,
        paddingHorizontal: 20,
        alignItems: 'center',
    },
    formBlockTotal_alignRight: {
        justifyContent: 'flex-end',
        height: 49,
    },
    formBlockTotal_border: {
        borderBottomWidth: 2,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderRadius: 3,
        borderStyle: 'dotted',
        borderColor: Color.pale_grey_two,
    },
    formHeaderBlock: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 20,
        height: 47.5,
    },
    formHeaderBlock_paymentPeriod: {
        height: 63,
    },
    formHeaderBlockCol: {
        flexDirection: 'column',
    },
    periodStatus: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.light_blue_grey,
        marginTop: 5,
    },
    periodStatus_waiting: {
        color: Color.faded_orange,
    },
    periodStatus_approved: {
        color: Color.turquoise,
    },
    submitButton: {
        width: 22.5,
        height: 22.5,
        borderRadius: 12,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.light_navy_10,
    },
});
