import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme';

export default StyleSheet.create({
    listItemWrapper: {
        width: '90%',
        marginBottom: 10,
        marginLeft: 22.5,
        marginRight: 21.5,
        backgroundColor: Color.white,
        borderRadius: 6,
        elevation: 5,
        paddingTop: 16,
        paddingLeft: 17,
        paddingBottom: 18,
    },
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        paddingBottom: 125,
        backgroundColor: Color.pale_grey_three,
    },
    mainContainer: {
        paddingTop: 30,
        height: '100%',
        width: '100%',
        marginBottom: -100,
    },
    titleDate: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.light_navy,
        textAlign: 'left',
        paddingBottom: 9,
        marginLeft: 23.5,
    },
    titleTime: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.light_navy,
        textAlign: 'right',
        paddingBottom: 9,
        marginLeft: '66%',
    },
    chevronRight: {
        fontSize: Font.size.font24,
        color: Color.light_navy,
        position: 'absolute',
        marginTop: 10,
        marginBottom: -10,
        right: 20,
        top: 30,
    },
    chevronLeft: {
        fontSize: Font.size.font24,
        color: Color.light_navy,
        position: 'absolute',
        marginTop: 10,
        marginBottom: -10,
        left: 20,
        top: 30,
    },
    weekSpan: {
        marginTop: 13,
        textAlign: 'center',
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.light_navy,
    },
    underline: {
        marginBottom: 20,
        borderColor: Color.pale_lilac,
        borderStyle: 'dotted',
        borderBottomWidth: 1,
        width: '100%',
        borderRadius: 1,
    },
    dateText: {
        marginLeft: 20,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font12,
        color: Color.light_grey_blue,
        marginBottom: 34.5,
    },
    dayText: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font12,
        color: Color.light_navy,
        marginBottom: 19.5,
        marginLeft: 295,
    },
    chevron: {
        color: Color.light_grey_blue,
        position: 'absolute',
        marginTop: 10,
        // marginBottom: -10,
        right: 15,
        top: -14,
    },
    cardType: {
        width: 103,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: Color.light_navy,
        height: 17,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 8,
        marginTop: 12,
    },
    cardTypeText: {
        color: Color.blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font8,
    },
    title: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue,
        textAlign: 'left',
        marginTop: 5,
    },
    listItemTimeCardHeader: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5,
    },
    cardTimes: {
        fontFamily: Font.type.regular,
        color: Color.light_grey_blue,
        fontSize: Font.size.font10,
    },
    cardTotalTime: {
        fontFamily: Font.type.regular,
        fontWeight: 'bold',
        color: Color.light_navy,
        fontSize: Font.size.font11,
        marginLeft: '59%',
    },
});
