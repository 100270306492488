import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme';

export default StyleSheet.create({
    mainContainer: {
        height: '100%',
        width: '100%',
    },
    menuContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        paddingHorizontal: 15,
    },
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_three,
    },
    activeTab: {
        width: '30%',
        textAlign: 'center',
        marginLeft: '10%',
        fontFamily: Font.type.bold,
        fontSize: Font.size.font11,
        color: Color.dark_navy_blue,
    },
    inactiveTab: {
        width: '55%',
        textAlign: 'center',
        marginLeft: '15%',
        fontFamily: Font.type.bold,
        fontSize: Font.size.font11,
        color: Color.light_grey_blue,
    },
    noOutstanding: {
        width: '100%',
        paddingLeft: '25%',
        paddingTop: '20%',
        paddingRight: '25%',
        alignItems: 'center',
    },
    noOutstandingText: {
        textAlign: 'center',
        fontFamily: Font.type.bold,
        fontSize: Font.size.font18,
        color: '#7f98b4',
    },
    listItemWrapper: {
        width: '90%',
        justifyContent: 'space-between',
        paddingHorizontal: 13,
        paddingTop: 12,
        paddingBottom: 17,
        marginBottom: 15,
        backgroundColor: Color.white,
        borderRadius: 6,
        height: 80,
        elevation: 5,
        alignSelf: 'center',
    },
    listItemTimeCardHeader: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5,
    },
    cardTimes: {
        fontFamily: Font.type.regular,
        color: Color.light_grey_blue,
        fontSize: Font.size.font10,
    },
    cardTotalTime: {
        fontFamily: Font.type.regular,
        fontWeight: 'bold',
        color: Color.light_navy,
        fontSize: Font.size.font11,
    },
    listItemColumn: {
        flexDirection: 'row',
    },
    listItemColumn_timeCard: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    chevronRight: {
        fontSize: Font.size.font24,
        color: Color.light_grey_blue,
        position: 'absolute',
        right: 15,
        top: 30,
    },
    chevronRight_timeCard: {
        marginTop: 10,
        marginBottom: -10,
    },
    listItemTitle: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue,
        marginTop: 10,
        marginBottom: -10,
        position: 'absolute',
        left: 9,
        top: 33,
    },
    dateText: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font12,
        color: Color.light_navy,
        marginTop: 25,
        marginBottom: 15,
        marginLeft: 20,
    },
});
