import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, TouchableOpacity, View } from 'react-native';
import { NavigationActions } from 'react-navigation';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import NetInfo from '@react-native-community/netinfo';
import { showMessage } from 'react-native-flash-message';
import { NavigationBar, PrimaryButton, ConfirmationDialog, SignModal } from '../../../components';

import { setLoading, signPayPeriod, addUnsavedSignedPeriod } from '../../../store/modules/timeCards';
import { getTimeOffCards } from '../../../store/modules/timeOff';

import { Color } from '../../../theme';
import s from './styles';
import { uploadImage } from '../../../store/helpers/common';
import { formatTimeFromSeconds } from '../../../utils/helpers';

class OutstandingTimeCardScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        user: PropTypes.object,
        groupedCards: PropTypes.array,
        timeOffItems: PropTypes.array,
        isLoading: PropTypes.bool,
        signPayPeriod: PropTypes.func,
        uploadImage: PropTypes.func,
        setLoading: PropTypes.func,
        addUnsavedSignedPeriod: PropTypes.func,
        getTimeOffCards: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            showConfirmationDialog: false,
        };
    }

    componentDidMount() {
        const { user, getTimeOffCards } = this.props;
        getTimeOffCards(user._id);
    }

    _handleSignSave = (signImageBuffer) => {
        const { uploadImage, signPayPeriod, addUnsavedSignedPeriod, user, setLoading, groupedCards, navigation } =
            this.props;
        const lastWeekTimeCardDate = moment().subtract(1, 'weeks').startOf('isoWeek');
        let lastWeekTimeCard = null;
        for (let i = 0; i < groupedCards.length; i++) {
            if (lastWeekTimeCardDate.isSame(groupedCards[i].StartTime, 'day')) {
                lastWeekTimeCard = cloneDeep(groupedCards[i]);
            }
        }
        const lastTimeCardStartTime = moment(lastWeekTimeCard.StartTime).format('MM/DD/YYYY');
        const lastTimeCardEndTime = moment(lastWeekTimeCard.EndTime).format('MM/DD/YYYY');
        const data = {
            name: `${user.fullName} time card sign (${lastTimeCardStartTime} - ${lastTimeCardEndTime})`,
            type: 'image/png',
            fileName: `${lastWeekTimeCard.id}.png`,
            types: ['image'],
            subtypes: [],
            tags: [
                {
                    value: 'image',
                    protected: true,
                },
            ],
        };
        setLoading(true);
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                uploadImage(data, signImageBuffer, 'sign').then(({ asset, error }) => {
                    if (error) {
                        setLoading(false);
                        showMessage({
                            message: 'Error',
                            description: 'An error occurred while uploading your signature. Please try again.',
                            type: 'danger',
                            icon: 'danger',
                            position: 'right',
                            duration: 3000,
                            hideStatusBar: true,
                            backgroundColor: Color.red,
                        });
                        return;
                    }

                    signPayPeriod(lastWeekTimeCard.id, asset, user);
                });
            } else {
                addUnsavedSignedPeriod(lastWeekTimeCard.id, data, signImageBuffer);
                showMessage({
                    message:
                        // eslint-disable-next-line max-len
                        'No data connection detected. Your signature/approval has been saved on your device and will be sent when you have a data connection.',
                    backgroundColor: Color.faded_orange,
                    color: Color.dark_navy_blue,
                    duration: 10000,
                    animationDuration: 0,
                    hideOnPress: true,
                    hideStatusBar: true,
                });
            }
        });
        this.setState({ isModalOpen: false });
        navigation.navigate(
            'Clock',
            {},
            NavigationActions.navigate({
                routeName: 'Clock',
            }),
        );
    };

    confirmationOnPress = () => {
        const { navigation } = this.props;
        this.setState({ isModalOpen: false });
        this.setState({ showConfirmationDialog: false });
        navigation.navigate(
            'Clock',
            {},
            NavigationActions.navigate({
                routeName: 'Clock',
            }),
        );
    };

    _renderWeekDays(lastWeekTimeCard) {
        const { timeOffItems } = this.props;
        const weekDays = [];
        weekDays.push(
            <View style={s.heading}>
                <Text style={{ width: 100 }}></Text>
                <Text style={s.reg}>REG</Text>
                <Text style={s.pto}>PTO</Text>
            </View>,
        );
        for (let i = 0; i < 7; i++) {
            const weekDay = moment(lastWeekTimeCard.StartTime).add(i, 'd');
            const formattedWeekDay = weekDay.format('MM-DD');
            let regDayTotal = 0;
            let ptoDayTotal = 0;
            lastWeekTimeCard.WorkTimes.forEach((card) => {
                if (weekDay.isSame(card.StartTime, 'day')) {
                    regDayTotal += card.Duration.WorkTime;
                }
            });
            timeOffItems.forEach((card) => {
                let timeOff = 0;
                card.TimeOffDays.forEach((recorded) => {
                    let dayTotal = 0;
                    if (weekDay.isSame(recorded.StartTime, 'day')) {
                        dayTotal += recorded.TotalTimeOff;
                    }
                    timeOff += dayTotal;
                });
                ptoDayTotal += timeOff;
            });

            weekDays.push(
                <View style={s.weekDay} key={`${formattedWeekDay}-${i}`}>
                    <View style={{ flexDirection: 'row', width: 100 }}>
                        <Text style={s.weekDayDate}>{weekDay.format('MM/DD')}</Text>
                        <Text style={s.weekDayName}>{weekDay.format('dddd')}</Text>
                    </View>
                    <Text
                        style={[
                            ptoDayTotal > 0
                                ? regDayTotal > 0
                                    ? s.weekDayTimeRegPadding
                                    : s.weekDayTime_inactiveRegPadding
                                : regDayTotal > 0
                                ? s.weekDayTimeReg
                                : s.weekDayTime_inactiveReg,
                        ]}
                    >
                        {regDayTotal > 0 ? formatTimeFromSeconds(regDayTotal, false) : '-'}
                    </Text>
                    <Text style={ptoDayTotal > 0 ? s.weekDayTimePto : s.weekDayTime_inactivePto}>
                        {ptoDayTotal > 0 ? formatTimeFromSeconds(ptoDayTotal, false) : '-'}
                    </Text>
                </View>,
            );
        }
        return weekDays;
    }

    render() {
        const { navigation, groupedCards, isLoading, timeOffItems } = this.props;
        const { isModalOpen, showConfirmationDialog } = this.state;
        const lastWeekTimeCardDate = moment().subtract(1, 'weeks').startOf('isoWeek');
        let lastWeeksTimeCard = null;
        for (let i = 0; i < groupedCards.length; i++) {
            if (lastWeekTimeCardDate.isSame(groupedCards[i].StartTime, 'day')) {
                lastWeeksTimeCard = groupedCards[i];
            }
        }
        let weekTotalTime = 0;
        let lastWeeksTimeCardPeriod = '';
        if (lastWeeksTimeCard) {
            lastWeeksTimeCard.WorkTimes.forEach((card) => {
                weekTotalTime += card.Duration.WorkTime;
            });
            const lastWeeksTimeCardStartTime = moment(lastWeeksTimeCard.StartTime).format('MMM DD');
            const lastWeeksTimeCardEndTime = moment(lastWeeksTimeCard.EndTime).format('MMM DD');
            lastWeeksTimeCardPeriod = `${lastWeeksTimeCardStartTime} - ${lastWeeksTimeCardEndTime}`;
        }
        let ptoDayTotal = 0;
        timeOffItems.forEach((card) => {
            let timeOff = 0;
            if (lastWeekTimeCardDate.isSame(card.StartTime, 'day')) {
                card.TimeOffDays.forEach((recorded) => {
                    timeOff += recorded.TotalTimeOff;
                });
            }
            ptoDayTotal += timeOff;
        });
        return (
            <View style={s.mainContainer}>
                <NavigationBar title="DASHBOARD" menuIcon {...this.props} />
                <View style={s.wrapper}>
                    {!lastWeeksTimeCard ? (
                        <TouchableOpacity
                            style={s.noOutstanding}
                            onPress={() => {
                                navigation.navigate(
                                    'Clock',
                                    {},
                                    NavigationActions.navigate({
                                        routeName: 'Clock',
                                    }),
                                );
                            }}
                        >
                            <Icon name="clipboard-check-outline" size={150} color="#7f98b4" />
                            <Text style={s.noOutstandingText}>You have signed off on all of your time cards</Text>
                        </TouchableOpacity>
                    ) : (
                        <View style={s.listItemWrapper}>
                            <View flexDirection="row">
                                <Text style={s.headerDate}>{lastWeeksTimeCardPeriod}</Text>
                            </View>
                            <View style={s.formBlockTotal_border} />
                            {this._renderWeekDays(lastWeeksTimeCard)}
                            <View style={s.formBlockTotal_border} />
                            <View style={s.totalTimes}>
                                <Text style={{ width: 100 }}></Text>
                                <Text style={s.totalTimeReg}>{formatTimeFromSeconds(weekTotalTime, false)}</Text>
                                <Text style={s.totalTimePto}>{formatTimeFromSeconds(ptoDayTotal, false)}</Text>
                            </View>
                            <View style={s.buttonsWrapper}>
                                <View style={s.buttonWrapper}>
                                    <PrimaryButton
                                        disabled={isLoading}
                                        title="SIGN FOR APPROVAL"
                                        onPress={() => this.setState({ isModalOpen: true })}
                                    />
                                </View>
                                <View style={s.buttonWrapper}>
                                    <PrimaryButton
                                        red
                                        title={'ERROR ON TIMECARD\n        OR CANCEL'}
                                        onPress={() => this.setState({ showConfirmationDialog: true })}
                                    />
                                </View>
                            </View>
                        </View>
                    )}
                </View>
                <SignModal
                    isOpen={isModalOpen}
                    title="SIGN TIME CARD"
                    onSave={this._handleSignSave}
                    onCancel={() => {
                        this.setState({ isModalOpen: false });
                    }}
                />
                <ConfirmationDialog
                    isOpen={showConfirmationDialog}
                    onPress={() => this.confirmationOnPress()}
                    text={
                        // eslint-disable-next-line max-len
                        'If you see an error on your time card or are experiencing trouble with the app, please email Payton Rausch at payton@jaybirdgroup.com. Please include the following information:\n• Date\n• Hours for day\n• Cost code\n• Job number\nPlease explain if you are experiencing trouble with the app.'
                    }
                />
            </View>
        );
    }
}

const mapStateToProps = ({ timeCards, account, timeOff }) => ({
    groupedCards: timeCards.groupedCards,
    isLoading: timeCards.isLoading,
    user: account.user,
    timeOffItems: timeOff.timeOffItems,
});

export default connect(mapStateToProps, {
    signPayPeriod,
    uploadImage,
    setLoading,
    addUnsavedSignedPeriod,
    getTimeOffCards,
})(OutstandingTimeCardScreen);
