import { cloneDeep, keyBy } from 'lodash';
import produce from 'immer';
import NetInfo from '@react-native-community/netinfo';
import { showMessage } from 'react-native-flash-message';
import CNST from '../constants';
import Api from '../../utils/apiMiddleware';
import { Config } from '../../config';
import { generateId } from '../../utils/helpers';

import { Color } from '../../theme';

function saveTimeOffOffline(ticket, dispatch) {
    ticket.unsaved = true;
    if (!ticket.unsavedId) {
        ticket.unsavedId = generateId();
    }
    showMessage({
        message: 'Error',
        description:
            'You do not currently have a data connection. This paid time off will save as soon has you have connection',
        type: 'danger',
        icon: 'danger',
        position: 'right',
        hideStatusBar: true,
        duration: 1500,
        backgroundColor: Color.red,
    });
    dispatch({
        type: CNST.TIME_OFF.SUBMIT_TIME_OFF_ITEM.ERROR,
        timeOffTicket: ticket,
    });
}

export function timeOffLoading() {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_OFF.LOADING.START,
        });
    };
}

export function timeOffFinishLoading() {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_OFF.LOADING.FINISH,
        });
    };
}

export function getPtoBuckets() {
    return (dispatch) => {
        return Api()
            .post(`/sources/${Config.PTO_BUCKETS}/data`, {
                relatedSearch: false,
                limit: 9999,
            })
            .then((response) => {
                dispatch({
                    type: CNST.TIME_OFF.GET_PTO_BUCKETS,
                    data: response.data,
                });
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    };
}

export function getTimeOffCards(userId) {
    const searchCriteria = {
        User: userId,
    };

    return (dispatch) => {
        return Api()
            .post(`/sources/${Config.TIME_OFF}/data`, {
                relatedSearch: false,
                limit: 500,
                searchCriteria,
            })
            .then((response) => {
                dispatch({
                    type: CNST.TIME_OFF.GET_TIME_OFF_ITEMS,
                    data: response.data,
                });
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    };
}

export function setTimeOffItem(item) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_OFF.SET_TIME_OFF_ITEM,
            item,
        });
    };
}

export function createTimeOffItem(data = {}) {
    const { user } = data;
    return (dispatch) => {
        return dispatch({
            type: CNST.TIME_OFF.CREATE_TIME_OFF_ITEM,
            item: {
                id: null,
                StartTime: null,
                EndTime: null,
                User: user._id,
                UserName: `${user.name.first} ${user.name.middle} ${user.name.last}`,
                TimeOffDays: [],
                TotalPaidTime: null,
                TotalUnpaidTime: null,
                UserComment: null,
                TotalPaidTimeOffBalance: null,
                AdminFeedback: null,
                TicketStatus: 'U',
                AdminApprover: [],
            },
        });
    };
}

export function saveTimeOffItem(data = {}) {
    const { timeOff, user } = data;
    const timeOffTicket = {
        User: timeOff.User,
        UserName: timeOff.UserName,
        TotalVacationHours: timeOff.TotalVacationHours,
        TotalPersonalHours: timeOff.TotalPersonalHours,
        TotalSickHours: timeOff.TotalSickHours,
        UserComment: timeOff.UserComment,
        AdminFeedback: timeOff.AdminFeedback,
        TicketStatus: 'U',
        AdminApprover: [],
        StartTime: timeOff.StartTime,
        EndTime: timeOff.EndTime,
        TimeOffDays: timeOff.TimeOffDays,
    };
    return (dispatch) => {
        dispatch({ type: CNST.TIME_OFF.SUBMIT_TIME_OFF_ITEM.LOADING });
        const isNew = timeOff.id === null;
        return NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                return Api()
                    .put(`/sources/${Config.TIME_OFF}/data/${isNew ? 'new' : timeOff.id}`, {
                        data: timeOffTicket,
                        modifiedBy: user.username,
                    })
                    .then((response) => {
                        showMessage({
                            message: 'Success',
                            description: 'Your Time Off has been uploaded',
                            type: 'success',
                            position: 'right',
                            hideStatusBar: true,
                            duration: 1500,
                            backgroundColor: Color.turquoise,
                        });

                        dispatch({
                            type: CNST.TIME_OFF.SUBMIT_TIME_OFF_ITEM.SUCCESS,
                            item: response,
                            unsavedTicketId: timeOff.unsavedId,
                        });
                        return true;
                    })
                    .catch((err) => {
                        showMessage({
                            message: 'Error',
                            description: err.message,
                            type: 'danger',
                            icon: 'danger',
                            position: 'right',
                            hideStatusBar: true,
                            duration: 5000,
                            backgroundColor: Color.red,
                        });
                        return false;
                    });
            }

            saveTimeOffOffline(timeOff, dispatch);
            return true;
        });
    };
}

export function cleanUp() {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_OFF.CLEAN_UP,
        });
    };
}

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = {
    timeOffItems: [],
    currentTimeOffItem: null,
    ptoBuckets: {},
    isLoading: false,
    assignUsers: [],
};

export default produce((state, action) => {
    switch (action.type) {
        case CNST.TIME_OFF.CLEAN_UP:
            state.currentTimeOffItem = action.data;
            state.isLoading = false;
            break;
        case CNST.TIME_OFF.GET_PTO_BUCKETS:
            state.ptoBuckets = keyBy(action.data, 'id');
            break;
        case CNST.TIME_OFF.GET_TIME_OFF_ITEMS:
            state.timeOffItems = action.data;
            break;
        case CNST.TIME_OFF.CREATE_TIME_OFF_ITEM:
        case CNST.TIME_OFF.SET_TIME_OFF_ITEM:
            state.currentTimeOffItem = action.item;
            break;
        case CNST.TIME_OFF.SUBMIT_TIME_OFF_ITEM.SUCCESS: {
            const { item, unsavedTicketId } = action;
            let tickets = state.timeOffItems;
            let found = false;
            tickets.forEach((ticket, i) => {
                if ((item.id && ticket.id === item.id) || (unsavedTicketId && ticket.unsavedId === unsavedTicketId)) {
                    tickets[i] = item;
                    found = true;
                }
            });
            if (!found) {
                tickets = [...state.timeOffItems, item];
            }
            state.isLoading = false;
            state.timeOffItems = tickets;
            break;
        }
        case CNST.TIME_OFF.SUBMIT_TIME_OFF_ITEM.ERROR: {
            const { timeOffTicket } = action;
            let found = false;
            state.timeOffItems.forEach((ticket, i) => {
                if (
                    (timeOffTicket.id && ticket.id === timeOffTicket.id) ||
                    (timeOffTicket.unsavedId && ticket.unsavedId === timeOffTicket.unsavedId)
                ) {
                    state.timeOffItems[i] = timeOffTicket;
                    found = true;
                }
            });
            if (!found) {
                state.timeOffItems = [...state.timeOffItems, timeOffTicket];
            }
            state.isLoading = false;
            break;
        }
        case CNST.TIME_OFF.LOADING.START:
            state.isLoading = true;
            break;
        case CNST.TIME_OFF.LOADING.FINISH:
            state.isLoading = false;
            break;
        case CNST.ACCOUNT.LOGOUT.SUCCESS: {
            const newState = cloneDeep(initialState);
            newState.timeOffItems = state.timeOffItems.filter((t) => t.unsaved);
            return newState;
        }
        default:
            break;
    }
}, initialState);
