import { StyleSheet } from 'react-native';
import { Font, Color } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        paddingTop: 30,
        height: '100%',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    listItemWrapper: {
        backgroundColor: Color.white,
        borderRadius: 6,
        marginBottom: 10,
        height: '97%',
        width: '90%',
        elevation: 5,
    },
    listItemRow: {
        borderBottomWidth: 1,
        borderBottomColor: Color.pale_lilac,
        paddingVertical: 10,
        paddingHorizontal: 20,
        width: '100%',
    },
    listItemLabel: {
        color: '#8b9198',
        fontSize: Font.size.font14,
    },
    listItemTitle: {
        fontSize: Font.size.font16,
    },
    listItemSubTitle: {
        fontSize: Font.size.font12,
    },
    listItemDate: {
        backgroundColor: Color.light_blue_3,
        borderRadius: 6,
        fontSize: Font.size.font16,
        paddingVertical: 5,
        paddingHorizontal: 10,
        alignSelf: 'flex-start',
    },
    listItemTotalTime: {
        fontSize: Font.size.font26,
        marginTop: 20,
        textAlign: 'center',
    },
    buttonWrapper: {
        marginVertical: 20,
        alignSelf: 'center',
        width: '80%',
    },
    flexRow: {
        flexDirection: 'row',
    },
    timeDetails: {
        marginLeft: 20,
        marginTop: 17,
    },
    statusText: {
        fontFamily: Font.type.bold,
        color: Color.dark_navy_blue,
        fontSize: Font.size.font9,
        marginRight: 3,
        fontWeight: 'bold',
    },
    statusTextPending: {
        fontFamily: Font.type.bold,
        color: Color.faded_orange,
        fontSize: Font.size.font9,
        marginRight: 3,
        fontWeight: 'bold',
    },
    statusTextApproved: {
        color: Color.turquoise,
    },
    statusTextDenied: {
        color: Color.pinkish_orange,
    },
});
