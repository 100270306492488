import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    modalWrapper: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.dark_navy_blue_50,
    },
    profileImageUploadModal: {
        borderRadius: 10,
        width: '90%',
        height: '80%',
        justifyContent: 'flex-end',
    },
    photoWrapper: {
        flex: 1,
        backgroundColor: Color.white,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        overflow: 'hidden',
    },
    photoPreview: {
        width: '100%',
        height: '100%',
    },
    attachmentImage: {
        flex: 1,
        backgroundColor: Color.white,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        overflow: 'hidden',
    },
    titleRow: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 65.5,
        backgroundColor: Color.light_navy,
        marginBottom: -1,
    },
    buttonsRow: {
        width: '100%',
        height: 88.5,
        backgroundColor: Color.middle_navy,
        flexDirection: 'row',
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
    },
    modalTitle: {
        fontFamily: Font.type.light,
        fontSize: Font.size.font18,
        color: Color.white_two,
    },
    buttonWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    takeShotButton: {
        width: 65,
        height: 65,
        borderRadius: 32.5,
        backgroundColor: Color.white_two,
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonText: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font12,
        color: Color.white_two,
    },
});
