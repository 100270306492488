import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ScrollView, Text, TouchableOpacity, View, Image } from 'react-native';
import PropTypes from 'prop-types';
import moment from 'moment';
import NetInfo from '@react-native-community/netinfo';
import { showMessage } from 'react-native-flash-message';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { cloneDeep, isEmpty } from 'lodash';

import { FileUploadModal, NavigationBar, FieldInput } from '../../../components/index';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import s from './styles';
import { Color } from '../../../theme/index';
import { generateId } from '../../../utils/helpers';
import {
    removeAttachment,
    saveAttachment,
    saveAccidentReportAttachments,
    saveAccidentReportTicket,
} from '../../../store/modules/accidentReporting';

class AccidentReportingAddNewStepThreeScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentAccident: PropTypes.object,
        user: PropTypes.object,
        isLoading: PropTypes.bool,
        removeAttachment: PropTypes.func,
        saveAttachment: PropTypes.func,
        saveAccidentReportAttachments: PropTypes.func,
        saveAccidentReportTicket: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { currentAccident } = props;
        if (currentAccident) {
            this.state = {
                officerName: currentAccident.PoliceInformation.OfficerName,
                department: currentAccident.PoliceInformation.Department,
                phoneNumber: currentAccident.PoliceInformation.PhoneNumber,
                badgeNumber: currentAccident.PoliceInformation.BadgeNumber,
                otherInfo: currentAccident.PoliceInformation.OtherInfo,
                witnessInformation: currentAccident.WitnessInformation,
                isAttachmentModal: false,
            };
        }
    }

    editWitness = (i, t, v) => {
        const { witnessInformation } = this.state;
        const newWitness = cloneDeep(witnessInformation);
        if (t === 'name') {
            newWitness[i].Name = v;
        } else if (t === 'address') {
            newWitness[i].Address = v;
        } else if (t === 'homePhone') {
            newWitness[i].HomePhone = v;
        } else {
            newWitness[i].WorkPhone = v;
        }
        this.setState({ witnessInformation: newWitness });
    };

    addWitness = () => {
        const { witnessInformation } = this.state;
        const newWitness = cloneDeep(witnessInformation);
        newWitness.push({
            PassengerName: '',
            PassengerPhoneNumber: '',
        });
        this.setState({ witnessInformation: newWitness });
    };

    removeWitness = (i) => {
        const { witnessInformation } = this.state;
        const newWitness = cloneDeep(witnessInformation);
        newWitness.splice(i, 1);
        this.setState({ witnessInformation: newWitness });
    };

    // --- ATTACHMENTS
    _returnAttachment(attachment, i) {
        const { currentAccident, removeAttachment } = this.props;

        return (
            <View style={s.attachmentItem} key={i}>
                <Image
                    source={{ uri: attachment.File ? attachment.File : `data:image/png;base64,${attachment.file}` }}
                    style={s.attachmentImage}
                />
                <TouchableOpacity
                    style={s.attachmentButton}
                    onPress={() => removeAttachment(attachment, currentAccident.Attachment)}
                >
                    <Icon name="close" size={25} color={Color.turquoise} />
                </TouchableOpacity>
            </View>
        );
    }

    async saveAttachments(items) {
        const { user, currentAccident, saveAttachment } = this.props;

        let i = 1;
        for (const item of items) {
            const currentLength = currentAccident.Attachment.length;
            let assetName = '';
            if (currentAccident.AccidentDetails.Date) {
                assetName += `${moment(currentAccident.AccidentDetails.Date).format('MM_DD_YYYY_hh_mm_ss_A')}_`;
            }
            assetName += `${user.username}_accident_attachment_${currentLength + i}`;
            const data = {
                id: generateId(),
                uploaded: false,
                name: assetName,
                type: item.type,
                fileName: `${assetName}.jpg`,
                types: ['image'],
                subtypes: [],
                tags: [
                    {
                        value: 'image',
                        protected: true,
                    },
                ],
                file: item.base64,
                path: item.uri,
            };
            saveAttachment(data, currentAccident.Attachment, false);
            i++;
        }

        this.setState({
            isAttachmentModal: false,
        });
    }

    save = async () => {
        const { navigation, currentAccident, user, saveAccidentReportAttachments, saveAccidentReportTicket } =
            this.props;

        const { officerName, department, phoneNumber, badgeNumber, otherInfo } = this.state;

        const ticket = cloneDeep(currentAccident);
        ticket.PoliceInformation.OfficerName = officerName;
        ticket.PoliceInformation.Department = department;
        ticket.PoliceInformation.PhoneNumber = phoneNumber;
        ticket.PoliceInformation.BadgeNumber = badgeNumber;
        ticket.PoliceInformation.OtherInfo = otherInfo;
        const witnessInformationList = [];
        ticket.WitnessInformation.forEach((w) => {
            if (w.Name !== '' || w.WorkPhone !== '' || w.HomePhone !== '' || w.Address !== '') {
                witnessInformationList.push(w);
            }
        });
        ticket.WitnessInformation = witnessInformationList;

        const connectionState = await NetInfo.fetch();
        if (connectionState.isConnected) {
            const success = await saveAccidentReportAttachments({ ticket });
            if (success) {
                saveAccidentReportTicket({ ticket, user, isConnected: connectionState.isConnected }).then((resp) => {
                    if (resp) {
                        navigation.navigate('AccidentReportingList');
                    }
                });
            } else {
                showMessage({
                    message: 'Error',
                    description: 'Some attachments were not uploaded. Please try again',
                    type: 'danger',
                    icon: 'danger',
                    position: 'right',
                    hideStatusBar: true,
                    duration: 1500,
                    backgroundColor: Color.red,
                });
            }
        } else {
            saveAccidentReportTicket({ ticket, user, isConnected: connectionState.isConnected });
            showMessage({
                message:
                    // eslint-disable-next-line max-len
                    'No data connection detected. Your report has been saved on your device and will be sent when you have a data connection.',
                backgroundColor: Color.faded_orange,
                color: Color.dark_navy_blue,
                duration: 10000,
                animationDuration: 0,
                hideOnPress: true,
                hideStatusBar: true,
            });
            navigation.navigate('AccidentReportingList');
        }
    };

    render() {
        const { currentAccident, isLoading } = this.props;

        const {
            officerName,
            department,
            phoneNumber,
            badgeNumber,
            otherInfo,
            witnessInformation,

            isAttachmentModal,
        } = this.state;

        return (
            <View style={s.wrapper}>
                <NavigationBar title="AUTO ACCIDENT REPORT" backIcon {...this.props} />
                <View style={s.menuContainer}>
                    <View style={s.menuDotOne} />
                    <View style={s.menuDotTwo} />
                    <View style={s.menuDotThree} />
                </View>
                <ScrollView>
                    <View style={s.mainContainer}>
                        <Text style={s.title}>Police Information</Text>
                        <FieldInput
                            title="OFFICER NAME"
                            initialValues={officerName}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ officerName: text });
                                },
                            }}
                        />
                        <FieldInput
                            title="DEPARTMENT"
                            initialValues={department}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ department: text });
                                },
                            }}
                        />
                        <FieldInput
                            title="PHONE"
                            initialValues={phoneNumber}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ phoneNumber: text });
                                },
                            }}
                        />
                        <FieldInput
                            title="BADGE NUMBER"
                            initialValues={badgeNumber}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ badgeNumber: text });
                                },
                            }}
                        />
                        <FieldInput
                            multiline
                            title="OTHER INFO"
                            initialValues={otherInfo}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            input={{
                                onChange: (text) => {
                                    this.setState({ otherInfo: text });
                                },
                            }}
                        />

                        <Text style={s.title}>Witness Information</Text>

                        <View style={s.listItemWrapper}>
                            {witnessInformation.map((item, i) => {
                                return (
                                    <View style={s.listItem} key={i}>
                                        <View style={s.listItemLeft}>
                                            <FieldInput
                                                style={s.fieldInternalStyle}
                                                title="NAME"
                                                initialValues={item.Name}
                                                meta={{
                                                    error: null,
                                                    touched: false,
                                                }}
                                                input={{
                                                    onChange: (text) => {
                                                        this.editWitness(i, 'name', text);
                                                    },
                                                }}
                                            />
                                            <FieldInput
                                                style={s.fieldInternalStyle}
                                                title="ADDRESS"
                                                initialValues={item.Address}
                                                meta={{
                                                    error: null,
                                                    touched: false,
                                                }}
                                                input={{
                                                    onChange: (text) => {
                                                        this.editWitness(i, 'address', text);
                                                    },
                                                }}
                                            />
                                            <FieldInput
                                                style={s.fieldInternalStyle}
                                                title="HOME PHONE"
                                                initialValues={item.HomePhone}
                                                meta={{
                                                    error: null,
                                                    touched: false,
                                                }}
                                                input={{
                                                    onChange: (text) => {
                                                        this.editWitness(i, 'homePhone', text);
                                                    },
                                                }}
                                            />
                                            <FieldInput
                                                style={s.fieldInternalStyle}
                                                title="WORK PHONE"
                                                initialValues={item.WorkPhone}
                                                meta={{
                                                    error: null,
                                                    touched: false,
                                                }}
                                                input={{
                                                    onChange: (text) => {
                                                        this.editWitness(i, 'workPhone', text);
                                                    },
                                                }}
                                            />
                                        </View>
                                        <View style={s.listItemRight}>
                                            <TouchableOpacity
                                                onPress={() => this.removeWitness(i)}
                                                style={s.removeButtonContainer}
                                            >
                                                <View style={s.removeButton}>
                                                    <Icon name="minus" size={20} color={Color.pinkish_orange} />
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                );
                            })}
                            <TouchableOpacity onPress={this.addWitness}>
                                <View style={s.buttonCancel}>
                                    <Text style={s.buttonTextCancel}>
                                        <Icon name="plus" size={15} />
                                        {' ADD NEW'}
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        </View>

                        <TouchableOpacity onPress={() => this.setState({ isAttachmentModal: true })}>
                            <View style={s.attachments}>
                                <Text style={s.attachmentsText}>ATTACHMENTS</Text>
                                <View style={s.iconWrapper}>
                                    <Icon name="attachment" color={Color.turquoise} size={20} />
                                </View>
                            </View>
                        </TouchableOpacity>
                        <View style={s.attachmentsWrapper}>
                            {!isEmpty(currentAccident) &&
                                currentAccident.Attachment.map((a, i) => {
                                    return this._returnAttachment(a, i);
                                })}
                        </View>

                        <FileUploadModal
                            isVisible={isAttachmentModal}
                            onCancel={() => {
                                this.setState({ isAttachmentModal: false });
                            }}
                            returnItems={(attachments) => this.saveAttachments(attachments)}
                            isUploading={false}
                        />

                        <View style={s.button}>
                            <PrimaryButton onPress={this.save} title="SAVE" isLoading={isLoading} />
                        </View>
                    </View>
                </ScrollView>
            </View>
        );
    }
}

const mapStateToProps = ({ accidentReporting, account }) => ({
    user: account.user,
    currentAccident: accidentReporting.currentTicket,
    isLoading: accidentReporting.isLoading,
});

export default connect(mapStateToProps, {
    saveAccidentReportTicket,
    saveAttachment,
    removeAttachment,
    saveAccidentReportAttachments,
})(AccidentReportingAddNewStepThreeScreen);
