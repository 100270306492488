import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        paddingTop: 23,
        height: '100%',
        width: '100%',
        paddingLeft: '7%',
    },
    textInputWithPlaceholder: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font11,
        backgroundColor: Color.white,
        borderRadius: 5,
        textAlignVertical: 'top',
        width: '90%',
        height: 106,
        paddingTop: 15.5,
        paddingLeft: 14.5,
        marginBottom: 9,
    },
    button: {
        height: 50,
        width: '90%',
        backgroundColor: Color.light_navy,
        borderRadius: 7.5,
        alignItems: 'center',
        paddingTop: 17,
        marginTop: 27,
        marginBottom: 30.5,
    },
    buttonText: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.white,
    },
    dropdownContainer: {
        backgroundColor: Color.white,
        borderRadius: 6,
        borderColor: Color.light_grey_blue,
        borderWidth: 1,
        paddingHorizontal: 19,
        height: 50,
        width: '80%',
        marginBottom: 10,
    },
    dropdownLabel: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
    },
    buttonCancel: {
        height: 50,
        width: '95%',
        backgroundColor: Color.white,
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: Color.light_navy,
        borderRadius: 7.5,
        alignItems: 'center',
        paddingTop: 17,
    },
    buttonTextCancel: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.light_navy,
    },
    listItemWrapper: {
        width: '90%',
        marginBottom: 10,
        marginRight: 21.5,
        backgroundColor: Color.white,
        borderRadius: 6,
        paddingTop: 17,
        paddingLeft: 15.5,
        paddingBottom: 17.5,
        elevation: 5,
    },
    listItemTitle: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font11,
        fontFamily: Font.type.regular,
        marginBottom: 11,
    },
    multilineTextInputWithPlaceholderLarge: {
        width: '90%',
    },
    removeButton: {
        borderColor: Color.pinkish_orange,
        borderStyle: 'solid',
        borderWidth: 2,
        height: 25,
        width: 25,
        alignItems: 'center',
        borderRadius: 12.5,
        position: 'absolute',
        right: 20,
        top: 43,
    },
});
