import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme/index';

export default StyleSheet.create({
    bg: {
        width: '100%',
        height: '100%',
    },
    forgotPasswordTitle: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font15,
        textAlign: 'center',
        marginVertical: 20,
        fontFamily: Font.type.regular,
        maxWidth: 500,
        width: '100%',
    },
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: '5%',
    },
    logo: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingTop: '10%',
        paddingBottom: '10%',
    },
    logoImg: {
        width: 150,
        height: 50,
    },
    form: {
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxWidth: 500,
        width: '100%',
    },
});
