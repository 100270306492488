import React, { PureComponent } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import s from './styles';
import { Color } from '../../../../theme';

export default class ListItem extends PureComponent {
    static propTypes = {
        item: PropTypes.object,
    };

    render() {
        const { item } = this.props;
        let statusText = '';
        switch (item.status) {
            case 'A':
                statusText = 'APPROVED';
                break;
            case 'D':
                statusText = 'DENIED';
                break;
            case 'U':
                statusText = 'PENDING APPROVAL';
                break;
            default:
                break;
        }
        const cost = `Cost: $${item.cost ? item.cost : 0}`;
        return (
            <TouchableOpacity style={s.listItemWrapper} onPress={item.onPress} disabled={item.status !== 'U'}>
                <View style={s.body}>
                    <Text style={s.listItemTitle}>{item.title}</Text>
                    <View style={s.subBody}>
                        <Text style={s.listItemTitle}>{cost}</Text>
                        <Icon
                            style={{ opacity: item.status === 'U' ? 1 : 0 }}
                            name="chevron-right"
                            size={28}
                            color={Color.turquoise}
                        />
                    </View>
                </View>
                <View style={s.status}>
                    <Text
                        style={[
                            s.statusText,
                            item.status === 'A' && s.statusTextApproved,
                            item.status === 'D' && s.statusTextDenied,
                            item.status === 'U' && s.statusTextPending,
                        ]}
                    >
                        {statusText}
                    </Text>
                    {item.unsaved && (
                        <View style={[s.statusBadge, s.statusBadgeOffline]}>
                            <Text style={s.statusBadgeOfflineText}>SAVED OFFLINE</Text>
                        </View>
                    )}
                </View>
            </TouchableOpacity>
        );
    }
}
