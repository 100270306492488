import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Text, TouchableOpacity, View, ActivityIndicator } from 'react-native';
import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { showMessage } from 'react-native-flash-message';
import LinkButton from '../LinkButton/LinkButton';
import { Color } from '../../theme';

import s from './styles';

export default class FileUploadModal extends Component {
    static propTypes = {
        isVisible: PropTypes.bool,
        isUploading: PropTypes.bool,
        onCancel: PropTypes.func,
        returnItems: PropTypes.func,
        uploadStart: PropTypes.func,
    };

    _showErrorMessage(isCamera, errorCode, errorMessage) {
        let message = 'Storage Access Denied';
        let description = 'Cannot add attachments until storage access granted';

        if (isCamera) {
            message = 'Storage or Camera Access Denied';
            description = 'Cannot add attachments until storage and camera access granted';
        }

        if (errorCode && errorCode === 'camera_unavailable') {
            message = 'Camera Unavailable';
            description = 'Cannot add attachments';
        } else if (errorCode && errorCode === 'others') {
            message = 'Error';
            description = errorMessage;
        }

        showMessage({
            message,
            description,
            type: 'danger',
            duration: 10000,
            animationDuration: 700,
            hideOnPress: true,
            hideStatusBar: true,
            backgroundColor: Color.red,
        });
    }

    selectPhotoFromLibrary = async () => {
        const { onCancel, returnItems, uploadStart } = this.props;

        const response = await launchImageLibrary({
            mediaType: 'photo',
            quality: 1,
            maxWidth: 900,
            maxHeight: 900,
            includeBase64: true,
            selectionLimit: 0,
        });
        const { didCancel, errorCode, errorMessage, assets } = response;
        if (didCancel) {
            onCancel();
        } else if (!errorCode) {
            if (uploadStart) {
                uploadStart();
            }
            returnItems(assets);
        } else {
            this._showErrorMessage(false, errorCode, errorMessage);
        }
    };

    createPhoto = async () => {
        const { onCancel, returnItems, uploadStart } = this.props;

        const response = await launchCamera({
            mediaType: 'photo',
            quality: 1,
            maxWidth: 900,
            maxHeight: 900,
            includeBase64: true,
            selectionLimit: 0,
            saveToPhotos: true,
        });
        const { didCancel, errorCode, errorMessage, assets } = response;
        if (didCancel) {
            onCancel();
        } else if (!errorCode) {
            if (uploadStart) {
                uploadStart();
            }
            returnItems(assets);
        } else {
            this._showErrorMessage(true, errorCode, errorMessage);
        }
    };

    render() {
        const { isVisible, onCancel, isUploading } = this.props;
        if (isUploading) {
            return (
                <Modal visible={isVisible} transparent>
                    <View style={s.modalWrapper}>
                        <View style={s.attachmentModal}>
                            <ActivityIndicator size={40} color={Color.blue} style={{ marginTop: 50, marginBottom: 20 }} />
                            <Text style={[s.attachmentModalTitle, { alignSelf: 'center', color: Color.cloudy_blue }]}>
                                Uploading...
                            </Text>
                        </View>
                    </View>
                </Modal>
            );
        }
        return (
            <Modal visible={isVisible} transparent>
                <View style={s.modalWrapper}>
                    <View style={s.attachmentModal}>
                        <Text style={s.attachmentModalTitle}>ADD ATTACHMENT</Text>
                        <TouchableOpacity style={s.attachmentItem} onPress={this.createPhoto}>
                            <View>
                                <Icon name="camera" color={Color.turquoise} size={30} />
                                <View style={s.attachmentPlusIconWrapper}>
                                    <Icon name="plus" color={Color.turquoise} />
                                </View>
                            </View>
                            <Text style={s.attachmentTitle}>Camera</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={s.attachmentItem} onPress={this.selectPhotoFromLibrary}>
                            <View>
                                <Icon name="image" color={Color.turquoise} size={30} />
                                <View style={s.attachmentPlusIconWrapper}>
                                    <Icon name="plus" color={Color.turquoise} />
                                </View>
                            </View>
                            <Text style={s.attachmentTitle}>Photo Library</Text>
                        </TouchableOpacity>
                        <View style={s.cancelModalButton}>
                            <LinkButton title="CANCEL" onPress={onCancel} />
                        </View>
                    </View>
                </View>
            </Modal>
        );
    }
}
