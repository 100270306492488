import moment from 'moment';
import React, { Component } from 'react';
import { Text, TouchableOpacity, View, ScrollView, BackHandler } from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { Document, Page, pdfjs } from 'react-pdf';
import { NavigationBar, PrimaryButton } from '../../components';
import s from './styles';
import { getEmployeeHandBooks } from '../../store/modules/account';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const employeeHandbookItems = [
    {
        id: 1,
        title: 'ADM_12-0_4A.pdf',
        date: 'August 03, 1998',
        url: 'https://www.osha.gov/sites/default/files/enforcement/directives/ADM_12-0_4A.pdf',
    },
    {
        id: 2,
        title: 'ADM_12-0_5A.pdf',
        date: 'August 03, 1998',
        url: 'https://www.osha.gov/sites/default/files/enforcement/directives/ADM_12-0_5A.pdf',
    },
    {
        id: 3,
        title: 'ADM_04-00-003.pdf',
        date: 'May 06, 2020',
        url: 'https://www.osha.gov/sites/default/files/enforcement/directives/ADM_04-00-003.pdf',
    },
];

class EmployeeHandbookDashboard extends Component {
    static propTypes = {
        getEmployeeHandBooks: PropTypes.func,
        employeeHandBooks: PropTypes.array,
    };

    constructor(props) {
        super(props);

        this.state = {
            isAdmin: false,
            selectedItem: null,
            items: [...employeeHandbookItems],
            numPages: null,
            pageNumber: 1,
        };
    }

    setHandbookItems() {
        const { employeeHandBooks } = this.props;
        let itemsToAddIn = [];

        if (employeeHandBooks && Array.isArray(employeeHandBooks)) {
            itemsToAddIn = employeeHandBooks.map((f) => {
                const item = {
                    id: f.id,
                    title: f.Name,
                    date: moment(f.timeCreated).format('MMMM DD, YYYY'),
                    url: f.Pdf,
                };
                return item;
            });
        }

        this.setState({ items: employeeHandbookItems.concat(itemsToAddIn) });
    }

    componentDidMount() {
        const { getEmployeeHandBooks } = this.props;
        getEmployeeHandBooks();
        this.setHandbookItems();
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
    }

    componentDidUpdate(prevProps) {
        const { employeeHandBooks } = this.props;
        const { employeeHandBooks: prevEmployeeHandBooks } = prevProps;

        if (!isEqual(prevEmployeeHandBooks, employeeHandBooks)) {
            this.setHandbookItems();
        }
    }

    componentWillUnmount() {
        this.backHandler.remove();
    }

    selectItem(id) {
        const { items } = this.state;
        for (let i = 0; i < items.length; i++) {
            if (items[i].id === id) {
                this.setState({ selectedItem: items[i] });
            }
        }
    }

    renderListItems() {
        const { items, isAdmin } = this.state;
        const listArray = [];
        for (let i = 0; i < items.length; i++) {
            listArray.push(
                <TouchableOpacity
                    key={items[i].id}
                    style={s.listItemWrapper}
                    onPress={() => this.selectItem(items[i].id)}
                >
                    <Icon name="book" style={s.listItemIcon} />
                    <View style={{ flexDirection: 'column' }}>
                        <Text style={s.listItemTitleText}>{items[i].title}</Text>
                        <Text style={s.listItemDateText}>{items[i].date}</Text>
                    </View>
                    {isAdmin ? <Icon name="download" style={s.adminIcon} /> : <></>}
                </TouchableOpacity>,
            );
        }
        return listArray;
    }

    handleBackPress = () => {
        this.setState({ selectedItem: null });
        return true;
    };

    handleSearch = (searchTerm) => {
        let filteredItems = null;
        if (searchTerm) {
            filteredItems = employeeHandbookItems.filter((item) => item.title.includes(searchTerm));
        } else {
            filteredItems = [...employeeHandbookItems];
        }

        this.setState({
            items: filteredItems,
        });
    };

    onDocumentLoadSuccess = (numPages) => {
        this.setState({ numPages });
    };

    renderPdfViewer() {
        const { selectedItem, pageNumber, numPages } = this.state;
        return (
            <View>
                <div style={s.pdfViewer}>
                <Document file={selectedItem.url} onLoadSuccess={this.onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                </Document>
                </div>
                <PrimaryButton
                    disabled={pageNumber === numPages}
                    onPress={() => this.setState({ pageNumber: pageNumber + 1 })}
                    title="Next Page"
                    md
                />
                <PrimaryButton
                    disabled={pageNumber === 1}
                    onPress={() => this.setState({ pageNumber: pageNumber - 1 })}
                    title="Prev Page"
                    md
                />
            </View>
        );
    }

    renderNavBar() {
        const { selectedItem } = this.state;

        if (selectedItem) {
            return (
                <>
                    <NavigationBar
                        title="PDF VIEWER"
                        backIcon
                        backIconFunction={this.handleBackPress}
                        {...this.props}
                    />
                    <View style={s.menuTopContainer}>
                        <Text style={s.menuTopTitle}>{selectedItem.title}</Text>
                        <Text style={s.listItemDateText}>{selectedItem.date}</Text>
                    </View>
                </>
            );
        }

        return <NavigationBar title="HANDBOOK" menuIcon searchIcon onSearch={this.handleSearch} {...this.props} />;
    }

    renderBody() {
        const { selectedItem } = this.state;

        if (selectedItem) {
            return this.renderPdfViewer();
        }

        return this.renderListItems();
    }

    render() {
        return (
            <View style={s.wrapper}>
                {this.renderNavBar()}
                <ScrollView style={s.mainContainer}>{this.renderBody()}</ScrollView>
            </View>
        );
    }
}

const mapStateToProps = ({ account }) => ({
    employeeHandBooks: account.employeeHandBooks,
});

export default connect(mapStateToProps, {
    getEmployeeHandBooks,
})(EmployeeHandbookDashboard);
