import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import NetInfo from '@react-native-community/netinfo';
import { showMessage } from 'react-native-flash-message';
import { Text, TouchableOpacity, View, FlatList } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { NavigationBar } from '../../../components';
import {
    setAccidentReportTicket,
    createAccidentReportTicket,
    getAccidentReportTickets,
} from '../../../store/modules/accidentReporting';
import { userLogin } from '../../../store/modules/account';
import { Color } from '../../../theme';
import s from './styles';

class AccidentReportingListScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        user: PropTypes.object,
        tickets: PropTypes.array,
        isLoading: PropTypes.bool,
        getAccidentReportTickets: PropTypes.func,
        createAccidentReportTicket: PropTypes.func,
        setAccidentReportTicket: PropTypes.func,
        userLogin: PropTypes.func,
    };

    componentDidMount() {
        this.onRefresh();
    }

    createTicket = () => {
        const { createAccidentReportTicket, user, navigation } = this.props;
        createAccidentReportTicket({ user: user._id });
        navigation.navigate('AccidentReportingAddNewStepOne');
    };

    selectTicket = (ticket) => {
        const { navigation, setAccidentReportTicket } = this.props;
        setAccidentReportTicket(ticket);
        navigation.navigate('AccidentReportingAddNewStepTwo');
    };

    onRefresh = () => {
        const { user, getAccidentReportTickets } = this.props;
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                getAccidentReportTickets(user._id);
            } else {
                showMessage({
                    message: 'Connection Failure!',
                    description: 'We are unable to get the most recent accident reports.',
                    type: 'danger',
                    duration: 5000,
                    animationDuration: 700,
                    hideOnPress: true,
                    hideStatusBar: true,
                    backgroundColor: Color.red,
                });
            }
        });
    };

    renderTicket = (ticket) => {
        const status = ticket.Status === 'P' ? 'PENDING' : ticket.Status === 'C' ? 'CLOSED' : 'MORE INFO NEED';
        const comment = ticket.AdminNotes ? ticket.AdminNotes : 'No comments yet';
        return (
            <TouchableOpacity style={s.listItemWrapper} onPress={() => this.selectTicket(ticket)}>
                <Text style={s.listItemDateText}>{moment(ticket.LastUpdated).format('MM/DD/YY')}</Text>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={s.listItemStatusPending}>{status}</Text>
                    {ticket.unsaved && <Text style={s.listItemStatusUnsaved}>Saved On Device</Text>}
                </View>
                <Text style={s.listItemComments}>{comment}</Text>
            </TouchableOpacity>
        );
    };

    render() {
        const { isLoading, tickets } = this.props;
        return (
            <View style={s.wrapper}>
                <NavigationBar title="DASHBOARD" menuIcon {...this.props} />
                <View style={s.mainContainer}>
                    <FlatList
                        data={tickets}
                        keyExtractor={(item, i) => (item ? item.id : i)}
                        refreshing={isLoading}
                        onRefresh={this.onRefresh}
                        style={{ width: '100%', height: '100%' }}
                        contentContainerStyle={{ paddingTop: 20 }}
                        renderItem={({ item }) => this.renderTicket(item)}
                    />
                    <View style={s.bottomButtonsBlock}>
                        <TouchableOpacity style={s.addButton} onPress={this.createTicket}>
                            <Icon name="plus" color={Color.white} size={30} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ accidentReporting, account, currentTimeCard }) => ({
    currentTimeCard,
    user: account.user,
    isLoading: accidentReporting.isLoading,
    tickets: accidentReporting.accidentReportTickets,
});

const mapDispatchToProps = (dispatch) => {
    const bindActions = bindActionCreators(
        {
            setAccidentReportTicket,
            createAccidentReportTicket,
            getAccidentReportTickets,
            userLogin,
        },
        dispatch,
    );
    bindActions.dispatch = dispatch;
    return bindActions;
};

export default connect(mapStateToProps, mapDispatchToProps)(AccidentReportingListScreen);
