import { cloneDeep } from 'lodash';
import produce from 'immer';
import NetInfo from '@react-native-community/netinfo';
import { showMessage } from 'react-native-flash-message';
import CNST from '../constants';
import Api from '../../utils/apiMiddleware';
import { Config } from '../../config';
import { generateId } from '../../utils/helpers';

import { Color } from '../../theme';

function saveInjuryReportingOffline(ticket, dispatch) {
    ticket.unsaved = true;
    if (!ticket.unsavedId) {
        ticket.unsavedId = generateId();
    }
    showMessage({
        message: 'Error',
        description:
            // eslint-disable-next-line max-len
            'You do not currently have a data connection. This Injury Report will be saved on your phone and uploaded as soon as you have a connection.',
        type: 'danger',
        icon: 'danger',
        position: 'right',
        hideStatusBar: true,
        duration: 1500,
        backgroundColor: Color.red,
    });
    dispatch({
        type: CNST.INJURY_REPORTING.SUBMIT_TICKET.ERROR,
        injuryReporting: ticket,
    });
}

export function injuryReportLoading() {
    return (dispatch) => {
        dispatch({
            type: CNST.INJURY_REPORTING.LOADING.START,
        });
    };
}

export function injuryReportFinishLoading() {
    return (dispatch) => {
        dispatch({
            type: CNST.INJURY_REPORTING.LOADING.FINISH,
        });
    };
}

export function getInjuryTicketTypes() {
    return (dispatch) => {
        dispatch({
            type: CNST.INJURY_REPORTING.LOADING.START,
        });
        return Api()
            .get(`/sources/${Config.INJURY_REPORTING_TYPES}/data`, { limit: 500 })
            .then((response) => {
                dispatch({
                    type: CNST.INJURY_REPORTING.GET_TYPES,
                    data: response.data,
                });
                return dispatch({
                    type: CNST.INJURY_REPORTING.LOADING.FINISH,
                });
            })
            .catch(() => {
                showMessage({
                    message: 'Error',
                    description:
                        "You do not currently have a data connection. We are not able to update the types of injury's",
                    type: 'danger',
                    icon: 'danger',
                    position: 'right',
                    hideStatusBar: true,
                    duration: 1500,
                    backgroundColor: Color.red,
                });
                return dispatch({
                    type: CNST.INJURY_REPORTING.LOADING.FINISH,
                });
            });
    };
}

export function getInjuryTickets(userId) {
    return (dispatch) => {
        return Api()
            .get(`/sources/${Config.INJURY_REPORTING_TICKETS}/data`, { limit: 500 })
            .then((response) => {
                const myCards = response.data.filter((d) => d.User === userId);
                dispatch({
                    type: CNST.INJURY_REPORTING.GET_TICKETS,
                    data: myCards,
                });
                return myCards;
            })
            .catch(() => {
                showMessage({
                    message: 'Error',
                    description:
                        'You do not currently have a data connection. We are not able to update the injury tickets.',
                    type: 'danger',
                    icon: 'danger',
                    position: 'right',
                    hideStatusBar: true,
                    duration: 1500,
                    backgroundColor: Color.red,
                });
                return dispatch({
                    type: CNST.INJURY_REPORTING.LOADING.FINISH,
                });
            });
    };
}

export function setInjuryTicket(item) {
    return (dispatch) => {
        dispatch({
            type: CNST.INJURY_REPORTING.SET_INJURY_TICKET,
            item,
        });
    };
}

export function createInjuryTicket(data = {}) {
    const { user } = data;
    return (dispatch) => {
        return dispatch({
            type: CNST.INJURY_REPORTING.SET_INJURY_TICKET,
            item: {
                id: null,
                User: user._id,
                InjuryType: null,
                Project: null,
                ProjectManager: null,
                WhoSawIt: null,
                Answers: [],
                TicketStatus: 'U',
                AdminFeedback: null,
            },
        });
    };
}

export function saveInjuryTicket(data = {}) {
    const { ticket, user } = data;
    const injuryReportingTicket = {
        User: ticket.User,
        InjuryType: ticket.InjuryType,
        Project: ticket.Project,
        ProjectManager: ticket.ProjectManager,
        WhoSawIt: ticket.WhoSawIt,
        Answers: ticket.Answers,
        AdminFeedback: ticket.AdminFeedback,
    };
    return (dispatch) => {
        dispatch({ type: CNST.INJURY_REPORTING.SUBMIT_TICKET.LOADING });
        const isNew = ticket.id === null;
        return NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                return Api()
                    .put(`/sources/${Config.INJURY_REPORTING_TICKETS}/data/${isNew ? 'new' : ticket.id}`, {
                        data: injuryReportingTicket,
                        modifiedBy: user.username,
                    })
                    .then((response) => {
                        showMessage({
                            message: 'Success',
                            description: 'Your Injury Reporting has been uploaded',
                            type: 'success',
                            position: 'right',
                            hideStatusBar: true,
                            duration: 1500,
                            backgroundColor: Color.turquoise,
                        });

                        dispatch({
                            type: CNST.INJURY_REPORTING.SUBMIT_TICKET.SUCCESS,
                            item: response,
                            unsavedTicketId: ticket.unsavedId,
                        });
                        return true;
                    })
                    .catch(() => {
                        saveInjuryReportingOffline(ticket, dispatch);
                        return false;
                    });
            }
            saveInjuryReportingOffline(ticket, dispatch);
            return false;
        });
    };
}

export function setInjuryReportProject(project) {
    return (dispatch) => {
        dispatch({
            type: CNST.INJURY_REPORTING.SET_PROJECT,
            project,
        });
    };
}

export function finishEditing() {
    return (dispatch) => {
        dispatch({
            type: CNST.INJURY_REPORTING.FINISH_EDITING,
        });
    };
}

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = {
    injuryTicketTypes: [],
    injuryTickets: [],
    currentTicket: null,
    isLoading: false,
    isInjuryReportEdit: false,
};

export default produce((state, action) => {
    switch (action.type) {
        case CNST.INJURY_REPORTING.GET_TYPES:
            state.injuryTicketTypes = action.data;
            break;
        case CNST.INJURY_REPORTING.GET_TICKETS:
            state.injuryTickets = action.data;
            break;
        case CNST.INJURY_REPORTING.SET_PROJECT: {
            state.currentTicket.Project = action.project;
            break;
        }
        case CNST.INJURY_REPORTING.SET_INJURY_TICKET:
            state.currentTicket = action.item;
            state.isInjuryReportEdit = true;
            break;
        case CNST.INJURY_REPORTING.SUBMIT_TICKET.SUCCESS: {
            const { item, unsavedTicketId } = action;
            const currentTicketIndex = state.injuryTickets.findIndex((ticket) => {
                return (item.id && ticket.id === item.id) || (unsavedTicketId && ticket.unsavedId === unsavedTicketId);
            });

            if (currentTicketIndex >= 0) {
                state.injuryTickets[currentTicketIndex] = item;
            } else {
                state.injuryTickets.push(item);
            }
            state.isLoading = false;
            break;
        }
        case CNST.INJURY_REPORTING.SUBMIT_TICKET.ERROR: {
            const { injuryReporting } = action;
            const currentTicketIndex = state.injuryTickets.findIndex((ticket) => {
                return (
                    (injuryReporting.id && ticket.id === injuryReporting.id) ||
                    (injuryReporting.unsavedId && ticket.unsavedId === injuryReporting.unsavedId)
                );
            });

            if (currentTicketIndex >= 0) {
                state.injuryTickets[currentTicketIndex] = injuryReporting;
            } else {
                state.injuryTickets.push(injuryReporting);
            }

            state.isLoading = false;
            break;
        }
        case CNST.INJURY_REPORTING.LOADING.START:
            state.isLoading = true;
            break;
        case CNST.INJURY_REPORTING.LOADING.FINISH:
            state.isLoading = false;
            break;

        case CNST.INJURY_REPORTING.FINISH_EDITING:
            state.isInjuryReportEdit = false;
            break;
        case CNST.ACCOUNT.LOGOUT.SUCCESS: {
            const newState = cloneDeep(initialState);
            newState.injuryTickets = state.injuryTickets.filter((r) => r.unsaved);
            return newState;
        }
        default:
            break;
    }
}, initialState);
