import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

import DropdownCalendarInput from './DropdownCalendarInput';

export default class DropdownCalendar extends Component {
    static propTypes = {
        range: PropTypes.object,
        type: PropTypes.string,
        mode: PropTypes.string,
        date: PropTypes.object,
        itemDate: PropTypes.object,
        startTime: PropTypes.object,
        endTime: PropTypes.object,
        placeholder: PropTypes.string,
        containerStyle: PropTypes.array,
        topLabel: PropTypes.bool,
        onSelect: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    returnDateValidationMax() {
        const { range, type, endTime } = this.props;

        if (type === '1' && range.to) {
            return moment(range.to).toDate();
        }

        if ((type === '1' || type === '2') && endTime) {
            return moment(endTime).toDate();
        }

        return null;
    }

    returnDateValidationMin() {
        const { range, type, startTime } = this.props;

        // from
        if (type === '1') {
            return moment(startTime).toDate();
        }

        // to
        if (type === '2' && range.from) {
            return moment(range.from).toDate();
        }

        if (type === '2') {
            return moment(startTime).toDate();
        }

        if (type === '0') {
            return moment().add(14, 'd').startOf('week').toDate();
        }

        return null;
    }

    handleDateChange = (date) => {
        const { mode, onSelect } = this.props;

        if (mode === 'date') {
            return onSelect(moment(date).startOf('day').toDate());
        }

        onSelect(date);
    };

    render() {
        const { date, itemDate, placeholder, containerStyle, topLabel, mode } = this.props;

        const showTime = mode === 'datetime' || mode === 'time';
        const onlyTime = mode === 'time';

        let dateFormat;
        if (date && mode === 'datetime') {
            dateFormat = 'MM/dd/yyyy hh:mm:ss aa';
        } else if (date && mode === 'time') {
            dateFormat = 'hh:mm:ss aa';
        } else {
            dateFormat = 'MM-dd-yyyy';
        }

        return (
            <DatePicker
                showTimeSelect={showTime}
                showTimeSelectOnly={onlyTime}
                timeIntervals={1}
                showPopperArrow={false}
                placeholderText={placeholder}
                todayButton="Today"
                selected={date ? moment(date).toDate() : itemDate}
                minDate={this.returnDateValidationMin()}
                maxDate={this.returnDateValidationMax()}
                onChange={this.handleDateChange}
                dateFormat={dateFormat}
                timeFormat="hh:mm aa"
                customInput={
                    <DropdownCalendarInput
                        mode={mode}
                        date={date}
                        containerStyle={containerStyle}
                        topLabel={topLabel}
                        inputRef={this.inputRef}
                    />
                }
            />
        );
    }
}
