import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    buttonWrapper: {
        width: 165,
        height: 26,
        backgroundColor: Color.turquoise,
        borderRadius: 13,
        padding: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    buttonWrapper_two: {
        width: 111,
    },
    buttonWrapper_big: {
        width: 218,
        height: 50,
        borderRadius: 25,
        padding: 2,
    },
    button: {
        width: 53.5,
        height: '100%',
        backgroundColor: Color.white,
        justifyContent: 'center',
        alignItems: 'center',
    },
    button_first: {
        borderBottomLeftRadius: 13,
        borderTopLeftRadius: 13,
    },
    button_first_big: {
        width: 106,
        borderBottomLeftRadius: 25,
        borderTopLeftRadius: 25,
    },
    button_mid_big: {
        width: 106,
    },
    button_last: {
        borderBottomRightRadius: 13,
        borderTopRightRadius: 13,
    },
    button_last_big: {
        width: 106,
        borderBottomRightRadius: 25,
        borderTopRightRadius: 25,
    },
    button_active: {
        backgroundColor: Color.turquoise,
    },
    buttonText: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font11,
        color: Color.turquoise,
    },
    buttonText_active: {
        color: Color.white,
    },
});
