import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../../theme';

export default StyleSheet.create({
    listItemWrapper: {
        width: '90%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 13,
        paddingTop: 12,
        paddingBottom: 17,
        marginBottom: 10,
        backgroundColor: Color.white,
        borderRadius: 6,
        elevation: 5,
        alignSelf: 'center',
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    listItemBody: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    listItemInfo: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    listItemInfoItem: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        marginRight: 20,
    },
    listItemInfoData: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font10,
        color: Color.light_grey_blue,
    },
    listItemTitle: {
        color: Color.dark_navy_blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
    },
    successText: {
        fontFamily: Font.type.bold,
        color: Color.dark_navy_blue,
        fontSize: Font.size.font9,
        marginRight: 3,
        fontWeight: 'bold',
    },
    successText_ready: {
        color: Color.turquoise,
    },
    successText_error: {
        color: Color.pinkish_orange,
    },
    successText_pending: {
        color: 'orange',
    },
    commentText: {
        color: Color.light_grey_blue,
        fontSize: Font.size.font9,
        fontFamily: Font.type.regular,
        marginLeft: 5,
    },
    cardType: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: Color.light_navy,
        height: 17,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 8,
        marginTop: 12,
    },
    cardTypeText: {
        color: Color.blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font8,
    },
    cardTypeOffline: {
        borderColor: '#ffecb2',
        backgroundColor: '#fefbef',
        marginLeft: 10,
    },
    cardTypeOfflineText: {
        color: '#f3a34d',
        fontFamily: Font.type.regular,
        fontSize: Font.size.font8,
    },
});
