export default {
    GET_SHIFTS: {
        LOADING: 'GET_SHIFTS_LOADING',
        SUCCESS: 'GET_SHIFTS_SUCCESS',
        ERROR: 'GET_SHIFTS_ERROR',
    },
    GET_AVAILABLE_SHIFTS: {
        LOADING: 'GET_AVAILABLE_SHIFTS_LOADING',
        SUCCESS: 'GET_AVAILABLE_SHIFTS_SUCCESS',
        ERROR: 'GET_AVAILABLE_SHIFTS_ERROR',
    },
    GET_ALL_SHIFTS: {
        SUCCESS: 'GET_ALL_SHIFTS_SUCCESS',
    },
    UPDATE_SHIFT: {
        LOADING: 'UPDATE_SHIFT_LOADING',
        SUCCESS: 'UPDATE_SHIFT_SUCCESS',
        ERROR: 'UPDATE_SHIFT_ERROR',
    },
    SUBMIT_SHIFT_TIME_OFF_REQUEST: {
        LOADING: 'SUBMIT_SHIFT_TIME_OFF_REQUEST_LOADING',
        SUCCESS: 'SUBMIT_SHIFT_TIME_OFF_REQUEST_SUCCESS',
        ERROR: 'SUBMIT_SHIFT_TIME_OFF_REQUEST_ERROR',
    },
    SELECT_CALENDAR_DATE: 'SELECT_CALENDAR_DATE',
};
