import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        paddingTop: 20,
        paddingBottom: 20,
        height: '100%',
        width: '100%',
        paddingHorizontal: '5%',
    },
    title: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue,
        textAlign: 'left',
        paddingLeft: 18.5,
        marginTop: 14,
    },
    listTitle: {
        color: Color.blue,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font16,
        marginBottom: 12.5,
        marginTop: 4,
        alignSelf: 'center',
    },
});
