import React, { Component } from 'react';
import { View, Text, ImageBackground } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { showMessage } from 'react-native-flash-message';
import { NavigationBar } from '../../../components';
import s from './styles';
import { Img, Color } from '../../../theme';
import { ResetPasswordForm } from '../../../forms';
import { resetPassword, userLogin } from '../../../store/modules/account';

class ResetPasswordScreen extends Component {
    static propTypes = {
        form: PropTypes.object,
        navigation: PropTypes.object,
        isLoading: PropTypes.bool,
        resetPassword: PropTypes.func,
        userLogin: PropTypes.func,
    };

    constructor(props) {
        super(props);
    }

    navigateToLogin = () => {
        const { navigation } = this.props;
        navigation.navigate('Login');
    };

    onSubmit = () => {
        const { form, resetPassword, navigation, userLogin } = this.props;
        const resetEmail = navigation.getParam('email');
        const resetKey = navigation.getParam('resetKey');
        if (!isEmpty(form.values)) {
            const { password, confirmPassword } = form.values;
            if (password === confirmPassword) {
                resetPassword({ resetKey, resetEmail, password, confirmPassword }).then((res) => {
                    if (res.message.includes('Success')) {
                        userLogin({ email: resetEmail, password });
                        showMessage({
                            message: res.message,
                            description: 'Your password has been changed',
                            type: 'danger',
                            duration: 5000,
                            autoHide: true,
                            hideOnPress: true,
                            hideStatusBar: true,
                            backgroundColor: Color.red,
                        });
                    } else {
                        showMessage({
                            message: 'Error',
                            description: res.message,
                            type: 'danger',
                            duration: 5000,
                            autoHide: true,
                            hideOnPress: true,
                            hideStatusBar: true,
                            backgroundColor: Color.red,
                        });
                    }
                });
            } else {
                showMessage({
                    message: 'Error',
                    description: 'The passwords must be the same',
                    type: 'danger',
                    duration: 5000,
                    autoHide: true,
                    hideOnPress: true,
                    hideStatusBar: true,
                    backgroundColor: Color.red,
                });
            }
        } else {
            showMessage({
                message: 'Error',
                description: 'You must have a password entered',
                type: 'danger',
                duration: 5000,
                autoHide: true,
                hideOnPress: true,
                hideStatusBar: true,
                backgroundColor: Color.red,
            });
        }
    };

    render() {
        const { navigation, isLoading } = this.props;
        return (
            <ImageBackground source={Img.bg} style={[s.wrapper]}>
                <NavigationBar {...this.props} title="RESET YOUR PASSWORD" backIcon />
                <View style={[s.mainContainer]} alignItems="center">
                    <Text style={s.forgotPasswordTitle}>Please enter the new password that you would like to use.</Text>
                    <ResetPasswordForm
                        isLoading={isLoading}
                        handleSubmit={this.onSubmit}
                        navigation={navigation}
                        containerStyle={{ width: '100%', paddingHorizontal: 30 }}
                    />
                </View>
            </ImageBackground>
        );
    }
}

const mapStateToProps = ({ form, account }) => ({
    form: form.resetPassword,
    isLoading: account.isLoading,
});

export default connect(mapStateToProps, {
    resetPassword,
    userLogin,
})(ResetPasswordScreen);
