import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../../theme';

export default StyleSheet.create({
    listItemWrapper: {
        width: '100%',
        height: 68.5,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        paddingHorizontal: 13,
        paddingVertical: 17,
        marginBottom: 10,
        backgroundColor: Color.white,
        borderRadius: 6,
        elevation: 5,
    },
    checkBox: {
        width: 15,
        height: 15,
        backgroundColor: Color.light_grey,
        borderRadius: 2,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 12.5,
    },
    checkBox_checked: {
        backgroundColor: Color.light_navy,
    },
    iconChecked: {
        color: Color.white,
        fontSize: Font.size.font9,
    },
    listItemBody: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    listItemInfo: {
        flexDirection: 'row',
        width: '100%',
    },
    listItemInfoItem: {
        flexDirection: 'row',
        marginRight: 20,
    },
    listItemInfoData: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font10,
        color: Color.light_grey_blue,
    },
    listItemInfoIcon: {
        fontSize: Font.size.font9,
        color: Color.light_grey_blue,
        marginTop: 3,
        marginRight: 2,
    },
    listItemTitle: {
        color: Color.dark_navy_blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        width: '100%',
    },
    chevronRight: {
        fontSize: Font.size.font24,
        color: Color.light_grey_blue,
    },
    successText: {
        fontFamily: Font.type.bold,
        color: Color.light_grey_blue,
        fontSize: Font.size.font9,
    },
    successText_ready: {
        color: Color.turquoise,
    },
});
