import { StyleSheet, Platform } from 'react-native';
import { Font, Color } from '../../../theme/index';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        overflow: 'scroll',
        paddingTop: 20,
        paddingHorizontal: '5%',
        paddingBottom: 100,
        alignItems: 'center',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1,
    },
    questionRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 20,
        width: '100%',
    },
    label: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font11,
        color: Color.light_grey_blue,
        marginRight: '25%',
    },
    blockTitle: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font11,
        color: Color.light_grey_blue,
        marginBottom: 20,
    },
    formWrapper: {
        width: '100%',
        borderRadius: 5,
        backgroundColor: Color.white,
        padding: 20,
    },
    modalWrapper: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.dark_navy_blue_50,
    },
    modalForm: {
        width: '80%',
    },
    modalTitle: {
        fontFamily: Font.type.bold,
        marginBottom: 20,
    },
    modalButtonsWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 20,
    },
    modalButton: {
        marginRight: 20,
    },
    shapesWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    shapeModal: {
        padding: 11,
    },
    selectShape: {
        backgroundColor: Color.light_navy_10,
    },
    notes: {
        marginTop: '30%',
    },
    bottomButtonsBlock: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        position: 'absolute',
        marginLeft: '35%',
        paddingTop: '10%',
    },
    addButton: {
        width: 43,
        height: 43,
        borderRadius: 22,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.light_navy,
        elevation: 12,
        shadowColor: Color.light_grey_blue,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.5,
        shadowRadius: 5,
    },
    buttonWrapper: {
        height: 50,
        width: 180,
        marginTop: 20,
        alignSelf: 'center',
    },
    formItem: {
        width: '100%',
    },
    calendarDropdown: {
        marginBottom: 10,
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    listItemWrapper: {
        width: '100%',
        marginBottom: 10,
        backgroundColor: Color.white,
        borderRadius: 6,
        padding: 20,
        justifyContent: 'center',
        elevation: 5,
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    listItemInternalWrapper: {
        marginBottom: 10,
        backgroundColor: Color.white,
        borderRadius: 6,
        borderStyle: 'dashed',
        borderWidth: 2,
        borderColor: Color.light_grey,
        justifyContent: 'center',
        padding: 20,
        width: '100%',
    },
    listItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        width: '100%',
    },
    listItemLeft: {
        width: '90%',
    },
    listItemRight: {
        width: '10%',
    },
    fieldInternalStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 50,
        width: '100%',
        elevation: 0,
        backgroundColor: Color.white,
        borderRadius: 8,
        borderColor: Color.light_grey,
        borderWidth: 2,
        marginBottom: 10,
        paddingHorizontal: 10,
    },
    fieldInt: {
        borderColor: Color.light_grey,
        borderWidth: 2,
        height: 50,
        shadowColor: Color.transparent,
    },
    dropdownInternal: {
        width: '100%',
        elevation: 0,
        backgroundColor: Color.white,
        borderRadius: 8,
        borderColor: Color.light_grey,
        borderWidth: 2,
        marginBottom: 10,
        paddingHorizontal: 10,
        paddingVertical: 8,
        shadowColor: Color.transparent,
    },
    touchField: {
        paddingVertical: 5,
    },
    field30: {
        width: '32%',
    },
    field40: {
        width: '39%',
    },
    field50: {
        width: '49%',
    },
    field60: {
        width: '59%',
    },
    shapeOutline: {
        marginTop: 7,
        marginRight: -15,
    },
    buttonCancel: {
        height: 50,
        backgroundColor: Color.white,
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: Color.turquoise,
        borderRadius: 7.5,
        alignItems: 'center',
        paddingTop: 17,
    },
    buttonTextCancel: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.turquoise,
    },
    removeButton: {
        borderColor: Color.pinkish_orange,
        borderStyle: 'solid',
        borderWidth: 2,
        height: 25,
        width: 25,
        alignItems: 'center',
        borderRadius: 12.5,
    },
    removeButtonContainer: {
        marginLeft: 20,
    },
    attachmentsWrapper: {
        width: '100%',
        flexDirection: 'row',
        marginTop: 10,
        flexWrap: 'wrap',
    },
    attachmentItem: {
        height: 150,
        width: 150,
        marginRight: 10,
        marginBottom: 10,
    },
    attachmentImage: {
        height: '100%',
        width: '100%',
    },
    attachmentButton: {
        width: 30,
        height: 30,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        right: 5,
        top: 5,
        backgroundColor: Color.pale_grey,
    },
});
