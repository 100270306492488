import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, TouchableOpacity, Modal, Text } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import CalendarPicker from 'react-native-calendar-picker';
import MonthSelectorCalendar from 'react-native-month-selector';
import WeekSelector from 'react-native-week-selector';

import PrimaryButton from '../PrimaryButton/PrimaryButton';
import LinkButton from '../LinkButton/LinkButton';
import s from './styles';
import { Color } from '../../theme';
import FILTERS from '../../config/filtersCNST';
// const Icon = createIconSetFromIcoMoon(icoMoonConfig);

const filters = [FILTERS.ALL_TIME, FILTERS.MONTH, FILTERS.WEEK, FILTERS.DAY];

export default class Filter extends Component {
    static propTypes = {
        currentFilter: PropTypes.string,
        filterValue: PropTypes.object,
        filterIcon: PropTypes.element,
        onFilterSelect: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            isDropdownOpen: false,
            selectedFilter: props.currentFilter,
            filterValue: props.filterValue,
            calendarWidth: 0,
        };
    }

    _returnCurrentFilter() {
        const { selectedFilter, filterValue, calendarWidth } = this.state;
        if (selectedFilter === FILTERS.MONTH) {
            return (
                <MonthSelectorCalendar
                    selectedDate={filterValue || moment()}
                    onMonthTapped={(month) => {
                        this.setState({ filterValue: month });
                    }}
                    selectedBackgroundColor={Color.cobalt_50}
                    containerStyle={s.monthContainer}
                    yearTextStyle={s.monthTextStyle}
                    monthTextStyle={[s.monthTextStyle, { borderRadius: 30 }]}
                    selectedMonthStyle={{ borderRadius: 30 }}
                    monthDisabledStyle={s.monthTextStyle_disabled}
                    currentMonthTextStyle={s.currentMonthTextStyle}
                    nextIcon={<Icon name="chevron-right" size={28} color={Color.turquoise} />}
                    prevIcon={<Icon name="chevron-left" size={28} color={Color.turquoise} />}
                />
            );
        }
        if (selectedFilter === FILTERS.WEEK) {
            return (
                <WeekSelector
                    date={filterValue || moment()}
                    ref={(c) => {
                        this.weekSelector = c;
                    }}
                    containerStyle={s.weekSelector}
                    selectorContainerStyle={s.weekSelectorContainer}
                    dateContainerStyle={s.weekSelectorDateContainer}
                    textStyle={s.weekSelectorText}
                    onWeekChanged={(week) => {
                        this.setState({ filterValue: week });
                    }}
                    renderPreviousSelector={() => {
                        return <Icon name="chevron-left" size={28} color={Color.turquoise} />;
                    }}
                    renderNextSelector={() => {
                        return <Icon name="chevron-right" size={28} color={Color.turquoise} />;
                    }}
                />
            );
        }
        if (selectedFilter === FILTERS.DAY) {
            return (
                <View
                    style={s.dayPickerContainer}
                    onLayout={(event) => this.setState({ calendarWidth: event.nativeEvent.layout.width })}
                >
                    {calendarWidth !== 0 && (
                        <CalendarPicker
                            startFromMonday
                            width={calendarWidth}
                            textStyle={s.monthTextStyle}
                            disabledDatesTextStyle={s.monthTextStyle_disabled}
                            todayTextStyle={s.currentMonthTextStyle}
                            todayBackgroundColor={Color.white}
                            selectedDayColor={Color.cobalt_50}
                            selectedDayTextColor={Color.white}
                            onDateChange={(date) => this.setState({ filterValue: date })}
                            initialDate={filterValue || moment()}
                            maxDate={moment()}
                        />
                    )}
                </View>
            );
        }

        return null;
    }

    render() {
        const { onFilterSelect, filterIcon } = this.props;

        const { isModalOpen, isDropdownOpen, selectedFilter, filterValue } = this.state;
        return (
            <View>
                <TouchableOpacity onPress={() => this.setState({ isModalOpen: true })}>
                    {filterIcon || <Icon name="filter-variant" color={Color.turquoise} size={25} />}
                </TouchableOpacity>
                <Modal visible={isModalOpen} transparent presentationStyle="overFullScreen">
                    <View style={s.modalWrapper}>
                        <View style={s.modal}>
                            <Text style={s.modalTitle}>FILTER BY DATE</Text>
                            <View style={s.dropdownContainer}>
                                <TouchableOpacity
                                    onPress={() => {
                                        this.setState({ isDropdownOpen: true });
                                    }}
                                >
                                    <View style={s.touchableRow}>
                                        <Text style={s.dropdownLabel}>{selectedFilter}</Text>
                                        <Icon name="chevron-down" size={25} color={Color.turquoise} />
                                    </View>
                                </TouchableOpacity>
                                <Modal visible={isDropdownOpen} transparent>
                                    <View style={s.container}>
                                        <View style={s.dropdownWrapper}>
                                            {filters.map((item, i) => {
                                                return (
                                                    <TouchableOpacity
                                                        onPress={() => {
                                                            this.setState({
                                                                isDropdownOpen: false,
                                                                selectedFilter: item,
                                                            });
                                                        }}
                                                        style={s.dropDownItem}
                                                        key={i}
                                                    >
                                                        <Text style={s.dropDownItemText}>{item}</Text>
                                                    </TouchableOpacity>
                                                );
                                            })}
                                        </View>
                                    </View>
                                </Modal>
                            </View>
                            {selectedFilter !== FILTERS.ALL_TIME && this._returnCurrentFilter()}
                            <View style={s.modalButtons}>
                                <View style={s.cancelModalButton}>
                                    <LinkButton
                                        title="CANCEL"
                                        onPress={() => {
                                            this.setState({ isModalOpen: false });
                                        }}
                                    />
                                </View>
                                <View style={s.submitModalButton}>
                                    <PrimaryButton
                                        md
                                        title="APPLY"
                                        onPress={() => {
                                            onFilterSelect(selectedFilter, filterValue);
                                            this.setState({ isModalOpen: false });
                                        }}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                </Modal>
            </View>
        );
    }
}
