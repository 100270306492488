import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    modalWrapper: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.dark_navy_blue_50,
    },
    attachmentModal: {
        backgroundColor: Color.white,
        borderRadius: 10,
        width: '90%',
    },
    attachmentModalTitle: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font18,
        color: Color.purple_brown,
        paddingHorizontal: 22.5,
        paddingVertical: 23,
        borderBottomWidth: 1,
        borderBottomColor: Color.pale_grey_two,
        textAlign: 'center',
    },
    attachmentItem: {
        width: '100%',
        height: 50,
        paddingHorizontal: 22.5,
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: Color.pale_grey_two,
    },
    attachmentPlusIconWrapper: {
        backgroundColor: Color.white,
        borderRadius: 6,
        width: 12,
        height: 12,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        right: 0,
        bottom: 0,
    },
    attachmentTitle: {
        fontFamily: Font.type.regular,
        color: Color.light_navy,
        fontSize: Font.size.font12,
        marginLeft: 15,
        width: '100%',
    },
    cancelModalButton: {
        width: '100%',
        paddingVertical: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalButton: {
        width: '100%',
        height: 185,
        borderWidth: 1,
        borderColor: Color.light_navy,
        borderRadius: 7.5,
    },
    modalButtonText: {
        fontFamily: Font.type.bold,
        color: Color.light_navy,
        fontSize: Font.size.font13,
        textAlign: 'center',
        width: '90%',
        margin: '5%',
    },
    modalButtonIcon: {
        textAlign: 'center',
    },
});
