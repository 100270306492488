import React, { PureComponent } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';

import s from './styles';

export default class LinkButton extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        style: PropTypes.object,
        onPress: PropTypes.func,
    };

    render() {
        const { title, onPress, style } = this.props;
        return (
            <TouchableOpacity onPress={onPress}>
                <View style={[s.buttonWrapper, style && style]}>
                    <Text style={s.buttonText}>{title}</Text>
                </View>
            </TouchableOpacity>
        );
    }
}
