import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import { NavigationActions } from 'react-navigation';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import moment from 'moment';

import { NavigationBar, DropdownList, FieldInput } from '../../../components/index';

import { Color } from '../../../theme/index';
import s from './styles';

class ItemRequestAddNew extends Component {
    static propTypes = {
        navigation: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            items: [
                {
                    id: `${moment().unix()}`,
                    item: 'item1',
                    quantity: '1',
                },
            ],
        };
    }

    addItem = () => {
        const { items } = this.state;
        items.push({
            id: `${moment().unix()}`,
            item: '',
            quantity: '',
        });
        this.setState({ items });
    };

    removeItem = (id) => {
        const { items } = this.state;
        this.setState({ items: items.filter((a) => a.id !== id) });
    };

    render() {
        const { navigation } = this.props;
        const { items } = this.state;
        return (
            <View style={s.wrapper}>
                <NavigationBar {...this.props} title="ADD NEW TICKET" backIcon />
                <ScrollView style={s.mainContainer}>
                    <FieldInput
                        placeholder="TICKET NAME"
                        sm
                        input={{
                            onChange: () => {
                                this.setState();
                            },
                        }}
                        meta={{
                            error: null,
                            touched: false,
                        }}
                        editable
                        style={s.textInputWithPlaceholder}
                    />
                    <View style={s.listItemWrapper}>
                        <Text style={s.listItemTitle}>ITEMS AND QUANTITY</Text>
                        {items.map((f) => {
                            return (
                                <View key={f.id}>
                                    <DropdownList
                                        items={items}
                                        containerStyle={s.dropdownContainer}
                                        placeholder="ITEM"
                                        height="50%"
                                        withIds
                                    />
                                    <DropdownList
                                        items={items}
                                        containerStyle={s.dropdownContainer}
                                        placeholder="QUANTITY"
                                        height="50%"
                                        withIds
                                    />
                                    <TouchableOpacity style={s.removeButton} onPress={() => this.removeItem(f.id)}>
                                        <Icon name="minus" size={20} color={Color.pinkish_orange} />
                                    </TouchableOpacity>
                                </View>
                            );
                        })}
                        <TouchableOpacity onPress={() => this.addItem()}>
                            <View style={s.buttonCancel}>
                                <Text style={s.buttonTextCancel}>
                                    <Icon name="plus" size={15} />
                                    ADD NEW
                                </Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                    <FieldInput
                        placeholder="DESCRIPTION"
                        meta={{
                            error: null,
                            touched: false,
                        }}
                        editable
                        multiline
                        style={s.multilineTextInputWithPlaceholderLarge}
                    />

                    <TouchableOpacity
                        onPress={() => {
                            navigation.navigate(
                                'ItemRequest',
                                {},
                                NavigationActions.navigate({
                                    routeName: 'ItemRequestList',
                                }),
                            );
                        }}
                    >
                        <View style={s.button}>
                            <Text style={s.buttonText}>SAVE</Text>
                        </View>
                    </TouchableOpacity>
                </ScrollView>
            </View>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ItemRequestAddNew);
