import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, Modal, Image, Linking, ScrollView, RefreshControl } from 'react-native';
import { withNavigationFocus } from 'react-navigation';
import NetInfo from '@react-native-community/netinfo';
// import Pdf from 'react-native-pdf';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {
    getReports,
    searchReports,
    createDailyReport,
    setDailyReport,
    dailyReportLoading,
    dailyReportFinishLoading,
    setViewingProject,
} from '../../../store/modules/dailyReports';
import { NavigationBar, DailyReportListItem } from '../../../components/index';

import { convertTimeStringToSeconds } from '../../../utils/helpers';
import { Config } from '../../../config';
import s from './styles';
import { Color, Img } from '../../../theme';

class DailyReportsScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentProject: PropTypes.object,
        currentReport: PropTypes.object,
        currentCostCode: PropTypes.object,
        user: PropTypes.object,
        isFocused: PropTypes.bool,
        isLoading: PropTypes.bool,
        reports: PropTypes.array,
        getReports: PropTypes.func,
        dailyReportLoading: PropTypes.func,
        dailyReportFinishLoading: PropTypes.func,
        setDailyReport: PropTypes.func,
        createDailyReport: PropTypes.func,
        viewingProject: PropTypes.object,
        setViewingProject: PropTypes.func,
        viewingUserReports: PropTypes.object,
        searchReports: PropTypes.func,
    };

    state = {
        pdf: null,
        isPdfOpen: false,
        search: '',
    };

    componentDidMount() {
        const { currentProject, viewingProject, currentReport, navigation } = this.props;
        if (!isEmpty(currentProject) || !isEmpty(viewingProject)) {
            this.reloadReports();
        }
        if (!isEmpty(currentReport)) {
            navigation.navigate('DailyReport');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentProject, isFocused, viewingProject, viewingUserReports, reports } = this.props;
        const { search } = this.state;
        if (
            (!isEmpty(currentProject) &&
                !isEmpty(prevProps.currentProject) &&
                currentProject.id !== prevProps.currentProject.id) ||
            (!isEmpty(currentProject) && isEmpty(prevProps.currentProject)) ||
            (!isEmpty(viewingProject) && isEmpty(prevProps.viewingProject)) ||
            (!isEmpty(viewingProject) &&
                !isEmpty(prevProps.viewingProject) &&
                viewingProject.id !== prevProps.viewingProject.id) ||
            viewingUserReports !== prevProps.viewingUserReports ||
            search !== prevState.search
        ) {
            this.reloadReports();
            return;
        }

        if (
            (!prevProps.isFocused && isFocused && !isEmpty(currentProject)) ||
            reports.length !== prevProps.reports.length
        ) {
            this.reloadReports();
        }
    }

    reloadReports = () => {
        const {
            getReports,
            currentProject,
            dailyReportLoading,
            dailyReportFinishLoading,
            viewingProject,
            user,
            viewingUserReports,
            searchReports,
        } = this.props;
        const { search } = this.state;

        let projectId = null;
        const userId = viewingUserReports ? viewingUserReports._id : user._id;
        if (viewingProject) {
            // eslint-disable-next-line prefer-destructuring
            projectId = viewingProject.id;
        } else if (currentProject) {
            // eslint-disable-next-line prefer-destructuring
            projectId = currentProject.id;
        }
        if (projectId) {
            dailyReportLoading();
            NetInfo.fetch().then((state) => {
                const { isConnected } = state;
                if (isConnected) {
                    if (search && search.length > 0) {
                        searchReports(search).then(() => {
                            dailyReportFinishLoading();
                        });
                    } else {
                        getReports(projectId, userId).then(() => {
                            dailyReportFinishLoading();
                        });
                    }
                } else {
                    dailyReportFinishLoading();
                }
            });
        }
    };

    static returnTotalTime(estimates) {
        let time = 0;
        estimates.forEach((estimate) => {
            time += convertTimeStringToSeconds(estimate.UsedTime);
        });
        return time;
    }

    _returnSurveys() {
        const { currentCostCode, user } = this.props;
        const list = [];
        if (currentCostCode.RoleQuestionList) {
            currentCostCode.RoleQuestionList.forEach((role) => {
                if (role.Role === user.role) {
                    role.QuestionList.forEach((questionList) => {
                        const item = {
                            Question: questionList.Question,
                            FlaggedQuestion: questionList.FlaggedQuestion,
                            OutOfHouseReports: questionList.OutOfHouseReports,
                        };
                        list.push(item);
                    });
                }
            });
        }
        if (currentCostCode.GroupQuestionList) {
            currentCostCode.GroupQuestionList.forEach((group) => {
                if (group.Group === user.group._id) {
                    group.QuestionList.forEach((questionList) => {
                        const item = {
                            Question: questionList.Question,
                            FlaggedQuestion: questionList.FlaggedQuestion,
                            OutOfHouseReports: questionList.OutOfHouseReports,
                        };
                        list.push(item);
                    });
                }
            });
        }
        if (list.length === 0) {
            if (currentCostCode.DefaultQuestionList) {
                currentCostCode.DefaultQuestionList.forEach((defaultQuestionList) => {
                    const item = {
                        Question: defaultQuestionList.Question,
                        FlaggedQuestion: defaultQuestionList.FlaggedQuestion,
                        OutOfHouseReports: defaultQuestionList.OutOfHouseReports,
                    };
                    list.push(item);
                });
            }
        }
        return list.map((item) => {
            return {
                Label: item.Question,
                Value: 'N/A',
                Description: '',
                Attachment: [],
                FlaggedQuestion: item.FlaggedQuestion,
                OutOfHouseReports: item.OutOfHouseReports,
            };
        });
    }

    _returnItems() {
        const { currentProject, reports, viewingProject } = this.props;
        const { search } = this.state;
        let items = [];
        if (!isEmpty(currentProject) || !isEmpty(viewingProject)) {
            const project = viewingProject || currentProject;
            items = reports.reduce((filtered, report) => {
                if (
                    !search ||
                    (search &&
                        ((report.ProjectName && report.ProjectName.includes(search)) ||
                            report.WorkOrder.includes(search)))
                ) {
                    if (!filtered.projectType) {
                        filtered.projectType = project.ProjectType;
                    }

                    if (!filtered.projectReportList) {
                        filtered.projectReportList = [
                            {
                                projectName: report.ProjectName,
                                reportList: [
                                    {
                                        id: report.id,
                                        date: report.Date,
                                        completed: report.Completed,
                                        unsaved: report.unsaved,
                                        status: report.status,
                                        totalTime: DailyReportsScreen.returnTotalTime(report.UsedEstimates),
                                        onPress: () => this.setDailyReport(report),
                                        openPDF: !isEmpty(report.files) ? () => this.openPDF(report.files.pdf) : null,
                                        weather: {
                                            minTemp: report.Weather.MinTemp,
                                            maxTemp: report.Weather.MaxTemp,
                                            type: report.Weather.CurrentWeather,
                                        },
                                        userName: report.UserName,
                                        projectName: report.ProjectName,
                                    },
                                ],
                            },
                        ];
                    } else {
                        const index = filtered.projectReportList.findIndex((r) => r.projectName === report.ProjectName);

                        if (index === -1) {
                            filtered.projectReportList.push({
                                projectName: report.ProjectName,
                                reportList: [
                                    {
                                        id: report.id,
                                        date: report.Date,
                                        completed: report.Completed,
                                        unsaved: report.unsaved,
                                        status: report.status,
                                        totalTime: DailyReportsScreen.returnTotalTime(report.UsedEstimates),
                                        onPress: () => this.setDailyReport(report),
                                        openPDF: !isEmpty(report.files) ? () => this.openPDF(report.files.pdf) : null,
                                        weather: {
                                            minTemp: report.Weather.MinTemp,
                                            maxTemp: report.Weather.MaxTemp,
                                            type: report.Weather.CurrentWeather,
                                        },
                                        userName: report.UserName,
                                        projectName: report.ProjectName,
                                    },
                                ],
                            });
                        } else {
                            filtered.projectReportList[index].reportList.push({
                                id: report.id,
                                date: report.Date,
                                completed: report.Completed,
                                unsaved: report.unsaved,
                                status: report.status,
                                totalTime: DailyReportsScreen.returnTotalTime(report.UsedEstimates),
                                onPress: () => this.setDailyReport(report),
                                openPDF: !isEmpty(report.files) ? () => this.openPDF(report.files.pdf) : null,
                                weather: {
                                    minTemp: report.Weather.MinTemp,
                                    maxTemp: report.Weather.MaxTemp,
                                    type: report.Weather.CurrentWeather,
                                },
                                userName: report.UserName,
                                projectName: report.ProjectName,
                                workOrderNumber: report.WorkOrder,
                            });
                        }
                    }
                }

                return filtered;
            }, []);
        }
        return items;
    }

    openPDF(pdf) {
        this.setState({ pdf, isPdfOpen: true });
    }

    setDailyReport = (report) => {
        const { setDailyReport, navigation } = this.props;
        setDailyReport(report);
        navigation.navigate('DailyReport');
    };

    createDailyReport = () => {
        const { createDailyReport, navigation, currentProject, user, setViewingProject } = this.props;
        const estimates = [];
        currentProject.ProjectEstimates.forEach((projectEstimates) => {
            const index = estimates.map((e) => e.CostCode).indexOf(projectEstimates.CostCode);
            if (projectEstimates.UsedTime) {
                let decimalTime = parseFloat(projectEstimates.UsedTime);
                decimalTime = decimalTime * 60 * 60;
                const hours = Math.floor(decimalTime / (60 * 60));
                decimalTime -= hours * 60 * 60;
                const minutes = Math.floor(decimalTime / 60);
                decimalTime -= minutes * 60;
                const seconds = Math.round(decimalTime);
                const usedTime = hours * 3600 + minutes * 60 + seconds;
                if (index === -1) {
                    estimates.push({
                        CostCode: projectEstimates.CostCode,
                        UsedTime: usedTime,
                    });
                } else {
                    estimates[index].UsedTime += usedTime;
                }
            }
        });
        for (let i = 0; i < estimates.length; i++) {
            estimates[i].UsedTime = `${(estimates[i].UsedTime / 60).toFixed(1)}m`;
        }
        const date = moment().format('MM-DD-YYYY');
        const surveys = this._returnSurveys(estimates);
        setViewingProject(currentProject);
        createDailyReport({ estimates, user, date, surveys });
        navigation.navigate('DailyReport');
    };

    goToSite = () => {
        Linking.openURL('https://darksky.net/poweredby/');
    };

    search = (newSearch) => {
        const { search } = this.state;

        const searchString = newSearch || newSearch === '' ? newSearch : search;
        this.setState({ search: searchString });
    };

    render() {
        const { navigation, currentProject, currentCostCode, isLoading, viewingProject, user, viewingUserReports } =
            this.props;
        const { isPdfOpen } = this.state;
        const dropdownMenuItems = [
            {
                title: 'Gallery',
                onPress: () => navigation.navigate('Gallery'),
            },
        ];
        let userPhoto = Img.noUserPhoto;
        if (viewingUserReports && viewingUserReports.meta && viewingUserReports.meta.UserPhoto) {
            userPhoto = viewingUserReports.meta.UserPhoto;
        } else if (!viewingUserReports && user && user.meta && user.meta.UserPhoto) {
            userPhoto = user.meta.UserPhoto;
        }

        let name = '';
        if (user) {
            name = user.fullName;
        }

        const projectLabel = Config.USE_DEPARTMENTS_JOBS ? 'department' : 'project';
        const costCodeLabel = Config.USE_DEPARTMENTS_JOBS ? 'job' : 'cost code';

        const items = this._returnItems();
        return (
            <View style={[s.wrapper]}>
                <NavigationBar
                    {...this.props}
                    title="DASHBOARD"
                    backIcon
                    dropdownMenu={dropdownMenuItems}
                    searchIcon
                    onSearch={(t) => this.search(t)}
                />
                <View style={s.menuTopContainer}>
                    <Text style={s.topMenu_title_tracking}>{isEmpty(currentProject) ? null : 'Tracking'}</Text>
                    <Text style={s.topMenu_title}>
                        {isEmpty(currentProject) ? `Please clock in to a ${projectLabel}` : currentProject.ProjectName}
                    </Text>
                    <Text style={s.topMenu_title}>
                        {isEmpty(currentCostCode) ? `Please clock in to a ${costCodeLabel}` : currentCostCode.Title}
                    </Text>
                </View>
                <View style={s.menuTopContainerUsers}>
                    {userPhoto && typeof userPhoto === 'string' ? (
                        <Image
                            source={{
                                uri: userPhoto,
                            }}
                            style={s.topMenuUserPhoto}
                        />
                    ) : (
                        <Image source={Img.noUserPhoto} size={14} style={s.topMenuUserPhoto} />
                    )}

                    <Text style={s.topMenu_user}>
                        {viewingUserReports
                            ? `${viewingUserReports.name.first} ${viewingUserReports.name.last}`.trim()
                            : name}
                    </Text>
                    <TouchableOpacity style={s.editUser} onPress={() => navigation.navigate('Users')}>
                        <Icon name="pencil-outline" color={Color.turquoise} size={23} />
                    </TouchableOpacity>
                </View>

                <View style={[s.viewingProjectInfo]}>
                    <Text style={s.viewingProjectTitle}>{`VIEWING ${projectLabel.toUpperCase()} NAME`}</Text>
                    <Text style={s.viewingProject}>
                        {' '}
                        {viewingProject
                            ? viewingProject.ProjectName
                            : currentProject
                            ? currentProject.ProjectName
                            : `Please select a ${projectLabel}`}
                    </Text>

                    <TouchableOpacity
                        style={s.editProject}
                        onPress={() => {
                            navigation.navigate('SwitchProjectsViewing', {
                                standalone: false,
                                isDailyReport: true,
                                isTimeCardEdit: false,
                                isInjuryReportEdit: false,
                            });
                        }}
                    >
                        <Icon name="pencil-outline" color={Color.turquoise} size={23} />
                    </TouchableOpacity>
                </View>

                <View style={s.mainContainer}>
                    {items.projectReportList && (
                        <ScrollView
                            style={{
                                paddingTop: 20,
                            }}
                            contentContainerStyle={{ paddingBottom: 30 }}
                            refreshControl={<RefreshControl refreshing={isLoading} onRefresh={this.reloadReports} />}
                        >
                            {items.projectReportList.map((reports) => {
                                return reports.reportList.map((report, index) => {
                                    return (
                                        <React.Fragment key={report.id}>
                                            {items.projectType === 'W' && index === 0 && (
                                                <Text style={s.projectNames}>{report.projectName}</Text>
                                            )}
                                            <DailyReportListItem item={report} />
                                        </React.Fragment>
                                    );
                                });
                            })}
                        </ScrollView>
                    )}
                    {!isEmpty(currentProject) && !isEmpty(currentCostCode) && (
                        <View style={s.bottomButtonsBlock}>
                            <TouchableOpacity style={s.addButton} onPress={this.createDailyReport}>
                                <Icon name="plus" color={Color.white} size={30} />
                            </TouchableOpacity>
                            <TouchableOpacity style={s.weatherButton} onPress={this.goToSite}>
                                <Image source={Img.dark_sky_logo} style={s.weatherButtonImage} />
                            </TouchableOpacity>
                        </View>
                    )}
                </View>
                <Modal visible={isPdfOpen}>
                    <View style={s.pdfContainer}>
                        <TouchableOpacity
                            style={s.closeButton}
                            onPress={() => this.setState({ pdf: null, isPdfOpen: false })}
                        >
                            <Icon name="close" color={Color.white} size={30} />
                        </TouchableOpacity>
                    </View>
                </Modal>
            </View>
        );
    }
}

const mapStateToProps = ({ dailyReports, timeCards, account }) => ({
    reports: dailyReports.reports,
    currentProject: dailyReports.currentProject,
    currentReport: dailyReports.currentReport,
    viewingProject: dailyReports.viewingProject,
    viewingUserReports: dailyReports.viewingUserReports,
    currentCostCode: dailyReports.currentCostCode,
    isLoading: dailyReports.isLoading,
    timeCards: timeCards.timeCards,
    user: account.user,
});

export default withNavigationFocus(
    connect(mapStateToProps, {
        createDailyReport,
        setDailyReport,
        getReports,
        dailyReportLoading,
        dailyReportFinishLoading,
        setViewingProject,
        searchReports,
    })(DailyReportsScreen),
);
