import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ActivityIndicator, Text, TouchableOpacity, View, Image } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import { Color } from '../../theme';

import s from './styles';

export default class ProfilePhotoUploadModal extends Component {
    static propTypes = {
        isOpen: PropTypes.bool,
        isLoading: PropTypes.bool,
        onCancel: PropTypes.func,
        onSave: PropTypes.func,
    };

    state = {
        image: null,
    };

    takePicture = async () => {
        if (this.camera) {
            const options = {
                quality: 0.5,
                base64: true,
                mirrorImage: true,
                fixOrientation: true,
            };
            const data = await this.camera.takePictureAsync(options);

            this.setState({
                image: data.base64,
            });
        }
    };

    onSave = async () => {
        const { image } = this.state;
        const { onSave } = this.props;
        onSave(image);
    };

    onCancel = () => {
        const { image } = this.state;
        const { onCancel } = this.props;
        if (image) {
            this.setState({ image: null });
        } else {
            onCancel();
        }
    };

    render() {
        const { image } = this.state;
        const { isOpen, isLoading } = this.props;
        return (
            <Modal visible={isOpen} transparent>
                <View style={s.modalWrapper}>
                    <View style={s.profileImageUploadModal}>
                        <View style={s.photoWrapper}>
                            {image ? (
                                <Image source={{ uri: `data:image/jpeg;base64,${image}` }} style={s.attachmentImage} />
                            ) : (
                                <View />
                            )}
                        </View>
                        <View style={s.titleRow}>
                            <Text style={s.modalTitle}>Please take a photo to Clock In</Text>
                        </View>
                        <View style={s.buttonsRow}>
                            <View style={s.buttonWrapper}>
                                <TouchableOpacity onPress={this.onCancel}>
                                    <Text style={s.buttonText}>CANCEL</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={s.buttonWrapper}>
                                {isLoading && <ActivityIndicator size={40} color={Color.white_two} />}
                                {!image && (
                                    <TouchableOpacity onPress={this.takePicture}>
                                        <View style={s.takeShotButton}>
                                            <Icon name="camera" size={25} color={Color.blue} />
                                        </View>
                                    </TouchableOpacity>
                                )}
                            </View>
                            <View style={s.buttonWrapper}>
                                {image && (
                                    <TouchableOpacity onPress={this.onSave}>
                                        <Text style={s.buttonText}>SAVE</Text>
                                    </TouchableOpacity>
                                )}
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        );
    }
}
