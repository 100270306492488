import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty, cloneDeep } from 'lodash';

import { View, Text, TouchableOpacity } from 'react-native';

import { finishEditing, saveToolboxTalks } from '../../../store/modules/toolboxTalks';

import { NavigationBar, DropdownList } from '../../../components/index';
import s from './styles';

class ToolBoxTalkCreateTicketScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentTicket: PropTypes.object,
        currentProject: PropTypes.object,
        user: PropTypes.object,
        toolboxTypes: PropTypes.array,
        saveToolboxTalks: PropTypes.func,
        finishEditing: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { currentTicket } = props;
        if (!isEmpty(currentTicket)) {
            const toolBoxTalk = this.returnToolboxTypeItem(currentTicket.ToolBoxTalk);
            this.state = {
                toolBoxTalk,
            };
        }
    }

    returnToolboxTypeItem(id) {
        const { toolboxTypes } = this.props;
        let type = null;
        toolboxTypes.forEach((t) => {
            if (t._id === id) {
                type = {
                    id: t._id,
                    title: t.ToolBoxTalkName,
                };
            }
        });
        return type;
    }

    onCreate = () => {
        const { navigation, saveToolboxTalks, user, currentTicket, currentProject } = this.props;
        const { toolBoxTalk } = this.state;
        const ticket = cloneDeep(currentTicket);
        ticket.ToolBoxTalk = toolBoxTalk.id;
        ticket.ToolBoxTalkName = `${toolBoxTalk.title} ${currentProject.ProjectNumber} ${
            user.fullName
        } ${moment().format('MM-DD-YYYY')}`;
        ticket.FieldForeman = user._id;
        ticket.Project = currentProject.id;
        ticket.Date = moment().toDate();

        saveToolboxTalks({ user, ticket });
        navigation.navigate('ToolboxTalkList');
    };

    onCancel() {
        const { navigation, finishEditing } = this.props;
        navigation.navigate('ToolboxTalkList');
        finishEditing();
    }

    render() {
        const { toolboxTypes } = this.props;
        const { toolBoxTalk } = this.state;

        const types = toolboxTypes.map((t) => {
            return {
                id: t.id,
                title: t.ToolBoxTalkName,
            };
        });

        return (
            <View style={s.wrapper}>
                <NavigationBar {...this.props} title="CREATE TOOLBOX TALK" backIcon />
                <View style={s.mainContainer}>
                    <DropdownList
                        items={types}
                        containerStyle={s.dropdownContainer}
                        selectedItem={toolBoxTalk}
                        onItemSelect={(item) => this.setState({ toolBoxTalk: item })}
                        placeholder="TOOLBOX TALK"
                        withIds
                        topLabel
                    />
                    <TouchableOpacity onPress={this.onCancel}>
                        <View style={s.buttonCancel}>
                            <Text style={s.buttonTextCancel}>CANCEL</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={this.onCreate}>
                        <View style={s.button}>
                            <Text style={s.buttonText}>CREATE</Text>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ account, toolboxTalks, currentTimeCard }) => ({
    user: account.user,
    users: account.users,
    toolboxTypes: toolboxTalks.types,
    currentTicket: toolboxTalks.currentTicket,
    currentProject: currentTimeCard.currentProject,
});

export default connect(mapStateToProps, {
    finishEditing,
    saveToolboxTalks,
})(ToolBoxTalkCreateTicketScreen);
