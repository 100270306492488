import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme';

export default StyleSheet.create({
    mainContainer: {
        paddingTop: 20,
        paddingBottom: 30,
        height: '100%',
        width: '100%',
        paddingHorizontal: '5%',
    },
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    title: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.blue,
        textAlign: 'center',
        marginBottom: 15,
    },
    listText: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
        color: Color.dark_navy_blue,
        textAlign: 'left',
        marginBottom: 10,
        width: '100%',
    },
    menuContainer: {
        backgroundColor: Color.white,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        justifyContent: 'center',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        paddingHorizontal: 15,
    },
    menuDotOne: {
        backgroundColor: Color.turquoise,
        height: 9.5,
        width: 9.5,
        borderRadius: 5,
        marginTop: 22.5,
    },
    menuDotTwo: {
        backgroundColor: Color.pale_grey_three,
        marginLeft: 13.5,
        height: 9.5,
        width: 9.5,
        borderRadius: 5,
        marginTop: 22.5,
    },
    menuDotThree: {
        backgroundColor: Color.pale_grey_three,
        borderRadius: 5,
        marginLeft: 12.5,
        height: 9.5,
        width: 9.5,
        marginTop: 22.5,
    },
    button: {
        height: 50,
        width: 180,
        backgroundColor: Color.blue,
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        marginTop: 20,
        alignSelf: 'center',
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.5,
        shadowRadius: 5,
    },
    buttonText: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.white,
        textTransform: 'uppercase',
    },
    underLine: {
        height: 1,
        backgroundColor: Color.turquoise,
        marginVertical: 15,
    },
});
