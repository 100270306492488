import { StyleSheet } from 'react-native';
import { Color, Font } from '../../../theme';

export default StyleSheet.create({
    listItemWrapper: {
        width: '90%',
        height: 89.5,
        marginBottom: 10,
        marginLeft: 22.5,
        marginRight: 21.5,
        backgroundColor: Color.white,
        borderRadius: 6,
        elevation: 5,
    },
    listItemWrapperItem: {
        width: '90%',
        height: 68.5,
        marginBottom: 10.5,
        marginLeft: 22.5,
        marginRight: 21.5,
        backgroundColor: Color.white,
        borderRadius: 6,
        elevation: 5,
    },
    listItemWrapperDenied: {
        width: '90%',
        height: 109.5,
        marginBottom: 10.5,
        marginLeft: 22.5,
        marginRight: 21.5,
        backgroundColor: Color.white,
        borderRadius: 6,
        elevation: 5,
    },
    listItemDateText: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font10,
        color: Color.dark_navy_blue,
        letterSpacing: 0.45,
        textAlign: 'left',
        paddingLeft: 18.5,
        marginTop: 14,
    },
    listItemStatusPending: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font9,
        color: Color.light_grey_blue,
        letterSpacing: 0.41,
        textAlign: 'left',
        paddingLeft: 18.5,
        marginTop: 10,
    },
    listItemStatusApproved: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font9,
        color: Color.turquoise,
        letterSpacing: 0.41,
        textAlign: 'left',
        paddingLeft: 18.5,
        marginTop: 12,
    },
    listItemStatusDenied: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font9,
        color: Color.pinkish_orange,
        letterSpacing: 0.41,
        textAlign: 'left',
        paddingLeft: 18.5,
        marginTop: 12,
    },
    listItemComments: {
        fontFamily: Font.type.regular,
        fontSize: Font.size.font9,
        color: Color.light_grey_blue,
        letterSpacing: 0.023,
        paddingLeft: 18.5,
        marginTop: 10,
    },
});
