export default {
    GET_TIME_AND_MATERIAL: 'GET_TIME_AND_MATERIAL',
    CREATE_TIME_AND_MATERIAL: 'CREATE_TIME_AND_MATERIAL',
    SET_TIME_AND_MATERIAL: 'SET_TIME_AND_MATERIAL',
    REMOVE_NOTE_IMAGE: 'REMOVE_NOTE_IMAGE',
    REMOVE_SURVEY_IMAGE: 'REMOVE_SURVEY_IMAGE',
    ADD_ITEMS: 'ADD_MATERIAL_ITEMS',
    SAVE_TIME_AND_MATERIAL_IMAGE: 'SAVE_TIME_AND_MATERIAL_IMAGE',
    REMOVE_TIME_AND_MATERIAL_IMAGE: 'REMOVE_TIME_AND_MATERIAL_IMAGE',
    SUBMIT_TIME_AND_MATERIAL: {
        LOADING: 'SUBMIT_TIME_AND_MATERIAL',
        SUCCESS: 'SUBMIT_TIME_AND_MATERIAL_SUCCESS',
        ERROR: 'SUBMIT_TIME_AND_MATERIAL_ERROR',
    },
    LOADING: {
        START: 'TIME_AND_MATERIAL_LOADING_START',
        FINISH: 'TIME_AND_MATERIAL_LOADING_FINISH',
    },
    CLEAN_UP: 'TIME_AND_MATERIAL_CLEAN_UP',
};
