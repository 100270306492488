import moment from 'moment';
import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import s from './styles';
import { Color } from '../../../../theme';

const ListItem = (props) => {
    const { item } = props;

    const returnDates = (item) => {
        const startTime = item.startTime ? moment(item.startTime).format('MM/DD/YY') : null;
        const endTime = item.endTime ? moment(item.endTime).format('MM/DD/YY') : null;
        return (
            <View style={s.listItemInfoItem}>
                <Text style={s.listItemInfoData}>{`${startTime} - ${endTime}`}</Text>
                <View>
                    <View>
                        <Text style={s.listItemInfoData}>{`Personal: ${item.totalPersonalHours}`}</Text>
                        <Text style={s.listItemInfoData}>{`Vacation: ${item.totalVacationHours}`}</Text>
                        <Text style={s.listItemInfoData}>{`Sick: ${item.totalSickHours}`}</Text>
                    </View>
                </View>
                {item.offline && (
                    <View style={[s.cardType, s.cardTypeOffline]}>
                        <Text style={[s.cardTypeText, s.cardTypeOfflineText]}>SAVED OFFLINE</Text>
                    </View>
                )}
            </View>
        );
    };

    const returnNote = () => {
        if (item.adminFeedback) {
            return (
                <View style={{ flexDirection: 'row', marginTop: 4, marginBottom: -5 }}>
                    <Icon name="message-reply-text" color={Color.turquoise} size={14} />
                    <Text style={s.commentText}>{item.adminFeedback}</Text>
                </View>
            );
        }
        return null;
    };

    let statusText = '';
    switch (item.ticketStatus) {
        case 'A':
            statusText = 'APPROVED';
            break;
        case 'D':
            statusText = 'DENIED';
            break;
        case 'U':
            statusText = 'PENDING APPROVAL';
            break;
        default:
            break;
    }

    return (
        <TouchableOpacity onPress={item.onPress}>
            <View style={s.listItemWrapper}>
                <View style={s.listItemBody}>
                    <Text numberOfLines={1} style={s.listItemTitle}>
                        Time Off
                    </Text>
                    <View style={s.listItemInfo}>{returnDates(item)}</View>
                    <View style={{ flexDirection: 'row' }}>
                        <Text
                            style={[
                                s.successText,
                                item.ticketStatus === 'A' && s.successText_ready,
                                item.ticketStatus === 'D' && s.successText_error,
                                item.ticketStatus === 'U' && s.successText_pending,
                            ]}
                        >
                            {statusText}
                        </Text>
                    </View>
                    {returnNote()}
                </View>
                <Icon name="pencil" size={28} color={Color.turquoise} style={{ marginLeft: 10 }} />
            </View>
        </TouchableOpacity>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
};

export default ListItem;
