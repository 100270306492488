import { StyleSheet } from 'react-native';
import { Color, Font } from '../../theme';

export default StyleSheet.create({
    dropdownLabel: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font14,
        fontFamily: Font.type.regular,
    },
    dropdownLabel_item: {
        color: Color.dark_navy_blue,
        fontSize: Font.size.font14,
    },
    touchableRow: {
        backgroundColor: Color.white,
        borderRadius: 6,
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        padding: 10,
        width: '100%',
        height: 50,
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        marginBottom: 10,
    },
    dropdownText: {
        marginTop: -50,
        borderBottomWidth: 0,
    },
    dropdownWrapper: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.56)',
        paddingTop: '5%',
        paddingBottom: '5%',
    },
    dropdownContent: {
        flexGrow: 1,
        justifyContent: 'center',
    },
    dropDownItem: {
        width: 300,
        height: 50,
        backgroundColor: Color.very_light_blue,
        paddingHorizontal: 20,
        justifyContent: 'center',
        borderColor: Color.light_navy_20,
        borderWidth: 1,
        borderRadius: 6,
        elevation: 8,
        alignSelf: 'center',
    },
    dropDownItemText: {
        color: Color.light_navy,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
    },
    topLabel: {
        fontFamily: Font.type.regular,
        color: Color.grey,
        fontSize: Font.size.font10,
        marginBottom: -16,
        marginTop: 7,
    },
    fieldTitle: {
        fontFamily: Font.type.regular,
        color: Color.light_grey_blue,
        fontSize: Font.size.font11,
        position: 'absolute',
        top: 5,
        left: 8,
    },
});
