import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, ScrollView } from 'react-native';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import { saveUserTime } from '../../../store/modules/dailyReports';
import { NavigationBar, PrimaryButton, FieldInput, ConfirmationDialog } from '../../../components/index';
import s from './styles';

class DailyWorkLogContractorScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentUserTime: PropTypes.object,
        saveUserTime: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { currentUserTime } = props;
        this.state = {
            note: currentUserTime.Note,
            user: currentUserTime.User,
            hours: currentUserTime.Hours,
            showConfirmationDialog: false,
            confirmationText: 'You must add the user and the hours worked',
        };
    }

    save = () => {
        const { currentUserTime, saveUserTime, navigation } = this.props;
        const { note, user, hours } = this.state;

        if (isNil(hours) || isNil(user)) {
            this.setState({ showConfirmationDialog: true });
        } else {
            const parsedHours = parseFloat(hours);
            const userTime = {
                id: currentUserTime.id,
                User: user,
                Note: note,
                Hours: parsedHours,
            };

            saveUserTime(userTime);
            navigation.navigate('DailyReport');
        }
    };

    render() {
        const { note, user, hours, isUpdated, showConfirmationDialog, confirmationText } = this.state;
        const backConfirmText =
            // eslint-disable-next-line max-len
            'You are about to back out of adding user time. All not saved data will be lost. Are you sure you want to do this ?';
        const backTitle = 'Warning';
        return (
            <View style={[s.wrapper]}>
                <NavigationBar
                    {...this.props}
                    title="ADD USER TIME"
                    backIcon
                    backConfirm={isUpdated ? { title: backTitle, text: backConfirmText } : null}
                />
                <View>
                    <View style={s.menuTopContainer}>
                        <Text style={s.topMenu_title}>{moment().format('MM/DD/YY')}</Text>
                    </View>
                    <ScrollView style={{ height: '100%', width: '100%' }} contentContainerStyle={s.mainContainer}>
                        <View style={s.form}>
                            <FieldInput
                                title="USER"
                                style={{ elevation: 10, marginBottom: 10 }}
                                input={{
                                    onChange: (text) => {
                                        this.setState({ user: text, isUpdated: true });
                                    },
                                }}
                                meta={{
                                    error: null,
                                    touched: false,
                                }}
                                initialValues={user}
                            />
                            <FieldInput
                                title="HOURS"
                                keyboardType="numeric"
                                style={{ elevation: 10, marginBottom: 10 }}
                                input={{
                                    onChange: (text) => {
                                        this.setState({ hours: text, isUpdated: true });
                                    },
                                }}
                                meta={{
                                    error: null,
                                    touched: false,
                                }}
                                initialValues={!isNil(hours) ? hours.toString() : ''}
                            />
                            <FieldInput
                                title="NOTES"
                                multiline
                                style={{ elevation: 10, marginBottom: 10 }}
                                input={{
                                    onChange: (text) => {
                                        this.setState({ note: text, isUpdated: true });
                                    },
                                }}
                                meta={{
                                    error: null,
                                    touched: false,
                                }}
                                initialValues={note}
                            />
                        </View>
                        <View style={s.buttonWrapper}>
                            <PrimaryButton title="SAVE" onPress={this.save} />
                        </View>
                        <ConfirmationDialog
                            isOpen={showConfirmationDialog}
                            confirmLabel="OK"
                            onPress={() => {
                                this.setState({
                                    showConfirmationDialog: false,
                                });
                            }}
                            text={confirmationText}
                        />
                    </ScrollView>
                </View>
            </View>
        );
    }
}

const mapStateToProps = ({ dailyReports }) => ({
    currentUserTime: dailyReports.currentUserTime,
});

export default connect(mapStateToProps, { saveUserTime })(DailyWorkLogContractorScreen);
