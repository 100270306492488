import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, ScrollView, Image, ActivityIndicator, Modal } from 'react-native';
// import SignatureCapture from 'react-native-signature-capture';
import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import NetInfo from '@react-native-community/netinfo';
import { showMessage } from 'react-native-flash-message';

import { uploadImage } from '../../../store/helpers/common';
import {
    setChangeOrderItem,
    saveChangeOrderItem,
    saveDescriptionImage,
    saveGCImage,
    removeDescriptionImage,
    removeGCImage,
    changeOrderLoading,
    changeOrderFinishLoading,
    cleanUp,
} from '../../../store/modules/changeOrder';
import { uploadStart, uploadFinish } from '../../../store/modules/dailyReports';
import { NavigationBar, PrimaryButton, DropdownList, FieldInput } from '../../../components/index';
import { Config } from '../../../config';
import { Color } from '../../../theme';
import s from './styles';

const TYPE = {
    DESCRIPTION: 'DESCRIPTION',
    GC: 'GC',
};

class ChangeOrderNewItemScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentChangeOrderItem: PropTypes.object,
        user: PropTypes.object,
        costCodes: PropTypes.array,
        currentUploading: PropTypes.array,
        isLoading: PropTypes.bool,
        uploadStart: PropTypes.func,
        uploadImage: PropTypes.func,
        saveDescriptionImage: PropTypes.func,
        saveGCImage: PropTypes.func,
        removeDescriptionImage: PropTypes.func,
        removeGCImage: PropTypes.func,
        saveChangeOrderItem: PropTypes.func,
        cleanUp: PropTypes.func,
        changeOrderLoading: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { currentChangeOrderItem } = props;
        let estimates = [];
        if (!isEmpty(currentChangeOrderItem.ListOfEstimates)) {
            estimates = this._returnCurrentTicketCodes(currentChangeOrderItem.ListOfEstimates);
        }
        const subcontractors =
            currentChangeOrderItem.Subcontractor && currentChangeOrderItem.Subcontractor.length > 0
                ? currentChangeOrderItem.Subcontractor
                : [''];
        let fields;
        if (!isEmpty(estimates)) {
            fields = estimates;
        } else {
            fields = [
                {
                    id: `${moment().unix()}`,
                    CostCode: '',
                    Estimate: '',
                },
            ];
        }

        this.state = {
            emails: !isEmpty(currentChangeOrderItem.CustomerEmails)
                ? currentChangeOrderItem.CustomerEmails
                : [{ id: `${moment().unix()}`, Value: '' }],
            fields,
            name: currentChangeOrderItem.TicketName || '',
            notes: currentChangeOrderItem.Description.Description || '',
            subcontractors,
            isSign: false,
            isConnected: true,
        };
    }

    _showErrorMessage(isCamera, errorCode, errorMessage) {
        let message = 'Storage Access Denied';
        let description = 'Cannot add attachments until storage access granted';

        if (isCamera) {
            message = 'Storage or Camera Access Denied';
            description = 'Cannot add attachments until storage and camera access granted';
        }

        if (errorCode && errorCode === 'camera_unavailable') {
            message = 'Camera Unavailable';
            description = 'Cannot add attachments';
        } else if (errorCode && errorCode === 'others') {
            message = 'Error';
            description = errorMessage;
        }

        showMessage({
            message,
            description,
            type: 'danger',
            duration: 10000,
            animationDuration: 700,
            hideOnPress: true,
            hideStatusBar: true,
            backgroundColor: Color.red,
        });
    }

    _returnCurrentTicketCodes(ticketCodes) {
        const { costCodes } = this.props;
        const items = [];
        ticketCodes.forEach((item) => {
            let title = '';
            costCodes.forEach((c) => {
                if (c.id === item.CostCode) {
                    title = `${c.Code} –– ${c.Title}`;
                }
            });
            items.push({
                id: `${moment().unix()}`,
                Estimate: item.Estimate,
                CostCode: {
                    id: item.CostCode,
                    title,
                },
            });
        });
        return items;
    }

    componentDidMount() {
        NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            this.setState({ isConnected });
        });
    }

    // ---- ATTACHMENTS ----
    selectPhotoFromLibrary = async (type) => {
        const { uploadStart } = this.props;

        const response = await launchImageLibrary({
            mediaType: 'photo',
            quality: 1,
            maxWidth: 900,
            maxHeight: 900,
            includeBase64: true,
            selectionLimit: 0,
        });
        const { didCancel, errorCode, errorMessage, assets } = response;
        if (!didCancel && !errorCode) {
            uploadStart();
            this.saveAttachments(assets, type);
        } else {
            this._showErrorMessage(false, errorCode, errorMessage);
        }
    };

    createPhoto = async (type) => {
        const { uploadStart } = this.props;

        const response = await launchCamera({
            mediaType: 'photo',
            quality: 1,
            maxWidth: 900,
            maxHeight: 900,
            includeBase64: true,
            selectionLimit: 0,
            saveToPhotos: true,
        });
        const { didCancel, errorCode, errorMessage, assets } = response;
        if (!didCancel && !errorCode) {
            uploadStart();
            this.saveAttachments(assets, type);
        } else {
            this._showErrorMessage(true, errorCode, errorMessage);
        }
    };

    saveAttachments(items, type) {
        const { currentChangeOrderItem, uploadImage, saveDescriptionImage, saveGCImage } = this.props;
        items.forEach(async (item, i) => {
            const currentLength = currentChangeOrderItem.Description.Attachment.length;
            const data = {
                name: `${currentChangeOrderItem.id}_change_order_${currentLength + i}`,
                type: item.type,
                fileName: `${currentChangeOrderItem.id}_change_order_${currentLength + i}.jpg`,
                types: ['image'],
                subtypes: [],
                tags: [
                    {
                        value: 'image',
                        protected: true,
                    },
                ],
            };
            const filetype = `change_order_${type}`;
            uploadImage(data, item.base64, filetype, currentChangeOrderItem.id).then(({ asset, error }) => {
                if (!isEmpty(error)) {
                    showMessage({
                        message: error.message,
                        description: '',
                        type: 'danger',
                        icon: 'danger',
                        position: 'right',
                        hideStatusBar: true,
                        backgroundColor: Color.red,
                    });
                } else if (type === TYPE.DESCRIPTION) {
                    saveDescriptionImage(asset, currentChangeOrderItem);
                } else {
                    saveGCImage(asset, currentChangeOrderItem);
                }
            });
        });
    }

    _returnAttachment(attachment, i, type) {
        const { isConnected } = this.state;
        const { currentChangeOrderItem, removeDescriptionImage, removeGCImage } = this.props;
        const isApproved = currentChangeOrderItem.ProjectManagerApproval === 'A';

        let onPress;
        if (type === TYPE.DESCRIPTION) {
            onPress = () => removeDescriptionImage(attachment.File, currentChangeOrderItem);
        } else {
            onPress = () => removeGCImage(attachment.File, currentChangeOrderItem);
        }
        if (isConnected) {
            return (
                <View style={s.attachmentItem} key={i}>
                    <Image source={{ uri: attachment.File }} style={s.attachmentImage} />
                    <TouchableOpacity style={s.attachmentButton} disabled={isApproved} onPress={onPress}>
                        <Icon name="close" size={25} color={Color.white} />
                    </TouchableOpacity>
                </View>
            );
        }
        return (
            <View style={s.offline} key={i}>
                <Icon name="wifi-off" size={30} color={Color.cloudy_blue} />
                <Text style={s.offlineText}>You are offline, connect to internet</Text>
            </View>
        );
    }

    _returnCostCodes() {
        const { costCodes } = this.props;
        const items = [];
        costCodes.forEach((c) => {
            const title = `${c.Code} –– ${c.Title}`;
            items.push({
                id: c.id,
                title,
            });
        });
        return items;
    }

    // --- REST ITEMS --
    editMaterials(id, type, val) {
        const { fields } = this.state;
        fields.forEach((f, i) => {
            if (f.id === id) {
                if (type === 1) {
                    fields[i].CostCode = val;
                } else {
                    fields[i].Estimate = val;
                }
            }
        });
        this.setState({ fields });
    }

    removeEstimate = (id) => {
        const { fields } = this.state;
        this.setState({ fields: fields.filter((a) => a.id !== id) });
    };

    addEstimate = () => {
        const { fields } = this.state;
        fields.push({
            id: `${moment().unix()}`,
            CostCode: '',
            Estimate: '',
        });
        this.setState({ fields });
    };

    editEmails(id, val) {
        const { emails } = this.state;
        emails.forEach((f, i) => {
            if (f.id === id) {
                emails[i].Value = val;
            }
        });
        this.setState({ emails });
    }

    removeEmails = (id) => {
        const { emails } = this.state;
        this.setState({ emails: emails.filter((a) => a.id !== id) });
    };

    addEmails = () => {
        const { emails } = this.state;
        emails.push({
            id: `${moment().unix()}`,
            Value: '',
        });
        this.setState({ emails });
    };

    // -- GENERAL --
    _onSaveEvent = (result) => {
        const { name, notes, emails, fields, subcontractors } = this.state;
        const {
            uploadImage,
            saveChangeOrderItem,
            currentChangeOrderItem,
            user,
            changeOrderLoading,
            navigation,
            cleanUp,
        } = this.props;
        const changeOrderItem = currentChangeOrderItem;
        const file = result.encoded;
        let Subcontractor = [];
        if (subcontractors.length > 0) {
            Subcontractor = subcontractors.map((item) => {
                if (item.length > 1) {
                    return {
                        Name: item,
                        Date: moment().format('MM-DD-YYYY'),
                    };
                }
                return null;
            });
        }

        Subcontractor = Subcontractor.length < 1 ? Subcontractor : [];
        const data = {
            name: `${currentChangeOrderItem.id}`,
            type: 'image',
            fileName: `${currentChangeOrderItem.id}.png`,
            tags: [
                {
                    value: 'image',
                    protected: true,
                },
            ],
        };
        const estimates = fields.map((f) => {
            const e = {
                CostCodeID: f.CostCode.id,
                Estimate: f.Estimate,
            };
            return e;
        });
        const Emails = emails;
        for (let i = 0; i < Emails.length; i++) {
            if (Emails[i].Value.length === 0) {
                Emails.splice(i, 1);
            }
        }
        changeOrderLoading();
        uploadImage(data, file, 'sign').then(({ asset, error }) => {
            if (error) {
                return;
            }

            changeOrderItem.TicketName = name;
            changeOrderItem.Description.Description = notes;
            changeOrderItem.CustomerEmails = Emails;
            changeOrderItem.ListOfEstimates = estimates;
            changeOrderItem.GeneralContractor = {
                ...changeOrderItem.GeneralContractor,
                Name: user.username,
                Complete: true,
                Signature: asset.file.url,
            };
            changeOrderItem.Subcontractor = Subcontractor;
            saveChangeOrderItem({ changeOrderItem, user }).then((resp) => {
                if (resp) {
                    navigation.navigate('ChangeOrderList');
                    cleanUp();
                }
            });
        });
    };

    setSubcontractor(text, id) {
        const { subcontractors } = this.state;
        subcontractors.forEach((s, i) => {
            if (i === id) {
                subcontractors[i] = text;
            }
        });
        this.setState({ subcontractors });
    }

    removeSubcontractor(id) {
        const { subcontractors } = this.state;
        this.setState({ subcontractors: subcontractors.splice(id, 1) });
    }

    addSubcontractor = () => {
        const { subcontractors } = this.state;
        const newSubcontractors = [...subcontractors, ''];
        this.setState({ subcontractors: newSubcontractors });
    };

    render() {
        const { isLoading, currentChangeOrderItem, currentUploading } = this.props;
        const { name, notes, fields, emails, isSign, subcontractors } = this.state;
        const descAttachments = currentChangeOrderItem ? currentChangeOrderItem.Description.Attachment : [];
        const gcAttachments =
            currentChangeOrderItem && !isEmpty(currentChangeOrderItem.GeneralContractor.Picture)
                ? [{ File: currentChangeOrderItem.GeneralContractor.Picture }]
                : [];
        const backConfirmText =
            // eslint-disable-next-line max-len
            'You are about to back out of your order change. All not saved data will be lost. Are you sure you want to do this ?';
        const backTitle = 'Warning';
        const items = this._returnCostCodes();
        const isDisabled = !isSign || isEmpty(notes) || isEmpty(name) || !isEmpty(currentUploading);
        const isApproved = currentChangeOrderItem && currentChangeOrderItem.ProjectManagerApproval === 'A';
        const costCodeTitle = Config.USE_DEPARTMENTS_JOBS ? 'JOB' : 'COST CODE';
        return (
            <ScrollView style={[s.wrapper]}>
                <NavigationBar
                    {...this.props}
                    title="ADD NEW TICKET"
                    backIcon
                    backConfirm={{ title: backTitle, text: backConfirmText }}
                />
                <View style={[s.mainContainer]}>
                    <FieldInput
                        placeholder="TICKET NAME"
                        sm
                        input={{
                            onChange: (text) => {
                                this.setState({ name: text });
                            },
                        }}
                        meta={{
                            error: null,
                            touched: false,
                        }}
                        initialValues={name}
                        editable={!isApproved}
                    />
                    <FieldInput
                        placeholder="NOTES"
                        multiline
                        input={{
                            onChange: (text) => {
                                this.setState({ notes: text });
                            },
                        }}
                        meta={{
                            error: null,
                            touched: false,
                        }}
                        initialValues={notes}
                        editable={!isApproved}
                    />
                    <View style={s.uploadBlock}>
                        <View style={s.uploadBlockHeader}>
                            <Text style={s.uploadBlockTitle}>ATTACH BEFORE HAND PHOTOS</Text>
                            <View style={{ flexDirection: 'row' }}>
                                <TouchableOpacity
                                    style={s.uploadIcon}
                                    disabled={isApproved}
                                    onPress={() => this.selectPhotoFromLibrary(TYPE.DESCRIPTION)}
                                >
                                    <Icon name="attachment" color={Color.light_navy} size={16} />
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={s.uploadIcon}
                                    disabled={isApproved}
                                    onPress={() => this.createPhoto(TYPE.DESCRIPTION)}
                                >
                                    <Icon name="camera" color={Color.light_navy} size={16} />
                                </TouchableOpacity>
                            </View>
                        </View>
                        {(!isEmpty(descAttachments) || !isEmpty(currentUploading)) && (
                            <View style={s.attachmentsWrapper}>
                                {descAttachments.map((a, i) => {
                                    return this._returnAttachment(a, i, TYPE.DESCRIPTION);
                                })}
                                {currentUploading.map((u, i) => {
                                    if (u.type === `change_order_${TYPE.DESCRIPTION}`) {
                                        return (
                                            <View style={s.attachmentItem} key={i}>
                                                <ActivityIndicator size={40} color={Color.blue} />
                                            </View>
                                        );
                                    }
                                    return null;
                                })}
                            </View>
                        )}
                    </View>
                    <View style={s.formWrapper}>
                        <Text style={s.formTitle}>ESTIMATES</Text>
                        {fields.map((f) => {
                            return (
                                <View style={s.materialItem} key={f.id}>
                                    <View style={s.materialItemFields}>
                                        <DropdownList
                                            items={items}
                                            disabled={isApproved}
                                            containerStyle={s.materialDropdown}
                                            selectedItem={f.CostCode}
                                            onItemSelect={(item) => {
                                                this.editMaterials(f.id, 1, item);
                                            }}
                                            placeholder={costCodeTitle}
                                            height="50%"
                                            withIds
                                        />
                                        <FieldInput
                                            placeholder="ESTIMATE"
                                            sm
                                            input={{
                                                onChange: (text) => {
                                                    this.editMaterials(f.id, 2, text);
                                                },
                                            }}
                                            meta={{
                                                error: null,
                                                touched: false,
                                            }}
                                            initialValues={f.Estimate}
                                            style={s.materialDropdown}
                                            editable={!isApproved}
                                        />
                                    </View>
                                    <TouchableOpacity
                                        disabled={isApproved}
                                        onPress={() => this.removeEstimate(f.id)}
                                        style={s.deleteButton}
                                    >
                                        <Icon name="minus" style={s.deleteIcon} />
                                    </TouchableOpacity>
                                </View>
                            );
                        })}
                        <TouchableOpacity style={s.materialItemAdd} disabled={isApproved} onPress={this.addEstimate}>
                            <Icon name="plus" style={s.materialItemAddIcon} />
                            <Text style={s.materialItemAddText}>ADD NEW</Text>
                        </TouchableOpacity>
                    </View>

                    <View style={s.formWrapper}>
                        <Text style={[s.formTitle, { marginBottom: 15 }]}>EMAILS</Text>
                        {emails.map((e, i) => {
                            return (
                                <View style={s.emailAdd} key={e.id}>
                                    <FieldInput
                                        placeholder="EMAIL"
                                        style={{ flex: 1, marginRight: 11.5 }}
                                        sm
                                        input={{
                                            onChange: (text) => {
                                                this.editEmails(e.id, text);
                                            },
                                        }}
                                        meta={{
                                            error: null,
                                            touched: false,
                                        }}
                                        initialValues={e.Value}
                                        editable={!isApproved}
                                    />
                                    {i + 1 < emails.length ? (
                                        <TouchableOpacity
                                            onPress={() => this.removeEmails(e.id)}
                                            disabled={isApproved}
                                            style={s.emailAddButton}
                                        >
                                            <Icon name="minus" style={s.emailAddIcon} />
                                        </TouchableOpacity>
                                    ) : (
                                        <TouchableOpacity
                                            onPress={this.addEmails}
                                            disabled={isApproved}
                                            style={s.emailAddButton}
                                        >
                                            <Icon name="plus" style={s.emailAddIcon} />
                                        </TouchableOpacity>
                                    )}
                                </View>
                            );
                        })}
                    </View>
                    <View style={s.formWrapper}>
                        <Text style={[s.formTitle, { marginBottom: 15 }]}>SUBCONTRACTORS</Text>
                        {subcontractors.map((name, i) => {
                            return (
                                <View style={s.emailAdd} key={i}>
                                    <FieldInput
                                        placeholder="SUBCONTRACTOR NAME"
                                        style={{ flex: 1, marginRight: 11.5 }}
                                        sm
                                        input={{
                                            onChange: (text) => {
                                                this.setSubcontractor(text, i);
                                            },
                                        }}
                                        meta={{
                                            error: null,
                                            touched: false,
                                        }}
                                        initialValues={name}
                                        editable={!isApproved}
                                    />
                                    {i + 1 < subcontractors.length ? (
                                        <TouchableOpacity
                                            onPress={() => this.removeSubcontractor(i)}
                                            style={s.emailAddButton}
                                            disabled={isApproved}
                                        >
                                            <Icon name="minus" style={s.emailAddIcon} />
                                        </TouchableOpacity>
                                    ) : (
                                        <TouchableOpacity
                                            onPress={this.addSubcontractor}
                                            style={s.emailAddButton}
                                            disabled={isApproved}
                                        >
                                            <Icon name="plus" style={s.emailAddIcon} />
                                        </TouchableOpacity>
                                    )}
                                </View>
                            );
                        })}
                    </View>
                    <View style={s.formWrapper}>
                        <View style={s.modalSignature}>

                            <Text style={s.signaturePlaceholder}>Please sign here</Text>
                        </View>
                    </View>
                    <View style={s.uploadBlock}>
                        <View style={s.uploadBlockHeader}>
                            <Text style={s.uploadBlockTitle}>ATTACH GENERAL CONTRACTOR PHOTO</Text>
                            <View style={{ flexDirection: 'row' }}>
                                <TouchableOpacity
                                    style={s.uploadIcon}
                                    disabled={isApproved}
                                    onPress={() => this.selectPhotoFromLibrary(TYPE.GC)}
                                >
                                    <Icon name="attachment" color={Color.light_navy} size={16} />
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={s.uploadIcon}
                                    disabled={isApproved}
                                    onPress={() => this.createPhoto(TYPE.GC)}
                                >
                                    <Icon name="camera" color={Color.light_navy} size={16} />
                                </TouchableOpacity>
                            </View>
                        </View>
                        {(!isEmpty(gcAttachments) || !isEmpty(currentUploading)) && (
                            <View style={s.attachmentsWrapper}>
                                {gcAttachments.map((a, i) => {
                                    return this._returnAttachment(a, i, TYPE.GC);
                                })}
                                {currentUploading.map((u, i) => {
                                    if (u.type === `change_order_${TYPE.GC}`) {
                                        return (
                                            <View style={s.attachmentItem} key={i}>
                                                <ActivityIndicator size={40} color={Color.blue} />
                                            </View>
                                        );
                                    }
                                    return null;
                                })}
                            </View>
                        )}
                    </View>
                    <View style={s.buttonWrapper}>
                        <PrimaryButton
                            title="SAVE"
                            onPress={() => {
                                this.signature.saveImage();
                            }}
                            disabled={isDisabled || isApproved}
                        />
                    </View>
                </View>
                <Modal visible={isLoading} transparent>
                    <View style={s.loadingModalWrapper}>
                        <ActivityIndicator color={Color.white} size={40} />
                    </View>
                </Modal>
            </ScrollView>
        );
    }
}

const mapStateToProps = ({ dailyReports, changeOrder, account }) => ({
    currentProject: dailyReports.currentProject,
    currentChangeOrderItem: changeOrder.currentChangeOrderItem,
    currentUploading: dailyReports.currentUploading,
    isLoading: changeOrder.isLoading,
    costCodes: account.costCodes,
    user: account.user,
});

export default connect(mapStateToProps, {
    setChangeOrderItem,
    saveChangeOrderItem,
    saveDescriptionImage,
    saveGCImage,
    removeDescriptionImage,
    removeGCImage,
    changeOrderLoading,
    changeOrderFinishLoading,
    uploadStart,
    uploadFinish,
    uploadImage,
    cleanUp,
})(ChangeOrderNewItemScreen);
